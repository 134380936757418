import React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';

import * as Views from './views';
import { RouteWithLayout } from './components';

const Routes = (props: RouteProps): JSX.Element => {
  return (
    <Switch>
      <Route component={Views.Login} exact path="/" />
      <RouteWithLayout {...props} component={Views.Login} exact path="/dashboard" requiresAuth roleRequired="*" />
      {/* Menú */}
      <RouteWithLayout {...props} component={Views.Menu} exact path="/menu" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.AddDish} exact path="/menu/add" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.AddDish} exact path="/menu/edit" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.Ingredients} exact path="/menu/ingredients" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.AddIngredients} exact path="/menu/ingredients/add" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.Modifiers} exact path="/menu/modifiers" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.AddModifiers} exact path="/menu/modifiers/add" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.Categories} exact path="/menu/categories" requiresAuth roleRequired="admin" />

      {/* Recepción */}
      <RouteWithLayout {...props} component={Views.Reception} exact path="/reception" requiresAuth roleRequired="reception" />
      <RouteWithLayout {...props} component={Views.ReceptionDetails} exact path="/reception/details" requiresAuth roleRequired="reception" />
      <RouteWithLayout {...props} component={Views.Registration} exact path="/reception/registration" requiresAuth roleRequired="reception" />
      <RouteWithLayout {...props} component={Views.CashierReport} exact path="/reception/cashier-close" requiresAuth roleRequired="reception" />
      <RouteWithLayout {...props} component={Views.Tickets} exact path="/reception/tickets" requiresAuth roleRequired="reception" />
      
      {/* Cuentas */}
      <RouteWithLayout {...props} component={Views.Accounts} exact path="/accounts" requiresAuth roleRequired="palapa" />
      <RouteWithLayout {...props} component={Views.AccountDetails} exact path="/accounts/details" requiresAuth roleRequired="palapa" />
      <RouteWithLayout {...props} component={Views.addCommandOrder} exact path="/accounts/details/add-order" requiresAuth roleRequired="palapa" />
      
      {/* Impresoras */}
      <RouteWithLayout {...props} component={Views.Printers} exact path="/printers" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.PrinterDetails} exact path="/printers/details" requiresAuth roleRequired="admin" />
      
      {/* Comandas */}
      <RouteWithLayout {...props} component={Views.commandsOrders} exact path="/checkin" requiresAuth roleRequired="reception" />
      <RouteWithLayout {...props} component={Views.addCommandOrder} exact path="/orders/add" requiresAuth roleRequired="palapa" />
      
      {/* Usuarios */}
      <RouteWithLayout {...props} component={Views.Users} exact path="/users" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.UserDetails} exact path="/users/details" requiresAuth roleRequired="admin" />
      <RouteWithLayout {...props} component={Views.AddUser} exact path="/users/add" requiresAuth roleRequired="admin" />
      
      {/* Condominios */}
      <RouteWithLayout {...props} component={Views.Apartments} exact path="/apartments" requiresAuth roleRequired="admin" />
      
      {/* Visitas */}
      <RouteWithLayout {...props} component={Views.Visits} exact path="/visits" requiresAuth roleRequired={['reception', 'portico']} />

      <RouteWithLayout {...props} component={Views.AddVisit} exact path="/visits/add" requiresAuth roleRequired={['reception', 'portico']} />

      {/* Reportes */}
      <RouteWithLayout {...props} component={Views.Reports} exact path="/reports" requiresAuth roleRequired="reception" />

      {/* Estados de cuenta */}
      <RouteWithLayout {...props} component={Views.Statements} exact path="/statements" requiresAuth roleRequired="admin" />

    </Switch>
  );
};

export default Routes;
