import React, { useState, FC } from 'react';
import { Alert, Snackbar, AlertColor, SnackbarCloseReason } from '@mui/material';

type SnackbarHandler = (message: string, severity: AlertColor, duration?: number) => void;

type SnackbarProps = {
  text: string;
  visible: boolean;
  severity: AlertColor;
  duration?: number;
};

interface SnackbarProviderContext {
  showSnackbar: SnackbarHandler;
}

const SnackbarContext = React.createContext<SnackbarProviderContext>({} as SnackbarProviderContext);

export const useSnackbar = (): SnackbarProviderContext => React.useContext(SnackbarContext);

export const SnackbarProvider: FC = ({ children }): JSX.Element => {
  const [snackbarState, setSnackbarState] = useState<SnackbarProps>({
    text: '',
    visible: false,
    severity: 'warning',
    duration: 3500
  });

  const showSnackbar = (message: string, severity: AlertColor, duration?: number): void => {
    setSnackbarState({
      text: message,
      visible: true,
      severity,
      duration: duration || 3500
    });
  };

  const onSnackbarClose = (_: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      visible: false
    });
  };

  return (
    <>
      <SnackbarContext.Provider value={{ showSnackbar }}>{children}</SnackbarContext.Provider>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={snackbarState.duration}
        onClose={onSnackbarClose}
        open={snackbarState.visible}
      >
        <Alert
          onClose={() => setSnackbarState({ ...snackbarState, visible: false })}
          severity={snackbarState.severity || 'warning'}
        >
          {snackbarState.text}
        </Alert>
      </Snackbar>
    </>
  );
};
