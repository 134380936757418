import React from 'react';
import {
  CircularProgress,
  Grid,
  Button,
  Table,
  Typography,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  IconButton
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { GetPrintersQuery } from 'types/graphql';
import { PrinterRow } from './components';

const GET_PRINTERS = gql`
  query GetPrinters {
    printers {
      id
      name
      address
      disabled
    }
  }
`;

const Printers = (): JSX.Element => {
  const { data, loading } = useQuery<GetPrintersQuery>(GET_PRINTERS);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item>
          <Typography variant="h5">Impresoras</Typography>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained">
            Agregar impresora
          </Button>
          <IconButton sx={{ ml: 2 }}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Dirección IP</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell sx={{ width: 80 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                data?.printers.map((p) => <PrinterRow key={p.id} printer={p} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Printers;
