import React, { useState } from 'react';
import { Typography, Grid, IconButton, Divider, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { BalancesReport, PalapaReport, TransactionsReport } from './components';

/* Movimientos, saldos y cargos respectivamente */
type ReportsType = 'transactions' | 'balances' | 'palapa';

const Reports = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [reportsType, setReportsType] = useState<ReportsType>('balances');
  const open = Boolean(anchorEl);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">Reportes</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
            <MenuItem onClick={() => setReportsType('palapa')}>Consumos</MenuItem>
            <MenuItem onClick={() => setReportsType('balances')}>Saldos</MenuItem>
            <MenuItem onClick={() => setReportsType('transactions')}>Movimientos</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {reportsType === 'balances' && <BalancesReport />}
      {reportsType === 'palapa' && <PalapaReport />}
      {reportsType === 'transactions' && <TransactionsReport />}
    </Grid>
  );
};

export default Reports;
