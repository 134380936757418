import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  admintype: any;
  bit: any;
  date: any;
  numeric: any;
  time: any;
  timestamp: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CreateUserAccountOutput = {
  __typename?: 'CreateUserAccountOutput';
  uid: Scalars['String'];
};

// export type PrintResult = {
//   __typename?: 'PrintResult';
//   message: Scalars['String'];
//   success: Scalars['Boolean'];
// };

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type SwitchUserStatusOutput = {
  __typename?: 'SwitchUserStatusOutput';
  affected_rows: Scalars['Int'];
};

export type UpdateUserEmailOutput = {
  __typename?: 'UpdateUserEmailOutput';
  email: Scalars['String'];
};

export type UpdateUserRoleOutput = {
  __typename?: 'UpdateUserRoleOutput';
  uid: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  uid?: Maybe<Scalars['String']>;
};

/** columns and relationships of "account_tickets" */
export type Account_Tickets = {
  __typename?: 'account_tickets';
  /** A computed field, executes function "account_apartment" */
  apartment_name?: Maybe<Scalars['String']>;
  commensal_balance: Scalars['numeric'];
  /** A computed field, executes function "get_account_commensal" */
  commensal_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  open: Scalars['Boolean'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** An object relationship */
  statement_account: Statement_Account;
  statement_account_id: Scalars['Int'];
  /** An object relationship */
  table: Tables;
  table_id: Scalars['Int'];
  tip: Scalars['numeric'];
  total: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "account_tickets" */
export type Account_TicketsOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "account_tickets" */
export type Account_TicketsOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** aggregated selection of "account_tickets" */
export type Account_Tickets_Aggregate = {
  __typename?: 'account_tickets_aggregate';
  aggregate?: Maybe<Account_Tickets_Aggregate_Fields>;
  nodes: Array<Account_Tickets>;
};

/** aggregate fields of "account_tickets" */
export type Account_Tickets_Aggregate_Fields = {
  __typename?: 'account_tickets_aggregate_fields';
  avg?: Maybe<Account_Tickets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Tickets_Max_Fields>;
  min?: Maybe<Account_Tickets_Min_Fields>;
  stddev?: Maybe<Account_Tickets_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Tickets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Tickets_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Tickets_Sum_Fields>;
  var_pop?: Maybe<Account_Tickets_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Tickets_Var_Samp_Fields>;
  variance?: Maybe<Account_Tickets_Variance_Fields>;
};


/** aggregate fields of "account_tickets" */
export type Account_Tickets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Tickets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_tickets" */
export type Account_Tickets_Aggregate_Order_By = {
  avg?: Maybe<Account_Tickets_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Tickets_Max_Order_By>;
  min?: Maybe<Account_Tickets_Min_Order_By>;
  stddev?: Maybe<Account_Tickets_Stddev_Order_By>;
  stddev_pop?: Maybe<Account_Tickets_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Account_Tickets_Stddev_Samp_Order_By>;
  sum?: Maybe<Account_Tickets_Sum_Order_By>;
  var_pop?: Maybe<Account_Tickets_Var_Pop_Order_By>;
  var_samp?: Maybe<Account_Tickets_Var_Samp_Order_By>;
  variance?: Maybe<Account_Tickets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_tickets" */
export type Account_Tickets_Arr_Rel_Insert_Input = {
  data: Array<Account_Tickets_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Account_Tickets_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Tickets_Avg_Fields = {
  __typename?: 'account_tickets_avg_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_tickets" */
export type Account_Tickets_Avg_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_tickets". All fields are combined with a logical 'AND'. */
export type Account_Tickets_Bool_Exp = {
  _and?: Maybe<Array<Account_Tickets_Bool_Exp>>;
  _not?: Maybe<Account_Tickets_Bool_Exp>;
  _or?: Maybe<Array<Account_Tickets_Bool_Exp>>;
  apartment_name?: Maybe<String_Comparison_Exp>;
  commensal_balance?: Maybe<Numeric_Comparison_Exp>;
  commensal_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  open?: Maybe<Boolean_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  statement_account?: Maybe<Statement_Account_Bool_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
  table?: Maybe<Tables_Bool_Exp>;
  table_id?: Maybe<Int_Comparison_Exp>;
  tip?: Maybe<Numeric_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_tickets" */
export enum Account_Tickets_Constraint {
  /** unique or primary key constraint */
  AccountTicketsPkey = 'account_tickets_pkey'
}

/** input type for incrementing numeric columns in table "account_tickets" */
export type Account_Tickets_Inc_Input = {
  commensal_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "account_tickets" */
export type Account_Tickets_Insert_Input = {
  commensal_balance?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  statement_account?: Maybe<Statement_Account_Obj_Rel_Insert_Input>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table?: Maybe<Tables_Obj_Rel_Insert_Input>;
  table_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Tickets_Max_Fields = {
  __typename?: 'account_tickets_max_fields';
  commensal_balance?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_tickets" */
export type Account_Tickets_Max_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Tickets_Min_Fields = {
  __typename?: 'account_tickets_min_fields';
  commensal_balance?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_tickets" */
export type Account_Tickets_Min_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_tickets" */
export type Account_Tickets_Mutation_Response = {
  __typename?: 'account_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Tickets>;
};

/** input type for inserting object relation for remote table "account_tickets" */
export type Account_Tickets_Obj_Rel_Insert_Input = {
  data: Account_Tickets_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Account_Tickets_On_Conflict>;
};

/** on_conflict condition type for table "account_tickets" */
export type Account_Tickets_On_Conflict = {
  constraint: Account_Tickets_Constraint;
  update_columns?: Array<Account_Tickets_Update_Column>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "account_tickets". */
export type Account_Tickets_Order_By = {
  apartment_name?: Maybe<Order_By>;
  commensal_balance?: Maybe<Order_By>;
  commensal_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  open?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  statement_account?: Maybe<Statement_Account_Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table?: Maybe<Tables_Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: account_tickets */
export type Account_Tickets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "account_tickets" */
export enum Account_Tickets_Select_Column {
  /** column name */
  CommensalBalance = 'commensal_balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Open = 'open',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  TableId = 'table_id',
  /** column name */
  Tip = 'tip',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_tickets" */
export type Account_Tickets_Set_Input = {
  commensal_balance?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Tickets_Stddev_Fields = {
  __typename?: 'account_tickets_stddev_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_tickets" */
export type Account_Tickets_Stddev_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Tickets_Stddev_Pop_Fields = {
  __typename?: 'account_tickets_stddev_pop_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_tickets" */
export type Account_Tickets_Stddev_Pop_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Tickets_Stddev_Samp_Fields = {
  __typename?: 'account_tickets_stddev_samp_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_tickets" */
export type Account_Tickets_Stddev_Samp_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Tickets_Sum_Fields = {
  __typename?: 'account_tickets_sum_fields';
  commensal_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "account_tickets" */
export type Account_Tickets_Sum_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** update columns of table "account_tickets" */
export enum Account_Tickets_Update_Column {
  /** column name */
  CommensalBalance = 'commensal_balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Open = 'open',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  TableId = 'table_id',
  /** column name */
  Tip = 'tip',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Tickets_Var_Pop_Fields = {
  __typename?: 'account_tickets_var_pop_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_tickets" */
export type Account_Tickets_Var_Pop_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Tickets_Var_Samp_Fields = {
  __typename?: 'account_tickets_var_samp_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_tickets" */
export type Account_Tickets_Var_Samp_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Tickets_Variance_Fields = {
  __typename?: 'account_tickets_variance_fields';
  commensal_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_tickets" */
export type Account_Tickets_Variance_Order_By = {
  commensal_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  tip?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** columns and relationships of "activities" */
export type Activities = {
  __typename?: 'activities';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "activities" */
export type Activities_Aggregate = {
  __typename?: 'activities_aggregate';
  aggregate?: Maybe<Activities_Aggregate_Fields>;
  nodes: Array<Activities>;
};

/** aggregate fields of "activities" */
export type Activities_Aggregate_Fields = {
  __typename?: 'activities_aggregate_fields';
  avg?: Maybe<Activities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Activities_Max_Fields>;
  min?: Maybe<Activities_Min_Fields>;
  stddev?: Maybe<Activities_Stddev_Fields>;
  stddev_pop?: Maybe<Activities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Activities_Stddev_Samp_Fields>;
  sum?: Maybe<Activities_Sum_Fields>;
  var_pop?: Maybe<Activities_Var_Pop_Fields>;
  var_samp?: Maybe<Activities_Var_Samp_Fields>;
  variance?: Maybe<Activities_Variance_Fields>;
};


/** aggregate fields of "activities" */
export type Activities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Activities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Activities_Avg_Fields = {
  __typename?: 'activities_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "activities". All fields are combined with a logical 'AND'. */
export type Activities_Bool_Exp = {
  _and?: Maybe<Array<Activities_Bool_Exp>>;
  _not?: Maybe<Activities_Bool_Exp>;
  _or?: Maybe<Array<Activities_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "activities" */
export enum Activities_Constraint {
  /** unique or primary key constraint */
  ActivitiesPkey = 'activities_pkey'
}

/** input type for incrementing numeric columns in table "activities" */
export type Activities_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "activities" */
export type Activities_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Activities_Max_Fields = {
  __typename?: 'activities_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Activities_Min_Fields = {
  __typename?: 'activities_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "activities" */
export type Activities_Mutation_Response = {
  __typename?: 'activities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Activities>;
};

/** on_conflict condition type for table "activities" */
export type Activities_On_Conflict = {
  constraint: Activities_Constraint;
  update_columns?: Array<Activities_Update_Column>;
  where?: Maybe<Activities_Bool_Exp>;
};

/** Ordering options when selecting data from "activities". */
export type Activities_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: activities */
export type Activities_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "activities" */
export enum Activities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "activities" */
export type Activities_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Activities_Stddev_Fields = {
  __typename?: 'activities_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Activities_Stddev_Pop_Fields = {
  __typename?: 'activities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Activities_Stddev_Samp_Fields = {
  __typename?: 'activities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Activities_Sum_Fields = {
  __typename?: 'activities_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "activities" */
export enum Activities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Activities_Var_Pop_Fields = {
  __typename?: 'activities_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Activities_Var_Samp_Fields = {
  __typename?: 'activities_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Activities_Variance_Fields = {
  __typename?: 'activities_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "addresses" */
export type Addresses = {
  __typename?: 'addresses';
  city: Scalars['String'];
  /** An array relationship */
  contact_addresses: Array<Contact_Addresses>;
  /** An aggregate relationship */
  contact_addresses_aggregate: Contact_Addresses_Aggregate;
  exterior: Scalars['String'];
  id: Scalars['Int'];
  interior: Scalars['String'];
  neighborhood: Scalars['String'];
  street: Scalars['String'];
  zip_code: Scalars['String'];
};


/** columns and relationships of "addresses" */
export type AddressesContact_AddressesArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesContact_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};

/** aggregated selection of "addresses" */
export type Addresses_Aggregate = {
  __typename?: 'addresses_aggregate';
  aggregate?: Maybe<Addresses_Aggregate_Fields>;
  nodes: Array<Addresses>;
};

/** aggregate fields of "addresses" */
export type Addresses_Aggregate_Fields = {
  __typename?: 'addresses_aggregate_fields';
  avg?: Maybe<Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Addresses_Max_Fields>;
  min?: Maybe<Addresses_Min_Fields>;
  stddev?: Maybe<Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Sum_Fields>;
  var_pop?: Maybe<Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Variance_Fields>;
};


/** aggregate fields of "addresses" */
export type Addresses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Addresses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Addresses_Avg_Fields = {
  __typename?: 'addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "addresses". All fields are combined with a logical 'AND'. */
export type Addresses_Bool_Exp = {
  _and?: Maybe<Array<Addresses_Bool_Exp>>;
  _not?: Maybe<Addresses_Bool_Exp>;
  _or?: Maybe<Array<Addresses_Bool_Exp>>;
  city?: Maybe<String_Comparison_Exp>;
  contact_addresses?: Maybe<Contact_Addresses_Bool_Exp>;
  exterior?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  interior?: Maybe<String_Comparison_Exp>;
  neighborhood?: Maybe<String_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "addresses" */
export enum Addresses_Constraint {
  /** unique or primary key constraint */
  AddressPkey = 'address_pkey'
}

/** input type for incrementing numeric columns in table "addresses" */
export type Addresses_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "addresses" */
export type Addresses_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  contact_addresses?: Maybe<Contact_Addresses_Arr_Rel_Insert_Input>;
  exterior?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interior?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Addresses_Max_Fields = {
  __typename?: 'addresses_max_fields';
  city?: Maybe<Scalars['String']>;
  exterior?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interior?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Addresses_Min_Fields = {
  __typename?: 'addresses_min_fields';
  city?: Maybe<Scalars['String']>;
  exterior?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interior?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "addresses" */
export type Addresses_Mutation_Response = {
  __typename?: 'addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses>;
};

/** input type for inserting object relation for remote table "addresses" */
export type Addresses_Obj_Rel_Insert_Input = {
  data: Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Addresses_On_Conflict>;
};

/** on_conflict condition type for table "addresses" */
export type Addresses_On_Conflict = {
  constraint: Addresses_Constraint;
  update_columns?: Array<Addresses_Update_Column>;
  where?: Maybe<Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "addresses". */
export type Addresses_Order_By = {
  city?: Maybe<Order_By>;
  contact_addresses_aggregate?: Maybe<Contact_Addresses_Aggregate_Order_By>;
  exterior?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interior?: Maybe<Order_By>;
  neighborhood?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: addresses */
export type Addresses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "addresses" */
export enum Addresses_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Exterior = 'exterior',
  /** column name */
  Id = 'id',
  /** column name */
  Interior = 'interior',
  /** column name */
  Neighborhood = 'neighborhood',
  /** column name */
  Street = 'street',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "addresses" */
export type Addresses_Set_Input = {
  city?: Maybe<Scalars['String']>;
  exterior?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  interior?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Addresses_Stddev_Fields = {
  __typename?: 'addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Addresses_Stddev_Pop_Fields = {
  __typename?: 'addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Addresses_Stddev_Samp_Fields = {
  __typename?: 'addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Addresses_Sum_Fields = {
  __typename?: 'addresses_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "addresses" */
export enum Addresses_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Exterior = 'exterior',
  /** column name */
  Id = 'id',
  /** column name */
  Interior = 'interior',
  /** column name */
  Neighborhood = 'neighborhood',
  /** column name */
  Street = 'street',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Addresses_Var_Pop_Fields = {
  __typename?: 'addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Addresses_Var_Samp_Fields = {
  __typename?: 'addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Addresses_Variance_Fields = {
  __typename?: 'addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "admin_items" */
export type Admin_Items = {
  __typename?: 'admin_items';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
  type?: Maybe<Scalars['admintype']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "admin_items" */
export type Admin_Items_Aggregate = {
  __typename?: 'admin_items_aggregate';
  aggregate?: Maybe<Admin_Items_Aggregate_Fields>;
  nodes: Array<Admin_Items>;
};

/** aggregate fields of "admin_items" */
export type Admin_Items_Aggregate_Fields = {
  __typename?: 'admin_items_aggregate_fields';
  avg?: Maybe<Admin_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_Items_Max_Fields>;
  min?: Maybe<Admin_Items_Min_Fields>;
  stddev?: Maybe<Admin_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_Items_Sum_Fields>;
  var_pop?: Maybe<Admin_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_Items_Var_Samp_Fields>;
  variance?: Maybe<Admin_Items_Variance_Fields>;
};


/** aggregate fields of "admin_items" */
export type Admin_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_Items_Avg_Fields = {
  __typename?: 'admin_items_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "admin_items". All fields are combined with a logical 'AND'. */
export type Admin_Items_Bool_Exp = {
  _and?: Maybe<Array<Admin_Items_Bool_Exp>>;
  _not?: Maybe<Admin_Items_Bool_Exp>;
  _or?: Maybe<Array<Admin_Items_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Admintype_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin_items" */
export enum Admin_Items_Constraint {
  /** unique or primary key constraint */
  AdminItemsPkey = 'admin_items_pkey'
}

/** input type for incrementing numeric columns in table "admin_items" */
export type Admin_Items_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "admin_items" */
export type Admin_Items_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['admintype']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Admin_Items_Max_Fields = {
  __typename?: 'admin_items_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['admintype']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Admin_Items_Min_Fields = {
  __typename?: 'admin_items_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['admintype']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "admin_items" */
export type Admin_Items_Mutation_Response = {
  __typename?: 'admin_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_Items>;
};

/** on_conflict condition type for table "admin_items" */
export type Admin_Items_On_Conflict = {
  constraint: Admin_Items_Constraint;
  update_columns?: Array<Admin_Items_Update_Column>;
  where?: Maybe<Admin_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "admin_items". */
export type Admin_Items_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: admin_items */
export type Admin_Items_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "admin_items" */
export enum Admin_Items_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "admin_items" */
export type Admin_Items_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['admintype']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Admin_Items_Stddev_Fields = {
  __typename?: 'admin_items_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_Items_Stddev_Pop_Fields = {
  __typename?: 'admin_items_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_Items_Stddev_Samp_Fields = {
  __typename?: 'admin_items_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_Items_Sum_Fields = {
  __typename?: 'admin_items_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "admin_items" */
export enum Admin_Items_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Admin_Items_Var_Pop_Fields = {
  __typename?: 'admin_items_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_Items_Var_Samp_Fields = {
  __typename?: 'admin_items_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_Items_Variance_Fields = {
  __typename?: 'admin_items_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "admin_record" */
export type Admin_Record = {
  __typename?: 'admin_record';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "admin_record" */
export type Admin_Record_Aggregate = {
  __typename?: 'admin_record_aggregate';
  aggregate?: Maybe<Admin_Record_Aggregate_Fields>;
  nodes: Array<Admin_Record>;
};

/** aggregate fields of "admin_record" */
export type Admin_Record_Aggregate_Fields = {
  __typename?: 'admin_record_aggregate_fields';
  avg?: Maybe<Admin_Record_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_Record_Max_Fields>;
  min?: Maybe<Admin_Record_Min_Fields>;
  stddev?: Maybe<Admin_Record_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_Record_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_Record_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_Record_Sum_Fields>;
  var_pop?: Maybe<Admin_Record_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_Record_Var_Samp_Fields>;
  variance?: Maybe<Admin_Record_Variance_Fields>;
};


/** aggregate fields of "admin_record" */
export type Admin_Record_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_Record_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_Record_Avg_Fields = {
  __typename?: 'admin_record_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "admin_record". All fields are combined with a logical 'AND'. */
export type Admin_Record_Bool_Exp = {
  _and?: Maybe<Array<Admin_Record_Bool_Exp>>;
  _not?: Maybe<Admin_Record_Bool_Exp>;
  _or?: Maybe<Array<Admin_Record_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin_record" */
export enum Admin_Record_Constraint {
  /** unique or primary key constraint */
  AdminRecordPkey = 'admin_record_pkey'
}

/** input type for incrementing numeric columns in table "admin_record" */
export type Admin_Record_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "admin_record" */
export type Admin_Record_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Admin_Record_Max_Fields = {
  __typename?: 'admin_record_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Admin_Record_Min_Fields = {
  __typename?: 'admin_record_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "admin_record" */
export type Admin_Record_Mutation_Response = {
  __typename?: 'admin_record_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_Record>;
};

/** on_conflict condition type for table "admin_record" */
export type Admin_Record_On_Conflict = {
  constraint: Admin_Record_Constraint;
  update_columns?: Array<Admin_Record_Update_Column>;
  where?: Maybe<Admin_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "admin_record". */
export type Admin_Record_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: admin_record */
export type Admin_Record_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "admin_record" */
export enum Admin_Record_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "admin_record" */
export type Admin_Record_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Admin_Record_Stddev_Fields = {
  __typename?: 'admin_record_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_Record_Stddev_Pop_Fields = {
  __typename?: 'admin_record_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_Record_Stddev_Samp_Fields = {
  __typename?: 'admin_record_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_Record_Sum_Fields = {
  __typename?: 'admin_record_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "admin_record" */
export enum Admin_Record_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Admin_Record_Var_Pop_Fields = {
  __typename?: 'admin_record_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_Record_Var_Samp_Fields = {
  __typename?: 'admin_record_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_Record_Variance_Fields = {
  __typename?: 'admin_record_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "admintype". All fields are combined with logical 'AND'. */
export type Admintype_Comparison_Exp = {
  _eq?: Maybe<Scalars['admintype']>;
  _gt?: Maybe<Scalars['admintype']>;
  _gte?: Maybe<Scalars['admintype']>;
  _in?: Maybe<Array<Scalars['admintype']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['admintype']>;
  _lte?: Maybe<Scalars['admintype']>;
  _neq?: Maybe<Scalars['admintype']>;
  _nin?: Maybe<Array<Scalars['admintype']>>;
};

/** columns and relationships of "apartment" */
export type Apartment = {
  __typename?: 'apartment';
  available: Scalars['Boolean'];
  /** An object relationship */
  building?: Maybe<Buildings>;
  building_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  /** An array relationship */
  rentals: Array<Rental>;
  /** An aggregate relationship */
  rentals_aggregate: Rental_Aggregate;
  /** An array relationship */
  statement_accounts: Array<Statement_Account>;
  /** An aggregate relationship */
  statement_accounts_aggregate: Statement_Account_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
};


/** columns and relationships of "apartment" */
export type ApartmentRentalsArgs = {
  distinct_on?: Maybe<Array<Rental_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Order_By>>;
  where?: Maybe<Rental_Bool_Exp>;
};


/** columns and relationships of "apartment" */
export type ApartmentRentals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Order_By>>;
  where?: Maybe<Rental_Bool_Exp>;
};


/** columns and relationships of "apartment" */
export type ApartmentStatement_AccountsArgs = {
  distinct_on?: Maybe<Array<Statement_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Statement_Account_Order_By>>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};


/** columns and relationships of "apartment" */
export type ApartmentStatement_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Statement_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Statement_Account_Order_By>>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};


/** columns and relationships of "apartment" */
export type ApartmentVisitsArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};


/** columns and relationships of "apartment" */
export type ApartmentVisits_AggregateArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};

/** aggregated selection of "apartment" */
export type Apartment_Aggregate = {
  __typename?: 'apartment_aggregate';
  aggregate?: Maybe<Apartment_Aggregate_Fields>;
  nodes: Array<Apartment>;
};

/** aggregate fields of "apartment" */
export type Apartment_Aggregate_Fields = {
  __typename?: 'apartment_aggregate_fields';
  avg?: Maybe<Apartment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Apartment_Max_Fields>;
  min?: Maybe<Apartment_Min_Fields>;
  stddev?: Maybe<Apartment_Stddev_Fields>;
  stddev_pop?: Maybe<Apartment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apartment_Stddev_Samp_Fields>;
  sum?: Maybe<Apartment_Sum_Fields>;
  var_pop?: Maybe<Apartment_Var_Pop_Fields>;
  var_samp?: Maybe<Apartment_Var_Samp_Fields>;
  variance?: Maybe<Apartment_Variance_Fields>;
};


/** aggregate fields of "apartment" */
export type Apartment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Apartment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "apartment" */
export type Apartment_Aggregate_Order_By = {
  avg?: Maybe<Apartment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Apartment_Max_Order_By>;
  min?: Maybe<Apartment_Min_Order_By>;
  stddev?: Maybe<Apartment_Stddev_Order_By>;
  stddev_pop?: Maybe<Apartment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Apartment_Stddev_Samp_Order_By>;
  sum?: Maybe<Apartment_Sum_Order_By>;
  var_pop?: Maybe<Apartment_Var_Pop_Order_By>;
  var_samp?: Maybe<Apartment_Var_Samp_Order_By>;
  variance?: Maybe<Apartment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apartment" */
export type Apartment_Arr_Rel_Insert_Input = {
  data: Array<Apartment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Apartment_On_Conflict>;
};

/** aggregate avg on columns */
export type Apartment_Avg_Fields = {
  __typename?: 'apartment_avg_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "apartment" */
export type Apartment_Avg_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apartment". All fields are combined with a logical 'AND'. */
export type Apartment_Bool_Exp = {
  _and?: Maybe<Array<Apartment_Bool_Exp>>;
  _not?: Maybe<Apartment_Bool_Exp>;
  _or?: Maybe<Array<Apartment_Bool_Exp>>;
  available?: Maybe<Boolean_Comparison_Exp>;
  building?: Maybe<Buildings_Bool_Exp>;
  building_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  number?: Maybe<Int_Comparison_Exp>;
  rentals?: Maybe<Rental_Bool_Exp>;
  statement_accounts?: Maybe<Statement_Account_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
  visits?: Maybe<Visits_Bool_Exp>;
};

/** unique or primary key constraints on table "apartment" */
export enum Apartment_Constraint {
  /** unique or primary key constraint */
  ApartmentPkey = 'apartment_pkey'
}

/** input type for incrementing numeric columns in table "apartment" */
export type Apartment_Inc_Input = {
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "apartment" */
export type Apartment_Insert_Input = {
  available?: Maybe<Scalars['Boolean']>;
  building?: Maybe<Buildings_Obj_Rel_Insert_Input>;
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  rentals?: Maybe<Rental_Arr_Rel_Insert_Input>;
  statement_accounts?: Maybe<Statement_Account_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
  visits?: Maybe<Visits_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apartment_Max_Fields = {
  __typename?: 'apartment_max_fields';
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "apartment" */
export type Apartment_Max_Order_By = {
  building_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Apartment_Min_Fields = {
  __typename?: 'apartment_min_fields';
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "apartment" */
export type Apartment_Min_Order_By = {
  building_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "apartment" */
export type Apartment_Mutation_Response = {
  __typename?: 'apartment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Apartment>;
};

/** input type for inserting object relation for remote table "apartment" */
export type Apartment_Obj_Rel_Insert_Input = {
  data: Apartment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Apartment_On_Conflict>;
};

/** on_conflict condition type for table "apartment" */
export type Apartment_On_Conflict = {
  constraint: Apartment_Constraint;
  update_columns?: Array<Apartment_Update_Column>;
  where?: Maybe<Apartment_Bool_Exp>;
};

/** Ordering options when selecting data from "apartment". */
export type Apartment_Order_By = {
  available?: Maybe<Order_By>;
  building?: Maybe<Buildings_Order_By>;
  building_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  rentals_aggregate?: Maybe<Rental_Aggregate_Order_By>;
  statement_accounts_aggregate?: Maybe<Statement_Account_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  visits_aggregate?: Maybe<Visits_Aggregate_Order_By>;
};

/** primary key columns input for table: apartment */
export type Apartment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "apartment" */
export enum Apartment_Select_Column {
  /** column name */
  Available = 'available',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "apartment" */
export type Apartment_Set_Input = {
  available?: Maybe<Scalars['Boolean']>;
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Apartment_Stddev_Fields = {
  __typename?: 'apartment_stddev_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "apartment" */
export type Apartment_Stddev_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apartment_Stddev_Pop_Fields = {
  __typename?: 'apartment_stddev_pop_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "apartment" */
export type Apartment_Stddev_Pop_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apartment_Stddev_Samp_Fields = {
  __typename?: 'apartment_stddev_samp_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "apartment" */
export type Apartment_Stddev_Samp_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Apartment_Sum_Fields = {
  __typename?: 'apartment_sum_fields';
  building_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "apartment" */
export type Apartment_Sum_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "apartment" */
export enum Apartment_Update_Column {
  /** column name */
  Available = 'available',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Apartment_Var_Pop_Fields = {
  __typename?: 'apartment_var_pop_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "apartment" */
export type Apartment_Var_Pop_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apartment_Var_Samp_Fields = {
  __typename?: 'apartment_var_samp_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "apartment" */
export type Apartment_Var_Samp_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Apartment_Variance_Fields = {
  __typename?: 'apartment_variance_fields';
  building_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "apartment" */
export type Apartment_Variance_Order_By = {
  building_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "apartments_helper" */
export type Apartments_Helper = {
  __typename?: 'apartments_helper';
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "apartments_helper" */
export type Apartments_Helper_Aggregate = {
  __typename?: 'apartments_helper_aggregate';
  aggregate?: Maybe<Apartments_Helper_Aggregate_Fields>;
  nodes: Array<Apartments_Helper>;
};

/** aggregate fields of "apartments_helper" */
export type Apartments_Helper_Aggregate_Fields = {
  __typename?: 'apartments_helper_aggregate_fields';
  avg?: Maybe<Apartments_Helper_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Apartments_Helper_Max_Fields>;
  min?: Maybe<Apartments_Helper_Min_Fields>;
  stddev?: Maybe<Apartments_Helper_Stddev_Fields>;
  stddev_pop?: Maybe<Apartments_Helper_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apartments_Helper_Stddev_Samp_Fields>;
  sum?: Maybe<Apartments_Helper_Sum_Fields>;
  var_pop?: Maybe<Apartments_Helper_Var_Pop_Fields>;
  var_samp?: Maybe<Apartments_Helper_Var_Samp_Fields>;
  variance?: Maybe<Apartments_Helper_Variance_Fields>;
};


/** aggregate fields of "apartments_helper" */
export type Apartments_Helper_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Apartments_Helper_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Apartments_Helper_Avg_Fields = {
  __typename?: 'apartments_helper_avg_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "apartments_helper". All fields are combined with a logical 'AND'. */
export type Apartments_Helper_Bool_Exp = {
  _and?: Maybe<Array<Apartments_Helper_Bool_Exp>>;
  _not?: Maybe<Apartments_Helper_Bool_Exp>;
  _or?: Maybe<Array<Apartments_Helper_Bool_Exp>>;
  apartment_id?: Maybe<Int_Comparison_Exp>;
  apartment_name?: Maybe<String_Comparison_Exp>;
  available?: Maybe<Boolean_Comparison_Exp>;
  balance?: Maybe<Numeric_Comparison_Exp>;
  balance_retained?: Maybe<Numeric_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  number?: Maybe<Int_Comparison_Exp>;
  owner?: Maybe<String_Comparison_Exp>;
  owner_id?: Maybe<Int_Comparison_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Apartments_Helper_Max_Fields = {
  __typename?: 'apartments_helper_max_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Apartments_Helper_Min_Fields = {
  __typename?: 'apartments_helper_min_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "apartments_helper". */
export type Apartments_Helper_Order_By = {
  apartment_id?: Maybe<Order_By>;
  apartment_name?: Maybe<Order_By>;
  available?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  balance_retained?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  owner?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** select columns of table "apartments_helper" */
export enum Apartments_Helper_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  ApartmentName = 'apartment_name',
  /** column name */
  Available = 'available',
  /** column name */
  Balance = 'balance',
  /** column name */
  BalanceRetained = 'balance_retained',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  Owner = 'owner',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  StatementAccountId = 'statement_account_id'
}

/** aggregate stddev on columns */
export type Apartments_Helper_Stddev_Fields = {
  __typename?: 'apartments_helper_stddev_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Apartments_Helper_Stddev_Pop_Fields = {
  __typename?: 'apartments_helper_stddev_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Apartments_Helper_Stddev_Samp_Fields = {
  __typename?: 'apartments_helper_stddev_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Apartments_Helper_Sum_Fields = {
  __typename?: 'apartments_helper_sum_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  number?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Apartments_Helper_Var_Pop_Fields = {
  __typename?: 'apartments_helper_var_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Apartments_Helper_Var_Samp_Fields = {
  __typename?: 'apartments_helper_var_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Apartments_Helper_Variance_Fields = {
  __typename?: 'apartments_helper_variance_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "appointments" */
export type Appointments = {
  __typename?: 'appointments';
  activity: Scalars['String'];
  appointment_length: Scalars['Int'];
  /** An object relationship */
  common_area: Common_Areas;
  common_area_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  datetime_scheduled?: Maybe<Scalars['timestamp']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "appointments" */
export type Appointments_Aggregate = {
  __typename?: 'appointments_aggregate';
  aggregate?: Maybe<Appointments_Aggregate_Fields>;
  nodes: Array<Appointments>;
};

/** aggregate fields of "appointments" */
export type Appointments_Aggregate_Fields = {
  __typename?: 'appointments_aggregate_fields';
  avg?: Maybe<Appointments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Appointments_Max_Fields>;
  min?: Maybe<Appointments_Min_Fields>;
  stddev?: Maybe<Appointments_Stddev_Fields>;
  stddev_pop?: Maybe<Appointments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appointments_Stddev_Samp_Fields>;
  sum?: Maybe<Appointments_Sum_Fields>;
  var_pop?: Maybe<Appointments_Var_Pop_Fields>;
  var_samp?: Maybe<Appointments_Var_Samp_Fields>;
  variance?: Maybe<Appointments_Variance_Fields>;
};


/** aggregate fields of "appointments" */
export type Appointments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Appointments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointments" */
export type Appointments_Aggregate_Order_By = {
  avg?: Maybe<Appointments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Appointments_Max_Order_By>;
  min?: Maybe<Appointments_Min_Order_By>;
  stddev?: Maybe<Appointments_Stddev_Order_By>;
  stddev_pop?: Maybe<Appointments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Appointments_Stddev_Samp_Order_By>;
  sum?: Maybe<Appointments_Sum_Order_By>;
  var_pop?: Maybe<Appointments_Var_Pop_Order_By>;
  var_samp?: Maybe<Appointments_Var_Samp_Order_By>;
  variance?: Maybe<Appointments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "appointments" */
export type Appointments_Arr_Rel_Insert_Input = {
  data: Array<Appointments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Appointments_On_Conflict>;
};

/** aggregate avg on columns */
export type Appointments_Avg_Fields = {
  __typename?: 'appointments_avg_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "appointments" */
export type Appointments_Avg_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointments". All fields are combined with a logical 'AND'. */
export type Appointments_Bool_Exp = {
  _and?: Maybe<Array<Appointments_Bool_Exp>>;
  _not?: Maybe<Appointments_Bool_Exp>;
  _or?: Maybe<Array<Appointments_Bool_Exp>>;
  activity?: Maybe<String_Comparison_Exp>;
  appointment_length?: Maybe<Int_Comparison_Exp>;
  common_area?: Maybe<Common_Areas_Bool_Exp>;
  common_area_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Int_Comparison_Exp>;
  datetime_scheduled?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointments" */
export enum Appointments_Constraint {
  /** unique or primary key constraint */
  AppointmentsPkey = 'appointments_pkey'
}

/** input type for incrementing numeric columns in table "appointments" */
export type Appointments_Inc_Input = {
  appointment_length?: Maybe<Scalars['Int']>;
  common_area_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "appointments" */
export type Appointments_Insert_Input = {
  activity?: Maybe<Scalars['String']>;
  appointment_length?: Maybe<Scalars['Int']>;
  common_area?: Maybe<Common_Areas_Obj_Rel_Insert_Input>;
  common_area_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  datetime_scheduled?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Appointments_Max_Fields = {
  __typename?: 'appointments_max_fields';
  activity?: Maybe<Scalars['String']>;
  appointment_length?: Maybe<Scalars['Int']>;
  common_area_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  datetime_scheduled?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointments" */
export type Appointments_Max_Order_By = {
  activity?: Maybe<Order_By>;
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datetime_scheduled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Appointments_Min_Fields = {
  __typename?: 'appointments_min_fields';
  activity?: Maybe<Scalars['String']>;
  appointment_length?: Maybe<Scalars['Int']>;
  common_area_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  datetime_scheduled?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointments" */
export type Appointments_Min_Order_By = {
  activity?: Maybe<Order_By>;
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datetime_scheduled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "appointments" */
export type Appointments_Mutation_Response = {
  __typename?: 'appointments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointments>;
};

/** on_conflict condition type for table "appointments" */
export type Appointments_On_Conflict = {
  constraint: Appointments_Constraint;
  update_columns?: Array<Appointments_Update_Column>;
  where?: Maybe<Appointments_Bool_Exp>;
};

/** Ordering options when selecting data from "appointments". */
export type Appointments_Order_By = {
  activity?: Maybe<Order_By>;
  appointment_length?: Maybe<Order_By>;
  common_area?: Maybe<Common_Areas_Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datetime_scheduled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: appointments */
export type Appointments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "appointments" */
export enum Appointments_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  AppointmentLength = 'appointment_length',
  /** column name */
  CommonAreaId = 'common_area_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DatetimeScheduled = 'datetime_scheduled',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "appointments" */
export type Appointments_Set_Input = {
  activity?: Maybe<Scalars['String']>;
  appointment_length?: Maybe<Scalars['Int']>;
  common_area_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  datetime_scheduled?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Appointments_Stddev_Fields = {
  __typename?: 'appointments_stddev_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "appointments" */
export type Appointments_Stddev_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Appointments_Stddev_Pop_Fields = {
  __typename?: 'appointments_stddev_pop_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "appointments" */
export type Appointments_Stddev_Pop_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Appointments_Stddev_Samp_Fields = {
  __typename?: 'appointments_stddev_samp_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "appointments" */
export type Appointments_Stddev_Samp_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Appointments_Sum_Fields = {
  __typename?: 'appointments_sum_fields';
  appointment_length?: Maybe<Scalars['Int']>;
  common_area_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "appointments" */
export type Appointments_Sum_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "appointments" */
export enum Appointments_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  AppointmentLength = 'appointment_length',
  /** column name */
  CommonAreaId = 'common_area_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DatetimeScheduled = 'datetime_scheduled',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Appointments_Var_Pop_Fields = {
  __typename?: 'appointments_var_pop_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "appointments" */
export type Appointments_Var_Pop_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Appointments_Var_Samp_Fields = {
  __typename?: 'appointments_var_samp_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "appointments" */
export type Appointments_Var_Samp_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Appointments_Variance_Fields = {
  __typename?: 'appointments_variance_fields';
  appointment_length?: Maybe<Scalars['Float']>;
  common_area_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "appointments" */
export type Appointments_Variance_Order_By = {
  appointment_length?: Maybe<Order_By>;
  common_area_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "arrivals" */
export type Arrivals = {
  __typename?: 'arrivals';
  arrival_date: Scalars['date'];
  arrived_in: Scalars['String'];
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  car_plates?: Maybe<Scalars['String']>;
  comments: Scalars['String'];
  created_at: Scalars['timestamptz'];
  departure_date: Scalars['date'];
  final_departure_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  people_number: Scalars['Int'];
  /** An object relationship */
  rental?: Maybe<Rental>;
  rental_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "arrivals" */
export type Arrivals_Aggregate = {
  __typename?: 'arrivals_aggregate';
  aggregate?: Maybe<Arrivals_Aggregate_Fields>;
  nodes: Array<Arrivals>;
};

/** aggregate fields of "arrivals" */
export type Arrivals_Aggregate_Fields = {
  __typename?: 'arrivals_aggregate_fields';
  avg?: Maybe<Arrivals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Arrivals_Max_Fields>;
  min?: Maybe<Arrivals_Min_Fields>;
  stddev?: Maybe<Arrivals_Stddev_Fields>;
  stddev_pop?: Maybe<Arrivals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Arrivals_Stddev_Samp_Fields>;
  sum?: Maybe<Arrivals_Sum_Fields>;
  var_pop?: Maybe<Arrivals_Var_Pop_Fields>;
  var_samp?: Maybe<Arrivals_Var_Samp_Fields>;
  variance?: Maybe<Arrivals_Variance_Fields>;
};


/** aggregate fields of "arrivals" */
export type Arrivals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Arrivals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "arrivals" */
export type Arrivals_Aggregate_Order_By = {
  avg?: Maybe<Arrivals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Arrivals_Max_Order_By>;
  min?: Maybe<Arrivals_Min_Order_By>;
  stddev?: Maybe<Arrivals_Stddev_Order_By>;
  stddev_pop?: Maybe<Arrivals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Arrivals_Stddev_Samp_Order_By>;
  sum?: Maybe<Arrivals_Sum_Order_By>;
  var_pop?: Maybe<Arrivals_Var_Pop_Order_By>;
  var_samp?: Maybe<Arrivals_Var_Samp_Order_By>;
  variance?: Maybe<Arrivals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "arrivals" */
export type Arrivals_Arr_Rel_Insert_Input = {
  data: Array<Arrivals_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Arrivals_On_Conflict>;
};

/** aggregate avg on columns */
export type Arrivals_Avg_Fields = {
  __typename?: 'arrivals_avg_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "arrivals" */
export type Arrivals_Avg_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "arrivals". All fields are combined with a logical 'AND'. */
export type Arrivals_Bool_Exp = {
  _and?: Maybe<Array<Arrivals_Bool_Exp>>;
  _not?: Maybe<Arrivals_Bool_Exp>;
  _or?: Maybe<Array<Arrivals_Bool_Exp>>;
  arrival_date?: Maybe<Date_Comparison_Exp>;
  arrived_in?: Maybe<String_Comparison_Exp>;
  car_color?: Maybe<String_Comparison_Exp>;
  car_description?: Maybe<String_Comparison_Exp>;
  car_plates?: Maybe<String_Comparison_Exp>;
  comments?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  departure_date?: Maybe<Date_Comparison_Exp>;
  final_departure_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  people_number?: Maybe<Int_Comparison_Exp>;
  rental?: Maybe<Rental_Bool_Exp>;
  rental_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "arrivals" */
export enum Arrivals_Constraint {
  /** unique or primary key constraint */
  ArrivalsPkey = 'arrivals_pkey'
}

/** input type for incrementing numeric columns in table "arrivals" */
export type Arrivals_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  rental_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "arrivals" */
export type Arrivals_Insert_Input = {
  arrival_date?: Maybe<Scalars['date']>;
  arrived_in?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  car_plates?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['date']>;
  final_departure_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  rental?: Maybe<Rental_Obj_Rel_Insert_Input>;
  rental_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Arrivals_Max_Fields = {
  __typename?: 'arrivals_max_fields';
  arrival_date?: Maybe<Scalars['date']>;
  arrived_in?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  car_plates?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['date']>;
  final_departure_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  rental_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "arrivals" */
export type Arrivals_Max_Order_By = {
  arrival_date?: Maybe<Order_By>;
  arrived_in?: Maybe<Order_By>;
  car_color?: Maybe<Order_By>;
  car_description?: Maybe<Order_By>;
  car_plates?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  final_departure_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Arrivals_Min_Fields = {
  __typename?: 'arrivals_min_fields';
  arrival_date?: Maybe<Scalars['date']>;
  arrived_in?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  car_plates?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['date']>;
  final_departure_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  rental_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "arrivals" */
export type Arrivals_Min_Order_By = {
  arrival_date?: Maybe<Order_By>;
  arrived_in?: Maybe<Order_By>;
  car_color?: Maybe<Order_By>;
  car_description?: Maybe<Order_By>;
  car_plates?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  final_departure_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "arrivals" */
export type Arrivals_Mutation_Response = {
  __typename?: 'arrivals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Arrivals>;
};

/** on_conflict condition type for table "arrivals" */
export type Arrivals_On_Conflict = {
  constraint: Arrivals_Constraint;
  update_columns?: Array<Arrivals_Update_Column>;
  where?: Maybe<Arrivals_Bool_Exp>;
};

/** Ordering options when selecting data from "arrivals". */
export type Arrivals_Order_By = {
  arrival_date?: Maybe<Order_By>;
  arrived_in?: Maybe<Order_By>;
  car_color?: Maybe<Order_By>;
  car_description?: Maybe<Order_By>;
  car_plates?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  final_departure_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental?: Maybe<Rental_Order_By>;
  rental_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: arrivals */
export type Arrivals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "arrivals" */
export enum Arrivals_Select_Column {
  /** column name */
  ArrivalDate = 'arrival_date',
  /** column name */
  ArrivedIn = 'arrived_in',
  /** column name */
  CarColor = 'car_color',
  /** column name */
  CarDescription = 'car_description',
  /** column name */
  CarPlates = 'car_plates',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  FinalDepartureDate = 'final_departure_date',
  /** column name */
  Id = 'id',
  /** column name */
  PeopleNumber = 'people_number',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "arrivals" */
export type Arrivals_Set_Input = {
  arrival_date?: Maybe<Scalars['date']>;
  arrived_in?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  car_plates?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['date']>;
  final_departure_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  rental_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Arrivals_Stddev_Fields = {
  __typename?: 'arrivals_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "arrivals" */
export type Arrivals_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Arrivals_Stddev_Pop_Fields = {
  __typename?: 'arrivals_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "arrivals" */
export type Arrivals_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Arrivals_Stddev_Samp_Fields = {
  __typename?: 'arrivals_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "arrivals" */
export type Arrivals_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Arrivals_Sum_Fields = {
  __typename?: 'arrivals_sum_fields';
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  rental_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "arrivals" */
export type Arrivals_Sum_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "arrivals" */
export enum Arrivals_Update_Column {
  /** column name */
  ArrivalDate = 'arrival_date',
  /** column name */
  ArrivedIn = 'arrived_in',
  /** column name */
  CarColor = 'car_color',
  /** column name */
  CarDescription = 'car_description',
  /** column name */
  CarPlates = 'car_plates',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  FinalDepartureDate = 'final_departure_date',
  /** column name */
  Id = 'id',
  /** column name */
  PeopleNumber = 'people_number',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Arrivals_Var_Pop_Fields = {
  __typename?: 'arrivals_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "arrivals" */
export type Arrivals_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Arrivals_Var_Samp_Fields = {
  __typename?: 'arrivals_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "arrivals" */
export type Arrivals_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Arrivals_Variance_Fields = {
  __typename?: 'arrivals_variance_fields';
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "arrivals" */
export type Arrivals_Variance_Order_By = {
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "balances_report" */
export type Balances_Report = {
  __typename?: 'balances_report';
  balance?: Maybe<Scalars['numeric']>;
  commensal_name?: Maybe<Scalars['String']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "balances_report" */
export type Balances_Report_Aggregate = {
  __typename?: 'balances_report_aggregate';
  aggregate?: Maybe<Balances_Report_Aggregate_Fields>;
  nodes: Array<Balances_Report>;
};

/** aggregate fields of "balances_report" */
export type Balances_Report_Aggregate_Fields = {
  __typename?: 'balances_report_aggregate_fields';
  avg?: Maybe<Balances_Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Balances_Report_Max_Fields>;
  min?: Maybe<Balances_Report_Min_Fields>;
  stddev?: Maybe<Balances_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Balances_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Balances_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Balances_Report_Sum_Fields>;
  var_pop?: Maybe<Balances_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Balances_Report_Var_Samp_Fields>;
  variance?: Maybe<Balances_Report_Variance_Fields>;
};


/** aggregate fields of "balances_report" */
export type Balances_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Balances_Report_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Balances_Report_Avg_Fields = {
  __typename?: 'balances_report_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "balances_report". All fields are combined with a logical 'AND'. */
export type Balances_Report_Bool_Exp = {
  _and?: Maybe<Array<Balances_Report_Bool_Exp>>;
  _not?: Maybe<Balances_Report_Bool_Exp>;
  _or?: Maybe<Array<Balances_Report_Bool_Exp>>;
  balance?: Maybe<Numeric_Comparison_Exp>;
  commensal_name?: Maybe<String_Comparison_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Balances_Report_Max_Fields = {
  __typename?: 'balances_report_max_fields';
  balance?: Maybe<Scalars['numeric']>;
  commensal_name?: Maybe<Scalars['String']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Balances_Report_Min_Fields = {
  __typename?: 'balances_report_min_fields';
  balance?: Maybe<Scalars['numeric']>;
  commensal_name?: Maybe<Scalars['String']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "balances_report". */
export type Balances_Report_Order_By = {
  balance?: Maybe<Order_By>;
  commensal_name?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** select columns of table "balances_report" */
export enum Balances_Report_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  CommensalName = 'commensal_name',
  /** column name */
  StatementAccountId = 'statement_account_id'
}

/** aggregate stddev on columns */
export type Balances_Report_Stddev_Fields = {
  __typename?: 'balances_report_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Balances_Report_Stddev_Pop_Fields = {
  __typename?: 'balances_report_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Balances_Report_Stddev_Samp_Fields = {
  __typename?: 'balances_report_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Balances_Report_Sum_Fields = {
  __typename?: 'balances_report_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Balances_Report_Var_Pop_Fields = {
  __typename?: 'balances_report_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Balances_Report_Var_Samp_Fields = {
  __typename?: 'balances_report_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Balances_Report_Variance_Fields = {
  __typename?: 'balances_report_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
export type Bit_Comparison_Exp = {
  _eq?: Maybe<Scalars['bit']>;
  _gt?: Maybe<Scalars['bit']>;
  _gte?: Maybe<Scalars['bit']>;
  _in?: Maybe<Array<Scalars['bit']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bit']>;
  _lte?: Maybe<Scalars['bit']>;
  _neq?: Maybe<Scalars['bit']>;
  _nin?: Maybe<Array<Scalars['bit']>>;
};

/** columns and relationships of "blog" */
export type Blog = {
  __typename?: 'blog';
  Deshabilitado: Scalars['bit'];
  /** An object relationship */
  blog_type: Blog_Types;
  blog_type_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted: Scalars['Boolean'];
  description: Scalars['String'];
  english: Scalars['bit'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  sended: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "blog" */
export type Blog_Aggregate = {
  __typename?: 'blog_aggregate';
  aggregate?: Maybe<Blog_Aggregate_Fields>;
  nodes: Array<Blog>;
};

/** aggregate fields of "blog" */
export type Blog_Aggregate_Fields = {
  __typename?: 'blog_aggregate_fields';
  avg?: Maybe<Blog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Blog_Max_Fields>;
  min?: Maybe<Blog_Min_Fields>;
  stddev?: Maybe<Blog_Stddev_Fields>;
  stddev_pop?: Maybe<Blog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blog_Stddev_Samp_Fields>;
  sum?: Maybe<Blog_Sum_Fields>;
  var_pop?: Maybe<Blog_Var_Pop_Fields>;
  var_samp?: Maybe<Blog_Var_Samp_Fields>;
  variance?: Maybe<Blog_Variance_Fields>;
};


/** aggregate fields of "blog" */
export type Blog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog" */
export type Blog_Aggregate_Order_By = {
  avg?: Maybe<Blog_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Blog_Max_Order_By>;
  min?: Maybe<Blog_Min_Order_By>;
  stddev?: Maybe<Blog_Stddev_Order_By>;
  stddev_pop?: Maybe<Blog_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Blog_Stddev_Samp_Order_By>;
  sum?: Maybe<Blog_Sum_Order_By>;
  var_pop?: Maybe<Blog_Var_Pop_Order_By>;
  var_samp?: Maybe<Blog_Var_Samp_Order_By>;
  variance?: Maybe<Blog_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "blog" */
export type Blog_Arr_Rel_Insert_Input = {
  data: Array<Blog_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Blog_On_Conflict>;
};

/** aggregate avg on columns */
export type Blog_Avg_Fields = {
  __typename?: 'blog_avg_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blog" */
export type Blog_Avg_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
export type Blog_Bool_Exp = {
  Deshabilitado?: Maybe<Bit_Comparison_Exp>;
  _and?: Maybe<Array<Blog_Bool_Exp>>;
  _not?: Maybe<Blog_Bool_Exp>;
  _or?: Maybe<Array<Blog_Bool_Exp>>;
  blog_type?: Maybe<Blog_Types_Bool_Exp>;
  blog_type_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Int_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  english?: Maybe<Bit_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  sended?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "blog" */
export enum Blog_Constraint {
  /** unique or primary key constraint */
  BlogIdKey = 'blog_id_key',
  /** unique or primary key constraint */
  BlogPkey = 'blog_pkey'
}

/** input type for incrementing numeric columns in table "blog" */
export type Blog_Inc_Input = {
  blog_type_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "blog" */
export type Blog_Insert_Input = {
  Deshabilitado?: Maybe<Scalars['bit']>;
  blog_type?: Maybe<Blog_Types_Obj_Rel_Insert_Input>;
  blog_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  english?: Maybe<Scalars['bit']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sended?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Blog_Max_Fields = {
  __typename?: 'blog_max_fields';
  blog_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "blog" */
export type Blog_Max_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Min_Fields = {
  __typename?: 'blog_min_fields';
  blog_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "blog" */
export type Blog_Min_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "blog" */
export type Blog_Mutation_Response = {
  __typename?: 'blog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog>;
};

/** on_conflict condition type for table "blog" */
export type Blog_On_Conflict = {
  constraint: Blog_Constraint;
  update_columns?: Array<Blog_Update_Column>;
  where?: Maybe<Blog_Bool_Exp>;
};

/** Ordering options when selecting data from "blog". */
export type Blog_Order_By = {
  Deshabilitado?: Maybe<Order_By>;
  blog_type?: Maybe<Blog_Types_Order_By>;
  blog_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  english?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sended?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: blog */
export type Blog_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blog" */
export enum Blog_Select_Column {
  /** column name */
  Deshabilitado = 'Deshabilitado',
  /** column name */
  BlogTypeId = 'blog_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  English = 'english',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Sended = 'sended',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blog" */
export type Blog_Set_Input = {
  Deshabilitado?: Maybe<Scalars['bit']>;
  blog_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  english?: Maybe<Scalars['bit']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sended?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Blog_Stddev_Fields = {
  __typename?: 'blog_stddev_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blog" */
export type Blog_Stddev_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blog_Stddev_Pop_Fields = {
  __typename?: 'blog_stddev_pop_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blog" */
export type Blog_Stddev_Pop_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blog_Stddev_Samp_Fields = {
  __typename?: 'blog_stddev_samp_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blog" */
export type Blog_Stddev_Samp_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Blog_Sum_Fields = {
  __typename?: 'blog_sum_fields';
  blog_type_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "blog" */
export type Blog_Sum_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "blog_types" */
export type Blog_Types = {
  __typename?: 'blog_types';
  /** An array relationship */
  blogs: Array<Blog>;
  /** An aggregate relationship */
  blogs_aggregate: Blog_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "blog_types" */
export type Blog_TypesBlogsArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


/** columns and relationships of "blog_types" */
export type Blog_TypesBlogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};

/** aggregated selection of "blog_types" */
export type Blog_Types_Aggregate = {
  __typename?: 'blog_types_aggregate';
  aggregate?: Maybe<Blog_Types_Aggregate_Fields>;
  nodes: Array<Blog_Types>;
};

/** aggregate fields of "blog_types" */
export type Blog_Types_Aggregate_Fields = {
  __typename?: 'blog_types_aggregate_fields';
  avg?: Maybe<Blog_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Blog_Types_Max_Fields>;
  min?: Maybe<Blog_Types_Min_Fields>;
  stddev?: Maybe<Blog_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Blog_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blog_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Blog_Types_Sum_Fields>;
  var_pop?: Maybe<Blog_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Blog_Types_Var_Samp_Fields>;
  variance?: Maybe<Blog_Types_Variance_Fields>;
};


/** aggregate fields of "blog_types" */
export type Blog_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Blog_Types_Avg_Fields = {
  __typename?: 'blog_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "blog_types". All fields are combined with a logical 'AND'. */
export type Blog_Types_Bool_Exp = {
  _and?: Maybe<Array<Blog_Types_Bool_Exp>>;
  _not?: Maybe<Blog_Types_Bool_Exp>;
  _or?: Maybe<Array<Blog_Types_Bool_Exp>>;
  blogs?: Maybe<Blog_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog_types" */
export enum Blog_Types_Constraint {
  /** unique or primary key constraint */
  BlogTypesPkey = 'blog_types_pkey'
}

/** input type for incrementing numeric columns in table "blog_types" */
export type Blog_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "blog_types" */
export type Blog_Types_Insert_Input = {
  blogs?: Maybe<Blog_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Blog_Types_Max_Fields = {
  __typename?: 'blog_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Blog_Types_Min_Fields = {
  __typename?: 'blog_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "blog_types" */
export type Blog_Types_Mutation_Response = {
  __typename?: 'blog_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog_Types>;
};

/** input type for inserting object relation for remote table "blog_types" */
export type Blog_Types_Obj_Rel_Insert_Input = {
  data: Blog_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Blog_Types_On_Conflict>;
};

/** on_conflict condition type for table "blog_types" */
export type Blog_Types_On_Conflict = {
  constraint: Blog_Types_Constraint;
  update_columns?: Array<Blog_Types_Update_Column>;
  where?: Maybe<Blog_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "blog_types". */
export type Blog_Types_Order_By = {
  blogs_aggregate?: Maybe<Blog_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: blog_types */
export type Blog_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blog_types" */
export enum Blog_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blog_types" */
export type Blog_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Blog_Types_Stddev_Fields = {
  __typename?: 'blog_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Blog_Types_Stddev_Pop_Fields = {
  __typename?: 'blog_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Blog_Types_Stddev_Samp_Fields = {
  __typename?: 'blog_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Blog_Types_Sum_Fields = {
  __typename?: 'blog_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "blog_types" */
export enum Blog_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Blog_Types_Var_Pop_Fields = {
  __typename?: 'blog_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Blog_Types_Var_Samp_Fields = {
  __typename?: 'blog_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Blog_Types_Variance_Fields = {
  __typename?: 'blog_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "blog" */
export enum Blog_Update_Column {
  /** column name */
  Deshabilitado = 'Deshabilitado',
  /** column name */
  BlogTypeId = 'blog_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  English = 'english',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Sended = 'sended',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Blog_Var_Pop_Fields = {
  __typename?: 'blog_var_pop_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blog" */
export type Blog_Var_Pop_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blog_Var_Samp_Fields = {
  __typename?: 'blog_var_samp_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blog" */
export type Blog_Var_Samp_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Blog_Variance_Fields = {
  __typename?: 'blog_variance_fields';
  blog_type_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blog" */
export type Blog_Variance_Order_By = {
  blog_type_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "buildings" */
export type Buildings = {
  __typename?: 'buildings';
  /** An array relationship */
  apartments: Array<Apartment>;
  /** An aggregate relationship */
  apartments_aggregate: Apartment_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "buildings" */
export type BuildingsApartmentsArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


/** columns and relationships of "buildings" */
export type BuildingsApartments_AggregateArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};

/** aggregated selection of "buildings" */
export type Buildings_Aggregate = {
  __typename?: 'buildings_aggregate';
  aggregate?: Maybe<Buildings_Aggregate_Fields>;
  nodes: Array<Buildings>;
};

/** aggregate fields of "buildings" */
export type Buildings_Aggregate_Fields = {
  __typename?: 'buildings_aggregate_fields';
  avg?: Maybe<Buildings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Buildings_Max_Fields>;
  min?: Maybe<Buildings_Min_Fields>;
  stddev?: Maybe<Buildings_Stddev_Fields>;
  stddev_pop?: Maybe<Buildings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Buildings_Stddev_Samp_Fields>;
  sum?: Maybe<Buildings_Sum_Fields>;
  var_pop?: Maybe<Buildings_Var_Pop_Fields>;
  var_samp?: Maybe<Buildings_Var_Samp_Fields>;
  variance?: Maybe<Buildings_Variance_Fields>;
};


/** aggregate fields of "buildings" */
export type Buildings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Buildings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Buildings_Avg_Fields = {
  __typename?: 'buildings_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "buildings". All fields are combined with a logical 'AND'. */
export type Buildings_Bool_Exp = {
  _and?: Maybe<Array<Buildings_Bool_Exp>>;
  _not?: Maybe<Buildings_Bool_Exp>;
  _or?: Maybe<Array<Buildings_Bool_Exp>>;
  apartments?: Maybe<Apartment_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "buildings" */
export enum Buildings_Constraint {
  /** unique or primary key constraint */
  BuildingsPkey = 'buildings_pkey'
}

/** input type for incrementing numeric columns in table "buildings" */
export type Buildings_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "buildings" */
export type Buildings_Insert_Input = {
  apartments?: Maybe<Apartment_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Buildings_Max_Fields = {
  __typename?: 'buildings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Buildings_Min_Fields = {
  __typename?: 'buildings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "buildings" */
export type Buildings_Mutation_Response = {
  __typename?: 'buildings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Buildings>;
};

/** input type for inserting object relation for remote table "buildings" */
export type Buildings_Obj_Rel_Insert_Input = {
  data: Buildings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Buildings_On_Conflict>;
};

/** on_conflict condition type for table "buildings" */
export type Buildings_On_Conflict = {
  constraint: Buildings_Constraint;
  update_columns?: Array<Buildings_Update_Column>;
  where?: Maybe<Buildings_Bool_Exp>;
};

/** Ordering options when selecting data from "buildings". */
export type Buildings_Order_By = {
  apartments_aggregate?: Maybe<Apartment_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: buildings */
export type Buildings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "buildings" */
export enum Buildings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "buildings" */
export type Buildings_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Buildings_Stddev_Fields = {
  __typename?: 'buildings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Buildings_Stddev_Pop_Fields = {
  __typename?: 'buildings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Buildings_Stddev_Samp_Fields = {
  __typename?: 'buildings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Buildings_Sum_Fields = {
  __typename?: 'buildings_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "buildings" */
export enum Buildings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Buildings_Var_Pop_Fields = {
  __typename?: 'buildings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Buildings_Var_Samp_Fields = {
  __typename?: 'buildings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Buildings_Variance_Fields = {
  __typename?: 'buildings_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  created_at: Scalars['timestamptz'];
  disabled: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An object relationship */
  menu_type: Menu_Types;
  menu_type_id: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  /** An array relationship */
  subcategories: Array<Subcategories>;
  /** An aggregate relationship */
  subcategories_aggregate: Subcategories_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "categories" */
export type CategoriesSubcategoriesArgs = {
  distinct_on?: Maybe<Array<Subcategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcategories_Order_By>>;
  where?: Maybe<Subcategories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesSubcategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Subcategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcategories_Order_By>>;
  where?: Maybe<Subcategories_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  avg?: Maybe<Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
  stddev?: Maybe<Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Categories_Sum_Fields>;
  var_pop?: Maybe<Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Categories_Var_Samp_Fields>;
  variance?: Maybe<Categories_Variance_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  avg?: Maybe<Categories_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Categories_Max_Order_By>;
  min?: Maybe<Categories_Min_Order_By>;
  stddev?: Maybe<Categories_Stddev_Order_By>;
  stddev_pop?: Maybe<Categories_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Categories_Stddev_Samp_Order_By>;
  sum?: Maybe<Categories_Sum_Order_By>;
  var_pop?: Maybe<Categories_Var_Pop_Order_By>;
  var_samp?: Maybe<Categories_Var_Samp_Order_By>;
  variance?: Maybe<Categories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** aggregate avg on columns */
export type Categories_Avg_Fields = {
  __typename?: 'categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "categories" */
export type Categories_Avg_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: Maybe<Array<Categories_Bool_Exp>>;
  _not?: Maybe<Categories_Bool_Exp>;
  _or?: Maybe<Array<Categories_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  menu_type?: Maybe<Menu_Types_Bool_Exp>;
  menu_type_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  subcategories?: Maybe<Subcategories_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint */
  CategoriesPkey = 'categories_pkey'
}

/** input type for incrementing numeric columns in table "categories" */
export type Categories_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  menu_type_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  menu_type?: Maybe<Menu_Types_Obj_Rel_Insert_Input>;
  menu_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  subcategories?: Maybe<Subcategories_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  menu_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  menu_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: Maybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  menu_type?: Maybe<Menu_Types_Order_By>;
  menu_type_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  subcategories_aggregate?: Maybe<Subcategories_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  MenuTypeId = 'menu_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  menu_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Categories_Stddev_Fields = {
  __typename?: 'categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "categories" */
export type Categories_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Categories_Stddev_Pop_Fields = {
  __typename?: 'categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "categories" */
export type Categories_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Categories_Stddev_Samp_Fields = {
  __typename?: 'categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "categories" */
export type Categories_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Categories_Sum_Fields = {
  __typename?: 'categories_sum_fields';
  id?: Maybe<Scalars['Int']>;
  menu_type_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "categories" */
export type Categories_Sum_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  MenuTypeId = 'menu_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Categories_Var_Pop_Fields = {
  __typename?: 'categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "categories" */
export type Categories_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Categories_Var_Samp_Fields = {
  __typename?: 'categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "categories" */
export type Categories_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Categories_Variance_Fields = {
  __typename?: 'categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
  menu_type_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "categories" */
export type Categories_Variance_Order_By = {
  id?: Maybe<Order_By>;
  menu_type_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** columns and relationships of "common_area_types" */
export type Common_Area_Types = {
  __typename?: 'common_area_types';
  /** An array relationship */
  common_areas: Array<Common_Areas>;
  /** An aggregate relationship */
  common_areas_aggregate: Common_Areas_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "common_area_types" */
export type Common_Area_TypesCommon_AreasArgs = {
  distinct_on?: Maybe<Array<Common_Areas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Areas_Order_By>>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};


/** columns and relationships of "common_area_types" */
export type Common_Area_TypesCommon_Areas_AggregateArgs = {
  distinct_on?: Maybe<Array<Common_Areas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Areas_Order_By>>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};

/** aggregated selection of "common_area_types" */
export type Common_Area_Types_Aggregate = {
  __typename?: 'common_area_types_aggregate';
  aggregate?: Maybe<Common_Area_Types_Aggregate_Fields>;
  nodes: Array<Common_Area_Types>;
};

/** aggregate fields of "common_area_types" */
export type Common_Area_Types_Aggregate_Fields = {
  __typename?: 'common_area_types_aggregate_fields';
  avg?: Maybe<Common_Area_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Common_Area_Types_Max_Fields>;
  min?: Maybe<Common_Area_Types_Min_Fields>;
  stddev?: Maybe<Common_Area_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Common_Area_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Common_Area_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Common_Area_Types_Sum_Fields>;
  var_pop?: Maybe<Common_Area_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Common_Area_Types_Var_Samp_Fields>;
  variance?: Maybe<Common_Area_Types_Variance_Fields>;
};


/** aggregate fields of "common_area_types" */
export type Common_Area_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Common_Area_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Area_Types_Avg_Fields = {
  __typename?: 'common_area_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common_area_types". All fields are combined with a logical 'AND'. */
export type Common_Area_Types_Bool_Exp = {
  _and?: Maybe<Array<Common_Area_Types_Bool_Exp>>;
  _not?: Maybe<Common_Area_Types_Bool_Exp>;
  _or?: Maybe<Array<Common_Area_Types_Bool_Exp>>;
  common_areas?: Maybe<Common_Areas_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common_area_types" */
export enum Common_Area_Types_Constraint {
  /** unique or primary key constraint */
  CommonAreaTypesPkey = 'common_area_types_pkey'
}

/** input type for incrementing numeric columns in table "common_area_types" */
export type Common_Area_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "common_area_types" */
export type Common_Area_Types_Insert_Input = {
  common_areas?: Maybe<Common_Areas_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_Area_Types_Max_Fields = {
  __typename?: 'common_area_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Common_Area_Types_Min_Fields = {
  __typename?: 'common_area_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "common_area_types" */
export type Common_Area_Types_Mutation_Response = {
  __typename?: 'common_area_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Common_Area_Types>;
};

/** input type for inserting object relation for remote table "common_area_types" */
export type Common_Area_Types_Obj_Rel_Insert_Input = {
  data: Common_Area_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Common_Area_Types_On_Conflict>;
};

/** on_conflict condition type for table "common_area_types" */
export type Common_Area_Types_On_Conflict = {
  constraint: Common_Area_Types_Constraint;
  update_columns?: Array<Common_Area_Types_Update_Column>;
  where?: Maybe<Common_Area_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "common_area_types". */
export type Common_Area_Types_Order_By = {
  common_areas_aggregate?: Maybe<Common_Areas_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: common_area_types */
export type Common_Area_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "common_area_types" */
export enum Common_Area_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common_area_types" */
export type Common_Area_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_Area_Types_Stddev_Fields = {
  __typename?: 'common_area_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Area_Types_Stddev_Pop_Fields = {
  __typename?: 'common_area_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Area_Types_Stddev_Samp_Fields = {
  __typename?: 'common_area_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Common_Area_Types_Sum_Fields = {
  __typename?: 'common_area_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "common_area_types" */
export enum Common_Area_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Common_Area_Types_Var_Pop_Fields = {
  __typename?: 'common_area_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Area_Types_Var_Samp_Fields = {
  __typename?: 'common_area_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Area_Types_Variance_Fields = {
  __typename?: 'common_area_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "common_areas" */
export type Common_Areas = {
  __typename?: 'common_areas';
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** An object relationship */
  common_area_type?: Maybe<Common_Area_Types>;
  common_area_type_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "common_areas" */
export type Common_AreasAppointmentsArgs = {
  distinct_on?: Maybe<Array<Appointments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointments_Order_By>>;
  where?: Maybe<Appointments_Bool_Exp>;
};


/** columns and relationships of "common_areas" */
export type Common_AreasAppointments_AggregateArgs = {
  distinct_on?: Maybe<Array<Appointments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointments_Order_By>>;
  where?: Maybe<Appointments_Bool_Exp>;
};

/** aggregated selection of "common_areas" */
export type Common_Areas_Aggregate = {
  __typename?: 'common_areas_aggregate';
  aggregate?: Maybe<Common_Areas_Aggregate_Fields>;
  nodes: Array<Common_Areas>;
};

/** aggregate fields of "common_areas" */
export type Common_Areas_Aggregate_Fields = {
  __typename?: 'common_areas_aggregate_fields';
  avg?: Maybe<Common_Areas_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Common_Areas_Max_Fields>;
  min?: Maybe<Common_Areas_Min_Fields>;
  stddev?: Maybe<Common_Areas_Stddev_Fields>;
  stddev_pop?: Maybe<Common_Areas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Common_Areas_Stddev_Samp_Fields>;
  sum?: Maybe<Common_Areas_Sum_Fields>;
  var_pop?: Maybe<Common_Areas_Var_Pop_Fields>;
  var_samp?: Maybe<Common_Areas_Var_Samp_Fields>;
  variance?: Maybe<Common_Areas_Variance_Fields>;
};


/** aggregate fields of "common_areas" */
export type Common_Areas_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Common_Areas_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "common_areas" */
export type Common_Areas_Aggregate_Order_By = {
  avg?: Maybe<Common_Areas_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Common_Areas_Max_Order_By>;
  min?: Maybe<Common_Areas_Min_Order_By>;
  stddev?: Maybe<Common_Areas_Stddev_Order_By>;
  stddev_pop?: Maybe<Common_Areas_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Common_Areas_Stddev_Samp_Order_By>;
  sum?: Maybe<Common_Areas_Sum_Order_By>;
  var_pop?: Maybe<Common_Areas_Var_Pop_Order_By>;
  var_samp?: Maybe<Common_Areas_Var_Samp_Order_By>;
  variance?: Maybe<Common_Areas_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "common_areas" */
export type Common_Areas_Arr_Rel_Insert_Input = {
  data: Array<Common_Areas_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Common_Areas_On_Conflict>;
};

/** aggregate avg on columns */
export type Common_Areas_Avg_Fields = {
  __typename?: 'common_areas_avg_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "common_areas" */
export type Common_Areas_Avg_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "common_areas". All fields are combined with a logical 'AND'. */
export type Common_Areas_Bool_Exp = {
  _and?: Maybe<Array<Common_Areas_Bool_Exp>>;
  _not?: Maybe<Common_Areas_Bool_Exp>;
  _or?: Maybe<Array<Common_Areas_Bool_Exp>>;
  appointments?: Maybe<Appointments_Bool_Exp>;
  common_area_type?: Maybe<Common_Area_Types_Bool_Exp>;
  common_area_type_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common_areas" */
export enum Common_Areas_Constraint {
  /** unique or primary key constraint */
  CommonAreasPkey = 'common_areas_pkey'
}

/** input type for incrementing numeric columns in table "common_areas" */
export type Common_Areas_Inc_Input = {
  common_area_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "common_areas" */
export type Common_Areas_Insert_Input = {
  appointments?: Maybe<Appointments_Arr_Rel_Insert_Input>;
  common_area_type?: Maybe<Common_Area_Types_Obj_Rel_Insert_Input>;
  common_area_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_Areas_Max_Fields = {
  __typename?: 'common_areas_max_fields';
  common_area_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "common_areas" */
export type Common_Areas_Max_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Common_Areas_Min_Fields = {
  __typename?: 'common_areas_min_fields';
  common_area_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "common_areas" */
export type Common_Areas_Min_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "common_areas" */
export type Common_Areas_Mutation_Response = {
  __typename?: 'common_areas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Common_Areas>;
};

/** input type for inserting object relation for remote table "common_areas" */
export type Common_Areas_Obj_Rel_Insert_Input = {
  data: Common_Areas_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Common_Areas_On_Conflict>;
};

/** on_conflict condition type for table "common_areas" */
export type Common_Areas_On_Conflict = {
  constraint: Common_Areas_Constraint;
  update_columns?: Array<Common_Areas_Update_Column>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};

/** Ordering options when selecting data from "common_areas". */
export type Common_Areas_Order_By = {
  appointments_aggregate?: Maybe<Appointments_Aggregate_Order_By>;
  common_area_type?: Maybe<Common_Area_Types_Order_By>;
  common_area_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: common_areas */
export type Common_Areas_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "common_areas" */
export enum Common_Areas_Select_Column {
  /** column name */
  CommonAreaTypeId = 'common_area_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common_areas" */
export type Common_Areas_Set_Input = {
  common_area_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_Areas_Stddev_Fields = {
  __typename?: 'common_areas_stddev_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "common_areas" */
export type Common_Areas_Stddev_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Common_Areas_Stddev_Pop_Fields = {
  __typename?: 'common_areas_stddev_pop_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "common_areas" */
export type Common_Areas_Stddev_Pop_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Common_Areas_Stddev_Samp_Fields = {
  __typename?: 'common_areas_stddev_samp_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "common_areas" */
export type Common_Areas_Stddev_Samp_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Common_Areas_Sum_Fields = {
  __typename?: 'common_areas_sum_fields';
  common_area_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "common_areas" */
export type Common_Areas_Sum_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "common_areas" */
export enum Common_Areas_Update_Column {
  /** column name */
  CommonAreaTypeId = 'common_area_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Common_Areas_Var_Pop_Fields = {
  __typename?: 'common_areas_var_pop_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "common_areas" */
export type Common_Areas_Var_Pop_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Common_Areas_Var_Samp_Fields = {
  __typename?: 'common_areas_var_samp_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "common_areas" */
export type Common_Areas_Var_Samp_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Common_Areas_Variance_Fields = {
  __typename?: 'common_areas_variance_fields';
  common_area_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "common_areas" */
export type Common_Areas_Variance_Order_By = {
  common_area_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "contact" */
export type Contact = {
  __typename?: 'contact';
  birth_date: Scalars['date'];
  /** An array relationship */
  contact_addresses: Array<Contact_Addresses>;
  /** An aggregate relationship */
  contact_addresses_aggregate: Contact_Addresses_Aggregate;
  /** An array relationship */
  contact_emails: Array<Contact_Emails>;
  /** An aggregate relationship */
  contact_emails_aggregate: Contact_Emails_Aggregate;
  /** An array relationship */
  contact_phones: Array<Contact_Phones>;
  /** An aggregate relationship */
  contact_phones_aggregate: Contact_Phones_Aggregate;
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  /** An array relationship */
  lessors: Array<Lessors>;
  /** An array relationship */
  lessorsByEmergencyContactId: Array<Lessors>;
  /** An aggregate relationship */
  lessorsByEmergencyContactId_aggregate: Lessors_Aggregate;
  /** An aggregate relationship */
  lessors_aggregate: Lessors_Aggregate;
};


/** columns and relationships of "contact" */
export type ContactContact_AddressesArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactContact_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactContact_EmailsArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactContact_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactContact_PhonesArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactContact_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactLessorsArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactLessorsByEmergencyContactIdArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactLessorsByEmergencyContactId_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactLessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};

/** columns and relationships of "contact_addresses" */
export type Contact_Addresses = {
  __typename?: 'contact_addresses';
  /** An object relationship */
  address: Addresses;
  address_id: Scalars['Int'];
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "contact_addresses" */
export type Contact_Addresses_Aggregate = {
  __typename?: 'contact_addresses_aggregate';
  aggregate?: Maybe<Contact_Addresses_Aggregate_Fields>;
  nodes: Array<Contact_Addresses>;
};

/** aggregate fields of "contact_addresses" */
export type Contact_Addresses_Aggregate_Fields = {
  __typename?: 'contact_addresses_aggregate_fields';
  avg?: Maybe<Contact_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Addresses_Max_Fields>;
  min?: Maybe<Contact_Addresses_Min_Fields>;
  stddev?: Maybe<Contact_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Addresses_Sum_Fields>;
  var_pop?: Maybe<Contact_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Contact_Addresses_Variance_Fields>;
};


/** aggregate fields of "contact_addresses" */
export type Contact_Addresses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Addresses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_addresses" */
export type Contact_Addresses_Aggregate_Order_By = {
  avg?: Maybe<Contact_Addresses_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contact_Addresses_Max_Order_By>;
  min?: Maybe<Contact_Addresses_Min_Order_By>;
  stddev?: Maybe<Contact_Addresses_Stddev_Order_By>;
  stddev_pop?: Maybe<Contact_Addresses_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contact_Addresses_Stddev_Samp_Order_By>;
  sum?: Maybe<Contact_Addresses_Sum_Order_By>;
  var_pop?: Maybe<Contact_Addresses_Var_Pop_Order_By>;
  var_samp?: Maybe<Contact_Addresses_Var_Samp_Order_By>;
  variance?: Maybe<Contact_Addresses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_addresses" */
export type Contact_Addresses_Arr_Rel_Insert_Input = {
  data: Array<Contact_Addresses_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contact_Addresses_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Addresses_Avg_Fields = {
  __typename?: 'contact_addresses_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_addresses" */
export type Contact_Addresses_Avg_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_addresses". All fields are combined with a logical 'AND'. */
export type Contact_Addresses_Bool_Exp = {
  _and?: Maybe<Array<Contact_Addresses_Bool_Exp>>;
  _not?: Maybe<Contact_Addresses_Bool_Exp>;
  _or?: Maybe<Array<Contact_Addresses_Bool_Exp>>;
  address?: Maybe<Addresses_Bool_Exp>;
  address_id?: Maybe<Int_Comparison_Exp>;
  contact?: Maybe<Contact_Bool_Exp>;
  contact_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_addresses" */
export enum Contact_Addresses_Constraint {
  /** unique or primary key constraint */
  ContactAddressesPkey = 'contact_addresses_pkey'
}

/** input type for incrementing numeric columns in table "contact_addresses" */
export type Contact_Addresses_Inc_Input = {
  address_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_addresses" */
export type Contact_Addresses_Insert_Input = {
  address?: Maybe<Addresses_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['Int']>;
  contact?: Maybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Contact_Addresses_Max_Fields = {
  __typename?: 'contact_addresses_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "contact_addresses" */
export type Contact_Addresses_Max_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Addresses_Min_Fields = {
  __typename?: 'contact_addresses_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "contact_addresses" */
export type Contact_Addresses_Min_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "contact_addresses" */
export type Contact_Addresses_Mutation_Response = {
  __typename?: 'contact_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Addresses>;
};

/** on_conflict condition type for table "contact_addresses" */
export type Contact_Addresses_On_Conflict = {
  constraint: Contact_Addresses_Constraint;
  update_columns?: Array<Contact_Addresses_Update_Column>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_addresses". */
export type Contact_Addresses_Order_By = {
  address?: Maybe<Addresses_Order_By>;
  address_id?: Maybe<Order_By>;
  contact?: Maybe<Contact_Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_addresses */
export type Contact_Addresses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact_addresses" */
export enum Contact_Addresses_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contact_addresses" */
export type Contact_Addresses_Set_Input = {
  address_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Contact_Addresses_Stddev_Fields = {
  __typename?: 'contact_addresses_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_addresses" */
export type Contact_Addresses_Stddev_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Addresses_Stddev_Pop_Fields = {
  __typename?: 'contact_addresses_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_addresses" */
export type Contact_Addresses_Stddev_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Addresses_Stddev_Samp_Fields = {
  __typename?: 'contact_addresses_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_addresses" */
export type Contact_Addresses_Stddev_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contact_Addresses_Sum_Fields = {
  __typename?: 'contact_addresses_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contact_addresses" */
export type Contact_Addresses_Sum_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "contact_addresses" */
export enum Contact_Addresses_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Contact_Addresses_Var_Pop_Fields = {
  __typename?: 'contact_addresses_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_addresses" */
export type Contact_Addresses_Var_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Addresses_Var_Samp_Fields = {
  __typename?: 'contact_addresses_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_addresses" */
export type Contact_Addresses_Var_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Addresses_Variance_Fields = {
  __typename?: 'contact_addresses_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_addresses" */
export type Contact_Addresses_Variance_Order_By = {
  address_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregated selection of "contact" */
export type Contact_Aggregate = {
  __typename?: 'contact_aggregate';
  aggregate?: Maybe<Contact_Aggregate_Fields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type Contact_Aggregate_Fields = {
  __typename?: 'contact_aggregate_fields';
  avg?: Maybe<Contact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Max_Fields>;
  min?: Maybe<Contact_Min_Fields>;
  stddev?: Maybe<Contact_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Sum_Fields>;
  var_pop?: Maybe<Contact_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Var_Samp_Fields>;
  variance?: Maybe<Contact_Variance_Fields>;
};


/** aggregate fields of "contact" */
export type Contact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contact_Avg_Fields = {
  __typename?: 'contact_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type Contact_Bool_Exp = {
  _and?: Maybe<Array<Contact_Bool_Exp>>;
  _not?: Maybe<Contact_Bool_Exp>;
  _or?: Maybe<Array<Contact_Bool_Exp>>;
  birth_date?: Maybe<Date_Comparison_Exp>;
  contact_addresses?: Maybe<Contact_Addresses_Bool_Exp>;
  contact_emails?: Maybe<Contact_Emails_Bool_Exp>;
  contact_phones?: Maybe<Contact_Phones_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  lessors?: Maybe<Lessors_Bool_Exp>;
  lessorsByEmergencyContactId?: Maybe<Lessors_Bool_Exp>;
};

/** unique or primary key constraints on table "contact" */
export enum Contact_Constraint {
  /** unique or primary key constraint */
  ContactPkey = 'contact_pkey'
}

/** columns and relationships of "contact_emails" */
export type Contact_Emails = {
  __typename?: 'contact_emails';
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['Int'];
  /** An object relationship */
  email: Email;
  email_id: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "contact_emails" */
export type Contact_Emails_Aggregate = {
  __typename?: 'contact_emails_aggregate';
  aggregate?: Maybe<Contact_Emails_Aggregate_Fields>;
  nodes: Array<Contact_Emails>;
};

/** aggregate fields of "contact_emails" */
export type Contact_Emails_Aggregate_Fields = {
  __typename?: 'contact_emails_aggregate_fields';
  avg?: Maybe<Contact_Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Emails_Max_Fields>;
  min?: Maybe<Contact_Emails_Min_Fields>;
  stddev?: Maybe<Contact_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Emails_Sum_Fields>;
  var_pop?: Maybe<Contact_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Emails_Var_Samp_Fields>;
  variance?: Maybe<Contact_Emails_Variance_Fields>;
};


/** aggregate fields of "contact_emails" */
export type Contact_Emails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Emails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_emails" */
export type Contact_Emails_Aggregate_Order_By = {
  avg?: Maybe<Contact_Emails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contact_Emails_Max_Order_By>;
  min?: Maybe<Contact_Emails_Min_Order_By>;
  stddev?: Maybe<Contact_Emails_Stddev_Order_By>;
  stddev_pop?: Maybe<Contact_Emails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contact_Emails_Stddev_Samp_Order_By>;
  sum?: Maybe<Contact_Emails_Sum_Order_By>;
  var_pop?: Maybe<Contact_Emails_Var_Pop_Order_By>;
  var_samp?: Maybe<Contact_Emails_Var_Samp_Order_By>;
  variance?: Maybe<Contact_Emails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_emails" */
export type Contact_Emails_Arr_Rel_Insert_Input = {
  data: Array<Contact_Emails_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contact_Emails_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Emails_Avg_Fields = {
  __typename?: 'contact_emails_avg_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_emails" */
export type Contact_Emails_Avg_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_emails". All fields are combined with a logical 'AND'. */
export type Contact_Emails_Bool_Exp = {
  _and?: Maybe<Array<Contact_Emails_Bool_Exp>>;
  _not?: Maybe<Contact_Emails_Bool_Exp>;
  _or?: Maybe<Array<Contact_Emails_Bool_Exp>>;
  contact?: Maybe<Contact_Bool_Exp>;
  contact_id?: Maybe<Int_Comparison_Exp>;
  email?: Maybe<Email_Bool_Exp>;
  email_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_emails" */
export enum Contact_Emails_Constraint {
  /** unique or primary key constraint */
  ContactEmailsPkey = 'contact_emails_pkey'
}

/** input type for incrementing numeric columns in table "contact_emails" */
export type Contact_Emails_Inc_Input = {
  contact_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_emails" */
export type Contact_Emails_Insert_Input = {
  contact?: Maybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Email_Obj_Rel_Insert_Input>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Contact_Emails_Max_Fields = {
  __typename?: 'contact_emails_max_fields';
  contact_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "contact_emails" */
export type Contact_Emails_Max_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Emails_Min_Fields = {
  __typename?: 'contact_emails_min_fields';
  contact_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "contact_emails" */
export type Contact_Emails_Min_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "contact_emails" */
export type Contact_Emails_Mutation_Response = {
  __typename?: 'contact_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Emails>;
};

/** on_conflict condition type for table "contact_emails" */
export type Contact_Emails_On_Conflict = {
  constraint: Contact_Emails_Constraint;
  update_columns?: Array<Contact_Emails_Update_Column>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_emails". */
export type Contact_Emails_Order_By = {
  contact?: Maybe<Contact_Order_By>;
  contact_id?: Maybe<Order_By>;
  email?: Maybe<Email_Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_emails */
export type Contact_Emails_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact_emails" */
export enum Contact_Emails_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contact_emails" */
export type Contact_Emails_Set_Input = {
  contact_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Contact_Emails_Stddev_Fields = {
  __typename?: 'contact_emails_stddev_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_emails" */
export type Contact_Emails_Stddev_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Emails_Stddev_Pop_Fields = {
  __typename?: 'contact_emails_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_emails" */
export type Contact_Emails_Stddev_Pop_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Emails_Stddev_Samp_Fields = {
  __typename?: 'contact_emails_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_emails" */
export type Contact_Emails_Stddev_Samp_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contact_Emails_Sum_Fields = {
  __typename?: 'contact_emails_sum_fields';
  contact_id?: Maybe<Scalars['Int']>;
  email_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contact_emails" */
export type Contact_Emails_Sum_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "contact_emails" */
export enum Contact_Emails_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Contact_Emails_Var_Pop_Fields = {
  __typename?: 'contact_emails_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_emails" */
export type Contact_Emails_Var_Pop_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Emails_Var_Samp_Fields = {
  __typename?: 'contact_emails_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_emails" */
export type Contact_Emails_Var_Samp_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Emails_Variance_Fields = {
  __typename?: 'contact_emails_variance_fields';
  contact_id?: Maybe<Scalars['Float']>;
  email_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_emails" */
export type Contact_Emails_Variance_Order_By = {
  contact_id?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "contact" */
export type Contact_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact" */
export type Contact_Insert_Input = {
  birth_date?: Maybe<Scalars['date']>;
  contact_addresses?: Maybe<Contact_Addresses_Arr_Rel_Insert_Input>;
  contact_emails?: Maybe<Contact_Emails_Arr_Rel_Insert_Input>;
  contact_phones?: Maybe<Contact_Phones_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  lessors?: Maybe<Lessors_Arr_Rel_Insert_Input>;
  lessorsByEmergencyContactId?: Maybe<Lessors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contact_Max_Fields = {
  __typename?: 'contact_max_fields';
  birth_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contact_Min_Fields = {
  __typename?: 'contact_min_fields';
  birth_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contact" */
export type Contact_Mutation_Response = {
  __typename?: 'contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact>;
};

/** input type for inserting object relation for remote table "contact" */
export type Contact_Obj_Rel_Insert_Input = {
  data: Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Contact_On_Conflict>;
};

/** on_conflict condition type for table "contact" */
export type Contact_On_Conflict = {
  constraint: Contact_Constraint;
  update_columns?: Array<Contact_Update_Column>;
  where?: Maybe<Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "contact". */
export type Contact_Order_By = {
  birth_date?: Maybe<Order_By>;
  contact_addresses_aggregate?: Maybe<Contact_Addresses_Aggregate_Order_By>;
  contact_emails_aggregate?: Maybe<Contact_Emails_Aggregate_Order_By>;
  contact_phones_aggregate?: Maybe<Contact_Phones_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  lessorsByEmergencyContactId_aggregate?: Maybe<Lessors_Aggregate_Order_By>;
  lessors_aggregate?: Maybe<Lessors_Aggregate_Order_By>;
};

/** columns and relationships of "contact_phones" */
export type Contact_Phones = {
  __typename?: 'contact_phones';
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  phone: Phones;
  phone_id: Scalars['Int'];
};

/** aggregated selection of "contact_phones" */
export type Contact_Phones_Aggregate = {
  __typename?: 'contact_phones_aggregate';
  aggregate?: Maybe<Contact_Phones_Aggregate_Fields>;
  nodes: Array<Contact_Phones>;
};

/** aggregate fields of "contact_phones" */
export type Contact_Phones_Aggregate_Fields = {
  __typename?: 'contact_phones_aggregate_fields';
  avg?: Maybe<Contact_Phones_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Phones_Max_Fields>;
  min?: Maybe<Contact_Phones_Min_Fields>;
  stddev?: Maybe<Contact_Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Phones_Sum_Fields>;
  var_pop?: Maybe<Contact_Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Phones_Var_Samp_Fields>;
  variance?: Maybe<Contact_Phones_Variance_Fields>;
};


/** aggregate fields of "contact_phones" */
export type Contact_Phones_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Phones_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_phones" */
export type Contact_Phones_Aggregate_Order_By = {
  avg?: Maybe<Contact_Phones_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contact_Phones_Max_Order_By>;
  min?: Maybe<Contact_Phones_Min_Order_By>;
  stddev?: Maybe<Contact_Phones_Stddev_Order_By>;
  stddev_pop?: Maybe<Contact_Phones_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contact_Phones_Stddev_Samp_Order_By>;
  sum?: Maybe<Contact_Phones_Sum_Order_By>;
  var_pop?: Maybe<Contact_Phones_Var_Pop_Order_By>;
  var_samp?: Maybe<Contact_Phones_Var_Samp_Order_By>;
  variance?: Maybe<Contact_Phones_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_phones" */
export type Contact_Phones_Arr_Rel_Insert_Input = {
  data: Array<Contact_Phones_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Contact_Phones_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Phones_Avg_Fields = {
  __typename?: 'contact_phones_avg_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_phones" */
export type Contact_Phones_Avg_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_phones". All fields are combined with a logical 'AND'. */
export type Contact_Phones_Bool_Exp = {
  _and?: Maybe<Array<Contact_Phones_Bool_Exp>>;
  _not?: Maybe<Contact_Phones_Bool_Exp>;
  _or?: Maybe<Array<Contact_Phones_Bool_Exp>>;
  contact?: Maybe<Contact_Bool_Exp>;
  contact_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  phone?: Maybe<Phones_Bool_Exp>;
  phone_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_phones" */
export enum Contact_Phones_Constraint {
  /** unique or primary key constraint */
  ContactPhonesPkey = 'contact_phones_pkey'
}

/** input type for incrementing numeric columns in table "contact_phones" */
export type Contact_Phones_Inc_Input = {
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  phone_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_phones" */
export type Contact_Phones_Insert_Input = {
  contact?: Maybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Phones_Obj_Rel_Insert_Input>;
  phone_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Contact_Phones_Max_Fields = {
  __typename?: 'contact_phones_max_fields';
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  phone_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "contact_phones" */
export type Contact_Phones_Max_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Phones_Min_Fields = {
  __typename?: 'contact_phones_min_fields';
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  phone_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "contact_phones" */
export type Contact_Phones_Min_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "contact_phones" */
export type Contact_Phones_Mutation_Response = {
  __typename?: 'contact_phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Phones>;
};

/** on_conflict condition type for table "contact_phones" */
export type Contact_Phones_On_Conflict = {
  constraint: Contact_Phones_Constraint;
  update_columns?: Array<Contact_Phones_Update_Column>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_phones". */
export type Contact_Phones_Order_By = {
  contact?: Maybe<Contact_Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Phones_Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_phones */
export type Contact_Phones_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact_phones" */
export enum Contact_Phones_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneId = 'phone_id'
}

/** input type for updating data in table "contact_phones" */
export type Contact_Phones_Set_Input = {
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  phone_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Contact_Phones_Stddev_Fields = {
  __typename?: 'contact_phones_stddev_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_phones" */
export type Contact_Phones_Stddev_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Phones_Stddev_Pop_Fields = {
  __typename?: 'contact_phones_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_phones" */
export type Contact_Phones_Stddev_Pop_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Phones_Stddev_Samp_Fields = {
  __typename?: 'contact_phones_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_phones" */
export type Contact_Phones_Stddev_Samp_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contact_Phones_Sum_Fields = {
  __typename?: 'contact_phones_sum_fields';
  contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  phone_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contact_phones" */
export type Contact_Phones_Sum_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** update columns of table "contact_phones" */
export enum Contact_Phones_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneId = 'phone_id'
}

/** aggregate var_pop on columns */
export type Contact_Phones_Var_Pop_Fields = {
  __typename?: 'contact_phones_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_phones" */
export type Contact_Phones_Var_Pop_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Phones_Var_Samp_Fields = {
  __typename?: 'contact_phones_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_phones" */
export type Contact_Phones_Var_Samp_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Phones_Variance_Fields = {
  __typename?: 'contact_phones_variance_fields';
  contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  phone_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_phones" */
export type Contact_Phones_Variance_Order_By = {
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_id?: Maybe<Order_By>;
};

/** primary key columns input for table: contact */
export type Contact_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact" */
export enum Contact_Select_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** input type for updating data in table "contact" */
export type Contact_Set_Input = {
  birth_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contact_Stddev_Fields = {
  __typename?: 'contact_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Stddev_Pop_Fields = {
  __typename?: 'contact_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Stddev_Samp_Fields = {
  __typename?: 'contact_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Contact_Sum_Fields = {
  __typename?: 'contact_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "contact" */
export enum Contact_Update_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** aggregate var_pop on columns */
export type Contact_Var_Pop_Fields = {
  __typename?: 'contact_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Var_Samp_Fields = {
  __typename?: 'contact_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Variance_Fields = {
  __typename?: 'contact_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "currency_type" */
export type Currency_Type = {
  __typename?: 'currency_type';
  /** An object relationship */
  exchange?: Maybe<Exchange>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: Transactions_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "currency_type" */
export type Currency_TypeTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "currency_type" */
export type Currency_TypeTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};

/** aggregated selection of "currency_type" */
export type Currency_Type_Aggregate = {
  __typename?: 'currency_type_aggregate';
  aggregate?: Maybe<Currency_Type_Aggregate_Fields>;
  nodes: Array<Currency_Type>;
};

/** aggregate fields of "currency_type" */
export type Currency_Type_Aggregate_Fields = {
  __typename?: 'currency_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Currency_Type_Max_Fields>;
  min?: Maybe<Currency_Type_Min_Fields>;
};


/** aggregate fields of "currency_type" */
export type Currency_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Currency_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "currency_type". All fields are combined with a logical 'AND'. */
export type Currency_Type_Bool_Exp = {
  _and?: Maybe<Array<Currency_Type_Bool_Exp>>;
  _not?: Maybe<Currency_Type_Bool_Exp>;
  _or?: Maybe<Array<Currency_Type_Bool_Exp>>;
  exchange?: Maybe<Exchange_Bool_Exp>;
  transactions?: Maybe<Transactions_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency_type" */
export enum Currency_Type_Constraint {
  /** unique or primary key constraint */
  CurrencyTypePkey = 'currency_type_pkey'
}

export enum Currency_Type_Enum {
  Cad = 'CAD',
  Eur = 'EUR',
  Mxn = 'MXN',
  Usd = 'USD'
}

/** Boolean expression to compare columns of type "currency_type_enum". All fields are combined with logical 'AND'. */
export type Currency_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Currency_Type_Enum>;
  _in?: Maybe<Array<Currency_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Currency_Type_Enum>;
  _nin?: Maybe<Array<Currency_Type_Enum>>;
};

/** input type for inserting data into table "currency_type" */
export type Currency_Type_Insert_Input = {
  exchange?: Maybe<Exchange_Obj_Rel_Insert_Input>;
  transactions?: Maybe<Transactions_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Currency_Type_Max_Fields = {
  __typename?: 'currency_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currency_Type_Min_Fields = {
  __typename?: 'currency_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "currency_type" */
export type Currency_Type_Mutation_Response = {
  __typename?: 'currency_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_Type>;
};

/** input type for inserting object relation for remote table "currency_type" */
export type Currency_Type_Obj_Rel_Insert_Input = {
  data: Currency_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Currency_Type_On_Conflict>;
};

/** on_conflict condition type for table "currency_type" */
export type Currency_Type_On_Conflict = {
  constraint: Currency_Type_Constraint;
  update_columns?: Array<Currency_Type_Update_Column>;
  where?: Maybe<Currency_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "currency_type". */
export type Currency_Type_Order_By = {
  exchange?: Maybe<Exchange_Order_By>;
  transactions_aggregate?: Maybe<Transactions_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: currency_type */
export type Currency_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "currency_type" */
export enum Currency_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "currency_type" */
export type Currency_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "currency_type" */
export enum Currency_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dishes" */
export type Dishes = {
  __typename?: 'dishes';
  cost: Scalars['numeric'];
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  ingredient_dishes: Array<Ingredient_Dish>;
  /** An aggregate relationship */
  ingredient_dishes_aggregate: Ingredient_Dish_Aggregate;
  /** An array relationship */
  modifier_group_dishes: Array<Modifier_Group_Dish>;
  /** An aggregate relationship */
  modifier_group_dishes_aggregate: Modifier_Group_Dish_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  order_dishes: Array<Order_Dish>;
  /** An aggregate relationship */
  order_dishes_aggregate: Order_Dish_Aggregate;
  price: Scalars['numeric'];
  /** An object relationship */
  subcategory?: Maybe<Subcategories>;
  subcategory_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "dishes" */
export type DishesIngredient_DishesArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


/** columns and relationships of "dishes" */
export type DishesIngredient_Dishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


/** columns and relationships of "dishes" */
export type DishesModifier_Group_DishesArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


/** columns and relationships of "dishes" */
export type DishesModifier_Group_Dishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


/** columns and relationships of "dishes" */
export type DishesOrder_DishesArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


/** columns and relationships of "dishes" */
export type DishesOrder_Dishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};

/** aggregated selection of "dishes" */
export type Dishes_Aggregate = {
  __typename?: 'dishes_aggregate';
  aggregate?: Maybe<Dishes_Aggregate_Fields>;
  nodes: Array<Dishes>;
};

/** aggregate fields of "dishes" */
export type Dishes_Aggregate_Fields = {
  __typename?: 'dishes_aggregate_fields';
  avg?: Maybe<Dishes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dishes_Max_Fields>;
  min?: Maybe<Dishes_Min_Fields>;
  stddev?: Maybe<Dishes_Stddev_Fields>;
  stddev_pop?: Maybe<Dishes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dishes_Stddev_Samp_Fields>;
  sum?: Maybe<Dishes_Sum_Fields>;
  var_pop?: Maybe<Dishes_Var_Pop_Fields>;
  var_samp?: Maybe<Dishes_Var_Samp_Fields>;
  variance?: Maybe<Dishes_Variance_Fields>;
};


/** aggregate fields of "dishes" */
export type Dishes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dishes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dishes" */
export type Dishes_Aggregate_Order_By = {
  avg?: Maybe<Dishes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dishes_Max_Order_By>;
  min?: Maybe<Dishes_Min_Order_By>;
  stddev?: Maybe<Dishes_Stddev_Order_By>;
  stddev_pop?: Maybe<Dishes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dishes_Stddev_Samp_Order_By>;
  sum?: Maybe<Dishes_Sum_Order_By>;
  var_pop?: Maybe<Dishes_Var_Pop_Order_By>;
  var_samp?: Maybe<Dishes_Var_Samp_Order_By>;
  variance?: Maybe<Dishes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dishes" */
export type Dishes_Arr_Rel_Insert_Input = {
  data: Array<Dishes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dishes_On_Conflict>;
};

/** aggregate avg on columns */
export type Dishes_Avg_Fields = {
  __typename?: 'dishes_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dishes" */
export type Dishes_Avg_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dishes". All fields are combined with a logical 'AND'. */
export type Dishes_Bool_Exp = {
  _and?: Maybe<Array<Dishes_Bool_Exp>>;
  _not?: Maybe<Dishes_Bool_Exp>;
  _or?: Maybe<Array<Dishes_Bool_Exp>>;
  cost?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ingredient_dishes?: Maybe<Ingredient_Dish_Bool_Exp>;
  modifier_group_dishes?: Maybe<Modifier_Group_Dish_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_dishes?: Maybe<Order_Dish_Bool_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  subcategory?: Maybe<Subcategories_Bool_Exp>;
  subcategory_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "dishes" */
export enum Dishes_Constraint {
  /** unique or primary key constraint */
  DishesPkey = 'dishes_pkey'
}

/** input type for incrementing numeric columns in table "dishes" */
export type Dishes_Inc_Input = {
  cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  subcategory_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dishes" */
export type Dishes_Insert_Input = {
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_dishes?: Maybe<Ingredient_Dish_Arr_Rel_Insert_Input>;
  modifier_group_dishes?: Maybe<Modifier_Group_Dish_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  order_dishes?: Maybe<Order_Dish_Arr_Rel_Insert_Input>;
  price?: Maybe<Scalars['numeric']>;
  subcategory?: Maybe<Subcategories_Obj_Rel_Insert_Input>;
  subcategory_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dishes_Max_Fields = {
  __typename?: 'dishes_max_fields';
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  subcategory_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "dishes" */
export type Dishes_Max_Order_By = {
  cost?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dishes_Min_Fields = {
  __typename?: 'dishes_min_fields';
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  subcategory_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "dishes" */
export type Dishes_Min_Order_By = {
  cost?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "dishes" */
export type Dishes_Mutation_Response = {
  __typename?: 'dishes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dishes>;
};

/** input type for inserting object relation for remote table "dishes" */
export type Dishes_Obj_Rel_Insert_Input = {
  data: Dishes_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Dishes_On_Conflict>;
};

/** on_conflict condition type for table "dishes" */
export type Dishes_On_Conflict = {
  constraint: Dishes_Constraint;
  update_columns?: Array<Dishes_Update_Column>;
  where?: Maybe<Dishes_Bool_Exp>;
};

/** Ordering options when selecting data from "dishes". */
export type Dishes_Order_By = {
  cost?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_dishes_aggregate?: Maybe<Ingredient_Dish_Aggregate_Order_By>;
  modifier_group_dishes_aggregate?: Maybe<Modifier_Group_Dish_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  order_dishes_aggregate?: Maybe<Order_Dish_Aggregate_Order_By>;
  price?: Maybe<Order_By>;
  subcategory?: Maybe<Subcategories_Order_By>;
  subcategory_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: dishes */
export type Dishes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "dishes" */
export enum Dishes_Select_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  SubcategoryId = 'subcategory_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "dishes" */
export type Dishes_Set_Input = {
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  subcategory_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dishes_Stddev_Fields = {
  __typename?: 'dishes_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dishes" */
export type Dishes_Stddev_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dishes_Stddev_Pop_Fields = {
  __typename?: 'dishes_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dishes" */
export type Dishes_Stddev_Pop_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dishes_Stddev_Samp_Fields = {
  __typename?: 'dishes_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dishes" */
export type Dishes_Stddev_Samp_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dishes_Sum_Fields = {
  __typename?: 'dishes_sum_fields';
  cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  subcategory_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dishes" */
export type Dishes_Sum_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** update columns of table "dishes" */
export enum Dishes_Update_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  SubcategoryId = 'subcategory_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Dishes_Var_Pop_Fields = {
  __typename?: 'dishes_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dishes" */
export type Dishes_Var_Pop_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dishes_Var_Samp_Fields = {
  __typename?: 'dishes_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dishes" */
export type Dishes_Var_Samp_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dishes_Variance_Fields = {
  __typename?: 'dishes_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dishes" */
export type Dishes_Variance_Order_By = {
  cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  subcategory_id?: Maybe<Order_By>;
};

/** columns and relationships of "email" */
export type Email = {
  __typename?: 'email';
  /** An array relationship */
  contact_emails: Array<Contact_Emails>;
  /** An aggregate relationship */
  contact_emails_aggregate: Contact_Emails_Aggregate;
  direction: Scalars['String'];
  id: Scalars['Int'];
};


/** columns and relationships of "email" */
export type EmailContact_EmailsArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


/** columns and relationships of "email" */
export type EmailContact_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};

/** aggregated selection of "email" */
export type Email_Aggregate = {
  __typename?: 'email_aggregate';
  aggregate?: Maybe<Email_Aggregate_Fields>;
  nodes: Array<Email>;
};

/** aggregate fields of "email" */
export type Email_Aggregate_Fields = {
  __typename?: 'email_aggregate_fields';
  avg?: Maybe<Email_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Max_Fields>;
  min?: Maybe<Email_Min_Fields>;
  stddev?: Maybe<Email_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Sum_Fields>;
  var_pop?: Maybe<Email_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Var_Samp_Fields>;
  variance?: Maybe<Email_Variance_Fields>;
};


/** aggregate fields of "email" */
export type Email_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Email_Avg_Fields = {
  __typename?: 'email_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "email". All fields are combined with a logical 'AND'. */
export type Email_Bool_Exp = {
  _and?: Maybe<Array<Email_Bool_Exp>>;
  _not?: Maybe<Email_Bool_Exp>;
  _or?: Maybe<Array<Email_Bool_Exp>>;
  contact_emails?: Maybe<Contact_Emails_Bool_Exp>;
  direction?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "email" */
export enum Email_Constraint {
  /** unique or primary key constraint */
  EmailPkey = 'email_pkey'
}

/** input type for incrementing numeric columns in table "email" */
export type Email_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "email" */
export type Email_Insert_Input = {
  contact_emails?: Maybe<Contact_Emails_Arr_Rel_Insert_Input>;
  direction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Email_Max_Fields = {
  __typename?: 'email_max_fields';
  direction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Email_Min_Fields = {
  __typename?: 'email_min_fields';
  direction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "email" */
export type Email_Mutation_Response = {
  __typename?: 'email_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email>;
};

/** input type for inserting object relation for remote table "email" */
export type Email_Obj_Rel_Insert_Input = {
  data: Email_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Email_On_Conflict>;
};

/** on_conflict condition type for table "email" */
export type Email_On_Conflict = {
  constraint: Email_Constraint;
  update_columns?: Array<Email_Update_Column>;
  where?: Maybe<Email_Bool_Exp>;
};

/** Ordering options when selecting data from "email". */
export type Email_Order_By = {
  contact_emails_aggregate?: Maybe<Contact_Emails_Aggregate_Order_By>;
  direction?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: email */
export type Email_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "email" */
export enum Email_Select_Column {
  /** column name */
  Direction = 'direction',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "email" */
export type Email_Set_Input = {
  direction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Email_Stddev_Fields = {
  __typename?: 'email_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Stddev_Pop_Fields = {
  __typename?: 'email_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Stddev_Samp_Fields = {
  __typename?: 'email_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Email_Sum_Fields = {
  __typename?: 'email_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "email" */
export enum Email_Update_Column {
  /** column name */
  Direction = 'direction',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Email_Var_Pop_Fields = {
  __typename?: 'email_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Var_Samp_Fields = {
  __typename?: 'email_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Variance_Fields = {
  __typename?: 'email_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "employees" */
export type Employees = {
  __typename?: 'employees';
  commensal_id: Scalars['Int'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "employees" */
export type Employees_Aggregate = {
  __typename?: 'employees_aggregate';
  aggregate?: Maybe<Employees_Aggregate_Fields>;
  nodes: Array<Employees>;
};

/** aggregate fields of "employees" */
export type Employees_Aggregate_Fields = {
  __typename?: 'employees_aggregate_fields';
  avg?: Maybe<Employees_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employees_Max_Fields>;
  min?: Maybe<Employees_Min_Fields>;
  stddev?: Maybe<Employees_Stddev_Fields>;
  stddev_pop?: Maybe<Employees_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employees_Stddev_Samp_Fields>;
  sum?: Maybe<Employees_Sum_Fields>;
  var_pop?: Maybe<Employees_Var_Pop_Fields>;
  var_samp?: Maybe<Employees_Var_Samp_Fields>;
  variance?: Maybe<Employees_Variance_Fields>;
};


/** aggregate fields of "employees" */
export type Employees_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Employees_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Employees_Avg_Fields = {
  __typename?: 'employees_avg_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employees". All fields are combined with a logical 'AND'. */
export type Employees_Bool_Exp = {
  _and?: Maybe<Array<Employees_Bool_Exp>>;
  _not?: Maybe<Employees_Bool_Exp>;
  _or?: Maybe<Array<Employees_Bool_Exp>>;
  commensal_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "employees" */
export enum Employees_Constraint {
  /** unique or primary key constraint */
  EmployeesPkey = 'employees_pkey'
}

/** input type for incrementing numeric columns in table "employees" */
export type Employees_Inc_Input = {
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "employees" */
export type Employees_Insert_Input = {
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Employees_Max_Fields = {
  __typename?: 'employees_max_fields';
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Employees_Min_Fields = {
  __typename?: 'employees_min_fields';
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "employees" */
export type Employees_Mutation_Response = {
  __typename?: 'employees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employees>;
};

/** on_conflict condition type for table "employees" */
export type Employees_On_Conflict = {
  constraint: Employees_Constraint;
  update_columns?: Array<Employees_Update_Column>;
  where?: Maybe<Employees_Bool_Exp>;
};

/** Ordering options when selecting data from "employees". */
export type Employees_Order_By = {
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: employees */
export type Employees_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "employees" */
export enum Employees_Select_Column {
  /** column name */
  CommensalId = 'commensal_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "employees" */
export type Employees_Set_Input = {
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Employees_Stddev_Fields = {
  __typename?: 'employees_stddev_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Employees_Stddev_Pop_Fields = {
  __typename?: 'employees_stddev_pop_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Employees_Stddev_Samp_Fields = {
  __typename?: 'employees_stddev_samp_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Employees_Sum_Fields = {
  __typename?: 'employees_sum_fields';
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "employees" */
export enum Employees_Update_Column {
  /** column name */
  CommensalId = 'commensal_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Employees_Var_Pop_Fields = {
  __typename?: 'employees_var_pop_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Employees_Var_Samp_Fields = {
  __typename?: 'employees_var_samp_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Employees_Variance_Fields = {
  __typename?: 'employees_variance_fields';
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "exchange" */
export type Exchange = {
  __typename?: 'exchange';
  currency_key: Currency_Type_Enum;
  currency_name: Scalars['String'];
  /** An object relationship */
  currency_type: Currency_Type;
  disabled: Scalars['Boolean'];
  exchange: Scalars['numeric'];
  id: Scalars['Int'];
};

/** aggregated selection of "exchange" */
export type Exchange_Aggregate = {
  __typename?: 'exchange_aggregate';
  aggregate?: Maybe<Exchange_Aggregate_Fields>;
  nodes: Array<Exchange>;
};

/** aggregate fields of "exchange" */
export type Exchange_Aggregate_Fields = {
  __typename?: 'exchange_aggregate_fields';
  avg?: Maybe<Exchange_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Exchange_Max_Fields>;
  min?: Maybe<Exchange_Min_Fields>;
  stddev?: Maybe<Exchange_Stddev_Fields>;
  stddev_pop?: Maybe<Exchange_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Exchange_Stddev_Samp_Fields>;
  sum?: Maybe<Exchange_Sum_Fields>;
  var_pop?: Maybe<Exchange_Var_Pop_Fields>;
  var_samp?: Maybe<Exchange_Var_Samp_Fields>;
  variance?: Maybe<Exchange_Variance_Fields>;
};


/** aggregate fields of "exchange" */
export type Exchange_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Exchange_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Exchange_Avg_Fields = {
  __typename?: 'exchange_avg_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "exchange". All fields are combined with a logical 'AND'. */
export type Exchange_Bool_Exp = {
  _and?: Maybe<Array<Exchange_Bool_Exp>>;
  _not?: Maybe<Exchange_Bool_Exp>;
  _or?: Maybe<Array<Exchange_Bool_Exp>>;
  currency_key?: Maybe<Currency_Type_Enum_Comparison_Exp>;
  currency_name?: Maybe<String_Comparison_Exp>;
  currency_type?: Maybe<Currency_Type_Bool_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  exchange?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "exchange" */
export enum Exchange_Constraint {
  /** unique or primary key constraint */
  ExchangeCurrencyKeyKey = 'exchange_currency_key_key',
  /** unique or primary key constraint */
  ExchangePkey = 'exchange_pkey'
}

/** input type for incrementing numeric columns in table "exchange" */
export type Exchange_Inc_Input = {
  exchange?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "exchange" */
export type Exchange_Insert_Input = {
  currency_key?: Maybe<Currency_Type_Enum>;
  currency_name?: Maybe<Scalars['String']>;
  currency_type?: Maybe<Currency_Type_Obj_Rel_Insert_Input>;
  disabled?: Maybe<Scalars['Boolean']>;
  exchange?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Exchange_Max_Fields = {
  __typename?: 'exchange_max_fields';
  currency_name?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Exchange_Min_Fields = {
  __typename?: 'exchange_min_fields';
  currency_name?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "exchange" */
export type Exchange_Mutation_Response = {
  __typename?: 'exchange_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Exchange>;
};

/** input type for inserting object relation for remote table "exchange" */
export type Exchange_Obj_Rel_Insert_Input = {
  data: Exchange_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Exchange_On_Conflict>;
};

/** on_conflict condition type for table "exchange" */
export type Exchange_On_Conflict = {
  constraint: Exchange_Constraint;
  update_columns?: Array<Exchange_Update_Column>;
  where?: Maybe<Exchange_Bool_Exp>;
};

/** Ordering options when selecting data from "exchange". */
export type Exchange_Order_By = {
  currency_key?: Maybe<Order_By>;
  currency_name?: Maybe<Order_By>;
  currency_type?: Maybe<Currency_Type_Order_By>;
  disabled?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: exchange */
export type Exchange_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "exchange" */
export enum Exchange_Select_Column {
  /** column name */
  CurrencyKey = 'currency_key',
  /** column name */
  CurrencyName = 'currency_name',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Exchange = 'exchange',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "exchange" */
export type Exchange_Set_Input = {
  currency_key?: Maybe<Currency_Type_Enum>;
  currency_name?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  exchange?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Exchange_Stddev_Fields = {
  __typename?: 'exchange_stddev_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Exchange_Stddev_Pop_Fields = {
  __typename?: 'exchange_stddev_pop_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Exchange_Stddev_Samp_Fields = {
  __typename?: 'exchange_stddev_samp_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Exchange_Sum_Fields = {
  __typename?: 'exchange_sum_fields';
  exchange?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "exchange" */
export enum Exchange_Update_Column {
  /** column name */
  CurrencyKey = 'currency_key',
  /** column name */
  CurrencyName = 'currency_name',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Exchange = 'exchange',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Exchange_Var_Pop_Fields = {
  __typename?: 'exchange_var_pop_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Exchange_Var_Samp_Fields = {
  __typename?: 'exchange_var_samp_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Exchange_Variance_Fields = {
  __typename?: 'exchange_variance_fields';
  exchange?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "history_comments" */
export type History_Comments = {
  __typename?: 'history_comments';
  comment: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  provider: Providers;
  provider_id: Scalars['Int'];
  rating: Scalars['Int'];
};

/** aggregated selection of "history_comments" */
export type History_Comments_Aggregate = {
  __typename?: 'history_comments_aggregate';
  aggregate?: Maybe<History_Comments_Aggregate_Fields>;
  nodes: Array<History_Comments>;
};

/** aggregate fields of "history_comments" */
export type History_Comments_Aggregate_Fields = {
  __typename?: 'history_comments_aggregate_fields';
  avg?: Maybe<History_Comments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<History_Comments_Max_Fields>;
  min?: Maybe<History_Comments_Min_Fields>;
  stddev?: Maybe<History_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<History_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<History_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<History_Comments_Sum_Fields>;
  var_pop?: Maybe<History_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<History_Comments_Var_Samp_Fields>;
  variance?: Maybe<History_Comments_Variance_Fields>;
};


/** aggregate fields of "history_comments" */
export type History_Comments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<History_Comments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "history_comments" */
export type History_Comments_Aggregate_Order_By = {
  avg?: Maybe<History_Comments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<History_Comments_Max_Order_By>;
  min?: Maybe<History_Comments_Min_Order_By>;
  stddev?: Maybe<History_Comments_Stddev_Order_By>;
  stddev_pop?: Maybe<History_Comments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<History_Comments_Stddev_Samp_Order_By>;
  sum?: Maybe<History_Comments_Sum_Order_By>;
  var_pop?: Maybe<History_Comments_Var_Pop_Order_By>;
  var_samp?: Maybe<History_Comments_Var_Samp_Order_By>;
  variance?: Maybe<History_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "history_comments" */
export type History_Comments_Arr_Rel_Insert_Input = {
  data: Array<History_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<History_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type History_Comments_Avg_Fields = {
  __typename?: 'history_comments_avg_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "history_comments" */
export type History_Comments_Avg_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "history_comments". All fields are combined with a logical 'AND'. */
export type History_Comments_Bool_Exp = {
  _and?: Maybe<Array<History_Comments_Bool_Exp>>;
  _not?: Maybe<History_Comments_Bool_Exp>;
  _or?: Maybe<Array<History_Comments_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  provider_id?: Maybe<Int_Comparison_Exp>;
  rating?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "history_comments" */
export enum History_Comments_Constraint {
  /** unique or primary key constraint */
  HistoryCommentsPkey = 'history_comments_pkey'
}

/** input type for incrementing numeric columns in table "history_comments" */
export type History_Comments_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "history_comments" */
export type History_Comments_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  provider_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type History_Comments_Max_Fields = {
  __typename?: 'history_comments_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "history_comments" */
export type History_Comments_Max_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type History_Comments_Min_Fields = {
  __typename?: 'history_comments_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "history_comments" */
export type History_Comments_Min_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** response of any mutation on the table "history_comments" */
export type History_Comments_Mutation_Response = {
  __typename?: 'history_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<History_Comments>;
};

/** on_conflict condition type for table "history_comments" */
export type History_Comments_On_Conflict = {
  constraint: History_Comments_Constraint;
  update_columns?: Array<History_Comments_Update_Column>;
  where?: Maybe<History_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "history_comments". */
export type History_Comments_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider?: Maybe<Providers_Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** primary key columns input for table: history_comments */
export type History_Comments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "history_comments" */
export enum History_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Rating = 'rating'
}

/** input type for updating data in table "history_comments" */
export type History_Comments_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type History_Comments_Stddev_Fields = {
  __typename?: 'history_comments_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "history_comments" */
export type History_Comments_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type History_Comments_Stddev_Pop_Fields = {
  __typename?: 'history_comments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "history_comments" */
export type History_Comments_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type History_Comments_Stddev_Samp_Fields = {
  __typename?: 'history_comments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "history_comments" */
export type History_Comments_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type History_Comments_Sum_Fields = {
  __typename?: 'history_comments_sum_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "history_comments" */
export type History_Comments_Sum_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** update columns of table "history_comments" */
export enum History_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Rating = 'rating'
}

/** aggregate var_pop on columns */
export type History_Comments_Var_Pop_Fields = {
  __typename?: 'history_comments_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "history_comments" */
export type History_Comments_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type History_Comments_Var_Samp_Fields = {
  __typename?: 'history_comments_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "history_comments" */
export type History_Comments_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type History_Comments_Variance_Fields = {
  __typename?: 'history_comments_variance_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "history_comments" */
export type History_Comments_Variance_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** columns and relationships of "ingredient_dish" */
export type Ingredient_Dish = {
  __typename?: 'ingredient_dish';
  /** An object relationship */
  dish: Dishes;
  dish_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  ingredient: Ingredients;
  ingredient_id: Scalars['Int'];
  optional: Scalars['Boolean'];
  quantity: Scalars['numeric'];
};

/** aggregated selection of "ingredient_dish" */
export type Ingredient_Dish_Aggregate = {
  __typename?: 'ingredient_dish_aggregate';
  aggregate?: Maybe<Ingredient_Dish_Aggregate_Fields>;
  nodes: Array<Ingredient_Dish>;
};

/** aggregate fields of "ingredient_dish" */
export type Ingredient_Dish_Aggregate_Fields = {
  __typename?: 'ingredient_dish_aggregate_fields';
  avg?: Maybe<Ingredient_Dish_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ingredient_Dish_Max_Fields>;
  min?: Maybe<Ingredient_Dish_Min_Fields>;
  stddev?: Maybe<Ingredient_Dish_Stddev_Fields>;
  stddev_pop?: Maybe<Ingredient_Dish_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ingredient_Dish_Stddev_Samp_Fields>;
  sum?: Maybe<Ingredient_Dish_Sum_Fields>;
  var_pop?: Maybe<Ingredient_Dish_Var_Pop_Fields>;
  var_samp?: Maybe<Ingredient_Dish_Var_Samp_Fields>;
  variance?: Maybe<Ingredient_Dish_Variance_Fields>;
};


/** aggregate fields of "ingredient_dish" */
export type Ingredient_Dish_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ingredient_dish" */
export type Ingredient_Dish_Aggregate_Order_By = {
  avg?: Maybe<Ingredient_Dish_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ingredient_Dish_Max_Order_By>;
  min?: Maybe<Ingredient_Dish_Min_Order_By>;
  stddev?: Maybe<Ingredient_Dish_Stddev_Order_By>;
  stddev_pop?: Maybe<Ingredient_Dish_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ingredient_Dish_Stddev_Samp_Order_By>;
  sum?: Maybe<Ingredient_Dish_Sum_Order_By>;
  var_pop?: Maybe<Ingredient_Dish_Var_Pop_Order_By>;
  var_samp?: Maybe<Ingredient_Dish_Var_Samp_Order_By>;
  variance?: Maybe<Ingredient_Dish_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ingredient_dish" */
export type Ingredient_Dish_Arr_Rel_Insert_Input = {
  data: Array<Ingredient_Dish_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Ingredient_Dish_On_Conflict>;
};

/** aggregate avg on columns */
export type Ingredient_Dish_Avg_Fields = {
  __typename?: 'ingredient_dish_avg_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Avg_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ingredient_dish". All fields are combined with a logical 'AND'. */
export type Ingredient_Dish_Bool_Exp = {
  _and?: Maybe<Array<Ingredient_Dish_Bool_Exp>>;
  _not?: Maybe<Ingredient_Dish_Bool_Exp>;
  _or?: Maybe<Array<Ingredient_Dish_Bool_Exp>>;
  dish?: Maybe<Dishes_Bool_Exp>;
  dish_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ingredient?: Maybe<Ingredients_Bool_Exp>;
  ingredient_id?: Maybe<Int_Comparison_Exp>;
  optional?: Maybe<Boolean_Comparison_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "ingredient_dish" */
export enum Ingredient_Dish_Constraint {
  /** unique or primary key constraint */
  IngredientDishPkey = 'ingredient_dish_pkey'
}

/** input type for incrementing numeric columns in table "ingredient_dish" */
export type Ingredient_Dish_Inc_Input = {
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ingredient_dish" */
export type Ingredient_Dish_Insert_Input = {
  dish?: Maybe<Dishes_Obj_Rel_Insert_Input>;
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ingredient?: Maybe<Ingredients_Obj_Rel_Insert_Input>;
  ingredient_id?: Maybe<Scalars['Int']>;
  optional?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Ingredient_Dish_Max_Fields = {
  __typename?: 'ingredient_dish_max_fields';
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Max_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ingredient_Dish_Min_Fields = {
  __typename?: 'ingredient_dish_min_fields';
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Min_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "ingredient_dish" */
export type Ingredient_Dish_Mutation_Response = {
  __typename?: 'ingredient_dish_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ingredient_Dish>;
};

/** on_conflict condition type for table "ingredient_dish" */
export type Ingredient_Dish_On_Conflict = {
  constraint: Ingredient_Dish_Constraint;
  update_columns?: Array<Ingredient_Dish_Update_Column>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};

/** Ordering options when selecting data from "ingredient_dish". */
export type Ingredient_Dish_Order_By = {
  dish?: Maybe<Dishes_Order_By>;
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient?: Maybe<Ingredients_Order_By>;
  ingredient_id?: Maybe<Order_By>;
  optional?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: ingredient_dish */
export type Ingredient_Dish_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ingredient_dish" */
export enum Ingredient_Dish_Select_Column {
  /** column name */
  DishId = 'dish_id',
  /** column name */
  Id = 'id',
  /** column name */
  IngredientId = 'ingredient_id',
  /** column name */
  Optional = 'optional',
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "ingredient_dish" */
export type Ingredient_Dish_Set_Input = {
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  optional?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Ingredient_Dish_Stddev_Fields = {
  __typename?: 'ingredient_dish_stddev_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Stddev_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ingredient_Dish_Stddev_Pop_Fields = {
  __typename?: 'ingredient_dish_stddev_pop_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Stddev_Pop_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ingredient_Dish_Stddev_Samp_Fields = {
  __typename?: 'ingredient_dish_stddev_samp_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Stddev_Samp_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ingredient_Dish_Sum_Fields = {
  __typename?: 'ingredient_dish_sum_fields';
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Sum_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "ingredient_dish" */
export enum Ingredient_Dish_Update_Column {
  /** column name */
  DishId = 'dish_id',
  /** column name */
  Id = 'id',
  /** column name */
  IngredientId = 'ingredient_id',
  /** column name */
  Optional = 'optional',
  /** column name */
  Quantity = 'quantity'
}

/** aggregate var_pop on columns */
export type Ingredient_Dish_Var_Pop_Fields = {
  __typename?: 'ingredient_dish_var_pop_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Var_Pop_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ingredient_Dish_Var_Samp_Fields = {
  __typename?: 'ingredient_dish_var_samp_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Var_Samp_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ingredient_Dish_Variance_Fields = {
  __typename?: 'ingredient_dish_variance_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ingredient_dish" */
export type Ingredient_Dish_Variance_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** columns and relationships of "ingredients" */
export type Ingredients = {
  __typename?: 'ingredients';
  available: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  ingredient_dishes: Array<Ingredient_Dish>;
  /** An aggregate relationship */
  ingredient_dishes_aggregate: Ingredient_Dish_Aggregate;
  inventory: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  order_dish_ingredients: Array<Order_Dish_Ingredients>;
  /** An aggregate relationship */
  order_dish_ingredients_aggregate: Order_Dish_Ingredients_Aggregate;
  quantity: Scalars['numeric'];
  unit: Scalars['Int'];
  /** An object relationship */
  unitObject: Units;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "ingredients" */
export type IngredientsIngredient_DishesArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


/** columns and relationships of "ingredients" */
export type IngredientsIngredient_Dishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


/** columns and relationships of "ingredients" */
export type IngredientsOrder_Dish_IngredientsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


/** columns and relationships of "ingredients" */
export type IngredientsOrder_Dish_Ingredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};

/** aggregated selection of "ingredients" */
export type Ingredients_Aggregate = {
  __typename?: 'ingredients_aggregate';
  aggregate?: Maybe<Ingredients_Aggregate_Fields>;
  nodes: Array<Ingredients>;
};

/** aggregate fields of "ingredients" */
export type Ingredients_Aggregate_Fields = {
  __typename?: 'ingredients_aggregate_fields';
  avg?: Maybe<Ingredients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ingredients_Max_Fields>;
  min?: Maybe<Ingredients_Min_Fields>;
  stddev?: Maybe<Ingredients_Stddev_Fields>;
  stddev_pop?: Maybe<Ingredients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ingredients_Stddev_Samp_Fields>;
  sum?: Maybe<Ingredients_Sum_Fields>;
  var_pop?: Maybe<Ingredients_Var_Pop_Fields>;
  var_samp?: Maybe<Ingredients_Var_Samp_Fields>;
  variance?: Maybe<Ingredients_Variance_Fields>;
};


/** aggregate fields of "ingredients" */
export type Ingredients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ingredients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ingredients" */
export type Ingredients_Aggregate_Order_By = {
  avg?: Maybe<Ingredients_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ingredients_Max_Order_By>;
  min?: Maybe<Ingredients_Min_Order_By>;
  stddev?: Maybe<Ingredients_Stddev_Order_By>;
  stddev_pop?: Maybe<Ingredients_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ingredients_Stddev_Samp_Order_By>;
  sum?: Maybe<Ingredients_Sum_Order_By>;
  var_pop?: Maybe<Ingredients_Var_Pop_Order_By>;
  var_samp?: Maybe<Ingredients_Var_Samp_Order_By>;
  variance?: Maybe<Ingredients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ingredients" */
export type Ingredients_Arr_Rel_Insert_Input = {
  data: Array<Ingredients_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Ingredients_On_Conflict>;
};

/** aggregate avg on columns */
export type Ingredients_Avg_Fields = {
  __typename?: 'ingredients_avg_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ingredients" */
export type Ingredients_Avg_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ingredients". All fields are combined with a logical 'AND'. */
export type Ingredients_Bool_Exp = {
  _and?: Maybe<Array<Ingredients_Bool_Exp>>;
  _not?: Maybe<Ingredients_Bool_Exp>;
  _or?: Maybe<Array<Ingredients_Bool_Exp>>;
  available?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ingredient_dishes?: Maybe<Ingredient_Dish_Bool_Exp>;
  inventory?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
  unit?: Maybe<Int_Comparison_Exp>;
  unitObject?: Maybe<Units_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ingredients" */
export enum Ingredients_Constraint {
  /** unique or primary key constraint */
  IngredientsPkey = 'ingredients_pkey'
}

/** input type for incrementing numeric columns in table "ingredients" */
export type Ingredients_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ingredients" */
export type Ingredients_Insert_Input = {
  available?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_dishes?: Maybe<Ingredient_Dish_Arr_Rel_Insert_Input>;
  inventory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars['numeric']>;
  unit?: Maybe<Scalars['Int']>;
  unitObject?: Maybe<Units_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ingredients_Max_Fields = {
  __typename?: 'ingredients_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ingredients" */
export type Ingredients_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ingredients_Min_Fields = {
  __typename?: 'ingredients_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ingredients" */
export type Ingredients_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "ingredients" */
export type Ingredients_Mutation_Response = {
  __typename?: 'ingredients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ingredients>;
};

/** input type for inserting object relation for remote table "ingredients" */
export type Ingredients_Obj_Rel_Insert_Input = {
  data: Ingredients_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ingredients_On_Conflict>;
};

/** on_conflict condition type for table "ingredients" */
export type Ingredients_On_Conflict = {
  constraint: Ingredients_Constraint;
  update_columns?: Array<Ingredients_Update_Column>;
  where?: Maybe<Ingredients_Bool_Exp>;
};

/** Ordering options when selecting data from "ingredients". */
export type Ingredients_Order_By = {
  available?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient_dishes_aggregate?: Maybe<Ingredient_Dish_Aggregate_Order_By>;
  inventory?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order_dish_ingredients_aggregate?: Maybe<Order_Dish_Ingredients_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  unitObject?: Maybe<Units_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: ingredients */
export type Ingredients_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ingredients" */
export enum Ingredients_Select_Column {
  /** column name */
  Available = 'available',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Inventory = 'inventory',
  /** column name */
  Name = 'name',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ingredients" */
export type Ingredients_Set_Input = {
  available?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inventory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Ingredients_Stddev_Fields = {
  __typename?: 'ingredients_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ingredients" */
export type Ingredients_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ingredients_Stddev_Pop_Fields = {
  __typename?: 'ingredients_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ingredients" */
export type Ingredients_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ingredients_Stddev_Samp_Fields = {
  __typename?: 'ingredients_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ingredients" */
export type Ingredients_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ingredients_Sum_Fields = {
  __typename?: 'ingredients_sum_fields';
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ingredients" */
export type Ingredients_Sum_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** update columns of table "ingredients" */
export enum Ingredients_Update_Column {
  /** column name */
  Available = 'available',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Inventory = 'inventory',
  /** column name */
  Name = 'name',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Ingredients_Var_Pop_Fields = {
  __typename?: 'ingredients_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ingredients" */
export type Ingredients_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ingredients_Var_Samp_Fields = {
  __typename?: 'ingredients_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ingredients" */
export type Ingredients_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ingredients_Variance_Fields = {
  __typename?: 'ingredients_variance_fields';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ingredients" */
export type Ingredients_Variance_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** arendado */
export type Lessors = {
  __typename?: 'lessors';
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['Int'];
  diner_id: Scalars['Int'];
  disabled: Scalars['Boolean'];
  /** An object relationship */
  emergency_contact: Contact;
  emergency_contact_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  number: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "lessors" */
export type Lessors_Aggregate = {
  __typename?: 'lessors_aggregate';
  aggregate?: Maybe<Lessors_Aggregate_Fields>;
  nodes: Array<Lessors>;
};

/** aggregate fields of "lessors" */
export type Lessors_Aggregate_Fields = {
  __typename?: 'lessors_aggregate_fields';
  avg?: Maybe<Lessors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lessors_Max_Fields>;
  min?: Maybe<Lessors_Min_Fields>;
  stddev?: Maybe<Lessors_Stddev_Fields>;
  stddev_pop?: Maybe<Lessors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lessors_Stddev_Samp_Fields>;
  sum?: Maybe<Lessors_Sum_Fields>;
  var_pop?: Maybe<Lessors_Var_Pop_Fields>;
  var_samp?: Maybe<Lessors_Var_Samp_Fields>;
  variance?: Maybe<Lessors_Variance_Fields>;
};


/** aggregate fields of "lessors" */
export type Lessors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lessors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lessors" */
export type Lessors_Aggregate_Order_By = {
  avg?: Maybe<Lessors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lessors_Max_Order_By>;
  min?: Maybe<Lessors_Min_Order_By>;
  stddev?: Maybe<Lessors_Stddev_Order_By>;
  stddev_pop?: Maybe<Lessors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lessors_Stddev_Samp_Order_By>;
  sum?: Maybe<Lessors_Sum_Order_By>;
  var_pop?: Maybe<Lessors_Var_Pop_Order_By>;
  var_samp?: Maybe<Lessors_Var_Samp_Order_By>;
  variance?: Maybe<Lessors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lessors" */
export type Lessors_Arr_Rel_Insert_Input = {
  data: Array<Lessors_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lessors_On_Conflict>;
};

/** aggregate avg on columns */
export type Lessors_Avg_Fields = {
  __typename?: 'lessors_avg_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lessors" */
export type Lessors_Avg_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lessors". All fields are combined with a logical 'AND'. */
export type Lessors_Bool_Exp = {
  _and?: Maybe<Array<Lessors_Bool_Exp>>;
  _not?: Maybe<Lessors_Bool_Exp>;
  _or?: Maybe<Array<Lessors_Bool_Exp>>;
  contact?: Maybe<Contact_Bool_Exp>;
  contact_id?: Maybe<Int_Comparison_Exp>;
  diner_id?: Maybe<Int_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  emergency_contact?: Maybe<Contact_Bool_Exp>;
  emergency_contact_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  number?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lessors" */
export enum Lessors_Constraint {
  /** unique or primary key constraint */
  LessorsPkey = 'lessors_pkey'
}

/** input type for incrementing numeric columns in table "lessors" */
export type Lessors_Inc_Input = {
  contact_id?: Maybe<Scalars['Int']>;
  diner_id?: Maybe<Scalars['Int']>;
  emergency_contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lessors" */
export type Lessors_Insert_Input = {
  contact?: Maybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: Maybe<Scalars['Int']>;
  diner_id?: Maybe<Scalars['Int']>;
  disabled?: Maybe<Scalars['Boolean']>;
  emergency_contact?: Maybe<Contact_Obj_Rel_Insert_Input>;
  emergency_contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lessors_Max_Fields = {
  __typename?: 'lessors_max_fields';
  contact_id?: Maybe<Scalars['Int']>;
  diner_id?: Maybe<Scalars['Int']>;
  emergency_contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lessors" */
export type Lessors_Max_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lessors_Min_Fields = {
  __typename?: 'lessors_min_fields';
  contact_id?: Maybe<Scalars['Int']>;
  diner_id?: Maybe<Scalars['Int']>;
  emergency_contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lessors" */
export type Lessors_Min_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lessors" */
export type Lessors_Mutation_Response = {
  __typename?: 'lessors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lessors>;
};

/** on_conflict condition type for table "lessors" */
export type Lessors_On_Conflict = {
  constraint: Lessors_Constraint;
  update_columns?: Array<Lessors_Update_Column>;
  where?: Maybe<Lessors_Bool_Exp>;
};

/** Ordering options when selecting data from "lessors". */
export type Lessors_Order_By = {
  contact?: Maybe<Contact_Order_By>;
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  emergency_contact?: Maybe<Contact_Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: lessors */
export type Lessors_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "lessors" */
export enum Lessors_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  DinerId = 'diner_id',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmergencyContactId = 'emergency_contact_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "lessors" */
export type Lessors_Set_Input = {
  contact_id?: Maybe<Scalars['Int']>;
  diner_id?: Maybe<Scalars['Int']>;
  disabled?: Maybe<Scalars['Boolean']>;
  emergency_contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lessors_Stddev_Fields = {
  __typename?: 'lessors_stddev_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lessors" */
export type Lessors_Stddev_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lessors_Stddev_Pop_Fields = {
  __typename?: 'lessors_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lessors" */
export type Lessors_Stddev_Pop_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lessors_Stddev_Samp_Fields = {
  __typename?: 'lessors_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lessors" */
export type Lessors_Stddev_Samp_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lessors_Sum_Fields = {
  __typename?: 'lessors_sum_fields';
  contact_id?: Maybe<Scalars['Int']>;
  diner_id?: Maybe<Scalars['Int']>;
  emergency_contact_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lessors" */
export type Lessors_Sum_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "lessors" */
export enum Lessors_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  DinerId = 'diner_id',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmergencyContactId = 'emergency_contact_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Lessors_Var_Pop_Fields = {
  __typename?: 'lessors_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lessors" */
export type Lessors_Var_Pop_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lessors_Var_Samp_Fields = {
  __typename?: 'lessors_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lessors" */
export type Lessors_Var_Samp_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lessors_Variance_Fields = {
  __typename?: 'lessors_variance_fields';
  contact_id?: Maybe<Scalars['Float']>;
  diner_id?: Maybe<Scalars['Float']>;
  emergency_contact_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lessors" */
export type Lessors_Variance_Order_By = {
  contact_id?: Maybe<Order_By>;
  diner_id?: Maybe<Order_By>;
  emergency_contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "menu_types" */
export type Menu_Types = {
  __typename?: 'menu_types';
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  disabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
};


/** columns and relationships of "menu_types" */
export type Menu_TypesCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** columns and relationships of "menu_types" */
export type Menu_TypesCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};

/** aggregated selection of "menu_types" */
export type Menu_Types_Aggregate = {
  __typename?: 'menu_types_aggregate';
  aggregate?: Maybe<Menu_Types_Aggregate_Fields>;
  nodes: Array<Menu_Types>;
};

/** aggregate fields of "menu_types" */
export type Menu_Types_Aggregate_Fields = {
  __typename?: 'menu_types_aggregate_fields';
  avg?: Maybe<Menu_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Menu_Types_Max_Fields>;
  min?: Maybe<Menu_Types_Min_Fields>;
  stddev?: Maybe<Menu_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Menu_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Menu_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Menu_Types_Sum_Fields>;
  var_pop?: Maybe<Menu_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Menu_Types_Var_Samp_Fields>;
  variance?: Maybe<Menu_Types_Variance_Fields>;
};


/** aggregate fields of "menu_types" */
export type Menu_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Menu_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Menu_Types_Avg_Fields = {
  __typename?: 'menu_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "menu_types". All fields are combined with a logical 'AND'. */
export type Menu_Types_Bool_Exp = {
  _and?: Maybe<Array<Menu_Types_Bool_Exp>>;
  _not?: Maybe<Menu_Types_Bool_Exp>;
  _or?: Maybe<Array<Menu_Types_Bool_Exp>>;
  categories?: Maybe<Categories_Bool_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu_types" */
export enum Menu_Types_Constraint {
  /** unique or primary key constraint */
  MenuTypesPkey = 'menu_types_pkey'
}

/** input type for incrementing numeric columns in table "menu_types" */
export type Menu_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "menu_types" */
export type Menu_Types_Insert_Input = {
  categories?: Maybe<Categories_Arr_Rel_Insert_Input>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Menu_Types_Max_Fields = {
  __typename?: 'menu_types_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Menu_Types_Min_Fields = {
  __typename?: 'menu_types_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "menu_types" */
export type Menu_Types_Mutation_Response = {
  __typename?: 'menu_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Types>;
};

/** input type for inserting object relation for remote table "menu_types" */
export type Menu_Types_Obj_Rel_Insert_Input = {
  data: Menu_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Menu_Types_On_Conflict>;
};

/** on_conflict condition type for table "menu_types" */
export type Menu_Types_On_Conflict = {
  constraint: Menu_Types_Constraint;
  update_columns?: Array<Menu_Types_Update_Column>;
  where?: Maybe<Menu_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "menu_types". */
export type Menu_Types_Order_By = {
  categories_aggregate?: Maybe<Categories_Aggregate_Order_By>;
  disabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** primary key columns input for table: menu_types */
export type Menu_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "menu_types" */
export enum Menu_Types_Select_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "menu_types" */
export type Menu_Types_Set_Input = {
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Menu_Types_Stddev_Fields = {
  __typename?: 'menu_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Menu_Types_Stddev_Pop_Fields = {
  __typename?: 'menu_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Menu_Types_Stddev_Samp_Fields = {
  __typename?: 'menu_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Menu_Types_Sum_Fields = {
  __typename?: 'menu_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** update columns of table "menu_types" */
export enum Menu_Types_Update_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** aggregate var_pop on columns */
export type Menu_Types_Var_Pop_Fields = {
  __typename?: 'menu_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Menu_Types_Var_Samp_Fields = {
  __typename?: 'menu_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Menu_Types_Variance_Fields = {
  __typename?: 'menu_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "minutes" */
export type Minutes = {
  __typename?: 'minutes';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted: Scalars['Boolean'];
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  minute_type_id: Scalars['Int'];
  /** An object relationship */
  minutesTypeByMinuteTypeId: Minutes_Types;
  /** An object relationship */
  minutes_type?: Maybe<Minutes_Types>;
  sended: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "minutes" */
export type Minutes_Aggregate = {
  __typename?: 'minutes_aggregate';
  aggregate?: Maybe<Minutes_Aggregate_Fields>;
  nodes: Array<Minutes>;
};

/** aggregate fields of "minutes" */
export type Minutes_Aggregate_Fields = {
  __typename?: 'minutes_aggregate_fields';
  avg?: Maybe<Minutes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Minutes_Max_Fields>;
  min?: Maybe<Minutes_Min_Fields>;
  stddev?: Maybe<Minutes_Stddev_Fields>;
  stddev_pop?: Maybe<Minutes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Minutes_Stddev_Samp_Fields>;
  sum?: Maybe<Minutes_Sum_Fields>;
  var_pop?: Maybe<Minutes_Var_Pop_Fields>;
  var_samp?: Maybe<Minutes_Var_Samp_Fields>;
  variance?: Maybe<Minutes_Variance_Fields>;
};


/** aggregate fields of "minutes" */
export type Minutes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Minutes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "minutes" */
export type Minutes_Aggregate_Order_By = {
  avg?: Maybe<Minutes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Minutes_Max_Order_By>;
  min?: Maybe<Minutes_Min_Order_By>;
  stddev?: Maybe<Minutes_Stddev_Order_By>;
  stddev_pop?: Maybe<Minutes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Minutes_Stddev_Samp_Order_By>;
  sum?: Maybe<Minutes_Sum_Order_By>;
  var_pop?: Maybe<Minutes_Var_Pop_Order_By>;
  var_samp?: Maybe<Minutes_Var_Samp_Order_By>;
  variance?: Maybe<Minutes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "minutes" */
export type Minutes_Arr_Rel_Insert_Input = {
  data: Array<Minutes_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Minutes_On_Conflict>;
};

/** aggregate avg on columns */
export type Minutes_Avg_Fields = {
  __typename?: 'minutes_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "minutes" */
export type Minutes_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "minutes". All fields are combined with a logical 'AND'. */
export type Minutes_Bool_Exp = {
  _and?: Maybe<Array<Minutes_Bool_Exp>>;
  _not?: Maybe<Minutes_Bool_Exp>;
  _or?: Maybe<Array<Minutes_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  created_by?: Maybe<Int_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  minute_type_id?: Maybe<Int_Comparison_Exp>;
  minutesTypeByMinuteTypeId?: Maybe<Minutes_Types_Bool_Exp>;
  minutes_type?: Maybe<Minutes_Types_Bool_Exp>;
  sended?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "minutes" */
export enum Minutes_Constraint {
  /** unique or primary key constraint */
  MinutesPkey = 'minutes_pkey'
}

/** input type for incrementing numeric columns in table "minutes" */
export type Minutes_Inc_Input = {
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  minute_type_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "minutes" */
export type Minutes_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  minute_type_id?: Maybe<Scalars['Int']>;
  minutesTypeByMinuteTypeId?: Maybe<Minutes_Types_Obj_Rel_Insert_Input>;
  minutes_type?: Maybe<Minutes_Types_Obj_Rel_Insert_Input>;
  sended?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Minutes_Max_Fields = {
  __typename?: 'minutes_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  minute_type_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "minutes" */
export type Minutes_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Minutes_Min_Fields = {
  __typename?: 'minutes_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  minute_type_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "minutes" */
export type Minutes_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "minutes" */
export type Minutes_Mutation_Response = {
  __typename?: 'minutes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Minutes>;
};

/** on_conflict condition type for table "minutes" */
export type Minutes_On_Conflict = {
  constraint: Minutes_Constraint;
  update_columns?: Array<Minutes_Update_Column>;
  where?: Maybe<Minutes_Bool_Exp>;
};

/** Ordering options when selecting data from "minutes". */
export type Minutes_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
  minutesTypeByMinuteTypeId?: Maybe<Minutes_Types_Order_By>;
  minutes_type?: Maybe<Minutes_Types_Order_By>;
  sended?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: minutes */
export type Minutes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "minutes" */
export enum Minutes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  MinuteTypeId = 'minute_type_id',
  /** column name */
  Sended = 'sended',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "minutes" */
export type Minutes_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  minute_type_id?: Maybe<Scalars['Int']>;
  sended?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Minutes_Stddev_Fields = {
  __typename?: 'minutes_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "minutes" */
export type Minutes_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Minutes_Stddev_Pop_Fields = {
  __typename?: 'minutes_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "minutes" */
export type Minutes_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Minutes_Stddev_Samp_Fields = {
  __typename?: 'minutes_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "minutes" */
export type Minutes_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Minutes_Sum_Fields = {
  __typename?: 'minutes_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  minute_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "minutes" */
export type Minutes_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "minutes_types" */
export type Minutes_Types = {
  __typename?: 'minutes_types';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  minutes: Array<Minutes>;
  /** An array relationship */
  minutesByMinuteTypeId: Array<Minutes>;
  /** An aggregate relationship */
  minutesByMinuteTypeId_aggregate: Minutes_Aggregate;
  /** An aggregate relationship */
  minutes_aggregate: Minutes_Aggregate;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "minutes_types" */
export type Minutes_TypesMinutesArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


/** columns and relationships of "minutes_types" */
export type Minutes_TypesMinutesByMinuteTypeIdArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


/** columns and relationships of "minutes_types" */
export type Minutes_TypesMinutesByMinuteTypeId_AggregateArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


/** columns and relationships of "minutes_types" */
export type Minutes_TypesMinutes_AggregateArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};

/** aggregated selection of "minutes_types" */
export type Minutes_Types_Aggregate = {
  __typename?: 'minutes_types_aggregate';
  aggregate?: Maybe<Minutes_Types_Aggregate_Fields>;
  nodes: Array<Minutes_Types>;
};

/** aggregate fields of "minutes_types" */
export type Minutes_Types_Aggregate_Fields = {
  __typename?: 'minutes_types_aggregate_fields';
  avg?: Maybe<Minutes_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Minutes_Types_Max_Fields>;
  min?: Maybe<Minutes_Types_Min_Fields>;
  stddev?: Maybe<Minutes_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Minutes_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Minutes_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Minutes_Types_Sum_Fields>;
  var_pop?: Maybe<Minutes_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Minutes_Types_Var_Samp_Fields>;
  variance?: Maybe<Minutes_Types_Variance_Fields>;
};


/** aggregate fields of "minutes_types" */
export type Minutes_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Minutes_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Minutes_Types_Avg_Fields = {
  __typename?: 'minutes_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "minutes_types". All fields are combined with a logical 'AND'. */
export type Minutes_Types_Bool_Exp = {
  _and?: Maybe<Array<Minutes_Types_Bool_Exp>>;
  _not?: Maybe<Minutes_Types_Bool_Exp>;
  _or?: Maybe<Array<Minutes_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  minutes?: Maybe<Minutes_Bool_Exp>;
  minutesByMinuteTypeId?: Maybe<Minutes_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "minutes_types" */
export enum Minutes_Types_Constraint {
  /** unique or primary key constraint */
  MinutesTypesPkey = 'minutes_types_pkey'
}

/** input type for incrementing numeric columns in table "minutes_types" */
export type Minutes_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "minutes_types" */
export type Minutes_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Minutes_Arr_Rel_Insert_Input>;
  minutesByMinuteTypeId?: Maybe<Minutes_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Minutes_Types_Max_Fields = {
  __typename?: 'minutes_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Minutes_Types_Min_Fields = {
  __typename?: 'minutes_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "minutes_types" */
export type Minutes_Types_Mutation_Response = {
  __typename?: 'minutes_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Minutes_Types>;
};

/** input type for inserting object relation for remote table "minutes_types" */
export type Minutes_Types_Obj_Rel_Insert_Input = {
  data: Minutes_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Minutes_Types_On_Conflict>;
};

/** on_conflict condition type for table "minutes_types" */
export type Minutes_Types_On_Conflict = {
  constraint: Minutes_Types_Constraint;
  update_columns?: Array<Minutes_Types_Update_Column>;
  where?: Maybe<Minutes_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "minutes_types". */
export type Minutes_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minutesByMinuteTypeId_aggregate?: Maybe<Minutes_Aggregate_Order_By>;
  minutes_aggregate?: Maybe<Minutes_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: minutes_types */
export type Minutes_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "minutes_types" */
export enum Minutes_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "minutes_types" */
export type Minutes_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Minutes_Types_Stddev_Fields = {
  __typename?: 'minutes_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Minutes_Types_Stddev_Pop_Fields = {
  __typename?: 'minutes_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Minutes_Types_Stddev_Samp_Fields = {
  __typename?: 'minutes_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Minutes_Types_Sum_Fields = {
  __typename?: 'minutes_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "minutes_types" */
export enum Minutes_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Minutes_Types_Var_Pop_Fields = {
  __typename?: 'minutes_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Minutes_Types_Var_Samp_Fields = {
  __typename?: 'minutes_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Minutes_Types_Variance_Fields = {
  __typename?: 'minutes_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "minutes" */
export enum Minutes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  MinuteTypeId = 'minute_type_id',
  /** column name */
  Sended = 'sended',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Minutes_Var_Pop_Fields = {
  __typename?: 'minutes_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "minutes" */
export type Minutes_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Minutes_Var_Samp_Fields = {
  __typename?: 'minutes_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "minutes" */
export type Minutes_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Minutes_Variance_Fields = {
  __typename?: 'minutes_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "minutes" */
export type Minutes_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minute_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "modifier_group_dish" */
export type Modifier_Group_Dish = {
  __typename?: 'modifier_group_dish';
  created_at: Scalars['timestamptz'];
  disabled: Scalars['Boolean'];
  /** An object relationship */
  dish: Dishes;
  dish_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  modifier_group: Modifier_Groups;
  modifier_group_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "modifier_group_dish" */
export type Modifier_Group_Dish_Aggregate = {
  __typename?: 'modifier_group_dish_aggregate';
  aggregate?: Maybe<Modifier_Group_Dish_Aggregate_Fields>;
  nodes: Array<Modifier_Group_Dish>;
};

/** aggregate fields of "modifier_group_dish" */
export type Modifier_Group_Dish_Aggregate_Fields = {
  __typename?: 'modifier_group_dish_aggregate_fields';
  avg?: Maybe<Modifier_Group_Dish_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Modifier_Group_Dish_Max_Fields>;
  min?: Maybe<Modifier_Group_Dish_Min_Fields>;
  stddev?: Maybe<Modifier_Group_Dish_Stddev_Fields>;
  stddev_pop?: Maybe<Modifier_Group_Dish_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Modifier_Group_Dish_Stddev_Samp_Fields>;
  sum?: Maybe<Modifier_Group_Dish_Sum_Fields>;
  var_pop?: Maybe<Modifier_Group_Dish_Var_Pop_Fields>;
  var_samp?: Maybe<Modifier_Group_Dish_Var_Samp_Fields>;
  variance?: Maybe<Modifier_Group_Dish_Variance_Fields>;
};


/** aggregate fields of "modifier_group_dish" */
export type Modifier_Group_Dish_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "modifier_group_dish" */
export type Modifier_Group_Dish_Aggregate_Order_By = {
  avg?: Maybe<Modifier_Group_Dish_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Modifier_Group_Dish_Max_Order_By>;
  min?: Maybe<Modifier_Group_Dish_Min_Order_By>;
  stddev?: Maybe<Modifier_Group_Dish_Stddev_Order_By>;
  stddev_pop?: Maybe<Modifier_Group_Dish_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Modifier_Group_Dish_Stddev_Samp_Order_By>;
  sum?: Maybe<Modifier_Group_Dish_Sum_Order_By>;
  var_pop?: Maybe<Modifier_Group_Dish_Var_Pop_Order_By>;
  var_samp?: Maybe<Modifier_Group_Dish_Var_Samp_Order_By>;
  variance?: Maybe<Modifier_Group_Dish_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "modifier_group_dish" */
export type Modifier_Group_Dish_Arr_Rel_Insert_Input = {
  data: Array<Modifier_Group_Dish_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Modifier_Group_Dish_On_Conflict>;
};

/** aggregate avg on columns */
export type Modifier_Group_Dish_Avg_Fields = {
  __typename?: 'modifier_group_dish_avg_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Avg_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "modifier_group_dish". All fields are combined with a logical 'AND'. */
export type Modifier_Group_Dish_Bool_Exp = {
  _and?: Maybe<Array<Modifier_Group_Dish_Bool_Exp>>;
  _not?: Maybe<Modifier_Group_Dish_Bool_Exp>;
  _or?: Maybe<Array<Modifier_Group_Dish_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  dish?: Maybe<Dishes_Bool_Exp>;
  dish_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  modifier_group?: Maybe<Modifier_Groups_Bool_Exp>;
  modifier_group_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "modifier_group_dish" */
export enum Modifier_Group_Dish_Constraint {
  /** unique or primary key constraint */
  ModifiersGroupDishPkey = 'modifiers_group_dish_pkey'
}

/** input type for incrementing numeric columns in table "modifier_group_dish" */
export type Modifier_Group_Dish_Inc_Input = {
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "modifier_group_dish" */
export type Modifier_Group_Dish_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  dish?: Maybe<Dishes_Obj_Rel_Insert_Input>;
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group?: Maybe<Modifier_Groups_Obj_Rel_Insert_Input>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Modifier_Group_Dish_Max_Fields = {
  __typename?: 'modifier_group_dish_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Modifier_Group_Dish_Min_Fields = {
  __typename?: 'modifier_group_dish_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "modifier_group_dish" */
export type Modifier_Group_Dish_Mutation_Response = {
  __typename?: 'modifier_group_dish_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Modifier_Group_Dish>;
};

/** on_conflict condition type for table "modifier_group_dish" */
export type Modifier_Group_Dish_On_Conflict = {
  constraint: Modifier_Group_Dish_Constraint;
  update_columns?: Array<Modifier_Group_Dish_Update_Column>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};

/** Ordering options when selecting data from "modifier_group_dish". */
export type Modifier_Group_Dish_Order_By = {
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  dish?: Maybe<Dishes_Order_By>;
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group?: Maybe<Modifier_Groups_Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: modifier_group_dish */
export type Modifier_Group_Dish_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "modifier_group_dish" */
export enum Modifier_Group_Dish_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DishId = 'dish_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierGroupId = 'modifier_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "modifier_group_dish" */
export type Modifier_Group_Dish_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Modifier_Group_Dish_Stddev_Fields = {
  __typename?: 'modifier_group_dish_stddev_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Stddev_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Modifier_Group_Dish_Stddev_Pop_Fields = {
  __typename?: 'modifier_group_dish_stddev_pop_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Stddev_Pop_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Modifier_Group_Dish_Stddev_Samp_Fields = {
  __typename?: 'modifier_group_dish_stddev_samp_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Stddev_Samp_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Modifier_Group_Dish_Sum_Fields = {
  __typename?: 'modifier_group_dish_sum_fields';
  dish_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Sum_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** update columns of table "modifier_group_dish" */
export enum Modifier_Group_Dish_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DishId = 'dish_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierGroupId = 'modifier_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Modifier_Group_Dish_Var_Pop_Fields = {
  __typename?: 'modifier_group_dish_var_pop_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Var_Pop_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Modifier_Group_Dish_Var_Samp_Fields = {
  __typename?: 'modifier_group_dish_var_samp_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Var_Samp_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Modifier_Group_Dish_Variance_Fields = {
  __typename?: 'modifier_group_dish_variance_fields';
  dish_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "modifier_group_dish" */
export type Modifier_Group_Dish_Variance_Order_By = {
  dish_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** columns and relationships of "modifier_groups" */
export type Modifier_Groups = {
  __typename?: 'modifier_groups';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  modifier_group_dishes: Array<Modifier_Group_Dish>;
  /** An aggregate relationship */
  modifier_group_dishes_aggregate: Modifier_Group_Dish_Aggregate;
  /** An array relationship */
  modifiers: Array<Modifiers>;
  /** An aggregate relationship */
  modifiers_aggregate: Modifiers_Aggregate;
  multiple: Scalars['Boolean'];
  /** An array relationship */
  order_dish_modifier_groups: Array<Order_Dish_Modifier_Groups>;
  /** An aggregate relationship */
  order_dish_modifier_groups_aggregate: Order_Dish_Modifier_Groups_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "modifier_groups" */
export type Modifier_GroupsModifier_Group_DishesArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


/** columns and relationships of "modifier_groups" */
export type Modifier_GroupsModifier_Group_Dishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


/** columns and relationships of "modifier_groups" */
export type Modifier_GroupsModifiersArgs = {
  distinct_on?: Maybe<Array<Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifiers_Order_By>>;
  where?: Maybe<Modifiers_Bool_Exp>;
};


/** columns and relationships of "modifier_groups" */
export type Modifier_GroupsModifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifiers_Order_By>>;
  where?: Maybe<Modifiers_Bool_Exp>;
};


/** columns and relationships of "modifier_groups" */
export type Modifier_GroupsOrder_Dish_Modifier_GroupsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};


/** columns and relationships of "modifier_groups" */
export type Modifier_GroupsOrder_Dish_Modifier_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};

/** aggregated selection of "modifier_groups" */
export type Modifier_Groups_Aggregate = {
  __typename?: 'modifier_groups_aggregate';
  aggregate?: Maybe<Modifier_Groups_Aggregate_Fields>;
  nodes: Array<Modifier_Groups>;
};

/** aggregate fields of "modifier_groups" */
export type Modifier_Groups_Aggregate_Fields = {
  __typename?: 'modifier_groups_aggregate_fields';
  avg?: Maybe<Modifier_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Modifier_Groups_Max_Fields>;
  min?: Maybe<Modifier_Groups_Min_Fields>;
  stddev?: Maybe<Modifier_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Modifier_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Modifier_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Modifier_Groups_Sum_Fields>;
  var_pop?: Maybe<Modifier_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Modifier_Groups_Var_Samp_Fields>;
  variance?: Maybe<Modifier_Groups_Variance_Fields>;
};


/** aggregate fields of "modifier_groups" */
export type Modifier_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Modifier_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Modifier_Groups_Avg_Fields = {
  __typename?: 'modifier_groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "modifier_groups". All fields are combined with a logical 'AND'. */
export type Modifier_Groups_Bool_Exp = {
  _and?: Maybe<Array<Modifier_Groups_Bool_Exp>>;
  _not?: Maybe<Modifier_Groups_Bool_Exp>;
  _or?: Maybe<Array<Modifier_Groups_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  modifier_group_dishes?: Maybe<Modifier_Group_Dish_Bool_Exp>;
  modifiers?: Maybe<Modifiers_Bool_Exp>;
  multiple?: Maybe<Boolean_Comparison_Exp>;
  order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "modifier_groups" */
export enum Modifier_Groups_Constraint {
  /** unique or primary key constraint */
  ModifersGroupsPkey = 'modifers_groups_pkey'
}

/** input type for incrementing numeric columns in table "modifier_groups" */
export type Modifier_Groups_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "modifier_groups" */
export type Modifier_Groups_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_dishes?: Maybe<Modifier_Group_Dish_Arr_Rel_Insert_Input>;
  modifiers?: Maybe<Modifiers_Arr_Rel_Insert_Input>;
  multiple?: Maybe<Scalars['Boolean']>;
  order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Modifier_Groups_Max_Fields = {
  __typename?: 'modifier_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Modifier_Groups_Min_Fields = {
  __typename?: 'modifier_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "modifier_groups" */
export type Modifier_Groups_Mutation_Response = {
  __typename?: 'modifier_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Modifier_Groups>;
};

/** input type for inserting object relation for remote table "modifier_groups" */
export type Modifier_Groups_Obj_Rel_Insert_Input = {
  data: Modifier_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Modifier_Groups_On_Conflict>;
};

/** on_conflict condition type for table "modifier_groups" */
export type Modifier_Groups_On_Conflict = {
  constraint: Modifier_Groups_Constraint;
  update_columns?: Array<Modifier_Groups_Update_Column>;
  where?: Maybe<Modifier_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "modifier_groups". */
export type Modifier_Groups_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_dishes_aggregate?: Maybe<Modifier_Group_Dish_Aggregate_Order_By>;
  modifiers_aggregate?: Maybe<Modifiers_Aggregate_Order_By>;
  multiple?: Maybe<Order_By>;
  order_dish_modifier_groups_aggregate?: Maybe<Order_Dish_Modifier_Groups_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: modifier_groups */
export type Modifier_Groups_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "modifier_groups" */
export enum Modifier_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiple = 'multiple',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "modifier_groups" */
export type Modifier_Groups_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  multiple?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Modifier_Groups_Stddev_Fields = {
  __typename?: 'modifier_groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Modifier_Groups_Stddev_Pop_Fields = {
  __typename?: 'modifier_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Modifier_Groups_Stddev_Samp_Fields = {
  __typename?: 'modifier_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Modifier_Groups_Sum_Fields = {
  __typename?: 'modifier_groups_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "modifier_groups" */
export enum Modifier_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiple = 'multiple',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Modifier_Groups_Var_Pop_Fields = {
  __typename?: 'modifier_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Modifier_Groups_Var_Samp_Fields = {
  __typename?: 'modifier_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Modifier_Groups_Variance_Fields = {
  __typename?: 'modifier_groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "modifiers" */
export type Modifiers = {
  __typename?: 'modifiers';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  modifier_group: Modifier_Groups;
  modifier_group_id: Scalars['Int'];
  /** An array relationship */
  order_dish_modifiers: Array<Order_Dish_Modifiers>;
  /** An aggregate relationship */
  order_dish_modifiers_aggregate: Order_Dish_Modifiers_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "modifiers" */
export type ModifiersOrder_Dish_ModifiersArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};


/** columns and relationships of "modifiers" */
export type ModifiersOrder_Dish_Modifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};

/** aggregated selection of "modifiers" */
export type Modifiers_Aggregate = {
  __typename?: 'modifiers_aggregate';
  aggregate?: Maybe<Modifiers_Aggregate_Fields>;
  nodes: Array<Modifiers>;
};

/** aggregate fields of "modifiers" */
export type Modifiers_Aggregate_Fields = {
  __typename?: 'modifiers_aggregate_fields';
  avg?: Maybe<Modifiers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Modifiers_Max_Fields>;
  min?: Maybe<Modifiers_Min_Fields>;
  stddev?: Maybe<Modifiers_Stddev_Fields>;
  stddev_pop?: Maybe<Modifiers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Modifiers_Stddev_Samp_Fields>;
  sum?: Maybe<Modifiers_Sum_Fields>;
  var_pop?: Maybe<Modifiers_Var_Pop_Fields>;
  var_samp?: Maybe<Modifiers_Var_Samp_Fields>;
  variance?: Maybe<Modifiers_Variance_Fields>;
};


/** aggregate fields of "modifiers" */
export type Modifiers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Modifiers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "modifiers" */
export type Modifiers_Aggregate_Order_By = {
  avg?: Maybe<Modifiers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Modifiers_Max_Order_By>;
  min?: Maybe<Modifiers_Min_Order_By>;
  stddev?: Maybe<Modifiers_Stddev_Order_By>;
  stddev_pop?: Maybe<Modifiers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Modifiers_Stddev_Samp_Order_By>;
  sum?: Maybe<Modifiers_Sum_Order_By>;
  var_pop?: Maybe<Modifiers_Var_Pop_Order_By>;
  var_samp?: Maybe<Modifiers_Var_Samp_Order_By>;
  variance?: Maybe<Modifiers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "modifiers" */
export type Modifiers_Arr_Rel_Insert_Input = {
  data: Array<Modifiers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Modifiers_On_Conflict>;
};

/** aggregate avg on columns */
export type Modifiers_Avg_Fields = {
  __typename?: 'modifiers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "modifiers" */
export type Modifiers_Avg_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "modifiers". All fields are combined with a logical 'AND'. */
export type Modifiers_Bool_Exp = {
  _and?: Maybe<Array<Modifiers_Bool_Exp>>;
  _not?: Maybe<Modifiers_Bool_Exp>;
  _or?: Maybe<Array<Modifiers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  modifier_group?: Maybe<Modifier_Groups_Bool_Exp>;
  modifier_group_id?: Maybe<Int_Comparison_Exp>;
  order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "modifiers" */
export enum Modifiers_Constraint {
  /** unique or primary key constraint */
  ModifiersPkey = 'modifiers_pkey'
}

/** input type for incrementing numeric columns in table "modifiers" */
export type Modifiers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "modifiers" */
export type Modifiers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group?: Maybe<Modifier_Groups_Obj_Rel_Insert_Input>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Modifiers_Max_Fields = {
  __typename?: 'modifiers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "modifiers" */
export type Modifiers_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Modifiers_Min_Fields = {
  __typename?: 'modifiers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "modifiers" */
export type Modifiers_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "modifiers" */
export type Modifiers_Mutation_Response = {
  __typename?: 'modifiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Modifiers>;
};

/** input type for inserting object relation for remote table "modifiers" */
export type Modifiers_Obj_Rel_Insert_Input = {
  data: Modifiers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Modifiers_On_Conflict>;
};

/** on_conflict condition type for table "modifiers" */
export type Modifiers_On_Conflict = {
  constraint: Modifiers_Constraint;
  update_columns?: Array<Modifiers_Update_Column>;
  where?: Maybe<Modifiers_Bool_Exp>;
};

/** Ordering options when selecting data from "modifiers". */
export type Modifiers_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group?: Maybe<Modifier_Groups_Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_modifiers_aggregate?: Maybe<Order_Dish_Modifiers_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: modifiers */
export type Modifiers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "modifiers" */
export enum Modifiers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierGroupId = 'modifier_group_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "modifiers" */
export type Modifiers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Modifiers_Stddev_Fields = {
  __typename?: 'modifiers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "modifiers" */
export type Modifiers_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Modifiers_Stddev_Pop_Fields = {
  __typename?: 'modifiers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "modifiers" */
export type Modifiers_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Modifiers_Stddev_Samp_Fields = {
  __typename?: 'modifiers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "modifiers" */
export type Modifiers_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Modifiers_Sum_Fields = {
  __typename?: 'modifiers_sum_fields';
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "modifiers" */
export type Modifiers_Sum_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** update columns of table "modifiers" */
export enum Modifiers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierGroupId = 'modifier_group_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Modifiers_Var_Pop_Fields = {
  __typename?: 'modifiers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "modifiers" */
export type Modifiers_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Modifiers_Var_Samp_Fields = {
  __typename?: 'modifiers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "modifiers" */
export type Modifiers_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Modifiers_Variance_Fields = {
  __typename?: 'modifiers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "modifiers" */
export type Modifiers_Variance_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
};

/** columns and relationships of "motions" */
export type Motions = {
  __typename?: 'motions';
  date: Scalars['date'];
  description: Scalars['String'];
  id: Scalars['Int'];
  id_user: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "motions" */
export type Motions_Aggregate = {
  __typename?: 'motions_aggregate';
  aggregate?: Maybe<Motions_Aggregate_Fields>;
  nodes: Array<Motions>;
};

/** aggregate fields of "motions" */
export type Motions_Aggregate_Fields = {
  __typename?: 'motions_aggregate_fields';
  avg?: Maybe<Motions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Motions_Max_Fields>;
  min?: Maybe<Motions_Min_Fields>;
  stddev?: Maybe<Motions_Stddev_Fields>;
  stddev_pop?: Maybe<Motions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Motions_Stddev_Samp_Fields>;
  sum?: Maybe<Motions_Sum_Fields>;
  var_pop?: Maybe<Motions_Var_Pop_Fields>;
  var_samp?: Maybe<Motions_Var_Samp_Fields>;
  variance?: Maybe<Motions_Variance_Fields>;
};


/** aggregate fields of "motions" */
export type Motions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Motions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Motions_Avg_Fields = {
  __typename?: 'motions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "motions". All fields are combined with a logical 'AND'. */
export type Motions_Bool_Exp = {
  _and?: Maybe<Array<Motions_Bool_Exp>>;
  _not?: Maybe<Motions_Bool_Exp>;
  _or?: Maybe<Array<Motions_Bool_Exp>>;
  date?: Maybe<Date_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  id_user?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "motions" */
export enum Motions_Constraint {
  /** unique or primary key constraint */
  MotionsPkey = 'Motions_pkey'
}

/** input type for incrementing numeric columns in table "motions" */
export type Motions_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "motions" */
export type Motions_Insert_Input = {
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Motions_Max_Fields = {
  __typename?: 'motions_max_fields';
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Motions_Min_Fields = {
  __typename?: 'motions_min_fields';
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "motions" */
export type Motions_Mutation_Response = {
  __typename?: 'motions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Motions>;
};

/** on_conflict condition type for table "motions" */
export type Motions_On_Conflict = {
  constraint: Motions_Constraint;
  update_columns?: Array<Motions_Update_Column>;
  where?: Maybe<Motions_Bool_Exp>;
};

/** Ordering options when selecting data from "motions". */
export type Motions_Order_By = {
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: motions */
export type Motions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "motions" */
export enum Motions_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IdUser = 'id_user',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "motions" */
export type Motions_Set_Input = {
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Motions_Stddev_Fields = {
  __typename?: 'motions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Motions_Stddev_Pop_Fields = {
  __typename?: 'motions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Motions_Stddev_Samp_Fields = {
  __typename?: 'motions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Motions_Sum_Fields = {
  __typename?: 'motions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
};

/** update columns of table "motions" */
export enum Motions_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IdUser = 'id_user',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Motions_Var_Pop_Fields = {
  __typename?: 'motions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Motions_Var_Samp_Fields = {
  __typename?: 'motions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Motions_Variance_Fields = {
  __typename?: 'motions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "motions_vote" */
export type Motions_Vote = {
  __typename?: 'motions_vote';
  date: Scalars['date'];
  id: Scalars['Int'];
  id_user: Scalars['Int'];
  /** An object relationship */
  user: Users;
  vote: Scalars['Int'];
};

/** aggregated selection of "motions_vote" */
export type Motions_Vote_Aggregate = {
  __typename?: 'motions_vote_aggregate';
  aggregate?: Maybe<Motions_Vote_Aggregate_Fields>;
  nodes: Array<Motions_Vote>;
};

/** aggregate fields of "motions_vote" */
export type Motions_Vote_Aggregate_Fields = {
  __typename?: 'motions_vote_aggregate_fields';
  avg?: Maybe<Motions_Vote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Motions_Vote_Max_Fields>;
  min?: Maybe<Motions_Vote_Min_Fields>;
  stddev?: Maybe<Motions_Vote_Stddev_Fields>;
  stddev_pop?: Maybe<Motions_Vote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Motions_Vote_Stddev_Samp_Fields>;
  sum?: Maybe<Motions_Vote_Sum_Fields>;
  var_pop?: Maybe<Motions_Vote_Var_Pop_Fields>;
  var_samp?: Maybe<Motions_Vote_Var_Samp_Fields>;
  variance?: Maybe<Motions_Vote_Variance_Fields>;
};


/** aggregate fields of "motions_vote" */
export type Motions_Vote_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Motions_Vote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "motions_vote" */
export type Motions_Vote_Aggregate_Order_By = {
  avg?: Maybe<Motions_Vote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Motions_Vote_Max_Order_By>;
  min?: Maybe<Motions_Vote_Min_Order_By>;
  stddev?: Maybe<Motions_Vote_Stddev_Order_By>;
  stddev_pop?: Maybe<Motions_Vote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Motions_Vote_Stddev_Samp_Order_By>;
  sum?: Maybe<Motions_Vote_Sum_Order_By>;
  var_pop?: Maybe<Motions_Vote_Var_Pop_Order_By>;
  var_samp?: Maybe<Motions_Vote_Var_Samp_Order_By>;
  variance?: Maybe<Motions_Vote_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "motions_vote" */
export type Motions_Vote_Arr_Rel_Insert_Input = {
  data: Array<Motions_Vote_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Motions_Vote_On_Conflict>;
};

/** aggregate avg on columns */
export type Motions_Vote_Avg_Fields = {
  __typename?: 'motions_vote_avg_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "motions_vote" */
export type Motions_Vote_Avg_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "motions_vote". All fields are combined with a logical 'AND'. */
export type Motions_Vote_Bool_Exp = {
  _and?: Maybe<Array<Motions_Vote_Bool_Exp>>;
  _not?: Maybe<Motions_Vote_Bool_Exp>;
  _or?: Maybe<Array<Motions_Vote_Bool_Exp>>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  id_user?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  vote?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "motions_vote" */
export enum Motions_Vote_Constraint {
  /** unique or primary key constraint */
  MotionsVotePkey = 'motions_vote_pkey'
}

/** input type for incrementing numeric columns in table "motions_vote" */
export type Motions_Vote_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "motions_vote" */
export type Motions_Vote_Insert_Input = {
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  vote?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Motions_Vote_Max_Fields = {
  __typename?: 'motions_vote_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "motions_vote" */
export type Motions_Vote_Max_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Motions_Vote_Min_Fields = {
  __typename?: 'motions_vote_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "motions_vote" */
export type Motions_Vote_Min_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** response of any mutation on the table "motions_vote" */
export type Motions_Vote_Mutation_Response = {
  __typename?: 'motions_vote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Motions_Vote>;
};

/** on_conflict condition type for table "motions_vote" */
export type Motions_Vote_On_Conflict = {
  constraint: Motions_Vote_Constraint;
  update_columns?: Array<Motions_Vote_Update_Column>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};

/** Ordering options when selecting data from "motions_vote". */
export type Motions_Vote_Order_By = {
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  vote?: Maybe<Order_By>;
};

/** primary key columns input for table: motions_vote */
export type Motions_Vote_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "motions_vote" */
export enum Motions_Vote_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  IdUser = 'id_user',
  /** column name */
  Vote = 'vote'
}

/** input type for updating data in table "motions_vote" */
export type Motions_Vote_Set_Input = {
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Motions_Vote_Stddev_Fields = {
  __typename?: 'motions_vote_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "motions_vote" */
export type Motions_Vote_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Motions_Vote_Stddev_Pop_Fields = {
  __typename?: 'motions_vote_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "motions_vote" */
export type Motions_Vote_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Motions_Vote_Stddev_Samp_Fields = {
  __typename?: 'motions_vote_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "motions_vote" */
export type Motions_Vote_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Motions_Vote_Sum_Fields = {
  __typename?: 'motions_vote_sum_fields';
  id?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "motions_vote" */
export type Motions_Vote_Sum_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** update columns of table "motions_vote" */
export enum Motions_Vote_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  IdUser = 'id_user',
  /** column name */
  Vote = 'vote'
}

/** aggregate var_pop on columns */
export type Motions_Vote_Var_Pop_Fields = {
  __typename?: 'motions_vote_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "motions_vote" */
export type Motions_Vote_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Motions_Vote_Var_Samp_Fields = {
  __typename?: 'motions_vote_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "motions_vote" */
export type Motions_Vote_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Motions_Vote_Variance_Fields = {
  __typename?: 'motions_vote_variance_fields';
  id?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  vote?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "motions_vote" */
export type Motions_Vote_Variance_Order_By = {
  id?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  CreateUserAccount?: Maybe<CreateUserAccountOutput>;
  SwitchUserStatus?: Maybe<SwitchUserStatusOutput>;
  // GenerateStatementPdf?: Maybe<PrintResult>;
  UpdateUserEmail?: Maybe<UpdateUserEmailOutput>;
  UpdateUserRole?: Maybe<UpdateUserRoleOutput>;
  /** delete data from the table: "account_tickets" */
  delete_account_tickets?: Maybe<Account_Tickets_Mutation_Response>;
  /** delete single row from the table: "account_tickets" */
  delete_account_tickets_by_pk?: Maybe<Account_Tickets>;
  /** delete data from the table: "activities" */
  delete_activities?: Maybe<Activities_Mutation_Response>;
  /** delete single row from the table: "activities" */
  delete_activities_by_pk?: Maybe<Activities>;
  /** delete data from the table: "addresses" */
  delete_addresses?: Maybe<Addresses_Mutation_Response>;
  /** delete single row from the table: "addresses" */
  delete_addresses_by_pk?: Maybe<Addresses>;
  /** delete data from the table: "admin_items" */
  delete_admin_items?: Maybe<Admin_Items_Mutation_Response>;
  /** delete single row from the table: "admin_items" */
  delete_admin_items_by_pk?: Maybe<Admin_Items>;
  /** delete data from the table: "admin_record" */
  delete_admin_record?: Maybe<Admin_Record_Mutation_Response>;
  /** delete single row from the table: "admin_record" */
  delete_admin_record_by_pk?: Maybe<Admin_Record>;
  /** delete data from the table: "apartment" */
  delete_apartment?: Maybe<Apartment_Mutation_Response>;
  /** delete single row from the table: "apartment" */
  delete_apartment_by_pk?: Maybe<Apartment>;
  /** delete data from the table: "appointments" */
  delete_appointments?: Maybe<Appointments_Mutation_Response>;
  /** delete single row from the table: "appointments" */
  delete_appointments_by_pk?: Maybe<Appointments>;
  /** delete data from the table: "arrivals" */
  delete_arrivals?: Maybe<Arrivals_Mutation_Response>;
  /** delete single row from the table: "arrivals" */
  delete_arrivals_by_pk?: Maybe<Arrivals>;
  /** delete data from the table: "blog" */
  delete_blog?: Maybe<Blog_Mutation_Response>;
  /** delete single row from the table: "blog" */
  delete_blog_by_pk?: Maybe<Blog>;
  /** delete data from the table: "blog_types" */
  delete_blog_types?: Maybe<Blog_Types_Mutation_Response>;
  /** delete single row from the table: "blog_types" */
  delete_blog_types_by_pk?: Maybe<Blog_Types>;
  /** delete data from the table: "buildings" */
  delete_buildings?: Maybe<Buildings_Mutation_Response>;
  /** delete single row from the table: "buildings" */
  delete_buildings_by_pk?: Maybe<Buildings>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "common_area_types" */
  delete_common_area_types?: Maybe<Common_Area_Types_Mutation_Response>;
  /** delete single row from the table: "common_area_types" */
  delete_common_area_types_by_pk?: Maybe<Common_Area_Types>;
  /** delete data from the table: "common_areas" */
  delete_common_areas?: Maybe<Common_Areas_Mutation_Response>;
  /** delete single row from the table: "common_areas" */
  delete_common_areas_by_pk?: Maybe<Common_Areas>;
  /** delete data from the table: "contact" */
  delete_contact?: Maybe<Contact_Mutation_Response>;
  /** delete data from the table: "contact_addresses" */
  delete_contact_addresses?: Maybe<Contact_Addresses_Mutation_Response>;
  /** delete single row from the table: "contact_addresses" */
  delete_contact_addresses_by_pk?: Maybe<Contact_Addresses>;
  /** delete single row from the table: "contact" */
  delete_contact_by_pk?: Maybe<Contact>;
  /** delete data from the table: "contact_emails" */
  delete_contact_emails?: Maybe<Contact_Emails_Mutation_Response>;
  /** delete single row from the table: "contact_emails" */
  delete_contact_emails_by_pk?: Maybe<Contact_Emails>;
  /** delete data from the table: "contact_phones" */
  delete_contact_phones?: Maybe<Contact_Phones_Mutation_Response>;
  /** delete single row from the table: "contact_phones" */
  delete_contact_phones_by_pk?: Maybe<Contact_Phones>;
  /** delete data from the table: "currency_type" */
  delete_currency_type?: Maybe<Currency_Type_Mutation_Response>;
  /** delete single row from the table: "currency_type" */
  delete_currency_type_by_pk?: Maybe<Currency_Type>;
  /** delete data from the table: "dishes" */
  delete_dishes?: Maybe<Dishes_Mutation_Response>;
  /** delete single row from the table: "dishes" */
  delete_dishes_by_pk?: Maybe<Dishes>;
  /** delete data from the table: "email" */
  delete_email?: Maybe<Email_Mutation_Response>;
  /** delete single row from the table: "email" */
  delete_email_by_pk?: Maybe<Email>;
  /** delete data from the table: "employees" */
  delete_employees?: Maybe<Employees_Mutation_Response>;
  /** delete single row from the table: "employees" */
  delete_employees_by_pk?: Maybe<Employees>;
  /** delete data from the table: "exchange" */
  delete_exchange?: Maybe<Exchange_Mutation_Response>;
  /** delete single row from the table: "exchange" */
  delete_exchange_by_pk?: Maybe<Exchange>;
  /** delete data from the table: "history_comments" */
  delete_history_comments?: Maybe<History_Comments_Mutation_Response>;
  /** delete single row from the table: "history_comments" */
  delete_history_comments_by_pk?: Maybe<History_Comments>;
  /** delete data from the table: "ingredient_dish" */
  delete_ingredient_dish?: Maybe<Ingredient_Dish_Mutation_Response>;
  /** delete single row from the table: "ingredient_dish" */
  delete_ingredient_dish_by_pk?: Maybe<Ingredient_Dish>;
  /** delete data from the table: "ingredients" */
  delete_ingredients?: Maybe<Ingredients_Mutation_Response>;
  /** delete single row from the table: "ingredients" */
  delete_ingredients_by_pk?: Maybe<Ingredients>;
  /** delete data from the table: "lessors" */
  delete_lessors?: Maybe<Lessors_Mutation_Response>;
  /** delete single row from the table: "lessors" */
  delete_lessors_by_pk?: Maybe<Lessors>;
  /** delete data from the table: "menu_types" */
  delete_menu_types?: Maybe<Menu_Types_Mutation_Response>;
  /** delete single row from the table: "menu_types" */
  delete_menu_types_by_pk?: Maybe<Menu_Types>;
  /** delete data from the table: "minutes" */
  delete_minutes?: Maybe<Minutes_Mutation_Response>;
  /** delete single row from the table: "minutes" */
  delete_minutes_by_pk?: Maybe<Minutes>;
  /** delete data from the table: "minutes_types" */
  delete_minutes_types?: Maybe<Minutes_Types_Mutation_Response>;
  /** delete single row from the table: "minutes_types" */
  delete_minutes_types_by_pk?: Maybe<Minutes_Types>;
  /** delete data from the table: "modifier_group_dish" */
  delete_modifier_group_dish?: Maybe<Modifier_Group_Dish_Mutation_Response>;
  /** delete single row from the table: "modifier_group_dish" */
  delete_modifier_group_dish_by_pk?: Maybe<Modifier_Group_Dish>;
  /** delete data from the table: "modifier_groups" */
  delete_modifier_groups?: Maybe<Modifier_Groups_Mutation_Response>;
  /** delete single row from the table: "modifier_groups" */
  delete_modifier_groups_by_pk?: Maybe<Modifier_Groups>;
  /** delete data from the table: "modifiers" */
  delete_modifiers?: Maybe<Modifiers_Mutation_Response>;
  /** delete single row from the table: "modifiers" */
  delete_modifiers_by_pk?: Maybe<Modifiers>;
  /** delete data from the table: "motions" */
  delete_motions?: Maybe<Motions_Mutation_Response>;
  /** delete single row from the table: "motions" */
  delete_motions_by_pk?: Maybe<Motions>;
  /** delete data from the table: "motions_vote" */
  delete_motions_vote?: Maybe<Motions_Vote_Mutation_Response>;
  /** delete single row from the table: "motions_vote" */
  delete_motions_vote_by_pk?: Maybe<Motions_Vote>;
  /** delete data from the table: "new_owners" */
  delete_new_owners?: Maybe<New_Owners_Mutation_Response>;
  /** delete single row from the table: "new_owners" */
  delete_new_owners_by_pk?: Maybe<New_Owners>;
  /** delete data from the table: "order_dish" */
  delete_order_dish?: Maybe<Order_Dish_Mutation_Response>;
  /** delete single row from the table: "order_dish" */
  delete_order_dish_by_pk?: Maybe<Order_Dish>;
  /** delete data from the table: "order_dish_ingredients" */
  delete_order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Mutation_Response>;
  /** delete single row from the table: "order_dish_ingredients" */
  delete_order_dish_ingredients_by_pk?: Maybe<Order_Dish_Ingredients>;
  /** delete data from the table: "order_dish_modifier_groups" */
  delete_order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Mutation_Response>;
  /** delete single row from the table: "order_dish_modifier_groups" */
  delete_order_dish_modifier_groups_by_pk?: Maybe<Order_Dish_Modifier_Groups>;
  /** delete data from the table: "order_dish_modifiers" */
  delete_order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Mutation_Response>;
  /** delete single row from the table: "order_dish_modifiers" */
  delete_order_dish_modifiers_by_pk?: Maybe<Order_Dish_Modifiers>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete data from the table: "orders_actions" */
  delete_orders_actions?: Maybe<Orders_Actions_Mutation_Response>;
  /** delete single row from the table: "orders_actions" */
  delete_orders_actions_by_pk?: Maybe<Orders_Actions>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "palapa_account" */
  delete_palapa_account?: Maybe<Palapa_Account_Mutation_Response>;
  /** delete single row from the table: "palapa_account" */
  delete_palapa_account_by_pk?: Maybe<Palapa_Account>;
  /** delete data from the table: "palapa_items" */
  delete_palapa_items?: Maybe<Palapa_Items_Mutation_Response>;
  /** delete single row from the table: "palapa_items" */
  delete_palapa_items_by_pk?: Maybe<Palapa_Items>;
  /** delete data from the table: "phones" */
  delete_phones?: Maybe<Phones_Mutation_Response>;
  /** delete single row from the table: "phones" */
  delete_phones_by_pk?: Maybe<Phones>;
  /** delete data from the table: "print_templates" */
  delete_print_templates?: Maybe<Print_Templates_Mutation_Response>;
  /** delete single row from the table: "print_templates" */
  delete_print_templates_by_pk?: Maybe<Print_Templates>;
  /** delete data from the table: "print_types" */
  delete_print_types?: Maybe<Print_Types_Mutation_Response>;
  /** delete single row from the table: "print_types" */
  delete_print_types_by_pk?: Maybe<Print_Types>;
  /** delete data from the table: "printer_logs" */
  delete_printer_logs?: Maybe<Printer_Logs_Mutation_Response>;
  /** delete single row from the table: "printer_logs" */
  delete_printer_logs_by_pk?: Maybe<Printer_Logs>;
  /** delete data from the table: "printers" */
  delete_printers?: Maybe<Printers_Mutation_Response>;
  /** delete single row from the table: "printers" */
  delete_printers_by_pk?: Maybe<Printers>;
  /** delete data from the table: "property_managers" */
  delete_property_managers?: Maybe<Property_Managers_Mutation_Response>;
  /** delete single row from the table: "property_managers" */
  delete_property_managers_by_pk?: Maybe<Property_Managers>;
  /** delete data from the table: "providers" */
  delete_providers?: Maybe<Providers_Mutation_Response>;
  /** delete single row from the table: "providers" */
  delete_providers_by_pk?: Maybe<Providers>;
  /** delete data from the table: "providers_ratings" */
  delete_providers_ratings?: Maybe<Providers_Ratings_Mutation_Response>;
  /** delete single row from the table: "providers_ratings" */
  delete_providers_ratings_by_pk?: Maybe<Providers_Ratings>;
  /** delete data from the table: "providers_services" */
  delete_providers_services?: Maybe<Providers_Services_Mutation_Response>;
  /** delete single row from the table: "providers_services" */
  delete_providers_services_by_pk?: Maybe<Providers_Services>;
  /** delete data from the table: "rental" */
  delete_rental?: Maybe<Rental_Mutation_Response>;
  /** delete single row from the table: "rental" */
  delete_rental_by_pk?: Maybe<Rental>;
  /** delete data from the table: "rules_and_regulations" */
  delete_rules_and_regulations?: Maybe<Rules_And_Regulations_Mutation_Response>;
  /** delete single row from the table: "rules_and_regulations" */
  delete_rules_and_regulations_by_pk?: Maybe<Rules_And_Regulations>;
  /** delete data from the table: "services" */
  delete_services?: Maybe<Services_Mutation_Response>;
  /** delete single row from the table: "services" */
  delete_services_by_pk?: Maybe<Services>;
  /** delete data from the table: "statement_account" */
  delete_statement_account?: Maybe<Statement_Account_Mutation_Response>;
  /** delete single row from the table: "statement_account" */
  delete_statement_account_by_pk?: Maybe<Statement_Account>;
  /** delete data from the table: "subcategories" */
  delete_subcategories?: Maybe<Subcategories_Mutation_Response>;
  /** delete single row from the table: "subcategories" */
  delete_subcategories_by_pk?: Maybe<Subcategories>;
  /** delete data from the table: "tables" */
  delete_tables?: Maybe<Tables_Mutation_Response>;
  /** delete single row from the table: "tables" */
  delete_tables_by_pk?: Maybe<Tables>;
  /** delete data from the table: "transaction_type" */
  delete_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** delete single row from the table: "transaction_type" */
  delete_transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "units" */
  delete_units?: Maybe<Units_Mutation_Response>;
  /** delete single row from the table: "units" */
  delete_units_by_pk?: Maybe<Units>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "visits" */
  delete_visits?: Maybe<Visits_Mutation_Response>;
  /** delete single row from the table: "visits" */
  delete_visits_by_pk?: Maybe<Visits>;
  /** insert data into the table: "account_tickets" */
  insert_account_tickets?: Maybe<Account_Tickets_Mutation_Response>;
  /** insert a single row into the table: "account_tickets" */
  insert_account_tickets_one?: Maybe<Account_Tickets>;
  /** insert data into the table: "activities" */
  insert_activities?: Maybe<Activities_Mutation_Response>;
  /** insert a single row into the table: "activities" */
  insert_activities_one?: Maybe<Activities>;
  /** insert data into the table: "addresses" */
  insert_addresses?: Maybe<Addresses_Mutation_Response>;
  /** insert a single row into the table: "addresses" */
  insert_addresses_one?: Maybe<Addresses>;
  /** insert data into the table: "admin_items" */
  insert_admin_items?: Maybe<Admin_Items_Mutation_Response>;
  /** insert a single row into the table: "admin_items" */
  insert_admin_items_one?: Maybe<Admin_Items>;
  /** insert data into the table: "admin_record" */
  insert_admin_record?: Maybe<Admin_Record_Mutation_Response>;
  /** insert a single row into the table: "admin_record" */
  insert_admin_record_one?: Maybe<Admin_Record>;
  /** insert data into the table: "apartment" */
  insert_apartment?: Maybe<Apartment_Mutation_Response>;
  /** insert a single row into the table: "apartment" */
  insert_apartment_one?: Maybe<Apartment>;
  /** insert data into the table: "appointments" */
  insert_appointments?: Maybe<Appointments_Mutation_Response>;
  /** insert a single row into the table: "appointments" */
  insert_appointments_one?: Maybe<Appointments>;
  /** insert data into the table: "arrivals" */
  insert_arrivals?: Maybe<Arrivals_Mutation_Response>;
  /** insert a single row into the table: "arrivals" */
  insert_arrivals_one?: Maybe<Arrivals>;
  /** insert data into the table: "blog" */
  insert_blog?: Maybe<Blog_Mutation_Response>;
  /** insert a single row into the table: "blog" */
  insert_blog_one?: Maybe<Blog>;
  /** insert data into the table: "blog_types" */
  insert_blog_types?: Maybe<Blog_Types_Mutation_Response>;
  /** insert a single row into the table: "blog_types" */
  insert_blog_types_one?: Maybe<Blog_Types>;
  /** insert data into the table: "buildings" */
  insert_buildings?: Maybe<Buildings_Mutation_Response>;
  /** insert a single row into the table: "buildings" */
  insert_buildings_one?: Maybe<Buildings>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "common_area_types" */
  insert_common_area_types?: Maybe<Common_Area_Types_Mutation_Response>;
  /** insert a single row into the table: "common_area_types" */
  insert_common_area_types_one?: Maybe<Common_Area_Types>;
  /** insert data into the table: "common_areas" */
  insert_common_areas?: Maybe<Common_Areas_Mutation_Response>;
  /** insert a single row into the table: "common_areas" */
  insert_common_areas_one?: Maybe<Common_Areas>;
  /** insert data into the table: "contact" */
  insert_contact?: Maybe<Contact_Mutation_Response>;
  /** insert data into the table: "contact_addresses" */
  insert_contact_addresses?: Maybe<Contact_Addresses_Mutation_Response>;
  /** insert a single row into the table: "contact_addresses" */
  insert_contact_addresses_one?: Maybe<Contact_Addresses>;
  /** insert data into the table: "contact_emails" */
  insert_contact_emails?: Maybe<Contact_Emails_Mutation_Response>;
  /** insert a single row into the table: "contact_emails" */
  insert_contact_emails_one?: Maybe<Contact_Emails>;
  /** insert a single row into the table: "contact" */
  insert_contact_one?: Maybe<Contact>;
  /** insert data into the table: "contact_phones" */
  insert_contact_phones?: Maybe<Contact_Phones_Mutation_Response>;
  /** insert a single row into the table: "contact_phones" */
  insert_contact_phones_one?: Maybe<Contact_Phones>;
  /** insert data into the table: "currency_type" */
  insert_currency_type?: Maybe<Currency_Type_Mutation_Response>;
  /** insert a single row into the table: "currency_type" */
  insert_currency_type_one?: Maybe<Currency_Type>;
  /** insert data into the table: "dishes" */
  insert_dishes?: Maybe<Dishes_Mutation_Response>;
  /** insert a single row into the table: "dishes" */
  insert_dishes_one?: Maybe<Dishes>;
  /** insert data into the table: "email" */
  insert_email?: Maybe<Email_Mutation_Response>;
  /** insert a single row into the table: "email" */
  insert_email_one?: Maybe<Email>;
  /** insert data into the table: "employees" */
  insert_employees?: Maybe<Employees_Mutation_Response>;
  /** insert a single row into the table: "employees" */
  insert_employees_one?: Maybe<Employees>;
  /** insert data into the table: "exchange" */
  insert_exchange?: Maybe<Exchange_Mutation_Response>;
  /** insert a single row into the table: "exchange" */
  insert_exchange_one?: Maybe<Exchange>;
  /** insert data into the table: "history_comments" */
  insert_history_comments?: Maybe<History_Comments_Mutation_Response>;
  /** insert a single row into the table: "history_comments" */
  insert_history_comments_one?: Maybe<History_Comments>;
  /** insert data into the table: "ingredient_dish" */
  insert_ingredient_dish?: Maybe<Ingredient_Dish_Mutation_Response>;
  /** insert a single row into the table: "ingredient_dish" */
  insert_ingredient_dish_one?: Maybe<Ingredient_Dish>;
  /** insert data into the table: "ingredients" */
  insert_ingredients?: Maybe<Ingredients_Mutation_Response>;
  /** insert a single row into the table: "ingredients" */
  insert_ingredients_one?: Maybe<Ingredients>;
  /** insert data into the table: "lessors" */
  insert_lessors?: Maybe<Lessors_Mutation_Response>;
  /** insert a single row into the table: "lessors" */
  insert_lessors_one?: Maybe<Lessors>;
  /** insert data into the table: "menu_types" */
  insert_menu_types?: Maybe<Menu_Types_Mutation_Response>;
  /** insert a single row into the table: "menu_types" */
  insert_menu_types_one?: Maybe<Menu_Types>;
  /** insert data into the table: "minutes" */
  insert_minutes?: Maybe<Minutes_Mutation_Response>;
  /** insert a single row into the table: "minutes" */
  insert_minutes_one?: Maybe<Minutes>;
  /** insert data into the table: "minutes_types" */
  insert_minutes_types?: Maybe<Minutes_Types_Mutation_Response>;
  /** insert a single row into the table: "minutes_types" */
  insert_minutes_types_one?: Maybe<Minutes_Types>;
  /** insert data into the table: "modifier_group_dish" */
  insert_modifier_group_dish?: Maybe<Modifier_Group_Dish_Mutation_Response>;
  /** insert a single row into the table: "modifier_group_dish" */
  insert_modifier_group_dish_one?: Maybe<Modifier_Group_Dish>;
  /** insert data into the table: "modifier_groups" */
  insert_modifier_groups?: Maybe<Modifier_Groups_Mutation_Response>;
  /** insert a single row into the table: "modifier_groups" */
  insert_modifier_groups_one?: Maybe<Modifier_Groups>;
  /** insert data into the table: "modifiers" */
  insert_modifiers?: Maybe<Modifiers_Mutation_Response>;
  /** insert a single row into the table: "modifiers" */
  insert_modifiers_one?: Maybe<Modifiers>;
  /** insert data into the table: "motions" */
  insert_motions?: Maybe<Motions_Mutation_Response>;
  /** insert a single row into the table: "motions" */
  insert_motions_one?: Maybe<Motions>;
  /** insert data into the table: "motions_vote" */
  insert_motions_vote?: Maybe<Motions_Vote_Mutation_Response>;
  /** insert a single row into the table: "motions_vote" */
  insert_motions_vote_one?: Maybe<Motions_Vote>;
  /** insert data into the table: "new_owners" */
  insert_new_owners?: Maybe<New_Owners_Mutation_Response>;
  /** insert a single row into the table: "new_owners" */
  insert_new_owners_one?: Maybe<New_Owners>;
  /** insert data into the table: "order_dish" */
  insert_order_dish?: Maybe<Order_Dish_Mutation_Response>;
  /** insert data into the table: "order_dish_ingredients" */
  insert_order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Mutation_Response>;
  /** insert a single row into the table: "order_dish_ingredients" */
  insert_order_dish_ingredients_one?: Maybe<Order_Dish_Ingredients>;
  /** insert data into the table: "order_dish_modifier_groups" */
  insert_order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Mutation_Response>;
  /** insert a single row into the table: "order_dish_modifier_groups" */
  insert_order_dish_modifier_groups_one?: Maybe<Order_Dish_Modifier_Groups>;
  /** insert data into the table: "order_dish_modifiers" */
  insert_order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Mutation_Response>;
  /** insert a single row into the table: "order_dish_modifiers" */
  insert_order_dish_modifiers_one?: Maybe<Order_Dish_Modifiers>;
  /** insert a single row into the table: "order_dish" */
  insert_order_dish_one?: Maybe<Order_Dish>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert data into the table: "orders_actions" */
  insert_orders_actions?: Maybe<Orders_Actions_Mutation_Response>;
  /** insert a single row into the table: "orders_actions" */
  insert_orders_actions_one?: Maybe<Orders_Actions>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "palapa_account" */
  insert_palapa_account?: Maybe<Palapa_Account_Mutation_Response>;
  /** insert a single row into the table: "palapa_account" */
  insert_palapa_account_one?: Maybe<Palapa_Account>;
  /** insert data into the table: "palapa_items" */
  insert_palapa_items?: Maybe<Palapa_Items_Mutation_Response>;
  /** insert a single row into the table: "palapa_items" */
  insert_palapa_items_one?: Maybe<Palapa_Items>;
  /** insert data into the table: "phones" */
  insert_phones?: Maybe<Phones_Mutation_Response>;
  /** insert a single row into the table: "phones" */
  insert_phones_one?: Maybe<Phones>;
  /** insert data into the table: "print_templates" */
  insert_print_templates?: Maybe<Print_Templates_Mutation_Response>;
  /** insert a single row into the table: "print_templates" */
  insert_print_templates_one?: Maybe<Print_Templates>;
  /** insert data into the table: "print_types" */
  insert_print_types?: Maybe<Print_Types_Mutation_Response>;
  /** insert a single row into the table: "print_types" */
  insert_print_types_one?: Maybe<Print_Types>;
  /** insert data into the table: "printer_logs" */
  insert_printer_logs?: Maybe<Printer_Logs_Mutation_Response>;
  /** insert a single row into the table: "printer_logs" */
  insert_printer_logs_one?: Maybe<Printer_Logs>;
  /** insert data into the table: "printers" */
  insert_printers?: Maybe<Printers_Mutation_Response>;
  /** insert a single row into the table: "printers" */
  insert_printers_one?: Maybe<Printers>;
  /** insert data into the table: "property_managers" */
  insert_property_managers?: Maybe<Property_Managers_Mutation_Response>;
  /** insert a single row into the table: "property_managers" */
  insert_property_managers_one?: Maybe<Property_Managers>;
  /** insert data into the table: "providers" */
  insert_providers?: Maybe<Providers_Mutation_Response>;
  /** insert a single row into the table: "providers" */
  insert_providers_one?: Maybe<Providers>;
  /** insert data into the table: "providers_ratings" */
  insert_providers_ratings?: Maybe<Providers_Ratings_Mutation_Response>;
  /** insert a single row into the table: "providers_ratings" */
  insert_providers_ratings_one?: Maybe<Providers_Ratings>;
  /** insert data into the table: "providers_services" */
  insert_providers_services?: Maybe<Providers_Services_Mutation_Response>;
  /** insert a single row into the table: "providers_services" */
  insert_providers_services_one?: Maybe<Providers_Services>;
  /** insert data into the table: "rental" */
  insert_rental?: Maybe<Rental_Mutation_Response>;
  /** insert a single row into the table: "rental" */
  insert_rental_one?: Maybe<Rental>;
  /** insert data into the table: "rules_and_regulations" */
  insert_rules_and_regulations?: Maybe<Rules_And_Regulations_Mutation_Response>;
  /** insert a single row into the table: "rules_and_regulations" */
  insert_rules_and_regulations_one?: Maybe<Rules_And_Regulations>;
  /** insert data into the table: "services" */
  insert_services?: Maybe<Services_Mutation_Response>;
  /** insert a single row into the table: "services" */
  insert_services_one?: Maybe<Services>;
  /** insert data into the table: "statement_account" */
  insert_statement_account?: Maybe<Statement_Account_Mutation_Response>;
  /** insert a single row into the table: "statement_account" */
  insert_statement_account_one?: Maybe<Statement_Account>;
  /** insert data into the table: "subcategories" */
  insert_subcategories?: Maybe<Subcategories_Mutation_Response>;
  /** insert a single row into the table: "subcategories" */
  insert_subcategories_one?: Maybe<Subcategories>;
  /** insert data into the table: "tables" */
  insert_tables?: Maybe<Tables_Mutation_Response>;
  /** insert a single row into the table: "tables" */
  insert_tables_one?: Maybe<Tables>;
  /** insert data into the table: "transaction_type" */
  insert_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** insert a single row into the table: "transaction_type" */
  insert_transaction_type_one?: Maybe<Transaction_Type>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "units" */
  insert_units?: Maybe<Units_Mutation_Response>;
  /** insert a single row into the table: "units" */
  insert_units_one?: Maybe<Units>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "visits" */
  insert_visits?: Maybe<Visits_Mutation_Response>;
  /** insert a single row into the table: "visits" */
  insert_visits_one?: Maybe<Visits>;
  /** update data of the table: "account_tickets" */
  update_account_tickets?: Maybe<Account_Tickets_Mutation_Response>;
  /** update single row of the table: "account_tickets" */
  update_account_tickets_by_pk?: Maybe<Account_Tickets>;
  /** update data of the table: "activities" */
  update_activities?: Maybe<Activities_Mutation_Response>;
  /** update single row of the table: "activities" */
  update_activities_by_pk?: Maybe<Activities>;
  /** update data of the table: "addresses" */
  update_addresses?: Maybe<Addresses_Mutation_Response>;
  /** update single row of the table: "addresses" */
  update_addresses_by_pk?: Maybe<Addresses>;
  /** update data of the table: "admin_items" */
  update_admin_items?: Maybe<Admin_Items_Mutation_Response>;
  /** update single row of the table: "admin_items" */
  update_admin_items_by_pk?: Maybe<Admin_Items>;
  /** update data of the table: "admin_record" */
  update_admin_record?: Maybe<Admin_Record_Mutation_Response>;
  /** update single row of the table: "admin_record" */
  update_admin_record_by_pk?: Maybe<Admin_Record>;
  /** update data of the table: "apartment" */
  update_apartment?: Maybe<Apartment_Mutation_Response>;
  /** update single row of the table: "apartment" */
  update_apartment_by_pk?: Maybe<Apartment>;
  /** update data of the table: "appointments" */
  update_appointments?: Maybe<Appointments_Mutation_Response>;
  /** update single row of the table: "appointments" */
  update_appointments_by_pk?: Maybe<Appointments>;
  /** update data of the table: "arrivals" */
  update_arrivals?: Maybe<Arrivals_Mutation_Response>;
  /** update single row of the table: "arrivals" */
  update_arrivals_by_pk?: Maybe<Arrivals>;
  /** update data of the table: "blog" */
  update_blog?: Maybe<Blog_Mutation_Response>;
  /** update single row of the table: "blog" */
  update_blog_by_pk?: Maybe<Blog>;
  /** update data of the table: "blog_types" */
  update_blog_types?: Maybe<Blog_Types_Mutation_Response>;
  /** update single row of the table: "blog_types" */
  update_blog_types_by_pk?: Maybe<Blog_Types>;
  /** update data of the table: "buildings" */
  update_buildings?: Maybe<Buildings_Mutation_Response>;
  /** update single row of the table: "buildings" */
  update_buildings_by_pk?: Maybe<Buildings>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update data of the table: "common_area_types" */
  update_common_area_types?: Maybe<Common_Area_Types_Mutation_Response>;
  /** update single row of the table: "common_area_types" */
  update_common_area_types_by_pk?: Maybe<Common_Area_Types>;
  /** update data of the table: "common_areas" */
  update_common_areas?: Maybe<Common_Areas_Mutation_Response>;
  /** update single row of the table: "common_areas" */
  update_common_areas_by_pk?: Maybe<Common_Areas>;
  /** update data of the table: "contact" */
  update_contact?: Maybe<Contact_Mutation_Response>;
  /** update data of the table: "contact_addresses" */
  update_contact_addresses?: Maybe<Contact_Addresses_Mutation_Response>;
  /** update single row of the table: "contact_addresses" */
  update_contact_addresses_by_pk?: Maybe<Contact_Addresses>;
  /** update single row of the table: "contact" */
  update_contact_by_pk?: Maybe<Contact>;
  /** update data of the table: "contact_emails" */
  update_contact_emails?: Maybe<Contact_Emails_Mutation_Response>;
  /** update single row of the table: "contact_emails" */
  update_contact_emails_by_pk?: Maybe<Contact_Emails>;
  /** update data of the table: "contact_phones" */
  update_contact_phones?: Maybe<Contact_Phones_Mutation_Response>;
  /** update single row of the table: "contact_phones" */
  update_contact_phones_by_pk?: Maybe<Contact_Phones>;
  /** update data of the table: "currency_type" */
  update_currency_type?: Maybe<Currency_Type_Mutation_Response>;
  /** update single row of the table: "currency_type" */
  update_currency_type_by_pk?: Maybe<Currency_Type>;
  /** update data of the table: "dishes" */
  update_dishes?: Maybe<Dishes_Mutation_Response>;
  /** update single row of the table: "dishes" */
  update_dishes_by_pk?: Maybe<Dishes>;
  /** update data of the table: "email" */
  update_email?: Maybe<Email_Mutation_Response>;
  /** update single row of the table: "email" */
  update_email_by_pk?: Maybe<Email>;
  /** update data of the table: "employees" */
  update_employees?: Maybe<Employees_Mutation_Response>;
  /** update single row of the table: "employees" */
  update_employees_by_pk?: Maybe<Employees>;
  /** update data of the table: "exchange" */
  update_exchange?: Maybe<Exchange_Mutation_Response>;
  /** update single row of the table: "exchange" */
  update_exchange_by_pk?: Maybe<Exchange>;
  /** update data of the table: "history_comments" */
  update_history_comments?: Maybe<History_Comments_Mutation_Response>;
  /** update single row of the table: "history_comments" */
  update_history_comments_by_pk?: Maybe<History_Comments>;
  /** update data of the table: "ingredient_dish" */
  update_ingredient_dish?: Maybe<Ingredient_Dish_Mutation_Response>;
  /** update single row of the table: "ingredient_dish" */
  update_ingredient_dish_by_pk?: Maybe<Ingredient_Dish>;
  /** update data of the table: "ingredients" */
  update_ingredients?: Maybe<Ingredients_Mutation_Response>;
  /** update single row of the table: "ingredients" */
  update_ingredients_by_pk?: Maybe<Ingredients>;
  /** update data of the table: "lessors" */
  update_lessors?: Maybe<Lessors_Mutation_Response>;
  /** update single row of the table: "lessors" */
  update_lessors_by_pk?: Maybe<Lessors>;
  /** update data of the table: "menu_types" */
  update_menu_types?: Maybe<Menu_Types_Mutation_Response>;
  /** update single row of the table: "menu_types" */
  update_menu_types_by_pk?: Maybe<Menu_Types>;
  /** update data of the table: "minutes" */
  update_minutes?: Maybe<Minutes_Mutation_Response>;
  /** update single row of the table: "minutes" */
  update_minutes_by_pk?: Maybe<Minutes>;
  /** update data of the table: "minutes_types" */
  update_minutes_types?: Maybe<Minutes_Types_Mutation_Response>;
  /** update single row of the table: "minutes_types" */
  update_minutes_types_by_pk?: Maybe<Minutes_Types>;
  /** update data of the table: "modifier_group_dish" */
  update_modifier_group_dish?: Maybe<Modifier_Group_Dish_Mutation_Response>;
  /** update single row of the table: "modifier_group_dish" */
  update_modifier_group_dish_by_pk?: Maybe<Modifier_Group_Dish>;
  /** update data of the table: "modifier_groups" */
  update_modifier_groups?: Maybe<Modifier_Groups_Mutation_Response>;
  /** update single row of the table: "modifier_groups" */
  update_modifier_groups_by_pk?: Maybe<Modifier_Groups>;
  /** update data of the table: "modifiers" */
  update_modifiers?: Maybe<Modifiers_Mutation_Response>;
  /** update single row of the table: "modifiers" */
  update_modifiers_by_pk?: Maybe<Modifiers>;
  /** update data of the table: "motions" */
  update_motions?: Maybe<Motions_Mutation_Response>;
  /** update single row of the table: "motions" */
  update_motions_by_pk?: Maybe<Motions>;
  /** update data of the table: "motions_vote" */
  update_motions_vote?: Maybe<Motions_Vote_Mutation_Response>;
  /** update single row of the table: "motions_vote" */
  update_motions_vote_by_pk?: Maybe<Motions_Vote>;
  /** update data of the table: "new_owners" */
  update_new_owners?: Maybe<New_Owners_Mutation_Response>;
  /** update single row of the table: "new_owners" */
  update_new_owners_by_pk?: Maybe<New_Owners>;
  /** update data of the table: "order_dish" */
  update_order_dish?: Maybe<Order_Dish_Mutation_Response>;
  /** update single row of the table: "order_dish" */
  update_order_dish_by_pk?: Maybe<Order_Dish>;
  /** update data of the table: "order_dish_ingredients" */
  update_order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Mutation_Response>;
  /** update single row of the table: "order_dish_ingredients" */
  update_order_dish_ingredients_by_pk?: Maybe<Order_Dish_Ingredients>;
  /** update data of the table: "order_dish_modifier_groups" */
  update_order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Mutation_Response>;
  /** update single row of the table: "order_dish_modifier_groups" */
  update_order_dish_modifier_groups_by_pk?: Maybe<Order_Dish_Modifier_Groups>;
  /** update data of the table: "order_dish_modifiers" */
  update_order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Mutation_Response>;
  /** update single row of the table: "order_dish_modifiers" */
  update_order_dish_modifiers_by_pk?: Maybe<Order_Dish_Modifiers>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update data of the table: "orders_actions" */
  update_orders_actions?: Maybe<Orders_Actions_Mutation_Response>;
  /** update single row of the table: "orders_actions" */
  update_orders_actions_by_pk?: Maybe<Orders_Actions>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update data of the table: "palapa_account" */
  update_palapa_account?: Maybe<Palapa_Account_Mutation_Response>;
  /** update single row of the table: "palapa_account" */
  update_palapa_account_by_pk?: Maybe<Palapa_Account>;
  /** update data of the table: "palapa_items" */
  update_palapa_items?: Maybe<Palapa_Items_Mutation_Response>;
  /** update single row of the table: "palapa_items" */
  update_palapa_items_by_pk?: Maybe<Palapa_Items>;
  /** update data of the table: "phones" */
  update_phones?: Maybe<Phones_Mutation_Response>;
  /** update single row of the table: "phones" */
  update_phones_by_pk?: Maybe<Phones>;
  /** update data of the table: "print_templates" */
  update_print_templates?: Maybe<Print_Templates_Mutation_Response>;
  /** update single row of the table: "print_templates" */
  update_print_templates_by_pk?: Maybe<Print_Templates>;
  /** update data of the table: "print_types" */
  update_print_types?: Maybe<Print_Types_Mutation_Response>;
  /** update single row of the table: "print_types" */
  update_print_types_by_pk?: Maybe<Print_Types>;
  /** update data of the table: "printer_logs" */
  update_printer_logs?: Maybe<Printer_Logs_Mutation_Response>;
  /** update single row of the table: "printer_logs" */
  update_printer_logs_by_pk?: Maybe<Printer_Logs>;
  /** update data of the table: "printers" */
  update_printers?: Maybe<Printers_Mutation_Response>;
  /** update single row of the table: "printers" */
  update_printers_by_pk?: Maybe<Printers>;
  /** update data of the table: "property_managers" */
  update_property_managers?: Maybe<Property_Managers_Mutation_Response>;
  /** update single row of the table: "property_managers" */
  update_property_managers_by_pk?: Maybe<Property_Managers>;
  /** update data of the table: "providers" */
  update_providers?: Maybe<Providers_Mutation_Response>;
  /** update single row of the table: "providers" */
  update_providers_by_pk?: Maybe<Providers>;
  /** update data of the table: "providers_ratings" */
  update_providers_ratings?: Maybe<Providers_Ratings_Mutation_Response>;
  /** update single row of the table: "providers_ratings" */
  update_providers_ratings_by_pk?: Maybe<Providers_Ratings>;
  /** update data of the table: "providers_services" */
  update_providers_services?: Maybe<Providers_Services_Mutation_Response>;
  /** update single row of the table: "providers_services" */
  update_providers_services_by_pk?: Maybe<Providers_Services>;
  /** update data of the table: "rental" */
  update_rental?: Maybe<Rental_Mutation_Response>;
  /** update single row of the table: "rental" */
  update_rental_by_pk?: Maybe<Rental>;
  /** update data of the table: "rules_and_regulations" */
  update_rules_and_regulations?: Maybe<Rules_And_Regulations_Mutation_Response>;
  /** update single row of the table: "rules_and_regulations" */
  update_rules_and_regulations_by_pk?: Maybe<Rules_And_Regulations>;
  /** update data of the table: "services" */
  update_services?: Maybe<Services_Mutation_Response>;
  /** update single row of the table: "services" */
  update_services_by_pk?: Maybe<Services>;
  /** update data of the table: "statement_account" */
  update_statement_account?: Maybe<Statement_Account_Mutation_Response>;
  /** update single row of the table: "statement_account" */
  update_statement_account_by_pk?: Maybe<Statement_Account>;
  /** update data of the table: "subcategories" */
  update_subcategories?: Maybe<Subcategories_Mutation_Response>;
  /** update single row of the table: "subcategories" */
  update_subcategories_by_pk?: Maybe<Subcategories>;
  /** update data of the table: "tables" */
  update_tables?: Maybe<Tables_Mutation_Response>;
  /** update single row of the table: "tables" */
  update_tables_by_pk?: Maybe<Tables>;
  /** update data of the table: "transaction_type" */
  update_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** update single row of the table: "transaction_type" */
  update_transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update data of the table: "units" */
  update_units?: Maybe<Units_Mutation_Response>;
  /** update single row of the table: "units" */
  update_units_by_pk?: Maybe<Units>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "visits" */
  update_visits?: Maybe<Visits_Mutation_Response>;
  /** update single row of the table: "visits" */
  update_visits_by_pk?: Maybe<Visits>;
};


/** mutation root */
export type Mutation_RootCreateUserAccountArgs = {
  apartment_id: Scalars['Int'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
  role: Scalars['String'];
  uid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSwitchUserStatusArgs = {
  locked: Scalars['Boolean'];
  uid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateUserEmailArgs = {
  email: Scalars['String'];
  uid: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateUserRoleArgs = {
  role: Scalars['String'];
  uid: Scalars['String'];
  user_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Account_TicketsArgs = {
  where: Account_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ActivitiesArgs = {
  where: Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activities_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AddressesArgs = {
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_ItemsArgs = {
  where: Admin_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_Items_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_RecordArgs = {
  where: Admin_Record_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_Record_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ApartmentArgs = {
  where: Apartment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apartment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AppointmentsArgs = {
  where: Appointments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Appointments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ArrivalsArgs = {
  where: Arrivals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Arrivals_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BlogArgs = {
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Blog_TypesArgs = {
  where: Blog_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BuildingsArgs = {
  where: Buildings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Buildings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Area_TypesArgs = {
  where: Common_Area_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Area_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_AreasArgs = {
  where: Common_Areas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Areas_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContactArgs = {
  where: Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_AddressesArgs = {
  where: Contact_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Addresses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_EmailsArgs = {
  where: Contact_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_PhonesArgs = {
  where: Contact_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Phones_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Currency_TypeArgs = {
  where: Currency_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_DishesArgs = {
  where: Dishes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dishes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmailArgs = {
  where: Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeesArgs = {
  where: Employees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employees_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExchangeArgs = {
  where: Exchange_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Exchange_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_History_CommentsArgs = {
  where: History_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_History_Comments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ingredient_DishArgs = {
  where: Ingredient_Dish_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ingredient_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_IngredientsArgs = {
  where: Ingredients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ingredients_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LessorsArgs = {
  where: Lessors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lessors_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_TypesArgs = {
  where: Menu_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MinutesArgs = {
  where: Minutes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minutes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Minutes_TypesArgs = {
  where: Minutes_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minutes_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Modifier_Group_DishArgs = {
  where: Modifier_Group_Dish_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Modifier_Group_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Modifier_GroupsArgs = {
  where: Modifier_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Modifier_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ModifiersArgs = {
  where: Modifiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Modifiers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MotionsArgs = {
  where: Motions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Motions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Motions_VoteArgs = {
  where: Motions_Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Motions_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_New_OwnersArgs = {
  where: New_Owners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Owners_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_DishArgs = {
  where: Order_Dish_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_IngredientsArgs = {
  where: Order_Dish_Ingredients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_Ingredients_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_Modifier_GroupsArgs = {
  where: Order_Dish_Modifier_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_Modifier_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_ModifiersArgs = {
  where: Order_Dish_Modifiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Dish_Modifiers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_ActionsArgs = {
  where: Orders_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_Actions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Palapa_AccountArgs = {
  where: Palapa_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Palapa_Account_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Palapa_ItemsArgs = {
  where: Palapa_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Palapa_Items_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PhonesArgs = {
  where: Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Phones_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Print_TemplatesArgs = {
  where: Print_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Print_Templates_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Print_TypesArgs = {
  where: Print_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Print_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Printer_LogsArgs = {
  where: Printer_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Printer_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PrintersArgs = {
  where: Printers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Printers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Property_ManagersArgs = {
  where: Property_Managers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Managers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProvidersArgs = {
  where: Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Providers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Providers_RatingsArgs = {
  where: Providers_Ratings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Providers_Ratings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Providers_ServicesArgs = {
  where: Providers_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Providers_Services_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RentalArgs = {
  where: Rental_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Rules_And_RegulationsArgs = {
  where: Rules_And_Regulations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rules_And_Regulations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ServicesArgs = {
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statement_AccountArgs = {
  where: Statement_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statement_Account_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubcategoriesArgs = {
  where: Subcategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcategories_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TablesArgs = {
  where: Tables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tables_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Transaction_TypeArgs = {
  where: Transaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  transaction_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UnitsArgs = {
  where: Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Units_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VisitsArgs = {
  where: Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Account_TicketsArgs = {
  objects: Array<Account_Tickets_Insert_Input>;
  on_conflict?: Maybe<Account_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Tickets_OneArgs = {
  object: Account_Tickets_Insert_Input;
  on_conflict?: Maybe<Account_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivitiesArgs = {
  objects: Array<Activities_Insert_Input>;
  on_conflict?: Maybe<Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activities_OneArgs = {
  object: Activities_Insert_Input;
  on_conflict?: Maybe<Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressesArgs = {
  objects: Array<Addresses_Insert_Input>;
  on_conflict?: Maybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_OneArgs = {
  object: Addresses_Insert_Input;
  on_conflict?: Maybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_ItemsArgs = {
  objects: Array<Admin_Items_Insert_Input>;
  on_conflict?: Maybe<Admin_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Items_OneArgs = {
  object: Admin_Items_Insert_Input;
  on_conflict?: Maybe<Admin_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_RecordArgs = {
  objects: Array<Admin_Record_Insert_Input>;
  on_conflict?: Maybe<Admin_Record_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Record_OneArgs = {
  object: Admin_Record_Insert_Input;
  on_conflict?: Maybe<Admin_Record_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApartmentArgs = {
  objects: Array<Apartment_Insert_Input>;
  on_conflict?: Maybe<Apartment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apartment_OneArgs = {
  object: Apartment_Insert_Input;
  on_conflict?: Maybe<Apartment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppointmentsArgs = {
  objects: Array<Appointments_Insert_Input>;
  on_conflict?: Maybe<Appointments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Appointments_OneArgs = {
  object: Appointments_Insert_Input;
  on_conflict?: Maybe<Appointments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ArrivalsArgs = {
  objects: Array<Arrivals_Insert_Input>;
  on_conflict?: Maybe<Arrivals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Arrivals_OneArgs = {
  object: Arrivals_Insert_Input;
  on_conflict?: Maybe<Arrivals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BlogArgs = {
  objects: Array<Blog_Insert_Input>;
  on_conflict?: Maybe<Blog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_OneArgs = {
  object: Blog_Insert_Input;
  on_conflict?: Maybe<Blog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_TypesArgs = {
  objects: Array<Blog_Types_Insert_Input>;
  on_conflict?: Maybe<Blog_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Types_OneArgs = {
  object: Blog_Types_Insert_Input;
  on_conflict?: Maybe<Blog_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BuildingsArgs = {
  objects: Array<Buildings_Insert_Input>;
  on_conflict?: Maybe<Buildings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Buildings_OneArgs = {
  object: Buildings_Insert_Input;
  on_conflict?: Maybe<Buildings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Area_TypesArgs = {
  objects: Array<Common_Area_Types_Insert_Input>;
  on_conflict?: Maybe<Common_Area_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Area_Types_OneArgs = {
  object: Common_Area_Types_Insert_Input;
  on_conflict?: Maybe<Common_Area_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_AreasArgs = {
  objects: Array<Common_Areas_Insert_Input>;
  on_conflict?: Maybe<Common_Areas_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Areas_OneArgs = {
  object: Common_Areas_Insert_Input;
  on_conflict?: Maybe<Common_Areas_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactArgs = {
  objects: Array<Contact_Insert_Input>;
  on_conflict?: Maybe<Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_AddressesArgs = {
  objects: Array<Contact_Addresses_Insert_Input>;
  on_conflict?: Maybe<Contact_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Addresses_OneArgs = {
  object: Contact_Addresses_Insert_Input;
  on_conflict?: Maybe<Contact_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_EmailsArgs = {
  objects: Array<Contact_Emails_Insert_Input>;
  on_conflict?: Maybe<Contact_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Emails_OneArgs = {
  object: Contact_Emails_Insert_Input;
  on_conflict?: Maybe<Contact_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_OneArgs = {
  object: Contact_Insert_Input;
  on_conflict?: Maybe<Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_PhonesArgs = {
  objects: Array<Contact_Phones_Insert_Input>;
  on_conflict?: Maybe<Contact_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Phones_OneArgs = {
  object: Contact_Phones_Insert_Input;
  on_conflict?: Maybe<Contact_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_TypeArgs = {
  objects: Array<Currency_Type_Insert_Input>;
  on_conflict?: Maybe<Currency_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_Type_OneArgs = {
  object: Currency_Type_Insert_Input;
  on_conflict?: Maybe<Currency_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DishesArgs = {
  objects: Array<Dishes_Insert_Input>;
  on_conflict?: Maybe<Dishes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dishes_OneArgs = {
  object: Dishes_Insert_Input;
  on_conflict?: Maybe<Dishes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailArgs = {
  objects: Array<Email_Insert_Input>;
  on_conflict?: Maybe<Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_OneArgs = {
  object: Email_Insert_Input;
  on_conflict?: Maybe<Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeesArgs = {
  objects: Array<Employees_Insert_Input>;
  on_conflict?: Maybe<Employees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_OneArgs = {
  object: Employees_Insert_Input;
  on_conflict?: Maybe<Employees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ExchangeArgs = {
  objects: Array<Exchange_Insert_Input>;
  on_conflict?: Maybe<Exchange_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Exchange_OneArgs = {
  object: Exchange_Insert_Input;
  on_conflict?: Maybe<Exchange_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_History_CommentsArgs = {
  objects: Array<History_Comments_Insert_Input>;
  on_conflict?: Maybe<History_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_History_Comments_OneArgs = {
  object: History_Comments_Insert_Input;
  on_conflict?: Maybe<History_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ingredient_DishArgs = {
  objects: Array<Ingredient_Dish_Insert_Input>;
  on_conflict?: Maybe<Ingredient_Dish_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ingredient_Dish_OneArgs = {
  object: Ingredient_Dish_Insert_Input;
  on_conflict?: Maybe<Ingredient_Dish_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IngredientsArgs = {
  objects: Array<Ingredients_Insert_Input>;
  on_conflict?: Maybe<Ingredients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ingredients_OneArgs = {
  object: Ingredients_Insert_Input;
  on_conflict?: Maybe<Ingredients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LessorsArgs = {
  objects: Array<Lessors_Insert_Input>;
  on_conflict?: Maybe<Lessors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lessors_OneArgs = {
  object: Lessors_Insert_Input;
  on_conflict?: Maybe<Lessors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_TypesArgs = {
  objects: Array<Menu_Types_Insert_Input>;
  on_conflict?: Maybe<Menu_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Types_OneArgs = {
  object: Menu_Types_Insert_Input;
  on_conflict?: Maybe<Menu_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MinutesArgs = {
  objects: Array<Minutes_Insert_Input>;
  on_conflict?: Maybe<Minutes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minutes_OneArgs = {
  object: Minutes_Insert_Input;
  on_conflict?: Maybe<Minutes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minutes_TypesArgs = {
  objects: Array<Minutes_Types_Insert_Input>;
  on_conflict?: Maybe<Minutes_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minutes_Types_OneArgs = {
  object: Minutes_Types_Insert_Input;
  on_conflict?: Maybe<Minutes_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Modifier_Group_DishArgs = {
  objects: Array<Modifier_Group_Dish_Insert_Input>;
  on_conflict?: Maybe<Modifier_Group_Dish_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Modifier_Group_Dish_OneArgs = {
  object: Modifier_Group_Dish_Insert_Input;
  on_conflict?: Maybe<Modifier_Group_Dish_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Modifier_GroupsArgs = {
  objects: Array<Modifier_Groups_Insert_Input>;
  on_conflict?: Maybe<Modifier_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Modifier_Groups_OneArgs = {
  object: Modifier_Groups_Insert_Input;
  on_conflict?: Maybe<Modifier_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModifiersArgs = {
  objects: Array<Modifiers_Insert_Input>;
  on_conflict?: Maybe<Modifiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Modifiers_OneArgs = {
  object: Modifiers_Insert_Input;
  on_conflict?: Maybe<Modifiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MotionsArgs = {
  objects: Array<Motions_Insert_Input>;
  on_conflict?: Maybe<Motions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Motions_OneArgs = {
  object: Motions_Insert_Input;
  on_conflict?: Maybe<Motions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Motions_VoteArgs = {
  objects: Array<Motions_Vote_Insert_Input>;
  on_conflict?: Maybe<Motions_Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Motions_Vote_OneArgs = {
  object: Motions_Vote_Insert_Input;
  on_conflict?: Maybe<Motions_Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_OwnersArgs = {
  objects: Array<New_Owners_Insert_Input>;
  on_conflict?: Maybe<New_Owners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Owners_OneArgs = {
  object: New_Owners_Insert_Input;
  on_conflict?: Maybe<New_Owners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_DishArgs = {
  objects: Array<Order_Dish_Insert_Input>;
  on_conflict?: Maybe<Order_Dish_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_IngredientsArgs = {
  objects: Array<Order_Dish_Ingredients_Insert_Input>;
  on_conflict?: Maybe<Order_Dish_Ingredients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_Ingredients_OneArgs = {
  object: Order_Dish_Ingredients_Insert_Input;
  on_conflict?: Maybe<Order_Dish_Ingredients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_Modifier_GroupsArgs = {
  objects: Array<Order_Dish_Modifier_Groups_Insert_Input>;
  on_conflict?: Maybe<Order_Dish_Modifier_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_Modifier_Groups_OneArgs = {
  object: Order_Dish_Modifier_Groups_Insert_Input;
  on_conflict?: Maybe<Order_Dish_Modifier_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_ModifiersArgs = {
  objects: Array<Order_Dish_Modifiers_Insert_Input>;
  on_conflict?: Maybe<Order_Dish_Modifiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_Modifiers_OneArgs = {
  object: Order_Dish_Modifiers_Insert_Input;
  on_conflict?: Maybe<Order_Dish_Modifiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Dish_OneArgs = {
  object: Order_Dish_Insert_Input;
  on_conflict?: Maybe<Order_Dish_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_ActionsArgs = {
  objects: Array<Orders_Actions_Insert_Input>;
  on_conflict?: Maybe<Orders_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_Actions_OneArgs = {
  object: Orders_Actions_Insert_Input;
  on_conflict?: Maybe<Orders_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Palapa_AccountArgs = {
  objects: Array<Palapa_Account_Insert_Input>;
  on_conflict?: Maybe<Palapa_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Palapa_Account_OneArgs = {
  object: Palapa_Account_Insert_Input;
  on_conflict?: Maybe<Palapa_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Palapa_ItemsArgs = {
  objects: Array<Palapa_Items_Insert_Input>;
  on_conflict?: Maybe<Palapa_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Palapa_Items_OneArgs = {
  object: Palapa_Items_Insert_Input;
  on_conflict?: Maybe<Palapa_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PhonesArgs = {
  objects: Array<Phones_Insert_Input>;
  on_conflict?: Maybe<Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phones_OneArgs = {
  object: Phones_Insert_Input;
  on_conflict?: Maybe<Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_TemplatesArgs = {
  objects: Array<Print_Templates_Insert_Input>;
  on_conflict?: Maybe<Print_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_Templates_OneArgs = {
  object: Print_Templates_Insert_Input;
  on_conflict?: Maybe<Print_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_TypesArgs = {
  objects: Array<Print_Types_Insert_Input>;
  on_conflict?: Maybe<Print_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_Types_OneArgs = {
  object: Print_Types_Insert_Input;
  on_conflict?: Maybe<Print_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Printer_LogsArgs = {
  objects: Array<Printer_Logs_Insert_Input>;
  on_conflict?: Maybe<Printer_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Printer_Logs_OneArgs = {
  object: Printer_Logs_Insert_Input;
  on_conflict?: Maybe<Printer_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrintersArgs = {
  objects: Array<Printers_Insert_Input>;
  on_conflict?: Maybe<Printers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Printers_OneArgs = {
  object: Printers_Insert_Input;
  on_conflict?: Maybe<Printers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_ManagersArgs = {
  objects: Array<Property_Managers_Insert_Input>;
  on_conflict?: Maybe<Property_Managers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Managers_OneArgs = {
  object: Property_Managers_Insert_Input;
  on_conflict?: Maybe<Property_Managers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProvidersArgs = {
  objects: Array<Providers_Insert_Input>;
  on_conflict?: Maybe<Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_OneArgs = {
  object: Providers_Insert_Input;
  on_conflict?: Maybe<Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_RatingsArgs = {
  objects: Array<Providers_Ratings_Insert_Input>;
  on_conflict?: Maybe<Providers_Ratings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_Ratings_OneArgs = {
  object: Providers_Ratings_Insert_Input;
  on_conflict?: Maybe<Providers_Ratings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_ServicesArgs = {
  objects: Array<Providers_Services_Insert_Input>;
  on_conflict?: Maybe<Providers_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_Services_OneArgs = {
  object: Providers_Services_Insert_Input;
  on_conflict?: Maybe<Providers_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RentalArgs = {
  objects: Array<Rental_Insert_Input>;
  on_conflict?: Maybe<Rental_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_OneArgs = {
  object: Rental_Insert_Input;
  on_conflict?: Maybe<Rental_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rules_And_RegulationsArgs = {
  objects: Array<Rules_And_Regulations_Insert_Input>;
  on_conflict?: Maybe<Rules_And_Regulations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rules_And_Regulations_OneArgs = {
  object: Rules_And_Regulations_Insert_Input;
  on_conflict?: Maybe<Rules_And_Regulations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServicesArgs = {
  objects: Array<Services_Insert_Input>;
  on_conflict?: Maybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_OneArgs = {
  object: Services_Insert_Input;
  on_conflict?: Maybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statement_AccountArgs = {
  objects: Array<Statement_Account_Insert_Input>;
  on_conflict?: Maybe<Statement_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statement_Account_OneArgs = {
  object: Statement_Account_Insert_Input;
  on_conflict?: Maybe<Statement_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubcategoriesArgs = {
  objects: Array<Subcategories_Insert_Input>;
  on_conflict?: Maybe<Subcategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subcategories_OneArgs = {
  object: Subcategories_Insert_Input;
  on_conflict?: Maybe<Subcategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TablesArgs = {
  objects: Array<Tables_Insert_Input>;
  on_conflict?: Maybe<Tables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tables_OneArgs = {
  object: Tables_Insert_Input;
  on_conflict?: Maybe<Tables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_TypeArgs = {
  objects: Array<Transaction_Type_Insert_Input>;
  on_conflict?: Maybe<Transaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_Type_OneArgs = {
  object: Transaction_Type_Insert_Input;
  on_conflict?: Maybe<Transaction_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UnitsArgs = {
  objects: Array<Units_Insert_Input>;
  on_conflict?: Maybe<Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Units_OneArgs = {
  object: Units_Insert_Input;
  on_conflict?: Maybe<Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VisitsArgs = {
  objects: Array<Visits_Insert_Input>;
  on_conflict?: Maybe<Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Visits_OneArgs = {
  object: Visits_Insert_Input;
  on_conflict?: Maybe<Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_TicketsArgs = {
  _inc?: Maybe<Account_Tickets_Inc_Input>;
  _set?: Maybe<Account_Tickets_Set_Input>;
  where: Account_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Tickets_By_PkArgs = {
  _inc?: Maybe<Account_Tickets_Inc_Input>;
  _set?: Maybe<Account_Tickets_Set_Input>;
  pk_columns: Account_Tickets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ActivitiesArgs = {
  _inc?: Maybe<Activities_Inc_Input>;
  _set?: Maybe<Activities_Set_Input>;
  where: Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activities_By_PkArgs = {
  _inc?: Maybe<Activities_Inc_Input>;
  _set?: Maybe<Activities_Set_Input>;
  pk_columns: Activities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AddressesArgs = {
  _inc?: Maybe<Addresses_Inc_Input>;
  _set?: Maybe<Addresses_Set_Input>;
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_By_PkArgs = {
  _inc?: Maybe<Addresses_Inc_Input>;
  _set?: Maybe<Addresses_Set_Input>;
  pk_columns: Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_ItemsArgs = {
  _inc?: Maybe<Admin_Items_Inc_Input>;
  _set?: Maybe<Admin_Items_Set_Input>;
  where: Admin_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Items_By_PkArgs = {
  _inc?: Maybe<Admin_Items_Inc_Input>;
  _set?: Maybe<Admin_Items_Set_Input>;
  pk_columns: Admin_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_RecordArgs = {
  _inc?: Maybe<Admin_Record_Inc_Input>;
  _set?: Maybe<Admin_Record_Set_Input>;
  where: Admin_Record_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Record_By_PkArgs = {
  _inc?: Maybe<Admin_Record_Inc_Input>;
  _set?: Maybe<Admin_Record_Set_Input>;
  pk_columns: Admin_Record_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApartmentArgs = {
  _inc?: Maybe<Apartment_Inc_Input>;
  _set?: Maybe<Apartment_Set_Input>;
  where: Apartment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apartment_By_PkArgs = {
  _inc?: Maybe<Apartment_Inc_Input>;
  _set?: Maybe<Apartment_Set_Input>;
  pk_columns: Apartment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppointmentsArgs = {
  _inc?: Maybe<Appointments_Inc_Input>;
  _set?: Maybe<Appointments_Set_Input>;
  where: Appointments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Appointments_By_PkArgs = {
  _inc?: Maybe<Appointments_Inc_Input>;
  _set?: Maybe<Appointments_Set_Input>;
  pk_columns: Appointments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ArrivalsArgs = {
  _inc?: Maybe<Arrivals_Inc_Input>;
  _set?: Maybe<Arrivals_Set_Input>;
  where: Arrivals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Arrivals_By_PkArgs = {
  _inc?: Maybe<Arrivals_Inc_Input>;
  _set?: Maybe<Arrivals_Set_Input>;
  pk_columns: Arrivals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BlogArgs = {
  _inc?: Maybe<Blog_Inc_Input>;
  _set?: Maybe<Blog_Set_Input>;
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_By_PkArgs = {
  _inc?: Maybe<Blog_Inc_Input>;
  _set?: Maybe<Blog_Set_Input>;
  pk_columns: Blog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_TypesArgs = {
  _inc?: Maybe<Blog_Types_Inc_Input>;
  _set?: Maybe<Blog_Types_Set_Input>;
  where: Blog_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Types_By_PkArgs = {
  _inc?: Maybe<Blog_Types_Inc_Input>;
  _set?: Maybe<Blog_Types_Set_Input>;
  pk_columns: Blog_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BuildingsArgs = {
  _inc?: Maybe<Buildings_Inc_Input>;
  _set?: Maybe<Buildings_Set_Input>;
  where: Buildings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Buildings_By_PkArgs = {
  _inc?: Maybe<Buildings_Inc_Input>;
  _set?: Maybe<Buildings_Set_Input>;
  pk_columns: Buildings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _inc?: Maybe<Categories_Inc_Input>;
  _set?: Maybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _inc?: Maybe<Categories_Inc_Input>;
  _set?: Maybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Area_TypesArgs = {
  _inc?: Maybe<Common_Area_Types_Inc_Input>;
  _set?: Maybe<Common_Area_Types_Set_Input>;
  where: Common_Area_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Area_Types_By_PkArgs = {
  _inc?: Maybe<Common_Area_Types_Inc_Input>;
  _set?: Maybe<Common_Area_Types_Set_Input>;
  pk_columns: Common_Area_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_AreasArgs = {
  _inc?: Maybe<Common_Areas_Inc_Input>;
  _set?: Maybe<Common_Areas_Set_Input>;
  where: Common_Areas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Areas_By_PkArgs = {
  _inc?: Maybe<Common_Areas_Inc_Input>;
  _set?: Maybe<Common_Areas_Set_Input>;
  pk_columns: Common_Areas_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContactArgs = {
  _inc?: Maybe<Contact_Inc_Input>;
  _set?: Maybe<Contact_Set_Input>;
  where: Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_AddressesArgs = {
  _inc?: Maybe<Contact_Addresses_Inc_Input>;
  _set?: Maybe<Contact_Addresses_Set_Input>;
  where: Contact_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Addresses_By_PkArgs = {
  _inc?: Maybe<Contact_Addresses_Inc_Input>;
  _set?: Maybe<Contact_Addresses_Set_Input>;
  pk_columns: Contact_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_By_PkArgs = {
  _inc?: Maybe<Contact_Inc_Input>;
  _set?: Maybe<Contact_Set_Input>;
  pk_columns: Contact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_EmailsArgs = {
  _inc?: Maybe<Contact_Emails_Inc_Input>;
  _set?: Maybe<Contact_Emails_Set_Input>;
  where: Contact_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Emails_By_PkArgs = {
  _inc?: Maybe<Contact_Emails_Inc_Input>;
  _set?: Maybe<Contact_Emails_Set_Input>;
  pk_columns: Contact_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_PhonesArgs = {
  _inc?: Maybe<Contact_Phones_Inc_Input>;
  _set?: Maybe<Contact_Phones_Set_Input>;
  where: Contact_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Phones_By_PkArgs = {
  _inc?: Maybe<Contact_Phones_Inc_Input>;
  _set?: Maybe<Contact_Phones_Set_Input>;
  pk_columns: Contact_Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_TypeArgs = {
  _set?: Maybe<Currency_Type_Set_Input>;
  where: Currency_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Type_By_PkArgs = {
  _set?: Maybe<Currency_Type_Set_Input>;
  pk_columns: Currency_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DishesArgs = {
  _inc?: Maybe<Dishes_Inc_Input>;
  _set?: Maybe<Dishes_Set_Input>;
  where: Dishes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dishes_By_PkArgs = {
  _inc?: Maybe<Dishes_Inc_Input>;
  _set?: Maybe<Dishes_Set_Input>;
  pk_columns: Dishes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailArgs = {
  _inc?: Maybe<Email_Inc_Input>;
  _set?: Maybe<Email_Set_Input>;
  where: Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_By_PkArgs = {
  _inc?: Maybe<Email_Inc_Input>;
  _set?: Maybe<Email_Set_Input>;
  pk_columns: Email_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeesArgs = {
  _inc?: Maybe<Employees_Inc_Input>;
  _set?: Maybe<Employees_Set_Input>;
  where: Employees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_By_PkArgs = {
  _inc?: Maybe<Employees_Inc_Input>;
  _set?: Maybe<Employees_Set_Input>;
  pk_columns: Employees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExchangeArgs = {
  _inc?: Maybe<Exchange_Inc_Input>;
  _set?: Maybe<Exchange_Set_Input>;
  where: Exchange_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Exchange_By_PkArgs = {
  _inc?: Maybe<Exchange_Inc_Input>;
  _set?: Maybe<Exchange_Set_Input>;
  pk_columns: Exchange_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_History_CommentsArgs = {
  _inc?: Maybe<History_Comments_Inc_Input>;
  _set?: Maybe<History_Comments_Set_Input>;
  where: History_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_History_Comments_By_PkArgs = {
  _inc?: Maybe<History_Comments_Inc_Input>;
  _set?: Maybe<History_Comments_Set_Input>;
  pk_columns: History_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ingredient_DishArgs = {
  _inc?: Maybe<Ingredient_Dish_Inc_Input>;
  _set?: Maybe<Ingredient_Dish_Set_Input>;
  where: Ingredient_Dish_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ingredient_Dish_By_PkArgs = {
  _inc?: Maybe<Ingredient_Dish_Inc_Input>;
  _set?: Maybe<Ingredient_Dish_Set_Input>;
  pk_columns: Ingredient_Dish_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IngredientsArgs = {
  _inc?: Maybe<Ingredients_Inc_Input>;
  _set?: Maybe<Ingredients_Set_Input>;
  where: Ingredients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ingredients_By_PkArgs = {
  _inc?: Maybe<Ingredients_Inc_Input>;
  _set?: Maybe<Ingredients_Set_Input>;
  pk_columns: Ingredients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LessorsArgs = {
  _inc?: Maybe<Lessors_Inc_Input>;
  _set?: Maybe<Lessors_Set_Input>;
  where: Lessors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lessors_By_PkArgs = {
  _inc?: Maybe<Lessors_Inc_Input>;
  _set?: Maybe<Lessors_Set_Input>;
  pk_columns: Lessors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_TypesArgs = {
  _inc?: Maybe<Menu_Types_Inc_Input>;
  _set?: Maybe<Menu_Types_Set_Input>;
  where: Menu_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Types_By_PkArgs = {
  _inc?: Maybe<Menu_Types_Inc_Input>;
  _set?: Maybe<Menu_Types_Set_Input>;
  pk_columns: Menu_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MinutesArgs = {
  _inc?: Maybe<Minutes_Inc_Input>;
  _set?: Maybe<Minutes_Set_Input>;
  where: Minutes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minutes_By_PkArgs = {
  _inc?: Maybe<Minutes_Inc_Input>;
  _set?: Maybe<Minutes_Set_Input>;
  pk_columns: Minutes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Minutes_TypesArgs = {
  _inc?: Maybe<Minutes_Types_Inc_Input>;
  _set?: Maybe<Minutes_Types_Set_Input>;
  where: Minutes_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minutes_Types_By_PkArgs = {
  _inc?: Maybe<Minutes_Types_Inc_Input>;
  _set?: Maybe<Minutes_Types_Set_Input>;
  pk_columns: Minutes_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Modifier_Group_DishArgs = {
  _inc?: Maybe<Modifier_Group_Dish_Inc_Input>;
  _set?: Maybe<Modifier_Group_Dish_Set_Input>;
  where: Modifier_Group_Dish_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Modifier_Group_Dish_By_PkArgs = {
  _inc?: Maybe<Modifier_Group_Dish_Inc_Input>;
  _set?: Maybe<Modifier_Group_Dish_Set_Input>;
  pk_columns: Modifier_Group_Dish_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Modifier_GroupsArgs = {
  _inc?: Maybe<Modifier_Groups_Inc_Input>;
  _set?: Maybe<Modifier_Groups_Set_Input>;
  where: Modifier_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Modifier_Groups_By_PkArgs = {
  _inc?: Maybe<Modifier_Groups_Inc_Input>;
  _set?: Maybe<Modifier_Groups_Set_Input>;
  pk_columns: Modifier_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ModifiersArgs = {
  _inc?: Maybe<Modifiers_Inc_Input>;
  _set?: Maybe<Modifiers_Set_Input>;
  where: Modifiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Modifiers_By_PkArgs = {
  _inc?: Maybe<Modifiers_Inc_Input>;
  _set?: Maybe<Modifiers_Set_Input>;
  pk_columns: Modifiers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MotionsArgs = {
  _inc?: Maybe<Motions_Inc_Input>;
  _set?: Maybe<Motions_Set_Input>;
  where: Motions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Motions_By_PkArgs = {
  _inc?: Maybe<Motions_Inc_Input>;
  _set?: Maybe<Motions_Set_Input>;
  pk_columns: Motions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Motions_VoteArgs = {
  _inc?: Maybe<Motions_Vote_Inc_Input>;
  _set?: Maybe<Motions_Vote_Set_Input>;
  where: Motions_Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Motions_Vote_By_PkArgs = {
  _inc?: Maybe<Motions_Vote_Inc_Input>;
  _set?: Maybe<Motions_Vote_Set_Input>;
  pk_columns: Motions_Vote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_OwnersArgs = {
  _inc?: Maybe<New_Owners_Inc_Input>;
  _set?: Maybe<New_Owners_Set_Input>;
  where: New_Owners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Owners_By_PkArgs = {
  _inc?: Maybe<New_Owners_Inc_Input>;
  _set?: Maybe<New_Owners_Set_Input>;
  pk_columns: New_Owners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_DishArgs = {
  _inc?: Maybe<Order_Dish_Inc_Input>;
  _set?: Maybe<Order_Dish_Set_Input>;
  where: Order_Dish_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_By_PkArgs = {
  _inc?: Maybe<Order_Dish_Inc_Input>;
  _set?: Maybe<Order_Dish_Set_Input>;
  pk_columns: Order_Dish_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_IngredientsArgs = {
  _inc?: Maybe<Order_Dish_Ingredients_Inc_Input>;
  _set?: Maybe<Order_Dish_Ingredients_Set_Input>;
  where: Order_Dish_Ingredients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_Ingredients_By_PkArgs = {
  _inc?: Maybe<Order_Dish_Ingredients_Inc_Input>;
  _set?: Maybe<Order_Dish_Ingredients_Set_Input>;
  pk_columns: Order_Dish_Ingredients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_Modifier_GroupsArgs = {
  _inc?: Maybe<Order_Dish_Modifier_Groups_Inc_Input>;
  _set?: Maybe<Order_Dish_Modifier_Groups_Set_Input>;
  where: Order_Dish_Modifier_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_Modifier_Groups_By_PkArgs = {
  _inc?: Maybe<Order_Dish_Modifier_Groups_Inc_Input>;
  _set?: Maybe<Order_Dish_Modifier_Groups_Set_Input>;
  pk_columns: Order_Dish_Modifier_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_ModifiersArgs = {
  _inc?: Maybe<Order_Dish_Modifiers_Inc_Input>;
  _set?: Maybe<Order_Dish_Modifiers_Set_Input>;
  where: Order_Dish_Modifiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Dish_Modifiers_By_PkArgs = {
  _inc?: Maybe<Order_Dish_Modifiers_Inc_Input>;
  _set?: Maybe<Order_Dish_Modifiers_Set_Input>;
  pk_columns: Order_Dish_Modifiers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_ActionsArgs = {
  _inc?: Maybe<Orders_Actions_Inc_Input>;
  _set?: Maybe<Orders_Actions_Set_Input>;
  where: Orders_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_Actions_By_PkArgs = {
  _inc?: Maybe<Orders_Actions_Inc_Input>;
  _set?: Maybe<Orders_Actions_Set_Input>;
  pk_columns: Orders_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Palapa_AccountArgs = {
  _inc?: Maybe<Palapa_Account_Inc_Input>;
  _set?: Maybe<Palapa_Account_Set_Input>;
  where: Palapa_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Palapa_Account_By_PkArgs = {
  _inc?: Maybe<Palapa_Account_Inc_Input>;
  _set?: Maybe<Palapa_Account_Set_Input>;
  pk_columns: Palapa_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Palapa_ItemsArgs = {
  _inc?: Maybe<Palapa_Items_Inc_Input>;
  _set?: Maybe<Palapa_Items_Set_Input>;
  where: Palapa_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Palapa_Items_By_PkArgs = {
  _inc?: Maybe<Palapa_Items_Inc_Input>;
  _set?: Maybe<Palapa_Items_Set_Input>;
  pk_columns: Palapa_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PhonesArgs = {
  _inc?: Maybe<Phones_Inc_Input>;
  _set?: Maybe<Phones_Set_Input>;
  where: Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Phones_By_PkArgs = {
  _inc?: Maybe<Phones_Inc_Input>;
  _set?: Maybe<Phones_Set_Input>;
  pk_columns: Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Print_TemplatesArgs = {
  _inc?: Maybe<Print_Templates_Inc_Input>;
  _set?: Maybe<Print_Templates_Set_Input>;
  where: Print_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Print_Templates_By_PkArgs = {
  _inc?: Maybe<Print_Templates_Inc_Input>;
  _set?: Maybe<Print_Templates_Set_Input>;
  pk_columns: Print_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Print_TypesArgs = {
  _inc?: Maybe<Print_Types_Inc_Input>;
  _set?: Maybe<Print_Types_Set_Input>;
  where: Print_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Print_Types_By_PkArgs = {
  _inc?: Maybe<Print_Types_Inc_Input>;
  _set?: Maybe<Print_Types_Set_Input>;
  pk_columns: Print_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Printer_LogsArgs = {
  _inc?: Maybe<Printer_Logs_Inc_Input>;
  _set?: Maybe<Printer_Logs_Set_Input>;
  where: Printer_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Printer_Logs_By_PkArgs = {
  _inc?: Maybe<Printer_Logs_Inc_Input>;
  _set?: Maybe<Printer_Logs_Set_Input>;
  pk_columns: Printer_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrintersArgs = {
  _inc?: Maybe<Printers_Inc_Input>;
  _set?: Maybe<Printers_Set_Input>;
  where: Printers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Printers_By_PkArgs = {
  _inc?: Maybe<Printers_Inc_Input>;
  _set?: Maybe<Printers_Set_Input>;
  pk_columns: Printers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ManagersArgs = {
  _inc?: Maybe<Property_Managers_Inc_Input>;
  _set?: Maybe<Property_Managers_Set_Input>;
  where: Property_Managers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Managers_By_PkArgs = {
  _inc?: Maybe<Property_Managers_Inc_Input>;
  _set?: Maybe<Property_Managers_Set_Input>;
  pk_columns: Property_Managers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProvidersArgs = {
  _inc?: Maybe<Providers_Inc_Input>;
  _set?: Maybe<Providers_Set_Input>;
  where: Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_By_PkArgs = {
  _inc?: Maybe<Providers_Inc_Input>;
  _set?: Maybe<Providers_Set_Input>;
  pk_columns: Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_RatingsArgs = {
  _inc?: Maybe<Providers_Ratings_Inc_Input>;
  _set?: Maybe<Providers_Ratings_Set_Input>;
  where: Providers_Ratings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_Ratings_By_PkArgs = {
  _inc?: Maybe<Providers_Ratings_Inc_Input>;
  _set?: Maybe<Providers_Ratings_Set_Input>;
  pk_columns: Providers_Ratings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_ServicesArgs = {
  _inc?: Maybe<Providers_Services_Inc_Input>;
  _set?: Maybe<Providers_Services_Set_Input>;
  where: Providers_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_Services_By_PkArgs = {
  _inc?: Maybe<Providers_Services_Inc_Input>;
  _set?: Maybe<Providers_Services_Set_Input>;
  pk_columns: Providers_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RentalArgs = {
  _inc?: Maybe<Rental_Inc_Input>;
  _set?: Maybe<Rental_Set_Input>;
  where: Rental_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_By_PkArgs = {
  _inc?: Maybe<Rental_Inc_Input>;
  _set?: Maybe<Rental_Set_Input>;
  pk_columns: Rental_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rules_And_RegulationsArgs = {
  _inc?: Maybe<Rules_And_Regulations_Inc_Input>;
  _set?: Maybe<Rules_And_Regulations_Set_Input>;
  where: Rules_And_Regulations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rules_And_Regulations_By_PkArgs = {
  _inc?: Maybe<Rules_And_Regulations_Inc_Input>;
  _set?: Maybe<Rules_And_Regulations_Set_Input>;
  pk_columns: Rules_And_Regulations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServicesArgs = {
  _inc?: Maybe<Services_Inc_Input>;
  _set?: Maybe<Services_Set_Input>;
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_By_PkArgs = {
  _inc?: Maybe<Services_Inc_Input>;
  _set?: Maybe<Services_Set_Input>;
  pk_columns: Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statement_AccountArgs = {
  _inc?: Maybe<Statement_Account_Inc_Input>;
  _set?: Maybe<Statement_Account_Set_Input>;
  where: Statement_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statement_Account_By_PkArgs = {
  _inc?: Maybe<Statement_Account_Inc_Input>;
  _set?: Maybe<Statement_Account_Set_Input>;
  pk_columns: Statement_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubcategoriesArgs = {
  _inc?: Maybe<Subcategories_Inc_Input>;
  _set?: Maybe<Subcategories_Set_Input>;
  where: Subcategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcategories_By_PkArgs = {
  _inc?: Maybe<Subcategories_Inc_Input>;
  _set?: Maybe<Subcategories_Set_Input>;
  pk_columns: Subcategories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TablesArgs = {
  _inc?: Maybe<Tables_Inc_Input>;
  _set?: Maybe<Tables_Set_Input>;
  where: Tables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tables_By_PkArgs = {
  _inc?: Maybe<Tables_Inc_Input>;
  _set?: Maybe<Tables_Set_Input>;
  pk_columns: Tables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_TypeArgs = {
  _set?: Maybe<Transaction_Type_Set_Input>;
  where: Transaction_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_Type_By_PkArgs = {
  _set?: Maybe<Transaction_Type_Set_Input>;
  pk_columns: Transaction_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _inc?: Maybe<Transactions_Inc_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _inc?: Maybe<Transactions_Inc_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UnitsArgs = {
  _inc?: Maybe<Units_Inc_Input>;
  _set?: Maybe<Units_Set_Input>;
  where: Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Units_By_PkArgs = {
  _inc?: Maybe<Units_Inc_Input>;
  _set?: Maybe<Units_Set_Input>;
  pk_columns: Units_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VisitsArgs = {
  _inc?: Maybe<Visits_Inc_Input>;
  _set?: Maybe<Visits_Set_Input>;
  where: Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Visits_By_PkArgs = {
  _inc?: Maybe<Visits_Inc_Input>;
  _set?: Maybe<Visits_Set_Input>;
  pk_columns: Visits_Pk_Columns_Input;
};

/** columns and relationships of "new_owners" */
export type New_Owners = {
  __typename?: 'new_owners';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "new_owners" */
export type New_Owners_Aggregate = {
  __typename?: 'new_owners_aggregate';
  aggregate?: Maybe<New_Owners_Aggregate_Fields>;
  nodes: Array<New_Owners>;
};

/** aggregate fields of "new_owners" */
export type New_Owners_Aggregate_Fields = {
  __typename?: 'new_owners_aggregate_fields';
  avg?: Maybe<New_Owners_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Owners_Max_Fields>;
  min?: Maybe<New_Owners_Min_Fields>;
  stddev?: Maybe<New_Owners_Stddev_Fields>;
  stddev_pop?: Maybe<New_Owners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Owners_Stddev_Samp_Fields>;
  sum?: Maybe<New_Owners_Sum_Fields>;
  var_pop?: Maybe<New_Owners_Var_Pop_Fields>;
  var_samp?: Maybe<New_Owners_Var_Samp_Fields>;
  variance?: Maybe<New_Owners_Variance_Fields>;
};


/** aggregate fields of "new_owners" */
export type New_Owners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<New_Owners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "new_owners" */
export type New_Owners_Aggregate_Order_By = {
  avg?: Maybe<New_Owners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<New_Owners_Max_Order_By>;
  min?: Maybe<New_Owners_Min_Order_By>;
  stddev?: Maybe<New_Owners_Stddev_Order_By>;
  stddev_pop?: Maybe<New_Owners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<New_Owners_Stddev_Samp_Order_By>;
  sum?: Maybe<New_Owners_Sum_Order_By>;
  var_pop?: Maybe<New_Owners_Var_Pop_Order_By>;
  var_samp?: Maybe<New_Owners_Var_Samp_Order_By>;
  variance?: Maybe<New_Owners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "new_owners" */
export type New_Owners_Arr_Rel_Insert_Input = {
  data: Array<New_Owners_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<New_Owners_On_Conflict>;
};

/** aggregate avg on columns */
export type New_Owners_Avg_Fields = {
  __typename?: 'new_owners_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "new_owners" */
export type New_Owners_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "new_owners". All fields are combined with a logical 'AND'. */
export type New_Owners_Bool_Exp = {
  _and?: Maybe<Array<New_Owners_Bool_Exp>>;
  _not?: Maybe<New_Owners_Bool_Exp>;
  _or?: Maybe<Array<New_Owners_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Int_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "new_owners" */
export enum New_Owners_Constraint {
  /** unique or primary key constraint */
  NewOwnersPkey = 'New_owners_pkey'
}

/** input type for incrementing numeric columns in table "new_owners" */
export type New_Owners_Inc_Input = {
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "new_owners" */
export type New_Owners_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type New_Owners_Max_Fields = {
  __typename?: 'new_owners_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "new_owners" */
export type New_Owners_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type New_Owners_Min_Fields = {
  __typename?: 'new_owners_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "new_owners" */
export type New_Owners_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "new_owners" */
export type New_Owners_Mutation_Response = {
  __typename?: 'new_owners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Owners>;
};

/** on_conflict condition type for table "new_owners" */
export type New_Owners_On_Conflict = {
  constraint: New_Owners_Constraint;
  update_columns?: Array<New_Owners_Update_Column>;
  where?: Maybe<New_Owners_Bool_Exp>;
};

/** Ordering options when selecting data from "new_owners". */
export type New_Owners_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: new_owners */
export type New_Owners_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "new_owners" */
export enum New_Owners_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "new_owners" */
export type New_Owners_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type New_Owners_Stddev_Fields = {
  __typename?: 'new_owners_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "new_owners" */
export type New_Owners_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type New_Owners_Stddev_Pop_Fields = {
  __typename?: 'new_owners_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "new_owners" */
export type New_Owners_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type New_Owners_Stddev_Samp_Fields = {
  __typename?: 'new_owners_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "new_owners" */
export type New_Owners_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type New_Owners_Sum_Fields = {
  __typename?: 'new_owners_sum_fields';
  created_by?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "new_owners" */
export type New_Owners_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "new_owners" */
export enum New_Owners_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type New_Owners_Var_Pop_Fields = {
  __typename?: 'new_owners_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "new_owners" */
export type New_Owners_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type New_Owners_Var_Samp_Fields = {
  __typename?: 'new_owners_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "new_owners" */
export type New_Owners_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type New_Owners_Variance_Fields = {
  __typename?: 'new_owners_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "new_owners" */
export type New_Owners_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_dish" */
export type Order_Dish = {
  __typename?: 'order_dish';
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled: Scalars['Boolean'];
  /** An object relationship */
  dish: Dishes;
  dish_cost: Scalars['numeric'];
  dish_id: Scalars['Int'];
  dish_price: Scalars['numeric'];
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  order: Orders;
  /** An array relationship */
  order_dish_ingredients: Array<Order_Dish_Ingredients>;
  /** An aggregate relationship */
  order_dish_ingredients_aggregate: Order_Dish_Ingredients_Aggregate;
  /** An array relationship */
  order_dish_modifier_groups: Array<Order_Dish_Modifier_Groups>;
  /** An aggregate relationship */
  order_dish_modifier_groups_aggregate: Order_Dish_Modifier_Groups_Aggregate;
  order_id: Scalars['Int'];
  quantity: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "order_dish" */
export type Order_DishOrder_Dish_IngredientsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


/** columns and relationships of "order_dish" */
export type Order_DishOrder_Dish_Ingredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


/** columns and relationships of "order_dish" */
export type Order_DishOrder_Dish_Modifier_GroupsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};


/** columns and relationships of "order_dish" */
export type Order_DishOrder_Dish_Modifier_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};

/** aggregated selection of "order_dish" */
export type Order_Dish_Aggregate = {
  __typename?: 'order_dish_aggregate';
  aggregate?: Maybe<Order_Dish_Aggregate_Fields>;
  nodes: Array<Order_Dish>;
};

/** aggregate fields of "order_dish" */
export type Order_Dish_Aggregate_Fields = {
  __typename?: 'order_dish_aggregate_fields';
  avg?: Maybe<Order_Dish_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Dish_Max_Fields>;
  min?: Maybe<Order_Dish_Min_Fields>;
  stddev?: Maybe<Order_Dish_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Dish_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Dish_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Dish_Sum_Fields>;
  var_pop?: Maybe<Order_Dish_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Dish_Var_Samp_Fields>;
  variance?: Maybe<Order_Dish_Variance_Fields>;
};


/** aggregate fields of "order_dish" */
export type Order_Dish_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Dish_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_dish" */
export type Order_Dish_Aggregate_Order_By = {
  avg?: Maybe<Order_Dish_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Dish_Max_Order_By>;
  min?: Maybe<Order_Dish_Min_Order_By>;
  stddev?: Maybe<Order_Dish_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Dish_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Dish_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Dish_Sum_Order_By>;
  var_pop?: Maybe<Order_Dish_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Dish_Var_Samp_Order_By>;
  variance?: Maybe<Order_Dish_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_dish" */
export type Order_Dish_Arr_Rel_Insert_Input = {
  data: Array<Order_Dish_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_Dish_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Dish_Avg_Fields = {
  __typename?: 'order_dish_avg_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_dish" */
export type Order_Dish_Avg_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_dish". All fields are combined with a logical 'AND'. */
export type Order_Dish_Bool_Exp = {
  _and?: Maybe<Array<Order_Dish_Bool_Exp>>;
  _not?: Maybe<Order_Dish_Bool_Exp>;
  _or?: Maybe<Array<Order_Dish_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  dish?: Maybe<Dishes_Bool_Exp>;
  dish_cost?: Maybe<Numeric_Comparison_Exp>;
  dish_id?: Maybe<Int_Comparison_Exp>;
  dish_price?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
  order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_dish" */
export enum Order_Dish_Constraint {
  /** unique or primary key constraint */
  OrderDishPkey = 'order_dish_pkey'
}

/** input type for incrementing numeric columns in table "order_dish" */
export type Order_Dish_Inc_Input = {
  dish_cost?: Maybe<Scalars['numeric']>;
  dish_id?: Maybe<Scalars['Int']>;
  dish_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "order_dish_ingredients" */
export type Order_Dish_Ingredients = {
  __typename?: 'order_dish_ingredients';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An object relationship */
  ingredient: Ingredients;
  ingredient_id: Scalars['Int'];
  /** An object relationship */
  order_dish: Order_Dish;
  order_dish_id: Scalars['Int'];
};

/** aggregated selection of "order_dish_ingredients" */
export type Order_Dish_Ingredients_Aggregate = {
  __typename?: 'order_dish_ingredients_aggregate';
  aggregate?: Maybe<Order_Dish_Ingredients_Aggregate_Fields>;
  nodes: Array<Order_Dish_Ingredients>;
};

/** aggregate fields of "order_dish_ingredients" */
export type Order_Dish_Ingredients_Aggregate_Fields = {
  __typename?: 'order_dish_ingredients_aggregate_fields';
  avg?: Maybe<Order_Dish_Ingredients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Dish_Ingredients_Max_Fields>;
  min?: Maybe<Order_Dish_Ingredients_Min_Fields>;
  stddev?: Maybe<Order_Dish_Ingredients_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Dish_Ingredients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Dish_Ingredients_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Dish_Ingredients_Sum_Fields>;
  var_pop?: Maybe<Order_Dish_Ingredients_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Dish_Ingredients_Var_Samp_Fields>;
  variance?: Maybe<Order_Dish_Ingredients_Variance_Fields>;
};


/** aggregate fields of "order_dish_ingredients" */
export type Order_Dish_Ingredients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Aggregate_Order_By = {
  avg?: Maybe<Order_Dish_Ingredients_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Dish_Ingredients_Max_Order_By>;
  min?: Maybe<Order_Dish_Ingredients_Min_Order_By>;
  stddev?: Maybe<Order_Dish_Ingredients_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Dish_Ingredients_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Dish_Ingredients_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Dish_Ingredients_Sum_Order_By>;
  var_pop?: Maybe<Order_Dish_Ingredients_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Dish_Ingredients_Var_Samp_Order_By>;
  variance?: Maybe<Order_Dish_Ingredients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Arr_Rel_Insert_Input = {
  data: Array<Order_Dish_Ingredients_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_Dish_Ingredients_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Dish_Ingredients_Avg_Fields = {
  __typename?: 'order_dish_ingredients_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Avg_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_dish_ingredients". All fields are combined with a logical 'AND'. */
export type Order_Dish_Ingredients_Bool_Exp = {
  _and?: Maybe<Array<Order_Dish_Ingredients_Bool_Exp>>;
  _not?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
  _or?: Maybe<Array<Order_Dish_Ingredients_Bool_Exp>>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ingredient?: Maybe<Ingredients_Bool_Exp>;
  ingredient_id?: Maybe<Int_Comparison_Exp>;
  order_dish?: Maybe<Order_Dish_Bool_Exp>;
  order_dish_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_dish_ingredients" */
export enum Order_Dish_Ingredients_Constraint {
  /** unique or primary key constraint */
  OrderDishIngredientsPkey = 'order_dish_ingredients_pkey'
}

/** input type for incrementing numeric columns in table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Insert_Input = {
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  ingredient?: Maybe<Ingredients_Obj_Rel_Insert_Input>;
  ingredient_id?: Maybe<Scalars['Int']>;
  order_dish?: Maybe<Order_Dish_Obj_Rel_Insert_Input>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Order_Dish_Ingredients_Max_Fields = {
  __typename?: 'order_dish_ingredients_max_fields';
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Max_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Dish_Ingredients_Min_Fields = {
  __typename?: 'order_dish_ingredients_min_fields';
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Min_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Mutation_Response = {
  __typename?: 'order_dish_ingredients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Dish_Ingredients>;
};

/** on_conflict condition type for table "order_dish_ingredients" */
export type Order_Dish_Ingredients_On_Conflict = {
  constraint: Order_Dish_Ingredients_Constraint;
  update_columns?: Array<Order_Dish_Ingredients_Update_Column>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};

/** Ordering options when selecting data from "order_dish_ingredients". */
export type Order_Dish_Ingredients_Order_By = {
  enabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredient?: Maybe<Ingredients_Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish?: Maybe<Order_Dish_Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** primary key columns input for table: order_dish_ingredients */
export type Order_Dish_Ingredients_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_dish_ingredients" */
export enum Order_Dish_Ingredients_Select_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  IngredientId = 'ingredient_id',
  /** column name */
  OrderDishId = 'order_dish_id'
}

/** input type for updating data in table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Set_Input = {
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Order_Dish_Ingredients_Stddev_Fields = {
  __typename?: 'order_dish_ingredients_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Dish_Ingredients_Stddev_Pop_Fields = {
  __typename?: 'order_dish_ingredients_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Dish_Ingredients_Stddev_Samp_Fields = {
  __typename?: 'order_dish_ingredients_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Dish_Ingredients_Sum_Fields = {
  __typename?: 'order_dish_ingredients_sum_fields';
  id?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Sum_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** update columns of table "order_dish_ingredients" */
export enum Order_Dish_Ingredients_Update_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  IngredientId = 'ingredient_id',
  /** column name */
  OrderDishId = 'order_dish_id'
}

/** aggregate var_pop on columns */
export type Order_Dish_Ingredients_Var_Pop_Fields = {
  __typename?: 'order_dish_ingredients_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Dish_Ingredients_Var_Samp_Fields = {
  __typename?: 'order_dish_ingredients_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Dish_Ingredients_Variance_Fields = {
  __typename?: 'order_dish_ingredients_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ingredient_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_dish_ingredients" */
export type Order_Dish_Ingredients_Variance_Order_By = {
  id?: Maybe<Order_By>;
  ingredient_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** input type for inserting data into table "order_dish" */
export type Order_Dish_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  dish?: Maybe<Dishes_Obj_Rel_Insert_Input>;
  dish_cost?: Maybe<Scalars['numeric']>;
  dish_id?: Maybe<Scalars['Int']>;
  dish_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  order_dish_ingredients?: Maybe<Order_Dish_Ingredients_Arr_Rel_Insert_Input>;
  order_dish_modifier_groups?: Maybe<Order_Dish_Modifier_Groups_Arr_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Dish_Max_Fields = {
  __typename?: 'order_dish_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dish_cost?: Maybe<Scalars['numeric']>;
  dish_id?: Maybe<Scalars['Int']>;
  dish_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_dish" */
export type Order_Dish_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Dish_Min_Fields = {
  __typename?: 'order_dish_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dish_cost?: Maybe<Scalars['numeric']>;
  dish_id?: Maybe<Scalars['Int']>;
  dish_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_dish" */
export type Order_Dish_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups = {
  __typename?: 'order_dish_modifier_groups';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  modifier_group: Modifier_Groups;
  modifier_group_id: Scalars['Int'];
  /** An object relationship */
  order_dish: Order_Dish;
  order_dish_id: Scalars['Int'];
  /** An array relationship */
  order_dish_modifiers: Array<Order_Dish_Modifiers>;
  /** An aggregate relationship */
  order_dish_modifiers_aggregate: Order_Dish_Modifiers_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "order_dish_modifier_groups" */
export type Order_Dish_Modifier_GroupsOrder_Dish_ModifiersArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};


/** columns and relationships of "order_dish_modifier_groups" */
export type Order_Dish_Modifier_GroupsOrder_Dish_Modifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};

/** aggregated selection of "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Aggregate = {
  __typename?: 'order_dish_modifier_groups_aggregate';
  aggregate?: Maybe<Order_Dish_Modifier_Groups_Aggregate_Fields>;
  nodes: Array<Order_Dish_Modifier_Groups>;
};

/** aggregate fields of "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Aggregate_Fields = {
  __typename?: 'order_dish_modifier_groups_aggregate_fields';
  avg?: Maybe<Order_Dish_Modifier_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Dish_Modifier_Groups_Max_Fields>;
  min?: Maybe<Order_Dish_Modifier_Groups_Min_Fields>;
  stddev?: Maybe<Order_Dish_Modifier_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Dish_Modifier_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Dish_Modifier_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Dish_Modifier_Groups_Sum_Fields>;
  var_pop?: Maybe<Order_Dish_Modifier_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Dish_Modifier_Groups_Var_Samp_Fields>;
  variance?: Maybe<Order_Dish_Modifier_Groups_Variance_Fields>;
};


/** aggregate fields of "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Aggregate_Order_By = {
  avg?: Maybe<Order_Dish_Modifier_Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Dish_Modifier_Groups_Max_Order_By>;
  min?: Maybe<Order_Dish_Modifier_Groups_Min_Order_By>;
  stddev?: Maybe<Order_Dish_Modifier_Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Dish_Modifier_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Dish_Modifier_Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Dish_Modifier_Groups_Sum_Order_By>;
  var_pop?: Maybe<Order_Dish_Modifier_Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Dish_Modifier_Groups_Var_Samp_Order_By>;
  variance?: Maybe<Order_Dish_Modifier_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Arr_Rel_Insert_Input = {
  data: Array<Order_Dish_Modifier_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_Dish_Modifier_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Dish_Modifier_Groups_Avg_Fields = {
  __typename?: 'order_dish_modifier_groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Avg_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_dish_modifier_groups". All fields are combined with a logical 'AND'. */
export type Order_Dish_Modifier_Groups_Bool_Exp = {
  _and?: Maybe<Array<Order_Dish_Modifier_Groups_Bool_Exp>>;
  _not?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
  _or?: Maybe<Array<Order_Dish_Modifier_Groups_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  modifier_group?: Maybe<Modifier_Groups_Bool_Exp>;
  modifier_group_id?: Maybe<Int_Comparison_Exp>;
  order_dish?: Maybe<Order_Dish_Bool_Exp>;
  order_dish_id?: Maybe<Int_Comparison_Exp>;
  order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_dish_modifier_groups" */
export enum Order_Dish_Modifier_Groups_Constraint {
  /** unique or primary key constraint */
  OrderDishModifierGroupsPkey = 'order_dish_modifier_groups_pkey'
}

/** input type for incrementing numeric columns in table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group?: Maybe<Modifier_Groups_Obj_Rel_Insert_Input>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish?: Maybe<Order_Dish_Obj_Rel_Insert_Input>;
  order_dish_id?: Maybe<Scalars['Int']>;
  order_dish_modifiers?: Maybe<Order_Dish_Modifiers_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Dish_Modifier_Groups_Max_Fields = {
  __typename?: 'order_dish_modifier_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Dish_Modifier_Groups_Min_Fields = {
  __typename?: 'order_dish_modifier_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Mutation_Response = {
  __typename?: 'order_dish_modifier_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Dish_Modifier_Groups>;
};

/** input type for inserting object relation for remote table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Obj_Rel_Insert_Input = {
  data: Order_Dish_Modifier_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_Dish_Modifier_Groups_On_Conflict>;
};

/** on_conflict condition type for table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_On_Conflict = {
  constraint: Order_Dish_Modifier_Groups_Constraint;
  update_columns?: Array<Order_Dish_Modifier_Groups_Update_Column>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "order_dish_modifier_groups". */
export type Order_Dish_Modifier_Groups_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_group?: Maybe<Modifier_Groups_Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish?: Maybe<Order_Dish_Order_By>;
  order_dish_id?: Maybe<Order_By>;
  order_dish_modifiers_aggregate?: Maybe<Order_Dish_Modifiers_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_dish_modifier_groups */
export type Order_Dish_Modifier_Groups_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_dish_modifier_groups" */
export enum Order_Dish_Modifier_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierGroupId = 'modifier_group_id',
  /** column name */
  OrderDishId = 'order_dish_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Dish_Modifier_Groups_Stddev_Fields = {
  __typename?: 'order_dish_modifier_groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Dish_Modifier_Groups_Stddev_Pop_Fields = {
  __typename?: 'order_dish_modifier_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Dish_Modifier_Groups_Stddev_Samp_Fields = {
  __typename?: 'order_dish_modifier_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Dish_Modifier_Groups_Sum_Fields = {
  __typename?: 'order_dish_modifier_groups_sum_fields';
  id?: Maybe<Scalars['Int']>;
  modifier_group_id?: Maybe<Scalars['Int']>;
  order_dish_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Sum_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** update columns of table "order_dish_modifier_groups" */
export enum Order_Dish_Modifier_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierGroupId = 'modifier_group_id',
  /** column name */
  OrderDishId = 'order_dish_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Dish_Modifier_Groups_Var_Pop_Fields = {
  __typename?: 'order_dish_modifier_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Dish_Modifier_Groups_Var_Samp_Fields = {
  __typename?: 'order_dish_modifier_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Dish_Modifier_Groups_Variance_Fields = {
  __typename?: 'order_dish_modifier_groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_group_id?: Maybe<Scalars['Float']>;
  order_dish_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_dish_modifier_groups" */
export type Order_Dish_Modifier_Groups_Variance_Order_By = {
  id?: Maybe<Order_By>;
  modifier_group_id?: Maybe<Order_By>;
  order_dish_id?: Maybe<Order_By>;
};

/** columns and relationships of "order_dish_modifiers" */
export type Order_Dish_Modifiers = {
  __typename?: 'order_dish_modifiers';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  modifier: Modifiers;
  modifier_id: Scalars['Int'];
  /** An object relationship */
  order_dish_modifier_group: Order_Dish_Modifier_Groups;
  order_dish_modifier_group_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "order_dish_modifiers" */
export type Order_Dish_Modifiers_Aggregate = {
  __typename?: 'order_dish_modifiers_aggregate';
  aggregate?: Maybe<Order_Dish_Modifiers_Aggregate_Fields>;
  nodes: Array<Order_Dish_Modifiers>;
};

/** aggregate fields of "order_dish_modifiers" */
export type Order_Dish_Modifiers_Aggregate_Fields = {
  __typename?: 'order_dish_modifiers_aggregate_fields';
  avg?: Maybe<Order_Dish_Modifiers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Dish_Modifiers_Max_Fields>;
  min?: Maybe<Order_Dish_Modifiers_Min_Fields>;
  stddev?: Maybe<Order_Dish_Modifiers_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Dish_Modifiers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Dish_Modifiers_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Dish_Modifiers_Sum_Fields>;
  var_pop?: Maybe<Order_Dish_Modifiers_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Dish_Modifiers_Var_Samp_Fields>;
  variance?: Maybe<Order_Dish_Modifiers_Variance_Fields>;
};


/** aggregate fields of "order_dish_modifiers" */
export type Order_Dish_Modifiers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Aggregate_Order_By = {
  avg?: Maybe<Order_Dish_Modifiers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Dish_Modifiers_Max_Order_By>;
  min?: Maybe<Order_Dish_Modifiers_Min_Order_By>;
  stddev?: Maybe<Order_Dish_Modifiers_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Dish_Modifiers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Dish_Modifiers_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Dish_Modifiers_Sum_Order_By>;
  var_pop?: Maybe<Order_Dish_Modifiers_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Dish_Modifiers_Var_Samp_Order_By>;
  variance?: Maybe<Order_Dish_Modifiers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Arr_Rel_Insert_Input = {
  data: Array<Order_Dish_Modifiers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_Dish_Modifiers_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Dish_Modifiers_Avg_Fields = {
  __typename?: 'order_dish_modifiers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Avg_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_dish_modifiers". All fields are combined with a logical 'AND'. */
export type Order_Dish_Modifiers_Bool_Exp = {
  _and?: Maybe<Array<Order_Dish_Modifiers_Bool_Exp>>;
  _not?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
  _or?: Maybe<Array<Order_Dish_Modifiers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  modifier?: Maybe<Modifiers_Bool_Exp>;
  modifier_id?: Maybe<Int_Comparison_Exp>;
  order_dish_modifier_group?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
  order_dish_modifier_group_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_dish_modifiers" */
export enum Order_Dish_Modifiers_Constraint {
  /** unique or primary key constraint */
  OrderDishModifiersPkey = 'order_dish_modifiers_pkey'
}

/** input type for incrementing numeric columns in table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  modifier_id?: Maybe<Scalars['Int']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier?: Maybe<Modifiers_Obj_Rel_Insert_Input>;
  modifier_id?: Maybe<Scalars['Int']>;
  order_dish_modifier_group?: Maybe<Order_Dish_Modifier_Groups_Obj_Rel_Insert_Input>;
  order_dish_modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Dish_Modifiers_Max_Fields = {
  __typename?: 'order_dish_modifiers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_id?: Maybe<Scalars['Int']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Dish_Modifiers_Min_Fields = {
  __typename?: 'order_dish_modifiers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_id?: Maybe<Scalars['Int']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Mutation_Response = {
  __typename?: 'order_dish_modifiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Dish_Modifiers>;
};

/** on_conflict condition type for table "order_dish_modifiers" */
export type Order_Dish_Modifiers_On_Conflict = {
  constraint: Order_Dish_Modifiers_Constraint;
  update_columns?: Array<Order_Dish_Modifiers_Update_Column>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};

/** Ordering options when selecting data from "order_dish_modifiers". */
export type Order_Dish_Modifiers_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modifier?: Maybe<Modifiers_Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group?: Maybe<Order_Dish_Modifier_Groups_Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_dish_modifiers */
export type Order_Dish_Modifiers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_dish_modifiers" */
export enum Order_Dish_Modifiers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierId = 'modifier_id',
  /** column name */
  OrderDishModifierGroupId = 'order_dish_modifier_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  modifier_id?: Maybe<Scalars['Int']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Dish_Modifiers_Stddev_Fields = {
  __typename?: 'order_dish_modifiers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Dish_Modifiers_Stddev_Pop_Fields = {
  __typename?: 'order_dish_modifiers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Dish_Modifiers_Stddev_Samp_Fields = {
  __typename?: 'order_dish_modifiers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Dish_Modifiers_Sum_Fields = {
  __typename?: 'order_dish_modifiers_sum_fields';
  id?: Maybe<Scalars['Int']>;
  modifier_id?: Maybe<Scalars['Int']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Sum_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** update columns of table "order_dish_modifiers" */
export enum Order_Dish_Modifiers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifierId = 'modifier_id',
  /** column name */
  OrderDishModifierGroupId = 'order_dish_modifier_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Dish_Modifiers_Var_Pop_Fields = {
  __typename?: 'order_dish_modifiers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Dish_Modifiers_Var_Samp_Fields = {
  __typename?: 'order_dish_modifiers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Dish_Modifiers_Variance_Fields = {
  __typename?: 'order_dish_modifiers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  modifier_id?: Maybe<Scalars['Float']>;
  order_dish_modifier_group_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_dish_modifiers" */
export type Order_Dish_Modifiers_Variance_Order_By = {
  id?: Maybe<Order_By>;
  modifier_id?: Maybe<Order_By>;
  order_dish_modifier_group_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_dish" */
export type Order_Dish_Mutation_Response = {
  __typename?: 'order_dish_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Dish>;
};

/** input type for inserting object relation for remote table "order_dish" */
export type Order_Dish_Obj_Rel_Insert_Input = {
  data: Order_Dish_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_Dish_On_Conflict>;
};

/** on_conflict condition type for table "order_dish" */
export type Order_Dish_On_Conflict = {
  constraint: Order_Dish_Constraint;
  update_columns?: Array<Order_Dish_Update_Column>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};

/** Ordering options when selecting data from "order_dish". */
export type Order_Dish_Order_By = {
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  dish?: Maybe<Dishes_Order_By>;
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_dish_ingredients_aggregate?: Maybe<Order_Dish_Ingredients_Aggregate_Order_By>;
  order_dish_modifier_groups_aggregate?: Maybe<Order_Dish_Modifier_Groups_Aggregate_Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order_dish */
export type Order_Dish_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_dish" */
export enum Order_Dish_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DishCost = 'dish_cost',
  /** column name */
  DishId = 'dish_id',
  /** column name */
  DishPrice = 'dish_price',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_dish" */
export type Order_Dish_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  dish_cost?: Maybe<Scalars['numeric']>;
  dish_id?: Maybe<Scalars['Int']>;
  dish_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Dish_Stddev_Fields = {
  __typename?: 'order_dish_stddev_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_dish" */
export type Order_Dish_Stddev_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Dish_Stddev_Pop_Fields = {
  __typename?: 'order_dish_stddev_pop_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_dish" */
export type Order_Dish_Stddev_Pop_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Dish_Stddev_Samp_Fields = {
  __typename?: 'order_dish_stddev_samp_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_dish" */
export type Order_Dish_Stddev_Samp_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Dish_Sum_Fields = {
  __typename?: 'order_dish_sum_fields';
  dish_cost?: Maybe<Scalars['numeric']>;
  dish_id?: Maybe<Scalars['Int']>;
  dish_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_dish" */
export type Order_Dish_Sum_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "order_dish" */
export enum Order_Dish_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DishCost = 'dish_cost',
  /** column name */
  DishId = 'dish_id',
  /** column name */
  DishPrice = 'dish_price',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Dish_Var_Pop_Fields = {
  __typename?: 'order_dish_var_pop_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_dish" */
export type Order_Dish_Var_Pop_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Dish_Var_Samp_Fields = {
  __typename?: 'order_dish_var_samp_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_dish" */
export type Order_Dish_Var_Samp_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Dish_Variance_Fields = {
  __typename?: 'order_dish_variance_fields';
  dish_cost?: Maybe<Scalars['Float']>;
  dish_id?: Maybe<Scalars['Float']>;
  dish_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_dish" */
export type Order_Dish_Variance_Order_By = {
  dish_cost?: Maybe<Order_By>;
  dish_id?: Maybe<Order_By>;
  dish_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** columns and relationships of "orders" */
export type Orders = {
  __typename?: 'orders';
  /** An object relationship */
  account_ticket?: Maybe<Account_Tickets>;
  account_ticket_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  order_dishes: Array<Order_Dish>;
  /** An aggregate relationship */
  order_dishes_aggregate: Order_Dish_Aggregate;
  /** An array relationship */
  orders_actions: Array<Orders_Actions>;
  /** An aggregate relationship */
  orders_actions_aggregate: Orders_Actions_Aggregate;
  /** An object relationship */
  statement_account: Statement_Account;
  statement_account_id: Scalars['Int'];
  status: Scalars['String'];
  /** An object relationship */
  table: Tables;
  table_id: Scalars['Int'];
  total: Scalars['numeric'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "orders" */
export type OrdersOrder_DishesArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersOrder_Dishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersOrders_ActionsArgs = {
  distinct_on?: Maybe<Array<Orders_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Actions_Order_By>>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersOrders_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Actions_Order_By>>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};

/** columns and relationships of "orders_actions" */
export type Orders_Actions = {
  __typename?: 'orders_actions';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['Int'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "orders_actions" */
export type Orders_Actions_Aggregate = {
  __typename?: 'orders_actions_aggregate';
  aggregate?: Maybe<Orders_Actions_Aggregate_Fields>;
  nodes: Array<Orders_Actions>;
};

/** aggregate fields of "orders_actions" */
export type Orders_Actions_Aggregate_Fields = {
  __typename?: 'orders_actions_aggregate_fields';
  avg?: Maybe<Orders_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_Actions_Max_Fields>;
  min?: Maybe<Orders_Actions_Min_Fields>;
  stddev?: Maybe<Orders_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Actions_Sum_Fields>;
  var_pop?: Maybe<Orders_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Actions_Var_Samp_Fields>;
  variance?: Maybe<Orders_Actions_Variance_Fields>;
};


/** aggregate fields of "orders_actions" */
export type Orders_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders_actions" */
export type Orders_Actions_Aggregate_Order_By = {
  avg?: Maybe<Orders_Actions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Actions_Max_Order_By>;
  min?: Maybe<Orders_Actions_Min_Order_By>;
  stddev?: Maybe<Orders_Actions_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Actions_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Actions_Sum_Order_By>;
  var_pop?: Maybe<Orders_Actions_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Actions_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Actions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orders_actions" */
export type Orders_Actions_Arr_Rel_Insert_Input = {
  data: Array<Orders_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Orders_Actions_On_Conflict>;
};

/** aggregate avg on columns */
export type Orders_Actions_Avg_Fields = {
  __typename?: 'orders_actions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders_actions" */
export type Orders_Actions_Avg_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders_actions". All fields are combined with a logical 'AND'. */
export type Orders_Actions_Bool_Exp = {
  _and?: Maybe<Array<Orders_Actions_Bool_Exp>>;
  _not?: Maybe<Orders_Actions_Bool_Exp>;
  _or?: Maybe<Array<Orders_Actions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders_actions" */
export enum Orders_Actions_Constraint {
  /** unique or primary key constraint */
  OrdersActionsPkey = 'orders_actions_pkey'
}

/** input type for incrementing numeric columns in table "orders_actions" */
export type Orders_Actions_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "orders_actions" */
export type Orders_Actions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Orders_Actions_Max_Fields = {
  __typename?: 'orders_actions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "orders_actions" */
export type Orders_Actions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Actions_Min_Fields = {
  __typename?: 'orders_actions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "orders_actions" */
export type Orders_Actions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "orders_actions" */
export type Orders_Actions_Mutation_Response = {
  __typename?: 'orders_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders_Actions>;
};

/** on_conflict condition type for table "orders_actions" */
export type Orders_Actions_On_Conflict = {
  constraint: Orders_Actions_Constraint;
  update_columns?: Array<Orders_Actions_Update_Column>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "orders_actions". */
export type Orders_Actions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: orders_actions */
export type Orders_Actions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "orders_actions" */
export enum Orders_Actions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "orders_actions" */
export type Orders_Actions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Orders_Actions_Stddev_Fields = {
  __typename?: 'orders_actions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders_actions" */
export type Orders_Actions_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Orders_Actions_Stddev_Pop_Fields = {
  __typename?: 'orders_actions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders_actions" */
export type Orders_Actions_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Orders_Actions_Stddev_Samp_Fields = {
  __typename?: 'orders_actions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders_actions" */
export type Orders_Actions_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Orders_Actions_Sum_Fields = {
  __typename?: 'orders_actions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "orders_actions" */
export type Orders_Actions_Sum_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** update columns of table "orders_actions" */
export enum Orders_Actions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Orders_Actions_Var_Pop_Fields = {
  __typename?: 'orders_actions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders_actions" */
export type Orders_Actions_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Orders_Actions_Var_Samp_Fields = {
  __typename?: 'orders_actions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders_actions" */
export type Orders_Actions_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Orders_Actions_Variance_Fields = {
  __typename?: 'orders_actions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders_actions" */
export type Orders_Actions_Variance_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  __typename?: 'orders_aggregate';
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  __typename?: 'orders_aggregate_fields';
  avg?: Maybe<Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
  stddev?: Maybe<Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Sum_Fields>;
  var_pop?: Maybe<Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Var_Samp_Fields>;
  variance?: Maybe<Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  avg?: Maybe<Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Max_Order_By>;
  min?: Maybe<Orders_Min_Order_By>;
  stddev?: Maybe<Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Sum_Order_By>;
  var_pop?: Maybe<Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  data: Array<Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Orders_Avg_Fields = {
  __typename?: 'orders_avg_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders" */
export type Orders_Avg_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Orders_Bool_Exp>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Orders_Bool_Exp>>;
  account_ticket?: Maybe<Account_Tickets_Bool_Exp>;
  account_ticket_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order_dishes?: Maybe<Order_Dish_Bool_Exp>;
  orders_actions?: Maybe<Orders_Actions_Bool_Exp>;
  statement_account?: Maybe<Statement_Account_Bool_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  table?: Maybe<Tables_Bool_Exp>;
  table_id?: Maybe<Int_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint */
  OrdersPkey = 'orders_pkey'
}

/** input type for incrementing numeric columns in table "orders" */
export type Orders_Inc_Input = {
  account_ticket_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  account_ticket?: Maybe<Account_Tickets_Obj_Rel_Insert_Input>;
  account_ticket_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_dishes?: Maybe<Order_Dish_Arr_Rel_Insert_Input>;
  orders_actions?: Maybe<Orders_Actions_Arr_Rel_Insert_Input>;
  statement_account?: Maybe<Statement_Account_Obj_Rel_Insert_Input>;
  statement_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  table?: Maybe<Tables_Obj_Rel_Insert_Input>;
  table_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  __typename?: 'orders_max_fields';
  account_ticket_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  table_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  __typename?: 'orders_min_fields';
  account_ticket_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  table_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  __typename?: 'orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  data: Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns?: Array<Orders_Update_Column>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  account_ticket?: Maybe<Account_Tickets_Order_By>;
  account_ticket_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_dishes_aggregate?: Maybe<Order_Dish_Aggregate_Order_By>;
  orders_actions_aggregate?: Maybe<Orders_Actions_Aggregate_Order_By>;
  statement_account?: Maybe<Statement_Account_Order_By>;
  statement_account_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  table?: Maybe<Tables_Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  AccountTicketId = 'account_ticket_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  Status = 'status',
  /** column name */
  TableId = 'table_id',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  account_ticket_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  table_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Orders_Stddev_Fields = {
  __typename?: 'orders_stddev_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders" */
export type Orders_Stddev_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Orders_Stddev_Pop_Fields = {
  __typename?: 'orders_stddev_pop_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Orders_Stddev_Pop_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Orders_Stddev_Samp_Fields = {
  __typename?: 'orders_stddev_samp_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Orders_Stddev_Samp_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Orders_Sum_Fields = {
  __typename?: 'orders_sum_fields';
  account_ticket_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  table_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "orders" */
export type Orders_Sum_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  AccountTicketId = 'account_ticket_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  Status = 'status',
  /** column name */
  TableId = 'table_id',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Orders_Var_Pop_Fields = {
  __typename?: 'orders_var_pop_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders" */
export type Orders_Var_Pop_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Orders_Var_Samp_Fields = {
  __typename?: 'orders_var_samp_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders" */
export type Orders_Var_Samp_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Orders_Variance_Fields = {
  __typename?: 'orders_variance_fields';
  account_ticket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  table_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders" */
export type Orders_Variance_Order_By = {
  account_ticket_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  table_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** columns and relationships of "palapa_account" */
export type Palapa_Account = {
  __typename?: 'palapa_account';
  active?: Maybe<Scalars['Boolean']>;
  balance: Scalars['numeric'];
  id: Scalars['Int'];
  palapa_user_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "palapa_account" */
export type Palapa_Account_Aggregate = {
  __typename?: 'palapa_account_aggregate';
  aggregate?: Maybe<Palapa_Account_Aggregate_Fields>;
  nodes: Array<Palapa_Account>;
};

/** aggregate fields of "palapa_account" */
export type Palapa_Account_Aggregate_Fields = {
  __typename?: 'palapa_account_aggregate_fields';
  avg?: Maybe<Palapa_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Palapa_Account_Max_Fields>;
  min?: Maybe<Palapa_Account_Min_Fields>;
  stddev?: Maybe<Palapa_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Palapa_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Palapa_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Palapa_Account_Sum_Fields>;
  var_pop?: Maybe<Palapa_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Palapa_Account_Var_Samp_Fields>;
  variance?: Maybe<Palapa_Account_Variance_Fields>;
};


/** aggregate fields of "palapa_account" */
export type Palapa_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Palapa_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Palapa_Account_Avg_Fields = {
  __typename?: 'palapa_account_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "palapa_account". All fields are combined with a logical 'AND'. */
export type Palapa_Account_Bool_Exp = {
  _and?: Maybe<Array<Palapa_Account_Bool_Exp>>;
  _not?: Maybe<Palapa_Account_Bool_Exp>;
  _or?: Maybe<Array<Palapa_Account_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  balance?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  palapa_user_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "palapa_account" */
export enum Palapa_Account_Constraint {
  /** unique or primary key constraint */
  PalapaAccountPkey = 'palapa_account_pkey'
}

/** input type for incrementing numeric columns in table "palapa_account" */
export type Palapa_Account_Inc_Input = {
  balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  palapa_user_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "palapa_account" */
export type Palapa_Account_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  palapa_user_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Palapa_Account_Max_Fields = {
  __typename?: 'palapa_account_max_fields';
  balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  palapa_user_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Palapa_Account_Min_Fields = {
  __typename?: 'palapa_account_min_fields';
  balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  palapa_user_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "palapa_account" */
export type Palapa_Account_Mutation_Response = {
  __typename?: 'palapa_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Palapa_Account>;
};

/** on_conflict condition type for table "palapa_account" */
export type Palapa_Account_On_Conflict = {
  constraint: Palapa_Account_Constraint;
  update_columns?: Array<Palapa_Account_Update_Column>;
  where?: Maybe<Palapa_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "palapa_account". */
export type Palapa_Account_Order_By = {
  active?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  palapa_user_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: palapa_account */
export type Palapa_Account_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "palapa_account" */
export enum Palapa_Account_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Balance = 'balance',
  /** column name */
  Id = 'id',
  /** column name */
  PalapaUserId = 'palapa_user_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "palapa_account" */
export type Palapa_Account_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  palapa_user_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Palapa_Account_Stddev_Fields = {
  __typename?: 'palapa_account_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Palapa_Account_Stddev_Pop_Fields = {
  __typename?: 'palapa_account_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Palapa_Account_Stddev_Samp_Fields = {
  __typename?: 'palapa_account_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Palapa_Account_Sum_Fields = {
  __typename?: 'palapa_account_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  palapa_user_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "palapa_account" */
export enum Palapa_Account_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Balance = 'balance',
  /** column name */
  Id = 'id',
  /** column name */
  PalapaUserId = 'palapa_user_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Palapa_Account_Var_Pop_Fields = {
  __typename?: 'palapa_account_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Palapa_Account_Var_Samp_Fields = {
  __typename?: 'palapa_account_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Palapa_Account_Variance_Fields = {
  __typename?: 'palapa_account_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  palapa_user_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "palapa_accounts" */
export type Palapa_Accounts = {
  __typename?: 'palapa_accounts';
  apartment_building?: Maybe<Scalars['String']>;
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  apartment_number?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  commensal_name?: Maybe<Scalars['String']>;
  commensal_type?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  pin_created?: Maybe<Scalars['Boolean']>;
  present?: Maybe<Scalars['Boolean']>;
  rental_id?: Maybe<Scalars['Int']>;
  statement_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  tickets: Array<Account_Tickets>;
  /** An aggregate relationship */
  tickets_aggregate: Account_Tickets_Aggregate;
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "palapa_accounts" */
export type Palapa_AccountsOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "palapa_accounts" */
export type Palapa_AccountsOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "palapa_accounts" */
export type Palapa_AccountsTicketsArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


/** columns and relationships of "palapa_accounts" */
export type Palapa_AccountsTickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};

/** aggregated selection of "palapa_accounts" */
export type Palapa_Accounts_Aggregate = {
  __typename?: 'palapa_accounts_aggregate';
  aggregate?: Maybe<Palapa_Accounts_Aggregate_Fields>;
  nodes: Array<Palapa_Accounts>;
};

/** aggregate fields of "palapa_accounts" */
export type Palapa_Accounts_Aggregate_Fields = {
  __typename?: 'palapa_accounts_aggregate_fields';
  avg?: Maybe<Palapa_Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Palapa_Accounts_Max_Fields>;
  min?: Maybe<Palapa_Accounts_Min_Fields>;
  stddev?: Maybe<Palapa_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Palapa_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Palapa_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Palapa_Accounts_Sum_Fields>;
  var_pop?: Maybe<Palapa_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Palapa_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Palapa_Accounts_Variance_Fields>;
};


/** aggregate fields of "palapa_accounts" */
export type Palapa_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Palapa_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Palapa_Accounts_Avg_Fields = {
  __typename?: 'palapa_accounts_avg_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "palapa_accounts". All fields are combined with a logical 'AND'. */
export type Palapa_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Palapa_Accounts_Bool_Exp>>;
  _not?: Maybe<Palapa_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Palapa_Accounts_Bool_Exp>>;
  apartment_building?: Maybe<String_Comparison_Exp>;
  apartment_id?: Maybe<Int_Comparison_Exp>;
  apartment_name?: Maybe<String_Comparison_Exp>;
  apartment_number?: Maybe<Int_Comparison_Exp>;
  balance?: Maybe<Numeric_Comparison_Exp>;
  balance_retained?: Maybe<Numeric_Comparison_Exp>;
  commensal_name?: Maybe<String_Comparison_Exp>;
  commensal_type?: Maybe<String_Comparison_Exp>;
  open?: Maybe<Boolean_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  pin_created?: Maybe<Boolean_Comparison_Exp>;
  present?: Maybe<Boolean_Comparison_Exp>;
  rental_id?: Maybe<Int_Comparison_Exp>;
  statement_id?: Maybe<Int_Comparison_Exp>;
  tickets?: Maybe<Account_Tickets_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Palapa_Accounts_Max_Fields = {
  __typename?: 'palapa_accounts_max_fields';
  apartment_building?: Maybe<Scalars['String']>;
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  apartment_number?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  commensal_name?: Maybe<Scalars['String']>;
  commensal_type?: Maybe<Scalars['String']>;
  rental_id?: Maybe<Scalars['Int']>;
  statement_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Palapa_Accounts_Min_Fields = {
  __typename?: 'palapa_accounts_min_fields';
  apartment_building?: Maybe<Scalars['String']>;
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  apartment_number?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  commensal_name?: Maybe<Scalars['String']>;
  commensal_type?: Maybe<Scalars['String']>;
  rental_id?: Maybe<Scalars['Int']>;
  statement_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "palapa_accounts". */
export type Palapa_Accounts_Order_By = {
  apartment_building?: Maybe<Order_By>;
  apartment_id?: Maybe<Order_By>;
  apartment_name?: Maybe<Order_By>;
  apartment_number?: Maybe<Order_By>;
  balance?: Maybe<Order_By>;
  balance_retained?: Maybe<Order_By>;
  commensal_name?: Maybe<Order_By>;
  commensal_type?: Maybe<Order_By>;
  open?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  pin_created?: Maybe<Order_By>;
  present?: Maybe<Order_By>;
  rental_id?: Maybe<Order_By>;
  statement_id?: Maybe<Order_By>;
  tickets_aggregate?: Maybe<Account_Tickets_Aggregate_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "palapa_accounts" */
export enum Palapa_Accounts_Select_Column {
  /** column name */
  ApartmentBuilding = 'apartment_building',
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  ApartmentName = 'apartment_name',
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  Balance = 'balance',
  /** column name */
  BalanceRetained = 'balance_retained',
  /** column name */
  CommensalName = 'commensal_name',
  /** column name */
  CommensalType = 'commensal_type',
  /** column name */
  Open = 'open',
  /** column name */
  PinCreated = 'pin_created',
  /** column name */
  Present = 'present',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  StatementId = 'statement_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Palapa_Accounts_Stddev_Fields = {
  __typename?: 'palapa_accounts_stddev_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Palapa_Accounts_Stddev_Pop_Fields = {
  __typename?: 'palapa_accounts_stddev_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Palapa_Accounts_Stddev_Samp_Fields = {
  __typename?: 'palapa_accounts_stddev_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Palapa_Accounts_Sum_Fields = {
  __typename?: 'palapa_accounts_sum_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_number?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['numeric']>;
  balance_retained?: Maybe<Scalars['numeric']>;
  rental_id?: Maybe<Scalars['Int']>;
  statement_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Palapa_Accounts_Var_Pop_Fields = {
  __typename?: 'palapa_accounts_var_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Palapa_Accounts_Var_Samp_Fields = {
  __typename?: 'palapa_accounts_var_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Palapa_Accounts_Variance_Fields = {
  __typename?: 'palapa_accounts_variance_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  apartment_number?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balance_retained?: Maybe<Scalars['Float']>;
  rental_id?: Maybe<Scalars['Float']>;
  statement_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "palapa_items" */
export type Palapa_Items = {
  __typename?: 'palapa_items';
  file: Scalars['String'];
  id: Scalars['Int'];
  img?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "palapa_items" */
export type Palapa_Items_Aggregate = {
  __typename?: 'palapa_items_aggregate';
  aggregate?: Maybe<Palapa_Items_Aggregate_Fields>;
  nodes: Array<Palapa_Items>;
};

/** aggregate fields of "palapa_items" */
export type Palapa_Items_Aggregate_Fields = {
  __typename?: 'palapa_items_aggregate_fields';
  avg?: Maybe<Palapa_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Palapa_Items_Max_Fields>;
  min?: Maybe<Palapa_Items_Min_Fields>;
  stddev?: Maybe<Palapa_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Palapa_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Palapa_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Palapa_Items_Sum_Fields>;
  var_pop?: Maybe<Palapa_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Palapa_Items_Var_Samp_Fields>;
  variance?: Maybe<Palapa_Items_Variance_Fields>;
};


/** aggregate fields of "palapa_items" */
export type Palapa_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Palapa_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Palapa_Items_Avg_Fields = {
  __typename?: 'palapa_items_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "palapa_items". All fields are combined with a logical 'AND'. */
export type Palapa_Items_Bool_Exp = {
  _and?: Maybe<Array<Palapa_Items_Bool_Exp>>;
  _not?: Maybe<Palapa_Items_Bool_Exp>;
  _or?: Maybe<Array<Palapa_Items_Bool_Exp>>;
  file?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  img?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "palapa_items" */
export enum Palapa_Items_Constraint {
  /** unique or primary key constraint */
  PalapaItemsPkey = 'palapa_items_pkey'
}

/** input type for incrementing numeric columns in table "palapa_items" */
export type Palapa_Items_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "palapa_items" */
export type Palapa_Items_Insert_Input = {
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Palapa_Items_Max_Fields = {
  __typename?: 'palapa_items_max_fields';
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Palapa_Items_Min_Fields = {
  __typename?: 'palapa_items_min_fields';
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "palapa_items" */
export type Palapa_Items_Mutation_Response = {
  __typename?: 'palapa_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Palapa_Items>;
};

/** on_conflict condition type for table "palapa_items" */
export type Palapa_Items_On_Conflict = {
  constraint: Palapa_Items_Constraint;
  update_columns?: Array<Palapa_Items_Update_Column>;
  where?: Maybe<Palapa_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "palapa_items". */
export type Palapa_Items_Order_By = {
  file?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: palapa_items */
export type Palapa_Items_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "palapa_items" */
export enum Palapa_Items_Select_Column {
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "palapa_items" */
export type Palapa_Items_Set_Input = {
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Palapa_Items_Stddev_Fields = {
  __typename?: 'palapa_items_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Palapa_Items_Stddev_Pop_Fields = {
  __typename?: 'palapa_items_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Palapa_Items_Stddev_Samp_Fields = {
  __typename?: 'palapa_items_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Palapa_Items_Sum_Fields = {
  __typename?: 'palapa_items_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "palapa_items" */
export enum Palapa_Items_Update_Column {
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Palapa_Items_Var_Pop_Fields = {
  __typename?: 'palapa_items_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Palapa_Items_Var_Samp_Fields = {
  __typename?: 'palapa_items_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Palapa_Items_Variance_Fields = {
  __typename?: 'palapa_items_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "phones" */
export type Phones = {
  __typename?: 'phones';
  /** An array relationship */
  contact_phones: Array<Contact_Phones>;
  /** An aggregate relationship */
  contact_phones_aggregate: Contact_Phones_Aggregate;
  id: Scalars['Int'];
  number: Scalars['String'];
};


/** columns and relationships of "phones" */
export type PhonesContact_PhonesArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


/** columns and relationships of "phones" */
export type PhonesContact_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};

/** aggregated selection of "phones" */
export type Phones_Aggregate = {
  __typename?: 'phones_aggregate';
  aggregate?: Maybe<Phones_Aggregate_Fields>;
  nodes: Array<Phones>;
};

/** aggregate fields of "phones" */
export type Phones_Aggregate_Fields = {
  __typename?: 'phones_aggregate_fields';
  avg?: Maybe<Phones_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Phones_Max_Fields>;
  min?: Maybe<Phones_Min_Fields>;
  stddev?: Maybe<Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Phones_Sum_Fields>;
  var_pop?: Maybe<Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Phones_Var_Samp_Fields>;
  variance?: Maybe<Phones_Variance_Fields>;
};


/** aggregate fields of "phones" */
export type Phones_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Phones_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Phones_Avg_Fields = {
  __typename?: 'phones_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "phones". All fields are combined with a logical 'AND'. */
export type Phones_Bool_Exp = {
  _and?: Maybe<Array<Phones_Bool_Exp>>;
  _not?: Maybe<Phones_Bool_Exp>;
  _or?: Maybe<Array<Phones_Bool_Exp>>;
  contact_phones?: Maybe<Contact_Phones_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  number?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "phones" */
export enum Phones_Constraint {
  /** unique or primary key constraint */
  PhonesNumberKey = 'phones_number_key',
  /** unique or primary key constraint */
  PhonesPkey = 'phones_pkey'
}

/** input type for incrementing numeric columns in table "phones" */
export type Phones_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "phones" */
export type Phones_Insert_Input = {
  contact_phones?: Maybe<Contact_Phones_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Phones_Max_Fields = {
  __typename?: 'phones_max_fields';
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Phones_Min_Fields = {
  __typename?: 'phones_min_fields';
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "phones" */
export type Phones_Mutation_Response = {
  __typename?: 'phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Phones>;
};

/** input type for inserting object relation for remote table "phones" */
export type Phones_Obj_Rel_Insert_Input = {
  data: Phones_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Phones_On_Conflict>;
};

/** on_conflict condition type for table "phones" */
export type Phones_On_Conflict = {
  constraint: Phones_Constraint;
  update_columns?: Array<Phones_Update_Column>;
  where?: Maybe<Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "phones". */
export type Phones_Order_By = {
  contact_phones_aggregate?: Maybe<Contact_Phones_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
};

/** primary key columns input for table: phones */
export type Phones_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "phones" */
export enum Phones_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number'
}

/** input type for updating data in table "phones" */
export type Phones_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Phones_Stddev_Fields = {
  __typename?: 'phones_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Phones_Stddev_Pop_Fields = {
  __typename?: 'phones_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Phones_Stddev_Samp_Fields = {
  __typename?: 'phones_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Phones_Sum_Fields = {
  __typename?: 'phones_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "phones" */
export enum Phones_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number'
}

/** aggregate var_pop on columns */
export type Phones_Var_Pop_Fields = {
  __typename?: 'phones_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Phones_Var_Samp_Fields = {
  __typename?: 'phones_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Phones_Variance_Fields = {
  __typename?: 'phones_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "print_templates" */
export type Print_Templates = {
  __typename?: 'print_templates';
  alias: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  printer?: Maybe<Printers>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "print_templates" */
export type Print_Templates_Aggregate = {
  __typename?: 'print_templates_aggregate';
  aggregate?: Maybe<Print_Templates_Aggregate_Fields>;
  nodes: Array<Print_Templates>;
};

/** aggregate fields of "print_templates" */
export type Print_Templates_Aggregate_Fields = {
  __typename?: 'print_templates_aggregate_fields';
  avg?: Maybe<Print_Templates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Templates_Max_Fields>;
  min?: Maybe<Print_Templates_Min_Fields>;
  stddev?: Maybe<Print_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Templates_Sum_Fields>;
  var_pop?: Maybe<Print_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Templates_Var_Samp_Fields>;
  variance?: Maybe<Print_Templates_Variance_Fields>;
};


/** aggregate fields of "print_templates" */
export type Print_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "print_templates" */
export type Print_Templates_Aggregate_Order_By = {
  avg?: Maybe<Print_Templates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Print_Templates_Max_Order_By>;
  min?: Maybe<Print_Templates_Min_Order_By>;
  stddev?: Maybe<Print_Templates_Stddev_Order_By>;
  stddev_pop?: Maybe<Print_Templates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Print_Templates_Stddev_Samp_Order_By>;
  sum?: Maybe<Print_Templates_Sum_Order_By>;
  var_pop?: Maybe<Print_Templates_Var_Pop_Order_By>;
  var_samp?: Maybe<Print_Templates_Var_Samp_Order_By>;
  variance?: Maybe<Print_Templates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "print_templates" */
export type Print_Templates_Arr_Rel_Insert_Input = {
  data: Array<Print_Templates_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Print_Templates_On_Conflict>;
};

/** aggregate avg on columns */
export type Print_Templates_Avg_Fields = {
  __typename?: 'print_templates_avg_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "print_templates" */
export type Print_Templates_Avg_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "print_templates". All fields are combined with a logical 'AND'. */
export type Print_Templates_Bool_Exp = {
  _and?: Maybe<Array<Print_Templates_Bool_Exp>>;
  _not?: Maybe<Print_Templates_Bool_Exp>;
  _or?: Maybe<Array<Print_Templates_Bool_Exp>>;
  alias?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  printer?: Maybe<Printers_Bool_Exp>;
  printer_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "print_templates" */
export enum Print_Templates_Constraint {
  /** unique or primary key constraint */
  PrintTemplatesPkey = 'print_templates_pkey'
}

/** input type for incrementing numeric columns in table "print_templates" */
export type Print_Templates_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "print_templates" */
export type Print_Templates_Insert_Input = {
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  printer?: Maybe<Printers_Obj_Rel_Insert_Input>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Print_Templates_Max_Fields = {
  __typename?: 'print_templates_max_fields';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "print_templates" */
export type Print_Templates_Max_Order_By = {
  alias?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Print_Templates_Min_Fields = {
  __typename?: 'print_templates_min_fields';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "print_templates" */
export type Print_Templates_Min_Order_By = {
  alias?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "print_templates" */
export type Print_Templates_Mutation_Response = {
  __typename?: 'print_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Print_Templates>;
};

/** on_conflict condition type for table "print_templates" */
export type Print_Templates_On_Conflict = {
  constraint: Print_Templates_Constraint;
  update_columns?: Array<Print_Templates_Update_Column>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "print_templates". */
export type Print_Templates_Order_By = {
  alias?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  printer?: Maybe<Printers_Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** primary key columns input for table: print_templates */
export type Print_Templates_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "print_templates" */
export enum Print_Templates_Select_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrinterId = 'printer_id'
}

/** input type for updating data in table "print_templates" */
export type Print_Templates_Set_Input = {
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Print_Templates_Stddev_Fields = {
  __typename?: 'print_templates_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "print_templates" */
export type Print_Templates_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Print_Templates_Stddev_Pop_Fields = {
  __typename?: 'print_templates_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "print_templates" */
export type Print_Templates_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Print_Templates_Stddev_Samp_Fields = {
  __typename?: 'print_templates_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "print_templates" */
export type Print_Templates_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Print_Templates_Sum_Fields = {
  __typename?: 'print_templates_sum_fields';
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "print_templates" */
export type Print_Templates_Sum_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** update columns of table "print_templates" */
export enum Print_Templates_Update_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrinterId = 'printer_id'
}

/** aggregate var_pop on columns */
export type Print_Templates_Var_Pop_Fields = {
  __typename?: 'print_templates_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "print_templates" */
export type Print_Templates_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Print_Templates_Var_Samp_Fields = {
  __typename?: 'print_templates_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "print_templates" */
export type Print_Templates_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Print_Templates_Variance_Fields = {
  __typename?: 'print_templates_variance_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "print_templates" */
export type Print_Templates_Variance_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** columns and relationships of "print_types" */
export type Print_Types = {
  __typename?: 'print_types';
  id: Scalars['Int'];
  /** An object relationship */
  printer: Printers;
  printer_id: Scalars['Int'];
  type: Scalars['String'];
};

/** aggregated selection of "print_types" */
export type Print_Types_Aggregate = {
  __typename?: 'print_types_aggregate';
  aggregate?: Maybe<Print_Types_Aggregate_Fields>;
  nodes: Array<Print_Types>;
};

/** aggregate fields of "print_types" */
export type Print_Types_Aggregate_Fields = {
  __typename?: 'print_types_aggregate_fields';
  avg?: Maybe<Print_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Types_Max_Fields>;
  min?: Maybe<Print_Types_Min_Fields>;
  stddev?: Maybe<Print_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Types_Sum_Fields>;
  var_pop?: Maybe<Print_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Types_Var_Samp_Fields>;
  variance?: Maybe<Print_Types_Variance_Fields>;
};


/** aggregate fields of "print_types" */
export type Print_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "print_types" */
export type Print_Types_Aggregate_Order_By = {
  avg?: Maybe<Print_Types_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Print_Types_Max_Order_By>;
  min?: Maybe<Print_Types_Min_Order_By>;
  stddev?: Maybe<Print_Types_Stddev_Order_By>;
  stddev_pop?: Maybe<Print_Types_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Print_Types_Stddev_Samp_Order_By>;
  sum?: Maybe<Print_Types_Sum_Order_By>;
  var_pop?: Maybe<Print_Types_Var_Pop_Order_By>;
  var_samp?: Maybe<Print_Types_Var_Samp_Order_By>;
  variance?: Maybe<Print_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "print_types" */
export type Print_Types_Arr_Rel_Insert_Input = {
  data: Array<Print_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Print_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Print_Types_Avg_Fields = {
  __typename?: 'print_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "print_types" */
export type Print_Types_Avg_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "print_types". All fields are combined with a logical 'AND'. */
export type Print_Types_Bool_Exp = {
  _and?: Maybe<Array<Print_Types_Bool_Exp>>;
  _not?: Maybe<Print_Types_Bool_Exp>;
  _or?: Maybe<Array<Print_Types_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  printer?: Maybe<Printers_Bool_Exp>;
  printer_id?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "print_types" */
export enum Print_Types_Constraint {
  /** unique or primary key constraint */
  PrintTypesPkey = 'print_types_pkey'
}

/** input type for incrementing numeric columns in table "print_types" */
export type Print_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "print_types" */
export type Print_Types_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  printer?: Maybe<Printers_Obj_Rel_Insert_Input>;
  printer_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Print_Types_Max_Fields = {
  __typename?: 'print_types_max_fields';
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "print_types" */
export type Print_Types_Max_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Print_Types_Min_Fields = {
  __typename?: 'print_types_min_fields';
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "print_types" */
export type Print_Types_Min_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "print_types" */
export type Print_Types_Mutation_Response = {
  __typename?: 'print_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Print_Types>;
};

/** on_conflict condition type for table "print_types" */
export type Print_Types_On_Conflict = {
  constraint: Print_Types_Constraint;
  update_columns?: Array<Print_Types_Update_Column>;
  where?: Maybe<Print_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "print_types". */
export type Print_Types_Order_By = {
  id?: Maybe<Order_By>;
  printer?: Maybe<Printers_Order_By>;
  printer_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: print_types */
export type Print_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "print_types" */
export enum Print_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "print_types" */
export type Print_Types_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Print_Types_Stddev_Fields = {
  __typename?: 'print_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "print_types" */
export type Print_Types_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Print_Types_Stddev_Pop_Fields = {
  __typename?: 'print_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "print_types" */
export type Print_Types_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Print_Types_Stddev_Samp_Fields = {
  __typename?: 'print_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "print_types" */
export type Print_Types_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Print_Types_Sum_Fields = {
  __typename?: 'print_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "print_types" */
export type Print_Types_Sum_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** update columns of table "print_types" */
export enum Print_Types_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Print_Types_Var_Pop_Fields = {
  __typename?: 'print_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "print_types" */
export type Print_Types_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Print_Types_Var_Samp_Fields = {
  __typename?: 'print_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "print_types" */
export type Print_Types_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Print_Types_Variance_Fields = {
  __typename?: 'print_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "print_types" */
export type Print_Types_Variance_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** columns and relationships of "printer_logs" */
export type Printer_Logs = {
  __typename?: 'printer_logs';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['Int'];
  level: Scalars['String'];
  /** An object relationship */
  printer?: Maybe<Printers>;
  printer_id?: Maybe<Scalars['Int']>;
  python_log?: Maybe<Scalars['String']>;
};

/** aggregated selection of "printer_logs" */
export type Printer_Logs_Aggregate = {
  __typename?: 'printer_logs_aggregate';
  aggregate?: Maybe<Printer_Logs_Aggregate_Fields>;
  nodes: Array<Printer_Logs>;
};

/** aggregate fields of "printer_logs" */
export type Printer_Logs_Aggregate_Fields = {
  __typename?: 'printer_logs_aggregate_fields';
  avg?: Maybe<Printer_Logs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Printer_Logs_Max_Fields>;
  min?: Maybe<Printer_Logs_Min_Fields>;
  stddev?: Maybe<Printer_Logs_Stddev_Fields>;
  stddev_pop?: Maybe<Printer_Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Printer_Logs_Stddev_Samp_Fields>;
  sum?: Maybe<Printer_Logs_Sum_Fields>;
  var_pop?: Maybe<Printer_Logs_Var_Pop_Fields>;
  var_samp?: Maybe<Printer_Logs_Var_Samp_Fields>;
  variance?: Maybe<Printer_Logs_Variance_Fields>;
};


/** aggregate fields of "printer_logs" */
export type Printer_Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Printer_Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "printer_logs" */
export type Printer_Logs_Aggregate_Order_By = {
  avg?: Maybe<Printer_Logs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Printer_Logs_Max_Order_By>;
  min?: Maybe<Printer_Logs_Min_Order_By>;
  stddev?: Maybe<Printer_Logs_Stddev_Order_By>;
  stddev_pop?: Maybe<Printer_Logs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Printer_Logs_Stddev_Samp_Order_By>;
  sum?: Maybe<Printer_Logs_Sum_Order_By>;
  var_pop?: Maybe<Printer_Logs_Var_Pop_Order_By>;
  var_samp?: Maybe<Printer_Logs_Var_Samp_Order_By>;
  variance?: Maybe<Printer_Logs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "printer_logs" */
export type Printer_Logs_Arr_Rel_Insert_Input = {
  data: Array<Printer_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Printer_Logs_On_Conflict>;
};

/** aggregate avg on columns */
export type Printer_Logs_Avg_Fields = {
  __typename?: 'printer_logs_avg_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "printer_logs" */
export type Printer_Logs_Avg_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "printer_logs". All fields are combined with a logical 'AND'. */
export type Printer_Logs_Bool_Exp = {
  _and?: Maybe<Array<Printer_Logs_Bool_Exp>>;
  _not?: Maybe<Printer_Logs_Bool_Exp>;
  _or?: Maybe<Array<Printer_Logs_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  level?: Maybe<String_Comparison_Exp>;
  printer?: Maybe<Printers_Bool_Exp>;
  printer_id?: Maybe<Int_Comparison_Exp>;
  python_log?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "printer_logs" */
export enum Printer_Logs_Constraint {
  /** unique or primary key constraint */
  PrinterLogsPkey = 'printer_logs_pkey'
}

/** input type for incrementing numeric columns in table "printer_logs" */
export type Printer_Logs_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "printer_logs" */
export type Printer_Logs_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  printer?: Maybe<Printers_Obj_Rel_Insert_Input>;
  printer_id?: Maybe<Scalars['Int']>;
  python_log?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Printer_Logs_Max_Fields = {
  __typename?: 'printer_logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['Int']>;
  python_log?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "printer_logs" */
export type Printer_Logs_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
  python_log?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Printer_Logs_Min_Fields = {
  __typename?: 'printer_logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['Int']>;
  python_log?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "printer_logs" */
export type Printer_Logs_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
  python_log?: Maybe<Order_By>;
};

/** response of any mutation on the table "printer_logs" */
export type Printer_Logs_Mutation_Response = {
  __typename?: 'printer_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Printer_Logs>;
};

/** on_conflict condition type for table "printer_logs" */
export type Printer_Logs_On_Conflict = {
  constraint: Printer_Logs_Constraint;
  update_columns?: Array<Printer_Logs_Update_Column>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "printer_logs". */
export type Printer_Logs_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  printer?: Maybe<Printers_Order_By>;
  printer_id?: Maybe<Order_By>;
  python_log?: Maybe<Order_By>;
};

/** primary key columns input for table: printer_logs */
export type Printer_Logs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "printer_logs" */
export enum Printer_Logs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  PythonLog = 'python_log'
}

/** input type for updating data in table "printer_logs" */
export type Printer_Logs_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['Int']>;
  python_log?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Printer_Logs_Stddev_Fields = {
  __typename?: 'printer_logs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "printer_logs" */
export type Printer_Logs_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Printer_Logs_Stddev_Pop_Fields = {
  __typename?: 'printer_logs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "printer_logs" */
export type Printer_Logs_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Printer_Logs_Stddev_Samp_Fields = {
  __typename?: 'printer_logs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "printer_logs" */
export type Printer_Logs_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Printer_Logs_Sum_Fields = {
  __typename?: 'printer_logs_sum_fields';
  id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "printer_logs" */
export type Printer_Logs_Sum_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** update columns of table "printer_logs" */
export enum Printer_Logs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  PythonLog = 'python_log'
}

/** aggregate var_pop on columns */
export type Printer_Logs_Var_Pop_Fields = {
  __typename?: 'printer_logs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "printer_logs" */
export type Printer_Logs_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Printer_Logs_Var_Samp_Fields = {
  __typename?: 'printer_logs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "printer_logs" */
export type Printer_Logs_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Printer_Logs_Variance_Fields = {
  __typename?: 'printer_logs_variance_fields';
  id?: Maybe<Scalars['Float']>;
  printer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "printer_logs" */
export type Printer_Logs_Variance_Order_By = {
  id?: Maybe<Order_By>;
  printer_id?: Maybe<Order_By>;
};

/** columns and relationships of "printers" */
export type Printers = {
  __typename?: 'printers';
  address: Scalars['String'];
  alias: Scalars['String'];
  disabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  port?: Maybe<Scalars['String']>;
  /** An array relationship */
  print_templates: Array<Print_Templates>;
  /** An aggregate relationship */
  print_templates_aggregate: Print_Templates_Aggregate;
  /** An array relationship */
  print_types: Array<Print_Types>;
  /** An aggregate relationship */
  print_types_aggregate: Print_Types_Aggregate;
  /** An array relationship */
  printer_logs: Array<Printer_Logs>;
  /** An aggregate relationship */
  printer_logs_aggregate: Printer_Logs_Aggregate;
};


/** columns and relationships of "printers" */
export type PrintersPrint_TemplatesArgs = {
  distinct_on?: Maybe<Array<Print_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Templates_Order_By>>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};


/** columns and relationships of "printers" */
export type PrintersPrint_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Templates_Order_By>>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};


/** columns and relationships of "printers" */
export type PrintersPrint_TypesArgs = {
  distinct_on?: Maybe<Array<Print_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Types_Order_By>>;
  where?: Maybe<Print_Types_Bool_Exp>;
};


/** columns and relationships of "printers" */
export type PrintersPrint_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Types_Order_By>>;
  where?: Maybe<Print_Types_Bool_Exp>;
};


/** columns and relationships of "printers" */
export type PrintersPrinter_LogsArgs = {
  distinct_on?: Maybe<Array<Printer_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printer_Logs_Order_By>>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};


/** columns and relationships of "printers" */
export type PrintersPrinter_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Printer_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printer_Logs_Order_By>>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};

/** aggregated selection of "printers" */
export type Printers_Aggregate = {
  __typename?: 'printers_aggregate';
  aggregate?: Maybe<Printers_Aggregate_Fields>;
  nodes: Array<Printers>;
};

/** aggregate fields of "printers" */
export type Printers_Aggregate_Fields = {
  __typename?: 'printers_aggregate_fields';
  avg?: Maybe<Printers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Printers_Max_Fields>;
  min?: Maybe<Printers_Min_Fields>;
  stddev?: Maybe<Printers_Stddev_Fields>;
  stddev_pop?: Maybe<Printers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Printers_Stddev_Samp_Fields>;
  sum?: Maybe<Printers_Sum_Fields>;
  var_pop?: Maybe<Printers_Var_Pop_Fields>;
  var_samp?: Maybe<Printers_Var_Samp_Fields>;
  variance?: Maybe<Printers_Variance_Fields>;
};


/** aggregate fields of "printers" */
export type Printers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Printers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Printers_Avg_Fields = {
  __typename?: 'printers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "printers". All fields are combined with a logical 'AND'. */
export type Printers_Bool_Exp = {
  _and?: Maybe<Array<Printers_Bool_Exp>>;
  _not?: Maybe<Printers_Bool_Exp>;
  _or?: Maybe<Array<Printers_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  alias?: Maybe<String_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  port?: Maybe<String_Comparison_Exp>;
  print_templates?: Maybe<Print_Templates_Bool_Exp>;
  print_types?: Maybe<Print_Types_Bool_Exp>;
  printer_logs?: Maybe<Printer_Logs_Bool_Exp>;
};

/** unique or primary key constraints on table "printers" */
export enum Printers_Constraint {
  /** unique or primary key constraint */
  PrintersAddressKey = 'printers_address_key',
  /** unique or primary key constraint */
  PrintersPkey = 'printers_pkey'
}

/** input type for incrementing numeric columns in table "printers" */
export type Printers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "printers" */
export type Printers_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
  print_templates?: Maybe<Print_Templates_Arr_Rel_Insert_Input>;
  print_types?: Maybe<Print_Types_Arr_Rel_Insert_Input>;
  printer_logs?: Maybe<Printer_Logs_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Printers_Max_Fields = {
  __typename?: 'printers_max_fields';
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Printers_Min_Fields = {
  __typename?: 'printers_min_fields';
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "printers" */
export type Printers_Mutation_Response = {
  __typename?: 'printers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Printers>;
};

/** input type for inserting object relation for remote table "printers" */
export type Printers_Obj_Rel_Insert_Input = {
  data: Printers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Printers_On_Conflict>;
};

/** on_conflict condition type for table "printers" */
export type Printers_On_Conflict = {
  constraint: Printers_Constraint;
  update_columns?: Array<Printers_Update_Column>;
  where?: Maybe<Printers_Bool_Exp>;
};

/** Ordering options when selecting data from "printers". */
export type Printers_Order_By = {
  address?: Maybe<Order_By>;
  alias?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  port?: Maybe<Order_By>;
  print_templates_aggregate?: Maybe<Print_Templates_Aggregate_Order_By>;
  print_types_aggregate?: Maybe<Print_Types_Aggregate_Order_By>;
  printer_logs_aggregate?: Maybe<Printer_Logs_Aggregate_Order_By>;
};

/** primary key columns input for table: printers */
export type Printers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "printers" */
export enum Printers_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Alias = 'alias',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Port = 'port'
}

/** input type for updating data in table "printers" */
export type Printers_Set_Input = {
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Printers_Stddev_Fields = {
  __typename?: 'printers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Printers_Stddev_Pop_Fields = {
  __typename?: 'printers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Printers_Stddev_Samp_Fields = {
  __typename?: 'printers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Printers_Sum_Fields = {
  __typename?: 'printers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "printers" */
export enum Printers_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Alias = 'alias',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Port = 'port'
}

/** aggregate var_pop on columns */
export type Printers_Var_Pop_Fields = {
  __typename?: 'printers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Printers_Var_Samp_Fields = {
  __typename?: 'printers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Printers_Variance_Fields = {
  __typename?: 'printers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "property_managers" */
export type Property_Managers = {
  __typename?: 'property_managers';
  cellphone: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
};

/** aggregated selection of "property_managers" */
export type Property_Managers_Aggregate = {
  __typename?: 'property_managers_aggregate';
  aggregate?: Maybe<Property_Managers_Aggregate_Fields>;
  nodes: Array<Property_Managers>;
};

/** aggregate fields of "property_managers" */
export type Property_Managers_Aggregate_Fields = {
  __typename?: 'property_managers_aggregate_fields';
  avg?: Maybe<Property_Managers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Managers_Max_Fields>;
  min?: Maybe<Property_Managers_Min_Fields>;
  stddev?: Maybe<Property_Managers_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Managers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Managers_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Managers_Sum_Fields>;
  var_pop?: Maybe<Property_Managers_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Managers_Var_Samp_Fields>;
  variance?: Maybe<Property_Managers_Variance_Fields>;
};


/** aggregate fields of "property_managers" */
export type Property_Managers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Property_Managers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Property_Managers_Avg_Fields = {
  __typename?: 'property_managers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "property_managers". All fields are combined with a logical 'AND'. */
export type Property_Managers_Bool_Exp = {
  _and?: Maybe<Array<Property_Managers_Bool_Exp>>;
  _not?: Maybe<Property_Managers_Bool_Exp>;
  _or?: Maybe<Array<Property_Managers_Bool_Exp>>;
  cellphone?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_managers" */
export enum Property_Managers_Constraint {
  /** unique or primary key constraint */
  PropertyManagersPkey = 'property_managers_pkey'
}

/** input type for incrementing numeric columns in table "property_managers" */
export type Property_Managers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "property_managers" */
export type Property_Managers_Insert_Input = {
  cellphone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Property_Managers_Max_Fields = {
  __typename?: 'property_managers_max_fields';
  cellphone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Managers_Min_Fields = {
  __typename?: 'property_managers_min_fields';
  cellphone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "property_managers" */
export type Property_Managers_Mutation_Response = {
  __typename?: 'property_managers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Managers>;
};

/** on_conflict condition type for table "property_managers" */
export type Property_Managers_On_Conflict = {
  constraint: Property_Managers_Constraint;
  update_columns?: Array<Property_Managers_Update_Column>;
  where?: Maybe<Property_Managers_Bool_Exp>;
};

/** Ordering options when selecting data from "property_managers". */
export type Property_Managers_Order_By = {
  cellphone?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
};

/** primary key columns input for table: property_managers */
export type Property_Managers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "property_managers" */
export enum Property_Managers_Select_Column {
  /** column name */
  Cellphone = 'cellphone',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** input type for updating data in table "property_managers" */
export type Property_Managers_Set_Input = {
  cellphone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Property_Managers_Stddev_Fields = {
  __typename?: 'property_managers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Property_Managers_Stddev_Pop_Fields = {
  __typename?: 'property_managers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Property_Managers_Stddev_Samp_Fields = {
  __typename?: 'property_managers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Property_Managers_Sum_Fields = {
  __typename?: 'property_managers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "property_managers" */
export enum Property_Managers_Update_Column {
  /** column name */
  Cellphone = 'cellphone',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** aggregate var_pop on columns */
export type Property_Managers_Var_Pop_Fields = {
  __typename?: 'property_managers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Property_Managers_Var_Samp_Fields = {
  __typename?: 'property_managers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Property_Managers_Variance_Fields = {
  __typename?: 'property_managers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "providers" */
export type Providers = {
  __typename?: 'providers';
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  history_comments: Array<History_Comments>;
  /** An aggregate relationship */
  history_comments_aggregate: History_Comments_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
  /** An array relationship */
  providers_ratings: Array<Providers_Ratings>;
  /** An aggregate relationship */
  providers_ratings_aggregate: Providers_Ratings_Aggregate;
  /** An array relationship */
  providers_services: Array<Providers_Services>;
  /** An aggregate relationship */
  providers_services_aggregate: Providers_Services_Aggregate;
  rate?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "providers" */
export type ProvidersHistory_CommentsArgs = {
  distinct_on?: Maybe<Array<History_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<History_Comments_Order_By>>;
  where?: Maybe<History_Comments_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersHistory_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<History_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<History_Comments_Order_By>>;
  where?: Maybe<History_Comments_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProviders_RatingsArgs = {
  distinct_on?: Maybe<Array<Providers_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Ratings_Order_By>>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProviders_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Ratings_Order_By>>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProviders_ServicesArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProviders_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};

/** aggregated selection of "providers" */
export type Providers_Aggregate = {
  __typename?: 'providers_aggregate';
  aggregate?: Maybe<Providers_Aggregate_Fields>;
  nodes: Array<Providers>;
};

/** aggregate fields of "providers" */
export type Providers_Aggregate_Fields = {
  __typename?: 'providers_aggregate_fields';
  avg?: Maybe<Providers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Providers_Max_Fields>;
  min?: Maybe<Providers_Min_Fields>;
  stddev?: Maybe<Providers_Stddev_Fields>;
  stddev_pop?: Maybe<Providers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Providers_Stddev_Samp_Fields>;
  sum?: Maybe<Providers_Sum_Fields>;
  var_pop?: Maybe<Providers_Var_Pop_Fields>;
  var_samp?: Maybe<Providers_Var_Samp_Fields>;
  variance?: Maybe<Providers_Variance_Fields>;
};


/** aggregate fields of "providers" */
export type Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Providers_Avg_Fields = {
  __typename?: 'providers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "providers". All fields are combined with a logical 'AND'. */
export type Providers_Bool_Exp = {
  _and?: Maybe<Array<Providers_Bool_Exp>>;
  _not?: Maybe<Providers_Bool_Exp>;
  _or?: Maybe<Array<Providers_Bool_Exp>>;
  email?: Maybe<String_Comparison_Exp>;
  history_comments?: Maybe<History_Comments_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  providers_ratings?: Maybe<Providers_Ratings_Bool_Exp>;
  providers_services?: Maybe<Providers_Services_Bool_Exp>;
  rate?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "providers" */
export enum Providers_Constraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for incrementing numeric columns in table "providers" */
export type Providers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "providers" */
export type Providers_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  history_comments?: Maybe<History_Comments_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  providers_ratings?: Maybe<Providers_Ratings_Arr_Rel_Insert_Input>;
  providers_services?: Maybe<Providers_Services_Arr_Rel_Insert_Input>;
  rate?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Providers_Max_Fields = {
  __typename?: 'providers_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Providers_Min_Fields = {
  __typename?: 'providers_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "providers" */
export type Providers_Mutation_Response = {
  __typename?: 'providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Providers>;
};

/** input type for inserting object relation for remote table "providers" */
export type Providers_Obj_Rel_Insert_Input = {
  data: Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Providers_On_Conflict>;
};

/** on_conflict condition type for table "providers" */
export type Providers_On_Conflict = {
  constraint: Providers_Constraint;
  update_columns?: Array<Providers_Update_Column>;
  where?: Maybe<Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "providers". */
export type Providers_Order_By = {
  email?: Maybe<Order_By>;
  history_comments_aggregate?: Maybe<History_Comments_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  providers_ratings_aggregate?: Maybe<Providers_Ratings_Aggregate_Order_By>;
  providers_services_aggregate?: Maybe<Providers_Services_Aggregate_Order_By>;
  rate?: Maybe<Order_By>;
};

/** primary key columns input for table: providers */
export type Providers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "providers_ratings" */
export type Providers_Ratings = {
  __typename?: 'providers_ratings';
  comment: Scalars['String'];
  id: Scalars['Int'];
  id_provider: Scalars['Int'];
  id_user: Scalars['Int'];
  /** An object relationship */
  provider: Providers;
  rating: Scalars['Int'];
};

/** aggregated selection of "providers_ratings" */
export type Providers_Ratings_Aggregate = {
  __typename?: 'providers_ratings_aggregate';
  aggregate?: Maybe<Providers_Ratings_Aggregate_Fields>;
  nodes: Array<Providers_Ratings>;
};

/** aggregate fields of "providers_ratings" */
export type Providers_Ratings_Aggregate_Fields = {
  __typename?: 'providers_ratings_aggregate_fields';
  avg?: Maybe<Providers_Ratings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Providers_Ratings_Max_Fields>;
  min?: Maybe<Providers_Ratings_Min_Fields>;
  stddev?: Maybe<Providers_Ratings_Stddev_Fields>;
  stddev_pop?: Maybe<Providers_Ratings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Providers_Ratings_Stddev_Samp_Fields>;
  sum?: Maybe<Providers_Ratings_Sum_Fields>;
  var_pop?: Maybe<Providers_Ratings_Var_Pop_Fields>;
  var_samp?: Maybe<Providers_Ratings_Var_Samp_Fields>;
  variance?: Maybe<Providers_Ratings_Variance_Fields>;
};


/** aggregate fields of "providers_ratings" */
export type Providers_Ratings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Providers_Ratings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "providers_ratings" */
export type Providers_Ratings_Aggregate_Order_By = {
  avg?: Maybe<Providers_Ratings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Providers_Ratings_Max_Order_By>;
  min?: Maybe<Providers_Ratings_Min_Order_By>;
  stddev?: Maybe<Providers_Ratings_Stddev_Order_By>;
  stddev_pop?: Maybe<Providers_Ratings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Providers_Ratings_Stddev_Samp_Order_By>;
  sum?: Maybe<Providers_Ratings_Sum_Order_By>;
  var_pop?: Maybe<Providers_Ratings_Var_Pop_Order_By>;
  var_samp?: Maybe<Providers_Ratings_Var_Samp_Order_By>;
  variance?: Maybe<Providers_Ratings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "providers_ratings" */
export type Providers_Ratings_Arr_Rel_Insert_Input = {
  data: Array<Providers_Ratings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Providers_Ratings_On_Conflict>;
};

/** aggregate avg on columns */
export type Providers_Ratings_Avg_Fields = {
  __typename?: 'providers_ratings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "providers_ratings" */
export type Providers_Ratings_Avg_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "providers_ratings". All fields are combined with a logical 'AND'. */
export type Providers_Ratings_Bool_Exp = {
  _and?: Maybe<Array<Providers_Ratings_Bool_Exp>>;
  _not?: Maybe<Providers_Ratings_Bool_Exp>;
  _or?: Maybe<Array<Providers_Ratings_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  id_provider?: Maybe<Int_Comparison_Exp>;
  id_user?: Maybe<Int_Comparison_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  rating?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "providers_ratings" */
export enum Providers_Ratings_Constraint {
  /** unique or primary key constraint */
  ProvidersRatingsPkey = 'providers_ratings_pkey'
}

/** input type for incrementing numeric columns in table "providers_ratings" */
export type Providers_Ratings_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  id_provider?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "providers_ratings" */
export type Providers_Ratings_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_provider?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  rating?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Providers_Ratings_Max_Fields = {
  __typename?: 'providers_ratings_max_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_provider?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "providers_ratings" */
export type Providers_Ratings_Max_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Providers_Ratings_Min_Fields = {
  __typename?: 'providers_ratings_min_fields';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_provider?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "providers_ratings" */
export type Providers_Ratings_Min_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** response of any mutation on the table "providers_ratings" */
export type Providers_Ratings_Mutation_Response = {
  __typename?: 'providers_ratings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Providers_Ratings>;
};

/** on_conflict condition type for table "providers_ratings" */
export type Providers_Ratings_On_Conflict = {
  constraint: Providers_Ratings_Constraint;
  update_columns?: Array<Providers_Ratings_Update_Column>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};

/** Ordering options when selecting data from "providers_ratings". */
export type Providers_Ratings_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  provider?: Maybe<Providers_Order_By>;
  rating?: Maybe<Order_By>;
};

/** primary key columns input for table: providers_ratings */
export type Providers_Ratings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "providers_ratings" */
export enum Providers_Ratings_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  IdProvider = 'id_provider',
  /** column name */
  IdUser = 'id_user',
  /** column name */
  Rating = 'rating'
}

/** input type for updating data in table "providers_ratings" */
export type Providers_Ratings_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  id_provider?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Providers_Ratings_Stddev_Fields = {
  __typename?: 'providers_ratings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "providers_ratings" */
export type Providers_Ratings_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Providers_Ratings_Stddev_Pop_Fields = {
  __typename?: 'providers_ratings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "providers_ratings" */
export type Providers_Ratings_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Providers_Ratings_Stddev_Samp_Fields = {
  __typename?: 'providers_ratings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "providers_ratings" */
export type Providers_Ratings_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Providers_Ratings_Sum_Fields = {
  __typename?: 'providers_ratings_sum_fields';
  id?: Maybe<Scalars['Int']>;
  id_provider?: Maybe<Scalars['Int']>;
  id_user?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "providers_ratings" */
export type Providers_Ratings_Sum_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** update columns of table "providers_ratings" */
export enum Providers_Ratings_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Id = 'id',
  /** column name */
  IdProvider = 'id_provider',
  /** column name */
  IdUser = 'id_user',
  /** column name */
  Rating = 'rating'
}

/** aggregate var_pop on columns */
export type Providers_Ratings_Var_Pop_Fields = {
  __typename?: 'providers_ratings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "providers_ratings" */
export type Providers_Ratings_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Providers_Ratings_Var_Samp_Fields = {
  __typename?: 'providers_ratings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "providers_ratings" */
export type Providers_Ratings_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Providers_Ratings_Variance_Fields = {
  __typename?: 'providers_ratings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  id_provider?: Maybe<Scalars['Float']>;
  id_user?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "providers_ratings" */
export type Providers_Ratings_Variance_Order_By = {
  id?: Maybe<Order_By>;
  id_provider?: Maybe<Order_By>;
  id_user?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** select columns of table "providers" */
export enum Providers_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Rate = 'rate'
}

/** columns and relationships of "providers_services" */
export type Providers_Services = {
  __typename?: 'providers_services';
  id: Scalars['Int'];
  /** An object relationship */
  provider: Providers;
  provider_id: Scalars['Int'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['Int'];
};

/** aggregated selection of "providers_services" */
export type Providers_Services_Aggregate = {
  __typename?: 'providers_services_aggregate';
  aggregate?: Maybe<Providers_Services_Aggregate_Fields>;
  nodes: Array<Providers_Services>;
};

/** aggregate fields of "providers_services" */
export type Providers_Services_Aggregate_Fields = {
  __typename?: 'providers_services_aggregate_fields';
  avg?: Maybe<Providers_Services_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Providers_Services_Max_Fields>;
  min?: Maybe<Providers_Services_Min_Fields>;
  stddev?: Maybe<Providers_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Providers_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Providers_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Providers_Services_Sum_Fields>;
  var_pop?: Maybe<Providers_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Providers_Services_Var_Samp_Fields>;
  variance?: Maybe<Providers_Services_Variance_Fields>;
};


/** aggregate fields of "providers_services" */
export type Providers_Services_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Providers_Services_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "providers_services" */
export type Providers_Services_Aggregate_Order_By = {
  avg?: Maybe<Providers_Services_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Providers_Services_Max_Order_By>;
  min?: Maybe<Providers_Services_Min_Order_By>;
  stddev?: Maybe<Providers_Services_Stddev_Order_By>;
  stddev_pop?: Maybe<Providers_Services_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Providers_Services_Stddev_Samp_Order_By>;
  sum?: Maybe<Providers_Services_Sum_Order_By>;
  var_pop?: Maybe<Providers_Services_Var_Pop_Order_By>;
  var_samp?: Maybe<Providers_Services_Var_Samp_Order_By>;
  variance?: Maybe<Providers_Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "providers_services" */
export type Providers_Services_Arr_Rel_Insert_Input = {
  data: Array<Providers_Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Providers_Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Providers_Services_Avg_Fields = {
  __typename?: 'providers_services_avg_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "providers_services" */
export type Providers_Services_Avg_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "providers_services". All fields are combined with a logical 'AND'. */
export type Providers_Services_Bool_Exp = {
  _and?: Maybe<Array<Providers_Services_Bool_Exp>>;
  _not?: Maybe<Providers_Services_Bool_Exp>;
  _or?: Maybe<Array<Providers_Services_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  provider_id?: Maybe<Int_Comparison_Exp>;
  service?: Maybe<Services_Bool_Exp>;
  service_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "providers_services" */
export enum Providers_Services_Constraint {
  /** unique or primary key constraint */
  ProvidersServicesPkey = 'providers_services_pkey'
}

/** input type for incrementing numeric columns in table "providers_services" */
export type Providers_Services_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "providers_services" */
export type Providers_Services_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  provider_id?: Maybe<Scalars['Int']>;
  service?: Maybe<Services_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Providers_Services_Max_Fields = {
  __typename?: 'providers_services_max_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "providers_services" */
export type Providers_Services_Max_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Providers_Services_Min_Fields = {
  __typename?: 'providers_services_min_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "providers_services" */
export type Providers_Services_Min_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "providers_services" */
export type Providers_Services_Mutation_Response = {
  __typename?: 'providers_services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Providers_Services>;
};

/** on_conflict condition type for table "providers_services" */
export type Providers_Services_On_Conflict = {
  constraint: Providers_Services_Constraint;
  update_columns?: Array<Providers_Services_Update_Column>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};

/** Ordering options when selecting data from "providers_services". */
export type Providers_Services_Order_By = {
  id?: Maybe<Order_By>;
  provider?: Maybe<Providers_Order_By>;
  provider_id?: Maybe<Order_By>;
  service?: Maybe<Services_Order_By>;
  service_id?: Maybe<Order_By>;
};

/** primary key columns input for table: providers_services */
export type Providers_Services_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "providers_services" */
export enum Providers_Services_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "providers_services" */
export type Providers_Services_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Providers_Services_Stddev_Fields = {
  __typename?: 'providers_services_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "providers_services" */
export type Providers_Services_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Providers_Services_Stddev_Pop_Fields = {
  __typename?: 'providers_services_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "providers_services" */
export type Providers_Services_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Providers_Services_Stddev_Samp_Fields = {
  __typename?: 'providers_services_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "providers_services" */
export type Providers_Services_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Providers_Services_Sum_Fields = {
  __typename?: 'providers_services_sum_fields';
  id?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "providers_services" */
export type Providers_Services_Sum_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** update columns of table "providers_services" */
export enum Providers_Services_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ServiceId = 'service_id'
}

/** aggregate var_pop on columns */
export type Providers_Services_Var_Pop_Fields = {
  __typename?: 'providers_services_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "providers_services" */
export type Providers_Services_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Providers_Services_Var_Samp_Fields = {
  __typename?: 'providers_services_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "providers_services" */
export type Providers_Services_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Providers_Services_Variance_Fields = {
  __typename?: 'providers_services_variance_fields';
  id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "providers_services" */
export type Providers_Services_Variance_Order_By = {
  id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** input type for updating data in table "providers" */
export type Providers_Set_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Providers_Stddev_Fields = {
  __typename?: 'providers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Providers_Stddev_Pop_Fields = {
  __typename?: 'providers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Providers_Stddev_Samp_Fields = {
  __typename?: 'providers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Providers_Sum_Fields = {
  __typename?: 'providers_sum_fields';
  id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** update columns of table "providers" */
export enum Providers_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Rate = 'rate'
}

/** aggregate var_pop on columns */
export type Providers_Var_Pop_Fields = {
  __typename?: 'providers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Providers_Var_Samp_Fields = {
  __typename?: 'providers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Providers_Variance_Fields = {
  __typename?: 'providers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  account_tickets: Array<Account_Tickets>;
  /** An aggregate relationship */
  account_tickets_aggregate: Account_Tickets_Aggregate;
  /** fetch data from the table: "account_tickets" using primary key columns */
  account_tickets_by_pk?: Maybe<Account_Tickets>;
  /** fetch data from the table: "activities" */
  activities: Array<Activities>;
  /** fetch aggregated fields from the table: "activities" */
  activities_aggregate: Activities_Aggregate;
  /** fetch data from the table: "activities" using primary key columns */
  activities_by_pk?: Maybe<Activities>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table: "admin_items" */
  admin_items: Array<Admin_Items>;
  /** fetch aggregated fields from the table: "admin_items" */
  admin_items_aggregate: Admin_Items_Aggregate;
  /** fetch data from the table: "admin_items" using primary key columns */
  admin_items_by_pk?: Maybe<Admin_Items>;
  /** fetch data from the table: "admin_record" */
  admin_record: Array<Admin_Record>;
  /** fetch aggregated fields from the table: "admin_record" */
  admin_record_aggregate: Admin_Record_Aggregate;
  /** fetch data from the table: "admin_record" using primary key columns */
  admin_record_by_pk?: Maybe<Admin_Record>;
  /** fetch data from the table: "apartment" */
  apartment: Array<Apartment>;
  /** fetch aggregated fields from the table: "apartment" */
  apartment_aggregate: Apartment_Aggregate;
  /** fetch data from the table: "apartment" using primary key columns */
  apartment_by_pk?: Maybe<Apartment>;
  /** fetch data from the table: "apartments_helper" */
  apartments_helper: Array<Apartments_Helper>;
  /** fetch aggregated fields from the table: "apartments_helper" */
  apartments_helper_aggregate: Apartments_Helper_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** fetch data from the table: "appointments" using primary key columns */
  appointments_by_pk?: Maybe<Appointments>;
  /** An array relationship */
  arrivals: Array<Arrivals>;
  /** An aggregate relationship */
  arrivals_aggregate: Arrivals_Aggregate;
  /** fetch data from the table: "arrivals" using primary key columns */
  arrivals_by_pk?: Maybe<Arrivals>;
  /** fetch data from the table: "balances_report" */
  balances_report: Array<Balances_Report>;
  /** fetch aggregated fields from the table: "balances_report" */
  balances_report_aggregate: Balances_Report_Aggregate;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** fetch data from the table: "blog_types" */
  blog_types: Array<Blog_Types>;
  /** fetch aggregated fields from the table: "blog_types" */
  blog_types_aggregate: Blog_Types_Aggregate;
  /** fetch data from the table: "blog_types" using primary key columns */
  blog_types_by_pk?: Maybe<Blog_Types>;
  /** fetch data from the table: "buildings" */
  buildings: Array<Buildings>;
  /** fetch aggregated fields from the table: "buildings" */
  buildings_aggregate: Buildings_Aggregate;
  /** fetch data from the table: "buildings" using primary key columns */
  buildings_by_pk?: Maybe<Buildings>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table: "common_area_types" */
  common_area_types: Array<Common_Area_Types>;
  /** fetch aggregated fields from the table: "common_area_types" */
  common_area_types_aggregate: Common_Area_Types_Aggregate;
  /** fetch data from the table: "common_area_types" using primary key columns */
  common_area_types_by_pk?: Maybe<Common_Area_Types>;
  /** An array relationship */
  common_areas: Array<Common_Areas>;
  /** An aggregate relationship */
  common_areas_aggregate: Common_Areas_Aggregate;
  /** fetch data from the table: "common_areas" using primary key columns */
  common_areas_by_pk?: Maybe<Common_Areas>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** An array relationship */
  contact_addresses: Array<Contact_Addresses>;
  /** An aggregate relationship */
  contact_addresses_aggregate: Contact_Addresses_Aggregate;
  /** fetch data from the table: "contact_addresses" using primary key columns */
  contact_addresses_by_pk?: Maybe<Contact_Addresses>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** An array relationship */
  contact_emails: Array<Contact_Emails>;
  /** An aggregate relationship */
  contact_emails_aggregate: Contact_Emails_Aggregate;
  /** fetch data from the table: "contact_emails" using primary key columns */
  contact_emails_by_pk?: Maybe<Contact_Emails>;
  /** An array relationship */
  contact_phones: Array<Contact_Phones>;
  /** An aggregate relationship */
  contact_phones_aggregate: Contact_Phones_Aggregate;
  /** fetch data from the table: "contact_phones" using primary key columns */
  contact_phones_by_pk?: Maybe<Contact_Phones>;
  /** fetch data from the table: "currency_type" */
  currency_type: Array<Currency_Type>;
  /** fetch aggregated fields from the table: "currency_type" */
  currency_type_aggregate: Currency_Type_Aggregate;
  /** fetch data from the table: "currency_type" using primary key columns */
  currency_type_by_pk?: Maybe<Currency_Type>;
  /** An array relationship */
  dishes: Array<Dishes>;
  /** An aggregate relationship */
  dishes_aggregate: Dishes_Aggregate;
  /** fetch data from the table: "dishes" using primary key columns */
  dishes_by_pk?: Maybe<Dishes>;
  /** fetch data from the table: "email" */
  email: Array<Email>;
  /** fetch aggregated fields from the table: "email" */
  email_aggregate: Email_Aggregate;
  /** fetch data from the table: "email" using primary key columns */
  email_by_pk?: Maybe<Email>;
  /** fetch data from the table: "employees" */
  employees: Array<Employees>;
  /** fetch aggregated fields from the table: "employees" */
  employees_aggregate: Employees_Aggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** fetch data from the table: "exchange" */
  exchange: Array<Exchange>;
  /** fetch aggregated fields from the table: "exchange" */
  exchange_aggregate: Exchange_Aggregate;
  /** fetch data from the table: "exchange" using primary key columns */
  exchange_by_pk?: Maybe<Exchange>;
  /** An array relationship */
  history_comments: Array<History_Comments>;
  /** An aggregate relationship */
  history_comments_aggregate: History_Comments_Aggregate;
  /** fetch data from the table: "history_comments" using primary key columns */
  history_comments_by_pk?: Maybe<History_Comments>;
  /** fetch data from the table: "ingredient_dish" */
  ingredient_dish: Array<Ingredient_Dish>;
  /** fetch aggregated fields from the table: "ingredient_dish" */
  ingredient_dish_aggregate: Ingredient_Dish_Aggregate;
  /** fetch data from the table: "ingredient_dish" using primary key columns */
  ingredient_dish_by_pk?: Maybe<Ingredient_Dish>;
  /** An array relationship */
  ingredients: Array<Ingredients>;
  /** An aggregate relationship */
  ingredients_aggregate: Ingredients_Aggregate;
  /** fetch data from the table: "ingredients" using primary key columns */
  ingredients_by_pk?: Maybe<Ingredients>;
  /** An array relationship */
  lessors: Array<Lessors>;
  /** An aggregate relationship */
  lessors_aggregate: Lessors_Aggregate;
  /** fetch data from the table: "lessors" using primary key columns */
  lessors_by_pk?: Maybe<Lessors>;
  /** fetch data from the table: "menu_types" */
  menu_types: Array<Menu_Types>;
  /** fetch aggregated fields from the table: "menu_types" */
  menu_types_aggregate: Menu_Types_Aggregate;
  /** fetch data from the table: "menu_types" using primary key columns */
  menu_types_by_pk?: Maybe<Menu_Types>;
  /** An array relationship */
  minutes: Array<Minutes>;
  /** An aggregate relationship */
  minutes_aggregate: Minutes_Aggregate;
  /** fetch data from the table: "minutes" using primary key columns */
  minutes_by_pk?: Maybe<Minutes>;
  /** fetch data from the table: "minutes_types" */
  minutes_types: Array<Minutes_Types>;
  /** fetch aggregated fields from the table: "minutes_types" */
  minutes_types_aggregate: Minutes_Types_Aggregate;
  /** fetch data from the table: "minutes_types" using primary key columns */
  minutes_types_by_pk?: Maybe<Minutes_Types>;
  /** fetch data from the table: "modifier_group_dish" */
  modifier_group_dish: Array<Modifier_Group_Dish>;
  /** fetch aggregated fields from the table: "modifier_group_dish" */
  modifier_group_dish_aggregate: Modifier_Group_Dish_Aggregate;
  /** fetch data from the table: "modifier_group_dish" using primary key columns */
  modifier_group_dish_by_pk?: Maybe<Modifier_Group_Dish>;
  /** fetch data from the table: "modifier_groups" */
  modifier_groups: Array<Modifier_Groups>;
  /** fetch aggregated fields from the table: "modifier_groups" */
  modifier_groups_aggregate: Modifier_Groups_Aggregate;
  /** fetch data from the table: "modifier_groups" using primary key columns */
  modifier_groups_by_pk?: Maybe<Modifier_Groups>;
  /** An array relationship */
  modifiers: Array<Modifiers>;
  /** An aggregate relationship */
  modifiers_aggregate: Modifiers_Aggregate;
  /** fetch data from the table: "modifiers" using primary key columns */
  modifiers_by_pk?: Maybe<Modifiers>;
  /** fetch data from the table: "motions" */
  motions: Array<Motions>;
  /** fetch aggregated fields from the table: "motions" */
  motions_aggregate: Motions_Aggregate;
  /** fetch data from the table: "motions" using primary key columns */
  motions_by_pk?: Maybe<Motions>;
  /** fetch data from the table: "motions_vote" */
  motions_vote: Array<Motions_Vote>;
  /** fetch aggregated fields from the table: "motions_vote" */
  motions_vote_aggregate: Motions_Vote_Aggregate;
  /** fetch data from the table: "motions_vote" using primary key columns */
  motions_vote_by_pk?: Maybe<Motions_Vote>;
  /** An array relationship */
  new_owners: Array<New_Owners>;
  /** An aggregate relationship */
  new_owners_aggregate: New_Owners_Aggregate;
  /** fetch data from the table: "new_owners" using primary key columns */
  new_owners_by_pk?: Maybe<New_Owners>;
  /** fetch data from the table: "order_dish" */
  order_dish: Array<Order_Dish>;
  /** fetch aggregated fields from the table: "order_dish" */
  order_dish_aggregate: Order_Dish_Aggregate;
  /** fetch data from the table: "order_dish" using primary key columns */
  order_dish_by_pk?: Maybe<Order_Dish>;
  /** An array relationship */
  order_dish_ingredients: Array<Order_Dish_Ingredients>;
  /** An aggregate relationship */
  order_dish_ingredients_aggregate: Order_Dish_Ingredients_Aggregate;
  /** fetch data from the table: "order_dish_ingredients" using primary key columns */
  order_dish_ingredients_by_pk?: Maybe<Order_Dish_Ingredients>;
  /** An array relationship */
  order_dish_modifier_groups: Array<Order_Dish_Modifier_Groups>;
  /** An aggregate relationship */
  order_dish_modifier_groups_aggregate: Order_Dish_Modifier_Groups_Aggregate;
  /** fetch data from the table: "order_dish_modifier_groups" using primary key columns */
  order_dish_modifier_groups_by_pk?: Maybe<Order_Dish_Modifier_Groups>;
  /** An array relationship */
  order_dish_modifiers: Array<Order_Dish_Modifiers>;
  /** An aggregate relationship */
  order_dish_modifiers_aggregate: Order_Dish_Modifiers_Aggregate;
  /** fetch data from the table: "order_dish_modifiers" using primary key columns */
  order_dish_modifiers_by_pk?: Maybe<Order_Dish_Modifiers>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An array relationship */
  orders_actions: Array<Orders_Actions>;
  /** An aggregate relationship */
  orders_actions_aggregate: Orders_Actions_Aggregate;
  /** fetch data from the table: "orders_actions" using primary key columns */
  orders_actions_by_pk?: Maybe<Orders_Actions>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "palapa_account" */
  palapa_account: Array<Palapa_Account>;
  /** fetch aggregated fields from the table: "palapa_account" */
  palapa_account_aggregate: Palapa_Account_Aggregate;
  /** fetch data from the table: "palapa_account" using primary key columns */
  palapa_account_by_pk?: Maybe<Palapa_Account>;
  /** fetch data from the table: "palapa_accounts" */
  palapa_accounts: Array<Palapa_Accounts>;
  /** fetch aggregated fields from the table: "palapa_accounts" */
  palapa_accounts_aggregate: Palapa_Accounts_Aggregate;
  /** fetch data from the table: "palapa_items" */
  palapa_items: Array<Palapa_Items>;
  /** fetch aggregated fields from the table: "palapa_items" */
  palapa_items_aggregate: Palapa_Items_Aggregate;
  /** fetch data from the table: "palapa_items" using primary key columns */
  palapa_items_by_pk?: Maybe<Palapa_Items>;
  /** fetch data from the table: "phones" */
  phones: Array<Phones>;
  /** fetch aggregated fields from the table: "phones" */
  phones_aggregate: Phones_Aggregate;
  /** fetch data from the table: "phones" using primary key columns */
  phones_by_pk?: Maybe<Phones>;
  /** An array relationship */
  print_templates: Array<Print_Templates>;
  /** An aggregate relationship */
  print_templates_aggregate: Print_Templates_Aggregate;
  /** fetch data from the table: "print_templates" using primary key columns */
  print_templates_by_pk?: Maybe<Print_Templates>;
  /** An array relationship */
  print_types: Array<Print_Types>;
  /** An aggregate relationship */
  print_types_aggregate: Print_Types_Aggregate;
  /** fetch data from the table: "print_types" using primary key columns */
  print_types_by_pk?: Maybe<Print_Types>;
  /** An array relationship */
  printer_logs: Array<Printer_Logs>;
  /** An aggregate relationship */
  printer_logs_aggregate: Printer_Logs_Aggregate;
  /** fetch data from the table: "printer_logs" using primary key columns */
  printer_logs_by_pk?: Maybe<Printer_Logs>;
  /** fetch data from the table: "printers" */
  printers: Array<Printers>;
  /** fetch aggregated fields from the table: "printers" */
  printers_aggregate: Printers_Aggregate;
  /** fetch data from the table: "printers" using primary key columns */
  printers_by_pk?: Maybe<Printers>;
  /** fetch data from the table: "property_managers" */
  property_managers: Array<Property_Managers>;
  /** fetch aggregated fields from the table: "property_managers" */
  property_managers_aggregate: Property_Managers_Aggregate;
  /** fetch data from the table: "property_managers" using primary key columns */
  property_managers_by_pk?: Maybe<Property_Managers>;
  /** fetch data from the table: "providers" */
  providers: Array<Providers>;
  /** fetch aggregated fields from the table: "providers" */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** An array relationship */
  providers_ratings: Array<Providers_Ratings>;
  /** An aggregate relationship */
  providers_ratings_aggregate: Providers_Ratings_Aggregate;
  /** fetch data from the table: "providers_ratings" using primary key columns */
  providers_ratings_by_pk?: Maybe<Providers_Ratings>;
  /** An array relationship */
  providers_services: Array<Providers_Services>;
  /** An aggregate relationship */
  providers_services_aggregate: Providers_Services_Aggregate;
  /** fetch data from the table: "providers_services" using primary key columns */
  providers_services_by_pk?: Maybe<Providers_Services>;
  registerUser?: Maybe<UserInfo>;
  /** fetch data from the table: "rental" */
  rental: Array<Rental>;
  /** fetch aggregated fields from the table: "rental" */
  rental_aggregate: Rental_Aggregate;
  /** fetch data from the table: "rental" using primary key columns */
  rental_by_pk?: Maybe<Rental>;
  /** fetch data from the table: "rules_and_regulations" */
  rules_and_regulations: Array<Rules_And_Regulations>;
  /** fetch aggregated fields from the table: "rules_and_regulations" */
  rules_and_regulations_aggregate: Rules_And_Regulations_Aggregate;
  /** fetch data from the table: "rules_and_regulations" using primary key columns */
  rules_and_regulations_by_pk?: Maybe<Rules_And_Regulations>;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "statement_account" */
  statement_account: Array<Statement_Account>;
  /** fetch aggregated fields from the table: "statement_account" */
  statement_account_aggregate: Statement_Account_Aggregate;
  /** fetch data from the table: "statement_account" using primary key columns */
  statement_account_by_pk?: Maybe<Statement_Account>;
  /** An array relationship */
  subcategories: Array<Subcategories>;
  /** An aggregate relationship */
  subcategories_aggregate: Subcategories_Aggregate;
  /** fetch data from the table: "subcategories" using primary key columns */
  subcategories_by_pk?: Maybe<Subcategories>;
  /** fetch data from the table: "tables" */
  tables: Array<Tables>;
  /** fetch aggregated fields from the table: "tables" */
  tables_aggregate: Tables_Aggregate;
  /** fetch data from the table: "tables" using primary key columns */
  tables_by_pk?: Maybe<Tables>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<Transaction_Type>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: Transaction_Type_Aggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "transactions_helper" */
  transactions_helper: Array<Transactions_Helper>;
  /** fetch aggregated fields from the table: "transactions_helper" */
  transactions_helper_aggregate: Transactions_Helper_Aggregate;
  /** fetch data from the table: "units" */
  units: Array<Units>;
  /** fetch aggregated fields from the table: "units" */
  units_aggregate: Units_Aggregate;
  /** fetch data from the table: "units" using primary key columns */
  units_by_pk?: Maybe<Units>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
  /** fetch data from the table: "visits" using primary key columns */
  visits_by_pk?: Maybe<Visits>;
};


export type Query_RootAccount_TicketsArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


export type Query_RootAccount_Tickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


export type Query_RootAccount_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Query_RootActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Query_RootActivities_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAddressesArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAdmin_ItemsArgs = {
  distinct_on?: Maybe<Array<Admin_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Items_Order_By>>;
  where?: Maybe<Admin_Items_Bool_Exp>;
};


export type Query_RootAdmin_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Items_Order_By>>;
  where?: Maybe<Admin_Items_Bool_Exp>;
};


export type Query_RootAdmin_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAdmin_RecordArgs = {
  distinct_on?: Maybe<Array<Admin_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Record_Order_By>>;
  where?: Maybe<Admin_Record_Bool_Exp>;
};


export type Query_RootAdmin_Record_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Record_Order_By>>;
  where?: Maybe<Admin_Record_Bool_Exp>;
};


export type Query_RootAdmin_Record_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootApartmentArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


export type Query_RootApartment_AggregateArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


export type Query_RootApartment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootApartments_HelperArgs = {
  distinct_on?: Maybe<Array<Apartments_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartments_Helper_Order_By>>;
  where?: Maybe<Apartments_Helper_Bool_Exp>;
};


export type Query_RootApartments_Helper_AggregateArgs = {
  distinct_on?: Maybe<Array<Apartments_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartments_Helper_Order_By>>;
  where?: Maybe<Apartments_Helper_Bool_Exp>;
};


export type Query_RootAppointmentsArgs = {
  distinct_on?: Maybe<Array<Appointments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointments_Order_By>>;
  where?: Maybe<Appointments_Bool_Exp>;
};


export type Query_RootAppointments_AggregateArgs = {
  distinct_on?: Maybe<Array<Appointments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointments_Order_By>>;
  where?: Maybe<Appointments_Bool_Exp>;
};


export type Query_RootAppointments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArrivalsArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


export type Query_RootArrivals_AggregateArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


export type Query_RootArrivals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBalances_ReportArgs = {
  distinct_on?: Maybe<Array<Balances_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balances_Report_Order_By>>;
  where?: Maybe<Balances_Report_Bool_Exp>;
};


export type Query_RootBalances_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Balances_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balances_Report_Order_By>>;
  where?: Maybe<Balances_Report_Bool_Exp>;
};


export type Query_RootBlogArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Query_RootBlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Query_RootBlog_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBlog_TypesArgs = {
  distinct_on?: Maybe<Array<Blog_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Types_Order_By>>;
  where?: Maybe<Blog_Types_Bool_Exp>;
};


export type Query_RootBlog_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Types_Order_By>>;
  where?: Maybe<Blog_Types_Bool_Exp>;
};


export type Query_RootBlog_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBuildingsArgs = {
  distinct_on?: Maybe<Array<Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Buildings_Order_By>>;
  where?: Maybe<Buildings_Bool_Exp>;
};


export type Query_RootBuildings_AggregateArgs = {
  distinct_on?: Maybe<Array<Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Buildings_Order_By>>;
  where?: Maybe<Buildings_Bool_Exp>;
};


export type Query_RootBuildings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCommon_Area_TypesArgs = {
  distinct_on?: Maybe<Array<Common_Area_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Area_Types_Order_By>>;
  where?: Maybe<Common_Area_Types_Bool_Exp>;
};


export type Query_RootCommon_Area_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Common_Area_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Area_Types_Order_By>>;
  where?: Maybe<Common_Area_Types_Bool_Exp>;
};


export type Query_RootCommon_Area_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCommon_AreasArgs = {
  distinct_on?: Maybe<Array<Common_Areas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Areas_Order_By>>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};


export type Query_RootCommon_Areas_AggregateArgs = {
  distinct_on?: Maybe<Array<Common_Areas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Areas_Order_By>>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};


export type Query_RootCommon_Areas_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContactArgs = {
  distinct_on?: Maybe<Array<Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Order_By>>;
  where?: Maybe<Contact_Bool_Exp>;
};


export type Query_RootContact_AddressesArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


export type Query_RootContact_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


export type Query_RootContact_Addresses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContact_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Order_By>>;
  where?: Maybe<Contact_Bool_Exp>;
};


export type Query_RootContact_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContact_EmailsArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


export type Query_RootContact_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


export type Query_RootContact_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContact_PhonesArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


export type Query_RootContact_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


export type Query_RootContact_Phones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCurrency_TypeArgs = {
  distinct_on?: Maybe<Array<Currency_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Type_Order_By>>;
  where?: Maybe<Currency_Type_Bool_Exp>;
};


export type Query_RootCurrency_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Currency_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Type_Order_By>>;
  where?: Maybe<Currency_Type_Bool_Exp>;
};


export type Query_RootCurrency_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootDishesArgs = {
  distinct_on?: Maybe<Array<Dishes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dishes_Order_By>>;
  where?: Maybe<Dishes_Bool_Exp>;
};


export type Query_RootDishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Dishes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dishes_Order_By>>;
  where?: Maybe<Dishes_Bool_Exp>;
};


export type Query_RootDishes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmailArgs = {
  distinct_on?: Maybe<Array<Email_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Order_By>>;
  where?: Maybe<Email_Bool_Exp>;
};


export type Query_RootEmail_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Order_By>>;
  where?: Maybe<Email_Bool_Exp>;
};


export type Query_RootEmail_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmployeesArgs = {
  distinct_on?: Maybe<Array<Employees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employees_Order_By>>;
  where?: Maybe<Employees_Bool_Exp>;
};


export type Query_RootEmployees_AggregateArgs = {
  distinct_on?: Maybe<Array<Employees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employees_Order_By>>;
  where?: Maybe<Employees_Bool_Exp>;
};


export type Query_RootEmployees_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootExchangeArgs = {
  distinct_on?: Maybe<Array<Exchange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exchange_Order_By>>;
  where?: Maybe<Exchange_Bool_Exp>;
};


export type Query_RootExchange_AggregateArgs = {
  distinct_on?: Maybe<Array<Exchange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exchange_Order_By>>;
  where?: Maybe<Exchange_Bool_Exp>;
};


export type Query_RootExchange_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootHistory_CommentsArgs = {
  distinct_on?: Maybe<Array<History_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<History_Comments_Order_By>>;
  where?: Maybe<History_Comments_Bool_Exp>;
};


export type Query_RootHistory_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<History_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<History_Comments_Order_By>>;
  where?: Maybe<History_Comments_Bool_Exp>;
};


export type Query_RootHistory_Comments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIngredient_DishArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


export type Query_RootIngredient_Dish_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


export type Query_RootIngredient_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIngredientsArgs = {
  distinct_on?: Maybe<Array<Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredients_Order_By>>;
  where?: Maybe<Ingredients_Bool_Exp>;
};


export type Query_RootIngredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredients_Order_By>>;
  where?: Maybe<Ingredients_Bool_Exp>;
};


export type Query_RootIngredients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLessorsArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


export type Query_RootLessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


export type Query_RootLessors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMenu_TypesArgs = {
  distinct_on?: Maybe<Array<Menu_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Menu_Types_Order_By>>;
  where?: Maybe<Menu_Types_Bool_Exp>;
};


export type Query_RootMenu_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Menu_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Menu_Types_Order_By>>;
  where?: Maybe<Menu_Types_Bool_Exp>;
};


export type Query_RootMenu_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMinutesArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


export type Query_RootMinutes_AggregateArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


export type Query_RootMinutes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMinutes_TypesArgs = {
  distinct_on?: Maybe<Array<Minutes_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Types_Order_By>>;
  where?: Maybe<Minutes_Types_Bool_Exp>;
};


export type Query_RootMinutes_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Minutes_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Types_Order_By>>;
  where?: Maybe<Minutes_Types_Bool_Exp>;
};


export type Query_RootMinutes_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootModifier_Group_DishArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


export type Query_RootModifier_Group_Dish_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


export type Query_RootModifier_Group_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootModifier_GroupsArgs = {
  distinct_on?: Maybe<Array<Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Groups_Order_By>>;
  where?: Maybe<Modifier_Groups_Bool_Exp>;
};


export type Query_RootModifier_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Groups_Order_By>>;
  where?: Maybe<Modifier_Groups_Bool_Exp>;
};


export type Query_RootModifier_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootModifiersArgs = {
  distinct_on?: Maybe<Array<Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifiers_Order_By>>;
  where?: Maybe<Modifiers_Bool_Exp>;
};


export type Query_RootModifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifiers_Order_By>>;
  where?: Maybe<Modifiers_Bool_Exp>;
};


export type Query_RootModifiers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMotionsArgs = {
  distinct_on?: Maybe<Array<Motions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Order_By>>;
  where?: Maybe<Motions_Bool_Exp>;
};


export type Query_RootMotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Motions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Order_By>>;
  where?: Maybe<Motions_Bool_Exp>;
};


export type Query_RootMotions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMotions_VoteArgs = {
  distinct_on?: Maybe<Array<Motions_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Vote_Order_By>>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};


export type Query_RootMotions_Vote_AggregateArgs = {
  distinct_on?: Maybe<Array<Motions_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Vote_Order_By>>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};


export type Query_RootMotions_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNew_OwnersArgs = {
  distinct_on?: Maybe<Array<New_Owners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<New_Owners_Order_By>>;
  where?: Maybe<New_Owners_Bool_Exp>;
};


export type Query_RootNew_Owners_AggregateArgs = {
  distinct_on?: Maybe<Array<New_Owners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<New_Owners_Order_By>>;
  where?: Maybe<New_Owners_Bool_Exp>;
};


export type Query_RootNew_Owners_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_DishArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


export type Query_RootOrder_Dish_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


export type Query_RootOrder_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Dish_IngredientsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


export type Query_RootOrder_Dish_Ingredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


export type Query_RootOrder_Dish_Ingredients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Dish_Modifier_GroupsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};


export type Query_RootOrder_Dish_Modifier_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};


export type Query_RootOrder_Dish_Modifier_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrder_Dish_ModifiersArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};


export type Query_RootOrder_Dish_Modifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};


export type Query_RootOrder_Dish_Modifiers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_ActionsArgs = {
  distinct_on?: Maybe<Array<Orders_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Actions_Order_By>>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};


export type Query_RootOrders_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Actions_Order_By>>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};


export type Query_RootOrders_Actions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPalapa_AccountArgs = {
  distinct_on?: Maybe<Array<Palapa_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Account_Order_By>>;
  where?: Maybe<Palapa_Account_Bool_Exp>;
};


export type Query_RootPalapa_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Palapa_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Account_Order_By>>;
  where?: Maybe<Palapa_Account_Bool_Exp>;
};


export type Query_RootPalapa_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPalapa_AccountsArgs = {
  distinct_on?: Maybe<Array<Palapa_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Accounts_Order_By>>;
  where?: Maybe<Palapa_Accounts_Bool_Exp>;
};


export type Query_RootPalapa_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Palapa_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Accounts_Order_By>>;
  where?: Maybe<Palapa_Accounts_Bool_Exp>;
};


export type Query_RootPalapa_ItemsArgs = {
  distinct_on?: Maybe<Array<Palapa_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Items_Order_By>>;
  where?: Maybe<Palapa_Items_Bool_Exp>;
};


export type Query_RootPalapa_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Palapa_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Items_Order_By>>;
  where?: Maybe<Palapa_Items_Bool_Exp>;
};


export type Query_RootPalapa_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPhonesArgs = {
  distinct_on?: Maybe<Array<Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phones_Order_By>>;
  where?: Maybe<Phones_Bool_Exp>;
};


export type Query_RootPhones_AggregateArgs = {
  distinct_on?: Maybe<Array<Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phones_Order_By>>;
  where?: Maybe<Phones_Bool_Exp>;
};


export type Query_RootPhones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPrint_TemplatesArgs = {
  distinct_on?: Maybe<Array<Print_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Templates_Order_By>>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};


export type Query_RootPrint_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Templates_Order_By>>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};


export type Query_RootPrint_Templates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPrint_TypesArgs = {
  distinct_on?: Maybe<Array<Print_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Types_Order_By>>;
  where?: Maybe<Print_Types_Bool_Exp>;
};


export type Query_RootPrint_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Types_Order_By>>;
  where?: Maybe<Print_Types_Bool_Exp>;
};


export type Query_RootPrint_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPrinter_LogsArgs = {
  distinct_on?: Maybe<Array<Printer_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printer_Logs_Order_By>>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};


export type Query_RootPrinter_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Printer_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printer_Logs_Order_By>>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};


export type Query_RootPrinter_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPrintersArgs = {
  distinct_on?: Maybe<Array<Printers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printers_Order_By>>;
  where?: Maybe<Printers_Bool_Exp>;
};


export type Query_RootPrinters_AggregateArgs = {
  distinct_on?: Maybe<Array<Printers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printers_Order_By>>;
  where?: Maybe<Printers_Bool_Exp>;
};


export type Query_RootPrinters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProperty_ManagersArgs = {
  distinct_on?: Maybe<Array<Property_Managers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Managers_Order_By>>;
  where?: Maybe<Property_Managers_Bool_Exp>;
};


export type Query_RootProperty_Managers_AggregateArgs = {
  distinct_on?: Maybe<Array<Property_Managers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Managers_Order_By>>;
  where?: Maybe<Property_Managers_Bool_Exp>;
};


export type Query_RootProperty_Managers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProvidersArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


export type Query_RootProviders_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


export type Query_RootProviders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProviders_RatingsArgs = {
  distinct_on?: Maybe<Array<Providers_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Ratings_Order_By>>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};


export type Query_RootProviders_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Ratings_Order_By>>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};


export type Query_RootProviders_Ratings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProviders_ServicesArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};


export type Query_RootProviders_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};


export type Query_RootProviders_Services_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRegisterUserArgs = {
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type Query_RootRentalArgs = {
  distinct_on?: Maybe<Array<Rental_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Order_By>>;
  where?: Maybe<Rental_Bool_Exp>;
};


export type Query_RootRental_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Order_By>>;
  where?: Maybe<Rental_Bool_Exp>;
};


export type Query_RootRental_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRules_And_RegulationsArgs = {
  distinct_on?: Maybe<Array<Rules_And_Regulations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rules_And_Regulations_Order_By>>;
  where?: Maybe<Rules_And_Regulations_Bool_Exp>;
};


export type Query_RootRules_And_Regulations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rules_And_Regulations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rules_And_Regulations_Order_By>>;
  where?: Maybe<Rules_And_Regulations_Bool_Exp>;
};


export type Query_RootRules_And_Regulations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootServicesArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


export type Query_RootServices_AggregateArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


export type Query_RootServices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatement_AccountArgs = {
  distinct_on?: Maybe<Array<Statement_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Statement_Account_Order_By>>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};


export type Query_RootStatement_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Statement_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Statement_Account_Order_By>>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};


export type Query_RootStatement_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubcategoriesArgs = {
  distinct_on?: Maybe<Array<Subcategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcategories_Order_By>>;
  where?: Maybe<Subcategories_Bool_Exp>;
};


export type Query_RootSubcategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Subcategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcategories_Order_By>>;
  where?: Maybe<Subcategories_Bool_Exp>;
};


export type Query_RootSubcategories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTablesArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


export type Query_RootTables_AggregateArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


export type Query_RootTables_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransaction_TypeArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Query_RootTransaction_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Query_RootTransaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_By_PkArgs = {
  transaction_id: Scalars['Int'];
};


export type Query_RootTransactions_HelperArgs = {
  distinct_on?: Maybe<Array<Transactions_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Helper_Order_By>>;
  where?: Maybe<Transactions_Helper_Bool_Exp>;
};


export type Query_RootTransactions_Helper_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Helper_Order_By>>;
  where?: Maybe<Transactions_Helper_Bool_Exp>;
};


export type Query_RootUnitsArgs = {
  distinct_on?: Maybe<Array<Units_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Units_Order_By>>;
  where?: Maybe<Units_Bool_Exp>;
};


export type Query_RootUnits_AggregateArgs = {
  distinct_on?: Maybe<Array<Units_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Units_Order_By>>;
  where?: Maybe<Units_Bool_Exp>;
};


export type Query_RootUnits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVisitsArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};


export type Query_RootVisits_AggregateArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};


export type Query_RootVisits_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "rental" */
export type Rental = {
  __typename?: 'rental';
  /** An object relationship */
  apartment: Apartment;
  apartment_id: Scalars['Int'];
  /** An array relationship */
  arrivals: Array<Arrivals>;
  /** An aggregate relationship */
  arrivals_aggregate: Arrivals_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['timestamptz']>;
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  people_number: Scalars['Int'];
  present: Scalars['Boolean'];
  statement_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "rental" */
export type RentalArrivalsArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


/** columns and relationships of "rental" */
export type RentalArrivals_AggregateArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};

/** aggregated selection of "rental" */
export type Rental_Aggregate = {
  __typename?: 'rental_aggregate';
  aggregate?: Maybe<Rental_Aggregate_Fields>;
  nodes: Array<Rental>;
};

/** aggregate fields of "rental" */
export type Rental_Aggregate_Fields = {
  __typename?: 'rental_aggregate_fields';
  avg?: Maybe<Rental_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rental_Max_Fields>;
  min?: Maybe<Rental_Min_Fields>;
  stddev?: Maybe<Rental_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Sum_Fields>;
  var_pop?: Maybe<Rental_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Var_Samp_Fields>;
  variance?: Maybe<Rental_Variance_Fields>;
};


/** aggregate fields of "rental" */
export type Rental_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rental_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rental" */
export type Rental_Aggregate_Order_By = {
  avg?: Maybe<Rental_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rental_Max_Order_By>;
  min?: Maybe<Rental_Min_Order_By>;
  stddev?: Maybe<Rental_Stddev_Order_By>;
  stddev_pop?: Maybe<Rental_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rental_Stddev_Samp_Order_By>;
  sum?: Maybe<Rental_Sum_Order_By>;
  var_pop?: Maybe<Rental_Var_Pop_Order_By>;
  var_samp?: Maybe<Rental_Var_Samp_Order_By>;
  variance?: Maybe<Rental_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental" */
export type Rental_Arr_Rel_Insert_Input = {
  data: Array<Rental_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Rental_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Avg_Fields = {
  __typename?: 'rental_avg_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rental" */
export type Rental_Avg_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental". All fields are combined with a logical 'AND'. */
export type Rental_Bool_Exp = {
  _and?: Maybe<Array<Rental_Bool_Exp>>;
  _not?: Maybe<Rental_Bool_Exp>;
  _or?: Maybe<Array<Rental_Bool_Exp>>;
  apartment?: Maybe<Apartment_Bool_Exp>;
  apartment_id?: Maybe<Int_Comparison_Exp>;
  arrivals?: Maybe<Arrivals_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  departure_date?: Maybe<Timestamptz_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  people_number?: Maybe<Int_Comparison_Exp>;
  present?: Maybe<Boolean_Comparison_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental" */
export enum Rental_Constraint {
  /** unique or primary key constraint */
  RentalPkey = 'rental_pkey'
}

/** input type for incrementing numeric columns in table "rental" */
export type Rental_Inc_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "rental" */
export type Rental_Insert_Input = {
  apartment?: Maybe<Apartment_Obj_Rel_Insert_Input>;
  apartment_id?: Maybe<Scalars['Int']>;
  arrivals?: Maybe<Arrivals_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  people_number?: Maybe<Scalars['Int']>;
  present?: Maybe<Scalars['Boolean']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rental_Max_Fields = {
  __typename?: 'rental_max_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  people_number?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "rental" */
export type Rental_Max_Order_By = {
  apartment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Min_Fields = {
  __typename?: 'rental_min_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  people_number?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "rental" */
export type Rental_Min_Order_By = {
  apartment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "rental" */
export type Rental_Mutation_Response = {
  __typename?: 'rental_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental>;
};

/** input type for inserting object relation for remote table "rental" */
export type Rental_Obj_Rel_Insert_Input = {
  data: Rental_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Rental_On_Conflict>;
};

/** on_conflict condition type for table "rental" */
export type Rental_On_Conflict = {
  constraint: Rental_Constraint;
  update_columns?: Array<Rental_Update_Column>;
  where?: Maybe<Rental_Bool_Exp>;
};

/** Ordering options when selecting data from "rental". */
export type Rental_Order_By = {
  apartment?: Maybe<Apartment_Order_By>;
  apartment_id?: Maybe<Order_By>;
  arrivals_aggregate?: Maybe<Arrivals_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  present?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: rental */
export type Rental_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "rental" */
export enum Rental_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PeopleNumber = 'people_number',
  /** column name */
  Present = 'present',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "rental" */
export type Rental_Set_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure_date?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  people_number?: Maybe<Scalars['Int']>;
  present?: Maybe<Scalars['Boolean']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Rental_Stddev_Fields = {
  __typename?: 'rental_stddev_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rental" */
export type Rental_Stddev_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Stddev_Pop_Fields = {
  __typename?: 'rental_stddev_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rental" */
export type Rental_Stddev_Pop_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Stddev_Samp_Fields = {
  __typename?: 'rental_stddev_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rental" */
export type Rental_Stddev_Samp_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rental_Sum_Fields = {
  __typename?: 'rental_sum_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  people_number?: Maybe<Scalars['Int']>;
  statement_account_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "rental" */
export type Rental_Sum_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** update columns of table "rental" */
export enum Rental_Update_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PeopleNumber = 'people_number',
  /** column name */
  Present = 'present',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Rental_Var_Pop_Fields = {
  __typename?: 'rental_var_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rental" */
export type Rental_Var_Pop_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Var_Samp_Fields = {
  __typename?: 'rental_var_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rental" */
export type Rental_Var_Samp_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Variance_Fields = {
  __typename?: 'rental_variance_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  people_number?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rental" */
export type Rental_Variance_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  people_number?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "rules_and_regulations" */
export type Rules_And_Regulations = {
  __typename?: 'rules_and_regulations';
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "rules_and_regulations" */
export type Rules_And_Regulations_Aggregate = {
  __typename?: 'rules_and_regulations_aggregate';
  aggregate?: Maybe<Rules_And_Regulations_Aggregate_Fields>;
  nodes: Array<Rules_And_Regulations>;
};

/** aggregate fields of "rules_and_regulations" */
export type Rules_And_Regulations_Aggregate_Fields = {
  __typename?: 'rules_and_regulations_aggregate_fields';
  avg?: Maybe<Rules_And_Regulations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rules_And_Regulations_Max_Fields>;
  min?: Maybe<Rules_And_Regulations_Min_Fields>;
  stddev?: Maybe<Rules_And_Regulations_Stddev_Fields>;
  stddev_pop?: Maybe<Rules_And_Regulations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rules_And_Regulations_Stddev_Samp_Fields>;
  sum?: Maybe<Rules_And_Regulations_Sum_Fields>;
  var_pop?: Maybe<Rules_And_Regulations_Var_Pop_Fields>;
  var_samp?: Maybe<Rules_And_Regulations_Var_Samp_Fields>;
  variance?: Maybe<Rules_And_Regulations_Variance_Fields>;
};


/** aggregate fields of "rules_and_regulations" */
export type Rules_And_Regulations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rules_And_Regulations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rules_And_Regulations_Avg_Fields = {
  __typename?: 'rules_and_regulations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rules_and_regulations". All fields are combined with a logical 'AND'. */
export type Rules_And_Regulations_Bool_Exp = {
  _and?: Maybe<Array<Rules_And_Regulations_Bool_Exp>>;
  _not?: Maybe<Rules_And_Regulations_Bool_Exp>;
  _or?: Maybe<Array<Rules_And_Regulations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rules_and_regulations" */
export enum Rules_And_Regulations_Constraint {
  /** unique or primary key constraint */
  RulesAndRegulationsPkey = 'rules_and_regulations_pkey'
}

/** input type for incrementing numeric columns in table "rules_and_regulations" */
export type Rules_And_Regulations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "rules_and_regulations" */
export type Rules_And_Regulations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rules_And_Regulations_Max_Fields = {
  __typename?: 'rules_and_regulations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Rules_And_Regulations_Min_Fields = {
  __typename?: 'rules_and_regulations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "rules_and_regulations" */
export type Rules_And_Regulations_Mutation_Response = {
  __typename?: 'rules_and_regulations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rules_And_Regulations>;
};

/** on_conflict condition type for table "rules_and_regulations" */
export type Rules_And_Regulations_On_Conflict = {
  constraint: Rules_And_Regulations_Constraint;
  update_columns?: Array<Rules_And_Regulations_Update_Column>;
  where?: Maybe<Rules_And_Regulations_Bool_Exp>;
};

/** Ordering options when selecting data from "rules_and_regulations". */
export type Rules_And_Regulations_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: rules_and_regulations */
export type Rules_And_Regulations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "rules_and_regulations" */
export enum Rules_And_Regulations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "rules_and_regulations" */
export type Rules_And_Regulations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Rules_And_Regulations_Stddev_Fields = {
  __typename?: 'rules_and_regulations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rules_And_Regulations_Stddev_Pop_Fields = {
  __typename?: 'rules_and_regulations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rules_And_Regulations_Stddev_Samp_Fields = {
  __typename?: 'rules_and_regulations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Rules_And_Regulations_Sum_Fields = {
  __typename?: 'rules_and_regulations_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "rules_and_regulations" */
export enum Rules_And_Regulations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Rules_And_Regulations_Var_Pop_Fields = {
  __typename?: 'rules_and_regulations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rules_And_Regulations_Var_Samp_Fields = {
  __typename?: 'rules_and_regulations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rules_And_Regulations_Variance_Fields = {
  __typename?: 'rules_and_regulations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "services" */
export type Services = {
  __typename?: 'services';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  providers_services: Array<Providers_Services>;
  /** An aggregate relationship */
  providers_services_aggregate: Providers_Services_Aggregate;
};


/** columns and relationships of "services" */
export type ServicesProviders_ServicesArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesProviders_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};

/** aggregated selection of "services" */
export type Services_Aggregate = {
  __typename?: 'services_aggregate';
  aggregate?: Maybe<Services_Aggregate_Fields>;
  nodes: Array<Services>;
};

/** aggregate fields of "services" */
export type Services_Aggregate_Fields = {
  __typename?: 'services_aggregate_fields';
  avg?: Maybe<Services_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Services_Max_Fields>;
  min?: Maybe<Services_Min_Fields>;
  stddev?: Maybe<Services_Stddev_Fields>;
  stddev_pop?: Maybe<Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Services_Stddev_Samp_Fields>;
  sum?: Maybe<Services_Sum_Fields>;
  var_pop?: Maybe<Services_Var_Pop_Fields>;
  var_samp?: Maybe<Services_Var_Samp_Fields>;
  variance?: Maybe<Services_Variance_Fields>;
};


/** aggregate fields of "services" */
export type Services_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Services_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Services_Avg_Fields = {
  __typename?: 'services_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "services". All fields are combined with a logical 'AND'. */
export type Services_Bool_Exp = {
  _and?: Maybe<Array<Services_Bool_Exp>>;
  _not?: Maybe<Services_Bool_Exp>;
  _or?: Maybe<Array<Services_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  providers_services?: Maybe<Providers_Services_Bool_Exp>;
};

/** unique or primary key constraints on table "services" */
export enum Services_Constraint {
  /** unique or primary key constraint */
  ServicesPkey = 'services_pkey'
}

/** input type for incrementing numeric columns in table "services" */
export type Services_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "services" */
export type Services_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  providers_services?: Maybe<Providers_Services_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Services_Max_Fields = {
  __typename?: 'services_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Services_Min_Fields = {
  __typename?: 'services_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "services" */
export type Services_Mutation_Response = {
  __typename?: 'services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services>;
};

/** input type for inserting object relation for remote table "services" */
export type Services_Obj_Rel_Insert_Input = {
  data: Services_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Services_On_Conflict>;
};

/** on_conflict condition type for table "services" */
export type Services_On_Conflict = {
  constraint: Services_Constraint;
  update_columns?: Array<Services_Update_Column>;
  where?: Maybe<Services_Bool_Exp>;
};

/** Ordering options when selecting data from "services". */
export type Services_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  providers_services_aggregate?: Maybe<Providers_Services_Aggregate_Order_By>;
};

/** primary key columns input for table: services */
export type Services_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "services" */
export enum Services_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "services" */
export type Services_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Services_Stddev_Fields = {
  __typename?: 'services_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Services_Stddev_Pop_Fields = {
  __typename?: 'services_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Services_Stddev_Samp_Fields = {
  __typename?: 'services_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Services_Sum_Fields = {
  __typename?: 'services_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "services" */
export enum Services_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Services_Var_Pop_Fields = {
  __typename?: 'services_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Services_Var_Samp_Fields = {
  __typename?: 'services_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Services_Variance_Fields = {
  __typename?: 'services_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statement_account" */
export type Statement_Account = {
  __typename?: 'statement_account';
  /** An array relationship */
  account_tickets: Array<Account_Tickets>;
  /** An aggregate relationship */
  account_tickets_aggregate: Account_Tickets_Aggregate;
  active: Scalars['Boolean'];
  /** An object relationship */
  apartment?: Maybe<Apartment>;
  apartment_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  open: Scalars['Boolean'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  owner: Scalars['Boolean'];
  pin?: Maybe<Scalars['String']>;
  pin_created: Scalars['Boolean'];
  total: Scalars['numeric'];
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: Transactions_Aggregate;
};


/** columns and relationships of "statement_account" */
export type Statement_AccountAccount_TicketsArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


/** columns and relationships of "statement_account" */
export type Statement_AccountAccount_Tickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


/** columns and relationships of "statement_account" */
export type Statement_AccountOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "statement_account" */
export type Statement_AccountOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "statement_account" */
export type Statement_AccountTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "statement_account" */
export type Statement_AccountTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};

/** aggregated selection of "statement_account" */
export type Statement_Account_Aggregate = {
  __typename?: 'statement_account_aggregate';
  aggregate?: Maybe<Statement_Account_Aggregate_Fields>;
  nodes: Array<Statement_Account>;
};

/** aggregate fields of "statement_account" */
export type Statement_Account_Aggregate_Fields = {
  __typename?: 'statement_account_aggregate_fields';
  avg?: Maybe<Statement_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statement_Account_Max_Fields>;
  min?: Maybe<Statement_Account_Min_Fields>;
  stddev?: Maybe<Statement_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Statement_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statement_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Statement_Account_Sum_Fields>;
  var_pop?: Maybe<Statement_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Statement_Account_Var_Samp_Fields>;
  variance?: Maybe<Statement_Account_Variance_Fields>;
};


/** aggregate fields of "statement_account" */
export type Statement_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Statement_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "statement_account" */
export type Statement_Account_Aggregate_Order_By = {
  avg?: Maybe<Statement_Account_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Statement_Account_Max_Order_By>;
  min?: Maybe<Statement_Account_Min_Order_By>;
  stddev?: Maybe<Statement_Account_Stddev_Order_By>;
  stddev_pop?: Maybe<Statement_Account_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Statement_Account_Stddev_Samp_Order_By>;
  sum?: Maybe<Statement_Account_Sum_Order_By>;
  var_pop?: Maybe<Statement_Account_Var_Pop_Order_By>;
  var_samp?: Maybe<Statement_Account_Var_Samp_Order_By>;
  variance?: Maybe<Statement_Account_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "statement_account" */
export type Statement_Account_Arr_Rel_Insert_Input = {
  data: Array<Statement_Account_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Statement_Account_On_Conflict>;
};

/** aggregate avg on columns */
export type Statement_Account_Avg_Fields = {
  __typename?: 'statement_account_avg_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "statement_account" */
export type Statement_Account_Avg_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "statement_account". All fields are combined with a logical 'AND'. */
export type Statement_Account_Bool_Exp = {
  _and?: Maybe<Array<Statement_Account_Bool_Exp>>;
  _not?: Maybe<Statement_Account_Bool_Exp>;
  _or?: Maybe<Array<Statement_Account_Bool_Exp>>;
  account_tickets?: Maybe<Account_Tickets_Bool_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  apartment?: Maybe<Apartment_Bool_Exp>;
  apartment_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  open?: Maybe<Boolean_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  owner?: Maybe<Boolean_Comparison_Exp>;
  pin?: Maybe<String_Comparison_Exp>;
  pin_created?: Maybe<Boolean_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  transactions?: Maybe<Transactions_Bool_Exp>;
};

/** unique or primary key constraints on table "statement_account" */
export enum Statement_Account_Constraint {
  /** unique or primary key constraint */
  StatementAccountPkey = 'statement_account_pkey'
}

/** input type for incrementing numeric columns in table "statement_account" */
export type Statement_Account_Inc_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "statement_account" */
export type Statement_Account_Insert_Input = {
  account_tickets?: Maybe<Account_Tickets_Arr_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  apartment?: Maybe<Apartment_Obj_Rel_Insert_Input>;
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  owner?: Maybe<Scalars['Boolean']>;
  pin?: Maybe<Scalars['String']>;
  pin_created?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['numeric']>;
  transactions?: Maybe<Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Statement_Account_Max_Fields = {
  __typename?: 'statement_account_max_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pin?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "statement_account" */
export type Statement_Account_Max_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pin?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Statement_Account_Min_Fields = {
  __typename?: 'statement_account_min_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  pin?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "statement_account" */
export type Statement_Account_Min_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pin?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** response of any mutation on the table "statement_account" */
export type Statement_Account_Mutation_Response = {
  __typename?: 'statement_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statement_Account>;
};

/** input type for inserting object relation for remote table "statement_account" */
export type Statement_Account_Obj_Rel_Insert_Input = {
  data: Statement_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Statement_Account_On_Conflict>;
};

/** on_conflict condition type for table "statement_account" */
export type Statement_Account_On_Conflict = {
  constraint: Statement_Account_Constraint;
  update_columns?: Array<Statement_Account_Update_Column>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "statement_account". */
export type Statement_Account_Order_By = {
  account_tickets_aggregate?: Maybe<Account_Tickets_Aggregate_Order_By>;
  active?: Maybe<Order_By>;
  apartment?: Maybe<Apartment_Order_By>;
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  open?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  owner?: Maybe<Order_By>;
  pin?: Maybe<Order_By>;
  pin_created?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  transactions_aggregate?: Maybe<Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: statement_account */
export type Statement_Account_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statement_account" */
export enum Statement_Account_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Open = 'open',
  /** column name */
  Owner = 'owner',
  /** column name */
  Pin = 'pin',
  /** column name */
  PinCreated = 'pin_created',
  /** column name */
  Total = 'total'
}

/** input type for updating data in table "statement_account" */
export type Statement_Account_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['Boolean']>;
  pin?: Maybe<Scalars['String']>;
  pin_created?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Statement_Account_Stddev_Fields = {
  __typename?: 'statement_account_stddev_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "statement_account" */
export type Statement_Account_Stddev_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Statement_Account_Stddev_Pop_Fields = {
  __typename?: 'statement_account_stddev_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "statement_account" */
export type Statement_Account_Stddev_Pop_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Statement_Account_Stddev_Samp_Fields = {
  __typename?: 'statement_account_stddev_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "statement_account" */
export type Statement_Account_Stddev_Samp_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Statement_Account_Sum_Fields = {
  __typename?: 'statement_account_sum_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "statement_account" */
export type Statement_Account_Sum_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** update columns of table "statement_account" */
export enum Statement_Account_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Open = 'open',
  /** column name */
  Owner = 'owner',
  /** column name */
  Pin = 'pin',
  /** column name */
  PinCreated = 'pin_created',
  /** column name */
  Total = 'total'
}

/** aggregate var_pop on columns */
export type Statement_Account_Var_Pop_Fields = {
  __typename?: 'statement_account_var_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "statement_account" */
export type Statement_Account_Var_Pop_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Statement_Account_Var_Samp_Fields = {
  __typename?: 'statement_account_var_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "statement_account" */
export type Statement_Account_Var_Samp_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Statement_Account_Variance_Fields = {
  __typename?: 'statement_account_variance_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "statement_account" */
export type Statement_Account_Variance_Order_By = {
  apartment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** columns and relationships of "subcategories" */
export type Subcategories = {
  __typename?: 'subcategories';
  /** An object relationship */
  category: Categories;
  category_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  disabled: Scalars['Boolean'];
  /** An array relationship */
  dishes: Array<Dishes>;
  /** An aggregate relationship */
  dishes_aggregate: Dishes_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "subcategories" */
export type SubcategoriesDishesArgs = {
  distinct_on?: Maybe<Array<Dishes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dishes_Order_By>>;
  where?: Maybe<Dishes_Bool_Exp>;
};


/** columns and relationships of "subcategories" */
export type SubcategoriesDishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Dishes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dishes_Order_By>>;
  where?: Maybe<Dishes_Bool_Exp>;
};

/** aggregated selection of "subcategories" */
export type Subcategories_Aggregate = {
  __typename?: 'subcategories_aggregate';
  aggregate?: Maybe<Subcategories_Aggregate_Fields>;
  nodes: Array<Subcategories>;
};

/** aggregate fields of "subcategories" */
export type Subcategories_Aggregate_Fields = {
  __typename?: 'subcategories_aggregate_fields';
  avg?: Maybe<Subcategories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcategories_Max_Fields>;
  min?: Maybe<Subcategories_Min_Fields>;
  stddev?: Maybe<Subcategories_Stddev_Fields>;
  stddev_pop?: Maybe<Subcategories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcategories_Stddev_Samp_Fields>;
  sum?: Maybe<Subcategories_Sum_Fields>;
  var_pop?: Maybe<Subcategories_Var_Pop_Fields>;
  var_samp?: Maybe<Subcategories_Var_Samp_Fields>;
  variance?: Maybe<Subcategories_Variance_Fields>;
};


/** aggregate fields of "subcategories" */
export type Subcategories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Subcategories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subcategories" */
export type Subcategories_Aggregate_Order_By = {
  avg?: Maybe<Subcategories_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Subcategories_Max_Order_By>;
  min?: Maybe<Subcategories_Min_Order_By>;
  stddev?: Maybe<Subcategories_Stddev_Order_By>;
  stddev_pop?: Maybe<Subcategories_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Subcategories_Stddev_Samp_Order_By>;
  sum?: Maybe<Subcategories_Sum_Order_By>;
  var_pop?: Maybe<Subcategories_Var_Pop_Order_By>;
  var_samp?: Maybe<Subcategories_Var_Samp_Order_By>;
  variance?: Maybe<Subcategories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subcategories" */
export type Subcategories_Arr_Rel_Insert_Input = {
  data: Array<Subcategories_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Subcategories_On_Conflict>;
};

/** aggregate avg on columns */
export type Subcategories_Avg_Fields = {
  __typename?: 'subcategories_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subcategories" */
export type Subcategories_Avg_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subcategories". All fields are combined with a logical 'AND'. */
export type Subcategories_Bool_Exp = {
  _and?: Maybe<Array<Subcategories_Bool_Exp>>;
  _not?: Maybe<Subcategories_Bool_Exp>;
  _or?: Maybe<Array<Subcategories_Bool_Exp>>;
  category?: Maybe<Categories_Bool_Exp>;
  category_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  dishes?: Maybe<Dishes_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subcategories" */
export enum Subcategories_Constraint {
  /** unique or primary key constraint */
  SubcategoriesPkey = 'subcategories_pkey'
}

/** input type for incrementing numeric columns in table "subcategories" */
export type Subcategories_Inc_Input = {
  category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "subcategories" */
export type Subcategories_Insert_Input = {
  category?: Maybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  dishes?: Maybe<Dishes_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Subcategories_Max_Fields = {
  __typename?: 'subcategories_max_fields';
  category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subcategories" */
export type Subcategories_Max_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Subcategories_Min_Fields = {
  __typename?: 'subcategories_min_fields';
  category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subcategories" */
export type Subcategories_Min_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "subcategories" */
export type Subcategories_Mutation_Response = {
  __typename?: 'subcategories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcategories>;
};

/** input type for inserting object relation for remote table "subcategories" */
export type Subcategories_Obj_Rel_Insert_Input = {
  data: Subcategories_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Subcategories_On_Conflict>;
};

/** on_conflict condition type for table "subcategories" */
export type Subcategories_On_Conflict = {
  constraint: Subcategories_Constraint;
  update_columns?: Array<Subcategories_Update_Column>;
  where?: Maybe<Subcategories_Bool_Exp>;
};

/** Ordering options when selecting data from "subcategories". */
export type Subcategories_Order_By = {
  category?: Maybe<Categories_Order_By>;
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  dishes_aggregate?: Maybe<Dishes_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: subcategories */
export type Subcategories_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "subcategories" */
export enum Subcategories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "subcategories" */
export type Subcategories_Set_Input = {
  category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Subcategories_Stddev_Fields = {
  __typename?: 'subcategories_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subcategories" */
export type Subcategories_Stddev_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subcategories_Stddev_Pop_Fields = {
  __typename?: 'subcategories_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subcategories" */
export type Subcategories_Stddev_Pop_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subcategories_Stddev_Samp_Fields = {
  __typename?: 'subcategories_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subcategories" */
export type Subcategories_Stddev_Samp_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Subcategories_Sum_Fields = {
  __typename?: 'subcategories_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "subcategories" */
export type Subcategories_Sum_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** update columns of table "subcategories" */
export enum Subcategories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Subcategories_Var_Pop_Fields = {
  __typename?: 'subcategories_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subcategories" */
export type Subcategories_Var_Pop_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subcategories_Var_Samp_Fields = {
  __typename?: 'subcategories_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subcategories" */
export type Subcategories_Var_Samp_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Subcategories_Variance_Fields = {
  __typename?: 'subcategories_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subcategories" */
export type Subcategories_Variance_Order_By = {
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  account_tickets: Array<Account_Tickets>;
  /** An aggregate relationship */
  account_tickets_aggregate: Account_Tickets_Aggregate;
  /** fetch data from the table: "account_tickets" using primary key columns */
  account_tickets_by_pk?: Maybe<Account_Tickets>;
  /** fetch data from the table: "activities" */
  activities: Array<Activities>;
  /** fetch aggregated fields from the table: "activities" */
  activities_aggregate: Activities_Aggregate;
  /** fetch data from the table: "activities" using primary key columns */
  activities_by_pk?: Maybe<Activities>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table: "admin_items" */
  admin_items: Array<Admin_Items>;
  /** fetch aggregated fields from the table: "admin_items" */
  admin_items_aggregate: Admin_Items_Aggregate;
  /** fetch data from the table: "admin_items" using primary key columns */
  admin_items_by_pk?: Maybe<Admin_Items>;
  /** fetch data from the table: "admin_record" */
  admin_record: Array<Admin_Record>;
  /** fetch aggregated fields from the table: "admin_record" */
  admin_record_aggregate: Admin_Record_Aggregate;
  /** fetch data from the table: "admin_record" using primary key columns */
  admin_record_by_pk?: Maybe<Admin_Record>;
  /** fetch data from the table: "apartment" */
  apartment: Array<Apartment>;
  /** fetch aggregated fields from the table: "apartment" */
  apartment_aggregate: Apartment_Aggregate;
  /** fetch data from the table: "apartment" using primary key columns */
  apartment_by_pk?: Maybe<Apartment>;
  /** fetch data from the table: "apartments_helper" */
  apartments_helper: Array<Apartments_Helper>;
  /** fetch aggregated fields from the table: "apartments_helper" */
  apartments_helper_aggregate: Apartments_Helper_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** fetch data from the table: "appointments" using primary key columns */
  appointments_by_pk?: Maybe<Appointments>;
  /** An array relationship */
  arrivals: Array<Arrivals>;
  /** An aggregate relationship */
  arrivals_aggregate: Arrivals_Aggregate;
  /** fetch data from the table: "arrivals" using primary key columns */
  arrivals_by_pk?: Maybe<Arrivals>;
  /** fetch data from the table: "balances_report" */
  balances_report: Array<Balances_Report>;
  /** fetch aggregated fields from the table: "balances_report" */
  balances_report_aggregate: Balances_Report_Aggregate;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** fetch data from the table: "blog_types" */
  blog_types: Array<Blog_Types>;
  /** fetch aggregated fields from the table: "blog_types" */
  blog_types_aggregate: Blog_Types_Aggregate;
  /** fetch data from the table: "blog_types" using primary key columns */
  blog_types_by_pk?: Maybe<Blog_Types>;
  /** fetch data from the table: "buildings" */
  buildings: Array<Buildings>;
  /** fetch aggregated fields from the table: "buildings" */
  buildings_aggregate: Buildings_Aggregate;
  /** fetch data from the table: "buildings" using primary key columns */
  buildings_by_pk?: Maybe<Buildings>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table: "common_area_types" */
  common_area_types: Array<Common_Area_Types>;
  /** fetch aggregated fields from the table: "common_area_types" */
  common_area_types_aggregate: Common_Area_Types_Aggregate;
  /** fetch data from the table: "common_area_types" using primary key columns */
  common_area_types_by_pk?: Maybe<Common_Area_Types>;
  /** An array relationship */
  common_areas: Array<Common_Areas>;
  /** An aggregate relationship */
  common_areas_aggregate: Common_Areas_Aggregate;
  /** fetch data from the table: "common_areas" using primary key columns */
  common_areas_by_pk?: Maybe<Common_Areas>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** An array relationship */
  contact_addresses: Array<Contact_Addresses>;
  /** An aggregate relationship */
  contact_addresses_aggregate: Contact_Addresses_Aggregate;
  /** fetch data from the table: "contact_addresses" using primary key columns */
  contact_addresses_by_pk?: Maybe<Contact_Addresses>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** An array relationship */
  contact_emails: Array<Contact_Emails>;
  /** An aggregate relationship */
  contact_emails_aggregate: Contact_Emails_Aggregate;
  /** fetch data from the table: "contact_emails" using primary key columns */
  contact_emails_by_pk?: Maybe<Contact_Emails>;
  /** An array relationship */
  contact_phones: Array<Contact_Phones>;
  /** An aggregate relationship */
  contact_phones_aggregate: Contact_Phones_Aggregate;
  /** fetch data from the table: "contact_phones" using primary key columns */
  contact_phones_by_pk?: Maybe<Contact_Phones>;
  /** fetch data from the table: "currency_type" */
  currency_type: Array<Currency_Type>;
  /** fetch aggregated fields from the table: "currency_type" */
  currency_type_aggregate: Currency_Type_Aggregate;
  /** fetch data from the table: "currency_type" using primary key columns */
  currency_type_by_pk?: Maybe<Currency_Type>;
  /** An array relationship */
  dishes: Array<Dishes>;
  /** An aggregate relationship */
  dishes_aggregate: Dishes_Aggregate;
  /** fetch data from the table: "dishes" using primary key columns */
  dishes_by_pk?: Maybe<Dishes>;
  /** fetch data from the table: "email" */
  email: Array<Email>;
  /** fetch aggregated fields from the table: "email" */
  email_aggregate: Email_Aggregate;
  /** fetch data from the table: "email" using primary key columns */
  email_by_pk?: Maybe<Email>;
  /** fetch data from the table: "employees" */
  employees: Array<Employees>;
  /** fetch aggregated fields from the table: "employees" */
  employees_aggregate: Employees_Aggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** fetch data from the table: "exchange" */
  exchange: Array<Exchange>;
  /** fetch aggregated fields from the table: "exchange" */
  exchange_aggregate: Exchange_Aggregate;
  /** fetch data from the table: "exchange" using primary key columns */
  exchange_by_pk?: Maybe<Exchange>;
  /** An array relationship */
  history_comments: Array<History_Comments>;
  /** An aggregate relationship */
  history_comments_aggregate: History_Comments_Aggregate;
  /** fetch data from the table: "history_comments" using primary key columns */
  history_comments_by_pk?: Maybe<History_Comments>;
  /** fetch data from the table: "ingredient_dish" */
  ingredient_dish: Array<Ingredient_Dish>;
  /** fetch aggregated fields from the table: "ingredient_dish" */
  ingredient_dish_aggregate: Ingredient_Dish_Aggregate;
  /** fetch data from the table: "ingredient_dish" using primary key columns */
  ingredient_dish_by_pk?: Maybe<Ingredient_Dish>;
  /** An array relationship */
  ingredients: Array<Ingredients>;
  /** An aggregate relationship */
  ingredients_aggregate: Ingredients_Aggregate;
  /** fetch data from the table: "ingredients" using primary key columns */
  ingredients_by_pk?: Maybe<Ingredients>;
  /** An array relationship */
  lessors: Array<Lessors>;
  /** An aggregate relationship */
  lessors_aggregate: Lessors_Aggregate;
  /** fetch data from the table: "lessors" using primary key columns */
  lessors_by_pk?: Maybe<Lessors>;
  /** fetch data from the table: "menu_types" */
  menu_types: Array<Menu_Types>;
  /** fetch aggregated fields from the table: "menu_types" */
  menu_types_aggregate: Menu_Types_Aggregate;
  /** fetch data from the table: "menu_types" using primary key columns */
  menu_types_by_pk?: Maybe<Menu_Types>;
  /** An array relationship */
  minutes: Array<Minutes>;
  /** An aggregate relationship */
  minutes_aggregate: Minutes_Aggregate;
  /** fetch data from the table: "minutes" using primary key columns */
  minutes_by_pk?: Maybe<Minutes>;
  /** fetch data from the table: "minutes_types" */
  minutes_types: Array<Minutes_Types>;
  /** fetch aggregated fields from the table: "minutes_types" */
  minutes_types_aggregate: Minutes_Types_Aggregate;
  /** fetch data from the table: "minutes_types" using primary key columns */
  minutes_types_by_pk?: Maybe<Minutes_Types>;
  /** fetch data from the table: "modifier_group_dish" */
  modifier_group_dish: Array<Modifier_Group_Dish>;
  /** fetch aggregated fields from the table: "modifier_group_dish" */
  modifier_group_dish_aggregate: Modifier_Group_Dish_Aggregate;
  /** fetch data from the table: "modifier_group_dish" using primary key columns */
  modifier_group_dish_by_pk?: Maybe<Modifier_Group_Dish>;
  /** fetch data from the table: "modifier_groups" */
  modifier_groups: Array<Modifier_Groups>;
  /** fetch aggregated fields from the table: "modifier_groups" */
  modifier_groups_aggregate: Modifier_Groups_Aggregate;
  /** fetch data from the table: "modifier_groups" using primary key columns */
  modifier_groups_by_pk?: Maybe<Modifier_Groups>;
  /** An array relationship */
  modifiers: Array<Modifiers>;
  /** An aggregate relationship */
  modifiers_aggregate: Modifiers_Aggregate;
  /** fetch data from the table: "modifiers" using primary key columns */
  modifiers_by_pk?: Maybe<Modifiers>;
  /** fetch data from the table: "motions" */
  motions: Array<Motions>;
  /** fetch aggregated fields from the table: "motions" */
  motions_aggregate: Motions_Aggregate;
  /** fetch data from the table: "motions" using primary key columns */
  motions_by_pk?: Maybe<Motions>;
  /** fetch data from the table: "motions_vote" */
  motions_vote: Array<Motions_Vote>;
  /** fetch aggregated fields from the table: "motions_vote" */
  motions_vote_aggregate: Motions_Vote_Aggregate;
  /** fetch data from the table: "motions_vote" using primary key columns */
  motions_vote_by_pk?: Maybe<Motions_Vote>;
  /** An array relationship */
  new_owners: Array<New_Owners>;
  /** An aggregate relationship */
  new_owners_aggregate: New_Owners_Aggregate;
  /** fetch data from the table: "new_owners" using primary key columns */
  new_owners_by_pk?: Maybe<New_Owners>;
  /** fetch data from the table: "order_dish" */
  order_dish: Array<Order_Dish>;
  /** fetch aggregated fields from the table: "order_dish" */
  order_dish_aggregate: Order_Dish_Aggregate;
  /** fetch data from the table: "order_dish" using primary key columns */
  order_dish_by_pk?: Maybe<Order_Dish>;
  /** An array relationship */
  order_dish_ingredients: Array<Order_Dish_Ingredients>;
  /** An aggregate relationship */
  order_dish_ingredients_aggregate: Order_Dish_Ingredients_Aggregate;
  /** fetch data from the table: "order_dish_ingredients" using primary key columns */
  order_dish_ingredients_by_pk?: Maybe<Order_Dish_Ingredients>;
  /** An array relationship */
  order_dish_modifier_groups: Array<Order_Dish_Modifier_Groups>;
  /** An aggregate relationship */
  order_dish_modifier_groups_aggregate: Order_Dish_Modifier_Groups_Aggregate;
  /** fetch data from the table: "order_dish_modifier_groups" using primary key columns */
  order_dish_modifier_groups_by_pk?: Maybe<Order_Dish_Modifier_Groups>;
  /** An array relationship */
  order_dish_modifiers: Array<Order_Dish_Modifiers>;
  /** An aggregate relationship */
  order_dish_modifiers_aggregate: Order_Dish_Modifiers_Aggregate;
  /** fetch data from the table: "order_dish_modifiers" using primary key columns */
  order_dish_modifiers_by_pk?: Maybe<Order_Dish_Modifiers>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An array relationship */
  orders_actions: Array<Orders_Actions>;
  /** An aggregate relationship */
  orders_actions_aggregate: Orders_Actions_Aggregate;
  /** fetch data from the table: "orders_actions" using primary key columns */
  orders_actions_by_pk?: Maybe<Orders_Actions>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "palapa_account" */
  palapa_account: Array<Palapa_Account>;
  /** fetch aggregated fields from the table: "palapa_account" */
  palapa_account_aggregate: Palapa_Account_Aggregate;
  /** fetch data from the table: "palapa_account" using primary key columns */
  palapa_account_by_pk?: Maybe<Palapa_Account>;
  /** fetch data from the table: "palapa_accounts" */
  palapa_accounts: Array<Palapa_Accounts>;
  /** fetch aggregated fields from the table: "palapa_accounts" */
  palapa_accounts_aggregate: Palapa_Accounts_Aggregate;
  /** fetch data from the table: "palapa_items" */
  palapa_items: Array<Palapa_Items>;
  /** fetch aggregated fields from the table: "palapa_items" */
  palapa_items_aggregate: Palapa_Items_Aggregate;
  /** fetch data from the table: "palapa_items" using primary key columns */
  palapa_items_by_pk?: Maybe<Palapa_Items>;
  /** fetch data from the table: "phones" */
  phones: Array<Phones>;
  /** fetch aggregated fields from the table: "phones" */
  phones_aggregate: Phones_Aggregate;
  /** fetch data from the table: "phones" using primary key columns */
  phones_by_pk?: Maybe<Phones>;
  /** An array relationship */
  print_templates: Array<Print_Templates>;
  /** An aggregate relationship */
  print_templates_aggregate: Print_Templates_Aggregate;
  /** fetch data from the table: "print_templates" using primary key columns */
  print_templates_by_pk?: Maybe<Print_Templates>;
  /** An array relationship */
  print_types: Array<Print_Types>;
  /** An aggregate relationship */
  print_types_aggregate: Print_Types_Aggregate;
  /** fetch data from the table: "print_types" using primary key columns */
  print_types_by_pk?: Maybe<Print_Types>;
  /** An array relationship */
  printer_logs: Array<Printer_Logs>;
  /** An aggregate relationship */
  printer_logs_aggregate: Printer_Logs_Aggregate;
  /** fetch data from the table: "printer_logs" using primary key columns */
  printer_logs_by_pk?: Maybe<Printer_Logs>;
  /** fetch data from the table: "printers" */
  printers: Array<Printers>;
  /** fetch aggregated fields from the table: "printers" */
  printers_aggregate: Printers_Aggregate;
  /** fetch data from the table: "printers" using primary key columns */
  printers_by_pk?: Maybe<Printers>;
  /** fetch data from the table: "property_managers" */
  property_managers: Array<Property_Managers>;
  /** fetch aggregated fields from the table: "property_managers" */
  property_managers_aggregate: Property_Managers_Aggregate;
  /** fetch data from the table: "property_managers" using primary key columns */
  property_managers_by_pk?: Maybe<Property_Managers>;
  /** fetch data from the table: "providers" */
  providers: Array<Providers>;
  /** fetch aggregated fields from the table: "providers" */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** An array relationship */
  providers_ratings: Array<Providers_Ratings>;
  /** An aggregate relationship */
  providers_ratings_aggregate: Providers_Ratings_Aggregate;
  /** fetch data from the table: "providers_ratings" using primary key columns */
  providers_ratings_by_pk?: Maybe<Providers_Ratings>;
  /** An array relationship */
  providers_services: Array<Providers_Services>;
  /** An aggregate relationship */
  providers_services_aggregate: Providers_Services_Aggregate;
  /** fetch data from the table: "providers_services" using primary key columns */
  providers_services_by_pk?: Maybe<Providers_Services>;
  /** fetch data from the table: "rental" */
  rental: Array<Rental>;
  /** fetch aggregated fields from the table: "rental" */
  rental_aggregate: Rental_Aggregate;
  /** fetch data from the table: "rental" using primary key columns */
  rental_by_pk?: Maybe<Rental>;
  /** fetch data from the table: "rules_and_regulations" */
  rules_and_regulations: Array<Rules_And_Regulations>;
  /** fetch aggregated fields from the table: "rules_and_regulations" */
  rules_and_regulations_aggregate: Rules_And_Regulations_Aggregate;
  /** fetch data from the table: "rules_and_regulations" using primary key columns */
  rules_and_regulations_by_pk?: Maybe<Rules_And_Regulations>;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "statement_account" */
  statement_account: Array<Statement_Account>;
  /** fetch aggregated fields from the table: "statement_account" */
  statement_account_aggregate: Statement_Account_Aggregate;
  /** fetch data from the table: "statement_account" using primary key columns */
  statement_account_by_pk?: Maybe<Statement_Account>;
  /** An array relationship */
  subcategories: Array<Subcategories>;
  /** An aggregate relationship */
  subcategories_aggregate: Subcategories_Aggregate;
  /** fetch data from the table: "subcategories" using primary key columns */
  subcategories_by_pk?: Maybe<Subcategories>;
  /** fetch data from the table: "tables" */
  tables: Array<Tables>;
  /** fetch aggregated fields from the table: "tables" */
  tables_aggregate: Tables_Aggregate;
  /** fetch data from the table: "tables" using primary key columns */
  tables_by_pk?: Maybe<Tables>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<Transaction_Type>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: Transaction_Type_Aggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "transactions_helper" */
  transactions_helper: Array<Transactions_Helper>;
  /** fetch aggregated fields from the table: "transactions_helper" */
  transactions_helper_aggregate: Transactions_Helper_Aggregate;
  /** fetch data from the table: "units" */
  units: Array<Units>;
  /** fetch aggregated fields from the table: "units" */
  units_aggregate: Units_Aggregate;
  /** fetch data from the table: "units" using primary key columns */
  units_by_pk?: Maybe<Units>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
  /** fetch data from the table: "visits" using primary key columns */
  visits_by_pk?: Maybe<Visits>;
};


export type Subscription_RootAccount_TicketsArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


export type Subscription_RootAccount_Tickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


export type Subscription_RootAccount_Tickets_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootActivitiesArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Subscription_RootActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Activities_Order_By>>;
  where?: Maybe<Activities_Bool_Exp>;
};


export type Subscription_RootActivities_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAddressesArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Addresses_Order_By>>;
  where?: Maybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAdmin_ItemsArgs = {
  distinct_on?: Maybe<Array<Admin_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Items_Order_By>>;
  where?: Maybe<Admin_Items_Bool_Exp>;
};


export type Subscription_RootAdmin_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Items_Order_By>>;
  where?: Maybe<Admin_Items_Bool_Exp>;
};


export type Subscription_RootAdmin_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAdmin_RecordArgs = {
  distinct_on?: Maybe<Array<Admin_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Record_Order_By>>;
  where?: Maybe<Admin_Record_Bool_Exp>;
};


export type Subscription_RootAdmin_Record_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Record_Order_By>>;
  where?: Maybe<Admin_Record_Bool_Exp>;
};


export type Subscription_RootAdmin_Record_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootApartmentArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


export type Subscription_RootApartment_AggregateArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


export type Subscription_RootApartment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootApartments_HelperArgs = {
  distinct_on?: Maybe<Array<Apartments_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartments_Helper_Order_By>>;
  where?: Maybe<Apartments_Helper_Bool_Exp>;
};


export type Subscription_RootApartments_Helper_AggregateArgs = {
  distinct_on?: Maybe<Array<Apartments_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartments_Helper_Order_By>>;
  where?: Maybe<Apartments_Helper_Bool_Exp>;
};


export type Subscription_RootAppointmentsArgs = {
  distinct_on?: Maybe<Array<Appointments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointments_Order_By>>;
  where?: Maybe<Appointments_Bool_Exp>;
};


export type Subscription_RootAppointments_AggregateArgs = {
  distinct_on?: Maybe<Array<Appointments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Appointments_Order_By>>;
  where?: Maybe<Appointments_Bool_Exp>;
};


export type Subscription_RootAppointments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArrivalsArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


export type Subscription_RootArrivals_AggregateArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


export type Subscription_RootArrivals_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBalances_ReportArgs = {
  distinct_on?: Maybe<Array<Balances_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balances_Report_Order_By>>;
  where?: Maybe<Balances_Report_Bool_Exp>;
};


export type Subscription_RootBalances_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Balances_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Balances_Report_Order_By>>;
  where?: Maybe<Balances_Report_Bool_Exp>;
};


export type Subscription_RootBlogArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Subscription_RootBlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Subscription_RootBlog_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBlog_TypesArgs = {
  distinct_on?: Maybe<Array<Blog_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Types_Order_By>>;
  where?: Maybe<Blog_Types_Bool_Exp>;
};


export type Subscription_RootBlog_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Types_Order_By>>;
  where?: Maybe<Blog_Types_Bool_Exp>;
};


export type Subscription_RootBlog_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBuildingsArgs = {
  distinct_on?: Maybe<Array<Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Buildings_Order_By>>;
  where?: Maybe<Buildings_Bool_Exp>;
};


export type Subscription_RootBuildings_AggregateArgs = {
  distinct_on?: Maybe<Array<Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Buildings_Order_By>>;
  where?: Maybe<Buildings_Bool_Exp>;
};


export type Subscription_RootBuildings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCommon_Area_TypesArgs = {
  distinct_on?: Maybe<Array<Common_Area_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Area_Types_Order_By>>;
  where?: Maybe<Common_Area_Types_Bool_Exp>;
};


export type Subscription_RootCommon_Area_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Common_Area_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Area_Types_Order_By>>;
  where?: Maybe<Common_Area_Types_Bool_Exp>;
};


export type Subscription_RootCommon_Area_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCommon_AreasArgs = {
  distinct_on?: Maybe<Array<Common_Areas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Areas_Order_By>>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};


export type Subscription_RootCommon_Areas_AggregateArgs = {
  distinct_on?: Maybe<Array<Common_Areas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Common_Areas_Order_By>>;
  where?: Maybe<Common_Areas_Bool_Exp>;
};


export type Subscription_RootCommon_Areas_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContactArgs = {
  distinct_on?: Maybe<Array<Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Order_By>>;
  where?: Maybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_AddressesArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


export type Subscription_RootContact_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Addresses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Addresses_Order_By>>;
  where?: Maybe<Contact_Addresses_Bool_Exp>;
};


export type Subscription_RootContact_Addresses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContact_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Order_By>>;
  where?: Maybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContact_EmailsArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


export type Subscription_RootContact_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Emails_Order_By>>;
  where?: Maybe<Contact_Emails_Bool_Exp>;
};


export type Subscription_RootContact_Emails_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContact_PhonesArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


export type Subscription_RootContact_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Phones_Order_By>>;
  where?: Maybe<Contact_Phones_Bool_Exp>;
};


export type Subscription_RootContact_Phones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCurrency_TypeArgs = {
  distinct_on?: Maybe<Array<Currency_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Type_Order_By>>;
  where?: Maybe<Currency_Type_Bool_Exp>;
};


export type Subscription_RootCurrency_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Currency_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Type_Order_By>>;
  where?: Maybe<Currency_Type_Bool_Exp>;
};


export type Subscription_RootCurrency_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDishesArgs = {
  distinct_on?: Maybe<Array<Dishes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dishes_Order_By>>;
  where?: Maybe<Dishes_Bool_Exp>;
};


export type Subscription_RootDishes_AggregateArgs = {
  distinct_on?: Maybe<Array<Dishes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dishes_Order_By>>;
  where?: Maybe<Dishes_Bool_Exp>;
};


export type Subscription_RootDishes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmailArgs = {
  distinct_on?: Maybe<Array<Email_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Order_By>>;
  where?: Maybe<Email_Bool_Exp>;
};


export type Subscription_RootEmail_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Order_By>>;
  where?: Maybe<Email_Bool_Exp>;
};


export type Subscription_RootEmail_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmployeesArgs = {
  distinct_on?: Maybe<Array<Employees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employees_Order_By>>;
  where?: Maybe<Employees_Bool_Exp>;
};


export type Subscription_RootEmployees_AggregateArgs = {
  distinct_on?: Maybe<Array<Employees_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Employees_Order_By>>;
  where?: Maybe<Employees_Bool_Exp>;
};


export type Subscription_RootEmployees_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootExchangeArgs = {
  distinct_on?: Maybe<Array<Exchange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exchange_Order_By>>;
  where?: Maybe<Exchange_Bool_Exp>;
};


export type Subscription_RootExchange_AggregateArgs = {
  distinct_on?: Maybe<Array<Exchange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Exchange_Order_By>>;
  where?: Maybe<Exchange_Bool_Exp>;
};


export type Subscription_RootExchange_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootHistory_CommentsArgs = {
  distinct_on?: Maybe<Array<History_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<History_Comments_Order_By>>;
  where?: Maybe<History_Comments_Bool_Exp>;
};


export type Subscription_RootHistory_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<History_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<History_Comments_Order_By>>;
  where?: Maybe<History_Comments_Bool_Exp>;
};


export type Subscription_RootHistory_Comments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIngredient_DishArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


export type Subscription_RootIngredient_Dish_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredient_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredient_Dish_Order_By>>;
  where?: Maybe<Ingredient_Dish_Bool_Exp>;
};


export type Subscription_RootIngredient_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIngredientsArgs = {
  distinct_on?: Maybe<Array<Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredients_Order_By>>;
  where?: Maybe<Ingredients_Bool_Exp>;
};


export type Subscription_RootIngredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredients_Order_By>>;
  where?: Maybe<Ingredients_Bool_Exp>;
};


export type Subscription_RootIngredients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLessorsArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


export type Subscription_RootLessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


export type Subscription_RootLessors_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMenu_TypesArgs = {
  distinct_on?: Maybe<Array<Menu_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Menu_Types_Order_By>>;
  where?: Maybe<Menu_Types_Bool_Exp>;
};


export type Subscription_RootMenu_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Menu_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Menu_Types_Order_By>>;
  where?: Maybe<Menu_Types_Bool_Exp>;
};


export type Subscription_RootMenu_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMinutesArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


export type Subscription_RootMinutes_AggregateArgs = {
  distinct_on?: Maybe<Array<Minutes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Order_By>>;
  where?: Maybe<Minutes_Bool_Exp>;
};


export type Subscription_RootMinutes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMinutes_TypesArgs = {
  distinct_on?: Maybe<Array<Minutes_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Types_Order_By>>;
  where?: Maybe<Minutes_Types_Bool_Exp>;
};


export type Subscription_RootMinutes_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Minutes_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Minutes_Types_Order_By>>;
  where?: Maybe<Minutes_Types_Bool_Exp>;
};


export type Subscription_RootMinutes_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootModifier_Group_DishArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


export type Subscription_RootModifier_Group_Dish_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifier_Group_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Group_Dish_Order_By>>;
  where?: Maybe<Modifier_Group_Dish_Bool_Exp>;
};


export type Subscription_RootModifier_Group_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootModifier_GroupsArgs = {
  distinct_on?: Maybe<Array<Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Groups_Order_By>>;
  where?: Maybe<Modifier_Groups_Bool_Exp>;
};


export type Subscription_RootModifier_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifier_Groups_Order_By>>;
  where?: Maybe<Modifier_Groups_Bool_Exp>;
};


export type Subscription_RootModifier_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootModifiersArgs = {
  distinct_on?: Maybe<Array<Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifiers_Order_By>>;
  where?: Maybe<Modifiers_Bool_Exp>;
};


export type Subscription_RootModifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Modifiers_Order_By>>;
  where?: Maybe<Modifiers_Bool_Exp>;
};


export type Subscription_RootModifiers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMotionsArgs = {
  distinct_on?: Maybe<Array<Motions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Order_By>>;
  where?: Maybe<Motions_Bool_Exp>;
};


export type Subscription_RootMotions_AggregateArgs = {
  distinct_on?: Maybe<Array<Motions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Order_By>>;
  where?: Maybe<Motions_Bool_Exp>;
};


export type Subscription_RootMotions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMotions_VoteArgs = {
  distinct_on?: Maybe<Array<Motions_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Vote_Order_By>>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};


export type Subscription_RootMotions_Vote_AggregateArgs = {
  distinct_on?: Maybe<Array<Motions_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Vote_Order_By>>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};


export type Subscription_RootMotions_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNew_OwnersArgs = {
  distinct_on?: Maybe<Array<New_Owners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<New_Owners_Order_By>>;
  where?: Maybe<New_Owners_Bool_Exp>;
};


export type Subscription_RootNew_Owners_AggregateArgs = {
  distinct_on?: Maybe<Array<New_Owners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<New_Owners_Order_By>>;
  where?: Maybe<New_Owners_Bool_Exp>;
};


export type Subscription_RootNew_Owners_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_DishArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Order_By>>;
  where?: Maybe<Order_Dish_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Dish_IngredientsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_Ingredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Ingredients_Order_By>>;
  where?: Maybe<Order_Dish_Ingredients_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_Ingredients_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Dish_Modifier_GroupsArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_Modifier_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifier_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifier_Groups_Order_By>>;
  where?: Maybe<Order_Dish_Modifier_Groups_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_Modifier_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrder_Dish_ModifiersArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_Modifiers_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Dish_Modifiers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Dish_Modifiers_Order_By>>;
  where?: Maybe<Order_Dish_Modifiers_Bool_Exp>;
};


export type Subscription_RootOrder_Dish_Modifiers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_ActionsArgs = {
  distinct_on?: Maybe<Array<Orders_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Actions_Order_By>>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};


export type Subscription_RootOrders_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Actions_Order_By>>;
  where?: Maybe<Orders_Actions_Bool_Exp>;
};


export type Subscription_RootOrders_Actions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPalapa_AccountArgs = {
  distinct_on?: Maybe<Array<Palapa_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Account_Order_By>>;
  where?: Maybe<Palapa_Account_Bool_Exp>;
};


export type Subscription_RootPalapa_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Palapa_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Account_Order_By>>;
  where?: Maybe<Palapa_Account_Bool_Exp>;
};


export type Subscription_RootPalapa_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPalapa_AccountsArgs = {
  distinct_on?: Maybe<Array<Palapa_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Accounts_Order_By>>;
  where?: Maybe<Palapa_Accounts_Bool_Exp>;
};


export type Subscription_RootPalapa_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Palapa_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Accounts_Order_By>>;
  where?: Maybe<Palapa_Accounts_Bool_Exp>;
};


export type Subscription_RootPalapa_ItemsArgs = {
  distinct_on?: Maybe<Array<Palapa_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Items_Order_By>>;
  where?: Maybe<Palapa_Items_Bool_Exp>;
};


export type Subscription_RootPalapa_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Palapa_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Palapa_Items_Order_By>>;
  where?: Maybe<Palapa_Items_Bool_Exp>;
};


export type Subscription_RootPalapa_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPhonesArgs = {
  distinct_on?: Maybe<Array<Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phones_Order_By>>;
  where?: Maybe<Phones_Bool_Exp>;
};


export type Subscription_RootPhones_AggregateArgs = {
  distinct_on?: Maybe<Array<Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phones_Order_By>>;
  where?: Maybe<Phones_Bool_Exp>;
};


export type Subscription_RootPhones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPrint_TemplatesArgs = {
  distinct_on?: Maybe<Array<Print_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Templates_Order_By>>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};


export type Subscription_RootPrint_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Templates_Order_By>>;
  where?: Maybe<Print_Templates_Bool_Exp>;
};


export type Subscription_RootPrint_Templates_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPrint_TypesArgs = {
  distinct_on?: Maybe<Array<Print_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Types_Order_By>>;
  where?: Maybe<Print_Types_Bool_Exp>;
};


export type Subscription_RootPrint_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Types_Order_By>>;
  where?: Maybe<Print_Types_Bool_Exp>;
};


export type Subscription_RootPrint_Types_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPrinter_LogsArgs = {
  distinct_on?: Maybe<Array<Printer_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printer_Logs_Order_By>>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};


export type Subscription_RootPrinter_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Printer_Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printer_Logs_Order_By>>;
  where?: Maybe<Printer_Logs_Bool_Exp>;
};


export type Subscription_RootPrinter_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPrintersArgs = {
  distinct_on?: Maybe<Array<Printers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printers_Order_By>>;
  where?: Maybe<Printers_Bool_Exp>;
};


export type Subscription_RootPrinters_AggregateArgs = {
  distinct_on?: Maybe<Array<Printers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Printers_Order_By>>;
  where?: Maybe<Printers_Bool_Exp>;
};


export type Subscription_RootPrinters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProperty_ManagersArgs = {
  distinct_on?: Maybe<Array<Property_Managers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Managers_Order_By>>;
  where?: Maybe<Property_Managers_Bool_Exp>;
};


export type Subscription_RootProperty_Managers_AggregateArgs = {
  distinct_on?: Maybe<Array<Property_Managers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Property_Managers_Order_By>>;
  where?: Maybe<Property_Managers_Bool_Exp>;
};


export type Subscription_RootProperty_Managers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProvidersArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


export type Subscription_RootProviders_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


export type Subscription_RootProviders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProviders_RatingsArgs = {
  distinct_on?: Maybe<Array<Providers_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Ratings_Order_By>>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};


export type Subscription_RootProviders_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Ratings_Order_By>>;
  where?: Maybe<Providers_Ratings_Bool_Exp>;
};


export type Subscription_RootProviders_Ratings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProviders_ServicesArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};


export type Subscription_RootProviders_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Services_Order_By>>;
  where?: Maybe<Providers_Services_Bool_Exp>;
};


export type Subscription_RootProviders_Services_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRentalArgs = {
  distinct_on?: Maybe<Array<Rental_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Order_By>>;
  where?: Maybe<Rental_Bool_Exp>;
};


export type Subscription_RootRental_AggregateArgs = {
  distinct_on?: Maybe<Array<Rental_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rental_Order_By>>;
  where?: Maybe<Rental_Bool_Exp>;
};


export type Subscription_RootRental_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRules_And_RegulationsArgs = {
  distinct_on?: Maybe<Array<Rules_And_Regulations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rules_And_Regulations_Order_By>>;
  where?: Maybe<Rules_And_Regulations_Bool_Exp>;
};


export type Subscription_RootRules_And_Regulations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rules_And_Regulations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rules_And_Regulations_Order_By>>;
  where?: Maybe<Rules_And_Regulations_Bool_Exp>;
};


export type Subscription_RootRules_And_Regulations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootServicesArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


export type Subscription_RootServices_AggregateArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


export type Subscription_RootServices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatement_AccountArgs = {
  distinct_on?: Maybe<Array<Statement_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Statement_Account_Order_By>>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};


export type Subscription_RootStatement_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Statement_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Statement_Account_Order_By>>;
  where?: Maybe<Statement_Account_Bool_Exp>;
};


export type Subscription_RootStatement_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubcategoriesArgs = {
  distinct_on?: Maybe<Array<Subcategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcategories_Order_By>>;
  where?: Maybe<Subcategories_Bool_Exp>;
};


export type Subscription_RootSubcategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Subcategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcategories_Order_By>>;
  where?: Maybe<Subcategories_Bool_Exp>;
};


export type Subscription_RootSubcategories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTablesArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


export type Subscription_RootTables_AggregateArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


export type Subscription_RootTables_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransaction_TypeArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Subscription_RootTransaction_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Transaction_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transaction_Type_Order_By>>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};


export type Subscription_RootTransaction_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_By_PkArgs = {
  transaction_id: Scalars['Int'];
};


export type Subscription_RootTransactions_HelperArgs = {
  distinct_on?: Maybe<Array<Transactions_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Helper_Order_By>>;
  where?: Maybe<Transactions_Helper_Bool_Exp>;
};


export type Subscription_RootTransactions_Helper_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Helper_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Helper_Order_By>>;
  where?: Maybe<Transactions_Helper_Bool_Exp>;
};


export type Subscription_RootUnitsArgs = {
  distinct_on?: Maybe<Array<Units_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Units_Order_By>>;
  where?: Maybe<Units_Bool_Exp>;
};


export type Subscription_RootUnits_AggregateArgs = {
  distinct_on?: Maybe<Array<Units_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Units_Order_By>>;
  where?: Maybe<Units_Bool_Exp>;
};


export type Subscription_RootUnits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVisitsArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};


export type Subscription_RootVisits_AggregateArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};


export type Subscription_RootVisits_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "tables" */
export type Tables = {
  __typename?: 'tables';
  /** An array relationship */
  account_tickets: Array<Account_Tickets>;
  /** An aggregate relationship */
  account_tickets_aggregate: Account_Tickets_Aggregate;
  created_at: Scalars['timestamptz'];
  disabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tables" */
export type TablesAccount_TicketsArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


/** columns and relationships of "tables" */
export type TablesAccount_Tickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Tickets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Tickets_Order_By>>;
  where?: Maybe<Account_Tickets_Bool_Exp>;
};


/** columns and relationships of "tables" */
export type TablesOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "tables" */
export type TablesOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** aggregated selection of "tables" */
export type Tables_Aggregate = {
  __typename?: 'tables_aggregate';
  aggregate?: Maybe<Tables_Aggregate_Fields>;
  nodes: Array<Tables>;
};

/** aggregate fields of "tables" */
export type Tables_Aggregate_Fields = {
  __typename?: 'tables_aggregate_fields';
  avg?: Maybe<Tables_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tables_Max_Fields>;
  min?: Maybe<Tables_Min_Fields>;
  stddev?: Maybe<Tables_Stddev_Fields>;
  stddev_pop?: Maybe<Tables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tables_Stddev_Samp_Fields>;
  sum?: Maybe<Tables_Sum_Fields>;
  var_pop?: Maybe<Tables_Var_Pop_Fields>;
  var_samp?: Maybe<Tables_Var_Samp_Fields>;
  variance?: Maybe<Tables_Variance_Fields>;
};


/** aggregate fields of "tables" */
export type Tables_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tables_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tables_Avg_Fields = {
  __typename?: 'tables_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tables". All fields are combined with a logical 'AND'. */
export type Tables_Bool_Exp = {
  _and?: Maybe<Array<Tables_Bool_Exp>>;
  _not?: Maybe<Tables_Bool_Exp>;
  _or?: Maybe<Array<Tables_Bool_Exp>>;
  account_tickets?: Maybe<Account_Tickets_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tables" */
export enum Tables_Constraint {
  /** unique or primary key constraint */
  TablesPkey = 'tables_pkey'
}

/** input type for incrementing numeric columns in table "tables" */
export type Tables_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tables" */
export type Tables_Insert_Input = {
  account_tickets?: Maybe<Account_Tickets_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tables_Max_Fields = {
  __typename?: 'tables_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tables_Min_Fields = {
  __typename?: 'tables_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tables" */
export type Tables_Mutation_Response = {
  __typename?: 'tables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tables>;
};

/** input type for inserting object relation for remote table "tables" */
export type Tables_Obj_Rel_Insert_Input = {
  data: Tables_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tables_On_Conflict>;
};

/** on_conflict condition type for table "tables" */
export type Tables_On_Conflict = {
  constraint: Tables_Constraint;
  update_columns?: Array<Tables_Update_Column>;
  where?: Maybe<Tables_Bool_Exp>;
};

/** Ordering options when selecting data from "tables". */
export type Tables_Order_By = {
  account_tickets_aggregate?: Maybe<Account_Tickets_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tables */
export type Tables_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tables" */
export enum Tables_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tables" */
export type Tables_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tables_Stddev_Fields = {
  __typename?: 'tables_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tables_Stddev_Pop_Fields = {
  __typename?: 'tables_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tables_Stddev_Samp_Fields = {
  __typename?: 'tables_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tables_Sum_Fields = {
  __typename?: 'tables_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tables" */
export enum Tables_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tables_Var_Pop_Fields = {
  __typename?: 'tables_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tables_Var_Samp_Fields = {
  __typename?: 'tables_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tables_Variance_Fields = {
  __typename?: 'tables_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: Maybe<Scalars['time']>;
  _gt?: Maybe<Scalars['time']>;
  _gte?: Maybe<Scalars['time']>;
  _in?: Maybe<Array<Scalars['time']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['time']>;
  _lte?: Maybe<Scalars['time']>;
  _neq?: Maybe<Scalars['time']>;
  _nin?: Maybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "transaction_type" */
export type Transaction_Type = {
  __typename?: 'transaction_type';
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: Transactions_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "transaction_type" */
export type Transaction_TypeTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "transaction_type" */
export type Transaction_TypeTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};

/** aggregated selection of "transaction_type" */
export type Transaction_Type_Aggregate = {
  __typename?: 'transaction_type_aggregate';
  aggregate?: Maybe<Transaction_Type_Aggregate_Fields>;
  nodes: Array<Transaction_Type>;
};

/** aggregate fields of "transaction_type" */
export type Transaction_Type_Aggregate_Fields = {
  __typename?: 'transaction_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Transaction_Type_Max_Fields>;
  min?: Maybe<Transaction_Type_Min_Fields>;
};


/** aggregate fields of "transaction_type" */
export type Transaction_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transaction_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "transaction_type". All fields are combined with a logical 'AND'. */
export type Transaction_Type_Bool_Exp = {
  _and?: Maybe<Array<Transaction_Type_Bool_Exp>>;
  _not?: Maybe<Transaction_Type_Bool_Exp>;
  _or?: Maybe<Array<Transaction_Type_Bool_Exp>>;
  transactions?: Maybe<Transactions_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transaction_type" */
export enum Transaction_Type_Constraint {
  /** unique or primary key constraint */
  TransactionTypePkey = 'transaction_type_pkey'
}

export enum Transaction_Type_Enum {
  Cancellation = 'Cancellation',
  Charge = 'Charge',
  Deposit = 'Deposit',
  Refund = 'Refund',
  Tip = 'Tip',
  Transfer = 'Transfer',
  Withdrawal = 'Withdrawal'
}

/** Boolean expression to compare columns of type "transaction_type_enum". All fields are combined with logical 'AND'. */
export type Transaction_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Transaction_Type_Enum>;
  _in?: Maybe<Array<Transaction_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Transaction_Type_Enum>;
  _nin?: Maybe<Array<Transaction_Type_Enum>>;
};

/** input type for inserting data into table "transaction_type" */
export type Transaction_Type_Insert_Input = {
  transactions?: Maybe<Transactions_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Transaction_Type_Max_Fields = {
  __typename?: 'transaction_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transaction_Type_Min_Fields = {
  __typename?: 'transaction_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transaction_type" */
export type Transaction_Type_Mutation_Response = {
  __typename?: 'transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction_Type>;
};

/** input type for inserting object relation for remote table "transaction_type" */
export type Transaction_Type_Obj_Rel_Insert_Input = {
  data: Transaction_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Transaction_Type_On_Conflict>;
};

/** on_conflict condition type for table "transaction_type" */
export type Transaction_Type_On_Conflict = {
  constraint: Transaction_Type_Constraint;
  update_columns?: Array<Transaction_Type_Update_Column>;
  where?: Maybe<Transaction_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction_type". */
export type Transaction_Type_Order_By = {
  transactions_aggregate?: Maybe<Transactions_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: transaction_type */
export type Transaction_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "transaction_type" */
export enum Transaction_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "transaction_type" */
export type Transaction_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "transaction_type" */
export enum Transaction_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "transactions" */
export type Transactions = {
  __typename?: 'transactions';
  amount: Scalars['numeric'];
  canceled: Scalars['Boolean'];
  canceled_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  currency: Currency_Type_Enum;
  /** An object relationship */
  currency_type: Currency_Type;
  en_description?: Maybe<Scalars['String']>;
  es_description?: Maybe<Scalars['String']>;
  exchange: Scalars['numeric'];
  is_tip: Scalars['Boolean'];
  /** An object relationship */
  statement_account: Statement_Account;
  statement_account_id: Scalars['Int'];
  /** An object relationship */
  transactionTypeByTransactionType: Transaction_Type;
  transaction_id: Scalars['Int'];
  transaction_type: Transaction_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  __typename?: 'transactions_aggregate_fields';
  avg?: Maybe<Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
  stddev?: Maybe<Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Sum_Fields>;
  var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Variance_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transactions" */
export type Transactions_Aggregate_Order_By = {
  avg?: Maybe<Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Transactions_Max_Order_By>;
  min?: Maybe<Transactions_Min_Order_By>;
  stddev?: Maybe<Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Transactions_Sum_Order_By>;
  var_pop?: Maybe<Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transactions" */
export type Transactions_Arr_Rel_Insert_Input = {
  data: Array<Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  __typename?: 'transactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transactions" */
export type Transactions_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: Maybe<Array<Transactions_Bool_Exp>>;
  _not?: Maybe<Transactions_Bool_Exp>;
  _or?: Maybe<Array<Transactions_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  canceled?: Maybe<Boolean_Comparison_Exp>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Int_Comparison_Exp>;
  currency?: Maybe<Currency_Type_Enum_Comparison_Exp>;
  currency_type?: Maybe<Currency_Type_Bool_Exp>;
  en_description?: Maybe<String_Comparison_Exp>;
  es_description?: Maybe<String_Comparison_Exp>;
  exchange?: Maybe<Numeric_Comparison_Exp>;
  is_tip?: Maybe<Boolean_Comparison_Exp>;
  statement_account?: Maybe<Statement_Account_Bool_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
  transactionTypeByTransactionType?: Maybe<Transaction_Type_Bool_Exp>;
  transaction_id?: Maybe<Int_Comparison_Exp>;
  transaction_type?: Maybe<Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint */
  TransactionsPkey = 'transactions_pkey'
}

/** columns and relationships of "transactions_helper" */
export type Transactions_Helper = {
  __typename?: 'transactions_helper';
  amount?: Maybe<Scalars['numeric']>;
  apartment_building?: Maybe<Scalars['String']>;
  apartment_name?: Maybe<Scalars['String']>;
  apartment_number?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commensal_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  is_tip?: Maybe<Scalars['Boolean']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['time']>;
  transaction_id?: Maybe<Scalars['Int']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "transactions_helper" */
export type Transactions_Helper_Aggregate = {
  __typename?: 'transactions_helper_aggregate';
  aggregate?: Maybe<Transactions_Helper_Aggregate_Fields>;
  nodes: Array<Transactions_Helper>;
};

/** aggregate fields of "transactions_helper" */
export type Transactions_Helper_Aggregate_Fields = {
  __typename?: 'transactions_helper_aggregate_fields';
  avg?: Maybe<Transactions_Helper_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transactions_Helper_Max_Fields>;
  min?: Maybe<Transactions_Helper_Min_Fields>;
  stddev?: Maybe<Transactions_Helper_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Helper_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Helper_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Helper_Sum_Fields>;
  var_pop?: Maybe<Transactions_Helper_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Helper_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Helper_Variance_Fields>;
};


/** aggregate fields of "transactions_helper" */
export type Transactions_Helper_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transactions_Helper_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Transactions_Helper_Avg_Fields = {
  __typename?: 'transactions_helper_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transactions_helper". All fields are combined with a logical 'AND'. */
export type Transactions_Helper_Bool_Exp = {
  _and?: Maybe<Array<Transactions_Helper_Bool_Exp>>;
  _not?: Maybe<Transactions_Helper_Bool_Exp>;
  _or?: Maybe<Array<Transactions_Helper_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  apartment_building?: Maybe<String_Comparison_Exp>;
  apartment_name?: Maybe<String_Comparison_Exp>;
  apartment_number?: Maybe<String_Comparison_Exp>;
  canceled?: Maybe<Boolean_Comparison_Exp>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  commensal_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  es_description?: Maybe<String_Comparison_Exp>;
  exchange?: Maybe<Numeric_Comparison_Exp>;
  is_tip?: Maybe<Boolean_Comparison_Exp>;
  statement_account_id?: Maybe<Int_Comparison_Exp>;
  time?: Maybe<Time_Comparison_Exp>;
  transaction_id?: Maybe<Int_Comparison_Exp>;
  transaction_type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Transactions_Helper_Max_Fields = {
  __typename?: 'transactions_helper_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  apartment_building?: Maybe<Scalars['String']>;
  apartment_name?: Maybe<Scalars['String']>;
  apartment_number?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commensal_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transactions_Helper_Min_Fields = {
  __typename?: 'transactions_helper_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  apartment_building?: Maybe<Scalars['String']>;
  apartment_name?: Maybe<Scalars['String']>;
  apartment_number?: Maybe<Scalars['String']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  commensal_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  transaction_type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "transactions_helper". */
export type Transactions_Helper_Order_By = {
  amount?: Maybe<Order_By>;
  apartment_building?: Maybe<Order_By>;
  apartment_name?: Maybe<Order_By>;
  apartment_number?: Maybe<Order_By>;
  canceled?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  commensal_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  es_description?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  is_tip?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  transaction_type?: Maybe<Order_By>;
};

/** select columns of table "transactions_helper" */
export enum Transactions_Helper_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  ApartmentBuilding = 'apartment_building',
  /** column name */
  ApartmentName = 'apartment_name',
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CommensalName = 'commensal_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  EsDescription = 'es_description',
  /** column name */
  Exchange = 'exchange',
  /** column name */
  IsTip = 'is_tip',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  Time = 'time',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type'
}

/** aggregate stddev on columns */
export type Transactions_Helper_Stddev_Fields = {
  __typename?: 'transactions_helper_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Helper_Stddev_Pop_Fields = {
  __typename?: 'transactions_helper_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Helper_Stddev_Samp_Fields = {
  __typename?: 'transactions_helper_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Transactions_Helper_Sum_Fields = {
  __typename?: 'transactions_helper_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Transactions_Helper_Var_Pop_Fields = {
  __typename?: 'transactions_helper_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Transactions_Helper_Var_Samp_Fields = {
  __typename?: 'transactions_helper_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Transactions_Helper_Variance_Fields = {
  __typename?: 'transactions_helper_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "transactions" */
export type Transactions_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['Int']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency_Type_Enum>;
  currency_type?: Maybe<Currency_Type_Obj_Rel_Insert_Input>;
  en_description?: Maybe<Scalars['String']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  is_tip?: Maybe<Scalars['Boolean']>;
  statement_account?: Maybe<Statement_Account_Obj_Rel_Insert_Input>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transactionTypeByTransactionType?: Maybe<Transaction_Type_Obj_Rel_Insert_Input>;
  transaction_id?: Maybe<Scalars['Int']>;
  transaction_type?: Maybe<Transaction_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  __typename?: 'transactions_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  en_description?: Maybe<Scalars['String']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transactions" */
export type Transactions_Max_Order_By = {
  amount?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  en_description?: Maybe<Order_By>;
  es_description?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  __typename?: 'transactions_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  en_description?: Maybe<Scalars['String']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transactions" */
export type Transactions_Min_Order_By = {
  amount?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  en_description?: Maybe<Order_By>;
  es_description?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint;
  update_columns?: Array<Transactions_Update_Column>;
  where?: Maybe<Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  amount?: Maybe<Order_By>;
  canceled?: Maybe<Order_By>;
  canceled_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  currency_type?: Maybe<Currency_Type_Order_By>;
  en_description?: Maybe<Order_By>;
  es_description?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  is_tip?: Maybe<Order_By>;
  statement_account?: Maybe<Statement_Account_Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transactionTypeByTransactionType?: Maybe<Transaction_Type_Order_By>;
  transaction_id?: Maybe<Order_By>;
  transaction_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  transaction_id: Scalars['Int'];
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  EnDescription = 'en_description',
  /** column name */
  EsDescription = 'es_description',
  /** column name */
  Exchange = 'exchange',
  /** column name */
  IsTip = 'is_tip',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency_Type_Enum>;
  en_description?: Maybe<Scalars['String']>;
  es_description?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['numeric']>;
  is_tip?: Maybe<Scalars['Boolean']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
  transaction_type?: Maybe<Transaction_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  __typename?: 'transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transactions" */
export type Transactions_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  __typename?: 'transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type Transactions_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  __typename?: 'transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type Transactions_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  __typename?: 'transactions_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['Int']>;
  exchange?: Maybe<Scalars['numeric']>;
  statement_account_id?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transactions" */
export type Transactions_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  EnDescription = 'en_description',
  /** column name */
  EsDescription = 'es_description',
  /** column name */
  Exchange = 'exchange',
  /** column name */
  IsTip = 'is_tip',
  /** column name */
  StatementAccountId = 'statement_account_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  __typename?: 'transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transactions" */
export type Transactions_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  __typename?: 'transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transactions" */
export type Transactions_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  __typename?: 'transactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  exchange?: Maybe<Scalars['Float']>;
  statement_account_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transactions" */
export type Transactions_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  exchange?: Maybe<Order_By>;
  statement_account_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** columns and relationships of "units" */
export type Units = {
  __typename?: 'units';
  abreviation: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  ingredients: Array<Ingredients>;
  /** An aggregate relationship */
  ingredients_aggregate: Ingredients_Aggregate;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "units" */
export type UnitsIngredientsArgs = {
  distinct_on?: Maybe<Array<Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredients_Order_By>>;
  where?: Maybe<Ingredients_Bool_Exp>;
};


/** columns and relationships of "units" */
export type UnitsIngredients_AggregateArgs = {
  distinct_on?: Maybe<Array<Ingredients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ingredients_Order_By>>;
  where?: Maybe<Ingredients_Bool_Exp>;
};

/** aggregated selection of "units" */
export type Units_Aggregate = {
  __typename?: 'units_aggregate';
  aggregate?: Maybe<Units_Aggregate_Fields>;
  nodes: Array<Units>;
};

/** aggregate fields of "units" */
export type Units_Aggregate_Fields = {
  __typename?: 'units_aggregate_fields';
  avg?: Maybe<Units_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Units_Max_Fields>;
  min?: Maybe<Units_Min_Fields>;
  stddev?: Maybe<Units_Stddev_Fields>;
  stddev_pop?: Maybe<Units_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Units_Stddev_Samp_Fields>;
  sum?: Maybe<Units_Sum_Fields>;
  var_pop?: Maybe<Units_Var_Pop_Fields>;
  var_samp?: Maybe<Units_Var_Samp_Fields>;
  variance?: Maybe<Units_Variance_Fields>;
};


/** aggregate fields of "units" */
export type Units_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Units_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Units_Avg_Fields = {
  __typename?: 'units_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "units". All fields are combined with a logical 'AND'. */
export type Units_Bool_Exp = {
  _and?: Maybe<Array<Units_Bool_Exp>>;
  _not?: Maybe<Units_Bool_Exp>;
  _or?: Maybe<Array<Units_Bool_Exp>>;
  abreviation?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ingredients?: Maybe<Ingredients_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "units" */
export enum Units_Constraint {
  /** unique or primary key constraint */
  UnitsPkey = 'units_pkey'
}

/** input type for incrementing numeric columns in table "units" */
export type Units_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "units" */
export type Units_Insert_Input = {
  abreviation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ingredients?: Maybe<Ingredients_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Units_Max_Fields = {
  __typename?: 'units_max_fields';
  abreviation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Units_Min_Fields = {
  __typename?: 'units_min_fields';
  abreviation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "units" */
export type Units_Mutation_Response = {
  __typename?: 'units_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Units>;
};

/** input type for inserting object relation for remote table "units" */
export type Units_Obj_Rel_Insert_Input = {
  data: Units_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Units_On_Conflict>;
};

/** on_conflict condition type for table "units" */
export type Units_On_Conflict = {
  constraint: Units_Constraint;
  update_columns?: Array<Units_Update_Column>;
  where?: Maybe<Units_Bool_Exp>;
};

/** Ordering options when selecting data from "units". */
export type Units_Order_By = {
  abreviation?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ingredients_aggregate?: Maybe<Ingredients_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: units */
export type Units_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "units" */
export enum Units_Select_Column {
  /** column name */
  Abreviation = 'abreviation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "units" */
export type Units_Set_Input = {
  abreviation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Units_Stddev_Fields = {
  __typename?: 'units_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Units_Stddev_Pop_Fields = {
  __typename?: 'units_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Units_Stddev_Samp_Fields = {
  __typename?: 'units_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Units_Sum_Fields = {
  __typename?: 'units_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "units" */
export enum Units_Update_Column {
  /** column name */
  Abreviation = 'abreviation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Units_Var_Pop_Fields = {
  __typename?: 'units_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Units_Var_Samp_Fields = {
  __typename?: 'units_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Units_Variance_Fields = {
  __typename?: 'units_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: 'user_roles';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: 'user_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};


/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: Maybe<Array<User_Roles_Bool_Exp>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<User_Roles_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint */
  UserRolesPkey = 'user_roles_pkey'
}

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: 'user_roles_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: 'user_roles_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: 'user_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  apartment?: Maybe<Apartment>;
  apartment_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  apartments: Array<Apartment>;
  /** An aggregate relationship */
  apartments_aggregate: Apartment_Aggregate;
  /** An array relationship */
  arrivals: Array<Arrivals>;
  /** An aggregate relationship */
  arrivals_aggregate: Arrivals_Aggregate;
  /** An array relationship */
  blogs: Array<Blog>;
  /** An aggregate relationship */
  blogs_aggregate: Blog_Aggregate;
  commensal_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  img_profile?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  lessors: Array<Lessors>;
  /** An aggregate relationship */
  lessors_aggregate: Lessors_Aggregate;
  /** An array relationship */
  motions_votes: Array<Motions_Vote>;
  /** An aggregate relationship */
  motions_votes_aggregate: Motions_Vote_Aggregate;
  /** An array relationship */
  new_owners: Array<New_Owners>;
  /** An aggregate relationship */
  new_owners_aggregate: New_Owners_Aggregate;
  phone_number?: Maybe<Scalars['String']>;
  present: Scalars['Boolean'];
  public_contact_info?: Maybe<Scalars['Boolean']>;
  roles: Scalars['String'];
  send_notifications?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactions_aggregate: Transactions_Aggregate;
  uid: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  visits: Array<Visits>;
  /** An aggregate relationship */
  visits_aggregate: Visits_Aggregate;
};


/** columns and relationships of "users" */
export type UsersApartmentsArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersApartments_AggregateArgs = {
  distinct_on?: Maybe<Array<Apartment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apartment_Order_By>>;
  where?: Maybe<Apartment_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersArrivalsArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersArrivals_AggregateArgs = {
  distinct_on?: Maybe<Array<Arrivals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arrivals_Order_By>>;
  where?: Maybe<Arrivals_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBlogsArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBlogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLessorsArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessors_Order_By>>;
  where?: Maybe<Lessors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMotions_VotesArgs = {
  distinct_on?: Maybe<Array<Motions_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Vote_Order_By>>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMotions_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Motions_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Motions_Vote_Order_By>>;
  where?: Maybe<Motions_Vote_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNew_OwnersArgs = {
  distinct_on?: Maybe<Array<New_Owners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<New_Owners_Order_By>>;
  where?: Maybe<New_Owners_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNew_Owners_AggregateArgs = {
  distinct_on?: Maybe<Array<New_Owners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<New_Owners_Order_By>>;
  where?: Maybe<New_Owners_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVisitsArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersVisits_AggregateArgs = {
  distinct_on?: Maybe<Array<Visits_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visits_Order_By>>;
  where?: Maybe<Visits_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  apartment?: Maybe<Apartment_Bool_Exp>;
  apartment_id?: Maybe<Int_Comparison_Exp>;
  apartments?: Maybe<Apartment_Bool_Exp>;
  arrivals?: Maybe<Arrivals_Bool_Exp>;
  blogs?: Maybe<Blog_Bool_Exp>;
  commensal_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  img_profile?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  lessors?: Maybe<Lessors_Bool_Exp>;
  motions_votes?: Maybe<Motions_Vote_Bool_Exp>;
  new_owners?: Maybe<New_Owners_Bool_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  present?: Maybe<Boolean_Comparison_Exp>;
  public_contact_info?: Maybe<Boolean_Comparison_Exp>;
  roles?: Maybe<String_Comparison_Exp>;
  send_notifications?: Maybe<Boolean_Comparison_Exp>;
  transactions?: Maybe<Transactions_Bool_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_name?: Maybe<String_Comparison_Exp>;
  visits?: Maybe<Visits_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  apartment?: Maybe<Apartment_Obj_Rel_Insert_Input>;
  apartment_id?: Maybe<Scalars['Int']>;
  apartments?: Maybe<Apartment_Arr_Rel_Insert_Input>;
  arrivals?: Maybe<Arrivals_Arr_Rel_Insert_Input>;
  blogs?: Maybe<Blog_Arr_Rel_Insert_Input>;
  commensal_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img_profile?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  lessors?: Maybe<Lessors_Arr_Rel_Insert_Input>;
  motions_votes?: Maybe<Motions_Vote_Arr_Rel_Insert_Input>;
  new_owners?: Maybe<New_Owners_Arr_Rel_Insert_Input>;
  phone_number?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  public_contact_info?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Scalars['String']>;
  send_notifications?: Maybe<Scalars['Boolean']>;
  transactions?: Maybe<Transactions_Arr_Rel_Insert_Input>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_name?: Maybe<Scalars['String']>;
  visits?: Maybe<Visits_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img_profile?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img_profile?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  apartment?: Maybe<Apartment_Order_By>;
  apartment_id?: Maybe<Order_By>;
  apartments_aggregate?: Maybe<Apartment_Aggregate_Order_By>;
  arrivals_aggregate?: Maybe<Arrivals_Aggregate_Order_By>;
  blogs_aggregate?: Maybe<Blog_Aggregate_Order_By>;
  commensal_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  img_profile?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  lessors_aggregate?: Maybe<Lessors_Aggregate_Order_By>;
  motions_votes_aggregate?: Maybe<Motions_Vote_Aggregate_Order_By>;
  new_owners_aggregate?: Maybe<New_Owners_Aggregate_Order_By>;
  phone_number?: Maybe<Order_By>;
  present?: Maybe<Order_By>;
  public_contact_info?: Maybe<Order_By>;
  roles?: Maybe<Order_By>;
  send_notifications?: Maybe<Order_By>;
  transactions_aggregate?: Maybe<Transactions_Aggregate_Order_By>;
  uid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
  visits_aggregate?: Maybe<Visits_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CommensalId = 'commensal_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImgProfile = 'img_profile',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Present = 'present',
  /** column name */
  PublicContactInfo = 'public_contact_info',
  /** column name */
  Roles = 'roles',
  /** column name */
  SendNotifications = 'send_notifications',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  img_profile?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  public_contact_info?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Scalars['String']>;
  send_notifications?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CommensalId = 'commensal_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImgProfile = 'img_profile',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Present = 'present',
  /** column name */
  PublicContactInfo = 'public_contact_info',
  /** column name */
  Roles = 'roles',
  /** column name */
  SendNotifications = 'send_notifications',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserName = 'user_name'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "visits" */
export type Visits = {
  __typename?: 'visits';
  /** An object relationship */
  apartment: Apartment;
  apartment_id: Scalars['Int'];
  /** A computed field, executes function "get_visit_apartment_name" */
  apartment_name?: Maybe<Scalars['String']>;
  arrival_method: Scalars['String'];
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  check_in_by: Scalars['Int'];
  commensal_id: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure?: Maybe<Scalars['timestamptz']>;
  expected_arrival: Scalars['timestamp'];
  has_arrived: Scalars['Boolean'];
  /** A computed field, executes function "get_visit_host_name" */
  host_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  plates?: Maybe<Scalars['String']>;
  registered_arrival?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  visitor_count: Scalars['Int'];
  visitor_name: Scalars['String'];
};

/** aggregated selection of "visits" */
export type Visits_Aggregate = {
  __typename?: 'visits_aggregate';
  aggregate?: Maybe<Visits_Aggregate_Fields>;
  nodes: Array<Visits>;
};

/** aggregate fields of "visits" */
export type Visits_Aggregate_Fields = {
  __typename?: 'visits_aggregate_fields';
  avg?: Maybe<Visits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Visits_Max_Fields>;
  min?: Maybe<Visits_Min_Fields>;
  stddev?: Maybe<Visits_Stddev_Fields>;
  stddev_pop?: Maybe<Visits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Visits_Stddev_Samp_Fields>;
  sum?: Maybe<Visits_Sum_Fields>;
  var_pop?: Maybe<Visits_Var_Pop_Fields>;
  var_samp?: Maybe<Visits_Var_Samp_Fields>;
  variance?: Maybe<Visits_Variance_Fields>;
};


/** aggregate fields of "visits" */
export type Visits_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Visits_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "visits" */
export type Visits_Aggregate_Order_By = {
  avg?: Maybe<Visits_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Visits_Max_Order_By>;
  min?: Maybe<Visits_Min_Order_By>;
  stddev?: Maybe<Visits_Stddev_Order_By>;
  stddev_pop?: Maybe<Visits_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Visits_Stddev_Samp_Order_By>;
  sum?: Maybe<Visits_Sum_Order_By>;
  var_pop?: Maybe<Visits_Var_Pop_Order_By>;
  var_samp?: Maybe<Visits_Var_Samp_Order_By>;
  variance?: Maybe<Visits_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "visits" */
export type Visits_Arr_Rel_Insert_Input = {
  data: Array<Visits_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Visits_On_Conflict>;
};

/** aggregate avg on columns */
export type Visits_Avg_Fields = {
  __typename?: 'visits_avg_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "visits" */
export type Visits_Avg_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "visits". All fields are combined with a logical 'AND'. */
export type Visits_Bool_Exp = {
  _and?: Maybe<Array<Visits_Bool_Exp>>;
  _not?: Maybe<Visits_Bool_Exp>;
  _or?: Maybe<Array<Visits_Bool_Exp>>;
  apartment?: Maybe<Apartment_Bool_Exp>;
  apartment_id?: Maybe<Int_Comparison_Exp>;
  apartment_name?: Maybe<String_Comparison_Exp>;
  arrival_method?: Maybe<String_Comparison_Exp>;
  car_color?: Maybe<String_Comparison_Exp>;
  car_description?: Maybe<String_Comparison_Exp>;
  check_in_by?: Maybe<Int_Comparison_Exp>;
  commensal_id?: Maybe<Int_Comparison_Exp>;
  comments?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  departure?: Maybe<Timestamptz_Comparison_Exp>;
  expected_arrival?: Maybe<Timestamp_Comparison_Exp>;
  has_arrived?: Maybe<Boolean_Comparison_Exp>;
  host_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  plates?: Maybe<String_Comparison_Exp>;
  registered_arrival?: Maybe<Timestamp_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  visitor_count?: Maybe<Int_Comparison_Exp>;
  visitor_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "visits" */
export enum Visits_Constraint {
  /** unique or primary key constraint */
  VisitsPkey = 'visits_pkey'
}

/** input type for incrementing numeric columns in table "visits" */
export type Visits_Inc_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  check_in_by?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  visitor_count?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "visits" */
export type Visits_Insert_Input = {
  apartment?: Maybe<Apartment_Obj_Rel_Insert_Input>;
  apartment_id?: Maybe<Scalars['Int']>;
  apartment_name?: Maybe<Scalars['String']>;
  arrives_by?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  checking_in_by?: Maybe<Scalars['Int']>;
  // commensal_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamp']>;
  has_arrived?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  plates?: Maybe<Scalars['String']>;
  registered_arrival?: Maybe<Scalars['timestamp']>;
  arrives_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  visitors_numbers?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Visits_Max_Fields = {
  __typename?: 'visits_max_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  arrival_method?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  check_in_by?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  plates?: Maybe<Scalars['String']>;
  registered_arrival?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visitor_count?: Maybe<Scalars['Int']>;
  visitor_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "visits" */
export type Visits_Max_Order_By = {
  apartment_id?: Maybe<Order_By>;
  arrival_method?: Maybe<Order_By>;
  car_color?: Maybe<Order_By>;
  car_description?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  plates?: Maybe<Order_By>;
  registered_arrival?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
  visitor_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Visits_Min_Fields = {
  __typename?: 'visits_min_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  arrival_method?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  check_in_by?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  plates?: Maybe<Scalars['String']>;
  registered_arrival?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visitor_count?: Maybe<Scalars['Int']>;
  visitor_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "visits" */
export type Visits_Min_Order_By = {
  apartment_id?: Maybe<Order_By>;
  arrival_method?: Maybe<Order_By>;
  car_color?: Maybe<Order_By>;
  car_description?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  plates?: Maybe<Order_By>;
  registered_arrival?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
  visitor_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "visits" */
export type Visits_Mutation_Response = {
  __typename?: 'visits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Visits>;
};

/** on_conflict condition type for table "visits" */
export type Visits_On_Conflict = {
  constraint: Visits_Constraint;
  update_columns?: Array<Visits_Update_Column>;
  where?: Maybe<Visits_Bool_Exp>;
};

/** Ordering options when selecting data from "visits". */
export type Visits_Order_By = {
  apartment?: Maybe<Apartment_Order_By>;
  apartment_id?: Maybe<Order_By>;
  apartment_name?: Maybe<Order_By>;
  arrival_method?: Maybe<Order_By>;
  car_color?: Maybe<Order_By>;
  car_description?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  departure?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  has_arrived?: Maybe<Order_By>;
  host_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  plates?: Maybe<Order_By>;
  registered_arrival?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  visitor_count?: Maybe<Order_By>;
  visitor_name?: Maybe<Order_By>;
};

/** primary key columns input for table: visits */
export type Visits_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "visits" */
export enum Visits_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  ArrivalMethod = 'arrival_method',
  /** column name */
  CarColor = 'car_color',
  /** column name */
  CarDescription = 'car_description',
  /** column name */
  CheckInBy = 'check_in_by',
  /** column name */
  CommensalId = 'commensal_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Departure = 'departure',
  /** column name */
  ExpectedArrival = 'expected_arrival',
  /** column name */
  HasArrived = 'has_arrived',
  /** column name */
  Id = 'id',
  /** column name */
  Plates = 'plates',
  /** column name */
  RegisteredArrival = 'registered_arrival',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitorCount = 'visitor_count',
  /** column name */
  VisitorName = 'visitor_name'
}

/** input type for updating data in table "visits" */
export type Visits_Set_Input = {
  apartment_id?: Maybe<Scalars['Int']>;
  arrival_method?: Maybe<Scalars['String']>;
  car_color?: Maybe<Scalars['String']>;
  car_description?: Maybe<Scalars['String']>;
  check_in_by?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  departure?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamp']>;
  has_arrived?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  plates?: Maybe<Scalars['String']>;
  registered_arrival?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visitor_count?: Maybe<Scalars['Int']>;
  visitor_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Visits_Stddev_Fields = {
  __typename?: 'visits_stddev_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "visits" */
export type Visits_Stddev_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Visits_Stddev_Pop_Fields = {
  __typename?: 'visits_stddev_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "visits" */
export type Visits_Stddev_Pop_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Visits_Stddev_Samp_Fields = {
  __typename?: 'visits_stddev_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "visits" */
export type Visits_Stddev_Samp_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Visits_Sum_Fields = {
  __typename?: 'visits_sum_fields';
  apartment_id?: Maybe<Scalars['Int']>;
  check_in_by?: Maybe<Scalars['Int']>;
  commensal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  visitor_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "visits" */
export type Visits_Sum_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** update columns of table "visits" */
export enum Visits_Update_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  ArrivalMethod = 'arrival_method',
  /** column name */
  CarColor = 'car_color',
  /** column name */
  CarDescription = 'car_description',
  /** column name */
  CheckInBy = 'check_in_by',
  /** column name */
  CommensalId = 'commensal_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Departure = 'departure',
  /** column name */
  ExpectedArrival = 'expected_arrival',
  /** column name */
  HasArrived = 'has_arrived',
  /** column name */
  Id = 'id',
  /** column name */
  Plates = 'plates',
  /** column name */
  RegisteredArrival = 'registered_arrival',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisitorCount = 'visitor_count',
  /** column name */
  VisitorName = 'visitor_name'
}

/** aggregate var_pop on columns */
export type Visits_Var_Pop_Fields = {
  __typename?: 'visits_var_pop_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "visits" */
export type Visits_Var_Pop_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Visits_Var_Samp_Fields = {
  __typename?: 'visits_var_samp_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "visits" */
export type Visits_Var_Samp_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Visits_Variance_Fields = {
  __typename?: 'visits_variance_fields';
  apartment_id?: Maybe<Scalars['Float']>;
  check_in_by?: Maybe<Scalars['Float']>;
  commensal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  visitor_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "visits" */
export type Visits_Variance_Order_By = {
  apartment_id?: Maybe<Order_By>;
  check_in_by?: Maybe<Order_By>;
  commensal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  visitor_count?: Maybe<Order_By>;
};

export type VerifyUserPinQueryVariables = Exact<{
  pin: Scalars['String'];
  id: Scalars['Int'];
}>;


export type VerifyUserPinQuery = { __typename?: 'query_root', statement_account: Array<{ __typename?: 'statement_account', id: number }> };

export type GetDishesCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDishesCategoriesQuery = { __typename?: 'query_root', menus: Array<{ __typename?: 'menu_types', id: number, name: string, position: number, categories: Array<{ __typename?: 'categories', id: number, name: string, subcategories: Array<{ __typename?: 'subcategories', id: number, name: string }> }> }> };

export type GetAllAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAccountsQuery = { __typename?: 'query_root', palapa_accounts: Array<{ __typename?: 'palapa_accounts', statement_id?: number | null | undefined, apartment_name?: string | null | undefined, commensal_name?: string | null | undefined, commensal_type?: string | null | undefined, balance?: any | null | undefined, open?: boolean | null | undefined }> };

export type GetClosedAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClosedAccountsQuery = { __typename?: 'query_root', palapa_accounts: Array<{ __typename?: 'palapa_accounts', statement_id?: number | null | undefined, apartment_name?: string | null | undefined, commensal_name?: string | null | undefined, commensal_type?: string | null | undefined, balance?: any | null | undefined, open?: boolean | null | undefined }> };

export type GetApartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApartmentsQuery = { __typename?: 'query_root', apartment: Array<{ __typename?: 'apartment', id: number, number: number, user_id?: number | null | undefined, user?: { __typename?: 'users', first_name?: string | null | undefined, last_name?: string | null | undefined } | null | undefined, building?: { __typename?: 'buildings', name: string } | null | undefined }> };

export type GetAllApartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllApartmentsQuery = { __typename?: 'query_root', apartment: Array<{ __typename?: 'apartments_helper', apartment_id?: number | null | undefined, statement_account_id?: number | null | undefined, apartment_name?: string | null | undefined, balance?: any | null | undefined, balance_retained?: any | null | undefined, owner?: string | null | undefined, owner_id?: number | null | undefined }> };

export type GetActiveCommensalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveCommensalsQuery = { __typename?: 'query_root', palapa_accounts: Array<{ __typename?: 'palapa_accounts', apartment_id?: number | null | undefined, apartment_name?: string | null | undefined, commensal_name?: string | null | undefined, commensal_type?: string | null | undefined, balance?: any | null | undefined, statement_id?: number | null | undefined }> };

export type GetUsersSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersSelectQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: number, first_name?: string | null | undefined, last_name?: string | null | undefined }> };

export type GetUserIdQueryVariables = Exact<{
  firebaseId: Scalars['String'];
}>;


export type GetUserIdQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: number }> };

export type GetAccountDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAccountDetailsQuery = { __typename?: 'query_root', ticket: Array<{ __typename?: 'account_tickets', id: number }>, palapa_accounts: Array<{ __typename?: 'palapa_accounts', statement_id?: number | null | undefined, commensal_type?: string | null | undefined, balance?: any | null | undefined, orders: Array<{ __typename?: 'orders', id: number, total: any, created_at?: any | null | undefined, order_dishes: Array<{ __typename?: 'order_dish', id: number, disabled: boolean, quantity: number, dish_price: any, order_dish_ingredients: Array<{ __typename?: 'order_dish_ingredients', enabled: boolean, ingredient: { __typename?: 'ingredients', name: string } }>, order_dish_modifier_groups: Array<{ __typename?: 'order_dish_modifier_groups', modifier_group: { __typename?: 'modifier_groups', id: number, title: string }, order_dish_modifiers: Array<{ __typename?: 'order_dish_modifiers', id: number, modifier: { __typename?: 'modifiers', title: string } }> }>, dish: { __typename?: 'dishes', name: string, price: any } }> }> }> };

export type CloseAccountMutationVariables = Exact<{
  accountId: Scalars['Int'];
  transactions: Array<Transactions_Insert_Input> | Transactions_Insert_Input;
  ordersFlag: Orders_Bool_Exp;
  actions: Array<Orders_Actions_Insert_Input> | Orders_Actions_Insert_Input;
  ticket: Account_Tickets_Set_Input;
  ticketId: Scalars['Int'];
}>;


export type CloseAccountMutation = { __typename?: 'mutation_root', update_statement_account_by_pk?: { __typename?: 'statement_account', id: number, open: boolean } | null | undefined, update_account_tickets_by_pk?: { __typename?: 'account_tickets', id: number, open: boolean } | null | undefined, insert_transactions?: { __typename?: 'transactions_mutation_response', returning: Array<{ __typename?: 'transactions', transaction_id: number }> } | null | undefined, update_orders?: { __typename?: 'orders_mutation_response', affected_rows: number } | null | undefined, insert_orders_actions?: { __typename?: 'orders_actions_mutation_response', returning: Array<{ __typename?: 'orders_actions', id: number }> } | null | undefined };

export type CancelAccountMutationVariables = Exact<{
  accountId: Scalars['Int'];
  ordersFlag: Orders_Bool_Exp;
  actions: Array<Orders_Actions_Insert_Input> | Orders_Actions_Insert_Input;
  dishesFlag: Order_Dish_Bool_Exp;
  ticketId: Scalars['Int'];
}>;


export type CancelAccountMutation = { __typename?: 'mutation_root', update_statement_account_by_pk?: { __typename?: 'statement_account', id: number } | null | undefined, update_account_tickets_by_pk?: { __typename?: 'account_tickets', id: number } | null | undefined, update_orders?: { __typename?: 'orders_mutation_response', affected_rows: number } | null | undefined, insert_orders_actions?: { __typename?: 'orders_actions_mutation_response', returning: Array<{ __typename?: 'orders_actions', id: number }> } | null | undefined, update_order_dish?: { __typename?: 'order_dish_mutation_response', affected_rows: number } | null | undefined };

export type GetOpenAccountsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetOpenAccountsQuery = { __typename?: 'query_root', aggregate: { __typename?: 'palapa_accounts_aggregate', aggregate?: { __typename?: 'palapa_accounts_aggregate_fields', count: number } | null | undefined }, accounts: Array<{ __typename?: 'palapa_accounts', statement_id?: number | null | undefined, commensal_name?: string | null | undefined, apartment_name?: string | null | undefined, commensal_type?: string | null | undefined, balance?: any | null | undefined, orders: Array<{ __typename?: 'orders', id: number, order_dishes: Array<{ __typename?: 'order_dish', dish: { __typename?: 'dishes', name: string, price: any } }> }> }> };

export type CancelDishMutationVariables = Exact<{
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  action: Scalars['String'];
}>;


export type CancelDishMutation = { __typename?: 'mutation_root', update_order_dish_by_pk?: { __typename?: 'order_dish', id: number, disabled: boolean } | null | undefined, insert_orders_actions_one?: { __typename?: 'orders_actions', id: number, type: string } | null | undefined };

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
  action: Scalars['String'];
  dishesFlag: Order_Dish_Bool_Exp;
}>;


export type CancelOrderMutation = { __typename?: 'mutation_root', update_orders_by_pk?: { __typename?: 'orders', id: number, status: string } | null | undefined, insert_orders_actions_one?: { __typename?: 'orders_actions', id: number, type: string } | null | undefined, update_order_dish?: { __typename?: 'order_dish_mutation_response', affected_rows: number } | null | undefined };

export type OpenAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OpenAccountMutation = { __typename?: 'mutation_root', update_statement_account_by_pk?: { __typename?: 'statement_account', id: number, open: boolean } | null | undefined };

export type CreateAccountTicketMutationVariables = Exact<{
  data: Account_Tickets_Insert_Input;
}>;


export type CreateAccountTicketMutation = { __typename?: 'mutation_root', insert_account_tickets_one?: { __typename?: 'account_tickets', id: number } | null | undefined };

export type GetTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTablesQuery = { __typename?: 'query_root', tables: Array<{ __typename?: 'tables', id: number, name: string }> };

export type GetAdminApartmentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchString: Scalars['String'];
}>;


export type GetAdminApartmentsQuery = { __typename?: 'query_root', apartments_helper_aggregate: { __typename?: 'apartments_helper_aggregate', aggregate?: { __typename?: 'apartments_helper_aggregate_fields', count: number } | null | undefined }, apartments_helper: Array<{ __typename?: 'apartments_helper', owner_id?: number | null | undefined, statement_account_id?: number | null | undefined, apartment_id?: number | null | undefined, apartment_name?: string | null | undefined, balance?: any | null | undefined, balance_retained?: any | null | undefined, owner?: string | null | undefined }> };

export type SetApartmentOwnerMutationVariables = Exact<{
  apartment_id: Scalars['Int'];
  owner_id: Scalars['Int'];
}>;


export type SetApartmentOwnerMutation = { __typename?: 'mutation_root', update_apartment_by_pk?: { __typename?: 'apartment', id: number } | null | undefined };

export type InsertOrderMutationVariables = Exact<{
  data: Orders_Insert_Input;
}>;

export type InsertStatementMutation = { __typename?: 'mutation_root', insert_statement_accout_one?: { __typename?: 'statement_account', id: number} | null | undefined }

export type InsertStatementMutationVariables = Exact<{
  apartment_id: Scalars['Int'];
}>;

export type InsertOrderMutation = { __typename?: 'mutation_root', insert_orders_one?: { __typename?: 'orders', id: number } | null | undefined };

export type GetCategoriesDishesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesDishesQuery = { __typename?: 'query_root', menu_types: Array<{ __typename?: 'menu_types', id: number, name: string, categories: Array<{ __typename?: 'categories', id: number, name: string, subcategories: Array<{ __typename?: 'subcategories', id: number, name: string, dishes: Array<{ __typename?: 'dishes', id: number, name: string, price: any, cost: any, description?: string | null | undefined, modifier_group_dishes: Array<{ __typename?: 'modifier_group_dish', id: number, modifier_group_id: number, modifier_group: { __typename?: 'modifier_groups', title: string, multiple: boolean, modifiers: Array<{ __typename?: 'modifiers', id: number, title: string }> } }>, ingredient_dishes: Array<{ __typename?: 'ingredient_dish', optional: boolean, ingredient: { __typename?: 'ingredients', id: number, name: string } }> }> }> }> }> };

export type GetOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = { __typename?: 'query_root', orders: Array<{ __typename?: 'orders', id: number, status: string, statement_account_id: number, created_at?: any | null | undefined, updated_at?: any | null | undefined }> };

export type GetUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitsQuery = { __typename?: 'query_root', units: Array<{ __typename?: 'units', id: number, name: string, abreviation: string }> };

export type InsertIngredientsMutationVariables = Exact<{
  data: Array<Ingredients_Insert_Input> | Ingredients_Insert_Input;
}>;


export type InsertIngredientsMutation = { __typename?: 'mutation_root', insert_ingredients?: { __typename?: 'ingredients_mutation_response', returning: Array<{ __typename?: 'ingredients', id: number }> } | null | undefined };

export type GetIngredientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIngredientsQuery = { __typename?: 'query_root', ingredients: Array<{ __typename?: 'ingredients', id: number, name: string, unit: number, quantity: any, inventory: boolean, available: boolean, unitObject: { __typename?: 'units', name: string, abreviation: string } }> };

export type UpdateDishMutationVariables = Exact<{
  id: Scalars['Int'];
  dish: Dishes_Set_Input;
  ingredients: Array<Ingredient_Dish_Insert_Input> | Ingredient_Dish_Insert_Input;
  modifiers: Array<Modifier_Group_Dish_Insert_Input> | Modifier_Group_Dish_Insert_Input;
}>;


export type UpdateDishMutation = { __typename?: 'mutation_root', update_dishes_by_pk?: { __typename?: 'dishes', id: number } | null | undefined, delete_ingredient_dish?: { __typename?: 'ingredient_dish_mutation_response', affected_rows: number } | null | undefined, delete_modifier_group_dish?: { __typename?: 'modifier_group_dish_mutation_response', affected_rows: number } | null | undefined, insert_modifier_group_dish?: { __typename?: 'modifier_group_dish_mutation_response', affected_rows: number } | null | undefined, insert_ingredient_dish?: { __typename?: 'ingredient_dish_mutation_response', affected_rows: number } | null | undefined };

export type InsertDishesMutationVariables = Exact<{
  data: Array<Dishes_Insert_Input> | Dishes_Insert_Input;
}>;


export type InsertDishesMutation = { __typename?: 'mutation_root', insert_dishes?: { __typename?: 'dishes_mutation_response', returning: Array<{ __typename?: 'dishes', id: number }> } | null | undefined };

export type CreateModifierMutationVariables = Exact<{
  data: Modifier_Groups_Insert_Input;
}>;


export type CreateModifierMutation = { __typename?: 'mutation_root', insert_modifier_groups_one?: { __typename?: 'modifier_groups', id: number } | null | undefined };

export type InsertMenuMutationVariables = Exact<{
  data: Menu_Types_Insert_Input;
}>;


export type InsertMenuMutation = { __typename?: 'mutation_root', insert_menu_types_one?: { __typename?: 'menu_types', id: number } | null | undefined };

export type InsertCategoryMutationVariables = Exact<{
  data: Categories_Insert_Input;
}>;


export type InsertCategoryMutation = { __typename?: 'mutation_root', insert_categories_one?: { __typename?: 'categories', id: number } | null | undefined };

export type InsertSubcategoryMutationVariables = Exact<{
  data: Subcategories_Insert_Input;
}>;


export type InsertSubcategoryMutation = { __typename?: 'mutation_root', insert_subcategories_one?: { __typename?: 'subcategories', id: number } | null | undefined };

export type GetMenusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMenusQuery = { __typename?: 'query_root', menus: Array<{ __typename?: 'menu_types', id: number, name: string, position: number, disabled: boolean, categories: Array<{ __typename?: 'categories', id: number, name: string, position: number, disabled: boolean, subcategories: Array<{ __typename?: 'subcategories', id: number, name: string, position: number, disabled: boolean }> }> }> };

export type UpdateMenuMutationVariables = Exact<{
  id: Scalars['Int'];
  data: Menu_Types_Set_Input;
}>;


export type UpdateMenuMutation = { __typename?: 'mutation_root', update_menu_types_by_pk?: { __typename?: 'menu_types', id: number } | null | undefined };

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  data: Categories_Set_Input;
}>;


export type UpdateCategoryMutation = { __typename?: 'mutation_root', update_categories_by_pk?: { __typename?: 'categories', id: number } | null | undefined };

export type UpdateSubcategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  data: Subcategories_Set_Input;
}>;


export type UpdateSubcategoryMutation = { __typename?: 'mutation_root', update_subcategories_by_pk?: { __typename?: 'subcategories', id: number } | null | undefined };

export type GetMenuQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
}>;


export type GetMenuQuery = { __typename?: 'query_root', aggregate: { __typename?: 'dishes_aggregate', aggregate?: { __typename?: 'dishes_aggregate_fields', count: number } | null | undefined }, dishes: Array<{ __typename?: 'dishes', id: number, cost: any, name: string, price: any, description?: string | null | undefined, modifier_group_dishes: Array<{ __typename?: 'modifier_group_dish', id: number, modifier_group: { __typename?: 'modifier_groups', id: number, title: string, multiple: boolean, modifiers: Array<{ __typename?: 'modifiers', title: string, id: number }> } }>, subcategory?: { __typename?: 'subcategories', id: number, name: string, category: { __typename?: 'categories', id: number, name: string, menu_type: { __typename?: 'menu_types', id: number, name: string } } } | null | undefined, ingredient_dishes: Array<{ __typename?: 'ingredient_dish', optional: boolean, quantity: any, ingredient: { __typename?: 'ingredients', name: string, id: number, unitObject: { __typename?: 'units', name: string, abreviation: string } } }> }> };

export type GetModifiersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetModifiersQuery = { __typename?: 'query_root', modifier_groups_aggregate: { __typename?: 'modifier_groups_aggregate', aggregate?: { __typename?: 'modifier_groups_aggregate_fields', count: number } | null | undefined }, modifier_groups: Array<{ __typename?: 'modifier_groups', id: number, title: string, multiple: boolean, modifiers: Array<{ __typename?: 'modifiers', title: string }> }> };

export type UpdateModifierGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  multiple: Scalars['Boolean'];
}>;


export type UpdateModifierGroupMutation = { __typename?: 'mutation_root', update_modifier_groups_by_pk?: { __typename?: 'modifier_groups', id: number } | null | undefined };

export type UpdateMenuNameMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type UpdateMenuNameMutation = { __typename?: 'mutation_root', update_menu_types_by_pk?: { __typename?: 'menu_types', name: string } | null | undefined };

export type UpdateCategoryNameMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type UpdateCategoryNameMutation = { __typename?: 'mutation_root', update_categories_by_pk?: { __typename?: 'categories', name: string } | null | undefined };

export type UpdateSubcategoryNameMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type UpdateSubcategoryNameMutation = { __typename?: 'mutation_root', update_subcategories_by_pk?: { __typename?: 'subcategories', name: string } | null | undefined };

export type GetAddDishIngredientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddDishIngredientsQuery = { __typename?: 'query_root', ingredients: Array<{ __typename?: 'ingredients', id: number, name: string, available: boolean, unitObject: { __typename?: 'units', name: string, abreviation: string } }> };

export type GetModifierGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModifierGroupsQuery = { __typename?: 'query_root', modifier_groups: Array<{ __typename?: 'modifier_groups', id: number, title: string, multiple: boolean, modifiers: Array<{ __typename?: 'modifiers', title: string }> }> };

export type GetPrinterInfoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPrinterInfoQuery = { __typename?: 'query_root', info?: { __typename?: 'printers', name: string, id: number, address: string } | null | undefined };

export type GetPrintersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrintersQuery = { __typename?: 'query_root', printers: Array<{ __typename?: 'printers', id: number, name: string, address: string, disabled: boolean }> };

export type UpdatePrinterMutationVariables = Exact<{
  ip: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdatePrinterMutation = { __typename?: 'mutation_root', printer?: { __typename?: 'printers', id: number } | null | undefined };

export type GetPrinterLogsQueryVariables = Exact<{
  id: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetPrinterLogsQuery = { __typename?: 'query_root', logs_length: { __typename?: 'printer_logs_aggregate', aggregate?: { __typename?: 'printer_logs_aggregate_fields', count: number } | null | undefined }, logs: Array<{ __typename?: 'printer_logs', level: string, description: string, python_log?: string | null | undefined, created_at: any }> };

export type GetPrinterTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrinterTemplatesQuery = { __typename?: 'query_root', templates: Array<{ __typename?: 'print_templates', id: number, alias: string, name: string, printer_id?: number | null | undefined }> };

export type UpdatePrinterTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  data: Print_Templates_Set_Input;
}>;


export type UpdatePrinterTemplateMutation = { __typename?: 'mutation_root', update_print_templates_by_pk?: { __typename?: 'print_templates', id: number } | null | undefined };

export type GetReportTransactionsQueryVariables = Exact<{
  date: Scalars['date'];
}>;


export type GetReportTransactionsQuery = { 
  __typename?: 'query_root', 
  transactions: Array<{ 
    __typename?: 'transactions_helper', 
    amount?: any | null | undefined, 
    canceled?: boolean | null | undefined, 
    commensal_name?: string | null | undefined, 
    apartment_name?: string | null | undefined, 
    transaction_type?: string | null | undefined, 
    es_description?: string | null | undefined, 
    canceled_at?: any | null | undefined, 
    created_at?: any | null | undefined, 
    currency?: string | null | undefined, 
    exchange?: any | null | undefined }> };

export type GetReceptionAccountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReceptionAccountQuery = { __typename?: 'query_root', palapa_accounts: Array<{ __typename?: 'palapa_accounts', rental_id?: number | null | undefined, user_id?: number | null | undefined, statement_id?: number | null | undefined, present?: boolean | null | undefined, commensal_name?: string | null | undefined, commensal_type?: string | null | undefined, apartment_name?: string | null | undefined, balance?: any | null | undefined, balance_retained?: any | null | undefined, tickets: Array<{ __typename?: 'account_tickets', id: number, open: boolean, tip: any, total: any, table: { __typename?: 'tables', name: string } }> }> };

export type GetReceptionCommensalsQueryVariables = Exact<{
  searchString: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetReceptionCommensalsQuery = { __typename?: 'query_root', aggregate: { __typename?: 'palapa_accounts_aggregate', aggregate?: { __typename?: 'palapa_accounts_aggregate_fields', count: number } | null | undefined }, accounts: Array<{ __typename?: 'palapa_accounts', statement_id?: number | null | undefined, commensal_name?: string | null | undefined, apartment_name?: string | null | undefined, commensal_type?: string | null | undefined, present?: boolean | null | undefined, balance?: any | null | undefined, balance_retained?: any | null | undefined, pin_created?: boolean | null | undefined, rental_id?: number | null | undefined, user_id?: number | null | undefined }> };

export type GetTicketsQueryVariables = Exact<{
  date: Scalars['date'];
}>;


export type GetTicketsQuery = { __typename?: 'query_root', tickets: Array<{ __typename?: 'account_tickets', id: number, date?: any | null | undefined, commensal_name?: string | null | undefined, orders: Array<{ __typename?: 'orders', order_dishes: Array<{ __typename?: 'order_dish', dish_price: any, quantity: number, dish: { __typename?: 'dishes', name: string } }> }> }> };

export type GetCommensalTransactionsQueryVariables = Exact<{
  id: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;


export type GetCommensalTransactionsQuery = { __typename?: 'query_root', transactions_aggregate: { __typename?: 'transactions_aggregate', aggregate?: { __typename?: 'transactions_aggregate_fields', count: number } | null | undefined }, transactions: Array<{ __typename?: 'transactions', transaction_id: number, es_description?: string | null | undefined, statement_account_id: number, amount: any, created_at: any, canceled: boolean, transaction_type: Transaction_Type_Enum, exchange: any, currency: Currency_Type_Enum, user: { __typename?: 'users', first_name?: string | null | undefined, last_name?: string | null | undefined } }> };

export type CheckoutRentalMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CheckoutRentalMutation = { __typename?: 'mutation_root', update_rental_by_pk?: { __typename?: 'rental', id: number } | null | undefined };

export type CheckoutOwnerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CheckoutOwnerMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: number } | null | undefined };

export type UpdateCommensalPinMutationVariables = Exact<{
  id: Scalars['Int'];
  pin: Scalars['String'];
}>;


export type UpdateCommensalPinMutation = { __typename?: 'mutation_root', update_statement_account_by_pk?: { __typename?: 'statement_account', id: number, pin?: string | null | undefined } | null | undefined };

export type UpdateCurrencyMutationVariables = Exact<{
  id: Scalars['Int'];
  amount: Scalars['numeric'];
}>;


export type UpdateCurrencyMutation = { __typename?: 'mutation_root', update_exchange_by_pk?: { __typename?: 'exchange', id: number, exchange: any } | null | undefined };

export type GetCurrenciesInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrenciesInfoQuery = { __typename?: 'query_root', exchange: Array<{ __typename?: 'exchange', id: number, currency_key: Currency_Type_Enum, currency_name: string, exchange: any }> };

export type GetCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrenciesQuery = { __typename?: 'query_root', exchange: Array<{ __typename?: 'exchange', id: number, currency_key: Currency_Type_Enum, currency_name: string, exchange: any }> };

export type CreateTransactionMutationVariables = Exact<{
  data: Transactions_Insert_Input;
}>;


export type CreateTransactionMutation = { __typename?: 'mutation_root', insert_transactions_one?: { __typename?: 'transactions', transaction_id: number, amount: any, exchange: any, currency: Currency_Type_Enum } | null | undefined };

export type CancelTransactionMutationVariables = Exact<{
  id: Scalars['Int'];
  data: Transactions_Insert_Input;
}>;


export type CancelTransactionMutation = { __typename?: 'mutation_root', insert_transactions_one?: { __typename?: 'transactions', transaction_id: number } | null | undefined, update_transactions_by_pk?: { __typename?: 'transactions', transaction_id: number, canceled: boolean } | null | undefined };

export type CreateTransferMutationVariables = Exact<{
  transactions: Array<Transactions_Insert_Input> | Transactions_Insert_Input;
}>;


export type CreateTransferMutation = { __typename?: 'mutation_root', insert_transactions?: { __typename?: 'transactions_mutation_response', returning: Array<{ __typename?: 'transactions', transaction_id: number }> } | null | undefined };

export type InsertOwnerArrivalMutationVariables = Exact<{
  data: Arrivals_Insert_Input;
}>;


export type InsertOwnerArrivalMutation = { __typename?: 'mutation_root', insert_arrivals_one?: { __typename?: 'arrivals', id: number } | null | undefined };

export type InsertRentalArrivalMutationVariables = Exact<{
  data: Rental_Insert_Input;
}>;

export type CheckRentalAvailabilityQueryVariables = Exact<{
  present: boolean;
  apartmentId: Scalars['Int'];
}>;

export type CheckRentalAvailabilityQuery = {__typename?: 'mutation_root', check_rental?: { __typename?: 'rental', id:number} | null | undefined};

// export type CheckOwnerPresentQueryVariables = Exact<{
//   present: boolean;
//   apartmentId: Scalars['Int'];
// }>;

// export type CheckOwnerPresentQuery = {__typename?: 'mutation_root', owner_present?: { __typename?: 'rental', id:number} | null | undefined};


export type InsertRentalArrivalMutation = { __typename?: 'mutation_root', insert_rental_one?: { __typename?: 'rental', id: number } | null | undefined };

export type UpdateArrivalMutationVariables = Exact<{
  data: Arrivals_Set_Input;
  id: Scalars['Int'];
}>;


export type UpdateArrivalMutation = { __typename?: 'mutation_root', update_arrivals_by_pk?: { __typename?: 'arrivals', id: number } | null | undefined };

export type GetAllBalancesQueryVariables = Exact<{
  searchString: Scalars['String'];
  filteredDate: Scalars['date'];
}>;


export type GetAllBalancesQuery = { __typename?: 'query_root', transactions: Array<{ __typename?: 'transactions_helper', transaction_id?: number | null | undefined, statement_account_id?: number | null | undefined, apartment_name?: string | null | undefined, amount?: any | null | undefined, commensal_name?: string | null | undefined, canceled?: boolean | null | undefined, transaction_type?: string | null | undefined, exchange?: any | null | undefined }> };

export type GetAllTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTicketsQuery = {
  __typename?: 'query_root';
  account_tickets: Array<{
    __typename?: 'account_tickets';
    id: number;
    tip: any;
    total: any;
    commensal_name?: string | null | undefined;
    apartment_name?: string | null | undefined;
    created_at: any;
    orders: Array<{
      __typename?: 'orders';
      total: any;
      order_dishes: Array<{
        __typename?: 'order_dish';
        dish_price: any;
        quantity: number;
        dish: { __typename?: 'dishes'; name: string };
      }>;
    }>;
    statement_account: {
      __typename?: 'statement_account';
      apartment: {
        __typename?: 'apartment';
        name: string;
        user: { __typename?: 'user'; first_name: string; last_name: string };
        building: { __typename?: 'building'; name: string };
      };
    };
  }>;
};
export type GetAllTransactionsQueryVariables = Exact<{
  filters: Transactions_Helper_Bool_Exp;
}>;


export type GetAllTransactionsQuery = { __typename?: 'query_root', transactions: Array<{ __typename?: 'transactions_helper', transaction_id?: number | null | undefined, commensal_name?: string | null | undefined, apartment_name?: string | null | undefined, es_description?: string | null | undefined, amount?: any | null | undefined, is_tip?: boolean | null | undefined, date?: any | null | undefined, time?: any | null | undefined, currency?: string | null | undefined, transaction_type?: string | null | undefined, created_at?: any | null | undefined }> };

export type CreateAccountMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  phone_number: Scalars['String'];
  roles: Scalars['String'];
  uid: Scalars['String'];
  apartment_id: Scalars['Int'];
}>;


export type CreateAccountMutation = { __typename?: 'mutation_root', CreateUserAccount?: { __typename?: 'CreateUserAccountOutput', uid: string } | null | undefined };

export type StatementPdfMutationVariables = Exact<{
  format: Scalars['String'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
  statement_account_id: Scalars['Int'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
}>;

export type StatementPdfMutation = { __typename?: 'mutation_root', GenerateStatementPdf?: { __typename?: 'PrintResult', message: string, success: boolean, pdfContent: String } | null | undefined };

export type PrintReportMutationVariables = Exact<{
  id: Scalars['Int'];
  format: Scalars['String'];
  date: Scalars['String'];
}>;

export type PrintReportMutation = { __typename?: 'mutation_root', PrintTicket?: { __typename?: 'PrintResult', message: string, success: boolean } | null | undefined };


export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserDetailsQuery = { __typename?: 'query_root', user?: { __typename?: 'users', id: number, first_name?: string | null | undefined, last_name?: string | null | undefined, email?: string | null | undefined, phone_number?: string | null | undefined, uid: string, roles: string, apartment?: { __typename?: 'apartment', id: number, statement_accounts: Array<{ __typename?: 'statement_account', total: any, transactions: Array<{ __typename?: 'transactions', amount: any, es_description?: string | null | undefined, transaction_type: Transaction_Type_Enum }> }> } | null | undefined } | null | undefined };

export type GetUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchString: Scalars['String'];
}>;


export type GetUsersQuery = { __typename?: 'query_root', users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null | undefined }, users: Array<{ __typename?: 'users', id: number, first_name?: string | null | undefined, last_name?: string | null | undefined, email?: string | null | undefined, roles: string }> };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  data: Users_Set_Input;
  apartment_id: Scalars['Int'];
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: number } | null | undefined, update_apartment_by_pk?: { __typename?: 'apartment', id: number } | null | undefined };

export type UpdateUserEmailMutationVariables = Exact<{
  email: Scalars['String'];
  uid: Scalars['String'];
}>;

export type InsertUserMutationVariables = Exact<{
  data: Users_Set_Input;
}>;


export type InsertUserMutation = { __typename?: 'mutation_root', insert_users?: { __typename?: 'users_mutation_response', affected_rows: number, returning: { id:number}[] } | null | undefined};



export type UpdateUserEmailMutation = { __typename?: 'mutation_root', UpdateUserEmail?: { __typename?: 'UpdateUserEmailOutput', email: string } | null | undefined };

export type UpdateRoleMutationVariables = Exact<{
  user: Scalars['Int'];
  uid: Scalars['String'];
  role: Scalars['String'];
}>;


export type UpdateRoleMutation = { __typename?: 'mutation_root', UpdateUserRole?: { __typename?: 'UpdateUserRoleOutput', uid: string } | null | undefined };

export type CreateVisitMutationVariables = Exact<{
  data: Visits_Insert_Input;
}>;


export type CreateVisitMutation = { __typename?: 'mutation_root', insert_visits_one?: { __typename?: 'visits', id: number } | null | undefined };

export type GetVisitsQueryVariables = Exact<{
  searchString: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetVisitsQuery = { __typename?: 'query_root', visits_aggregate: { __typename?: 'visits_aggregate', aggregate?: { __typename?: 'visits_aggregate_fields', count: number } | null | undefined }, visits: Array<{ __typename?: 'visits', id: number, name: string, host_name?: string | null | undefined, apartment_name?: string | null | undefined, expected_arrival: any, checking_in: any, checking_out: any, arrival_method: string, car_color?: string | null | undefined, plates?: string | null | undefined, car_description?: string | null | undefined, arrives_at?: string | null | undefined, comments?: string | null | undefined, visitor_count: number, apartment: Array<{__typename?: 'apartment', name: string, buildings: Array<{__typename?: 'buildings', name: string }> }> }> };

export type GetArrivalsQueryVariables = Exact<{
  searchString: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  startDate: Scalars['timestamp'];
  endDate: Scalars['timestamp'];
}>;

export type GetArrivalsQuery = { __typename?: 'query_root', arrivals_aggregate: { __typename?: 'arrivals_aggregate', aggregate?: { __typename?: 'arrivals_aggregate_fields', count: number } | null | undefined }, arrivals: Array<{ __typename?: 'arrivals', id: number, name: string, apartment_name?: string | null | undefined, user_id?: number | null | undefined, arrival_date: any, final_departure_date: any, departure_date: any,  arrived_in: string, car_color?: string | null | undefined, plates?: string | null | undefined, car_description?: string | null | undefined, comments?: string | null | undefined }> };


export const VerifyUserPinDocument = gql`
    query VerifyUserPin($pin: String!, $id: Int!) {
  statement_account(where: {_and: [{id: {_eq: $id}}, {pin: {_eq: $pin}}]}) {
    id
  }
}
    `;

/**
 * __useVerifyUserPinQuery__
 *
 * To run a query within a React component, call `useVerifyUserPinQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserPinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyUserPinQuery({
 *   variables: {
 *      pin: // value for 'pin'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyUserPinQuery(baseOptions: Apollo.QueryHookOptions<VerifyUserPinQuery, VerifyUserPinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyUserPinQuery, VerifyUserPinQueryVariables>(VerifyUserPinDocument, options);
      }
export function useVerifyUserPinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyUserPinQuery, VerifyUserPinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyUserPinQuery, VerifyUserPinQueryVariables>(VerifyUserPinDocument, options);
        }
export type VerifyUserPinQueryHookResult = ReturnType<typeof useVerifyUserPinQuery>;
export type VerifyUserPinLazyQueryHookResult = ReturnType<typeof useVerifyUserPinLazyQuery>;
export type VerifyUserPinQueryResult = Apollo.QueryResult<VerifyUserPinQuery, VerifyUserPinQueryVariables>;
export const GetDishesCategoriesDocument = gql`
    query GetDishesCategories {
  menus: menu_types(order_by: {position: asc}, where: {disabled: {_eq: false}}) {
    id
    name
    position
    categories(order_by: {name: asc}, where: {disabled: {_eq: false}}) {
      id
      name
      subcategories(where: {disabled: {_eq: false}}) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetDishesCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDishesCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDishesCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDishesCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDishesCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetDishesCategoriesQuery, GetDishesCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDishesCategoriesQuery, GetDishesCategoriesQueryVariables>(GetDishesCategoriesDocument, options);
      }
export function useGetDishesCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDishesCategoriesQuery, GetDishesCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDishesCategoriesQuery, GetDishesCategoriesQueryVariables>(GetDishesCategoriesDocument, options);
        }
export type GetDishesCategoriesQueryHookResult = ReturnType<typeof useGetDishesCategoriesQuery>;
export type GetDishesCategoriesLazyQueryHookResult = ReturnType<typeof useGetDishesCategoriesLazyQuery>;
export type GetDishesCategoriesQueryResult = Apollo.QueryResult<GetDishesCategoriesQuery, GetDishesCategoriesQueryVariables>;
export const GetAllAccountsDocument = gql`
    query GetAllAccounts {
  palapa_accounts(
    order_by: [{apartment_building: asc_nulls_last}, {apartment_number: asc}]
  ) {
    statement_id
    apartment_name
    commensal_name
    commensal_type
    balance
    open
  }
}
    `;

/**
 * __useGetAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccountsQuery, GetAllAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
      }
export function useGetAllAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountsQuery, GetAllAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
        }
export type GetAllAccountsQueryHookResult = ReturnType<typeof useGetAllAccountsQuery>;
export type GetAllAccountsLazyQueryHookResult = ReturnType<typeof useGetAllAccountsLazyQuery>;
export type GetAllAccountsQueryResult = Apollo.QueryResult<GetAllAccountsQuery, GetAllAccountsQueryVariables>;
export const GetClosedAccountsDocument = gql`
    query GetClosedAccounts {
  palapa_accounts(
    where: {open: {_eq: false}}
    order_by: [{apartment_building: asc_nulls_last}, {apartment_number: asc}]
  ) {
    statement_id
    apartment_name
    commensal_name
    commensal_type
    balance
    open
  }
}
    `;

/**
 * __useGetClosedAccountsQuery__
 *
 * To run a query within a React component, call `useGetClosedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClosedAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetClosedAccountsQuery, GetClosedAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClosedAccountsQuery, GetClosedAccountsQueryVariables>(GetClosedAccountsDocument, options);
      }
export function useGetClosedAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClosedAccountsQuery, GetClosedAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClosedAccountsQuery, GetClosedAccountsQueryVariables>(GetClosedAccountsDocument, options);
        }
export type GetClosedAccountsQueryHookResult = ReturnType<typeof useGetClosedAccountsQuery>;
export type GetClosedAccountsLazyQueryHookResult = ReturnType<typeof useGetClosedAccountsLazyQuery>;
export type GetClosedAccountsQueryResult = Apollo.QueryResult<GetClosedAccountsQuery, GetClosedAccountsQueryVariables>;
export const GetApartmentsDocument = gql`
    query GetApartments {
  apartment(
    where: {id: {_neq: 0}, user_id: {_is_null: false}}
    order_by: {building: {name: asc}, number: asc}
  ) {
    id
    number
    user_id
    user {
      first_name
      last_name
    }
    building {
      name
    }
  }
}
    `;

/**
 * __useGetApartmentsQuery__
 *
 * To run a query within a React component, call `useGetApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetApartmentsQuery, GetApartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApartmentsQuery, GetApartmentsQueryVariables>(GetApartmentsDocument, options);
      }
export function useGetApartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApartmentsQuery, GetApartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApartmentsQuery, GetApartmentsQueryVariables>(GetApartmentsDocument, options);
        }
export type GetApartmentsQueryHookResult = ReturnType<typeof useGetApartmentsQuery>;
export type GetApartmentsLazyQueryHookResult = ReturnType<typeof useGetApartmentsLazyQuery>;
export type GetApartmentsQueryResult = Apollo.QueryResult<GetApartmentsQuery, GetApartmentsQueryVariables>;
export const GetAllApartmentsDocument = gql`
    query GetAllApartments {
  apartment: apartments_helper(
    where: {apartment_id: {_neq: 0}}
    order_by: {name: asc, number: asc}
  ) {
    apartment_id
    statement_account_id
    apartment_name
    balance
    balance_retained
    owner
    owner_id
  }
}
    `;

/**
 * __useGetAllApartmentsQuery__
 *
 * To run a query within a React component, call `useGetAllApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllApartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllApartmentsQuery, GetAllApartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllApartmentsQuery, GetAllApartmentsQueryVariables>(GetAllApartmentsDocument, options);
      }
export function useGetAllApartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllApartmentsQuery, GetAllApartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllApartmentsQuery, GetAllApartmentsQueryVariables>(GetAllApartmentsDocument, options);
        }
export type GetAllApartmentsQueryHookResult = ReturnType<typeof useGetAllApartmentsQuery>;
export type GetAllApartmentsLazyQueryHookResult = ReturnType<typeof useGetAllApartmentsLazyQuery>;
export type GetAllApartmentsQueryResult = Apollo.QueryResult<GetAllApartmentsQuery, GetAllApartmentsQueryVariables>;
export const GetActiveCommensalsDocument = gql`
    query GetActiveCommensals {
  palapa_accounts(
    where: {_or: [{present: {_eq: true}}, {commensal_type: {_eq: "owner"}}]}
    order_by: [{apartment_building: asc}, {apartment_number: asc}]
  ) {
    apartment_id
    apartment_name
    commensal_name
    commensal_type
    balance
    statement_id
  }
}
    `;

/**
 * __useGetActiveCommensalsQuery__
 *
 * To run a query within a React component, call `useGetActiveCommensalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCommensalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCommensalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCommensalsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveCommensalsQuery, GetActiveCommensalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveCommensalsQuery, GetActiveCommensalsQueryVariables>(GetActiveCommensalsDocument, options);
      }
export function useGetActiveCommensalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveCommensalsQuery, GetActiveCommensalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveCommensalsQuery, GetActiveCommensalsQueryVariables>(GetActiveCommensalsDocument, options);
        }
export type GetActiveCommensalsQueryHookResult = ReturnType<typeof useGetActiveCommensalsQuery>;
export type GetActiveCommensalsLazyQueryHookResult = ReturnType<typeof useGetActiveCommensalsLazyQuery>;
export type GetActiveCommensalsQueryResult = Apollo.QueryResult<GetActiveCommensalsQuery, GetActiveCommensalsQueryVariables>;
export const GetUsersSelectDocument = gql`
    query GetUsersSelect {
  users(
    where: {_and: [{roles: {_eq: "user"}}, {disabled: {_eq: false}}]}
    order_by: {first_name: asc}
  ) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useGetUsersSelectQuery__
 *
 * To run a query within a React component, call `useGetUsersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersSelectQuery, GetUsersSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersSelectQuery, GetUsersSelectQueryVariables>(GetUsersSelectDocument, options);
      }
export function useGetUsersSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersSelectQuery, GetUsersSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersSelectQuery, GetUsersSelectQueryVariables>(GetUsersSelectDocument, options);
        }
export type GetUsersSelectQueryHookResult = ReturnType<typeof useGetUsersSelectQuery>;
export type GetUsersSelectLazyQueryHookResult = ReturnType<typeof useGetUsersSelectLazyQuery>;
export type GetUsersSelectQueryResult = Apollo.QueryResult<GetUsersSelectQuery, GetUsersSelectQueryVariables>;
export const GetUserIdDocument = gql`
    query GetUserId($firebaseId: String!) {
  users(where: {uid: {_eq: $firebaseId}}) {
    id
  }
}
    `;

/**
 * __useGetUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdQuery({
 *   variables: {
 *      firebaseId: // value for 'firebaseId'
 *   },
 * });
 */
export function useGetUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserIdQuery, GetUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserIdQuery, GetUserIdQueryVariables>(GetUserIdDocument, options);
      }
export function useGetUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserIdQuery, GetUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserIdQuery, GetUserIdQueryVariables>(GetUserIdDocument, options);
        }
export type GetUserIdQueryHookResult = ReturnType<typeof useGetUserIdQuery>;
export type GetUserIdLazyQueryHookResult = ReturnType<typeof useGetUserIdLazyQuery>;
export type GetUserIdQueryResult = Apollo.QueryResult<GetUserIdQuery, GetUserIdQueryVariables>;
export const GetAccountDetailsDocument = gql`
    query GetAccountDetails($id: Int!) {
  ticket: account_tickets(
    where: {_and: [{statement_account_id: {_eq: $id}}, {open: {_eq: true}}]}
  ) {
    id
  }
  palapa_accounts(where: {statement_id: {_eq: $id}}) {
    statement_id
    commensal_type
    balance
    orders(where: {status: {_eq: "open"}}) {
      id
      total
      created_at
      order_dishes(order_by: {disabled: asc}) {
        order_dish_ingredients {
          enabled
          ingredient {
            name
          }
        }
        order_dish_modifier_groups {
          modifier_group {
            id
            title
          }
          order_dish_modifiers {
            id
            modifier {
              title
            }
          }
        }
        id
        disabled
        quantity
        dish_price
        dish {
          name
          price
        }
      }
    }
  }
}
    `;

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
      }
export function useGetAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
        }
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
export type GetAccountDetailsQueryResult = Apollo.QueryResult<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>;
export const CloseAccountDocument = gql`
    mutation CloseAccount($accountId: Int!, $transactions: [transactions_insert_input!]!, $ordersFlag: orders_bool_exp!, $actions: [orders_actions_insert_input!]!, $ticket: account_tickets_set_input!, $ticketId: Int!) {
  update_statement_account_by_pk(
    pk_columns: {id: $accountId}
    _set: {open: false}
  ) {
    id
    open
  }
  update_account_tickets_by_pk(pk_columns: {id: $ticketId}, _set: $ticket) {
    id
    open
  }
  insert_transactions(objects: $transactions) {
    returning {
      transaction_id
    }
  }
  update_orders(where: $ordersFlag, _set: {status: "closed"}) {
    affected_rows
  }
  insert_orders_actions(objects: $actions) {
    returning {
      id
    }
  }
}
    `;
export type CloseAccountMutationFn = Apollo.MutationFunction<CloseAccountMutation, CloseAccountMutationVariables>;

/**
 * __useCloseAccountMutation__
 *
 * To run a mutation, you first call `useCloseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAccountMutation, { data, loading, error }] = useCloseAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      transactions: // value for 'transactions'
 *      ordersFlag: // value for 'ordersFlag'
 *      actions: // value for 'actions'
 *      ticket: // value for 'ticket'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useCloseAccountMutation(baseOptions?: Apollo.MutationHookOptions<CloseAccountMutation, CloseAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseAccountMutation, CloseAccountMutationVariables>(CloseAccountDocument, options);
      }
export type CloseAccountMutationHookResult = ReturnType<typeof useCloseAccountMutation>;
export type CloseAccountMutationResult = Apollo.MutationResult<CloseAccountMutation>;
export type CloseAccountMutationOptions = Apollo.BaseMutationOptions<CloseAccountMutation, CloseAccountMutationVariables>;
export const CancelAccountDocument = gql`
    mutation CancelAccount($accountId: Int!, $ordersFlag: orders_bool_exp!, $actions: [orders_actions_insert_input!]!, $dishesFlag: order_dish_bool_exp!, $ticketId: Int!) {
  update_statement_account_by_pk(
    pk_columns: {id: $accountId}
    _set: {open: false}
  ) {
    id
  }
  update_account_tickets_by_pk(pk_columns: {id: $ticketId}, _set: {open: false}) {
    id
  }
  update_orders(where: $ordersFlag, _set: {status: "canceled"}) {
    affected_rows
  }
  insert_orders_actions(objects: $actions) {
    returning {
      id
    }
  }
  update_order_dish(where: $dishesFlag, _set: {disabled: true}) {
    affected_rows
  }
}
    `;
export type CancelAccountMutationFn = Apollo.MutationFunction<CancelAccountMutation, CancelAccountMutationVariables>;

/**
 * __useCancelAccountMutation__
 *
 * To run a mutation, you first call `useCancelAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAccountMutation, { data, loading, error }] = useCancelAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      ordersFlag: // value for 'ordersFlag'
 *      actions: // value for 'actions'
 *      dishesFlag: // value for 'dishesFlag'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useCancelAccountMutation(baseOptions?: Apollo.MutationHookOptions<CancelAccountMutation, CancelAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAccountMutation, CancelAccountMutationVariables>(CancelAccountDocument, options);
      }
export type CancelAccountMutationHookResult = ReturnType<typeof useCancelAccountMutation>;
export type CancelAccountMutationResult = Apollo.MutationResult<CancelAccountMutation>;
export type CancelAccountMutationOptions = Apollo.BaseMutationOptions<CancelAccountMutation, CancelAccountMutationVariables>;
export const GetOpenAccountsDocument = gql`
    query GetOpenAccounts($searchTerm: String!, $limit: Int!, $offset: Int!) {
  aggregate: palapa_accounts_aggregate(
    where: {open: {_eq: true}, _or: [{apartment_name: {_like: $searchTerm}}, {commensal_name: {_like: $searchTerm}}]}
  ) {
    aggregate {
      count
    }
  }
  accounts: palapa_accounts(
    limit: $limit
    offset: $offset
    order_by: {commensal_name: asc}
    where: {open: {_eq: true}, _or: [{apartment_name: {_like: $searchTerm}}, {commensal_name: {_like: $searchTerm}}]}
  ) {
    statement_id
    commensal_name
    apartment_name
    commensal_type
    balance
    orders(where: {status: {_eq: "open"}}) {
      id
      order_dishes {
        dish {
          name
          price
        }
      }
    }
  }
}
    `;

/**
 * __useGetOpenAccountsQuery__
 *
 * To run a query within a React component, call `useGetOpenAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAccountsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOpenAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetOpenAccountsQuery, GetOpenAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOpenAccountsQuery, GetOpenAccountsQueryVariables>(GetOpenAccountsDocument, options);
      }
export function useGetOpenAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenAccountsQuery, GetOpenAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOpenAccountsQuery, GetOpenAccountsQueryVariables>(GetOpenAccountsDocument, options);
        }
export type GetOpenAccountsQueryHookResult = ReturnType<typeof useGetOpenAccountsQuery>;
export type GetOpenAccountsLazyQueryHookResult = ReturnType<typeof useGetOpenAccountsLazyQuery>;
export type GetOpenAccountsQueryResult = Apollo.QueryResult<GetOpenAccountsQuery, GetOpenAccountsQueryVariables>;
export const CancelDishDocument = gql`
    mutation CancelDish($id: Int!, $orderId: Int!, $action: String!) {
  update_order_dish_by_pk(pk_columns: {id: $id}, _set: {disabled: true}) {
    id
    disabled
  }
  insert_orders_actions_one(object: {order_id: $orderId, type: $action}) {
    id
    type
  }
}
    `;
export type CancelDishMutationFn = Apollo.MutationFunction<CancelDishMutation, CancelDishMutationVariables>;

/**
 * __useCancelDishMutation__
 *
 * To run a mutation, you first call `useCancelDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDishMutation, { data, loading, error }] = useCancelDishMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderId: // value for 'orderId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useCancelDishMutation(baseOptions?: Apollo.MutationHookOptions<CancelDishMutation, CancelDishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelDishMutation, CancelDishMutationVariables>(CancelDishDocument, options);
      }
export type CancelDishMutationHookResult = ReturnType<typeof useCancelDishMutation>;
export type CancelDishMutationResult = Apollo.MutationResult<CancelDishMutation>;
export type CancelDishMutationOptions = Apollo.BaseMutationOptions<CancelDishMutation, CancelDishMutationVariables>;
export const CancelOrderDocument = gql`
    mutation CancelOrder($orderId: Int!, $action: String!, $dishesFlag: order_dish_bool_exp!) {
  update_orders_by_pk(pk_columns: {id: $orderId}, _set: {status: "canceled"}) {
    id
    status
  }
  insert_orders_actions_one(object: {order_id: $orderId, type: $action}) {
    id
    type
  }
  update_order_dish(where: $dishesFlag, _set: {disabled: true}) {
    affected_rows
  }
}
    `;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      action: // value for 'action'
 *      dishesFlag: // value for 'dishesFlag'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const OpenAccountDocument = gql`
    mutation OpenAccount($id: Int!) {
  update_statement_account_by_pk(pk_columns: {id: $id}, _set: {open: true}) {
    id
    open
  }
}
    `;
export type OpenAccountMutationFn = Apollo.MutationFunction<OpenAccountMutation, OpenAccountMutationVariables>;

/**
 * __useOpenAccountMutation__
 *
 * To run a mutation, you first call `useOpenAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openAccountMutation, { data, loading, error }] = useOpenAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenAccountMutation(baseOptions?: Apollo.MutationHookOptions<OpenAccountMutation, OpenAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenAccountMutation, OpenAccountMutationVariables>(OpenAccountDocument, options);
      }
export type OpenAccountMutationHookResult = ReturnType<typeof useOpenAccountMutation>;
export type OpenAccountMutationResult = Apollo.MutationResult<OpenAccountMutation>;
export type OpenAccountMutationOptions = Apollo.BaseMutationOptions<OpenAccountMutation, OpenAccountMutationVariables>;
export const CreateAccountTicketDocument = gql`
    mutation CreateAccountTicket($data: account_tickets_insert_input!) {
  insert_account_tickets_one(object: $data) {
    id
  }
}
    `;
export type CreateAccountTicketMutationFn = Apollo.MutationFunction<CreateAccountTicketMutation, CreateAccountTicketMutationVariables>;

/**
 * __useCreateAccountTicketMutation__
 *
 * To run a mutation, you first call `useCreateAccountTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountTicketMutation, { data, loading, error }] = useCreateAccountTicketMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccountTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountTicketMutation, CreateAccountTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountTicketMutation, CreateAccountTicketMutationVariables>(CreateAccountTicketDocument, options);
      }
export type CreateAccountTicketMutationHookResult = ReturnType<typeof useCreateAccountTicketMutation>;
export type CreateAccountTicketMutationResult = Apollo.MutationResult<CreateAccountTicketMutation>;
export type CreateAccountTicketMutationOptions = Apollo.BaseMutationOptions<CreateAccountTicketMutation, CreateAccountTicketMutationVariables>;
export const GetTablesDocument = gql`
    query GetTables {
  tables(where: {disabled: {_eq: false}}) {
    id
    name
  }
}
    `;

/**
 * __useGetTablesQuery__
 *
 * To run a query within a React component, call `useGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTablesQuery(baseOptions?: Apollo.QueryHookOptions<GetTablesQuery, GetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options);
      }
export function useGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTablesQuery, GetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options);
        }
export type GetTablesQueryHookResult = ReturnType<typeof useGetTablesQuery>;
export type GetTablesLazyQueryHookResult = ReturnType<typeof useGetTablesLazyQuery>;
export type GetTablesQueryResult = Apollo.QueryResult<GetTablesQuery, GetTablesQueryVariables>;
export const GetAdminApartmentsDocument = gql`
    query GetAdminApartments($limit: Int!, $offset: Int!, $searchString: String!) {
  apartments_helper_aggregate(
    where: {_or: [{owner: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}]}
  ) {
    aggregate {
      count
    }
  }
  apartments_helper(
    order_by: [{name: asc}, {number: asc}]
    limit: $limit
    offset: $offset
    where: {_or: [{owner: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}]}
  ) {
    owner_id
    apartment_id
    apartment_name
    balance
    balance_retained
    owner
  }
}
    `;

/**
 * __useGetAdminApartmentsQuery__
 *
 * To run a query within a React component, call `useGetAdminApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminApartmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetAdminApartmentsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables>(GetAdminApartmentsDocument, options);
      }
export function useGetAdminApartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables>(GetAdminApartmentsDocument, options);
        }
export type GetAdminApartmentsQueryHookResult = ReturnType<typeof useGetAdminApartmentsQuery>;
export type GetAdminApartmentsLazyQueryHookResult = ReturnType<typeof useGetAdminApartmentsLazyQuery>;
export type GetAdminApartmentsQueryResult = Apollo.QueryResult<GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables>;
export const SetApartmentOwnerDocument = gql`
    mutation SetApartmentOwner($apartment_id: Int!, $owner_id: Int!) {
  update_apartment_by_pk(
    pk_columns: {id: $apartment_id}
    _set: {user_id: $owner_id}
  ) {
    id
  }
}
    `;
export type SetApartmentOwnerMutationFn = Apollo.MutationFunction<SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables>;

/**
 * __useSetApartmentOwnerMutation__
 *
 * To run a mutation, you first call `useSetApartmentOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApartmentOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApartmentOwnerMutation, { data, loading, error }] = useSetApartmentOwnerMutation({
 *   variables: {
 *      apartment_id: // value for 'apartment_id'
 *      owner_id: // value for 'owner_id'
 *   },
 * });
 */
export function useSetApartmentOwnerMutation(baseOptions?: Apollo.MutationHookOptions<SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables>(SetApartmentOwnerDocument, options);
      }
export type SetApartmentOwnerMutationHookResult = ReturnType<typeof useSetApartmentOwnerMutation>;
export type SetApartmentOwnerMutationResult = Apollo.MutationResult<SetApartmentOwnerMutation>;
export type SetApartmentOwnerMutationOptions = Apollo.BaseMutationOptions<SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables>;
export const InsertOrderDocument = gql`
    mutation InsertOrder($data: orders_insert_input!) {
  insert_orders_one(object: $data) {
    id
  }
}
    `;
export type InsertOrderMutationFn = Apollo.MutationFunction<InsertOrderMutation, InsertOrderMutationVariables>;

/**
 * __useInsertOrderMutation__
 *
 * To run a mutation, you first call `useInsertOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderMutation, { data, loading, error }] = useInsertOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertOrderMutation(baseOptions?: Apollo.MutationHookOptions<InsertOrderMutation, InsertOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOrderMutation, InsertOrderMutationVariables>(InsertOrderDocument, options);
      }
export type InsertOrderMutationHookResult = ReturnType<typeof useInsertOrderMutation>;
export type InsertOrderMutationResult = Apollo.MutationResult<InsertOrderMutation>;
export type InsertOrderMutationOptions = Apollo.BaseMutationOptions<InsertOrderMutation, InsertOrderMutationVariables>;
export const GetCategoriesDishesDocument = gql`
    query GetCategoriesDishes {
  menu_types(order_by: {position: asc}, where: {disabled: {_eq: false}}) {
    id
    name
    categories(order_by: {position: asc}, where: {disabled: {_eq: false}}) {
      id
      name
      subcategories(order_by: {position: asc}, where: {disabled: {_eq: false}}) {
        id
        name
        dishes {
          id
          name
          price
          cost
          description
          modifier_group_dishes {
            id
            modifier_group_id
            modifier_group {
              title
              multiple
              modifiers {
                id
                title
              }
            }
          }
          ingredient_dishes {
            optional
            ingredient {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoriesDishesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesDishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesDishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesDishesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesDishesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesDishesQuery, GetCategoriesDishesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesDishesQuery, GetCategoriesDishesQueryVariables>(GetCategoriesDishesDocument, options);
      }
export function useGetCategoriesDishesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesDishesQuery, GetCategoriesDishesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesDishesQuery, GetCategoriesDishesQueryVariables>(GetCategoriesDishesDocument, options);
        }
export type GetCategoriesDishesQueryHookResult = ReturnType<typeof useGetCategoriesDishesQuery>;
export type GetCategoriesDishesLazyQueryHookResult = ReturnType<typeof useGetCategoriesDishesLazyQuery>;
export type GetCategoriesDishesQueryResult = Apollo.QueryResult<GetCategoriesDishesQuery, GetCategoriesDishesQueryVariables>;
export const GetOrdersDocument = gql`
    query getOrders {
  orders {
    id
    status
    statement_account_id
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetUnitsDocument = gql`
    query GetUnits {
  units(order_by: {name: asc}) {
    id
    name
    abreviation
  }
}
    `;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export const InsertIngredientsDocument = gql`
    mutation InsertIngredients($data: [ingredients_insert_input!]!) {
  insert_ingredients(objects: $data) {
    returning {
      id
    }
  }
}
    `;
export type InsertIngredientsMutationFn = Apollo.MutationFunction<InsertIngredientsMutation, InsertIngredientsMutationVariables>;

/**
 * __useInsertIngredientsMutation__
 *
 * To run a mutation, you first call `useInsertIngredientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIngredientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIngredientsMutation, { data, loading, error }] = useInsertIngredientsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertIngredientsMutation(baseOptions?: Apollo.MutationHookOptions<InsertIngredientsMutation, InsertIngredientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertIngredientsMutation, InsertIngredientsMutationVariables>(InsertIngredientsDocument, options);
      }
export type InsertIngredientsMutationHookResult = ReturnType<typeof useInsertIngredientsMutation>;
export type InsertIngredientsMutationResult = Apollo.MutationResult<InsertIngredientsMutation>;
export type InsertIngredientsMutationOptions = Apollo.BaseMutationOptions<InsertIngredientsMutation, InsertIngredientsMutationVariables>;
export const GetIngredientsDocument = gql`
    query GetIngredients {
  ingredients(order_by: {name: asc}) {
    id
    name
    unit
    quantity
    inventory
    available
    unitObject {
      name
      abreviation
    }
  }
}
    `;

/**
 * __useGetIngredientsQuery__
 *
 * To run a query within a React component, call `useGetIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIngredientsQuery(baseOptions?: Apollo.QueryHookOptions<GetIngredientsQuery, GetIngredientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(GetIngredientsDocument, options);
      }
export function useGetIngredientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIngredientsQuery, GetIngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(GetIngredientsDocument, options);
        }
export type GetIngredientsQueryHookResult = ReturnType<typeof useGetIngredientsQuery>;
export type GetIngredientsLazyQueryHookResult = ReturnType<typeof useGetIngredientsLazyQuery>;
export type GetIngredientsQueryResult = Apollo.QueryResult<GetIngredientsQuery, GetIngredientsQueryVariables>;
export const UpdateDishDocument = gql`
    mutation UpdateDish($id: Int!, $dish: dishes_set_input!, $ingredients: [ingredient_dish_insert_input!]!, $modifiers: [modifier_group_dish_insert_input!]!) {
  update_dishes_by_pk(pk_columns: {id: $id}, _set: $dish) {
    id
  }
  delete_ingredient_dish(where: {dish_id: {_eq: $id}}) {
    affected_rows
  }
  delete_modifier_group_dish(where: {dish_id: {_eq: $id}}) {
    affected_rows
  }
  insert_modifier_group_dish(objects: $modifiers) {
    affected_rows
  }
  insert_ingredient_dish(objects: $ingredients) {
    affected_rows
  }
}
    `;
export type UpdateDishMutationFn = Apollo.MutationFunction<UpdateDishMutation, UpdateDishMutationVariables>;

/**
 * __useUpdateDishMutation__
 *
 * To run a mutation, you first call `useUpdateDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDishMutation, { data, loading, error }] = useUpdateDishMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dish: // value for 'dish'
 *      ingredients: // value for 'ingredients'
 *      modifiers: // value for 'modifiers'
 *   },
 * });
 */
export function useUpdateDishMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDishMutation, UpdateDishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDishMutation, UpdateDishMutationVariables>(UpdateDishDocument, options);
      }
export type UpdateDishMutationHookResult = ReturnType<typeof useUpdateDishMutation>;
export type UpdateDishMutationResult = Apollo.MutationResult<UpdateDishMutation>;
export type UpdateDishMutationOptions = Apollo.BaseMutationOptions<UpdateDishMutation, UpdateDishMutationVariables>;
export const InsertDishesDocument = gql`
    mutation InsertDishes($data: [dishes_insert_input!]!) {
  insert_dishes(objects: $data) {
    returning {
      id
    }
  }
}
    `;
export type InsertDishesMutationFn = Apollo.MutationFunction<InsertDishesMutation, InsertDishesMutationVariables>;

/**
 * __useInsertDishesMutation__
 *
 * To run a mutation, you first call `useInsertDishesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDishesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDishesMutation, { data, loading, error }] = useInsertDishesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertDishesMutation(baseOptions?: Apollo.MutationHookOptions<InsertDishesMutation, InsertDishesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDishesMutation, InsertDishesMutationVariables>(InsertDishesDocument, options);
      }
export type InsertDishesMutationHookResult = ReturnType<typeof useInsertDishesMutation>;
export type InsertDishesMutationResult = Apollo.MutationResult<InsertDishesMutation>;
export type InsertDishesMutationOptions = Apollo.BaseMutationOptions<InsertDishesMutation, InsertDishesMutationVariables>;
export const CreateModifierDocument = gql`
    mutation CreateModifier($data: modifier_groups_insert_input!) {
  insert_modifier_groups_one(object: $data) {
    id
  }
}
    `;
export type CreateModifierMutationFn = Apollo.MutationFunction<CreateModifierMutation, CreateModifierMutationVariables>;

/**
 * __useCreateModifierMutation__
 *
 * To run a mutation, you first call `useCreateModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModifierMutation, { data, loading, error }] = useCreateModifierMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateModifierMutation(baseOptions?: Apollo.MutationHookOptions<CreateModifierMutation, CreateModifierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModifierMutation, CreateModifierMutationVariables>(CreateModifierDocument, options);
      }
export type CreateModifierMutationHookResult = ReturnType<typeof useCreateModifierMutation>;
export type CreateModifierMutationResult = Apollo.MutationResult<CreateModifierMutation>;
export type CreateModifierMutationOptions = Apollo.BaseMutationOptions<CreateModifierMutation, CreateModifierMutationVariables>;
export const InsertMenuDocument = gql`
    mutation InsertMenu($data: menu_types_insert_input!) {
  insert_menu_types_one(object: $data) {
    id
  }
}
    `;
export type InsertMenuMutationFn = Apollo.MutationFunction<InsertMenuMutation, InsertMenuMutationVariables>;

/**
 * __useInsertMenuMutation__
 *
 * To run a mutation, you first call `useInsertMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMenuMutation, { data, loading, error }] = useInsertMenuMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertMenuMutation(baseOptions?: Apollo.MutationHookOptions<InsertMenuMutation, InsertMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMenuMutation, InsertMenuMutationVariables>(InsertMenuDocument, options);
      }
export type InsertMenuMutationHookResult = ReturnType<typeof useInsertMenuMutation>;
export type InsertMenuMutationResult = Apollo.MutationResult<InsertMenuMutation>;
export type InsertMenuMutationOptions = Apollo.BaseMutationOptions<InsertMenuMutation, InsertMenuMutationVariables>;
export const InsertCategoryDocument = gql`
    mutation InsertCategory($data: categories_insert_input!) {
  insert_categories_one(object: $data) {
    id
  }
}
    `;
export type InsertCategoryMutationFn = Apollo.MutationFunction<InsertCategoryMutation, InsertCategoryMutationVariables>;

/**
 * __useInsertCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCategoryMutation, { data, loading, error }] = useInsertCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertCategoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertCategoryMutation, InsertCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCategoryMutation, InsertCategoryMutationVariables>(InsertCategoryDocument, options);
      }
export type InsertCategoryMutationHookResult = ReturnType<typeof useInsertCategoryMutation>;
export type InsertCategoryMutationResult = Apollo.MutationResult<InsertCategoryMutation>;
export type InsertCategoryMutationOptions = Apollo.BaseMutationOptions<InsertCategoryMutation, InsertCategoryMutationVariables>;
export const InsertSubcategoryDocument = gql`
    mutation InsertSubcategory($data: subcategories_insert_input!) {
  insert_subcategories_one(object: $data) {
    id
  }
}
    `;
export type InsertSubcategoryMutationFn = Apollo.MutationFunction<InsertSubcategoryMutation, InsertSubcategoryMutationVariables>;

/**
 * __useInsertSubcategoryMutation__
 *
 * To run a mutation, you first call `useInsertSubcategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSubcategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSubcategoryMutation, { data, loading, error }] = useInsertSubcategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertSubcategoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertSubcategoryMutation, InsertSubcategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSubcategoryMutation, InsertSubcategoryMutationVariables>(InsertSubcategoryDocument, options);
      }
export type InsertSubcategoryMutationHookResult = ReturnType<typeof useInsertSubcategoryMutation>;
export type InsertSubcategoryMutationResult = Apollo.MutationResult<InsertSubcategoryMutation>;
export type InsertSubcategoryMutationOptions = Apollo.BaseMutationOptions<InsertSubcategoryMutation, InsertSubcategoryMutationVariables>;
export const GetMenusDocument = gql`
    query GetMenus {
  menus: menu_types(order_by: {position: asc}) {
    id
    name
    position
    disabled
    categories(order_by: {position: asc}) {
      id
      name
      position
      disabled
      subcategories(order_by: {position: asc}) {
        id
        name
        position
        disabled
      }
    }
  }
}
    `;

/**
 * __useGetMenusQuery__
 *
 * To run a query within a React component, call `useGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenusQuery(baseOptions?: Apollo.QueryHookOptions<GetMenusQuery, GetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenusQuery, GetMenusQueryVariables>(GetMenusDocument, options);
      }
export function useGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenusQuery, GetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenusQuery, GetMenusQueryVariables>(GetMenusDocument, options);
        }
export type GetMenusQueryHookResult = ReturnType<typeof useGetMenusQuery>;
export type GetMenusLazyQueryHookResult = ReturnType<typeof useGetMenusLazyQuery>;
export type GetMenusQueryResult = Apollo.QueryResult<GetMenusQuery, GetMenusQueryVariables>;
export const UpdateMenuDocument = gql`
    mutation UpdateMenu($id: Int!, $data: menu_types_set_input!) {
  update_menu_types_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateMenuMutationFn = Apollo.MutationFunction<UpdateMenuMutation, UpdateMenuMutationVariables>;

/**
 * __useUpdateMenuMutation__
 *
 * To run a mutation, you first call `useUpdateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuMutation, { data, loading, error }] = useUpdateMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMenuMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuMutation, UpdateMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuMutation, UpdateMenuMutationVariables>(UpdateMenuDocument, options);
      }
export type UpdateMenuMutationHookResult = ReturnType<typeof useUpdateMenuMutation>;
export type UpdateMenuMutationResult = Apollo.MutationResult<UpdateMenuMutation>;
export type UpdateMenuMutationOptions = Apollo.BaseMutationOptions<UpdateMenuMutation, UpdateMenuMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: Int!, $data: categories_set_input!) {
  update_categories_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateSubcategoryDocument = gql`
    mutation UpdateSubcategory($id: Int!, $data: subcategories_set_input!) {
  update_subcategories_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateSubcategoryMutationFn = Apollo.MutationFunction<UpdateSubcategoryMutation, UpdateSubcategoryMutationVariables>;

/**
 * __useUpdateSubcategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSubcategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcategoryMutation, { data, loading, error }] = useUpdateSubcategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSubcategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubcategoryMutation, UpdateSubcategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubcategoryMutation, UpdateSubcategoryMutationVariables>(UpdateSubcategoryDocument, options);
      }
export type UpdateSubcategoryMutationHookResult = ReturnType<typeof useUpdateSubcategoryMutation>;
export type UpdateSubcategoryMutationResult = Apollo.MutationResult<UpdateSubcategoryMutation>;
export type UpdateSubcategoryMutationOptions = Apollo.BaseMutationOptions<UpdateSubcategoryMutation, UpdateSubcategoryMutationVariables>;
export const GetMenuDocument = gql`
    query GetMenu($offset: Int!, $limit: Int!, $searchString: String) {
  aggregate: dishes_aggregate(where: {name: {_like: $searchString}}) {
    aggregate {
      count
    }
  }
  dishes(
    offset: $offset
    limit: $limit
    where: {name: {_ilike: $searchString}}
    order_by: {name: asc}
  ) {
    id
    cost
    name
    price
    description
    modifier_group_dishes {
      id
      modifier_group {
        id
        title
        multiple
        modifiers {
          title
          id
        }
      }
    }
    subcategory {
      id
      name
      category {
        id
        name
        menu_type {
          id
          name
        }
      }
    }
    ingredient_dishes {
      optional
      quantity
      ingredient {
        name
        id
        unitObject {
          name
          abreviation
        }
      }
    }
  }
}
    `;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetMenuQuery(baseOptions: Apollo.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
      }
export function useGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
        }
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = Apollo.QueryResult<GetMenuQuery, GetMenuQueryVariables>;
export const GetModifiersDocument = gql`
    query GetModifiers($offset: Int!, $limit: Int!) {
  modifier_groups_aggregate {
    aggregate {
      count
    }
  }
  modifier_groups(order_by: {title: asc}, limit: $limit, offset: $offset) {
    id
    title
    multiple
    modifiers {
      title
    }
  }
}
    `;

/**
 * __useGetModifiersQuery__
 *
 * To run a query within a React component, call `useGetModifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModifiersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetModifiersQuery(baseOptions: Apollo.QueryHookOptions<GetModifiersQuery, GetModifiersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModifiersQuery, GetModifiersQueryVariables>(GetModifiersDocument, options);
      }
export function useGetModifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModifiersQuery, GetModifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModifiersQuery, GetModifiersQueryVariables>(GetModifiersDocument, options);
        }
export type GetModifiersQueryHookResult = ReturnType<typeof useGetModifiersQuery>;
export type GetModifiersLazyQueryHookResult = ReturnType<typeof useGetModifiersLazyQuery>;
export type GetModifiersQueryResult = Apollo.QueryResult<GetModifiersQuery, GetModifiersQueryVariables>;
export const UpdateModifierGroupDocument = gql`
    mutation UpdateModifierGroup($id: Int!, $multiple: Boolean!) {
  update_modifier_groups_by_pk(pk_columns: {id: $id}, _set: {multiple: $multiple}) {
    id
  }
}
    `;
export type UpdateModifierGroupMutationFn = Apollo.MutationFunction<UpdateModifierGroupMutation, UpdateModifierGroupMutationVariables>;

/**
 * __useUpdateModifierGroupMutation__
 *
 * To run a mutation, you first call `useUpdateModifierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModifierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModifierGroupMutation, { data, loading, error }] = useUpdateModifierGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      multiple: // value for 'multiple'
 *   },
 * });
 */
export function useUpdateModifierGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModifierGroupMutation, UpdateModifierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModifierGroupMutation, UpdateModifierGroupMutationVariables>(UpdateModifierGroupDocument, options);
      }
export type UpdateModifierGroupMutationHookResult = ReturnType<typeof useUpdateModifierGroupMutation>;
export type UpdateModifierGroupMutationResult = Apollo.MutationResult<UpdateModifierGroupMutation>;
export type UpdateModifierGroupMutationOptions = Apollo.BaseMutationOptions<UpdateModifierGroupMutation, UpdateModifierGroupMutationVariables>;
export const UpdateMenuNameDocument = gql`
    mutation UpdateMenuName($id: Int!, $name: String!) {
  update_menu_types_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    name
  }
}
    `;
export type UpdateMenuNameMutationFn = Apollo.MutationFunction<UpdateMenuNameMutation, UpdateMenuNameMutationVariables>;

/**
 * __useUpdateMenuNameMutation__
 *
 * To run a mutation, you first call `useUpdateMenuNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuNameMutation, { data, loading, error }] = useUpdateMenuNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateMenuNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuNameMutation, UpdateMenuNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuNameMutation, UpdateMenuNameMutationVariables>(UpdateMenuNameDocument, options);
      }
export type UpdateMenuNameMutationHookResult = ReturnType<typeof useUpdateMenuNameMutation>;
export type UpdateMenuNameMutationResult = Apollo.MutationResult<UpdateMenuNameMutation>;
export type UpdateMenuNameMutationOptions = Apollo.BaseMutationOptions<UpdateMenuNameMutation, UpdateMenuNameMutationVariables>;
export const UpdateCategoryNameDocument = gql`
    mutation UpdateCategoryName($id: Int!, $name: String!) {
  update_categories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    name
  }
}
    `;
export type UpdateCategoryNameMutationFn = Apollo.MutationFunction<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>;

/**
 * __useUpdateCategoryNameMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryNameMutation, { data, loading, error }] = useUpdateCategoryNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCategoryNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>(UpdateCategoryNameDocument, options);
      }
export type UpdateCategoryNameMutationHookResult = ReturnType<typeof useUpdateCategoryNameMutation>;
export type UpdateCategoryNameMutationResult = Apollo.MutationResult<UpdateCategoryNameMutation>;
export type UpdateCategoryNameMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryNameMutation, UpdateCategoryNameMutationVariables>;
export const UpdateSubcategoryNameDocument = gql`
    mutation UpdateSubcategoryName($id: Int!, $name: String!) {
  update_subcategories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    name
  }
}
    `;
export type UpdateSubcategoryNameMutationFn = Apollo.MutationFunction<UpdateSubcategoryNameMutation, UpdateSubcategoryNameMutationVariables>;

/**
 * __useUpdateSubcategoryNameMutation__
 *
 * To run a mutation, you first call `useUpdateSubcategoryNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcategoryNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcategoryNameMutation, { data, loading, error }] = useUpdateSubcategoryNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSubcategoryNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubcategoryNameMutation, UpdateSubcategoryNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubcategoryNameMutation, UpdateSubcategoryNameMutationVariables>(UpdateSubcategoryNameDocument, options);
      }
export type UpdateSubcategoryNameMutationHookResult = ReturnType<typeof useUpdateSubcategoryNameMutation>;
export type UpdateSubcategoryNameMutationResult = Apollo.MutationResult<UpdateSubcategoryNameMutation>;
export type UpdateSubcategoryNameMutationOptions = Apollo.BaseMutationOptions<UpdateSubcategoryNameMutation, UpdateSubcategoryNameMutationVariables>;
export const GetAddDishIngredientsDocument = gql`
    query GetAddDishIngredients {
  ingredients(order_by: {name: asc}) {
    id
    name
    available
    unitObject {
      name
      abreviation
    }
  }
}
    `;

/**
 * __useGetAddDishIngredientsQuery__
 *
 * To run a query within a React component, call `useGetAddDishIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddDishIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddDishIngredientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddDishIngredientsQuery(baseOptions?: Apollo.QueryHookOptions<GetAddDishIngredientsQuery, GetAddDishIngredientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddDishIngredientsQuery, GetAddDishIngredientsQueryVariables>(GetAddDishIngredientsDocument, options);
      }
export function useGetAddDishIngredientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddDishIngredientsQuery, GetAddDishIngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddDishIngredientsQuery, GetAddDishIngredientsQueryVariables>(GetAddDishIngredientsDocument, options);
        }
export type GetAddDishIngredientsQueryHookResult = ReturnType<typeof useGetAddDishIngredientsQuery>;
export type GetAddDishIngredientsLazyQueryHookResult = ReturnType<typeof useGetAddDishIngredientsLazyQuery>;
export type GetAddDishIngredientsQueryResult = Apollo.QueryResult<GetAddDishIngredientsQuery, GetAddDishIngredientsQueryVariables>;
export const GetModifierGroupsDocument = gql`
    query GetModifierGroups {
  modifier_groups {
    id
    title
    multiple
    modifiers {
      title
    }
  }
}
    `;

/**
 * __useGetModifierGroupsQuery__
 *
 * To run a query within a React component, call `useGetModifierGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModifierGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModifierGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModifierGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetModifierGroupsQuery, GetModifierGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModifierGroupsQuery, GetModifierGroupsQueryVariables>(GetModifierGroupsDocument, options);
      }
export function useGetModifierGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModifierGroupsQuery, GetModifierGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModifierGroupsQuery, GetModifierGroupsQueryVariables>(GetModifierGroupsDocument, options);
        }
export type GetModifierGroupsQueryHookResult = ReturnType<typeof useGetModifierGroupsQuery>;
export type GetModifierGroupsLazyQueryHookResult = ReturnType<typeof useGetModifierGroupsLazyQuery>;
export type GetModifierGroupsQueryResult = Apollo.QueryResult<GetModifierGroupsQuery, GetModifierGroupsQueryVariables>;
export const GetPrinterInfoDocument = gql`
    query GetPrinterInfo($id: Int!) {
  info: printers_by_pk(id: $id) {
    name
    id
    address
  }
}
    `;

/**
 * __useGetPrinterInfoQuery__
 *
 * To run a query within a React component, call `useGetPrinterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrinterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrinterInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrinterInfoQuery(baseOptions: Apollo.QueryHookOptions<GetPrinterInfoQuery, GetPrinterInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrinterInfoQuery, GetPrinterInfoQueryVariables>(GetPrinterInfoDocument, options);
      }
export function useGetPrinterInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrinterInfoQuery, GetPrinterInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrinterInfoQuery, GetPrinterInfoQueryVariables>(GetPrinterInfoDocument, options);
        }
export type GetPrinterInfoQueryHookResult = ReturnType<typeof useGetPrinterInfoQuery>;
export type GetPrinterInfoLazyQueryHookResult = ReturnType<typeof useGetPrinterInfoLazyQuery>;
export type GetPrinterInfoQueryResult = Apollo.QueryResult<GetPrinterInfoQuery, GetPrinterInfoQueryVariables>;
export const GetPrintersDocument = gql`
    query GetPrinters {
  printers {
    id
    name
    address
    disabled
  }
}
    `;

/**
 * __useGetPrintersQuery__
 *
 * To run a query within a React component, call `useGetPrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrintersQuery(baseOptions?: Apollo.QueryHookOptions<GetPrintersQuery, GetPrintersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintersQuery, GetPrintersQueryVariables>(GetPrintersDocument, options);
      }
export function useGetPrintersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintersQuery, GetPrintersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintersQuery, GetPrintersQueryVariables>(GetPrintersDocument, options);
        }
export type GetPrintersQueryHookResult = ReturnType<typeof useGetPrintersQuery>;
export type GetPrintersLazyQueryHookResult = ReturnType<typeof useGetPrintersLazyQuery>;
export type GetPrintersQueryResult = Apollo.QueryResult<GetPrintersQuery, GetPrintersQueryVariables>;
export const UpdatePrinterDocument = gql`
    mutation UpdatePrinter($ip: String!, $name: String!, $id: Int!) {
  printer: update_printers_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, address: $ip}
  ) {
    id
  }
}
    `;
export type UpdatePrinterMutationFn = Apollo.MutationFunction<UpdatePrinterMutation, UpdatePrinterMutationVariables>;

/**
 * __useUpdatePrinterMutation__
 *
 * To run a mutation, you first call `useUpdatePrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrinterMutation, { data, loading, error }] = useUpdatePrinterMutation({
 *   variables: {
 *      ip: // value for 'ip'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePrinterMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrinterMutation, UpdatePrinterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrinterMutation, UpdatePrinterMutationVariables>(UpdatePrinterDocument, options);
      }
export type UpdatePrinterMutationHookResult = ReturnType<typeof useUpdatePrinterMutation>;
export type UpdatePrinterMutationResult = Apollo.MutationResult<UpdatePrinterMutation>;
export type UpdatePrinterMutationOptions = Apollo.BaseMutationOptions<UpdatePrinterMutation, UpdatePrinterMutationVariables>;
export const GetPrinterLogsDocument = gql`
    query GetPrinterLogs($id: Int!, $offset: Int!, $limit: Int!) {
  logs_length: printer_logs_aggregate(where: {printer_id: {_eq: $id}}) {
    aggregate {
      count
    }
  }
  logs: printer_logs(
    where: {printer_id: {_eq: $id}}
    offset: $offset
    limit: $limit
    order_by: {created_at: desc}
  ) {
    level
    description
    python_log
    created_at
  }
}
    `;

/**
 * __useGetPrinterLogsQuery__
 *
 * To run a query within a React component, call `useGetPrinterLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrinterLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrinterLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPrinterLogsQuery(baseOptions: Apollo.QueryHookOptions<GetPrinterLogsQuery, GetPrinterLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrinterLogsQuery, GetPrinterLogsQueryVariables>(GetPrinterLogsDocument, options);
      }
export function useGetPrinterLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrinterLogsQuery, GetPrinterLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrinterLogsQuery, GetPrinterLogsQueryVariables>(GetPrinterLogsDocument, options);
        }
export type GetPrinterLogsQueryHookResult = ReturnType<typeof useGetPrinterLogsQuery>;
export type GetPrinterLogsLazyQueryHookResult = ReturnType<typeof useGetPrinterLogsLazyQuery>;
export type GetPrinterLogsQueryResult = Apollo.QueryResult<GetPrinterLogsQuery, GetPrinterLogsQueryVariables>;
export const GetPrinterTemplatesDocument = gql`
    query GetPrinterTemplates {
  templates: print_templates(order_by: {id: asc}) {
    id
    alias
    name
    printer_id
  }
}
    `;

/**
 * __useGetPrinterTemplatesQuery__
 *
 * To run a query within a React component, call `useGetPrinterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrinterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrinterTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrinterTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetPrinterTemplatesQuery, GetPrinterTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrinterTemplatesQuery, GetPrinterTemplatesQueryVariables>(GetPrinterTemplatesDocument, options);
      }
export function useGetPrinterTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrinterTemplatesQuery, GetPrinterTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrinterTemplatesQuery, GetPrinterTemplatesQueryVariables>(GetPrinterTemplatesDocument, options);
        }
export type GetPrinterTemplatesQueryHookResult = ReturnType<typeof useGetPrinterTemplatesQuery>;
export type GetPrinterTemplatesLazyQueryHookResult = ReturnType<typeof useGetPrinterTemplatesLazyQuery>;
export type GetPrinterTemplatesQueryResult = Apollo.QueryResult<GetPrinterTemplatesQuery, GetPrinterTemplatesQueryVariables>;
export const UpdatePrinterTemplateDocument = gql`
    mutation UpdatePrinterTemplate($id: Int!, $data: print_templates_set_input!) {
  update_print_templates_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdatePrinterTemplateMutationFn = Apollo.MutationFunction<UpdatePrinterTemplateMutation, UpdatePrinterTemplateMutationVariables>;

/**
 * __useUpdatePrinterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePrinterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrinterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrinterTemplateMutation, { data, loading, error }] = useUpdatePrinterTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePrinterTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrinterTemplateMutation, UpdatePrinterTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrinterTemplateMutation, UpdatePrinterTemplateMutationVariables>(UpdatePrinterTemplateDocument, options);
      }
export type UpdatePrinterTemplateMutationHookResult = ReturnType<typeof useUpdatePrinterTemplateMutation>;
export type UpdatePrinterTemplateMutationResult = Apollo.MutationResult<UpdatePrinterTemplateMutation>;
export type UpdatePrinterTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePrinterTemplateMutation, UpdatePrinterTemplateMutationVariables>;
export const GetReportTransactionsDocument = gql`
    query GetReportTransactions($date: date!) {
  transactions: transactions_helper(
    where: {_and: {date: {_eq: $date}, _or: [{transaction_type: {_eq: "Refund"}}, {transaction_type: {_eq: "Deposit"}}, {transaction_type: {_eq: "Transfer"}}]}}
  ) {
    amount
    canceled
    commensal_name
    apartment_name
    transaction_type
    es_description
    canceled_at
    created_at
    currency
    exchange
  }
}
    `;

/**
 * __useGetReportTransactionsQuery__
 *
 * To run a query within a React component, call `useGetReportTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportTransactionsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetReportTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetReportTransactionsQuery, GetReportTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportTransactionsQuery, GetReportTransactionsQueryVariables>(GetReportTransactionsDocument, options);
      }
export function useGetReportTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportTransactionsQuery, GetReportTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportTransactionsQuery, GetReportTransactionsQueryVariables>(GetReportTransactionsDocument, options);
        }
export type GetReportTransactionsQueryHookResult = ReturnType<typeof useGetReportTransactionsQuery>;
export type GetReportTransactionsLazyQueryHookResult = ReturnType<typeof useGetReportTransactionsLazyQuery>;
export type GetReportTransactionsQueryResult = Apollo.QueryResult<GetReportTransactionsQuery, GetReportTransactionsQueryVariables>;
export const GetReceptionAccountDocument = gql`
    query GetReceptionAccount($id: Int!) {
  palapa_accounts(where: {statement_id: {_eq: $id}}) {
    rental_id
    user_id
    statement_id
    present
    commensal_name
    commensal_type
    apartment_name
    balance
    balance_retained
    tickets {
      id
      table {
        name
      }
      open
      tip
      total
    }
  }
}
    `;

/**
 * __useGetReceptionAccountQuery__
 *
 * To run a query within a React component, call `useGetReceptionAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceptionAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceptionAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReceptionAccountQuery(baseOptions: Apollo.QueryHookOptions<GetReceptionAccountQuery, GetReceptionAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceptionAccountQuery, GetReceptionAccountQueryVariables>(GetReceptionAccountDocument, options);
      }
export function useGetReceptionAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceptionAccountQuery, GetReceptionAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceptionAccountQuery, GetReceptionAccountQueryVariables>(GetReceptionAccountDocument, options);
        }
export type GetReceptionAccountQueryHookResult = ReturnType<typeof useGetReceptionAccountQuery>;
export type GetReceptionAccountLazyQueryHookResult = ReturnType<typeof useGetReceptionAccountLazyQuery>;
export type GetReceptionAccountQueryResult = Apollo.QueryResult<GetReceptionAccountQuery, GetReceptionAccountQueryVariables>;
export const GetReceptionCommensalsDocument = gql`
    query GetReceptionCommensals($searchString: String!, $limit: Int!, $offset: Int!) {
  aggregate: palapa_accounts_aggregate(
    where: {_and: [{_or: [{commensal_name: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}]}, {present: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
  accounts: palapa_accounts(
    where: {_and: [{_or: [{commensal_name: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}]}, {present: {_eq: true}}]}
    limit: $limit
    offset: $offset
    order_by: [{apartment_building: asc}, {apartment_name: asc}]
  ) {
    statement_id
    commensal_name
    apartment_name
    commensal_type
    present
    balance
    balance_retained
    pin_created
    rental_id
    user_id
  }
}
    `;

/**
 * __useGetReceptionCommensalsQuery__
 *
 * To run a query within a React component, call `useGetReceptionCommensalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceptionCommensalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceptionCommensalsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetReceptionCommensalsQuery(baseOptions: Apollo.QueryHookOptions<GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables>(GetReceptionCommensalsDocument, options);
      }
export function useGetReceptionCommensalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables>(GetReceptionCommensalsDocument, options);
        }
export type GetReceptionCommensalsQueryHookResult = ReturnType<typeof useGetReceptionCommensalsQuery>;
export type GetReceptionCommensalsLazyQueryHookResult = ReturnType<typeof useGetReceptionCommensalsLazyQuery>;
export type GetReceptionCommensalsQueryResult = Apollo.QueryResult<GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables>;
export const GetTicketsDocument = gql`
    query GetTickets($date: date!) {
  tickets: account_tickets(where: {_and: [{date: {_gte: $date, _lte: $date}}]}) {
    id
    date
    commensal_name
    orders {
      order_dishes {
        dish {
          name
        }
        dish_price
        quantity
      }
    }
  }
}
    `;

/**
 * __useGetTicketsQuery__
 *
 * To run a query within a React component, call `useGetTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, options);
      }
export function useGetTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, options);
        }
export type GetTicketsQueryHookResult = ReturnType<typeof useGetTicketsQuery>;
export type GetTicketsLazyQueryHookResult = ReturnType<typeof useGetTicketsLazyQuery>;
export type GetTicketsQueryResult = Apollo.QueryResult<GetTicketsQuery, GetTicketsQueryVariables>;
export const GetCommensalTransactionsDocument = gql`
    query GetCommensalTransactions($id: Int!, $limit: Int!, $offset: Int!) {
  transactions_aggregate(where: {statement_account_id: {_eq: $id}}) {
    aggregate {
      count
    }
  }
  transactions(
    where: {statement_account_id: {_eq: $id}}
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    transaction_id
    es_description
    statement_account_id
    amount
    created_at
    canceled
    transaction_type
    exchange
    user {
      first_name
      last_name
    }
    currency
  }
}
    `;

/**
 * __useGetCommensalTransactionsQuery__
 *
 * To run a query within a React component, call `useGetCommensalTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommensalTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommensalTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCommensalTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables>(GetCommensalTransactionsDocument, options);
      }
export function useGetCommensalTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables>(GetCommensalTransactionsDocument, options);
        }
export type GetCommensalTransactionsQueryHookResult = ReturnType<typeof useGetCommensalTransactionsQuery>;
export type GetCommensalTransactionsLazyQueryHookResult = ReturnType<typeof useGetCommensalTransactionsLazyQuery>;
export type GetCommensalTransactionsQueryResult = Apollo.QueryResult<GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables>;
export const CheckoutRentalDocument = gql`
    mutation CheckoutRental($id: Int!) {
  update_rental_by_pk(pk_columns: {id: $id}, _set: {present: false}) {
    id
  }
}
    `;
export type CheckoutRentalMutationFn = Apollo.MutationFunction<CheckoutRentalMutation, CheckoutRentalMutationVariables>;

/**
 * __useCheckoutRentalMutation__
 *
 * To run a mutation, you first call `useCheckoutRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutRentalMutation, { data, loading, error }] = useCheckoutRentalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutRentalMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutRentalMutation, CheckoutRentalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutRentalMutation, CheckoutRentalMutationVariables>(CheckoutRentalDocument, options);
      }
export type CheckoutRentalMutationHookResult = ReturnType<typeof useCheckoutRentalMutation>;
export type CheckoutRentalMutationResult = Apollo.MutationResult<CheckoutRentalMutation>;
export type CheckoutRentalMutationOptions = Apollo.BaseMutationOptions<CheckoutRentalMutation, CheckoutRentalMutationVariables>;
export const CheckoutOwnerDocument = gql`
    mutation CheckoutOwner($id: Int!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {present: false}) {
    id
  }
}
    `;
export type CheckoutOwnerMutationFn = Apollo.MutationFunction<CheckoutOwnerMutation, CheckoutOwnerMutationVariables>;

/**
 * __useCheckoutOwnerMutation__
 *
 * To run a mutation, you first call `useCheckoutOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutOwnerMutation, { data, loading, error }] = useCheckoutOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutOwnerMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutOwnerMutation, CheckoutOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutOwnerMutation, CheckoutOwnerMutationVariables>(CheckoutOwnerDocument, options);
      }
export type CheckoutOwnerMutationHookResult = ReturnType<typeof useCheckoutOwnerMutation>;
export type CheckoutOwnerMutationResult = Apollo.MutationResult<CheckoutOwnerMutation>;
export type CheckoutOwnerMutationOptions = Apollo.BaseMutationOptions<CheckoutOwnerMutation, CheckoutOwnerMutationVariables>;
export const UpdateCommensalPinDocument = gql`
    mutation UpdateCommensalPin($id: Int!, $pin: String!) {
  update_statement_account_by_pk(
    pk_columns: {id: $id}
    _set: {pin_created: true, pin: $pin}
  ) {
    id
    pin
  }
}
    `;
export type UpdateCommensalPinMutationFn = Apollo.MutationFunction<UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables>;

/**
 * __useUpdateCommensalPinMutation__
 *
 * To run a mutation, you first call `useUpdateCommensalPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommensalPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommensalPinMutation, { data, loading, error }] = useUpdateCommensalPinMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useUpdateCommensalPinMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables>(UpdateCommensalPinDocument, options);
      }
export type UpdateCommensalPinMutationHookResult = ReturnType<typeof useUpdateCommensalPinMutation>;
export type UpdateCommensalPinMutationResult = Apollo.MutationResult<UpdateCommensalPinMutation>;
export type UpdateCommensalPinMutationOptions = Apollo.BaseMutationOptions<UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables>;
export const UpdateCurrencyDocument = gql`
    mutation UpdateCurrency($id: Int!, $amount: numeric!) {
  update_exchange_by_pk(pk_columns: {id: $id}, _set: {exchange: $amount}) {
    id
    exchange
  }
}
    `;
export type UpdateCurrencyMutationFn = Apollo.MutationFunction<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;

/**
 * __useUpdateCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyMutation, { data, loading, error }] = useUpdateCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdateCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, options);
      }
export type UpdateCurrencyMutationHookResult = ReturnType<typeof useUpdateCurrencyMutation>;
export type UpdateCurrencyMutationResult = Apollo.MutationResult<UpdateCurrencyMutation>;
export type UpdateCurrencyMutationOptions = Apollo.BaseMutationOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export const GetCurrenciesInfoDocument = gql`
    query GetCurrenciesInfo {
  exchange(where: {disabled: {_eq: false}}, order_by: {id: asc}) {
    id
    currency_key
    currency_name
    exchange
  }
}
    `;

/**
 * __useGetCurrenciesInfoQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrenciesInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrenciesInfoQuery, GetCurrenciesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrenciesInfoQuery, GetCurrenciesInfoQueryVariables>(GetCurrenciesInfoDocument, options);
      }
export function useGetCurrenciesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenciesInfoQuery, GetCurrenciesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrenciesInfoQuery, GetCurrenciesInfoQueryVariables>(GetCurrenciesInfoDocument, options);
        }
export type GetCurrenciesInfoQueryHookResult = ReturnType<typeof useGetCurrenciesInfoQuery>;
export type GetCurrenciesInfoLazyQueryHookResult = ReturnType<typeof useGetCurrenciesInfoLazyQuery>;
export type GetCurrenciesInfoQueryResult = Apollo.QueryResult<GetCurrenciesInfoQuery, GetCurrenciesInfoQueryVariables>;
export const GetCurrenciesDocument = gql`
    query GetCurrencies {
  exchange(where: {disabled: {_eq: false}}, order_by: {id: asc}) {
    id
    currency_key
    currency_name
    exchange
  }
}
    `;

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
      }
export function useGetCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>;
export type GetCurrenciesQueryResult = Apollo.QueryResult<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const CreateTransactionDocument = gql`
    mutation CreateTransaction($data: transactions_insert_input!) {
  insert_transactions_one(object: $data) {
    transaction_id
    amount
    exchange
    currency
  }
}
    `;
export type CreateTransactionMutationFn = Apollo.MutationFunction<CreateTransactionMutation, CreateTransactionMutationVariables>;

/**
 * __useCreateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionMutation, { data, loading, error }] = useCreateTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransactionMutation, CreateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransactionMutation, CreateTransactionMutationVariables>(CreateTransactionDocument, options);
      }
export type CreateTransactionMutationHookResult = ReturnType<typeof useCreateTransactionMutation>;
export type CreateTransactionMutationResult = Apollo.MutationResult<CreateTransactionMutation>;
export type CreateTransactionMutationOptions = Apollo.BaseMutationOptions<CreateTransactionMutation, CreateTransactionMutationVariables>;
export const CancelTransactionDocument = gql`
    mutation CancelTransaction($id: Int!, $data: transactions_insert_input!) {
  insert_transactions_one(object: $data) {
    transaction_id
  }
  update_transactions_by_pk(
    pk_columns: {transaction_id: $id}
    _set: {canceled: true}
  ) {
    transaction_id
    canceled
  }
}
    `;
export type CancelTransactionMutationFn = Apollo.MutationFunction<CancelTransactionMutation, CancelTransactionMutationVariables>;

/**
 * __useCancelTransactionMutation__
 *
 * To run a mutation, you first call `useCancelTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTransactionMutation, { data, loading, error }] = useCancelTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CancelTransactionMutation, CancelTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTransactionMutation, CancelTransactionMutationVariables>(CancelTransactionDocument, options);
      }
export type CancelTransactionMutationHookResult = ReturnType<typeof useCancelTransactionMutation>;
export type CancelTransactionMutationResult = Apollo.MutationResult<CancelTransactionMutation>;
export type CancelTransactionMutationOptions = Apollo.BaseMutationOptions<CancelTransactionMutation, CancelTransactionMutationVariables>;
export const CreateTransferDocument = gql`
    mutation CreateTransfer($transactions: [transactions_insert_input!]!) {
  insert_transactions(objects: $transactions) {
    returning {
      transaction_id
    }
  }
}
    `;
export type CreateTransferMutationFn = Apollo.MutationFunction<CreateTransferMutation, CreateTransferMutationVariables>;

/**
 * __useCreateTransferMutation__
 *
 * To run a mutation, you first call `useCreateTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferMutation, { data, loading, error }] = useCreateTransferMutation({
 *   variables: {
 *      transactions: // value for 'transactions'
 *   },
 * });
 */
export function useCreateTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransferMutation, CreateTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransferMutation, CreateTransferMutationVariables>(CreateTransferDocument, options);
      }
export type CreateTransferMutationHookResult = ReturnType<typeof useCreateTransferMutation>;
export type CreateTransferMutationResult = Apollo.MutationResult<CreateTransferMutation>;
export type CreateTransferMutationOptions = Apollo.BaseMutationOptions<CreateTransferMutation, CreateTransferMutationVariables>;
export const InsertOwnerArrivalDocument = gql`
    mutation InsertOwnerArrival($data: arrivals_insert_input!) {
  insert_arrivals_one(object: $data) {
    id
  }
}
    `;
export type InsertOwnerArrivalMutationFn = Apollo.MutationFunction<InsertOwnerArrivalMutation, InsertOwnerArrivalMutationVariables>;

/**
 * __useInsertOwnerArrivalMutation__
 *
 * To run a mutation, you first call `useInsertOwnerArrivalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOwnerArrivalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOwnerArrivalMutation, { data, loading, error }] = useInsertOwnerArrivalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertOwnerArrivalMutation(baseOptions?: Apollo.MutationHookOptions<InsertOwnerArrivalMutation, InsertOwnerArrivalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOwnerArrivalMutation, InsertOwnerArrivalMutationVariables>(InsertOwnerArrivalDocument, options);
      }
export type InsertOwnerArrivalMutationHookResult = ReturnType<typeof useInsertOwnerArrivalMutation>;
export type InsertOwnerArrivalMutationResult = Apollo.MutationResult<InsertOwnerArrivalMutation>;
export type InsertOwnerArrivalMutationOptions = Apollo.BaseMutationOptions<InsertOwnerArrivalMutation, InsertOwnerArrivalMutationVariables>;
export const InsertRentalArrivalDocument = gql`
    mutation InsertRentalArrival($data: rental_insert_input!) {
  insert_rental_one(object: $data) {
    id
  }
}
    `;
export type InsertRentalArrivalMutationFn = Apollo.MutationFunction<InsertRentalArrivalMutation, InsertRentalArrivalMutationVariables>;

/**
 * __useInsertRentalArrivalMutation__
 *
 * To run a mutation, you first call `useInsertRentalArrivalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRentalArrivalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRentalArrivalMutation, { data, loading, error }] = useInsertRentalArrivalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertRentalArrivalMutation(baseOptions?: Apollo.MutationHookOptions<InsertRentalArrivalMutation, InsertRentalArrivalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRentalArrivalMutation, InsertRentalArrivalMutationVariables>(InsertRentalArrivalDocument, options);
      }
export type InsertRentalArrivalMutationHookResult = ReturnType<typeof useInsertRentalArrivalMutation>;
export type InsertRentalArrivalMutationResult = Apollo.MutationResult<InsertRentalArrivalMutation>;
export type InsertRentalArrivalMutationOptions = Apollo.BaseMutationOptions<InsertRentalArrivalMutation, InsertRentalArrivalMutationVariables>;
export const UpdateArrivalDocument = gql`
    mutation UpdateArrival($data: arrivals_set_input!, $id: Int!) {
  update_arrivals_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateArrivalMutationFn = Apollo.MutationFunction<UpdateArrivalMutation, UpdateArrivalMutationVariables>;

/**
 * __useUpdateArrivalMutation__
 *
 * To run a mutation, you first call `useUpdateArrivalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArrivalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArrivalMutation, { data, loading, error }] = useUpdateArrivalMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateArrivalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArrivalMutation, UpdateArrivalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArrivalMutation, UpdateArrivalMutationVariables>(UpdateArrivalDocument, options);
      }
export type UpdateArrivalMutationHookResult = ReturnType<typeof useUpdateArrivalMutation>;
export type UpdateArrivalMutationResult = Apollo.MutationResult<UpdateArrivalMutation>;
export type UpdateArrivalMutationOptions = Apollo.BaseMutationOptions<UpdateArrivalMutation, UpdateArrivalMutationVariables>;
export const GetAllBalancesDocument = gql`
    query GetAllBalances($searchString: String!, $filteredDate: date!) {
  transactions: transactions_helper(
    where: {_and: [{_or: [{commensal_name: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}], date: {_lte: $filteredDate}}]}
    order_by: [{apartment_building: asc}, {apartment_number: asc}]
  ) {
    transaction_id
    statement_account_id
    apartment_name
    amount
    commensal_name
    canceled
    transaction_type
    exchange
  }
}
    `;

/**
 * __useGetAllBalancesQuery__
 *
 * To run a query within a React component, call `useGetAllBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBalancesQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      filteredDate: // value for 'filteredDate'
 *   },
 * });
 */
export function useGetAllBalancesQuery(baseOptions: Apollo.QueryHookOptions<GetAllBalancesQuery, GetAllBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBalancesQuery, GetAllBalancesQueryVariables>(GetAllBalancesDocument, options);
      }
export function useGetAllBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBalancesQuery, GetAllBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBalancesQuery, GetAllBalancesQueryVariables>(GetAllBalancesDocument, options);
        }
export type GetAllBalancesQueryHookResult = ReturnType<typeof useGetAllBalancesQuery>;
export type GetAllBalancesLazyQueryHookResult = ReturnType<typeof useGetAllBalancesLazyQuery>;
export type GetAllBalancesQueryResult = Apollo.QueryResult<GetAllBalancesQuery, GetAllBalancesQueryVariables>;
export const GetAllTicketsDocument = gql`
    query GetAllTickets {
  account_tickets {
    id
    tip
    total
    commensal_name
    apartment_name
    created_at
    orders {
      total
      order_dishes {
        dish_price
        quantity
        dish {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllTicketsQuery__
 *
 * To run a query within a React component, call `useGetAllTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTicketsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTicketsQuery, GetAllTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTicketsQuery, GetAllTicketsQueryVariables>(GetAllTicketsDocument, options);
      }
export function useGetAllTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTicketsQuery, GetAllTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTicketsQuery, GetAllTicketsQueryVariables>(GetAllTicketsDocument, options);
        }
export type GetAllTicketsQueryHookResult = ReturnType<typeof useGetAllTicketsQuery>;
export type GetAllTicketsLazyQueryHookResult = ReturnType<typeof useGetAllTicketsLazyQuery>;
export type GetAllTicketsQueryResult = Apollo.QueryResult<GetAllTicketsQuery, GetAllTicketsQueryVariables>;
export const GetAllTransactionsDocument = gql`
    query GetAllTransactions($filters: transactions_helper_bool_exp!) {
  transactions: transactions_helper(where: $filters, order_by: {created_at: desc}) {
    transaction_id
    commensal_name
    apartment_name
    es_description
    amount
    is_tip
    date
    time
    currency
    transaction_type
    created_at
  }
}
    `;

/**
 * __useGetAllTransactionsQuery__
 *
 * To run a query within a React component, call `useGetAllTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransactionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>(GetAllTransactionsDocument, options);
      }
export function useGetAllTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>(GetAllTransactionsDocument, options);
        }
export type GetAllTransactionsQueryHookResult = ReturnType<typeof useGetAllTransactionsQuery>;
export type GetAllTransactionsLazyQueryHookResult = ReturnType<typeof useGetAllTransactionsLazyQuery>;
export type GetAllTransactionsQueryResult = Apollo.QueryResult<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($first_name: String!, $last_name: String!, $email: String!, $phone_number: String!, $role: String!, $uid: String!, $apartment_id: Int!) {
  CreateUserAccount(
    first_name: $first_name
    last_name: $last_name
    email: $email
    phone_number: $phone_number
    role: $role
    uid: $uid
    apartment_id: $apartment_id
  ) {
    uid
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      phone_number: // value for 'phone_number'
 *      role: // value for 'role'
 *      uid: // value for 'uid'
 *      apartment_id: // value for 'apartment_id'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($id: Int!) {
  user: users_by_pk(id: $id) {
    id
    first_name
    last_name
    email
    phone_number
    uid
    roles
    apartment {
      id
      statement_accounts {
        total
        transactions {
          amount
          es_description
          transaction_type
        }
      }
    }
  }
}
    `;

// export function useStatementPdfMutation(baseOptions?: Apollo.MutationHookOptions<StatementPdfMutation, StatementPdfMutationVariables>) {
//       const options = {...defaultOptions, ...baseOptions}
//       return Apollo.useMutation<StatementPdfMutation, StatementPdfMutationVariables>(CreateAccountDocument, options);
//     }
// export type StatementPdfMutationHookResult = ReturnType<typeof useStatementPdfMutation>;
// export type StatementPdfMutationResult = Apollo.MutationResult<StatementPdfMutation>;
// export type StatementPdfMutationOptions = Apollo.BaseMutationOptions<StatementPdfMutation, StatementPdfMutationVariables>;    

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($limit: Int!, $offset: Int!, $searchString: String!) {
  users_aggregate(
    where: {_or: [{first_name: {_ilike: $searchString}}, {last_name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]}
  ) {
    aggregate {
      count
    }
  }
  users(
    order_by: {first_name: asc}
    where: {_or: [{first_name: {_ilike: $searchString}}, {last_name: {_ilike: $searchString}}, {email: {_ilike: $searchString}}]}
    offset: $offset
    limit: $limit
  ) {
    id
    first_name
    last_name
    email
    roles
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $data: users_set_input!, $apartment_id: Int!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
  update_apartment_by_pk(pk_columns: {id: $apartment_id}, _set: {user_id: $id}) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      apartment_id: // value for 'apartment_id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserEmailDocument = gql`
    mutation UpdateUserEmail($email: String!, $uid: String!) {
  UpdateUserEmail(email: $email, uid: $uid) {
    email
  }
}
    `;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(UpdateUserEmailDocument, options);
      }
export type UpdateUserEmailMutationHookResult = ReturnType<typeof useUpdateUserEmailMutation>;
export type UpdateUserEmailMutationResult = Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($user: Int!, $uid: String!, $role: String!) {
  UpdateUserRole(role: $role, uid: $uid, user_id: $user) {
    uid
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      user: // value for 'user'
 *      uid: // value for 'uid'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const CreateVisitDocument = gql`
    mutation CreateVisit($data: visits_insert_input!) {
  insert_visits_one(object: $data) {
    id
  }
}
    `;
export type CreateVisitMutationFn = Apollo.MutationFunction<CreateVisitMutation, CreateVisitMutationVariables>;

/**
 * __useCreateVisitMutation__
 *
 * To run a mutation, you first call `useCreateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitMutation, { data, loading, error }] = useCreateVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVisitMutation(baseOptions?: Apollo.MutationHookOptions<CreateVisitMutation, CreateVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVisitMutation, CreateVisitMutationVariables>(CreateVisitDocument, options);
      }
export type CreateVisitMutationHookResult = ReturnType<typeof useCreateVisitMutation>;
export type CreateVisitMutationResult = Apollo.MutationResult<CreateVisitMutation>;
export type CreateVisitMutationOptions = Apollo.BaseMutationOptions<CreateVisitMutation, CreateVisitMutationVariables>;
export const GetVisitsDocument = gql`
    query GetVisits($searchString: String!, $offset: Int!, $limit: Int!) {
  visits_aggregate(
    where: {_or: [{visitor_name: {_ilike: $searchString}}, {host_name: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}]}
  ) {
    aggregate {
      count
    }
  }
  visits(
    where: {_or: [{visitor_name: {_ilike: $searchString}}, {host_name: {_ilike: $searchString}}, {apartment_name: {_ilike: $searchString}}]}
    offset: $offset
    limit: $limit
  ) {
    id
    visitor_name
    host_name
    apartment_name
    expected_arrival
    arrival_method
    car_color
    plates
    car_description
    comments
    visitor_count
  }
}
    `;

/**
 * __useGetVisitsQuery__
 *
 * To run a query within a React component, call `useGetVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetVisitsQuery(baseOptions: Apollo.QueryHookOptions<GetVisitsQuery, GetVisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitsQuery, GetVisitsQueryVariables>(GetVisitsDocument, options);
      }
export function useGetVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitsQuery, GetVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitsQuery, GetVisitsQueryVariables>(GetVisitsDocument, options);
        }
export type GetVisitsQueryHookResult = ReturnType<typeof useGetVisitsQuery>;
export type GetVisitsLazyQueryHookResult = ReturnType<typeof useGetVisitsLazyQuery>;
export type GetVisitsQueryResult = Apollo.QueryResult<GetVisitsQuery, GetVisitsQueryVariables>;