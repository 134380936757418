import React, { useState, useEffect } from 'react';
import {
  colors,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fab,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { Add as AddIcon, Delete as DeleteIcon, Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useMutation, gql, useQuery } from '@apollo/client';

import {
  InsertDishesMutation,
  InsertDishesMutationVariables,
  GetDishesCategoriesQuery,
  UpdateDishMutation,
  UpdateDishMutationVariables,
  Dishes_Set_Input as DishesSetInput
} from 'types/graphql';
import { Queries } from 'common';
import { useSnackbar } from 'context';
import { useAutoSave } from 'hooks';
import { NumberFormatInput } from 'components';
import { IngredientsPicker, ModifiersPicker } from './components';

type FormikDish = typeof dish;

type Modifier = {
  title: string;
  id: number;
};

type ModifierGroup = {
  title: string;
  selected: boolean;
  id: number;
  open: boolean;
  multiple: boolean;
  modifiers: Array<Modifier>;
};

type Ingredient = {
  id: number;
  quantity: number;
  name: string;
  unit: string;
  optional: boolean;
};

type FormikState = {
  dishes: Array<{
    id: number;
    name: string;
    description: string;
    price: string;
    cost: string;
    fileName: string;
    menu: number;
    category: number;
    subcategory: number;
    fileBinary: File | null;
    showIngredients: boolean;
    showModifiers: boolean;
    modifierGroups: Array<ModifierGroup>;
    ingredients: Array<{
      id: number;
      unit: string;
      name: string;
      optional: boolean;
      quantity: number;
    }>;
  }>;
};
type Dish = {
  id: number;
  name: string;
  description: string;
  subcategory: number;
  category: number;
  menu: number;
  cost: number;
  price: number;
  showIngredients: boolean;
  ingredients: Array<Ingredient>;
  modifierGroups: Array<ModifierGroup>;
};

const UPDATE_DISH = gql`
  mutation UpdateDish(
    $id: Int!
    $dish: dishes_set_input!
    $ingredients: [ingredient_dish_insert_input!]!
    $modifiers: [modifier_group_dish_insert_input!]!
  ) {
    update_dishes_by_pk(pk_columns: { id: $id }, _set: $dish) {
      id
    }
    delete_ingredient_dish(where: { dish_id: { _eq: $id } }) {
      affected_rows
    }
    delete_modifier_group_dish(where: { dish_id: { _eq: $id } }) {
      affected_rows
    }
    insert_modifier_group_dish(objects: $modifiers) {
      affected_rows
    }
    insert_ingredient_dish(objects: $ingredients) {
      affected_rows
    }
  }
`;

const INSERT_DISHES_MUTATION = gql`
  mutation InsertDishes($data: [dishes_insert_input!]!) {
    insert_dishes(objects: $data) {
      returning {
        id
      }
    }
  }
`;

const dish = {
  id: 0,
  name: '',
  description: '',
  price: '',
  cost: '',
  subcategory: 1,
  fileName: '',
  menu: 1,
  category: 1,
  fileBinary: null,
  showIngredients: false,
  showModifiers: false,
  ingredients: [],
  modifierGroups: []
};

let selectedMenu = 0;
let selectedCategory = 0;
let selectedSubcategory = 0;

const initialState: FormikState = {
  dishes: [dish]
};

const AddDish = (): JSX.Element => {
  const [initialFormikState, setInitialFormikState] = useState<FormikState>(initialState);
  const location = useLocation<{ edit: boolean; dish?: Dish }>();
  const { data } = useQuery<GetDishesCategoriesQuery>(Queries.GET_DISHES_CATEGORIES);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const [updateDish] = useMutation<UpdateDishMutation, UpdateDishMutationVariables>(UPDATE_DISH);
  const [doInsert, { loading }] = useMutation<InsertDishesMutation, InsertDishesMutationVariables>(
    INSERT_DISHES_MUTATION
  );
  const { getAutoSaveData, setAutoSaveData, destroyAutoSaveData } = useAutoSave<FormikState>('dish_items');

  useEffect(() => {
    if (location.state.edit && location.state.dish) {
      setInitialFormikState({
        dishes: [
          {
            id: location.state.dish.id,
            subcategory: location.state.dish.subcategory,
            name: location.state.dish.name,
            cost: location.state.dish.cost.toString(),
            price: location.state.dish.price.toString(),
            description: location.state.dish.description,
            menu: location.state.dish.menu,
            category: location.state.dish.category,
            fileBinary: null,
            fileName: '',
            showIngredients: false,
            showModifiers: false,
            ingredients: location.state.dish.ingredients,
            modifierGroups: location.state.dish.modifierGroups
          }
        ]
      });
    } else {
      const data = getAutoSaveData();
      if (data) {
        setInitialFormikState(data);
      }
    }
  }, []);

  useEffect(() => {
    if (!location.state.edit && data) {
      selectedMenu = data.menus[0]?.id || 1;
      selectedCategory = data.menus[0]?.categories[0]?.id || 1;
      selectedSubcategory = data.menus[0]?.categories[0]?.subcategories[0]?.id || 1;

      const initialDishData: FormikDish = {
        ...dish,
        category: selectedCategory,
        menu: selectedMenu,
        subcategory: selectedSubcategory
      };

      setInitialFormikState({ dishes: [initialDishData] });
    }
  }, [data]);

  const autoSaveChanges = (values: FormikState): void => {
    if (!location.state.edit) {
      setAutoSaveData(values);
    }
  };

  const handleSubmission = async (values: FormikState, helpers: FormikHelpers<FormikState>): Promise<void> => {
    try {
      if (location.state.edit && location.state.dish) {
        const d = values.dishes[0];
        const [selectedDish] = values.dishes;
        const dish: DishesSetInput = {
          subcategory_id: selectedDish.subcategory,
          cost: selectedDish.cost,
          price: selectedDish.price,
          name: selectedDish.name,
          description: selectedDish.description
        };

        await updateDish({
          variables: {
            id: location.state.dish.id,
            dish,
            modifiers: d.modifierGroups.map((mg) => ({
              dish_id: d.id,
              modifier_group_id: mg.id,
              disabled: false
            })),
            ingredients: d.ingredients.map((i) => ({
              ingredient_id: i.id,
              optional: i.optional,
              quantity: i.quantity,
              dish_id: d.id
            }))
          }
        });
      } else {
        const mappedInsert: InsertDishesMutationVariables = {
          data: values.dishes.map((d) => ({
            name: d.name,
            description: d.description,
            price: d.price,
            cost: d.cost,
            subcategory_id: d.subcategory,
            modifier_group_dishes: {
              data: d.modifierGroups.map((mg) => ({
                modifier_group_id: mg.id,
                disabled: false
              }))
            },
            ingredient_dishes: {
              data: d.ingredients.map((i) => ({
                ingredient_id: i.id,
                optional: i.optional
              }))
            }
          }))
        };
        await doInsert({ variables: mappedInsert });
        destroyAutoSaveData();
        helpers.resetForm();
      }
      showSnackbar('Platillos creados correctamente', 'success');
      history.goBack();
    } catch (err) {
      showSnackbar('Error al crear platillos', 'error');
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialFormikState} onSubmit={handleSubmission}>
      {({ values, setFieldValue, handleSubmit }) => (
        <Grid alignItems="center" container spacing={2} sx={{ pl: 2, pr: 2 }}>
          <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
            <Grid item>
              <Typography variant="h5">Agregar platillos</Typography>
            </Grid>
            <Grid item>
              <IconButton
                disabled={location.state.edit}
                onClick={() =>
                  setFieldValue('dishes', [
                    ...values.dishes,
                    {
                      ...initialState.dishes[0],
                      category: selectedCategory,
                      menu: selectedMenu,
                      subcategory: selectedSubcategory
                    }
                  ])
                }
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: 'white',
                  '&:hover': { backgroundColor: (theme) => theme.palette.primary.light }
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
          {values.dishes.map((d, index) => (
            <Grid container flexDirection="column" key={index} spacing={1} sx={{ pt: 1 }}>
              <IngredientsPicker
                ingredients={d.ingredients}
                onAccept={(ingredients) => {
                  setFieldValue(`dishes[${index}].ingredients`, ingredients);
                  setFieldValue(`dishes[${index}].showIngredients`, false);
                  autoSaveChanges(values);
                }}
                onClose={() => setFieldValue(`dishes[${index}].showIngredients`, false)}
                open={d.showIngredients}
              />
              <ModifiersPicker
                modifiers={d.modifierGroups}
                onAccept={(modifiers) => {
                  setFieldValue(`dishes[${index}].modifierGroups`, modifiers);
                  setFieldValue(`dishes[${index}].showModifiers`, false);
                  autoSaveChanges(values);
                }}
                onCancel={() => setFieldValue(`dishes[${index}].showModifiers`, false)}
                open={d.showModifiers}
              />
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={12}>
                  <Divider textAlign="left">
                    <Tooltip title="Eliminar platillo">
                      <IconButton
                        color="error"
                        disabled={location.state.edit || values.dishes.length === 1}
                        onClick={() => {
                          const copy = [...values.dishes];
                          copy.splice(index, 1);
                          setFieldValue('dishes', copy);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Divider>
                </Grid>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nombre"
                      onBlur={() => autoSaveChanges(values)}
                      onChange={(e) => setFieldValue(`dishes[${index}].name`, e.target.value)}
                      value={d.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descripción"
                      multiline
                      onBlur={() => autoSaveChanges(values)}
                      onChange={(e) => setFieldValue(`dishes[${index}].description`, e.target.value)}
                      rows={3}
                      value={d.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <NumberFormatInput
                      decimalScale={2}
                      fullWidth
                      label="Costo"
                      onBlur={() => autoSaveChanges(values)}
                      onChange={(e) => setFieldValue(`dishes[${index}].cost`, e.target.value)}
                      prefix="$"
                      thousandSeparator
                      value={d.cost}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <NumberFormatInput
                      decimalScale={2}
                      fullWidth
                      label="Precio"
                      onBlur={() => autoSaveChanges(values)}
                      onChange={(e) => setFieldValue(`dishes[${index}].price`, e.target.value)}
                      prefix="$"
                      thousandSeparator
                      value={d.price}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="categoria">Menú</InputLabel>
                      {data?.menus && (
                        <Select
                          label="Menú"
                          onChange={(e) => {
                            const selectedCategoryOption = data.menus.find((m) => m.id === e.target.value)
                              ?.categories[0]?.id;
                            setFieldValue(`dishes[${index}].category`, selectedCategoryOption || 0);
                            setFieldValue(`dishes[${index}].menu`, e.target.value);
                          }}
                          value={d.menu}
                          variant="outlined"
                        >
                          {data.menus.map((m) => (
                            <MenuItem key={m.id} value={m.id}>
                              {`${m.name}`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="categoria">Categoría</InputLabel>
                      {data?.menus.find((m) => m.id === values.dishes[index].menu) && (
                        <Select
                          label="Categoría"
                          onChange={(e) => {
                            const selectedSubcategoryOption = data.menus
                              .find((m) => m.id === values.dishes[index].category)
                              ?.categories.find((c) => c.id === e.target.value)?.subcategories[0]?.id;
                            setFieldValue(`dishes[${index}].subcategory`, selectedSubcategoryOption);
                            setFieldValue(`dishes[${index}].category`, e.target.value);
                          }}
                          value={d.category}
                          variant="outlined"
                        >
                          {data.menus
                            .find((m) => m.id === values.dishes[index].menu)
                            ?.categories.map((c) => (
                              <MenuItem key={c.id} value={c.id}>
                                {`${c.name}`}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="categoria">Subcategoría</InputLabel>
                      {data?.menus
                        .find((m) => m.id === values.dishes[index].menu)
                        ?.categories.find((c) => c.id === values.dishes[index].category) && (
                        <Select
                          label="Subcategoría"
                          onChange={(e) => setFieldValue(`dishes[${index}].subcategory`, e.target.value)}
                          value={d.subcategory}
                          variant="outlined"
                        >
                          {data.menus
                            .find((m) => m.id === values.dishes[index].menu)
                            ?.categories.find((c) => c.id === values.dishes[index].category)
                            ?.subcategories.map((sc) => (
                              <MenuItem key={sc.id} value={sc.id}>
                                {`${sc.name}`}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
              </Grid>
              <Grid container flexDirection="row" spacing={4} sx={{ pt: 2 }}>
                <Grid container item lg={6} md={12} spacing={2} xl={6} xs={12}>
                  <Grid alignItems="flex-start" container item justifyContent="space-between" xs={12}>
                    <Grid item>
                      <Typography variant="h6">Ingredientes...</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => setFieldValue(`dishes[${index}].showIngredients`, true)}
                        variant="contained"
                      >
                        Editar ingredientes
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid alignItems="flex-start" container item xs={12}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableCell sx={{ width: '10%', textAlign: 'center' }}>Opcional</TableCell>
                            <TableCell>Ingrediente</TableCell>
                            <TableCell sx={{ width: '10%', textAlign: 'center' }} />
                          </TableHead>
                          <TableBody>
                            {d.ingredients.length > 0 ? (
                              d.ingredients.map((i, ingredientIndex) => (
                                <TableRow key={i.id} sx={{ '&:hover': { background: colors.grey[200] } }}>
                                  <TableCell align="center">
                                    <Switch
                                      checked={i.optional}
                                      color="primary"
                                      onChange={() => {
                                        const newValue = !values.dishes[index].ingredients[ingredientIndex].optional;
                                        setFieldValue(
                                          `dishes[${index}].ingredients[${ingredientIndex}].optional`,
                                          newValue
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>{i.name}</b> {`(${i.quantity} ${i.unit})`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        const arrayCopy = [...d.ingredients];
                                        arrayCopy.splice(ingredientIndex, 1);
                                        setFieldValue(`dishes[${index}].ingredients`, arrayCopy);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={3}>
                                  <Typography variant="body2">
                                    <b>No se han asignado ingredientes</b>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item lg={6} md={12} spacing={2} xl={6} xs={12}>
                  <Grid alignItems="flex-start" container item justifyContent="space-between" xs={12}>
                    <Grid item>
                      <Typography variant="h6">Modificadores...</Typography>
                    </Grid>
                    <Grid item>
                      <Button onClick={() => setFieldValue(`dishes[${index}].showModifiers`, true)} variant="contained">
                        Editar modificadores
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid alignItems="flex-start" container item xs={12}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: '10%' }} />
                              <TableCell>Grupo modificador</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {d.modifierGroups.length > 0 ? (
                              d.modifierGroups.map((mg, mIndex) => (
                                <TableRow key={mIndex}>
                                  <TableCell align="center">
                                    <IconButton
                                      onClick={() => {
                                        const arrayCopy = [...d.modifierGroups];
                                        arrayCopy.splice(mIndex, 1);
                                        setFieldValue(`dishes[${index}].modifierGroups`, arrayCopy);
                                      }}
                                    >
                                      <CloseIcon color="error" />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell>{mg.title}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={2}>
                                  <Typography variant="body2">
                                    <b>No se han asignado modificadores</b>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Fab
            color="primary"
            disabled={loading}
            onClick={() => handleSubmit()}
            sx={{ position: 'fixed', bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}
          >
            {loading ? <CircularProgress /> : <DoneIcon />}
          </Fab>
        </Grid>
      )}
    </Formik>
  );
};

export default AddDish;
