import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { KeyboardBackspace as ManageAccountsIcon } from '@mui/icons-material';

const Footer = (): JSX.Element => {
  const history = useHistory();

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.secondary.main,
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '60px',
        zIndex: 3
      }}
    >
      <Grid container flexDirection="row" sx={{ height: '100%', pl: 2, pr: 2 }}>
        <Grid alignItems="center" container item justifyContent="center" xs={12}>
          <Grid item xs={6}>
            <IconButton onClick={() => history.goBack()}>
              <ManageAccountsIcon sx={{ color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
