import React, { useEffect, FC } from 'react';
import axios, { AxiosInstance } from 'axios';

import { useSnackbar } from 'hooks';

const AxiosHelper = axios.create({ baseURL: process.env.REACT_APP_API_LINK as string });

type ErrorResponse = {
  code?: 'NO_PRINTER' | 'INVALID_TEMPLATE';
};

interface AxiosProviderContext {
  axios: AxiosInstance;
}

const AxiosContext = React.createContext<AxiosProviderContext>({} as AxiosProviderContext);

export const useAxios = (): AxiosProviderContext => React.useContext(AxiosContext);

export const AxiosProvider: FC = ({ children }): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const responseInterceptor = AxiosHelper.interceptors.response.use((config) => {
      if ((config.data as ErrorResponse).code === 'NO_PRINTER') {
        showSnackbar('Error al imprimir. No se proporcionó una impresora de destino', 'error');
      } else if ((config.data as ErrorResponse).code === 'INVALID_TEMPLATE') {
        showSnackbar('Error al imprimir. No se proporcionó una plantilla válida.', 'error');
      }

      return config;
    });

    return () => {
      AxiosHelper.interceptors.response.eject(responseInterceptor);
    };
  });

  return (
    <>
      <AxiosContext.Provider value={{ axios: AxiosHelper }}>{children}</AxiosContext.Provider>
    </>
  );
};
