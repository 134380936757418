import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, Collapse, Box, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

import { formattedNumber } from 'helpers';
import { GetReceptionAccountQuery, GetReceptionAccountQueryVariables } from 'types/graphql';
import { AccountStatement, UserDataForm, ContextMenu } from './components';

type Commensal = GetReceptionAccountQuery['palapa_accounts'][0];

const GET_RECEPTION_DETAILS = gql`
  query GetReceptionAccount($id: Int!) {
    palapa_accounts(where: { statement_id: { _eq: $id } }) {
      rental_id
      user_id
      statement_id
      present
      commensal_name
      commensal_type
      apartment_name
      balance
      balance_retained
      tickets {
        id
        table {
          name
        }
        open
        tip
        total
      }
    }
  }
`;

const Details = (): JSX.Element => {
  const [commensal, setCommensal] = useState<Commensal | null>(null);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showTransactions, setShowTransactions] = useState(true);
  const location = useLocation<{ id: number }>();
  const { data } = useQuery<GetReceptionAccountQuery, GetReceptionAccountQueryVariables>(GET_RECEPTION_DETAILS, {
    variables: { id: location.state.id },
    pollInterval: 1000
  });

  useEffect(() => {

    if (data?.palapa_accounts) {
      const [currentCommensal] = data.palapa_accounts;

      setCommensal(currentCommensal);
    }
  }, [data]);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">{`${commensal?.apartment_name} ${commensal?.commensal_name}`}</Typography>
        </Grid>
        <Grid item>
          <ContextMenu
            commensalBalance={commensal?.balance || 0}
            isOwner={commensal?.commensal_type === 'owner'}
            pinCreated
            rentalId={commensal?.rental_id || null}
            statementId={commensal?.statement_id || 0}
            userId={commensal?.user_id || null}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>Información personal</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setShowUserInfo((prevState) => !prevState);
              }}
            >
              {showUserInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <Collapse in={showUserInfo}>
            <Grid alignItems="center" container item xs={12}>
              <UserDataForm editing statementId={commensal?.statement_id || 0} />
            </Grid>
          </Collapse>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* TODO: Otros detalles aquí */}
      <Grid item xs={12}>
        <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>
              Estado de cuenta ${formattedNumber((commensal?.balance || 0).toFixed(2))}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setShowTransactions((prevState) => !prevState);
              }}
            >
              {showTransactions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <Collapse in={showTransactions}>
            <Grid alignItems="center" container item xs={12}>
              <AccountStatement statementId={commensal?.statement_id || 0} />
            </Grid>
          </Collapse>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Details;
