import * as ActionTypes from './actions';
import { PalapaReduxState, StoreActions } from 'types/redux';

const initialState: PalapaReduxState = {
  snackbar: {
    visible: false,
    severity: null,
    text: '',
    duration: 0
  },
  user: {
    email: '',
    auth: false,
    uid: '',
    token: '',
    role: ''
  }
};

const reducer = (state: PalapaReduxState = initialState, action: StoreActions): PalapaReduxState => {
  switch (action.type) {
    case ActionTypes.UPDATE_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload
        }
      };

    case ActionTypes.STORE_USER:
      return {
        ...state,
        user: action.payload
      };

    case ActionTypes.REMOVE_USER:
      return {
        ...state,
        user: initialState.user
      };

    case ActionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: initialState.snackbar
      };

    case ActionTypes.OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
export { initialState };
