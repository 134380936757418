import React, { useState, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useMutation, gql } from '@apollo/client';

import { CustomDialog, CommensalSelector } from 'components';
import { useSnackbar } from 'hooks';
import { UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables } from 'types/graphql';

interface PinOperationDialogProps {
  open: boolean;
  statementId: number;
  pinCreated?: boolean;
  onClose: (...args: any[]) => void;
}

const UPDATE_PIN = gql`
  mutation UpdateCommensalPin($id: Int!, $pin: String!) {
    update_statement_account_by_pk(pk_columns: { id: $id }, _set: { pin_created: true, pin: $pin }) {
      id
      pin
    }
  }
`;

const initialValues = {
  pin: '',
  pinConfirm: '',
  statementId: 2
};

type FormikState = typeof initialValues;

const PinOperationDialog = ({ open, pinCreated, statementId, onClose }: PinOperationDialogProps): JSX.Element => {
  const [initialFormikState, setInitialFormikState] = useState<FormikState>(initialValues);
  const [updateCommensalPin] = useMutation<UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables>(UPDATE_PIN);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setInitialFormikState((prevState) => ({
      ...prevState,
      statementId
    }));
  }, [statementId]);

  const handleSubmission = async (values: FormikState) => {
    if (values.pin == values.pinConfirm){
      try {
        await updateCommensalPin({
          variables: {
            id: values.statementId,
            pin: values.pin
          }
        });
        onClose();
        showSnackbar('Nip actualizado', 'success');
      } catch (err) {
        showSnackbar(`Error al ${pinCreated ? 'cambiar' : 'crear'} nip`, 'error');
      }
    }else{
      showSnackbar('Los Nips deben de coincidir', 'error')
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialFormikState} onSubmit={handleSubmission}>
      {({ values, setFieldValue, handleSubmit }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={handleSubmit}
          onCancel={onClose}
          open={open}
          title={pinCreated ? 'Cambio de nip' : 'Crear nip'}
        >
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={12}>
              <CommensalSelector onChange={(id) => setFieldValue('commensalId', id)} value={values.statementId} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 4
                }}
                label="Nip"
                onChange={(e) => setFieldValue('pin', e.target.value)}
                type="password"
                value={values.pin}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 4
                }}
                label="Confirmar nip"
                onChange={(e) => setFieldValue('pinConfirm', e.target.value)}
                type="password"
                value={values.pinConfirm}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default PinOperationDialog;
