import React, { useState, useEffect } from 'react';
import { Grid, TableCell, TableRow, IconButton, Collapse, Table, TableBody, TableHead } from '@mui/material';
import { Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { gql, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

import { formattedNumber } from 'helpers';
import { CustomTable } from 'components';
import { GetAllTicketsQuery } from 'types/graphql';

type Filters = {
  searchString: string;
  date: Dayjs;
  showDate: boolean;
};

type MappedDish = {
  name: string;
  price: number;
  quantity: number;
};

type MappedTicket = {
  id: number;
  accountName: string;
  tip: number;
  total: number;
  createdAt: Dayjs;
  open: boolean;
  dishes: MappedDish[];
};

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];

const TABLE_HEADERS: TableHeaders = [
  {
    title: '',
    width: '5%'
  },
  {
    title: '# Folio'
  },
  {
    title: 'Cuenta'
  },
  {
    title: 'Fecha y hora'
  },
  {
    title: 'Propina',
    align: 'right'
  },
  {
    title: 'Total',
    align: 'right'
  }
];

const GET_TICKETS = gql`
  query GetAllTickets ($searchString: String!, $filteredDate: timestamptz) {
    account_tickets( where: {
      _and: [
        {
          _or: [
            {
              statement_account: {
                apartment: {
                  user: {
                    _or: [
                      { first_name: { _ilike: $searchString } },
                      { last_name: { _ilike: $searchString } }
                    ]
                  }
                }
              }
            },
            {
              statement_account: {
                apartment: {
                  name: { _like: $searchString }
                }
              }
            }
          ]
        },
        { created_at: { _lte: $filteredDate } }
      ]
    }
    order_by: { created_at: desc }
  ) {
      
      id
      tip
      total
    	statement_account {
        apartment {
          name
          user {
            first_name
            last_name
          }
          building {
            name
          }
        }
      }
      created_at
      orders {
        total
        order_dishes {
          dish_price
          quantity
          dish {
            name
          }
        }
      }
    }
  }
`;

const PalapaReport = (): JSX.Element => {
  const [filters, setFilters] = useState<Filters>({ searchString: '', showDate: false, date: dayjs() });
  const [tickets, setTickets] = useState<MappedTicket[]>([]);
  const { data } = useQuery<GetAllTicketsQuery>(GET_TICKETS, { 
    variables: { searchString: `%${filters.searchString}%`, filteredDate: filters.date.format('YYYY-MM-DD') },
    pollInterval: 1000 
  });

  useEffect(() => {
    if (data?.account_tickets) {
      const mappedTickets: MappedTicket[] = data.account_tickets.map((t) => {
        const dishesHelper: MappedDish[] = [];
        t.orders.forEach((o) => {
          o.order_dishes.forEach((od) => {
            dishesHelper.push({ name: od.dish.name, quantity: od.quantity, price: od.dish_price });
          });
        });

        const apartmentName = t.statement_account.apartment.name;
        const buildingName = t.statement_account.apartment.building.name;
        const userFirstName = t.statement_account.apartment.user.first_name;
        const userLastName = t.statement_account.apartment.user.last_name;

        const accountName = `${apartmentName} ${buildingName} ${userFirstName} ${userLastName}`;

        return {
          id: t.id,
          tip: t.tip,
          total: Math.abs(t.total),
          createdAt: dayjs(t.created_at),
          open: false,
          accountName: accountName,
          dishes: dishesHelper
        };
      });

      setTickets(mappedTickets);
    }
  }, [data]);

  const handleCollapseClick = (index: number) => {
    const arrayCopy = [...tickets];
    arrayCopy[index].open = !arrayCopy[index].open;
    setTickets(arrayCopy);
  };

  return (
    <>
      <Grid alignItems="center" container item xs={12}>
        <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
          <Grid item xs={8}>
            <Typography variant="body2">Consumos</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Búsqueda"
              onChange={(e) => setFilters((prevState) => ({ ...prevState, searchString: e.target.value }))}
              value={filters.searchString}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              label="Cargos al día"
              maxDate={dayjs()}
              onChange={(date) => setFilters((prevState) => ({ ...prevState, date: dayjs(date) }))}
              renderInput={(props) => <TextField {...props} fullWidth label="Cargos al día" />}
              value={filters.date}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTable headers={TABLE_HEADERS}>
            {tickets.map((t, index) => (
              <React.Fragment key={t.id}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' }, '&:hover': { backgroundColor: undefined } }}>
                  <TableCell align="center">
                    <IconButton onClick={() => handleCollapseClick(index)}>
                      {t.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{t.id}</TableCell>
                  <TableCell>{t.accountName}</TableCell>
                  <TableCell>{t.createdAt.format('DD/MM/YYYY hh:mm:ss a')}</TableCell>
                  <TableCell align="right">$ {formattedNumber(t.tip.toFixed(2))}</TableCell>
                  <TableCell align="right">$ {formattedNumber(t.total.toFixed(2))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={TABLE_HEADERS.length} sx={{ py: 0 }}>
                    <Collapse in={t.open} unmountOnExit>
                      <Table sx={{ px: 0 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Platillo</TableCell>
                            <TableCell align="right">Piezas</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {t.dishes.map((d, index) => (
                            <TableRow key={index}>
                              <TableCell>{d.name}</TableCell>
                              <TableCell align="right">{d.quantity}</TableCell>
                              <TableCell align="right">$ {formattedNumber((d.price*d.quantity).toFixed(2))}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </CustomTable>
        </Grid>
      </Grid>
    </>
  );

};

export default PalapaReport;
