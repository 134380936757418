import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  DialogProps,
  DialogActions,
  Grid,
  Typography,
  Button
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';

interface CustomDialogProps extends DialogProps {
  title: string;
  open: boolean;
  onAccept?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
  variant?: 'confirm' | 'info';
  content?: string | React.ReactNode | React.ReactNode[];
}

const CustomDialog: FC<CustomDialogProps> = ({
  open,
  title,
  onAccept,
  onCancel,
  children,
  variant = 'confirm',
  content,
  ...props
}): JSX.Element => {
  return (
    <Dialog {...props} onClose={onCancel} open={open}>
      <DialogTitle>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>{children || content}</DialogContent>
      <Divider />
      <DialogActions>
        {variant === 'confirm' && (
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button color="secondary" onClick={onCancel} startIcon={<CloseIcon />} variant="contained">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={onAccept} startIcon={<DoneIcon />} variant="contained">
                Aceptar
              </Button>
            </Grid>
          </Grid>
        )}
        {variant === 'info' && (
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button color="primary" onClick={onCancel} startIcon={<DoneIcon />} variant="contained">
                Aceptar
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
