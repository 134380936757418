import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, CircularProgress, colors } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useAxios } from 'hooks';

type Printer = {
  id: number;
  name: string;
  address: string;
  disabled: boolean;
};

interface PrinterRowProps {
  printer: Printer;
}

const PrinterRow = ({ printer }: PrinterRowProps): JSX.Element => {
  const [fetchingStatus, setFetchingStatus] = useState(true);
  const [status, setStatus] = useState('offline');
  const { axios } = useAxios();
  const history = useHistory();

  useEffect(() => {
    const getPrinterStatus = async (): Promise<void> => {
      setFetchingStatus(true);
      try {
        const response = await axios.get<{ success: boolean }>('printer_status', { params: { id: printer.id } });
        setStatus(response.data.success ? 'En línea' : 'Desconectado');
      } catch (err) {
        // TODO: Handle error
      } finally {
        setFetchingStatus(false);
      }
    };

    getPrinterStatus();
  }, []);

  return (
    <TableRow
      onClick={() => history.push('/printers/details', { id: printer.id })}
      sx={{ '&:hover': { cursor: 'pointer', bgcolor: colors.grey[200] } }}
    >
      <TableCell>{printer.name}</TableCell>
      <TableCell>{printer.address}</TableCell>
      <TableCell>{fetchingStatus ? <CircularProgress size={20} /> : status}</TableCell>
      <TableCell />
    </TableRow>
  );
};

export default PrinterRow;
