import React, { useState, useEffect } from 'react';
import { ListSubheader, Collapse, ListItem, ListItemText, IconButton, Grid, colors, Typography } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { Block as BlockIcon, Close as CloseIcon, Print as PrintIcon } from '@mui/icons-material';

import {
  GetAccountDetailsQuery,
  CancelDishMutation,
  CancelDishMutationVariables,
  CancelOrderMutation,
  CancelOrderMutationVariables
} from 'types/graphql';
import { CustomDialog } from 'components';
import { useSnackbar, useAxios } from 'context';
import { formattedNumber } from 'helpers';
import { palapa } from 'cbconstants';

type Order = GetAccountDetailsQuery['palapa_accounts'][0]['orders'][0];
type OrderControl = Order & { open: boolean };

interface AccountDetailOrderProps {
  order: Order;
  name: string;
}

const CANCEL_DISH = gql`
  mutation CancelDish($id: Int!, $orderId: Int!, $action: String!) {
    update_order_dish_by_pk(pk_columns: { id: $id }, _set: { disabled: true }) {
      id
      disabled
    }
    insert_orders_actions_one(object: { order_id: $orderId, type: $action }) {
      id
      type
    }
  }
`;

const CANCEL_ORDER = gql`
  mutation CancelOrder($orderId: Int!, $action: String!, $dishesFlag: order_dish_bool_exp!) {
    update_orders_by_pk(pk_columns: { id: $orderId }, _set: { status: "canceled" }) {
      id
      status
    }
    insert_orders_actions_one(object: { order_id: $orderId, type: $action }) {
      id
      type
    }
    update_order_dish(where: $dishesFlag, _set: { disabled: true }) {
      affected_rows
    }
  }
`;

let dishId = 1;

const AccountDetailOrder = ({ order, name }: AccountDetailOrderProps): JSX.Element => {
  const [orderControl, setOrderControl] = useState<OrderControl>({ ...order, open: false });
  const [openDialog, setOpenDialog] = useState(false);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { axios } = useAxios();
  const [cancelOrder] = useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CANCEL_ORDER);
  const [cancelDish] = useMutation<CancelDishMutation, CancelDishMutationVariables>(CANCEL_DISH);

  useEffect(() => {
    setOrderControl((prevState) => ({ ...order, open: prevState.open }));
  }, [order]);

  const handleDishCancel = async (id: number) => {
    try {
      await cancelDish({
        variables: {
          id,
          orderId: order.id,
          action: palapa.DISH_CANCELED
        }
      });

      showSnackbar('Platillo cancelado', 'success');
      setOpenDialog(false);
    } catch (err) {
      showSnackbar('Error al cancelar platillo', 'error');
    }
  };

  const handleOrderPrinting = async () => {
    try {
      await axios.post('print_ticket', { template: 'order', commensal: name, id: order.id });
    } catch (err) {
      showSnackbar('Error al imprimir comanda', 'error');
    }
  };

  const handleOrderCancel = async () => {
    try {
      const dishesFlag = orderControl.order_dishes.map((dish) => {
        return { id: { _eq: dish.id } };
      });

      await cancelOrder({
        variables: {
          orderId: orderControl.id,
          action: palapa.ORDER_CANCELED,
          dishesFlag: { _and: dishesFlag }
        }
      });
      showSnackbar('Comanda canelada', 'success');
      setOpenOrderDialog(false);
    } catch (err) {
      showSnackbar('Error al cancelar comanda', 'error');
    }
  };

  return (
    <li>
      <CustomDialog
        fullWidth
        maxWidth="md"
        onAccept={() => handleDishCancel(dishId)}
        onCancel={() => setOpenDialog(false)}
        open={openDialog}
        title="Cancelar platillo"
      >
        <Typography variant="body1">¿Seguro que desea cancelar el platillo?</Typography>
      </CustomDialog>
      <CustomDialog
        fullWidth
        maxWidth="md"
        onAccept={() => handleOrderCancel()}
        onCancel={() => setOpenOrderDialog(false)}
        open={openOrderDialog}
        title="Cancelar comanda"
      >
        <Typography variant="body1">¿Seguro que desea cancelar la comanda?</Typography>
      </CustomDialog>
      <ul>
        <ListSubheader
          onClick={() => setOrderControl({ ...orderControl, open: !orderControl.open })}
          sx={{
            bgcolor: colors.grey[100],
            color: 'black'
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>{`Comanda #${orderControl.id} | ${dayjs(orderControl.created_at).format('hh:mm:ss a')}`}</Grid>
            <Grid item sx={{ zIndex: 99999 }}>
              <IconButton onClick={() => setOpenOrderDialog(true)}>
                <BlockIcon color="error" />
              </IconButton>
              <IconButton onClick={handleOrderPrinting} sx={{ pl: 1 }}>
                <PrintIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </ListSubheader>
        <Collapse in={orderControl.open}>
          {orderControl.order_dishes.map((od, index) => (
            <>
              <ListItem
                key={index}
                secondaryAction={
                  !od.disabled ? (
                    <IconButton
                      onClick={() => {
                        dishId = od.id;
                        setOpenDialog(true);
                      }}
                    >
                      <CloseIcon color="error" />
                    </IconButton>
                  ) : null
                }
                sx={{
                  bgcolor: colors.grey[100]
                }}
              >
                <ListItemText
                  primary={od.dish.name}
                  secondary={
                    od.disabled
                      ? 'Cancelado'
                      : `${od.quantity} pieza${od.quantity === 1 ? '' : 's'} - $ ${formattedNumber(
                          (od.quantity * od.dish_price).toFixed(2)
                        )}`
                  }
                  sx={{ color: (theme) => (od.disabled ? theme.palette.error.main : theme.palette.primary.main) }}
                />
              </ListItem>
            </>
          ))}
        </Collapse>
      </ul>
    </li>
  );
};

export default AccountDetailOrder;
