import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

const LoadingIndicator = (): JSX.Element => {
  return (
    <Backdrop open sx={{ zIndex: 9999 }}>
      <CircularProgress size={60} />
    </Backdrop>
  );
};

export default LoadingIndicator;
