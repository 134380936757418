import React from 'react';
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableCellProps,
  CircularProgress
} from '@mui/material';

type Headers = Array<TableCellProps & { title: string }>;

interface CustomTableProps {
  headers: Headers;
  loading?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const CustomTable = ({ headers, children, loading }: CustomTableProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((h, index) => (
              <TableCell {...h} key={index}>
                {h.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell align="center" colSpan={headers.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            children
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
