import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Collapse, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMutation, gql, useLazyQuery, useQuery } from '@apollo/client';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { Apartments, NumberFormatInput } from 'components';
import { useSnackbar, useHasuraUser } from 'hooks';
import {
  GetCurrenciesQuery,
  CreateTransactionMutation,
  CreateTransactionMutationVariables,
  InsertOwnerArrivalMutationVariables,
  InsertRentalArrivalMutationVariables,
  Transaction_Type_Enum,
  UpdateArrivalMutationVariables,
  UpdateCommensalPinMutation, 
  UpdateCommensalPinMutationVariables
} from 'types/graphql';

interface UserDataFormProps {
  editing?: boolean;
  statementId?: number;
}

const INSERT_OWNER_ARRIVAL = gql`
  mutation InsertOwnerArrival($data: arrivals_insert_input!) {
    insert_arrivals_one(object: $data) {
      id
    }
  }
`;

const INSERT_RENTAL_ARRIVAL = gql`
  mutation InsertRentalArrival($data: rental_insert_input!) {
    insert_rental_one(object: $data) {
      id
    }
  }
`;

const UPDATE_ARRIVAL_DATA = gql`
  mutation UpdateArrival($data: arrivals_set_input!, $id: Int!) {
    update_arrivals_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

const CHECKIN_OWNER = gql`
  mutation CheckIn($id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { present: true }) {
      id
    }
  }
`;

const GET_STATEMENT_ACCOUNT_ID = gql`
  query GetStatementAccountId ($apartmentId: Int! $active: Boolean!, $owner: Boolean!) {
    statement_account(where: {apartment_id: {_eq: $apartmentId}, _and: {active: {_eq: $active}, _and: {owner: {_eq: $owner}}}}) {
      id
    }
  }
`;

const UPDATE_PIN = gql`
  mutation UpdateCommensalPin($id: Int!, $pin: String!) {
    update_statement_account_by_pk(pk_columns: { id: $id }, _set: { pin_created: true, pin: $pin }) {
      id
      pin
    }
  }
`;
const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($data: transactions_insert_input!) {
    insert_transactions_one(object: $data) {
      transaction_id
      amount
      exchange
      currency
    }
  }
`;

const GET_CURRENCIES = gql`
  query GetCurrencies {
    exchange(where: { disabled: { _eq: false } }, order_by: { id: asc }) {
      id
      currency_key
      currency_name
      exchange
    }
  }
`;

const validationSchema = yup.object().shape({
  userType: yup.string().required('Este campo es requerido'),
  arrival_date: yup.string().required('Este campo es requerido')
});

const initialFormikState = {
  userType: '2',
  arrival_date: dayjs().toISOString(),
  departure_date: dayjs().add(1, 'day').toISOString(),
  arrived_in: 'Car',
  people_number: '1',
  car_plates: '',
  car_description: '',
  rental_name: '',
  rental_lastname: '',
  car_color: '',
  comments: '',
  owner_name: '',
  apartment_name: '',
  apartment_id: 0,
  user_id: 0,
  commensal_id: 0,
  pin: '',
  pinConfirm: '',
  initial_deposit: 0,
  currency: 1
};

type FormikState = typeof initialFormikState & { commensal_id?: number | null };

const UserDataForm = ({ editing, statementId }: UserDataFormProps): JSX.Element => {
  const [formikState] = useState<FormikState>(initialFormikState);
  const [insertOwnerArrival] = useMutation<unknown, InsertOwnerArrivalMutationVariables>(INSERT_OWNER_ARRIVAL);
  const [insertRentalArrival] = useMutation<unknown, InsertRentalArrivalMutationVariables>(INSERT_RENTAL_ARRIVAL);
  const [updateArrival] = useMutation<unknown, UpdateArrivalMutationVariables>(UPDATE_ARRIVAL_DATA);
  const [checkInUser] = useMutation(CHECKIN_OWNER);
  const [fetchStatementAccount] = useLazyQuery(GET_STATEMENT_ACCOUNT_ID);
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const [updateCommensalPin] = useMutation<UpdateCommensalPinMutation, UpdateCommensalPinMutationVariables>(UPDATE_PIN);
  const [createTransaction] = useMutation<CreateTransactionMutation, CreateTransactionMutationVariables>(CREATE_TRANSACTION);
  const { data: currenciesData, loading: loadingCurrencies } = useQuery<GetCurrenciesQuery>(GET_CURRENCIES);
  const { user } = useHasuraUser();



  const handleDataSubmission = async (values: FormikState) => {
    try {  
      // Check if PINs match before proceeding
      const currencieData = currenciesData?.exchange.find((c) => c.id === values.currency);
      if (values.pin !== values.pinConfirm) {
        showSnackbar('Los NIPs deben coincidir', 'error');
        return;
      }
      
      if (!editing) {
        const commonArrivalData = {
          departure_date: values.departure_date,
          arrival_date: values.arrival_date,
          arrived_in: values.arrived_in,
          car_color: values.car_color,
          car_description: values.car_description,
          car_plates: values.car_plates,
          commensal_id: values.userType === '1' ? values.commensal_id : undefined,
          people_number: Number(values.people_number),
          name: values.userType === '1' ? values.owner_name : `${values.rental_name} ${values.rental_lastname}`,
          apartment_name: values.apartment_name
        };

        if (values.userType === '1') {
            await insertOwnerArrival({
              variables: {
                data: commonArrivalData
              }
            });
            await checkInUser({
              variables: { id: values.user_id }
            });
            if(Number(values.initial_deposit) > 0){
              if (currencieData){
                await createTransaction({
                  variables: {
                    data: {
                      statement_account_id: values.commensal_id,
                      amount: Number(values.initial_deposit),
                      currency: currencieData.currency_key,
                      exchange: currencieData.exchange,
                      transaction_type: 'Deposit' as Transaction_Type_Enum,
                      es_description: 'Depósito',
                      en_description: 'Deposit',
                      is_tip: false,
                      created_by: user?.id
                    }
                  }
                });
              }
            }  
  
        } else {
            await insertRentalArrival({
              variables: {
                data: {
                  first_name: values.rental_name,
                  last_name: values.rental_lastname,
                  apartment_id: values.apartment_id,
                  people_number: Number(values.people_number),
                  present: true,
                  arrivals: {
                    data: [commonArrivalData]
                  }
                }
              }
            });
            const {data} = await fetchStatementAccount({ variables: { apartmentId: values.apartment_id, active: true, owner: false } });

            await updateCommensalPin({
              variables: {
                id: data.statement_account[0].id,
                pin: values.pin
              }
            });
            if(Number(values.initial_deposit) > 0){
              if (currencieData){
                await createTransaction({
                  variables: {
                    data: {
                      statement_account_id: data.statement_account[0].id,
                      amount: Number(values.initial_deposit),
                      currency: currencieData.currency_key,
                      exchange: currencieData.exchange,
                      transaction_type: 'Deposit' as Transaction_Type_Enum,
                      es_description: 'Depósito',
                      en_description: 'Deposit',
                      is_tip: false,
                      created_by: user?.id
                    }
                  }
                });
              }
            }
        }
        showSnackbar('Registro creado correctamente', 'success');
        history.push('/reception');
      } else {
        await updateArrival({
          variables: {
            id: statementId || 0,
            data: {
              arrival_date: values.arrival_date,
              departure_date: values.departure_date,
              arrived_in: values.arrived_in,
              car_color: values.car_color,
              car_description: values.car_description,
              car_plates: values.car_plates
            }
          }
        });
        showSnackbar('Registro actualizado', 'success');
      }
    } catch (error) {
      if(values.userType === '1'){
        if (error?.graphQLErrors[0]?.extensions?.internal?.error?.message) {
          showSnackbar('El dueño ya esta presente', 'error');
        } else {
          showSnackbar('Error al crear el registro', 'error');
        }
      }else{
        if (error?.graphQLErrors[0]?.extensions?.internal?.error?.message) {
          showSnackbar('Ya existe una renta para este condominio', 'error');
        } else {
          showSnackbar('Error al crear el registro', 'error');
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formikState}
      onSubmit={handleDataSubmission}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, isSubmitting, handleSubmit }) => (
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="type">Tipo de usuario</InputLabel>
              <Select
                label="Tipo de usuario"
                labelId="type"
                onChange={(e) => setFieldValue('userType', e.target.value)}
                value={values.userType}
              >
                <MenuItem value="2">Huésped</MenuItem>
                <MenuItem value="1">Dueño</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              label="Fecha de llegada"
              onChange={(date) => setFieldValue('arrival_date', date)}
              renderInput={(props) => <TextField fullWidth {...props} />}
              value={values.arrival_date}
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              label="Fecha de salida"
              onChange={(date) => setFieldValue('departure_date', date)}
              renderInput={(props) => <TextField fullWidth {...props} />}
              value={values.departure_date}
            />
          </Grid>
          <Grid item xs={4}>
            <Apartments
              label="Condominio"
              onChange={(apartmentId, userId, commensalId, owner_name, apartment_name) => {
                setFieldValue('apartment_id', apartmentId);
                setFieldValue('user_id', userId);
                setFieldValue('commensal_id', commensalId);
                setFieldValue('owner_name', owner_name);
                setFieldValue('apartment_name', apartment_name);
              }}
              value={values.apartment_id}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Collapse in={values.userType === '2'}>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Nombre(s)"
                      onChange={(e) => setFieldValue('rental_name', e.target.value)}
                      value={values.rental_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Apellido(s)"
                      onChange={(e) => setFieldValue('rental_lastname', e.target.value)}
                      value={values.rental_lastname}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormatInput
                      fullWidth
                      label="Personas"
                      onChange={(e) => setFieldValue('people_number', e.target.value)}
                      value={values.people_number}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="type">Llegó en</InputLabel>
              <Select
                label="Llegó en"
                labelId="type"
                onChange={(e) => setFieldValue('arrived_in', e.target.value)}
                value={values.arrived_in}
              >
                <MenuItem value="Car">Carro</MenuItem>
                <MenuItem value="Plane">Avión</MenuItem>
                <MenuItem value="Bus">Autobús</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Collapse in={values.arrived_in === 'Car'}>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Placas"
                      onChange={(e) => setFieldValue('car_plates', e.target.value)}
                      value={values.car_plates}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Color"
                      onChange={(e) => setFieldValue('car_color', e.target.value)}
                      value={values.car_color}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descripción del vehículo"
                      multiline
                      onChange={(e) => setFieldValue('car_description', e.target.value)}
                      rows={3}
                      value={values.car_description}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Collapse in={values.userType === '2'}>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Nip"
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only numbers and limit to 4 digits
                        if (/^\d{0,4}$/.test(value)) {
                          setFieldValue('pin', value);
                        }
                      }}
                      type="password"
                      value={values.pin}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Confirmar Nip"
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only numbers and limit to 4 digits
                        if (/^\d{0,4}$/.test(value)) {
                          setFieldValue('pinConfirm', value);
                        }
                      }}
                      type="password"
                      value={values.pinConfirm}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <NumberFormatInput
              fullWidth
              helperText=" "
              inputProps={{ inputMode: 'numeric' }}
              label="Deposito Inicial"
              onChange={(e) => setFieldValue('initial_deposit', e.target.value.replace(/^0+/, ''))}
              prefix="$"
              thousandSeparator
              value={values.initial_deposit}
              variant="outlined"
            />
          </Grid>
          {!loadingCurrencies && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="exchange">Moneda</InputLabel>
                <Select
                  label="Moneda"
                  labelId="exchange"
                  onChange={(e) => setFieldValue('currency', e.target.value)}
                  value={values.currency}
                >
                  {currenciesData?.exchange.map((c, index) => (
                    <MenuItem key={index} value={c.id}>
                      {`${c.currency_name} (${c.currency_key}) ${
                        c.currency_key !== 'MXN' ? `($ ${c.exchange.toFixed(2)})` : ''
                      }`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comentarios"
              multiline
              onChange={(e) => setFieldValue('comments', e.target.value)}
              rows={3}
              value={values.comments}
              variant="outlined"
            />
          </Grid>
          <Grid alignItems="center" container item justifyContent="flex-end" spacing={2} xs={12}>
            <Grid item>
              <LoadingButton color="primary" loading={isSubmitting} onClick={() => handleSubmit()} variant="contained">
                Aceptar
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default UserDataForm;

