import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';


import { CustomDialog } from 'components';
import { useSnackbar } from 'hooks';
import { TransactionDialog, PinOperationsDialog, Transfers } from '.';
import { CheckoutRentalMutationVariables, CheckoutOwnerMutationVariables } from 'types/graphql';
import { PalapaReduxState } from 'types/redux';


type ContextMenuEvent = 'transfer' | 'deposit' | 'refund' | 'checkout' | 'charge';
type TransactionType = 'transfer' | 'deposit' | 'refund' | 'charge';
type TransactionDialog = {
  open: boolean;
  transactionType: TransactionType;
};

type PinDialog = {
  open: boolean;
  pinCreated?: boolean;
};

type TransferDialog = {
  open: boolean;
  statementId: number;
};

interface ContextMenuProps {
  pinCreated: boolean;
  statementId: number;
  commensalBalance: number;
  isOwner: boolean;
  rentalId: number | null;
  userId: number | null;
}

const CHECKOUT_RENTAL = gql`
  mutation CheckoutRental($id: Int!) {
    update_rental_by_pk(pk_columns: { id: $id }, _set: { present: false }) {
      id
    }
  }
`;

const CHECKOUT_OWNER = gql`
  mutation CheckoutOwner($id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { present: false }) {
      id
    }
  }
`;

const ContextMenu = ({
  pinCreated,
  statementId,
  commensalBalance,
  isOwner,
  rentalId,
  userId
}: ContextMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [pinDialog, setPinDialog] = useState<PinDialog>({ open: false, pinCreated: false });
  const [checkoutDialog, setCheckoutDialog] = useState<boolean>(false);
  const [transferDialog, setTransferDialog] = useState<TransferDialog>({ open: false, statementId: 0 });
  const [transactionDialog, setTransactionDialog] = useState<TransactionDialog>({
    open: false,
    transactionType: 'deposit'
  });
  const [checkoutRental] = useMutation<unknown, CheckoutRentalMutationVariables>(CHECKOUT_RENTAL);
  const [checkoutOwner] = useMutation<unknown, CheckoutOwnerMutationVariables>(CHECKOUT_OWNER);
  const { showSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  const handleContextMenuClick = (transactionType: ContextMenuEvent) => {
    if (transactionType === 'transfer') {
      setTransferDialog({ open: true, statementId });
    } else if (transactionType === 'checkout') {
      setCheckoutDialog(true);
    } else  {
      setTransactionDialog({ open: true, transactionType });
    }
    setAnchorEl(null);
  };

  const handleUserCheckout = async (): Promise<void> => {
    try {
      if (Math.abs(commensalBalance) > 0) {
        showSnackbar('No se puede dar salida a un usuario con saldo pendiente.', 'error');
        return;
      }
      if (!isOwner && rentalId) {
        await checkoutRental({ variables: { id: rentalId } });
      } else if (isOwner && userId) {
        await checkoutOwner({ variables: { id: userId } });
      } else {
        showSnackbar('Error al dar salida', 'error');
        return;
      }

      setCheckoutDialog(false);
      showSnackbar('Checkout correcto', 'success');
    } catch (err) {
      showSnackbar('Error al dar salida', 'error');
    }
  };

  const handlePinDialogClick = (pinCreated: boolean): void => {
    setPinDialog({ open: true, pinCreated });
    setAnchorEl(null);
  };

  const user = useSelector((state: PalapaReduxState) => state.user);

  return (
    <>
      <CustomDialog
        fullWidth
        maxWidth="md"
        onAccept={handleUserCheckout}
        onCancel={() => setCheckoutDialog(false)}
        open={checkoutDialog}
        title="Checkout"
      >
        ¿Dar salida?
      </CustomDialog>
      <Transfers
        onClose={() => setTransferDialog((prevState) => ({ ...prevState, open: false }))}
        open={transferDialog.open}
        statementId={transferDialog.statementId}
      />
      <TransactionDialog
        apartmentId={1}
        commensalBalance={commensalBalance}
        onClose={() => setTransactionDialog((prevState) => ({ ...prevState, open: false }))}
        open={transactionDialog.open}
        statementId={statementId}
        transactionType={transactionDialog.transactionType}
      />
      <PinOperationsDialog
        onClose={() => setPinDialog({ open: false, pinCreated })}
        open={pinDialog.open}
        pinCreated={pinDialog.pinCreated}
        statementId={statementId}
      />
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem onClick={() => handleContextMenuClick('transfer')}>Traspaso</MenuItem>
        {pinCreated ? (
          <div>
            <MenuItem onClick={() => handleContextMenuClick('deposit')}>Depósito</MenuItem>
            <MenuItem onClick={() => handleContextMenuClick('refund')}>Reembolso</MenuItem>
            <MenuItem onClick={() => handleContextMenuClick('charge')}>Consumo</MenuItem>
            {user.role === 'admin' && (
              <MenuItem onClick={() => handlePinDialogClick(true)}>Cambiar pin</MenuItem>
            )}          
          </div>
        ) : (
          <div>
            <MenuItem onClick={() => handlePinDialogClick(false)}>Crear pin</MenuItem>
          </div>
        )}
        <MenuItem onClick={() => handleContextMenuClick('checkout')}>Dar salida</MenuItem>
      </Menu>
    </>
  );
};

export default ContextMenu;
