import React, { useState, useEffect } from 'react';
import {
  useMediaQuery,
  Button,
  Divider,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Switch,
  IconButton
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { gql, useQuery } from '@apollo/client';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { CustomDialog, NumberFormatInput } from 'components';
import { GetAddDishIngredientsQuery } from 'types/graphql';

type Ingredient = {
  id: number;
  name: string;
  unit: string;
  optional: boolean;
  quantity: number;
};

type Ingredients = Array<Ingredient>;

type FormikState = {
  ingredients: Ingredients;
};

interface IngredientsPickerProps {
  open: boolean;
  ingredients: Ingredients;
  onAccept: (values: Ingredients) => void;
  onClose: () => void;
}

const GET_INGREDIENTS = gql`
  query GetAddDishIngredients {
    ingredients(order_by: { name: asc }) {
      id
      name
      available
      unitObject {
        name
        abreviation
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  ingredients: yup
    .array()
    .of(
      yup.object().shape({
        optional: yup.bool().required('Capo requerido'),
        id: yup.number().required('Campo id es requerido'),
        name: yup.string().required('Campo nombre requerido'),
        unit: yup.string().required('Campo unidad requerido'),
        quantity: yup.number().moreThan(0, 'La unidad debe ser mayor a 0')
      })
    )
    .min(1, 'Se debe elegir al menos un ingrediente')
});

const formikInitialState: FormikState = {
  ingredients: [
    {
      id: 1,
      name: 'Jamón',
      unit: 'Pz',
      optional: false,
      quantity: 1
    }
  ]
};

const IngredientsPicker = ({ open, onAccept, onClose, ingredients }: IngredientsPickerProps): JSX.Element => {
  const topLabel = useMediaQuery('(max-width: 910px)');
  const [formikState, setFormikState] = useState<FormikState>(formikInitialState);
  const { data, loading } = useQuery<GetAddDishIngredientsQuery>(GET_INGREDIENTS);

  useEffect(() => {
    if (ingredients.length > 0) {
      setFormikState({ ingredients });
    }
  }, [ingredients]);

  const handleFormSubmission = (values: FormikState) => {
    onAccept(values.ingredients);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formikState}
      onSubmit={handleFormSubmission}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={() => handleSubmit()}
          onCancel={onClose}
          open={open}
          title="Agregar ingredientes"
        >
          {loading ? (
            <Grid alignItems="center" container item justifyContent="center" xs={12}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {values.ingredients.map((i, index) => (
                <Grid alignItems="center" container item justifyContent="flex-end" key={index} spacing={1} xs={12}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="ingredient">Ingrediente</InputLabel>
                      <Select
                        label="Ingrediente"
                        labelId="ingredient"
                        onChange={(e) => {
                          const selectedIngredient = data?.ingredients.find((ing) => ing.id === e.target.value);
                          setFieldValue(`ingredients[${index}]`, {
                            id: e.target.value,
                            name: selectedIngredient?.name,
                            unit: selectedIngredient?.unitObject.abreviation,
                            optional: false,
                            quantity: 1
                          } as Ingredient);
                        }}
                        value={i.id}
                      >
                        {data?.ingredients.map((ingredient, index) => (
                          <MenuItem key={index} value={ingredient.id}>
                            {ingredient.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <NumberFormatInput
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{i.unit}</InputAdornment>
                      }}
                      label="Cantidad"
                      onChange={(e) => setFieldValue(`ingredients[${index}].quantity`, e.target.value)}
                      value={i.quantity}
                    />
                  </Grid>
                  <Grid container item justifyContent="flex-end" xs={2}>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={i.optional}
                            color="primary"
                            onChange={(e) => setFieldValue(`ingredients[${index}].optional`, e.target.checked)}
                          />
                        }
                        label="Opcional"
                        labelPlacement={topLabel ? 'top' : 'end'}
                      />
                    </Grid>
                  </Grid>
                  <Grid alignItems="center" container item justifyContent="center" xs={1}>
                    <Grid item>
                      <IconButton
                        color="error"
                        disabled={values.ingredients.length === 1}
                        onClick={() => {
                          const ingredientsCopy = [...values.ingredients];
                          ingredientsCopy.splice(index, 1);
                          setFieldValue('ingredients', ingredientsCopy);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ pt: 1, pb: 1 }} xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
              <Grid container item sx={{ mt: 2 }} xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={() =>
                    setFieldValue('ingredients', [...values.ingredients, formikInitialState.ingredients[0]])
                  }
                  variant="contained"
                >
                  Nuevo ingrediente
                </Button>
              </Grid>
            </>
          )}
        </CustomDialog>
      )}
    </Formik>
  );
};

export default IngredientsPicker;
