import React from 'react';
import { colors, Toolbar, Divider, List, ListItem, Button, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { NavLink as Link } from 'react-router-dom';
import {
  MenuBook as MenuBookIcon,
  People as PeopleIcon,
  Bookmark as BookmarkIcon,
  Book as BookIcon,
  Print as PrintIcon,
  ManageAccounts as ManageAccountsIcon,
  Apartment as ApartmentIcon,
  DirectionsCar as DirectionsCarIcon,
  Article as ArticleIcon
} from '@mui/icons-material';

import { PalapaReduxState, UserRole } from 'types/redux';

type Route = {
  icon: JSX.Element;
  route: string;
  title: string;
  requires: Array<UserRole>;
};

const routes: Array<Route> = [
  {
    icon: <PeopleIcon />,
    route: '/accounts',
    title: 'Cuentas',
    requires: ['palapa']
  },
  {
    icon: <MenuBookIcon />,
    route: '/menu',
    title: 'Menú',
    requires: ['admin']
  },
  {
    icon: <PrintIcon />,
    route: '/printers',
    title: 'Impresoras',
    requires: ['admin']
  },
  {
    icon: <BookmarkIcon />,
    route: '/checkin',
    title: 'Check-In',
    requires: ['reception']
  },
  {
    icon: <BookIcon />,
    route: '/reception',
    title: 'Recepción',
    requires: ['reception']
  },
  {
    icon: <ManageAccountsIcon />,
    route: '/users',
    title: 'Usuarios',
    requires: ['admin']
  },
  {
    icon: <ApartmentIcon />,
    route: '/apartments',
    title: 'Condominios',
    requires: ['admin']
  },
  {
    icon: <DirectionsCarIcon />,
    route: '/visits',
    title: 'Visitas',
    requires: ['portico','reception']
  },
  {
    icon: <ArticleIcon />,
    route: '/reports',
    title: 'Reportes',
    requires: ['reception']
  }
  // {
  //   icon: <ArticleIcon />,
  //   route: '/statements',
  //   title: 'Estados de cuenta',
  //   requires: 'admin'
  // }
];

const useStyles = makeStyles((theme: Theme) => ({
  inactiveLink: {
    fontWeight: theme.typography.fontWeightMedium,
    width: '95%',
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: '10px 8px',
    color: 'white',
    borderRadius: '0 40px 40px 0',
    '& .MuiButton-startIcon': {
      marginLeft: theme.spacing(1)
    },
    '&:hover': {
      backgroundColor: colors.grey[800]
    }
  },
  activeLink: {
    backgroundColor: colors.grey[800]
  }
}));

const DrawerContent = (): JSX.Element => {
  const styles = useStyles();
  const user = useSelector((state: PalapaReduxState) => state.user);

  // Filter routes based on the user's role
  const filteredRoutes = routes.filter(route => route.requires.includes(user.role) || user.role === 'admin');

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {filteredRoutes.map((route) => (
          <ListItem disableGutters key={route.route}>
            <Button
              activeClassName={styles.activeLink}
              className={styles.inactiveLink}
              component={Link}
              startIcon={route.icon}
              sx={{ pl: 2 }}
              to={route.route}
            >
              {route.title}
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DrawerContent;