import React from 'react';
import { Typography, Grid, TextField, Divider, Switch, FormControlLabel, IconButton, Fab } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Add as AddIcon, Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';
import * as yup from 'yup';

import { CreateModifierMutation, CreateModifierMutationVariables } from 'types/graphql';

const CREATE_MODIFIER = gql`
  mutation CreateModifier($data: modifier_groups_insert_input!) {
    insert_modifier_groups_one(object: $data) {
      id
    }
  }
`;

const validationSchema = yup.object().shape({
  title: yup.string().required('Este campo es requerido'),
  modifiers: yup.array().of(yup.string())
});

const initialFormikState = {
  title: '',
  multiple: false,
  modifiers: ['']
};

type FormikState = typeof initialFormikState;

const AddModifiers = (): JSX.Element => {
  const history = useHistory();
  const [doInsert] = useMutation<CreateModifierMutation, CreateModifierMutationVariables>(CREATE_MODIFIER);

  const handleSubmission = async (values: FormikState) => {
    try {
      await doInsert({
        variables: {
          data: {
            title: values.title,
            multiple: values.multiple,
            modifiers: {
              data: values.modifiers.map((m) => ({
                title: m
              }))
            }
          }
        }
      });
      history.goBack();
    } catch (err) {
      // TODO: Handle error
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">Agregar modificador</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Formik initialValues={initialFormikState} onSubmit={handleSubmission} validationSchema={validationSchema}>
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
            <>
              <Grid item xs={11}>
                <TextField
                  error={Boolean(errors.title)}
                  fullWidth
                  helperText={Boolean(errors.title) && errors.title}
                  label="Grupo modificador"
                  onChange={handleChange('title')}
                  value={values.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel
                  control={<Switch checked={values.multiple} onChange={handleChange('multiple')} />}
                  label="Múltiple"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="right">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      const newModifier = [...values.modifiers, ''];
                      setFieldValue('modifiers', newModifier);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Divider>
              </Grid>
              {values.modifiers.map((m, index) => (
                <Grid alignItems="center" container item key={index} spacing={2} xs={12}>
                  <Grid item xs={11}>
                    <TextField
                      autoFocus
                      fullWidth
                      label="Nombre modificador"
                      onChange={(e) => setFieldValue(`modifiers[${index}]`, e.target.value)}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          setFieldValue('modifiers', [...values.modifiers, '']);
                        }
                      }}
                      value={m}
                    />
                  </Grid>
                  <Grid alignItems="center" container item justifyContent="center" spacing={2} xs={1}>
                    <Grid item>
                      <IconButton
                        color="error"
                        onClick={() => {
                          const arrayCopy = [...values.modifiers];
                          arrayCopy.splice(index, 1);
                          setFieldValue('modifiers', arrayCopy);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Fab color="primary" onClick={() => handleSubmit()} sx={{ position: 'absolute', bottom: 20, right: 20 }}>
                <DoneIcon />
              </Fab>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddModifiers;
