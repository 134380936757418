import * as ActionTypes from './actions';
import {
  StoreAction,
  StoreUserAction,
  UserState,
  RemoveUserAction,
  OpenSnackbarAction,
  CloseSnackbarAction,
  SnackbarState,
  UpdateTokenAction
} from 'types/redux';

export function storeUser(user: UserState): StoreUserAction {
  return dispatchAction(ActionTypes.STORE_USER, user);
}

export function removeUser(): RemoveUserAction {
  return dispatchAction(ActionTypes.REMOVE_USER, null);
}

export function openSnackbar(snackbar: SnackbarState): OpenSnackbarAction {
  return dispatchAction(ActionTypes.OPEN_SNACKBAR, { ...snackbar, visible: true });
}

export function closeSnackbar(): CloseSnackbarAction {
  return dispatchAction(ActionTypes.CLOSE_SNACKBAR, null);
}

export function updateToken(token: string): UpdateTokenAction {
  return dispatchAction(ActionTypes.UPDATE_TOKEN, token);
}

function dispatchAction<T extends string, P>(type: T, payload: P): StoreAction<T, P> {
  return { type, payload };
}
