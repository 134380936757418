import React, { FC, useState, useContext, useEffect } from 'react';
import {
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  useMediaQuery,
  Theme,
  CssBaseline,
  Box,
  Drawer,
  Button,
  IconButton,
  colors
} from '@mui/material';
import { AccountBox as AccountBoxIcon } from '@mui/icons-material';
import { Menu as MenuIcon, Logout as LogoutIcon } from '@mui/icons-material';

import { DrawerContent, Footer } from './components';
import { AuthFirebaseContext } from 'components';
import mdLogo from 'images/logo/md.png';

const DRAWER_WIDTH = 220;

const MainLayout: FC = ({ children }): JSX.Element => {
  const [open, setOpen] = useState(true);
  const { logout } = useContext(AuthFirebaseContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userMenu = Boolean(anchorEl);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState);
  };

  const handleMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    if (logout) {
      logout();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile ? (
            <>
              <IconButton onClick={handleDrawerToggle} size="large">
                <MenuIcon style={{ color: 'white' }} />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Box component="div" sx={{ pr: 1, pl: 1 }}>
                <img alt="Costabonita Logo" src={mdLogo} style={{ width: '100%' }} />
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton size="large">
                <AccountBoxIcon style={{ color: 'white' }} />
              </IconButton>
            </>
          ) : (
            <>
              <img alt="Costabonita Logo" src={mdLogo} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ pr: 1 }}>
                <Button color="inherit" onClick={handleMenuToggle} startIcon={<AccountBoxIcon />}>
                  Perfil
                </Button>
                <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={userMenu}>
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon sx={{ color: (theme) => theme.palette.grey[600] }} />
                    &nbsp; Cerrar sesión
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isMobile ? (
        <Drawer
          ModalProps={{ keepMounted: true }}
          onClose={handleDrawerToggle}
          open={open}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, backgroundColor: colors.grey[900] }
          }}
          variant="temporary"
        >
          <Box sx={{ overflow: 'auto' }}>
            <DrawerContent />
          </Box>
        </Drawer>
      ) : (
        <Drawer
          open
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, backgroundColor: colors.grey[900] }
          }}
          variant="permanent"
        >
          <DrawerContent />
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1, p: isMobile ? 2 : 3, mt: 8, pb: isMobile ? '80px' : 2 }}>
        {children}
        {isMobile && <Footer />}
      </Box>
    </Box>
  );
};

export default MainLayout;
