import React, { useState, useEffect } from 'react';
import { Grid, Divider, TableRow, TableCell, CircularProgress, Pagination } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { CustomTable } from 'components';
import { GetUsersQuery, GetUsersQueryVariables } from 'types/graphql';

type Headers = Parameters<typeof CustomTable>[0]['headers'];

const USERS_PER_PAGE = 20;

const GET_USERS_STATEMENTS = gql`
query MyQuery {
    users_aggregate {
        aggregate {
          count
        }
      }
    users(where: {apartments: {_not: {statement_accounts: {}}}}) {
      id
      first_name
      apartments {
        id
        name
        building {
          name
        }
      }
    }
  }`

// const GET_USERS = gql`
//   query GetUsers($limit: Int!, $offset: Int!, $searchString: String!) {
//     users_aggregate(
//       where: {
//         _or: [
//           { first_name: { _ilike: $searchString } }
//           { last_name: { _ilike: $searchString } }
//           { email: { _ilike: $searchString } }
//         ]
//       }
//     ) {
//       aggregate {
//         count
//       }
//     }
//     users(
//       order_by: { first_name: asc }
//       where: {
//         _or: [
//           { first_name: { _ilike: $searchString } }
//           { last_name: { _ilike: $searchString } }
//           { email: { _ilike: $searchString } }
//         ]
//       }
//       offset: $offset
//       limit: $limit
//     ) {
//       id
//       first_name
//       last_name
//       email
//       roles
//     }
//   }
// `;

const TABLE_HEADERS: Headers = [
  {
    title: 'Nombre'
  },
  {
    title: 'Condominio'
  }
];

const Statements = (): JSX.Element => {
    const [searchString] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const history = useHistory();
    const { data, loading } = useQuery<GetUsersQuery, GetUsersQueryVariables>(GET_USERS_STATEMENTS, {
      variables: {
        searchString: `%${searchString}%`,
        offset: (currentPage - 1) * USERS_PER_PAGE,
        limit: USERS_PER_PAGE
      }
    });
  
    useEffect(() => {
      if (data) {
        setMaxPages(Math.ceil((data.users_aggregate.aggregate?.count || 0) / USERS_PER_PAGE));
      }
    }, [data]);
  
    // const getUserRole = (role: string): string => {
    //   switch (role) {
    //     case 'admin':
    //       return 'Administrador';
  
    //     case 'recepcion':
    //       return 'Recepcionista';
  
    //     case 'user':
    //       return 'Usuario / dueño';
  
    //     case 'palapa':
    //       return 'Mesero';
  
    //     default:
    //       return 'unknown';
    //   }
    // };
  
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <CustomTable headers={TABLE_HEADERS}>
            {loading ? (
              <TableRow>
                <TableCell align="center" colSpan={TABLE_HEADERS.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              data?.users.map((user, index) => (
                <TableRow
                  key={index}
                  onClick={() => history.push('/users/details', { id: user.id })}
                  sx={{ cursor: 'pointer', '&:hover': { backgroundColor: (theme) => theme.palette.grey[200] } }}
                >
                  <TableCell>
                    {user.first_name} {user.last_name || ''}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))
            )}
          </CustomTable>
        </Grid>
        <Grid alignItems="center" container item justifyContent="center" spacing={2} xs={12}>
          <Grid item>
            <Pagination
              color="primary"
              count={maxPages}
              onChange={(_, value) => setCurrentPage(value)}
              page={currentPage}
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default Statements;
