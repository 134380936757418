import React, { useState } from 'react';
import { Grid, Typography, IconButton, Divider, List, ListItem, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';

import CategoryContainerItem from './CategoryContainerItem';

type Category = {
  id: number;
  title: string;
  position: number;
  disabled: boolean;
  hideMenu?: boolean;
  editing?: boolean;
};

type CategoryType = 'menus' | 'categories' | 'subcategories';

interface CategorieContainerProps {
  title: 'Menús' | 'Categorías' | 'Subcategorías';
  categories: Array<Category>;
  selectedCategory: number;
  onCategoryClick?: (type: CategoryType, id: number) => void;
  onAddCategoryClick?: (type: CategoryType) => void;
  onEditClick?: (type: CategoryType, categories: Array<Category>) => void;
  onVisibilityChange?: (type: CategoryType, id: number) => void;
  onStatusToggle?: (type: CategoryType, category: Category | null) => Promise<void>;
  handleDrag?: (type: CategoryType, index: number) => void;
  handleDragStart?: (type: CategoryType, index: number) => void;
  handleDragOver?: (type: CategoryType, index: number) => void;
  handleDragEnd?: (type: CategoryType) => Promise<unknown>;
}

const CategorieContainer = ({
  title,
  categories,
  selectedCategory,
  onCategoryClick,
  onAddCategoryClick,
  onEditClick,
  onStatusToggle,
  handleDrag,
  handleDragStart,
  handleDragOver,
  handleDragEnd
}: CategorieContainerProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<Category | null>(null);
  const open = Boolean(anchorEl);
  const categoryType: CategoryType =
    title === 'Menús' ? 'menus' : title === 'Categorías' ? 'categories' : 'subcategories';

  const handleEditClick = () => {
    const currentIndex = categories.findIndex((c) => c.id === selectedMenuCategory?.id) || -1;
    if (currentIndex >= 0 && selectedMenuCategory && onEditClick) {
      const arrayCopy = [...categories];
      arrayCopy[currentIndex] = { ...selectedMenuCategory, editing: !selectedMenuCategory.editing };
      onEditClick(categoryType, arrayCopy);
      setAnchorEl(null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            onClick={() => {
              onAddCategoryClick && onAddCategoryClick(categoryType);
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
          <MenuItem onClick={() => onStatusToggle && onStatusToggle(categoryType, selectedMenuCategory)}>
            <ListItemIcon>{selectedMenuCategory?.disabled ? <VisibilityIcon /> : <VisibilityOffIcon />}</ListItemIcon>
            {selectedMenuCategory?.disabled ? 'Mostrar' : 'Ocultar'}
          </MenuItem>
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Editar
          </MenuItem>
        </Menu>
        <List>
          {categories.length >= 1 ? (
            categories.map((c, index) => (
              <ListItem
                disablePadding
                draggable
                key={index}
                onDrag={() => handleDrag && handleDrag(categoryType, index)}
                onDragEnd={() => handleDragEnd && handleDragEnd(categoryType)}
                onDragOver={() => handleDragOver && handleDragOver(categoryType, index)}
                onDragStart={() => handleDragStart && handleDragStart(categoryType, index)}
                secondaryAction={
                  !c.hideMenu && (
                    <IconButton
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setSelectedMenuCategory(c);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )
                }
                sx={{ py: 0.5 }}
              >
                <CategoryContainerItem
                  category={c}
                  categoryType={categoryType}
                  onCategoryClick={onCategoryClick}
                  selected={selectedCategory === c.id}
                />
              </ListItem>
            ))
          ) : (
            <Grid alignItems="center" container item justifyContent="center" spacing={2} xs={12}>
              <Grid item>
                <Typography variant="body1">Sin registros</Typography>
              </Grid>
            </Grid>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default CategorieContainer;
