import React, { useEffect, useState } from 'react';
import {
  TextField,
  ListItemText,
  Grid,
  Typography,
  Divider,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  Chip,
  Box,
  MenuItem
} from '@mui/material';
import { Remove as RemoveIcon, Add as AddIcon } from '@mui/icons-material';

import { CustomDialog } from 'components';
import { GetCategoriesDishesQuery } from 'types/graphql';

type Modifier = {
  id: number;
  title: string;
  selected: boolean;
};

type ModifierGroup = {
  id: number;
  title: string;
  multiple: boolean;
  modifiers: Array<Modifier>;
};

type Ingredient = {
  id: number;
  name: string;
  selected: boolean;
  enabled: boolean;
};

type Ingredients = Array<Ingredient>;
type ModifierGroups = Array<ModifierGroup>;

type Dish = GetCategoriesDishesQuery['menu_types'][0]['categories'][0]['subcategories'][0]['dishes'][0] & {
  quantity?: number;
  notes?: string;
  ingredients?: Ingredients;
  modifierGroups?: ModifierGroups;
};

interface DishIngredientPickerProps {
  dish: Dish | null;
  open: boolean;
  onClose: (...args: any[]) => void;
  onAccept: (dish: Dish) => void;
}

const DishIngredientPicker = ({ dish, open, onClose, onAccept }: DishIngredientPickerProps): JSX.Element => {
  const [notes, setNotes] = useState('');
  const [ingredients, setIngredients] = useState<Ingredients | null>(null);
  const [modifierGroups, setModifierGroups] = useState<ModifierGroups>([]);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (dish?.ingredient_dishes) {
      const mappedIngredients: Ingredients = dish?.ingredient_dishes.map((id) => ({
        id: id.ingredient.id,
        name: id.ingredient.name,
        selected: true,
        enabled: !id.optional
      }));

      setIngredients(mappedIngredients);
    } else {
      setIngredients(null);
    }

    if (dish?.modifier_group_dishes) {
      const mappedModifiers: ModifierGroups = dish.modifier_group_dishes.map((mg) => ({
        id: mg.modifier_group_id,
        multiple: mg.modifier_group.multiple,
        title: mg.modifier_group.title,
        modifiers: mg.modifier_group.modifiers.map((m) => ({ title: m.title, selected: false, id: m.id }))
      }));

      setModifierGroups(mappedModifiers);
    } else {
      setModifierGroups([]);
    }
  }, [dish]);

  const handleLessClick = () => {
    if (quantity <= 1) {
      return;
    }
    setQuantity((prevState) => --prevState);
  };

  const handleMoreClick = () => {
    setQuantity((prevState) => ++prevState);
  };

  const handleAccept = () => {
    if (dish && ingredients) {
      const fixedDish = {
        ...dish,
        quantity,
        notes,
        ingredients,
        modifierGroups
      };

      onAccept(fixedDish);
      handleClose();
    } else onClose();
  };

  const handleClose = () => {
    onClose();
    setQuantity(1);
    setNotes('');
  };

  return (
    <CustomDialog
      fullWidth
      maxWidth="md"
      onAccept={handleAccept}
      onCancel={handleClose}
      onClose={handleClose}
      open={open}
      title="Agregar platillo"
    >
      <Grid container flexDirection="column" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{dish?.name}</Typography>
          <Grid container item spacing={2} sx={{ mt: 2, pl: 2, pr: 2 }} xs={12}>
            {ingredients?.map((i, index) => (
              <>
                <Grid item key={index} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={i.selected}
                        disabled={i.enabled}
                        onClick={() => {
                          const arrayCopy = [...ingredients];
                          arrayCopy[index].selected = !arrayCopy[index].selected;
                          setIngredients(arrayCopy);
                        }}
                      />
                    }
                    label={i.name}
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {modifierGroups?.length > 0 ? (
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">Modificadores:</Typography>
            </Grid>
            {modifierGroups.map((mg, index) => (
              <Grid item key={index} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id={`label-${index}`}>{mg.title}</InputLabel>
                  <Select
                    fullWidth
                    id={`select-label-${index}`}
                    label={mg.title}
                    labelId={`label-${index}`}
                    multiple={mg.multiple}
                    renderValue={() =>
                      mg.multiple ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {mg.modifiers.map((m)=>{
                            if(m.selected)
                            return (<Chip label={m.title} />)
                            else
                            return null
                          })}
                        </Box>
                      ) : (
                        mg.modifiers.find((m) => m.selected)?.title || ''
                      )
                    }
                    value={mg.multiple ? mg.modifiers.filter((m) => m.selected) : mg.modifiers.find((m) => m.selected)}
                  >
                    {mg.modifiers.map((m, mIndex) => (
                      <MenuItem
                        key={mIndex}
                        onClick={() => {
                          if (mg.multiple) {
                            const arrayCopy = [...modifierGroups];
                            arrayCopy[index].modifiers[mIndex].selected = !arrayCopy[index].modifiers[mIndex].selected;
                            setModifierGroups(arrayCopy);
                          } else {
                            const arrayCopy = [...modifierGroups];
                            arrayCopy[index].modifiers.forEach((m) => {
                              m.selected = false;
                            });
                            arrayCopy[index].modifiers[mIndex].selected = !arrayCopy[index].modifiers[mIndex].selected;
                            setModifierGroups(arrayCopy);
                          }
                        }}
                        value={m.title}
                      >
                        {mg.multiple ? (
                          <>
                            <Checkbox checked={m.selected} />
                            <ListItemText primary={m.title} />
                          </>
                        ) : (
                          m.title
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        ) : null}
        <Grid container item justifyContent="space-between" spacing={1} sx={{ mb: 1, pr: 3, pl: 3 }} xs={12}>
          <Grid item>
            <IconButton color="primary" onClick={handleLessClick}>
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="body1">{quantity}</Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleMoreClick}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Notas"
            maxRows={3}
            multiline
            onChange={(e) => setNotes(e.target.value)}
            rows={3}
            value={notes}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default DishIngredientPicker;
