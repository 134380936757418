import React, { useState, useEffect } from 'react';
import {
  Pagination,
  IconButton,
  Switch,
  Collapse,
  CircularProgress,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Divider
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

import { useSnackbar } from 'context';
import {
  GetModifiersQuery,
  GetModifiersQueryVariables,
  UpdateModifierGroupMutation,
  UpdateModifierGroupMutationVariables
} from 'types/graphql';

type Modifier = GetModifiersQuery['modifier_groups'][0] & { open: boolean };

const MODIFIERS_PER_PAGE = 15;

const GET_MODIFIER_GROUPS = gql`
  query GetModifiers($offset: Int!, $limit: Int!) {
    modifier_groups_aggregate {
      aggregate {
        count
      }
    }
    modifier_groups(order_by: { title: asc }, limit: $limit, offset: $offset) {
      id
      title
      multiple
      modifiers {
        title
      }
    }
  }
`;

const UPDATE_MODIFIER_GROUP = gql`
  mutation UpdateModifierGroup($id: Int!, $multiple: Boolean!) {
    update_modifier_groups_by_pk(pk_columns: { id: $id }, _set: { multiple: $multiple }) {
      id
    }
  }
`;

const Modifiers = (): JSX.Element => {
  const [maxPages, setMaxPages] = useState(1);
  const [modifiers, setModifiers] = useState<Array<Modifier>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { showSnackbar } = useSnackbar();
  const { data, loading } = useQuery<GetModifiersQuery, GetModifiersQueryVariables>(GET_MODIFIER_GROUPS, {
    variables: {
      offset: (currentPage - 1) * MODIFIERS_PER_PAGE,
      limit: MODIFIERS_PER_PAGE
    }
  });
  const history = useHistory();
  const [doUpdate] = useMutation<UpdateModifierGroupMutation, UpdateModifierGroupMutationVariables>(
    UPDATE_MODIFIER_GROUP
  );

  useEffect(() => {
    if (data) {
      setMaxPages(Math.ceil((data.modifier_groups_aggregate.aggregate?.count || 0) / MODIFIERS_PER_PAGE));
      const mappedModifiers: Array<Modifier> = data.modifier_groups.map((mg) => ({
        ...mg,
        open: false
      }));
      setModifiers(mappedModifiers);
    }
  }, [data]);

  const handleMultipleChange = async (id: number) => {
    try {
      if (data?.modifier_groups) {
        const prevValue = data.modifier_groups.find((mg) => mg.id === id)?.multiple;
        const index = data.modifier_groups.findIndex((mg) => mg.id === id);
        const arrayCopy = [...modifiers];

        await doUpdate({
          variables: {
            id,
            multiple: !prevValue
          }
        });

        arrayCopy[index].multiple = !arrayCopy[index].multiple;
        setModifiers(arrayCopy);

        showSnackbar('Modificador actualizado', 'success');
      }
    } catch (err) {
      showSnackbar('Error al actualizar modificador', 'error');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">Modificadores</Typography>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => history.push('/menu/modifiers/add')} variant="contained">
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%">
                  Múltiple
                </TableCell>
                <TableCell align="center" width="10%">
                  Opciones
                </TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell align="center" width="8%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                modifiers?.map((mg, index) => (
                  <>
                    <TableRow key={mg.id} sx={{ '&:hover': { backgroundColor: (theme) => theme.palette.grey[200] } }}>
                      <TableCell align="center" width="10%">
                        <Switch checked={mg.multiple} color="primary" onChange={() => handleMultipleChange(mg.id)} />
                      </TableCell>
                      <TableCell align="center">{mg.modifiers.length}</TableCell>
                      <TableCell>{mg.title}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            const arrayCopy = [...modifiers];
                            arrayCopy[index].open = !arrayCopy[index].open;
                            setModifiers(arrayCopy);
                          }}
                        >
                          {!mg.open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} sx={{ pb: 0, pt: 0 }}>
                        <Collapse in={mg.open}>
                          <Box sx={{ margin: 1 }}>
                            <List>
                              {mg.modifiers.map((m, index) => (
                                <ListItem key={index}>
                                  <ListItemText primary={m.title} />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} sx={{ mt: 2 }} xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={maxPages}
            onChange={(_, value) => setCurrentPage(value)}
            page={currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Modifiers;
