import React, { useState, useEffect } from 'react';
import { Grid, Typography, Pagination, Divider, TableRow, TableCell, CircularProgress, TextField } from '@mui/material';
import { gql, useQuery } from '@apollo/client';

import { GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables } from 'types/graphql';
import { ApartmentRow } from './components';
import { CustomTable } from 'components';

type Headers = Parameters<typeof CustomTable>[0]['headers'];

const APARTMENTS_PER_PAGE = 20;

const TABLE_HEADERS: Headers = [
  {
    title: 'Condominio',
    width: '20%'
  },
  {
    title: 'Dueño'
  },
  {
    title: 'Saldo',
    align: 'right'
  },
  {
    title: 'Saldo retenido',
    align: 'right'
  },
  {
    title: '',
    width: '10%',
    align: 'center'
  }
];

const GET_APARTMENTS = gql`
  query GetAdminApartments($limit: Int!, $offset: Int!, $searchString: String!) {
    apartments_helper_aggregate(
      where: { _or: [{ owner: { _ilike: $searchString } }, { apartment_name: { _ilike: $searchString } }] }
    ) {
      aggregate {
        count
      }
    }
    apartments_helper(
      order_by: [{ name: asc }, { number: asc }]
      limit: $limit
      offset: $offset
      where: { _or: [{ owner: { _ilike: $searchString } }, { apartment_name: { _ilike: $searchString } }] }
    ) {
      owner_id
      statement_account_id
      apartment_id
      apartment_name
      balance
      balance_retained
      owner
    }
  }
`;

const Apartments = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [maxPages, setMaxPages] = useState(1);
  const { data, loading } = useQuery<GetAdminApartmentsQuery, GetAdminApartmentsQueryVariables>(GET_APARTMENTS, {
    variables: {
      limit: APARTMENTS_PER_PAGE,
      offset: (currentPage - 1) * APARTMENTS_PER_PAGE,
      searchString: `%${searchString}%`
    },
    pollInterval: 1000
  });

  useEffect(() => {
    const count = data?.apartments_helper_aggregate?.aggregate?.count;
    if (count) {
      setMaxPages(Math.ceil(count / APARTMENTS_PER_PAGE));
    }
  }, [data]);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">Condominios</Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="Búsqueda"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS}>
          {loading ? (
            <TableRow>
              <TableCell align="center" colSpan={TABLE_HEADERS.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            data?.apartments_helper.map((a, index) => <ApartmentRow apartment={a} key={index} />)
          )}
        </CustomTable>
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={maxPages}
            onChange={(e, value) => setCurrentPage(value)}
            page={currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Apartments;
