import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

import { UserDataForm } from './components';

const Registration = (): JSX.Element => {
  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">Registro</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <UserDataForm />
      </Grid>
    </Grid>
  );
};

export default Registration;
