import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Grid, TextField } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import * as yup from 'yup';

import { CustomDialog, CommensalSelector } from 'components';
import { useSnackbar, useHasuraUser } from 'hooks';
import { formattedNumber } from 'helpers';
import CustomNumberFormat from './CustomNumberFormat';
import { CreateTransferMutationVariables, Currency_Type_Enum, Transaction_Type_Enum } from 'types/graphql';

interface TransfersProps {
  open: boolean;
  statementId: number;
  onClose: (...args: any[]) => void;
}

const validationSchema = yup.object().shape({
  total: yup
    .number()
    .required('Este campo es requerido')
    .when('fromBalance', (fromBalance, schema) =>
      fromBalance
        ? schema.max(
            fromBalance,
            `El monto a transferir no puede ser mayor a $ ${formattedNumber(fromBalance.toFixed(2))}`
          )
        : schema.moreThan(Number.NEGATIVE_INFINITY, 'El monto a transferir debe ser mayor a 0')
    ),
  fromId: yup
    .number()
    .required('Se requiere una cuenta de origen')
    .notOneOf([yup.ref('toId')], 'No es posible transferir a la misma cuenta'),
  toId: yup
    .number()
    .required('Se requiere una cuenta de destino')
    .notOneOf([yup.ref('fromId')], 'No es posible transferir a la misma cuenta')
});

const CREATE_TRANSFER = gql`
  mutation CreateTransfer($transactions: [transactions_insert_input!]!) {
    insert_transactions(objects: $transactions) {
      returning {
        transaction_id
      }
    }
  }
`;

const initialFormikValues = {
  total: 0,
  toAmount: '',
  fromId: 1,
  toId: 1,
  fromName: '',
  toName: '',
  fromBalance: 0,
  toBalance: 0
};

type FormikState = typeof initialFormikValues;

const Transfers = ({ open, statementId, onClose }: TransfersProps): JSX.Element => {
  const [initialFormikState, setInitialFormikState] = useState<FormikState>(initialFormikValues);
  const [createTransfer] = useMutation<unknown, CreateTransferMutationVariables>(CREATE_TRANSFER);
  const { showSnackbar } = useSnackbar();
  const { user } = useHasuraUser();

  useEffect(() => {
    setInitialFormikState((prevState) => ({ ...prevState, fromId: statementId }));
  }, [statementId]);

  const handleFormSubmission = async (values: FormikState) => {
    try {
      await createTransfer({
        variables: {
          transactions: [
            {
              amount: -Number(values.total),
              statement_account_id: values.fromId,
              created_by: user?.id,
              transaction_type: Transaction_Type_Enum.Transfer,
              es_description: 'Traspaso',
              en_description: 'Transfer',
              currency: Currency_Type_Enum.Mxn
            },
            {
              amount: Math.abs(Number(values.total)),
              statement_account_id: values.toId,
              created_by: user?.id,
              transaction_type: Transaction_Type_Enum.Transfer,
              es_description: 'Traspaso',
              en_description: 'Transfer',
              currency: Currency_Type_Enum.Mxn
            }
          ]
        }
      });
      showSnackbar('Traspaso registrado', 'success');
      onClose();
    } catch (err) {
      showSnackbar('Error al registrar traspaso', 'error');
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormikState}
      onSubmit={handleFormSubmission}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={() => handleSubmit()}
          onCancel={onClose}
          open={open}
          title="Traspaso"
        >
          <Grid alignItems="center" container item spacing={2} xs={12}>
            <Grid alignItems="center" item xs={6}>
              <CommensalSelector
                helperText={`Saldo: $ ${formattedNumber(values.fromBalance.toFixed(2))} ${
                  Number(values.total) > 0
                    ? `($ ${formattedNumber((Number(values.fromBalance) - Number(values.total)).toFixed(2))})`
                    : ''
                }`}
                label="Cuenta origen"
                onChange={(statementId, accountInfo) => {
                  setFieldValue('fromId', statementId);
                  setFieldValue('fromName', accountInfo.commensalName);
                  setFieldValue('fromBalance', accountInfo.balance);
                }}
                value={values.fromId}
              />
            </Grid>
            <Grid alignItems="center" item xs={6}>
              <CommensalSelector
                helperText={`Saldo: $ ${formattedNumber(values.toBalance.toFixed(2))} ${
                  Number(values.total) > 0
                    ? `($ ${formattedNumber((Number(values.toBalance) + Number(values.total)).toFixed(2))})`
                    : ''
                }`}
                label="Cuenta destino"
                onChange={(statementId, accountInfo) => {
                  setFieldValue('toId', statementId);
                  setFieldValue('toName', accountInfo.commensalName);
                  setFieldValue('toBalance', accountInfo.balance);
                }}
                value={values.toId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  inputComponent: CustomNumberFormat as any
                }}
                error={Boolean(errors.total)}
                fullWidth
                helperText={errors.total ? errors.total : ''}
                label="Monto"
                onChange={(e) => setFieldValue('total', e.target.value)}
              />
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default Transfers;
