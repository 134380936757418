import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import { gql, useQuery } from '@apollo/client';

import { GetAllApartmentsQuery } from 'types/graphql';

type Apartment = {
  id: number;
  label: string;
  userId?: number | null;
  statementId?: number | null;
  owner?: string | null;
  apartment_name?: string | null;
};

type ApartmentsEventHandler = (apartmentId: number, userId?: number | null, statementId?: number | null, owner?: string | null, apartment_name?: string | null) => void;

interface ApartmentsProps {
  hideOwnerNames?: boolean;
  hideOwnedapartments?: boolean;
  label: string;
  value?: number;
  onChange?: ApartmentsEventHandler;
  onDataLoaded?: ApartmentsEventHandler;
  disabled?: boolean;
}

const GET_APARTMENTS = gql`
  query GetAllApartments {
    apartment: apartments_helper(where: { apartment_id: { _neq: 0 } }, order_by: { name: asc, number: asc }) {
      apartment_id
      statement_account_id
      apartment_name
      balance
      balance_retained
      owner
      owner_id
    }
  }
`;

let firstLoad = false;

const Apartments = ({
  hideOwnedapartments,
  hideOwnerNames,
  label,
  value,
  onChange,
  onDataLoaded,
  disabled
}: ApartmentsProps): JSX.Element => {
  const [apartments, setApartments] = useState<Array<Apartment>>([]);
  const [selectedApartment, setSelectedApartment] = useState<Apartment | null>(null);
  const { data, loading } = useQuery<GetAllApartmentsQuery>(GET_APARTMENTS, { pollInterval: 3000 });

  const handleValueChange = () => {
    const selected = apartments.find((a) => a.id === value);

    setSelectedApartment(selected || null);
  };

  useEffect(() => {
    if (apartments && value) {
      handleValueChange();
    }
  }, [apartments, value]);

  useEffect(() => {
    if (data?.apartment) {
      const mappedApartments: Apartment[] = data.apartment.map((a) => ({
        id: a.apartment_id || 0,
        userId: a.owner_id || 0,
        statementId: a.statement_account_id || 0,
        owner: a.owner,
        apartment_name: a.apartment_name,
        label: `${a.apartment_name}${hideOwnerNames ? '' : a.owner ? ` | ${a.owner}` : ''}`
      })).filter(a => !(hideOwnedapartments && Boolean(a.userId)));

      setApartments(mappedApartments);

      if (value) {
        const selected = mappedApartments.find((a) => a.id === value);
        setSelectedApartment(selected || null);
      }

      if (!firstLoad && onDataLoaded && mappedApartments.length > 0) {
        const { apartment_id, owner_id, statement_account_id } = data.apartment[0];
        onDataLoaded(apartment_id || 0, owner_id, statement_account_id || null);
        firstLoad = true;
      }
    }
  }, [data, value, hideOwnerNames, hideOwnedapartments, onDataLoaded]);

  const handleApartmentChange = (_: React.SyntheticEvent<unknown>, value: Apartment | null) => {
    if (value) {
      setSelectedApartment(value);
      if (onChange) {
        onChange(value.id, value.userId, value.statementId, value.owner, value.apartment_name);
      }
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      onChange={handleApartmentChange}
      options={apartments}
      renderInput={(props) => <TextField label={label} variant="outlined" {...props} />}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.label}
        </Box>
      )}
      value={selectedApartment}
    />
  );
};

export default Apartments;
