import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import * as yup from 'yup';

import { useSnackbar, useHasuraUser } from 'hooks';
import { CommensalSelector } from 'components';
import { CreateVisitMutation, CreateVisitMutationVariables } from 'types/graphql';
import { time } from 'console';

type ArrivalMethod = 'car' | 'taxi' | 'bus' | '';
type CommensalType = 'owner' | 'rental';
type FormikState = {
  isOwner: boolean;
  car_color: string;
  name: string;
  expected_arrival: string | Date;
  visitors_numbers: string | number;
  apartment_id: number;
  commensal_id: number;
  check_in_by: number;
  arrives_by: ArrivalMethod;
  arrives_at: string | Date;
  plates: string;
  car_description: string;
  comments: string;
  apartment_name: string;
};

const CREATE_VISIT = gql`
  mutation CreateVisit($data: visits_insert_input!) {
    insert_visits_one(object: $data) {
      id
    }
  }
`;

const validationSchema = yup.object().shape({
  isOwner: yup.boolean(),
  name: yup.string().required('Complete este campo'),
  visitors_numbers: yup
    .number()
    .min(1, 'El número de visitantes no puede ser menor a 0')
    .when('commensal_type', (cType: CommensalType) => {
      if (cType === 'rental') {
        return yup.number().max(2, 'El número máximo de visitantes para huéspedes es de 2');
      } else {
        return yup.number();
      }
    }),
  apartment_id: yup.number().min(1, 'Seleccione un condominio'),
  arrives_by: yup.string().required('Complete este campo')
});

const initialState: FormikState = {
  isOwner: false,
  name: '',
  visitors_numbers: 1,
  expected_arrival: new Date(),
  apartment_id: 1,
  commensal_id: 1,
  check_in_by: 1,
  arrives_by: '',
  plates: '',
  car_description: '',
  arrives_at: '',
  comments: '',
  car_color: '',
  apartment_name: ''
};

const AddVisit = (): JSX.Element => {
  const [initialFormikState, setInitialFormikState] = useState<FormikState>(initialState);
  const location = useLocation<{ visit?: FormikState }>();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { user } = useHasuraUser();
  const [createVisit, { loading }] = useMutation<CreateVisitMutation, CreateVisitMutationVariables>(CREATE_VISIT);

  useEffect(() => {
    if (location.state?.visit) {
      setInitialFormikState(location.state.visit);
    }
  }, []);

  const handleSumbision = async (values: FormikState): Promise<void> => {
    try {
      if (user) {

        const localArrival = new Date(values.expected_arrival);
        console.log(localArrival);
      const timezoneOffset = localArrival.getTimezoneOffset() * 60000; // Offset in milliseconds
      console.log(timezoneOffset);
      
      const adjustedDate = new Date(localArrival.getTime() - timezoneOffset);      
      console.log(adjustedDate);
      
        

        await createVisit({
          variables: {
            data: {
              car_color: values.car_color,
              car_description: values.car_description,
              name: values.name,
              visitors_numbers: Number(values.visitors_numbers),
              arrives_by: values.arrives_by,
              expected_arrival: localArrival,
              arrives_at: localArrival,
              plates: values.plates,
              apartment_id: values.apartment_id,
              // commensal_id: values.commensal_id,
              checking_in_by: user.id,
              apartment_name: values.apartment_name
            }
          }
        });
        showSnackbar('Visita registrada', 'success');
        history.goBack();
      }
    } catch (err) {
      showSnackbar('Error al registrar visita', 'error');
    }
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item>
          <Typography variant="h6">Agregar|Editar visita</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Formik
          enableReinitialize
          initialValues={initialFormikState}
          onSubmit={handleSumbision}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <Grid item xs={12}>
                <CommensalSelector
                  label="Anfitrión"
                  onChange={(commensalId, commensalInfo) => {
                    setFieldValue('commensal_id', commensalId);
                    setFieldValue('isOwner', commensalInfo.isOwner);
                    setFieldValue('apartment_id', commensalInfo.apartmentId);
                    setFieldValue('commensal_type', commensalInfo.isOwner ? 'owner' : 'rental')
                    setFieldValue('apartment_name', commensalInfo.apartment);
                  }}
                  value={values.commensal_id}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Nombre visitante"
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  value={values.name}
                />
              </Grid>
              <Grid item xs={3}>
                <DateTimePicker
                  hideTabs
                  inputFormat="dd/MM/yyyy hh:mm a"
                  label="Fecha y hora"
                  mask="__/__/____ __:__ m"
                  minDate={new Date()}
                  onChange={(date) => setFieldValue('expected_arrival', date)}
                  renderInput={(props) => <TextField fullWidth label="Fecha y hora" {...props} />}
                  value={values.expected_arrival}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Número de visitantes"
                  onChange={(e) => setFieldValue('visitors_numbers', e.target.value)}
                  value={values.visitors_numbers}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="arrivalMethod">Llegará en</InputLabel>
                  <Select
                    label="Llegará en"
                    labelId="arrivalMethod"
                    onChange={(e) => setFieldValue('arrives_by', e.target.value)}
                    value={values.arrives_by}
                  >
                    <MenuItem value="car">Carro</MenuItem>
                    <MenuItem value="bus">Autobús</MenuItem>
                    <MenuItem value="taxi">Uber / Taxi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {values.arrives_by === 'car' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Placas"
                      onChange={(e) => setFieldValue('plates', e.target.value)}
                      value={values.plates}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Color"
                      onChange={(e) => setFieldValue('car_color', e.target.value)}
                      value={values.car_color}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descripción del auto"
                      multiline
                      onChange={(e) => setFieldValue('car_description', e.target.value)}
                      rows={2}
                      value={values.car_description}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Comentarios"
                  multiline
                  onChange={(e) => setFieldValue('comments', e.target.value)}
                  rows={2}
                  value={values.comments}
                  variant="outlined"
                />
              </Grid>
              <Grid alignItems="center" container item justifyContent="flex-end" spacing={2} xs={12}>
                <Grid item>
                  <LoadingButton color="primary" loading={loading} onClick={() => handleSubmit()} variant="contained">
                    Aceptar
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddVisit;
