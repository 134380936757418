import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  IconButton,
  Grid,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  CircularProgress
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Edit as EditIcon } from '@mui/icons-material';

import { GetIngredientsQuery } from 'types/graphql';

const GET_INGREDIENTS = gql`
  query GetIngredients {
    ingredients(order_by: { name: asc }) {
      id
      name
      unit
      quantity
      inventory
      available
      unitObject {
        name
        abreviation
      }
    }
  }
`;

const Ingredients = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const { data, loading } = useQuery<GetIngredientsQuery>(GET_INGREDIENTS);

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item>
          <TextField
            label="Buscar..."
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/menu/ingredients/add')} variant="contained">
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="right">Disponible</TableCell>
                <TableCell align="right">Inventariable</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              ) : (
                data?.ingredients.map((ingredient) => (
                  <TableRow key={ingredient.id}>
                    <TableCell>{ingredient.name}</TableCell>
                    <TableCell align="right">
                      {ingredient.quantity} {ingredient.unitObject.abreviation}
                    </TableCell>
                    <TableCell align="right">{ingredient.inventory ? 'Sí' : 'No'}</TableCell>
                    <TableCell align="center">
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Ingredients;
