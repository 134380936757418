import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuery, useMutation, gql } from '@apollo/client';

import {
  GetPrinterTemplatesQuery,
  UpdatePrinterTemplateMutation,
  UpdatePrinterTemplateMutationVariables
} from 'types/graphql';
import { useSnackbar } from 'hooks';

type PrinterTemplate = {
  id: number;
  printerId: number | null;
  active: boolean;
  name: string;
};

interface PrinterTemplatesProps {
  id: number;
}

const GET_PRINTER_TEMPLATES = gql`
  query GetPrinterTemplates {
    templates: print_templates(order_by: { id: asc }) {
      id
      alias
      name
      printer_id
    }
  }
`;

const UPDATE_PRINTER_TEMPLATE = gql`
  mutation UpdatePrinterTemplate($id: Int!, $data: print_templates_set_input!) {
    update_print_templates_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

const PrinterTemplates = ({ id }: PrinterTemplatesProps): JSX.Element => {
  const [templates, setTemplates] = useState<PrinterTemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const { data } = useQuery<GetPrinterTemplatesQuery>(GET_PRINTER_TEMPLATES, { pollInterval: 2000 });
  const { showSnackbar } = useSnackbar();
  const [updatePrinter] = useMutation<UpdatePrinterTemplateMutation, UpdatePrinterTemplateMutationVariables>(
    UPDATE_PRINTER_TEMPLATE
  );

  useEffect(() => {
    if (data?.templates) {
      const mappedTemplates: Array<PrinterTemplate> = data.templates.map((t) => ({
        id: t.id,
        printerId: t.printer_id || null,
        name: t.alias,
        active: t.printer_id === id
      }));

      setTemplates(mappedTemplates);
    }
  }, [data]);

  const getPrinterId = (t: PrinterTemplate): number | null => {
    /* 
      Para el programador del futuro, la lógica es la siguiente:
      Una plantilla de impresión puede NO tener ninguna impresora asignada. Siendo ese el caso, la solicitud
      de impresión simplemente será ignorada por la API, es por eso que el campo printerId puede contener null.
      Sólo puede haber una plantilla asignada a una impresora a la vez, en caso de que se seleccione una plantilla
      que ya está asignada a otra impresora, el campo printerId será asignado a la nueva impresora seleccionada. 
      Ejemplo: 
        - Impresora "Cocina" tiene asignada la plantilla "Ticket de comanda"
        - Se asigna la plantilla "Ticket de comanda" a la impresora "Recepción"
        - La plantilla "Ticket de comanda" se procesará en la impresora "Recepción" y la impresora "Cocina" dejará
          de imprimir esa plantilla.

      Al presionar el botón guardar se procesa un update por cada plantilla que existe. Es por eso que se hace la
      validación de la línea 90, si no se reasigna una plantilla, se deja asignada a su impresora original.
    */
    if (t.printerId === null) {
      if (t.active) {
        return id;
      } else {
        return null;
      }
    } else {
      if (t.printerId === id && !t.active) {
        return null;
      } else if (t.printerId !== id && !t.active) {
        return t.printerId;
      } else {
        return id;
      }
    }
  };

  const handleTemplatesUpdate = async () => {
    try {
      const updatePromises: Promise<any>[] = [];
      templates.forEach((t) => {
        const printer_id = getPrinterId(t);

        updatePromises.push(
          updatePrinter({
            variables: {
              id: t.id,
              data: {
                printer_id
              }
            }
          })
        );
      });
      setLoading(true);
      await Promise.all(updatePromises);
      showSnackbar('Plantillas de impresión actualizadas', 'success');
    } catch (err) {
      showSnackbar('Error al actualizar plantillas de impresión', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box border={1} borderColor={(theme) => theme.palette.divider} borderRadius={2}>
      <Grid container flexDirection="column" item sx={{ p: 2 }} xs={12}>
        <Grid item sx={{ pb: 1 }}>
          <Typography variant="body1">Impresiones</Typography>
        </Grid>
        {templates.map((t, index) => (
          <Grid item key={index}>
            <FormControlLabel
              checked={t.active}
              control={
                <Checkbox
                  onChange={(e) => {
                    const copy = [...templates];
                    copy[index].active = e.target.checked;
                    setTemplates([...copy]);
                  }}
                />
              }
              label={t.name}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item justifyContent="flex-end" spacing={2} sx={{ mt: 0.3 }} xs={12}>
          <Grid item>
            <LoadingButton color="primary" loading={loading} onClick={handleTemplatesUpdate} variant="contained">
              Guardar
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrinterTemplates;
