import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Autocomplete } from '@mui/material';
import { gql, useQuery } from '@apollo/client';

import { GetApartmentsQuery } from 'types/graphql';

type ApartmentInfo = {
  apartmentName: string;
  id: number;
  user_id: number;
};

interface ApartmentSelectProps {
  label: string;
  onChange?: (...args: any[]) => void;
  value?: ApartmentInfo;
  error?: boolean | undefined;
  helperText?: string | undefined;
  hideOwnerName?: boolean;
}

const GET_APARTMENTS = gql`
  query GetApartments {
    apartment(
      where: { id: { _neq: 0 }, user_id: { _is_null: false } }
      order_by: { building: { name: asc }, number: asc }
    ) {
      id
      number
      user_id
      user {
        first_name
        last_name
      }
      building {
        name
      }
    }
  }
`;

const ApartmentSelect = ({
  label,
  onChange,
  value,
  error,
  helperText,
  hideOwnerName
}: ApartmentSelectProps): JSX.Element => {
  const { data, loading } = useQuery<GetApartmentsQuery, null>(GET_APARTMENTS);
  const [apartments, setApartments] = useState<ApartmentInfo[]>([]);

  useEffect(() => {
    if (data) {
      const mappedApartments = data.apartment.map(
        (a) =>
          !!a.building && {
            apartmentName: `${a.number}-${a.building.name} ${
              !hideOwnerName ? `${a.user?.first_name || ''} ${a.user?.last_name || ''}` : ''
            }`,
            id: a.id,
            user_id: a.user_id
          }
      ) as ApartmentInfo[];
      setApartments(mappedApartments);
    }
  }, [data]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    !!apartments && (
      <>
        <Autocomplete
          data-testid="autocomplete"
          getOptionLabel={(option) => option.apartmentName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText="No condomminiums found"
          onChange={onChange}
          options={apartments}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error || false}
              fullWidth
              helperText={helperText || ''}
              label={label}
              variant="outlined"
            />
          )}
          value={value}
        />
      </>
    )
  );
};

export default ApartmentSelect;
