import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, Box, Chip, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material';

import { PrinterDetailsForm, PrinterTemplates } from './components';
import { useSnackbar, useAxios } from 'hooks';
import { GetPrinterInfoQuery, GetPrinterInfoQueryVariables } from 'types/graphql';

const GET_PRINTER_INFO = gql`
  query GetPrinterInfo($id: Int!) {
    info: printers_by_pk(id: $id) {
      name
      id
      address
    }
  }
`;

const PrinterDetails = (): JSX.Element => {
  const location = useLocation<{ id: number }>();
  const [status, setStatus] = useState<boolean | null>(null);
  const { showSnackbar } = useSnackbar();
  const { axios } = useAxios();
  const { data, loading } = useQuery<GetPrinterInfoQuery, GetPrinterInfoQueryVariables>(GET_PRINTER_INFO, {
    variables: { id: location.state.id },
    pollInterval: 1000
  });

  const setPrinterStatus = async (printerId: number): Promise<void> => {
    setStatus(null);
    try {
      const res = await axios.get<{ success: boolean }>('printer_status', { data: { id: printerId } });
      setStatus(res.data.success);
    } catch (err) {
      setStatus(false);
      showSnackbar('Error obteniendo estado de impresora', 'error');
    }
  };

  useEffect(() => {
    if (data?.info) {
      setPrinterStatus(data.info.id);
    }
  }, [loading, data]);

  return (
    <Grid container spacing={2}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid alignItems="center" container item xs={12}>
            <Typography variant="h5">{data?.info?.name}</Typography>
            <Box sx={{ px: 2 }}>
              <Chip
                color={status ? 'success' : status === null ? 'primary' : 'error'}
                icon={
                  status ? (
                    <DoneIcon />
                  ) : status === null ? (
                    <CircularProgress size={16} sx={{ ml: 1 }} />
                  ) : (
                    <CloseIcon />
                  )
                }
                label={status ? 'En línea' : status === null ? 'Conectando...' : 'Desconectado'}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Box border={1} borderColor={(theme) => theme.palette.divider} borderRadius={2}>
                {data?.info && (
                  <PrinterDetailsForm onPrinterUpdate={(ip) => setPrinterStatus(ip)} printer={data.info} />
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid alignItems="flex-start" container item spacing={2} xs={12}>
            <Grid container flexDirection="column" item md={6} sm={12} xs={12}>
              {/* --Templates-- */}
              <PrinterTemplates id={data?.info?.id || 0} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PrinterDetails;
