import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { VerifyUserPinQuery, VerifyUserPinQueryVariables } from 'types/graphql';
import { Queries } from 'common';

interface PinAuthorization {
  authorize: (pin: string, id: number) => void;
  authorized: boolean;
  loadingAuth: boolean;
}

const usePinAuthorization = (): PinAuthorization => {
  const [authorized, setAuthorized] = useState(false);
  const [authFilters, setAuthFilters] = useState<{ pin: string; id: number }>({ pin: '', id: 0 });
  const { data, loading } = useQuery<VerifyUserPinQuery, VerifyUserPinQueryVariables>(Queries.VERIFY_USER_PIN, {
    variables: authFilters
  });

  useEffect(() => {
    if (data) {
      const auth = Boolean(data.statement_account[0]?.id) && data.statement_account[0]?.id !== null;
      setAuthorized(auth);
    } else {
      setAuthorized(false);
    }
  }, [data]);

  const authorize = (pin: string, id: number): void => {
    setAuthFilters({ pin, id });
  };

  return { authorized, authorize, loadingAuth: loading };
};

export default usePinAuthorization;
