import React, { useState, useEffect } from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Formik } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';

import { CustomDialog, NumberFormatInput, CommensalSelector } from 'components';
import { usePinAuthorization, useAxios, useSnackbar, useHasuraUser } from 'hooks';
import {
  GetCurrenciesQuery,
  CreateTransactionMutation,
  CreateTransactionMutationVariables,
  Transaction_Type_Enum
} from 'types/graphql';
import { formattedNumber } from 'helpers';

interface TransactionDialogProps {
  open: boolean;
  transactionType: 'transfer' | 'deposit' | 'refund' | 'charge';
  statementId: number;
  commensalBalance: number;
  apartmentId: number;
  onClose: (...args: any[]) => void;
}

const GET_CURRENCIES = gql`
  query GetCurrencies {
    exchange(where: { disabled: { _eq: false } }, order_by: { id: asc }) {
      id
      currency_key
      currency_name
      exchange
    }
  }
`;

const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($data: transactions_insert_input!) {
    insert_transactions_one(object: $data) {
      transaction_id
      amount
      exchange
      currency
    }
  }
`;

const initialFormikState = {
  transactionType: 'deposit',
  statementId: 1,
  commensalBalance: 0,
  amount: '',
  apartmentId: 1,
  nip: '',
  currency: 1
};

type FormikState = typeof initialFormikState;

const TransactionDialog = ({ open, onClose, ...props }: TransactionDialogProps): JSX.Element => {
  const [formikState, setFormikState] = useState<FormikState>(initialFormikState);
  const { data: currenciesData, loading: loadingCurrencies } = useQuery<GetCurrenciesQuery>(GET_CURRENCIES);
  const [createTransaction, { data: transactionData, loading: transactionLoading }] = useMutation<
    CreateTransactionMutation,
    CreateTransactionMutationVariables
  >(CREATE_TRANSACTION);
  const { showSnackbar } = useSnackbar();
  const { user } = useHasuraUser();
  const { axios } = useAxios();
  const { authorize, authorized } = usePinAuthorization();

  useEffect(() => {
    const printTicket = async () => {
      try {
        if (transactionData?.insert_transactions_one?.transaction_id) {
          await axios.post('print_ticket', {
            template: 'transaction',
            transaction_id: transactionData.insert_transactions_one.transaction_id
          });
          showSnackbar('Movimiento creado', 'success');
        }
      } catch (err) {
        showSnackbar('Movimiento creado. Error imprimiendo ticket', 'warning');
      }
    };
    printTicket();
  }, [transactionLoading]);

  useEffect(() => {
    if (open) {
      setFormikState({
        transactionType: props.transactionType,
        commensalBalance: props.commensalBalance,
        apartmentId: props.apartmentId,
        statementId: props.statementId,
        amount: '',
        nip: '',
        currency: 1
      });
    }
  }, [open]);

  const handleSubmission = async (values: FormikState) => {
    try {
      if (authorized) {
        const currencieData = currenciesData?.exchange.find((c) => c.id === values.currency);
        if (currencieData) {
          await createTransaction({
            variables: {
              data: {
                statement_account_id: props.statementId,
                amount: Number(values.amount) * (values.transactionType === 'deposit' ? 1 : -1),
                currency: currencieData.currency_key,
                exchange: currencieData.exchange,
                transaction_type: (values.transactionType === 'deposit'
                  ? 'Deposit'
                  : values.transactionType === 'refund' 
                  ? 'Refund' 
                  : 'Charge') as Transaction_Type_Enum,
                es_description: values.transactionType === 'deposit' ? 'Depósito' : values.transactionType === 'refund' ? 'Reembolso' : 'Consumo',
                en_description: values.transactionType === 'deposit' ? 'Deposit' : values.transactionType === 'refund' ? 'Refund' : 'Charge',
                is_tip: false,
                created_by: user?.id
              }
            }
          });
          onClose();
          setFormikState(initialFormikState);
        } else {
          showSnackbar('Error al crear movimiento', 'error');
        }
      } else {
        showSnackbar('Nip incorrecto', 'error');
      }
    } catch (err) {
      showSnackbar('Error creando movimiento', 'error');
    }
  };

  return (
    <Formik enableReinitialize initialValues={formikState} onSubmit={handleSubmission}>
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={handleSubmit}
          onCancel={onClose}
          onClose={onClose}
          open={open}
          title="Agregar movimiento"
        >
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={12}>
              <CommensalSelector
                helperText={`Saldo: $${formattedNumber(values.commensalBalance.toFixed(2))}`}
                onChange={(commensalId) => setFieldValue('commensalId', commensalId)}
                value={values.statementId}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="transaction">Movimiento</FormLabel>
                <RadioGroup
                  onChange={(e) => {
                    if (e.target.value !== 'deposit') {
                      setFieldValue('currency', 1);
                    }
                    setFieldValue('transactionType', e.target.value);
                  }}
                  row
                  value={values.transactionType}
                >
                  <FormControlLabel control={<Radio />} label="Depósito" value="deposit" />
                  <FormControlLabel control={<Radio />} disabled={values.commensalBalance <= 0} label="Reembolso" value="refund"/>
                  <FormControlLabel control={<Radio />} label="Consumo" value="charge" />

                </RadioGroup>
              </FormControl>
            </Grid>
            {values.transactionType === 'deposit' && !loadingCurrencies && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="exchange">Moneda</InputLabel>
                  <Select
                    label="Moneda"
                    labelId="exchange"
                    onChange={(e) => setFieldValue('currency', e.target.value)}
                    value={values.currency}
                  >
                    {currenciesData?.exchange.map((c, index) => (
                      <MenuItem key={index} value={c.id}>
                        {`${c.currency_name} (${c.currency_key}) ${
                          c.currency_key !== 'MXN' ? `($ ${c.exchange.toFixed(2)})` : ''
                        }`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}>
              <NumberFormatInput
                fullWidth
                helperText=" "
                inputProps={{ inputMode: 'numeric' }}
                label="Total"
                onChange={(e) => setFieldValue('amount', e.target.value)}
                prefix="$"
                thousandSeparator
                value={values.amount}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(errors.nip)}
                fullWidth
                helperText={Boolean(errors.nip) && errors.nip}
                inputProps={{ inputMode: 'numeric', maxLength: 4 }}
                label="Nip"
                onChange={(e) => {
                  if (e.target.value.length === 4) {
                    authorize(e.target.value, props.statementId);
                  }
                  setFieldValue('nip', e.target.value);
                }}
                type="password"
                value={values.nip}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default TransactionDialog;
