import React, { useState, useEffect } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, TextField, InputAdornment, IconButton } from '@mui/material';
import { DragHandle as DragHandleIcon, Close as CloseIcon } from '@mui/icons-material';
import { useMutation, gql } from '@apollo/client';

import { useSnackbar } from 'hooks';
import {
  UpdateMenuNameMutationVariables,
  UpdateCategoryNameMutationVariables,
  UpdateSubcategoryNameMutationVariables
} from 'types/graphql';

type Category = {
  id: number;
  title: string;
  position: number;
  disabled: boolean;
  hideMenu?: boolean;
  editing?: boolean;
};

type CategoryType = 'menus' | 'categories' | 'subcategories';

interface CategoryContainerItemProps {
  category: Category;
  categoryType: CategoryType;
  selected: boolean;
  onCategoryClick?: (type: CategoryType, id: number) => void;
}

const UPDATE_MENU = gql`
  mutation UpdateMenuName($id: Int!, $name: String!) {
    update_menu_types_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      name
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategoryName($id: Int!, $name: String!) {
    update_categories_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      name
    }
  }
`;

const UPDATE_SUBCATEGORY = gql`
  mutation UpdateSubcategoryName($id: Int!, $name: String!) {
    update_subcategories_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      name
    }
  }
`;

const CategoryContainerItem = ({
  category,
  categoryType,
  selected,
  onCategoryClick
}: CategoryContainerItemProps): JSX.Element => {
  const [categoryName, setCategoryName] = useState<string>(category.title);
  const [editing, setEditing] = useState<boolean>(Boolean(category.editing));
  const [updateMenu] = useMutation<null, UpdateMenuNameMutationVariables>(UPDATE_MENU);
  const [updateCategory] = useMutation<null, UpdateCategoryNameMutationVariables>(UPDATE_CATEGORY);
  const [updateSubcategory] = useMutation<null, UpdateSubcategoryNameMutationVariables>(UPDATE_SUBCATEGORY);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setEditing(Boolean(category.editing));
  }, [category.editing]);

  const handleEditClick = (): void => {
    setEditing(false);
    setCategoryName(category.title);
  };

  const handleEditSubmission = async (): Promise<void> => {
    try {
      switch (categoryType) {
        case 'menus': {
          await updateMenu({ variables: { id: category.id, name: categoryName } });
          break;
        }
        case 'categories': {
          await updateCategory({ variables: { id: category.id, name: categoryName } });
          break;
        }
        case 'subcategories': {
          await updateSubcategory({ variables: { id: category.id, name: categoryName } });
          break;
        }
      }

      showSnackbar('Nombre actulizado', 'success');
      handleEditClick();
    } catch (err) {
      showSnackbar('Error al editar nombre', 'error');
    }
  };

  return (
    <ListItemButton
      disableRipple
      onClick={() => {
        onCategoryClick && onCategoryClick(categoryType, category.id);
      }}
      sx={{
        backgroundColor: (theme) => (selected ? theme.palette.grey[300] : 'transparent')
      }}
    >
      <ListItemIcon sx={{ '&:hover': { cursor: 'grab' }, '&:active': { cursor: 'grabbing' } }}>
        <DragHandleIcon />
      </ListItemIcon>
      {editing ? (
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleEditClick}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          inputRef={(input) => input && input.focus()}
          onChange={(e) => setCategoryName(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleEditSubmission();
            }
          }}
          value={categoryName}
          variant="standard"
        />
      ) : (
        <ListItemText
          primary={category.title}
          sx={{ color: (theme) => (category.disabled ? theme.palette.error.main : '') }}
        />
      )}
    </ListItemButton>
  );
};

export default CategoryContainerItem;
