import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'context';
import { grantAccess } from 'helpers';
import { MainLayout } from '../MainLayout';
import { PalapaReduxState } from 'types/redux';

type Role = 'user' | 'admin' | 'portico' | 'board' | 'palapa' | 'recepcion';

interface RouteWithLayoutProps extends RouteProps {
  component: React.ComponentType<any>;
  requiresAuth?: boolean;
  roleRequired: Role | Role[] | '*';
  layout?: React.ComponentType;
}

const RouteWithLayout = ({
  component: Component,
  layout: Layout,
  requiresAuth,
  roleRequired,
  ...props
}: RouteWithLayoutProps): JSX.Element => {
  const state = useSelector((reduxState: PalapaReduxState) => reduxState.user);
  const { showSnackbar } = useSnackbar();
  const hasAccess = grantAccess(state.role as Role, roleRequired);
  const isLoggedIn = state.auth;

  if (!isLoggedIn && requiresAuth) {
    showSnackbar('Inicie sesión para continuar', 'error');
    return <Redirect to="/" />;
  }

  if (!hasAccess) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...props}
      render={(matchProps) => {
        if (Layout) {
          return (
            <Layout {...props}>
              <Component {...props} {...matchProps} />
            </Layout>
          );
        } else {
          return (
            <MainLayout {...props}>
              <Component {...props} {...matchProps} />
            </MainLayout>
          );
        }
      }}
    />
  );
};

export default RouteWithLayout;
