import React, { useState, useEffect } from 'react';
import {
  Collapse,
  colors,
  Pagination,
  Grid,
  TextField,
  Button,
  Divider,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { GetOpenAccountsQuery, GetOpenAccountsQueryVariables } from 'types/graphql';
import { SearchAccounts } from './components';

type MappedAccount = GetOpenAccountsQuery['accounts'][0] & { open: boolean };
type MappedAccounts = Array<MappedAccount>;
type SortedAccounts = Array<{ key: string; open: boolean; data?: MappedAccounts }>;

const ACCOUNTS_PER_PAGE = 15;

const GET_ACCOUNTS = gql`
  query GetOpenAccounts($searchTerm: String!, $limit: Int!, $offset: Int!) {
    aggregate: palapa_accounts_aggregate(
      where: {
        open: { _eq: true }
        _or: [{ apartment_name: { _like: $searchTerm } }, { commensal_name: { _like: $searchTerm } }]
      }
    ) {
      aggregate {
        count
      }
    }
    accounts: palapa_accounts(
      limit: $limit
      offset: $offset
      order_by: { commensal_name: asc }
      where: {
        open: { _eq: true }
        _or: [{ apartment_name: { _like: $searchTerm } }, { commensal_name: { _like: $searchTerm } }]
      }
    ) {
      statement_id
      commensal_name
      apartment_name
      commensal_type
      balance
      orders(where: { status: { _eq: "open" } }) {
        id
        order_dishes {
          dish {
            name
            price
          }
        }
      }
    }
  }
`;

const Accounts = (): JSX.Element => {
  const history = useHistory();
  const [searchString, setSearchString] = useState('');
  const [accountsOpen, setAccountsOpen] = useState(false);
  const [accounts, setAccounts] = useState<SortedAccounts>();
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useQuery<GetOpenAccountsQuery, GetOpenAccountsQueryVariables>(GET_ACCOUNTS, {
    variables: {
      searchTerm: `%${searchString}%`,
      offset: (currentPage - 1) * ACCOUNTS_PER_PAGE,
      limit: ACCOUNTS_PER_PAGE
    },
    pollInterval: 1000
  });

  useEffect(() => {
    if (data) {
      const maxPages = Math.ceil((data.aggregate.aggregate?.count || 0) / ACCOUNTS_PER_PAGE);
      const arrayHelper: Array<{ key: string; open: boolean; data?: MappedAccounts }> = [];

      data.accounts.forEach((a) => {
        if (a?.commensal_name && !arrayHelper.some((l) => l.key === a?.commensal_name?.[0].toUpperCase())) {
          arrayHelper.push({ key: a?.commensal_name[0].toUpperCase(), open: true });
        }
      });

      const mappedAccounts: MappedAccounts = data.accounts.map((c) => ({
        ...c,
        open: false
      }));

      arrayHelper.forEach((a) => {
        const accounts = mappedAccounts.filter((ac) => ac?.commensal_name?.[0].toUpperCase() === a.key);
        a.data = accounts;
      });

      setPages(maxPages);
      setAccounts(arrayHelper);
    }
  }, [data]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <SearchAccounts
          onAccept={() => setAccountsOpen(false)}
          onCancel={() => setAccountsOpen(false)}
          open={accountsOpen}
        />
        <Grid item>
          <TextField
            label="Buscar"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => setAccountsOpen(true)} variant="contained">
            Abrir cuenta
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <List
          subheader={<li />}
          sx={{
            width: '100%',
            borderRadius: 4,
            position: 'relative',
            bgcolor: 'background.paper',
            borderWidth: 1,
            overflow: 'auto',
            '& ul': { padding: 0 }
          }}
        >
          {accounts?.map((a, index) => (
            <li key={a.key}>
              <ul>
                <ListSubheader
                  onClick={() => {
                    const arrayCopy = [...accounts];
                    arrayCopy[index].open = !arrayCopy[index].open;
                    setAccounts(arrayCopy);
                  }}
                  sx={{ '&:hover': { cursor: 'pointer' }, backgroundColor: colors.grey[200], color: 'black' }}
                >
                  {a.key}
                </ListSubheader>
                <Collapse in={a.open}>
                  {a.data?.map((ac) => (
                    <ListItemButton
                      key={ac.statement_id}
                      onClick={() =>
                        history.push('/accounts/details', {
                          id: ac.statement_id || 0,
                          title: `${ac.commensal_name} ${ac.apartment_name ? `(${ac.apartment_name})` : ''}`
                        })
                      }
                    >
                      <ListItem
                        secondaryAction={
                          <IconButton>
                            <ArrowRightIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={`${ac.commensal_name}${ac.apartment_name ? ` - ${ac.apartment_name}` : ''}`}
                        />
                      </ListItem>
                    </ListItemButton>
                  ))}
                </Collapse>
              </ul>
            </li>
          ))}
        </List>
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} sx={{ mt: 2, p: 0 }} xs={12}>
        <Grid item>
          <Pagination color="primary" count={pages} onChange={handlePageChange} page={currentPage} shape="rounded" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Accounts;
