import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  Grow,
  Grid,
  TextField,
  Button,
  Divider,
  Pagination,
  IconButton,
  ClickAwayListener,
  Popper,
  MenuItem,
  MenuList
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { TableView } from './components';
import { GetMenuQuery, GetMenuQueryVariables } from 'types/graphql';

const DISHES_PER_PAGE = 15;

const GET_MENU = gql`
  query GetMenu($offset: Int!, $limit: Int!, $searchString: String) {
    aggregate: dishes_aggregate(where: { name: { _like: $searchString } }) {
      aggregate {
        count
      }
    }
    dishes(offset: $offset, limit: $limit, where: { name: { _ilike: $searchString } }, order_by: { name: asc }) {
      id
      cost
      name
      price
      description
      modifier_group_dishes {
        id
        modifier_group {
          id
          title
          multiple
          modifiers {
            title
            id
          }
        }
      }
      subcategory {
        id
        name
        category {
          id
          name
          menu_type {
            id
            name
          }
        }
      }
      ingredient_dishes {
        optional
        quantity
        ingredient {
          name
          id
          unitObject {
            name
            abreviation
          }
        }
      }
    }
  }
`;

const Menu = (): JSX.Element => {
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const anchorRef = useRef<null | HTMLAnchorElement>(null);
  const { data } = useQuery<GetMenuQuery, GetMenuQueryVariables>(GET_MENU, {
    variables: {
      offset: (currentPage - 1) * DISHES_PER_PAGE,
      limit: DISHES_PER_PAGE,
      searchString: `%${searchString}%`
    },
    pollInterval: 1000
  });
  const history = useHistory();

  useEffect(() => {
    const maxPages = Math.ceil((data?.aggregate.aggregate?.count || 0) / DISHES_PER_PAGE);
    setPages(maxPages);
  }, [data]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleClickAway = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item>
          <TextField
            label="Búsqueda..."
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => history.push('/menu/add', { edit: false })} variant="contained">
            Agregar
          </Button>
          <IconButton href="" onClick={() => setMenuOpen(true)} ref={anchorRef} sx={{ ml: 2 }}>
            <MoreVertIcon />
          </IconButton>
          <Popper anchorEl={anchorRef.current} open={menuOpen} placement="bottom-start" role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <MenuList autoFocusItem={menuOpen}>
                      <MenuItem onClick={() => history.push('/menu/modifiers')}>Modificadores</MenuItem>
                      <MenuItem onClick={() => history.push('/menu/ingredients')}>Ingredientes</MenuItem>
                      <MenuItem onClick={() => history.push('/menu/categories')}>Menús y categorías</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        {data && <TableView data={data} />}
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} sx={{ mt: 2, p: 0 }} xs={12}>
        <Grid item>
          <Pagination color="primary" count={pages} onChange={handlePageChange} page={currentPage} shape="rounded" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Menu;
