import { gql } from '@apollo/client';

export const VERIFY_USER_PIN = gql`
  query VerifyUserPin($pin: String!, $id: Int!) {
    statement_account(where: { _and: [{ id: { _eq: $id } }, { pin: { _eq: $pin } }] }) {
      id
    }
  }
`;

export const GET_DISHES_CATEGORIES = gql`
  query GetDishesCategories {
    menus: menu_types(order_by: { position: asc }, where: { disabled: { _eq: false } }) {
      id
      name
      position
      categories(order_by: { name: asc }, where: { disabled: { _eq: false } }) {
        id
        name
        subcategories(where: { disabled: { _eq: false } }) {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_ACCOUNTS = gql`
  query GetAllAccounts {
    palapa_accounts(order_by: [{ apartment_building: asc_nulls_last }, { apartment_number: asc }]) {
      statement_id
      apartment_name
      commensal_name
      commensal_type
      balance
      open
    }
  }
`;

export const GET_CLOSED_ACCOUNTS = gql`
  query GetClosedAccounts {
    palapa_accounts(
      where: { open: { _eq: false } }
      order_by: [{ apartment_building: asc_nulls_last }, { apartment_number: asc }]
    ) {
      statement_id
      apartment_name
      commensal_name
      commensal_type
      balance
      open
    }
  }
`;
