import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { gql, useQuery } from '@apollo/client';

import { GetUsersSelectQuery } from 'types/graphql';

type MappedUser = {
  id: number;
  name: string;
};

interface UsersSelectProps {
  label: string;
  onChange?: (userId: number) => void;
  value?: number;
}

const GET_USERS_SELECT = gql`
  query GetUsersSelect {
    users(where: { _and: [{ roles: { _eq: "user" } }, { disabled: { _eq: false } }] }, order_by: { first_name: asc }) {
      id
      first_name
      last_name
    }
  }
`;

const UsersSelect = ({ label, value, onChange }: UsersSelectProps): JSX.Element => {
  const [mappedUsers, setMappedUsers] = useState<Array<MappedUser>>([]);
  const [selectedUser, setSelectedUser] = useState<MappedUser | null>(null);
  const { data, loading } = useQuery<GetUsersSelectQuery>(GET_USERS_SELECT);

  useEffect(() => {
    if (data?.users && value) {
      const user = data.users.find((u) => u.id === value);
      if (user) {
        setSelectedUser({ name: `${user.first_name} ${user.last_name}`, id: user.id });
      }
    }
  }, [data, value]);

  useEffect(() => {
    const users: MappedUser[] = [];

    if (data?.users) {
      data.users.forEach((u) => {
        if (u.first_name !== null && u.last_name !== null) {
          users.push({ name: `${u.first_name} ${u.last_name}`, id: u.id });
        }
      });
    }

    setMappedUsers(users);
  }, [data]);

  const handleUserChange = (_: React.SyntheticEvent<unknown>, value: MappedUser | null) => {
    if (value) {
      setSelectedUser(value);
      if (onChange && value.id) {
        onChange(value.id);
      }
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      onChange={handleUserChange}
      options={mappedUsers}
      renderInput={(props) => <TextField {...props} fullWidth label={label} />}
      value={selectedUser}
    />
  );
};

export default UsersSelect;
