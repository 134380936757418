import React, { useState, useEffect } from 'react';
import {
  Grid,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemButton,
  IconButton
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

import { CustomDialog } from 'components';
import { GetModifierGroupsQuery } from 'types/graphql';

type Modifier = {
  title: string;
};

type ModifierGroup = {
  title: string;
  selected: boolean;
  id: number;
  open: boolean;
  multiple: boolean;
  modifiers: Array<Modifier>;
};

type ModifiersState = Array<ModifierGroup>;

interface ModifiersPickerProps {
  open: boolean;
  modifiers?: Array<ModifierGroup>;
  onAccept: (modifier: Array<ModifierGroup>) => void;
  onCancel: (...args: any[]) => void;
}

const GET_MODIFIER_GROUPS = gql`
  query GetModifierGroups {
    modifier_groups {
      id
      title
      multiple
      modifiers {
        title
      }
    }
  }
`;

const ModifiersPicker = ({ open, modifiers, onAccept, onCancel }: ModifiersPickerProps): JSX.Element => {
  const [modifierGroups, setModifierGroups] = useState<ModifiersState>([]);
  const { data } = useQuery<GetModifierGroupsQuery>(GET_MODIFIER_GROUPS);

  useEffect(() => {
    if (data?.modifier_groups) {
      const mappedModifiers: ModifiersState = data.modifier_groups.map((mg) => ({
        id: mg.id,
        selected: false,
        title: mg.title,
        open: false,
        multiple: mg.multiple,
        modifiers: mg.modifiers.map((m) => ({ title: m.title }))
      }));

      if (modifiers) {
        mappedModifiers.forEach((mm) => {
          if (modifiers.some((m) => m.id === mm.id)) {
            mm.selected = true;
          }
        });
      }

      setModifierGroups(mappedModifiers);
    }
  }, [data, modifiers]);

  const handleModifierSubmission = () => {
    const selectedModifiers = modifierGroups.filter((mg) => mg.selected);

    if (selectedModifiers) {
      onAccept(selectedModifiers);
    } else {
      onCancel();
    }
  };

  return (
    <CustomDialog
      fullWidth
      maxWidth="md"
      onAccept={handleModifierSubmission}
      onCancel={onCancel}
      open={open}
      title="Asignar modificadores"
    >
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12}>
          <List>
            {modifierGroups.map((mg, index) => (
              <>
                <ListItem key={index}>
                  <ListItemButton>
                    <ListItemIcon>
                      <Checkbox
                        checked={mg.selected}
                        onClick={() => {
                          const arrayCopy = [...modifierGroups];
                          arrayCopy[index].selected = !arrayCopy[index].selected;
                          setModifierGroups(arrayCopy);
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${mg.title} - ${mg.multiple ? 'Selección múltiple' : 'Selección individual'}`}
                    />
                    <IconButton
                      onClick={() => {
                        const arrayCopy = [...modifierGroups];
                        arrayCopy[index].open = !arrayCopy[index].open;
                        setModifierGroups(arrayCopy);
                      }}
                    >
                      {mg.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItemButton>
                </ListItem>
                <Collapse in={mg.open}>
                  <List component="div" disablePadding sx={{ pl: 5 }}>
                    {mg.modifiers.map((m, index) => (
                      <ListItem divider key={index}>
                        <ListItemText primary={m.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ))}
          </List>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default ModifiersPicker;
