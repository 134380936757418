import React, { useState } from 'react';
import { TableRow, TableCell, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useMutation, gql } from '@apollo/client';

import { useSnackbar } from 'hooks';
import { formattedNumber } from 'helpers';
import { CustomDialog, UsersSelect } from 'components';
import { GetAdminApartmentsQuery, SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables, InsertStatementMutation, InsertStatementMutationVariables } from 'types/graphql';

type Apartment = GetAdminApartmentsQuery['apartments_helper'][0];

interface ApartmentRowProps {
  apartment: Apartment;
}

const UPDATE_APARTMENT = gql`
  mutation SetApartmentOwner($apartment_id: Int!, $owner_id: Int!) {
    update_apartment_by_pk(pk_columns: { id: $apartment_id }, _set: { user_id: $owner_id }) {
      id
    }
  }
`;

const CREATE_STATEMENT = gql`
  mutation MyMutation($apartment_id: Int) {
    insert_statement_account(objects: {apartment_id: $apartment_id, total: "0", owner: true}) {
      affected_rows
    }
  }
`;

const ApartmentRow = ({ apartment }: ApartmentRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [ownerDialog, setOwnerDialog] = useState(false);
  const [statementDialog, insertStatementDialog] = useState(false);
  const [ownerId, setOwnerId] = useState(1);
  const { showSnackbar } = useSnackbar();
  const [updateApartment] = useMutation<SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables>(
    UPDATE_APARTMENT
  );
  const [insertStatement] = useMutation<InsertStatementMutation, InsertStatementMutationVariables>(
    CREATE_STATEMENT
  );
  const open = Boolean(anchorEl);

  const handleApartmentUpdate = async () => {
    try {
      if (apartment.apartment_id) {
        await updateApartment({
          variables: {
            apartment_id: apartment.apartment_id,
            owner_id: ownerId
          }
        });
        showSnackbar('Dueño asignado correctamente', 'success');
      }
    } catch (err) {
      showSnackbar('Error al asignar dueño', 'error');
    }
  };

  const handleStatementInsert = async () => {
    try {
      if (apartment.apartment_id) {
        await insertStatement({
          variables: {
            apartment_id: apartment.apartment_id
          }
        });
        showSnackbar('Estado de cuenta creado correctamente', 'success');
      }
    } catch (err) {
      showSnackbar('Error al crear el estado de cuenta', 'error');
    }
  };

  return (
    <TableRow sx={{ '&:hover': { bgcolor: (theme) => theme.palette.grey[200], cursor: 'pointer' } }}>
      <CustomDialog
        fullWidth
        maxWidth="md"
        onAccept={handleApartmentUpdate}
        onCancel={() => setOwnerDialog(false)}
        open={ownerDialog}
        title={`Asignar dueño (${apartment.apartment_name})`}
      >
        <UsersSelect label="Dueño" onChange={(userId) => setOwnerId(userId)} value={ownerId} />
      </CustomDialog>

      <CustomDialog
        maxWidth="md"
        onAccept={handleStatementInsert}
        onCancel={() => insertStatementDialog(false)}
        open={statementDialog}
        title="Crear estado de cuenta"
      />

      <TableCell>{apartment.apartment_name}</TableCell>
      <TableCell>{apartment.owner}</TableCell>
      
      {apartment.statement_account_id === 0 ? (
        <>
          <TableCell align="right">
            NaN
          </TableCell>
          <TableCell align="right">
            NaN
          </TableCell>
        </>
      ) : (
        <>
          <TableCell align="right">
            $ {formattedNumber(apartment.balance.toFixed(2))}
          </TableCell>
          <TableCell align="right">
            $ {formattedNumber(apartment.balance_retained.toFixed(2))}
          </TableCell>
        </>
      )}

      <TableCell align="center">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
          {apartment.statement_account_id === 0 ? (
            [
              <MenuItem key="assign-owner" onClick={() => setOwnerDialog(true)}>Asignar dueño</MenuItem>,
              <MenuItem key="insert-statement" onClick={() => insertStatementDialog(true)}>Dar de alta</MenuItem>
            ]
          ) : (
            [
              <MenuItem key="assign-owner" onClick={() => setOwnerDialog(true)}>Asignar dueño</MenuItem>
            ]
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default ApartmentRow;
