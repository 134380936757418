import React, { useState, useEffect } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, FormikHelpers } from 'formik';
import { gql, useMutation, ApolloError } from '@apollo/client';
import * as yup from 'yup';

import { useSnackbar } from 'hooks';
import { UpdatePrinterMutation, UpdatePrinterMutationVariables } from 'types/graphql';

type FormikState = {
  id: number;
  address: string;
  name: string;
};
type Printer = FormikState;

interface PrinterDetailsProps {
  printer: Printer;
  onPrinterUpdate: (id: number) => void;
}

const UPDATE_PRINTER = gql`
  mutation UpdatePrinter($ip: String!, $name: String!, $id: Int!) {
    printer: update_printers_by_pk(pk_columns: { id: $id }, _set: { name: $name, address: $ip }) {
      id
    }
  }
`;

const ipRegex =
  /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;

const validationSchema = yup.object().shape({
  address: yup.string().matches(ipRegex, 'Ingrese una dirección IP válida').required('Se requiere una dirección ip'),
  name: yup.string().required('Se requiere un nombre')
});

const PrinterDetailsForm = ({ printer, onPrinterUpdate }: PrinterDetailsProps): JSX.Element => {
  const [formikState, setFormikState] = useState<FormikState>({
    address: printer.address,
    name: printer.name,
    id: printer.id
  });
  const [updatePrinter] = useMutation<UpdatePrinterMutation, UpdatePrinterMutationVariables>(UPDATE_PRINTER);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setFormikState(printer);
  }, [printer]);

  const handleSubmission = async (values: FormikState, helper: FormikHelpers<FormikState>) => {
    try {
      await updatePrinter({
        variables: {
          id: values.id,
          name: values.name,
          ip: values.address
        }
      });
      onPrinterUpdate(values.id);
      showSnackbar('Impresora actualizada', 'success');
    } catch (err) {
      if ((err as ApolloError)?.message.toLocaleLowerCase().includes('uniqueness violation')) {
        helper.setErrors({ address: 'Ya existe una impresora con la dirección ip proporcionada' });
      } else {
        showSnackbar('Error actualizando datos de impresora', 'error');
      }
    }
  };

  return (
    <Formik initialValues={formikState} onSubmit={handleSubmission} validationSchema={validationSchema}>
      {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, handleBlur }) => (
        <>
          <Grid alignItems="center" container item spacing={2} sx={{ p: 2 }} xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1">Información</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={touched.address && Boolean(errors.address)}
                fullWidth
                helperText={touched.address && errors.address}
                label="Dirección ip"
                onBlur={handleBlur('address')}
                onChange={handleChange('address')}
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre"
                onChange={handleChange('name')}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid alignContent="center" container item justifyContent="flex-end" spacing={2} xs={12}>
              <Grid item>
                <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()} variant="contained">
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default PrinterDetailsForm;
