/* eslint-disable react/prop-types */
import React from 'react';
import NumberFormat from 'react-number-format';

interface CustomNumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  prefix?: string;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<unknown>, CustomNumberFormatProps>(function CustomFormat(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => onChange({ target: { name: props.name, value: values.value } })}
      thousandSeparator
    />
  );
});

export default CustomNumberFormat;
