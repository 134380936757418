import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Collapse
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery, gql } from '@apollo/client';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Print as PrintIcon } from '@mui/icons-material';

import { formattedNumber } from 'helpers';
import { useAxios, useSnackbar } from 'hooks';
import { GetTicketsQuery, GetTicketsQueryVariables } from 'types/graphql';

type Dish = {
  name: string;
  price: number;
  quantity: number;
};

type Ticket = {
  id: number;
  open: boolean;
  date: Date;
  total: number;
  name: string;
  dishes: Array<Dish>;
};

const GET_TICKETS = gql`
  query GetTickets($date: date!) {
    tickets: account_tickets(where: { _and: [{ date: { _gte: $date, _lte: $date } }] }) {
      id
      date
      commensal_name
      orders {
        order_dishes {
          dish {
            name
          }
          dish_price
          quantity
        }
      }
    }
  }
`;

const Tickets = (): JSX.Element => {
  const [tickets, setTickets] = useState<Array<Ticket>>([]);
  const [ticketDate, setTicketDate] = useState<Date>(new Date());
  const { axios } = useAxios();
  const { showSnackbar } = useSnackbar();
  const { data } = useQuery<GetTicketsQuery, GetTicketsQueryVariables>(GET_TICKETS, {
    variables: { date: ticketDate.toISOString().split('T')[0] }
  });

  useEffect(() => {
    if (data) {
      const mappedTickets: Array<Ticket> = data.tickets.map((t) => {
        const dishes: Array<Dish> = [];
        let total = 0;

        t.orders.forEach((o) => {
          o.order_dishes.forEach((od) => {
            total += od.dish_price * od.quantity;
            dishes.push({
              name: od.dish.name,
              price: od.dish_price,
              quantity: od.quantity
            });
          });
        });

        return {
          id: t.id,
          date: new Date(t.date as string),
          open: false,
          name: t.commensal_name || '',
          dishes,
          total
        };
      });

      setTickets(mappedTickets);
    }
  }, [data, ticketDate]);

  const handlePrintClick = async (id: number): Promise<void> => {
    try {
      const { data } = await axios.post<{ success: boolean }>('print_ticket', { id, template: 'account' });
      if (!data.success) {
        showSnackbar('Error al imprimir ticket', 'error');
      }
    } catch (err) {
      showSnackbar('Error al imprimir ticket', 'error');
    }
  };

  const handleCollapseToggle = (index: number): void => {
    const copy = [...tickets];
    copy[index].open = !copy[index].open;
    setTickets(copy);
  };

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">Reimpresión de tickets</Typography>
        </Grid>
        <Grid item>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="Fecha"
            maxDate={new Date()}
            onChange={(date) => date && setTicketDate(date)}
            renderInput={(props) => <TextField {...props} />}
            value={ticketDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        {tickets.map((t, index) => (
          <Grid alignItems="center" container item justifyContent="space-between" key={index} spacing={2} xs={12}>
            <Grid item>
              <Typography>{t.name}</Typography>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={() => handlePrintClick(t.id)}>
                <PrintIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => handleCollapseToggle(index)} sx={{ ml: 2 }}>
                {t.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={t.open}>
                <List>
                  {t.dishes.map((d, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={d.name}
                        secondary={`${d.quantity} pieza(s) $ ${formattedNumber((d.price * d.quantity).toFixed(2))}`}
                      />
                    </ListItem>
                  ))}
                </List>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  alignItems="center"
                  container
                  item
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ p: 1 }}
                  xs={12}
                >
                  <Grid item>
                    <Typography>Total</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>$ {formattedNumber(t.total.toFixed(2))}</Typography>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Tickets;
