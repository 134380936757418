import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Divider,
  Button,
  CircularProgress,
  Collapse,
  Theme
} from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useSnackbar } from 'context';
import { CustomDialog } from '../../../components';
import { numberWithCommas } from '../utils';
import CustomNumberFormat from './CustomNumberFormat';
import { FormikState, ExchangeItemProps, SettingsDialogProps } from '../types/SettingsDialog';
import { CurrencyKey } from '../types/Globals';
import { GetCurrenciesInfoQuery, UpdateCurrencyMutation, UpdateCurrencyMutationVariables } from 'types/graphql';

const UPDATE_EXCHANGE = gql`
  mutation UpdateCurrency($id: Int!, $amount: numeric!) {
    update_exchange_by_pk(pk_columns: { id: $id }, _set: { exchange: $amount }) {
      id
      exchange
    }
  }
`;

const GET_CURRENCIES = gql`
  query GetCurrenciesInfo {
    exchange(where: { disabled: { _eq: false } }, order_by: { id: asc }) {
      id
      currency_key
      currency_name
      exchange
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  amount: yup.number().required('Este campo es requerido').moreThan(0, 'Tipo de cambio debe ser mayor a 0')
});

const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const ExchangeItem = ({ currencyName, currencyKey, exchange, id }: ExchangeItemProps): JSX.Element => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);
  const [doUpdate] = useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UPDATE_EXCHANGE);
  const { showSnackbar } = useSnackbar();

  const handleCurrencyUpdate = async (values: FormikState) => {
    try {
      await doUpdate({
        variables: {
          id,
          amount: Number(values.amount)
        }
      });
      setCollapse(false);
      showSnackbar('Tipo de cambio actualizado', 'success');
    } catch (err) {
      showSnackbar('Error al actualizar tipo de cambio', 'error');
    }
  };

  const toggleCollapse = () => {
    setCollapse((prevState) => !prevState);
  };

  return (
    <>
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item xs={6}>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {currencyKey}
          </Typography>
          <Typography>{currencyName}</Typography>
        </Grid>
        <Grid alignItems="center" container item justifyContent="flex-end" spacing={1} xs={6}>
          <Grid item>
            <Typography>$ {numberWithCommas(exchange.toFixed(2))}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={toggleCollapse} size="large">
              {collapse ? <CloseIcon /> : <EditIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={collapse} timeout="auto" unmountOnExit>
        <Formik
          initialValues={{ id, amount: exchange }}
          onSubmit={handleCurrencyUpdate}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <Grid className={classes.spacing} container justifyContent="space-between">
              <Grid item>
                <TextField
                  InputProps={{ inputComponent: CustomNumberFormat as any }}
                  error={!!errors.amount}
                  fullWidth
                  helperText={!!errors.amount && errors.amount}
                  label="Monto"
                  onChange={handleChange('amount')}
                  prefix="$"
                  size="small"
                  value={values.amount}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button color="primary" onClick={() => handleSubmit()} size="small" variant="contained">
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Collapse>
      <Divider />
    </>
  );
};

const SettingsDialog = ({ open, onClose }: SettingsDialogProps): JSX.Element => {
  const classes = useStyles();
  const { data, loading } = useQuery<GetCurrenciesInfoQuery, null>(GET_CURRENCIES);

  return (
    <CustomDialog fullWidth maxWidth="md" onAccept={onClose} onCancel={onClose} open={open} title="Tipos de cambio">
      <Grid alignContent="center" container justifyContent="center">
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid className={classes.spacing} container direction="column">
              {data?.exchange.map((c) => (
                <ExchangeItem
                  currencyKey={c.currency_key as CurrencyKey}
                  currencyName={c.currency_name}
                  exchange={c.exchange}
                  id={c.id}
                  key={c.currency_key}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default SettingsDialog;
