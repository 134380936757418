import React, { useState, createContext, FC, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, getAuth, User, signOut, onIdTokenChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';

import { UserState, UserRole, PalapaReduxState } from 'types/redux';
import * as actionCreators from 'store/actionCreators';

initializeApp({
  apiKey: 'AIzaSyDeaK_6gkxJVbVoaUz2RUeTftVFPZ_8cck',
  authDomain: 'owners-272001.firebaseapp.com',
  databaseURL: 'https://owners-272001.firebaseio.com',
  projectId: 'owners-272001',
  storageBucket: 'owners-272001.appspot.com',
  messagingSenderId: '834650896343',
  appId: '1:834650896343:web:098ad040233b330c79bd92',
  measurementId: 'G-DDG0J70XQK'
});

interface AuthFirebase {
  user: UserState;
  signIn?: (email: string, password: string) => Promise<User | null>;
  getUserToken?: () => Promise<string>;
  logout?: () => Promise<boolean>;
}

const defaultContext: AuthFirebase = {
  user: {
    email: '',
    token: '',
    uid: '',
    auth: false,
    role: 'user'
  }
};

export const AuthFirebaseContext = createContext<AuthFirebase>(defaultContext);
export const useFirebaseAuth = (): AuthFirebase => React.useContext(AuthFirebaseContext);

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<UserState>(defaultContext.user);
  const reduxUser = useSelector((state: PalapaReduxState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    onIdTokenChanged(getAuth(), async (firebaseUser) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken();
        const tokenResult = await firebaseUser.getIdTokenResult();
        const hasuraClaim = tokenResult.claims['https://hasura.io/jwt'] as string;
        setUser((prevState) => ({ ...prevState, hasuraLink: hasuraClaim, token }));
        localStorage.setItem('user_token', token);

        dispatch(actionCreators.updateToken(token));
      }
    });
  }, []);

  useEffect(() => {
    setUser(reduxUser);
  }, [reduxUser]);

  const getUserToken = async (): Promise<string> => {
    const token = (await getAuth().currentUser?.getIdToken()) || '';

    return token;
  };

  const signIn = async (email: string, password: string): Promise<User | null> => {
    const auth = getAuth();
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const token = await user.getIdToken();
    const tokenResult = await user.getIdTokenResult();
    const role = ((tokenResult.claims['https://hasura.io/jwt/claims'] as any)['x-hasura-role'] ||
      (tokenResult.claims['https://hasura.io/jwt/claims'] as any)['x-hasura-default-role']) as UserRole;

    if (user) {
      dispatch(
        actionCreators.storeUser({
          uid: user.uid,
          email,
          token,
          role,
          auth: true
        })
      );

      setUser((prevState) => ({
        ...prevState,
        email,
        uid: user.uid,
        token,
        auth: true,
        role
      }));
      localStorage.setItem('user_token', token);
      return user;
    } else {
      return null;
    }
  };

  const logout = async (): Promise<boolean> => {
    const auth = getAuth();
    try {
      await signOut(auth);
      dispatch(actionCreators.removeUser());
      localStorage.removeItemI('user_token');
      setUser(defaultContext.user);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <AuthFirebaseContext.Provider value={{ ...defaultContext, user, signIn, logout, getUserToken }}>
      {children}
    </AuthFirebaseContext.Provider>
  );
};
