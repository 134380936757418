import React, { useState } from 'react';
import { Grid, TextField, Typography, Divider, TableRow, TableCell, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery, gql, useMutation } from '@apollo/client';
import { ArrowBack as ArrowBackIcon, Print as PrintIcon } from '@mui/icons-material';
import dayjs from 'dayjs';

import { useSnackbar } from 'hooks';
import { CustomTable } from 'components';
import { formattedNumber } from 'helpers';
import { GetReportTransactionsQuery, GetReportTransactionsQueryVariables, PrintReportMutation, PrintReportMutationVariables } from 'types/graphql';

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];

const GET_REPORT_TRANSACTIONS = gql`
  query GetReportTransactions($date: date!) {
    transactions: transactions_helper(
      where: {
        _and: {
          date: { _eq: $date }
          _or: [
            { transaction_type: { _eq: "Refund" } }
            { transaction_type: { _eq: "Deposit" } }
            { transaction_type: { _eq: "Transfer" } }
          ]
        }
      }
    ) {
      amount
      canceled
      commensal_name
      apartment_name
      transaction_type
      es_description
      canceled_at
      created_at
      currency
      exchange
    }
  }
`;

const PRINT_REPORT_TRANSACTIONS = gql `
  mutation printTicketReport ($format: String!, $date: String!) {
  PrintTicket(format: $format, date: $date) {
    message
    success
  }
}
`;

const TABLE_HEADERS: TableHeaders = [
  {
    title: 'Comensal'
  },
  {
    title: 'Hora'
  },
  {
    title: 'Tipo'
  },
  {
    title: 'Moneda'
  },
  {
    title: 'Monto',
    align: 'right',
    width: '15%'
  },
  {
    title: 'Total',
    align: 'right',
    width: '15%'
  }
];

const CashierReport = (): JSX.Element => {
  const [cashierDate, setCashierDate] = useState<Date>(new Date());
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const [printReport] = useMutation<PrintReportMutation, PrintReportMutationVariables>(PRINT_REPORT_TRANSACTIONS);
  const { data, loading } = useQuery<GetReportTransactionsQuery, GetReportTransactionsQueryVariables>(
    GET_REPORT_TRANSACTIONS,
    {
      variables: { date: cashierDate.toLocaleString('sv').split(' ')[0] }
    }
  );

  const handleTicketPrint = async (): Promise<void> => {
    try {
      const  {data}  = await printReport({
        variables: {
          id: 0,
          format: 'report',
          date: cashierDate.toLocaleString('sv').split(' ')[0]
        },
      });

      if (data?.PrintTicket?.success) {
        showSnackbar('Corte de caja impreso correctamente', 'success');
      } else {
        showSnackbar('Error al imprimir corte de caja', 'error');
      }
    } catch (err) {
      showSnackbar('Error al imprimir ticket', 'error');
    }
  };

  const getTransactionType = (type: string): string => {
    return type === 'Deposit' ? 'Depósito' : type === 'Transfer' ? 'Transferencia' : 'Reembolso' ;
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Grid item>
          <IconButton color="primary" onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6">Corte de caja</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="Fecha"
            maxDate={new Date()}
            onChange={(date) => date && setCashierDate(date)}
            renderInput={(params) => <TextField {...params} />}
            value={cashierDate}
          />
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={handleTicketPrint}>
            <PrintIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS} loading={loading}>
          {data?.transactions.length === 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={TABLE_HEADERS.length}>
                No hay registros
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data?.transactions.map((t, index) => (
                <TableRow key={index}>
                  <TableCell>{`(${t.apartment_name}) ${t.commensal_name}`}</TableCell>
                  <TableCell>{dayjs(t.created_at).format('hh:mm:ss a')}</TableCell>
                  <TableCell>{getTransactionType(t.transaction_type as string)}</TableCell>
                  <TableCell>{t.currency}</TableCell>
                  <TableCell align="right">$ {formattedNumber(t.amount.toFixed(2))}</TableCell>
                  <TableCell align="right">$ {formattedNumber((t.amount * t.exchange).toFixed(2))}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right" colSpan={TABLE_HEADERS.length - 1}>
                  <Typography sx={{ fontWeight: 'bold' }}>Total</Typography>
                </TableCell>
                <TableCell align="right">
                  ${' '}
                  {formattedNumber(
                    data?.transactions.reduce((prevValue, t) => (prevValue += t.amount * t.exchange), 0).toFixed(2) || 0
                  )}
                </TableCell>
              </TableRow>
            </>
          )}
        </CustomTable>
      </Grid>
    </Grid>
  );
};

export default CashierReport;
