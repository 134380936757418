import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Divider,
  Theme,
  TextField,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  Fab
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Add as AddIcon, Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'context';
import { useAutoSave } from 'hooks';
import { GetUnitsQuery, InsertIngredientsMutation, InsertIngredientsMutationVariables } from 'types/graphql';

const GET_UNITS = gql`
  query GetUnits {
    units(order_by: { name: asc }) {
      id
      name
      abreviation
    }
  }
`;

const INSERT_UNITS = gql`
  mutation InsertIngredients($data: [ingredients_insert_input!]!) {
    insert_ingredients(objects: $data) {
      returning {
        id
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  ingredients: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Este campo es obligatorio'),
      inventory: yup.boolean(),
      unit: yup.string().required('Este campo es obligatorio'),
      available: yup.number()
    })
  )
});

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

const initialState = {
  ingredients: [
    {
      name: '',
      inventory: true,
      unit: '',
      available: 0
    }
  ]
};

type FormikState = typeof initialState;

const AddIngredient = (): JSX.Element => {
  const [initialFormikState, setInitialFormikState] = useState(initialState);
  const { showSnackbar } = useSnackbar();
  const { data, loading } = useQuery<GetUnitsQuery>(GET_UNITS);
  const [doInsert] = useMutation<InsertIngredientsMutation, InsertIngredientsMutationVariables>(INSERT_UNITS);
  const { getAutoSaveData, setAutoSaveData, destroyAutoSaveData } = useAutoSave<FormikState>('add_ingredients_data');
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    const item = getAutoSaveData();
    if (item) {
      setInitialFormikState(item);
    }
  }, []);

  const handleSubmission = async (values: FormikState) => {
    try {
      const mappedData = values.ingredients.map((i) => ({
        quantity: i.available,
        name: i.name,
        inventory: i.inventory,
        unit: parseInt(i.unit)
      }));

      await doInsert({
        variables: {
          data: mappedData
        }
      });

      showSnackbar('Ingredientes registrados correctamente', 'success');
      history.goBack();
      destroyAutoSaveData();
    } catch (err) {
      showSnackbar('Error registrando ingrediente', 'error');
    }
  };

  const handleAutoSaveBlur = (values: FormikState) => {
    setAutoSaveData(values);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormikState}
      onSubmit={handleSubmission}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <>
          <Grid alignItems="center" container flexDirection="column" spacing={2} sx={{ marginBottom: 8 }}>
            <Grid container item justifyContent="space-between" xs={12}>
              <Grid item>
                <Typography variant="h6">Agregar ingredientes</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={styles.addButton}
                  onClick={() => setFieldValue('ingredients', [...values.ingredients, initialState.ingredients[0]])}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {values.ingredients.map((v, index) => (
              <React.Fragment key={index}>
                <Grid container item key={index} spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <Divider textAlign="left">
                      <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                        Ingrediente {index + 1}
                      </Typography>
                    </Divider>
                  </Grid>
                  <Grid alignItems="center" container item spacing={2} xs={11}>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Nombre"
                        onBlur={() => handleAutoSaveBlur(values)}
                        onChange={(e) => setFieldValue(`ingredients[${index}].name`, e.target.value)}
                        value={v.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="label-unidad">Unidad</InputLabel>
                        <Select
                          label="Unidad"
                          labelId="label-unidad"
                          onBlur={() => handleAutoSaveBlur(values)}
                          onChange={(e) => setFieldValue(`ingredients[${index}].unit`, e.target.value)}
                          value={v.unit}
                        >
                          {data?.units.map((u) => (
                            <MenuItem key={u.id} value={u.id}>{`${u.name} (${u.abreviation})`}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={9} sm={8} xs={12}>
                      <TextField
                        disabled={!v.inventory}
                        fullWidth
                        label="Existencia"
                        onBlur={() => handleAutoSaveBlur(values)}
                        onChange={(e) => setFieldValue(`ingredients[${index}].available`, e.target.value)}
                        value={v.available}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} sm={4} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={v.inventory}
                              color="primary"
                              onChange={(e) => {
                                setFieldValue(`ingredients[${index}].inventory`, e.target.checked);
                              }}
                            />
                          }
                          label="Inventariable"
                          onBlur={() => handleAutoSaveBlur(values)}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid alignItems="center" container item justifyContent="center" xs={1}>
                    <Grid item>
                      <IconButton
                        color="error"
                        disabled={values.ingredients.length === 1}
                        onClick={() => {
                          const valuesHelper = [...values.ingredients];
                          valuesHelper.splice(index, 1);
                          setFieldValue('ingredients', valuesHelper);
                          handleAutoSaveBlur(values);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Fab
            color="primary"
            onClick={() => handleSubmit()}
            sx={{ position: 'fixed', margin: 0, bottom: 20, right: 20, top: 'auto', left: 'auto' }}
            variant="extended"
          >
            <DoneIcon sx={{ marginRight: 1 }} />
            Guardar
          </Fab>
        </>
      )}
    </Formik>
  );
};

export default AddIngredient;
