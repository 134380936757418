import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, colors } from '@mui/material';

import { GetMenuQuery } from 'types/graphql';
import { formattedNumber } from 'helpers';

type Modifier = {
  title: string;
  id: number;
};

type ModifierGroup = {
  title: string;
  selected: boolean;
  id: number;
  open: boolean;
  multiple: boolean;
  modifiers: Array<Modifier>;
};

type Ingredient = {
  id: number;
  quantity: number;
  name: string;
  unit: string;
  optional: boolean;
};

type MappedMenu = Array<{
  id: number;
  name: string;
  cost: number;
  price: number;
  subcategory: number;
  menu: number;
  category: number;
  showIngredients: boolean;
  ingredients: Array<Ingredient>;
  modifierGroups: Array<ModifierGroup>;
}>;

interface TableViewProps {
  data: GetMenuQuery;
}

const TableView = ({ data }: TableViewProps): JSX.Element => {
  const history = useHistory();
  const [menu, setMenu] = useState<MappedMenu>();

  useEffect(() => {
    if (data) {
      const mappedMenu: MappedMenu = data.dishes.map((d) => ({
        id: d.id,
        name: d.name,
        showIngredients: false,
        menu: d.subcategory?.category.menu_type.id || 1,
        category: d.subcategory?.category.id || 1,
        cost: d.cost,
        subcategory: d.subcategory?.id || 1,
        description: d.description,
        price: d.price,
        modifierGroups:
          d.modifier_group_dishes.map((mg) => ({
            id: mg.modifier_group.id,
            title: mg.modifier_group.title,
            open: false,
            modifiers: mg.modifier_group.modifiers.map((m) => ({ title: m.title, id: m.id })) || [],
            selected: true,
            multiple: mg.modifier_group.multiple
          })) || [],
        ingredients: d.ingredient_dishes.map((di) => ({
          id: di.ingredient.id,
          quantity: di.quantity,
          name: di.ingredient.name,
          unit: di.ingredient.unitObject.abreviation,
          optional: di.optional
        }))
      }));

      setMenu(mappedMenu);
    }
  }, [data]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align="right">Costo</TableCell>
            <TableCell align="right">Precio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {menu?.map((d, index) => (
            <TableRow
              key={index}
              onClick={() => history.push('/menu/edit', { dish: d, edit: true })}
              sx={{ '&:hover': { backgroundColor: colors.grey[200], cursor: 'pointer' } }}
            >
              <TableCell>{d.name}</TableCell>
              <TableCell align="right">$ {formattedNumber(d.cost.toFixed(2))}</TableCell>
              <TableCell align="right">$ {formattedNumber(d.price.toFixed(2))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableView;
