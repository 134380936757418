import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Alert, AlertTitle, Pagination, Divider } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { GetPrinterLogsQuery, GetPrinterLogsQueryVariables } from 'types/graphql';

type PrinterLogLevel = 'success' | 'error';

type PrinterLog = {
  time: string;
  level: PrinterLogLevel;
  description: string;
  pythonDescription: string;
  open: boolean;
};

interface PrinterLogsProps {
  id: number;
}

const LOGS_PER_PAGE = 10;

const GET_PRINTER_LOGS = gql`
  query GetPrinterLogs($id: Int!, $offset: Int!, $limit: Int!) {
    logs_length: printer_logs_aggregate(where: { printer_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
    logs: printer_logs(
      where: { printer_id: { _eq: $id } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      level
      description
      python_log
      created_at
    }
  }
`;

const PrinterLogs = ({ id }: PrinterLogsProps): JSX.Element => {
  const [maxPages, setMaxPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [logs, setLogs] = useState<PrinterLog[]>([]);
  const { data, loading } = useQuery<GetPrinterLogsQuery, GetPrinterLogsQueryVariables>(GET_PRINTER_LOGS, {
    variables: { id, offset: LOGS_PER_PAGE * (currentPage - 1), limit: LOGS_PER_PAGE },
    pollInterval: 5000
  });

  useEffect(() => {
    if (data?.logs) {
      const mappedLogs: Array<PrinterLog> = data.logs.map((l) => ({
        description: l.description,
        pythonDescription: l.python_log || '',
        level: l.level as PrinterLogLevel,
        time: dayjs(l.created_at).format('DD/MM/YYYY HH:mm:ss'),
        open: false
      }));

      setLogs(mappedLogs);
    }

    if (data?.logs_length.aggregate) {
      setMaxPages(Math.ceil((data.logs_length.aggregate.count || 1) / LOGS_PER_PAGE));
    }
  }, [data, loading]);

  return (
    <Box border={1} borderColor={(theme) => theme.palette.divider} borderRadius={2}>
      <Grid container flexDirection="column" item spacing={2} sx={{ p: 2 }} xs={12}>
        <Grid item sx={{ pb: 1 }}>
          <Typography variant="body1">Registros</Typography>
        </Grid>
        {logs.map((l, index) => {
          return (
            <Grid item key={index}>
              <Alert severity={l.level} variant="outlined">
                <AlertTitle>{`${l.time}`}</AlertTitle>
                <strong>{l.description}</strong> - {l.pythonDescription}
              </Alert>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item justifyContent="center" spacing={2} xs={12}>
          <Grid item>
            <Pagination
              color="primary"
              count={maxPages}
              onChange={(_, value) => setCurrentPage(value)}
              page={currentPage}
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrinterLogs;
