import React, { useState } from 'react';
import { Grid, Select, MenuItem, InputLabel, FormControl, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gql, useMutation } from '@apollo/client';

import { useSnackbar } from 'context';
import { GetUserDetailsQuery, UpdateRoleMutation, UpdateRoleMutationVariables } from 'types/graphql';

type User = GetUserDetailsQuery['user'];
type Role = 'user' | 'admin' | 'palapa' | 'recepcion' | 'board' | 'portico';

interface UserManagerProps {
  user: User;
}

const UPDATE_ROLE = gql`
  mutation UpdateRole($user: Int!, $uid: String!, $role: String!) {
    UpdateUserRole(role: $role, uid: $uid, user_id: $user) {
      uid
    }
  }
`;

const UserManager = ({ user }: UserManagerProps): JSX.Element => {
  const [role, setRole] = useState<Role | null>((user?.roles as Role) || null);
  const { showSnackbar } = useSnackbar();
  const [updateRole, { loading: roleLoading }] = useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UPDATE_ROLE
  );

  const handleRoleUpdate = async () => {
    try {
      if (user && role) {
        await updateRole({
          variables: {
            user: user.id,
            uid: user.uid,
            role
          }
        });
        showSnackbar('Permisos actualizados correctamente', 'success');
      } else {
        showSnackbar('Error actualizando usuario', 'error');
      }
    } catch (err) {
      showSnackbar('Error actualizando usuario', 'error');
    }
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Grid item xs={10}>
          <FormControl fullWidth>
            <InputLabel id="permisos">Permisos</InputLabel>
            <Select label="Permisos" labelId="permisos" onChange={(e) => setRole(e.target.value as Role)} value={role}>
              <MenuItem value="user">Usuario / dueño</MenuItem>
              <MenuItem value="admin">Administrador</MenuItem>
              <MenuItem value="palapa">Palapa</MenuItem>
              <MenuItem value="recepcion">Recepcionista</MenuItem>
              <MenuItem value="board">Board</MenuItem>
              <MenuItem value="portico">Pórtico</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            color="primary"
            disabled={role === user?.roles}
            fullWidth
            loading={roleLoading}
            onClick={handleRoleUpdate}
            variant="contained"
          >
            Actualizar
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Grid item xs={10}>
          <TextField fullWidth label="Contraseña" />
        </Grid>
        <Grid item xs={2}>
          <Button color="primary" fullWidth variant="contained">
            Actualizar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button color="error" fullWidth variant="contained">
          Bloquear
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserManager;
