import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  Divider,
  Pagination,
  TableRow,
  TableCell,
  CircularProgress,
  Typography
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables } from 'types/graphql';
import { CustomTable } from 'components';
import { formattedNumber } from 'helpers';
import { ReceptionContextMenu, ContextMenu } from './components';

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];

const ACCOUNTS_PER_PAGE = 20;

const GET_ACCOUNTS = gql`
  query GetReceptionCommensals($searchString: String!, $limit: Int!, $offset: Int!) {
    aggregate: palapa_accounts_aggregate(
      where: {
        _and: [
          { _or: [{ commensal_name: { _ilike: $searchString } }, { apartment_name: { _ilike: $searchString } }] }
          { present: { _eq: true } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    accounts: palapa_accounts(
      where: {
        _and: [
          { _or: [{ commensal_name: { _ilike: $searchString } }, { apartment_name: { _ilike: $searchString } }] }
          { present: { _eq: true } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{ apartment_building: asc }, { apartment_name: asc }]
    ) {
      statement_id
      commensal_name
      apartment_name
      commensal_type
      present
      balance
      balance_retained
      pin_created
      rental_id
      user_id
    }
  }
`;

const TABLE_HEADERS: TableHeaders = [
  {
    title: 'Nombre'
  },
  {
    title: 'Condominio'
  },
  {
    title: 'Saldo',
    width: '13%',
    align: 'right'
  },
  {
    title: 'Cuenta retenida',
    width: '13%',
    align: 'right'
  },
  {
    title: '',
    width: '5%'
  }
];

const Reception = (): JSX.Element => {
  const [searchString, setSearchString] = useState('');
  const [maxPages, setMaxPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const { data, loading } = useQuery<GetReceptionCommensalsQuery, GetReceptionCommensalsQueryVariables>(GET_ACCOUNTS, {
    variables: {
      searchString: `%${searchString}%`,
      limit: ACCOUNTS_PER_PAGE,
      offset: (currentPage - 1) * ACCOUNTS_PER_PAGE
    },
    pollInterval: 1000
  });

  useEffect(() => {
    if (data?.aggregate.aggregate) {
      setMaxPages(Math.ceil((data.aggregate.aggregate.count || 0) / ACCOUNTS_PER_PAGE));
    }
  }, [data]);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Button color="primary" onClick={() => history.push('/reception/registration')} variant="contained">
            Check in
          </Button>
        </Grid>
        <Grid item>
          <TextField label="Buscar" onChange={(e) => setSearchString(e.target.value)} value={searchString} />
          <ReceptionContextMenu />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS}>
          {loading ? (
            <TableRow>
              <TableCell align="center" colSpan={TABLE_HEADERS.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            data?.accounts.map((ac, index) => (
              <TableRow key={index} sx={{ '&:hover': { bgcolor: (theme) => theme.palette.grey[200] } }}>
                <TableCell>
                  <Typography
                    onClick={() => history.push('/reception/details', { id: ac.statement_id })}
                    sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                    variant="body2"
                  >
                    {ac.commensal_name}
                  </Typography>
                </TableCell>
                <TableCell>{ac.apartment_name}</TableCell>
                <TableCell align="right">$ {formattedNumber(ac.balance.toFixed(2))}</TableCell>
                <TableCell align="right">$ {formattedNumber(ac.balance_retained.toFixed(2))}</TableCell>
                <TableCell align="center">
                  <ContextMenu
                    commensalBalance={ac.balance}
                    isOwner={ac.commensal_type === 'owner'}
                    pinCreated={ac.pin_created || false}
                    rentalId={ac.rental_id || null}
                    statementId={ac.statement_id || 0}
                    userId={ac.user_id || null}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </CustomTable>
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} sx={{ mt: 2 }} xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={maxPages}
            onChange={(_, value) => setCurrentPage(value)}
            page={currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reception;
