import React, { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface CustomTextFieldProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name?: string;
}

type NumberFormatInputProps = {
  prefix?: string;
} & TextFieldProps &
  CustomTextFieldProps &
  NumberFormatProps;

const CustomTextField = forwardRef<NumberFormat<unknown>, CustomTextFieldProps>(function NumberFormatInput(
  { onChange, name, ...props },
  ref
) {
  return (
    <NumberFormat
      {...props}
      decimalScale={2}
      getInputRef={ref}
      onValueChange={(values) => onChange({ target: { name: name || '', value: values.value } })}
    />
  );
});

const NumberFormatInput = ({
  label,
  variant,
  fullWidth,
  InputProps,
  ...props
}: NumberFormatInputProps): JSX.Element => {
  return (
    <TextField
      InputProps={{ inputComponent: CustomTextField as any, ...InputProps }}
      fullWidth={fullWidth}
      inputProps={props}
      label={label}
      variant={variant}
    />
  );
};

export default NumberFormatInput;
