import React, { useState, useEffect } from 'react';
import { Grid, Divider, Button, TextField, Pagination, TableCell, TableRow } from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { PalapaReduxState } from 'types/redux';
import { useSelector } from 'react-redux';

import { CustomTable } from 'components';
import { GetVisitsQuery, GetVisitsQueryVariables } from 'types/graphql';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];



const VISITS_PER_PAGE = 15;



const GET_VISITS = gql`
  query GetVisits($searchString: String!, $offset: Int!, $limit: Int!) {
    visits_aggregate(
      where: {
        _or: [
          { name: { _ilike: $searchString } }
          { host_name: { _ilike: $searchString } }
          { apartment_name: { _ilike: $searchString } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    visits(
      where: {
        _or: [
          { name: { _ilike: $searchString } }
          { host_name: { _ilike: $searchString } }
          { apartment_name: { _ilike: $searchString } }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      host_name
      apartment_name
      expected_arrival
      arrives_at
      arrives_by
      car_color
      checking_in
      checking_in_by
      checking_out
      checking_out_by
      plates
      car_description
      comments
      visitors_numbers
      apartment {
        name
        building {
          name
        }
      }
    }
  }
`;

const UPDATEENTRYVISITGQL = gql`
mutation UpdateCheckIn($pk_columns: visits_pk_columns_input!, $checking_in: timestamptz = "") {
  update_visits_by_pk(pk_columns: $pk_columns, _set: {checking_in: $checking_in}) {
    checking_in
  }
}
`;

const UPDATEDEPARTUREVISITGQL = gql `mutation UpdateCheckIn($pk_columns: visits_pk_columns_input!, $checking_out: timestamptz = "") {
  update_visits_by_pk(pk_columns: $pk_columns, _set: {checking_out: $checking_out}) {
    checking_out
  }
}
`;

const Visits = (): JSX.Element => {

  const user = useSelector((state: PalapaReduxState) => state.user);
  const [searchString, setSearchString] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState({ maxPages: 1, currentPage: 1 });
  const history = useHistory();
  const { data, loading, refetch } = useQuery<GetVisitsQuery, GetVisitsQueryVariables>(GET_VISITS, {
    variables: {
      searchString: `%${searchString}%`,
      offset: (paginationInfo.currentPage - 1) * VISITS_PER_PAGE,
      limit: VISITS_PER_PAGE
    },
    fetchPolicy: 'no-cache'
  });
  const [updateEntryVisitGql,{loading:loadingDataEntry,data:dataEntry,error:updateEntryError}] = useMutation(UPDATEENTRYVISITGQL);
  const [updateDepartureVisitGql,{loading:loadingDataDeparture,data:dataDeparture,error:updateDepartureError}] = useMutation(UPDATEDEPARTUREVISITGQL);

  const TABLE_HEADERS: TableHeaders = [
    {
      title: 'Condominio'
    },
    {
      title: 'Nombre'
    },
    {
      title: 'Fecha y hora de llegada esperada'
    },
    {
      title: 'Llegada'
    },
    {
      title: 'Salida'
    }
  ];

  
  const updateEntryVisit = async (id) =>{
    const answer = window.confirm('¿Seguro que desea registrar la hora de entrara?');
    const currentTimestamp = new Date().toISOString();
    if(answer === true)
    await updateEntryVisitGql({
      variables: {
        pk_columns: { id: id },
        checking_in: currentTimestamp,
      },
    });
    await refetch();
  };

  const updateDepartureVisit = async(id) =>{
    const answer = window.confirm('¿Seguro que desea registrar la hora de salida?');
    const currentTimestamp = new Date().toISOString();
    if(answer === true)
    await updateDepartureVisitGql({
      variables: {
        pk_columns: { id: id },
        checking_out: currentTimestamp,
      },
    });
    await refetch();
  };

  useEffect(() => {
    if (data) {
      const maxPages = Math.ceil((data.visits_aggregate.aggregate?.count || 1) / VISITS_PER_PAGE);
      const currentPage = 1;

      setPaginationInfo({ maxPages, currentPage }); 
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <TextField
            label="Búsqueda"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => history.push('visits/add')} variant="contained">
            Registrar visita
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS} loading={loading}>
          {data?.visits.map((v, index) => (
            <TableRow key={index}>
              <TableCell>{v.apartment_name}</TableCell>
              <TableCell>{v.name}</TableCell>
              <TableCell>{dayjs(v.arrives_at).local().format('DD/MM/YYYY hh:mm A')}</TableCell>
              {user.role === 'portico' ?
              <>
                <TableCell>
                  {v.checking_in ? dayjs(v.checking_in).format('DD/MM/YYYY hh:mm A') : <button onClick={() => updateEntryVisit(v.id)}>Register entry date</button>}
                </TableCell>
                <TableCell>
                {v.checking_out ? dayjs(v.checking_out).format('DD/MM/YYYY hh:mm A') : <button onClick={() => updateDepartureVisit(v.id)}>Register entry date</button>}
                </TableCell>
              </>
              :
              <>
                <TableCell>
                  {v.checking_in ? dayjs(v.checking_in).format('DD/MM/YYYY hh:mm A') : 'Sin registrar'}
                </TableCell>
                <TableCell>
                  {v.checking_out ? dayjs(v.checking_out).format('DD/MM/YYYY hh:mm A') : 'Sin registrar'}
                </TableCell>
              </>}

              {/* <TableCell>
                      {(userDatas?.users[0]?.roles === 'portico')
                        ? (visit.checking_in === null 
                            ? <button onClick={() => updateEntryVisit(visit.id)}>Register entry date</button>
                            : moment(visit.checking_in).format('LLLL'))
                        : (visit.checking_in === null ? '-' : moment(visit.checking_in).format('LLLL'))}
                    </TableCell>
                    <TableCell>
                      {(userDatas?.users[0]?.roles === 'portico')
                        ? (visit.checking_out === null 
                            ? <button onClick={() => updateDepartureVisit(visit.id)} >Register departure date</button>
                            : moment(visit.checking_out).format('LLLL'))
                        : (visit.checking_out === null 
                            ? '-' 
                            : moment(visit.checking_out).format('LLLL'))}
                    </TableCell> */}
            </TableRow>
          ))}
        </CustomTable>
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={paginationInfo.maxPages}
            onChange={(e, value) => setPaginationInfo((prevState) => ({ ...prevState, currentPage: value }))}
            page={paginationInfo.currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Visits;
