import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, List } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';

import { useSnackbar, useAxios, useHasuraUser } from 'hooks';
import { CustomDialog } from 'components';
import { AccountDetailOrder, CloseAccountDialog } from './components';
import {
  GetAccountDetailsQuery,
  GetAccountDetailsQueryVariables,
  CloseAccountMutation,
  CloseAccountMutationVariables,
  Currency_Type_Enum as CurrencyTypeEnum,
  Transaction_Type_Enum as TransactionTypeEnum,
  Transactions_Insert_Input as TransactionsInsertInput,
  CancelAccountMutation,
  CancelAccountMutationVariables
} from 'types/graphql';
import { palapa } from 'cbconstants';

type TipInfo = {
  tip: string;
  tipPercentage: number;
  pin: string;
};
type Orders = GetAccountDetailsQuery['palapa_accounts'][0]['orders'];

const ORDER_DISCOUNT = 0.83;

const GET_ACCOUNT_DETAILS = gql`
  query GetAccountDetails($id: Int!) {
    ticket: account_tickets(where: { _and: [{ statement_account_id: { _eq: $id } }, { open: { _eq: true } }] }) {
      id
    }
    palapa_accounts(where: { statement_id: { _eq: $id } }) {
      statement_id
      commensal_type
      balance # -> antes statement_account.total
      orders(where: { status: { _eq: "open" } }) {
        id
        total
        created_at
        order_dishes(order_by: { disabled: asc }) {
          order_dish_ingredients {
            enabled
            ingredient {
              name
            }
          }
          order_dish_modifier_groups {
            modifier_group {
              id
              title
            }
            order_dish_modifiers {
              id
              modifier {
                title
              }
            }
          }
          id
          disabled
          quantity
          dish_price
          dish {
            name
            price
          }
        }
      }
    }
  }
`;

const CLOSE_ACCOUNT = gql`
  mutation CloseAccount(
    $accountId: Int!
    $transactions: [transactions_insert_input!]!
    $ordersFlag: orders_bool_exp!
    $actions: [orders_actions_insert_input!]!
    $ticket: account_tickets_set_input!
    $ticketId: Int!
  ) {
    update_statement_account_by_pk(pk_columns: { id: $accountId }, _set: { open: false }) {
      id
      open
    }
    update_account_tickets_by_pk(pk_columns: { id: $ticketId }, _set: $ticket) {
      id
      open
    }
    insert_transactions(objects: $transactions) {
      returning {
        transaction_id
      }
    }
    update_orders(where: $ordersFlag, _set: { status: "closed" }) {
      affected_rows
    }
    insert_orders_actions(objects: $actions) {
      returning {
        id
      }
    }
  }
`;

const CANCEL_ACCOUNT = gql`
  mutation CancelAccount(
    $accountId: Int!
    $ordersFlag: orders_bool_exp!
    $actions: [orders_actions_insert_input!]!
    $dishesFlag: order_dish_bool_exp!
    $ticketId: Int!
  ) {
    update_statement_account_by_pk(pk_columns: { id: $accountId }, _set: { open: false }) {
      id
    }
    update_account_tickets_by_pk(pk_columns: { id: $ticketId }, _set: { open: false }) {
      id
    }
    update_orders(where: $ordersFlag, _set: { status: "canceled" }) {
      affected_rows
    }
    insert_orders_actions(objects: $actions) {
      returning {
        id
      }
    }
    update_order_dish(where: $dishesFlag, _set: { disabled: true }) {
      affected_rows
    }
  }
`;

const AccountDetails = (): JSX.Element => {
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const location = useLocation<{ id: number; title: string }>();
  const [closeAccount, { data: closedAccount }] = useMutation<CloseAccountMutation, CloseAccountMutationVariables>(
    CLOSE_ACCOUNT
  );
  const [cancelAccount] = useMutation<CancelAccountMutation, CancelAccountMutationVariables>(CANCEL_ACCOUNT);
  const { showSnackbar } = useSnackbar();
  const { axios } = useAxios();
  const { user } = useHasuraUser();
  const { data } = useQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GET_ACCOUNT_DETAILS, {
    variables: { id: location.state.id },
    pollInterval: 1000
  });

  /**
   * 0: Total sin descuento en caso de dueño
   * 1: Total con descuento aplicado
   */
  const calculateOrderTotal = (orders: Orders, isOwner: boolean): [number, number] => {
    const total = orders.reduce(
      (prev, current) =>
        prev +
        current.order_dishes.reduce(
          (prevO, currentO) => prevO + (currentO.disabled ? 0 : currentO.dish_price * currentO.quantity),
          0
        ),
      0
    );

    const totalWithDiscount = orders.reduce(
      (prev, current) =>
        prev +
        current.order_dishes.reduce(
          (prevO, currentO) =>
            prevO + (currentO.disabled ? 0 : currentO.dish_price * (isOwner ? ORDER_DISCOUNT : 1) * currentO.quantity),
          0
        ),
      0
    );

    return [total, totalWithDiscount];
  };

  useEffect(() => {
    const printTicket = async () => {
      try {
        await axios.post('print_ticket', {
          id: closedAccount?.update_account_tickets_by_pk?.id,
          template: 'account'
        });
      } catch (err) {
        showSnackbar('Cuenta cerrada. Error al imprimir ticket', 'warning');
      }
    };
    if (closedAccount?.update_account_tickets_by_pk?.id) {
      printTicket();
    }
  }, [closedAccount]);

  useEffect(() => {
    if (data?.palapa_accounts[0]) {
      const { orders, commensal_type } = data.palapa_accounts[0];
      const [subtotal, total] = calculateOrderTotal(orders, commensal_type === 'owner');

      setOrderTotal(total);
      setOrderSubtotal(subtotal);
    } else {
      setOrderTotal(0);
    }
  }, [data]);

  const closeCommensalAccount = async (info: TipInfo) => {
    try {
      if (data?.palapa_accounts[0].statement_id && user?.id) {
        const { statement_id, orders, commensal_type } = data.palapa_accounts[0];
        const totalTip = -Number(info.tip);
        const isOwner = commensal_type === 'owner';
        const actions = data.palapa_accounts[0].orders.map((order) => {
          return {
            type: palapa.ORDER_CLOSED,
            order_id: order.id
          };
        });

        const orderFilters = orders.map((order) => {
          return { _and: [{ id: { _eq: order.id } }, { status: { _eq: 'open' } }] };
        });

        const dishesHelper = orders.flatMap((order) => {
          return order.order_dishes;
        });

        const dishes = dishesHelper.filter((d) => !d.disabled);

        let total: number = dishes.reduce((prevValue, currentValue) => {
          return prevValue - currentValue.dish_price * (isOwner ? ORDER_DISCOUNT : 1) * currentValue.quantity;
        }, 0);

        total += totalTip;

        const transactions: Array<TransactionsInsertInput> = orders.map((order) => {
          const orderTotal = order.order_dishes.reduce(
            (prevValue, currentValue) =>
              prevValue +
              (currentValue.disabled
                ? 0
                : currentValue.dish_price * (isOwner ? ORDER_DISCOUNT : 1) * currentValue.quantity),
            0
          );

          return {
            amount: -orderTotal,
            transaction_type: TransactionTypeEnum.Charge,
            statement_account_id: statement_id || 1,
            exchange: 1,
            currency: CurrencyTypeEnum.Mxn,
            created_by: user.id,
            is_tip: false,
            es_description: `Palapa comanda #${order.id}`,
            en_description: `Palapa order #${order.id}`
          };
        });

        if (totalTip < 0) {
          transactions.push({
            amount: totalTip,
            transaction_type: TransactionTypeEnum.Tip,
            statement_account_id: statement_id || 1,
            exchange: 1,
            currency: CurrencyTypeEnum.Mxn,
            es_description: 'Propina palapa',
            created_by: user.id,
            is_tip: true,
            en_description: 'Tip palapa'
          });
        }

        await closeAccount({
          variables: {
            ticketId: data.ticket[0].id,
            ticket: {
              open: false,
              tip: Number(info.tip),
              total,
              commensal_balance: data.palapa_accounts[0].balance - (total + Number(info.tip))
            },
            actions,
            accountId: statement_id,
            ordersFlag: {
              _or: orderFilters
            },
            transactions
          }
        });

        setDialogOpen(false);
        history.goBack();

        // TODO: Impresión de tickets. Llamada a la API
        showSnackbar('Cuenta cerrada', 'success');
      } else {
        showSnackbar('Error al cerrar cuenta.', 'error');
      }
    } catch (err) {
      showSnackbar('Ocurrió un error inespeerado.', 'error');
    }
  };

  const cancelCommensalAccount = async () => {
    try {
      const accountId = data?.palapa_accounts[0].statement_id;
      const statementId = data?.palapa_accounts[0].statement_id;
      const orders = data?.palapa_accounts[0].orders;
      if (accountId && orders && statementId) {
        const actions = orders.map((order) => {
          return {
            type: palapa.ORDER_CANCELED,
            order_id: order.id
          };
        });

        const ordersFlag = orders.map((order) => {
          return { id: { _eq: order.id } };
        });

        const dishes = orders.flatMap((order) => {
          return order.order_dishes;
        });

        const dishesFlag = dishes.map((d) => {
          return { id: { _eq: d.id } };
        });

        await cancelAccount({
          variables: {
            ticketId: data.ticket[0].id,
            accountId,
            actions,
            ordersFlag: { _or: ordersFlag },
            dishesFlag: { _or: dishesFlag }
          }
        });
        setCancelDialogOpen(false);
        showSnackbar('Cuenta cancelada', 'success');
      } else {
        showSnackbar('Error al cancelar la cuenta', 'error');
      }
    } catch (err) {
      showSnackbar('Error al cancelar la cuenta', 'error');
    }
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" spacing={2}>
      <CloseAccountDialog
        onAccept={closeCommensalAccount}
        onCancel={() => setDialogOpen(false)}
        open={dialogOpen}
        orderSubtotal={orderSubtotal}
        orderTotal={orderTotal}
        statementId={data?.palapa_accounts[0].statement_id || 0}
      />
      <CustomDialog
        fullWidth
        maxWidth="md"
        onAccept={cancelCommensalAccount}
        onCancel={() => setCancelDialogOpen(false)}
        open={cancelDialogOpen}
        title="Cancelar cuenta"
      >
        <Typography variant="body1">¿Seguro que desea cancelar la cuenta?</Typography>
      </CustomDialog>
      <Grid item xs={12}>
        <Typography variant="h5">{location.state.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <List
          subheader={<li />}
          sx={{
            width: '100%',
            position: 'relative',
            borderWidth: 1,
            borderColor: (theme) => theme.palette.divider,
            overflow: 'auto',
            '& ul': { padding: 0 }
          }}
        >
          {data?.palapa_accounts[0].orders &&
            data.palapa_accounts[0].orders.map((o, index) => (
              <AccountDetailOrder key={index} name={location.state.title} order={o} />
            ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          fullWidth
          onClick={() =>
            history.push('/accounts/details/add-order', {
              commensalId: location.state.id,
              commensalName: location.state.title,
              ticketId: data?.ticket[0].id
            })
          }
          size="large"
          variant="contained"
        >
          Agregar comanda
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button color="error" fullWidth onClick={() => setDialogOpen(true)} size="large" variant="contained">
          Cerrar cuenta
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button color="secondary" fullWidth onClick={() => setCancelDialogOpen(true)} size="large" variant="contained">
          Cancelar cuenta
        </Button>
      </Grid>
    </Grid>
  );
};

export default AccountDetails;
