import React, { useState, useEffect } from 'react';
import {
  colors,
  Box,
  Grid,
  Tabs,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tab,
  List,
  ListItem,
  Collapse,
  ListSubheader,
  ListItemText,
  IconButton
} from '@mui/material';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  ArrowRight as ArrowRightIcon,
  Close as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';

import { palapa } from 'cbconstants';
import { useSnackbar, useAxios } from 'hooks';
import { DishIngredientPicker } from 'components';
import { GetCategoriesDishesQuery, InsertOrderMutation, InsertOrderMutationVariables } from 'types/graphql';
import { formattedNumber } from 'helpers';

type Modifier = {
  title: string;
  id: number;
  selected: boolean;
};

type ModifierGroup = {
  id: number;
  title: string;
  multiple: boolean;
  modifiers: Array<Modifier>;
};

type Ingredient = {
  id: number;
  name: string;
  selected: boolean;
  enabled: boolean;
};

type Ingredients = Array<Ingredient>;
type ModifierGroups = Array<ModifierGroup>;

type Subcategory = GetCategoriesDishesQuery['menu_types'][0]['categories'][0]['subcategories'][0] & {
  open: boolean;
};

type Category = GetCategoriesDishesQuery['menu_types'][0]['categories'][0] & {
  open: boolean;
  ingredientsOpen: boolean;
  mappedSubcategories: Subcategory[];
};

type Dish = GetCategoriesDishesQuery['menu_types'][0]['categories'][0]['subcategories'][0]['dishes'][0] & {
  quantity?: number;
  notes?: string;
  ingredients?: Ingredients;
  modifierGroups?: ModifierGroups;
};

const CREATE_ORDER = gql`
  mutation InsertOrder($data: orders_insert_input!) {
    insert_orders_one(object: $data) {
      id
    }
  }
`;

const GET_DISHES_CATEGORIES = gql`
  query GetCategoriesDishes {
    menu_types(order_by: { position: asc }, where: { disabled: { _eq: false } }) {
      id
      name
      categories(order_by: { position: asc }, where: { disabled: { _eq: false } }) {
        id
        name
        subcategories(order_by: { position: asc }, where: { disabled: { _eq: false } }) {
          id
          name
          dishes {
            id
            name
            price
            cost
            description
            modifier_group_dishes {
              id
              modifier_group_id
              modifier_group {
                title
                multiple
                modifiers {
                  id
                  title
                }
              }
            }
            ingredient_dishes {
              optional
              ingredient {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const addCommandOrder = (): JSX.Element => {
  const location = useLocation<{ commensalId: number; commensalName: string; ticketId: number }>();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<Array<Category> | undefined>();
  const [value, setValue] = React.useState(1);
  const [selectedDish, setSelectedDish] = useState<Dish | null>(null);
  const [order, setOrder] = useState<Array<Dish> | null>(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const { axios } = useAxios();
  const [insertOrder, { loading: creatingOrder, data: orderData }] = useMutation<
    InsertOrderMutation,
    InsertOrderMutationVariables
  >(CREATE_ORDER);
  const { data: categoriesData, loading: loadingCategories } =
    useQuery<GetCategoriesDishesQuery>(GET_DISHES_CATEGORIES);

  useEffect(() => {
    const categories = getCategories();
    if (categories && categories.length > 0) {
      const firstCategory = categories[0].id;
      setValue(firstCategory);
    }
    setCategories(categories);
  }, [categoriesData]);

  useEffect(() => {
    if (orderData?.insert_orders_one?.id) {
      const printTicket = async () => {
        await axios.post<{ success: boolean }>('print_ticket', {
          template: 'order',
          commensal: location.state.commensalName.split('(')[0],
          id: orderData?.insert_orders_one?.id
        });
      };

      printTicket();
    }
  }, [orderData]);

  useEffect(() => {
    const categories = getCategories();
    setCategories(categories);
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getCategories = (): Array<Category> | undefined => {
    const dishes = categoriesData?.menu_types.find((m) => m.id === value);
    const mappedCategories: Category[] =
      dishes?.categories.map((c) => {
        const mappedSubcategories: Subcategory[] =
          c.subcategories.map((sc) => ({
            ...sc,
            open: false
          })) || [];
        return {
          ...c,
          open: false,
          mappedSubcategories,
          ingredientsOpen: false
        };
      }) || [];
    return mappedCategories;
  };

  const handleOrderInsert = async () => {
    try {
      if (order === null || order.length === 0) {
        showSnackbar('Seleccione platillos para continuar', 'error');
      } else {
        await insertOrder({
          variables: {
            data: {
              account_ticket_id: location.state.ticketId,
              statement_account_id: location.state.commensalId,
              status: 'open',
              total: 0,
              order_dishes: {
                data:
                  order?.map((o) => ({
                    disabled: false,
                    dish_id: o.id,
                    dish_price: o.price,
                    dish_cost: o.cost,
                    notes: o.notes || '',
                    quantity: o.quantity || 1,
                    order_dish_modifier_groups: {
                      data:
                        o.modifierGroups?.map((mg) => ({
                          modifier_group_id: mg.id,
                          order_dish_modifiers: {
                            data: mg.modifiers.filter((m) => m.selected).map((m) => ({ modifier_id: m.id })) || []
                          }
                        })) || []
                    },
                    order_dish_ingredients: {
                      data:
                        o.ingredients?.map((i) => ({
                          ingredient_id: i.id,
                          enabled: i.enabled
                        })) || []
                    }
                  })) || []
              },
              orders_actions: {
                data: [
                  {
                    type: palapa.ORDER_CREATED
                  }
                ]
              }
            }
          }
        });
      }
      showSnackbar('Comanda creada correctamente', 'success');
      history.goBack();
    } catch (err) {
      if ((err as AxiosError).isAxiosError) {
        showSnackbar('Comanda creada. Error imprimiendo ticket', 'warning');
        history.goBack();
      } else {
        showSnackbar('Error al crear comanda', 'error');
      }
    }
  };

  const handleDishSelect = (dish: Dish) => {
    if (selectedDish) {
      if (order) {
        setOrder([dish, ...order]);
      } else {
        setOrder([dish]);
      }
    }
    setPickerOpen(false);
  };

  if (loadingCategories) {
    return <p>Cargando...</p>;
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <DishIngredientPicker
          dish={selectedDish}
          onAccept={handleDishSelect}
          onClose={() => setPickerOpen(false)}
          open={pickerOpen}
        />
        <Tabs onChange={handleChange} value={value} variant="fullWidth">
          {categoriesData?.menu_types &&
            categoriesData.menu_types.map((category) => (
              <Tab key={category.id} label={category.name} value={category.id} />
            ))}
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {categories?.map((cat, cIndex) => (
            <List
              key={cat.id}
              subheader={<li />}
              sx={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                '& ul': { padding: 0 }
              }}
            >
              <li>
                <ul>
                  <ListSubheader
                    onClick={() => {
                      const arrayCopy = [...categories];
                      arrayCopy[cIndex].open = !arrayCopy[cIndex].open;
                      setCategories(arrayCopy);
                    }}
                    sx={{
                      '&:hover': { cursor: 'pointer' },
                      color: 'black',
                      bgcolor: 'inherit',
                      borderBottom: `1.8px solid ${colors.grey[900]}`
                    }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>{cat.name}</Grid>
                      <Grid item>
                        <IconButton>{cat.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                      </Grid>
                    </Grid>
                  </ListSubheader>
                  <Collapse in={cat.open}>
                    {cat.mappedSubcategories.map((sc, scIndex) => (
                      <React.Fragment key={scIndex}>
                        <ListItem
                          onClick={() => {
                            const copy = [...categories];
                            copy[cIndex].mappedSubcategories[scIndex].open =
                              !copy[cIndex].mappedSubcategories[scIndex].open;
                            setCategories(copy);
                          }}
                          secondaryAction={
                            <IconButton
                              onClick={() => {
                                const copy = [...categories];
                                copy[cIndex].mappedSubcategories[scIndex].open =
                                  !copy[cIndex].mappedSubcategories[scIndex].open;
                                setCategories(copy);
                              }}
                            >
                              {sc.open ? <DeleteIcon /> : <ArrowRightIcon />}
                            </IconButton>
                          }
                          sx={{ '&:hover': { cursor: 'pointer' } }}
                        >
                          <ListItemText>{sc.name}</ListItemText>
                        </ListItem>
                        <Collapse in={sc.open}>
                          <List sx={{ pl: 2 }}>
                            {sc.dishes.map((d, dIndex) => (
                              <ListItem
                                key={dIndex}
                                onClick={() => {
                                  setSelectedDish(d);
                                  setPickerOpen(true);
                                }}
                              >
                                <ListItemText
                                  primary={d.name}
                                  secondary={formattedNumber(d.price.toFixed(2))}
                                  sx={{ '&:hover': { cursor: 'pointer' } }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                  </Collapse>
                </ul>
              </li>
            </List>
          ))}
        </Box>
        <Box sx={{ mt: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '60px' }} />
                  <TableCell>Platillo</TableCell>
                  <TableCell align="right">Unidades</TableCell>
                  <TableCell align="right">Precio unitario</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.map((dish, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => {
                          const arrayCopy = [...order];
                          arrayCopy.splice(index, 1);
                          setOrder(arrayCopy);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{dish.name}</TableCell>
                    <TableCell align="right">{dish.quantity || 1}</TableCell>
                    <TableCell align="right">$ {formattedNumber(dish.price.toFixed(2))}</TableCell>
                    <TableCell align="right">
                      $ {formattedNumber((dish.price * (dish.quantity || 1)).toFixed(2))}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    Total:{' '}
                  </TableCell>
                  <TableCell align="right">
                    ${' '}
                    {formattedNumber(
                      order
                        ?.reduce(
                          (prevValue, currentValue) => prevValue + currentValue.price * (currentValue.quantity || 1),
                          0
                        )
                        .toFixed(2) || '0.00'
                    ) || '0.00'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <LoadingButton
            color="primary"
            fullWidth
            loading={creatingOrder}
            onClick={handleOrderInsert}
            variant="contained"
          >
            Crear comanda
          </LoadingButton>
        </Box>
      </Box>
    </div>
  );
};

export default addCommandOrder;
