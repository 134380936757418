import React, { useState, useEffect } from 'react';
import { Grid, Divider, TextField, Pagination, TableCell, TableRow } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';


import { CustomTable } from 'components';
import { GetArrivalsQuery, GetArrivalsQueryVariables } from 'types/graphql';

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];

const VISITS_PER_PAGE = 15;

const TABLE_HEADERS: TableHeaders = [
  {
    title: 'Condominio'
  },
  {
    title: 'Nombre'
  },
  {
    title: 'Fecha de llegada',
    align: 'center'
  },
  {
    title: 'Fecha de salida',
    align: 'center'
  },
  {
    title: 'Tipo'
  }
];

const GET_ARRIVALS = gql`
  query GetArrivals($searchString: String!, $offset: Int!, $limit: Int!, $startDate: date, $endDate: date) {
    arrivals_aggregate(
      where: {
        name: { _ilike: $searchString }
        apartment_name: { _ilike: $searchString }
        arrival_date: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      aggregate {
        count
      }
    }
    arrivals(
      where: {
        name: { _ilike: $searchString }
        apartment_name: { _ilike: $searchString }
        arrival_date: { _gte: $startDate, _lte: $endDate }
      }
      offset: $offset
      limit: $limit
      order_by: {arrival_date: desc}
    ) {
      id
      name
      apartment_name
      arrival_date
      departure_date
      user_id
    }
  }
`;

const Visits = (): JSX.Element => {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);
  const [searchString, setSearchString] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState({ maxPages: 1, currentPage: 1 });
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(oneMonthAgo);
  const [endDate, setEndDate] = useState<Date | null>(today);
  const { data, loading } = useQuery<GetArrivalsQuery, GetArrivalsQueryVariables>(GET_ARRIVALS, {
    variables: {
      searchString: `%${searchString}%`,
      offset: (paginationInfo.currentPage - 1) * VISITS_PER_PAGE,
      limit: VISITS_PER_PAGE,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null
    }
  });

  useEffect(() => {
    if (data) {
      const maxPages = Math.ceil((data.arrivals_aggregate.aggregate?.count || 1) / VISITS_PER_PAGE);

      setPaginationInfo({ maxPages, currentPage });
    }
  }, [data, currentPage]);

  return (
    <Grid container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid container item spacing={2} xs={10}>
            <Grid item sm={2} xs={12}>
              <DatePicker
                label="Fecha Inicial"
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={startDate}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <DatePicker
                label="Fecha Final"
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={endDate}
              />
            </Grid>
          </Grid>
          <Grid alignItems="flex-start" display="flex" item justifyContent="flex-end" xs={2}>
              <TextField
                label="Búsqueda"
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
                variant="outlined"
              />
            </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS} loading={loading}>
          {data?.arrivals.map((v, index) => (
            <TableRow key={index}>
              <TableCell>{v.apartment_name}</TableCell>
              <TableCell>{v.name}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{dayjs(v.arrival_date).format('DD/MM/YYYY')}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{dayjs(v.departure_date).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{v.user_id? 'Dueño' : 'Renta'}</TableCell>

            </TableRow>
          ))}
        </CustomTable>
      </Grid>
      <Grid alignItems="center" container item justifyContent="center" spacing={2} xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={paginationInfo.maxPages}
            onChange={(e, value) => setCurrentPage((value ))}
            page={paginationInfo.currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Visits;
