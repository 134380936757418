import { createTheme, colors } from '@mui/material';

const white = '#FFFFFF';

const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.grey[900]
    },
    secondary: {
      main: colors.grey[700],
      contrastText: white
    },
    success: {
      contrastText: white,
      dark: colors.green[900],
      main: colors.green[600],
      light: colors.green[400]
    },
    info: {
      contrastText: white,
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400]
    },
    warning: {
      contrastText: white,
      dark: colors.orange[900],
      main: colors.orange[600],
      light: colors.orange[400]
    },
    error: {
      contrastText: white,
      main: '#b00020',
      dark: '#790000',
      light: '#e94948'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
    background: {
      default: '#F4F6F8',
      paper: white
    }
  },
  components: {
    MuiTableRow: {
      defaultProps: {
        sx: {
          '&:hover': {
            backgroundColor: colors.grey[200]
          }
        }
      }
    }
  }
});

export default customTheme;
