import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useQuery, gql, useMutation } from '@apollo/client';

import { CustomDialog } from 'components';
import { useSnackbar } from 'hooks';
import { GetCurrenciesQuery, UpdateCurrencyMutation, UpdateCurrencyMutationVariables } from 'types/graphql';

const GET_CURRENCIES = gql`
  query GetCurrencies {
    exchange(where: { disabled: { _eq: false } }, order_by: { id: asc }) {
      id
      currency_key
      currency_name
      exchange
    }
  }`
;
const UPDATE_EXCHANGE = gql `
  mutation UpdateCurrency ($id: Int!, $amount: numeric!) {
  update_exchange(where: {id: {_eq: $id}}, _set: {exchange: $amount}) {
    affected_rows
  }
}
`;

interface ExchangeRateDialogProps {
  open: boolean;
  onClose: () => void;
}

const ExchangeRateDialog = ({ open, onClose }: ExchangeRateDialogProps): JSX.Element => {
  const { data: currenciesData, loading: loadingCurrencies, error, refetch } = useQuery<GetCurrenciesQuery>(GET_CURRENCIES, {
    skip: !open, // Skip the query when the dialog is closed
  });  
  const [UpdateCurrency] = useMutation<
    UpdateCurrencyMutation,
    UpdateCurrencyMutationVariables
  >(UPDATE_EXCHANGE);
  const { showSnackbar } = useSnackbar();

  const initialValues = {
    id: 0,
    currency: '',
    selectedCurrencyKey: '',
    selectedCurrencyName: '',
    selectedExchangeRate: 1,
    newExchangeRate: '',
  };

  const handleSubmission = async (values: typeof initialValues) => {
  try {
    await UpdateCurrency({
      variables: {
        id: values.id,
        amount: values.newExchangeRate
      }
    });
    showSnackbar('Moneda actualizado', 'success');
    onClose();
  }catch (err) {
    showSnackbar('Error al actualizar la moneda', 'error');
  }
  };

  useEffect(() => {
    if (open) {
      refetch(); // Refetch the currencies data when the dialog is opened
    }
  }, [open, refetch]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmission}>
      {({ values, setFieldValue, handleSubmit, resetForm }) => {
        useEffect(() => {
          if (!open) {
            resetForm(); // Reset the form when the dialog is closed
          }
        }, [open, resetForm]);

        return (
          <CustomDialog
            fullWidth
            maxWidth="md"
            onAccept={handleSubmit}
            onCancel={onClose}
            open={open}
            title="Cambiar valor de moneda"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="exchange">Moneda</InputLabel>
                  <Select
                    label="Moneda"
                    labelId="exchange"
                    onChange={(e) => {
                      const selectedCurrency = currenciesData?.exchange.find(c => c.id = Number(e.target.value));
                      if (selectedCurrency) {
                        setFieldValue('currency', e.target.value);
                        setFieldValue('selectedCurrencyKey', selectedCurrency.currency_key);
                        setFieldValue('selectedCurrencyName', selectedCurrency.currency_name);
                        setFieldValue('selectedExchangeRate', selectedCurrency.exchange);
                        setFieldValue('newExchangeRate', selectedCurrency.exchange);
                        setFieldValue('id', selectedCurrency.id);
                      }
                    }}
                    value={values.currency}
                  >
                    {currenciesData?.exchange.map((c, index) => (
                      <MenuItem key={index} value={c.id} disabled={c.currency_key === 'MXN'}>
                        {`${c.currency_name} (${c.currency_key}) ${
                          c.currency_key !== 'MXN' ? `($ ${c.exchange.toFixed(2)})` : ''
                        }`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {values.selectedCurrencyKey && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Moneda seleccionada: {values.selectedCurrencyName} ({values.selectedCurrencyKey})
                    </Typography>
                    <Typography variant="body1">
                      Valor actual: {values.selectedExchangeRate} MXN
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nuevo valor"
                      type="number"
                      onChange={(e) => setFieldValue('newExchangeRate', e.target.value)}
                      value={values.newExchangeRate}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </CustomDialog>
        );
      }}
    </Formik>
  );
};

export default ExchangeRateDialog;
