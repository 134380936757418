import React, { useState, useEffect } from 'react';
import { Grid, Typography, TableRow, TableCell, TextField, IconButton } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { FilterAlt as FilterAltIcon } from '@mui/icons-material';

import { formattedNumber } from 'helpers';
import { CustomTable } from 'components';
import { GetAllTransactionsQuery, GetAllTransactionsQueryVariables } from 'types/graphql';
import TransactionsReportsFilters from './TransactionsReportFilters';

type TransactionsBoolExp = GetAllTransactionsQueryVariables['filters'];
type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];
type Transaction = {
  id: number;
  commensalName: string;
  amount: number;
  transactionType: string;
  isTip: boolean;
  currency: string;
  createdAt: Dayjs;
  apartmentName: string;
  description: string;
};

const GET_TRANSACTIONS = gql`
  query GetAllTransactions($filters: transactions_helper_bool_exp!) {
    transactions: transactions_helper(where: $filters, order_by: { created_at: desc }) {
      transaction_id
      commensal_name
      apartment_name
      es_description
      amount
      is_tip
      date
      time
      currency
      transaction_type
      created_at
    }
  }
`;

const TABLE_HEADERS: TableHeaders = [
  {
    title: 'Folio'
  },
  {
    title: 'Tipo de movimiento'
  },
  {
    title: 'Cuenta'
  },
  {
    title: 'Fecha y hora'
  },
  {
    title: 'Monto',
    align: 'right'
  }
];

const TransactionsReport = (): JSX.Element => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [filtersDialog, setFiltersDialog] = useState<boolean>(false);
  const [queryFilters, setQueryFilters] = useState<TransactionsBoolExp>({});
  const [transactionsTotal, setTransactionsTotal] = useState<number>(0);
  const { data } = useQuery<GetAllTransactionsQuery, GetAllTransactionsQueryVariables>(GET_TRANSACTIONS, {
    pollInterval: 1000,
    variables: {
      filters: {
        _or: [{ commensal_name: { _ilike: `%${searchString}%` } }, { apartment_name: { _ilike: `%${searchString}%` } }],
        ...queryFilters
      }
    }
  });

  useEffect(() => {
    if (data?.transactions) {
      const mappedTransactions: Transaction[] =
        data.transactions.map((t) => ({
          id: t.transaction_id || 1,
          commensalName: `${t.apartment_name} ${t.commensal_name}` || '',
          apartmentName: t.apartment_name || '',
          transactionType: t.transaction_type || '',
          amount: Math.abs(t.amount) || 0,
          createdAt: dayjs(t.created_at),
          currency: t.currency || '',
          description: t.es_description || '',
          isTip: t.is_tip || false
        })) || [];

      setTransactions(mappedTransactions);

      const reducedTransactions = mappedTransactions.reduce((acc, t) => acc + Math.abs(t.amount), 0);
      setTransactionsTotal(reducedTransactions);
    }
  }, [data]);

  return (
    <>
      <TransactionsReportsFilters
        onAccept={() => setFiltersDialog(false)}
        onApply={(filters) => setQueryFilters(filters)}
        onCancel={() => setFiltersDialog(false)}
        open={filtersDialog}
      />
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item>
          <Typography variant="body2">Movimientos</Typography>
        </Grid>
        <Grid item>
          <TextField label="Búsqueda" onChange={(e) => setSearchString(e.target.value)} value={searchString} />
          <IconButton onClick={() => setFiltersDialog(true)} sx={{ ml: 2, mt: 1 }}>
            <FilterAltIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS}>
          <>
            {transactions.map((t) => (
              <TableRow key={t.id}>
                <TableCell># {t.id}</TableCell>
                <TableCell>{t.description}</TableCell>
                <TableCell>{t.commensalName}</TableCell>
                <TableCell>{t.createdAt.format('DD/MM/YYYY hh:mm:ss a')}</TableCell>
                <TableCell align="right">$ {formattedNumber(t.amount.toFixed(2))}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right" colSpan={TABLE_HEADERS.length - 1}>
                Total
              </TableCell>
              <TableCell align="right" colSpan={TABLE_HEADERS.length - 1}>
                $ {formattedNumber(transactionsTotal.toFixed(2))}
              </TableCell>
            </TableRow>
          </>
        </CustomTable>
      </Grid>
    </>
  );
};

export default TransactionsReport;
