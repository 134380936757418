import React, { useState, useEffect } from 'react';
import { Pagination, Grid, TextField, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery, gql, useMutation } from '@apollo/client';
import PrintIcon from '@mui/icons-material/Print';
import { useSnackbar } from 'context';

import TransactionRow from './TransactionRow';
import { CustomTable } from 'components';
import { GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables, StatementPdfMutation, StatementPdfMutationVariables} from 'types/graphql';

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];

interface AccountStatementProps {
  statementId: number;
}

const TABLE_HEADERS: TableHeaders = [
  {
    title: 'Descripción'
  },
  {
    title: 'Fecha y hora'
  },
  {
    title: 'Tipo'
  },
  {
    title: 'Total',
    align: 'right'
  },
  {
    title: '',
    width: '5%'
  }
];

const TRANSACTIONS_PER_PAGE = 20;

const GET_STATEMENT_PDF = gql`
mutation MyMutation ($statement_account_id: Int!, $format: String!, $startDate: timestamptz, $endDate: timestamptz) {
  GenerateStatementPdf(finalDate: $endDate, folio: $statement_account_id, format: $format, initDate: $startDate){
    pdfContent
  }
}
`;

const GET_COMMENSAL_TRANSACTIONS = gql`
  query GetCommensalTransactions($id: Int!, $limit: Int!, $offset: Int!, $startDate: timestamptz, $endDate: timestamptz) {
    transactions_aggregate(where: { statement_account_id: { _eq: $id }, created_at: { _gte: $startDate, _lte: $endDate } }) {
      aggregate {
        count
      }
    }
    transactions(
      where: { statement_account_id: { _eq: $id }, created_at: { _gte: $startDate, _lte: $endDate } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
    ) {
      transaction_id
      es_description
      statement_account_id
      amount
      created_at
      canceled
      transaction_type
      exchange
      user {
        first_name
        last_name
      }
      currency
    }
  }
`;

const AccountStatement = ({ statementId }: AccountStatementProps): JSX.Element => {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);
  const { showSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(oneMonthAgo);
  const [endDate, setEndDate] = useState<Date | null>(today);

  const [generateStatementPdf] = useMutation<StatementPdfMutation, StatementPdfMutationVariables>(GET_STATEMENT_PDF);
  const { data, loading, refetch } = useQuery<GetCommensalTransactionsQuery, GetCommensalTransactionsQueryVariables>(
    GET_COMMENSAL_TRANSACTIONS,
    {
      variables: {
        id: statementId,
        offset: (currentPage - 1) * TRANSACTIONS_PER_PAGE,
        limit: TRANSACTIONS_PER_PAGE,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null
      },
      pollInterval: 1000
    }
  );

  useEffect(() => {
    const maxTransactions = data?.transactions_aggregate.aggregate?.count;
    if (maxTransactions) {
      setMaxPages(Math.ceil(maxTransactions / TRANSACTIONS_PER_PAGE));
    } else {
      setMaxPages(1);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      id: statementId,
      offset: (currentPage - 1) * TRANSACTIONS_PER_PAGE,
      limit: TRANSACTIONS_PER_PAGE,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null
    });
  }, [startDate, endDate, currentPage]);

  const base64ToBlob = (base64, type) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  };

  const handlePrintClick = async () => {
    try {
      const response = await generateStatementPdf({
        variables: {
          statement_account_id: statementId,
          format: 'statementpdf',
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString()
        }
      });    
          const pdfContent = response?.data?.GenerateStatementPdf?.pdfContent;
          const pdfBlob = base64ToBlob(pdfContent, 'application/pdf');
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank');
          showSnackbar('PDF generado correctamente' , 'success');
        } catch (error) {
            showSnackbar('Error al generar el PDF', 'error');
        }   
    };

    return (
      <Grid alignItems="center" container item justifyContent="center" spacing={2} sx={{ pt: 2 }} xs={12}>
        <Grid alignItems="center" container item spacing={2} xs={12}>
          <Grid container item spacing={2} xs={10}>
            <Grid item sm={2} xs={12}>
              <DatePicker
                label="Fecha Inicial"
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={startDate}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <DatePicker
                label="Fecha Final"
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={endDate}
              />
            </Grid>
          </Grid>
          <Grid alignItems="flex-start" display="flex" item justifyContent="flex-end" xs={2}>
            <IconButton color="primary" onClick={handlePrintClick}>
              <PrintIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTable headers={TABLE_HEADERS} loading={loading}>
            {data?.transactions.map((t, index) => (
              <TransactionRow key={index} transaction={t} />
            ))}
          </CustomTable>
        </Grid>
        <Grid item>
          <Pagination
            color="primary"
            count={maxPages}
            onChange={(_, page) => setCurrentPage(page)}
            page={currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    );
    
    
};

export default AccountStatement;
