import React, { useState } from 'react';
import { TableCell, Box, TableRow, IconButton, Menu, MenuItem, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';

import { useSnackbar, useHasuraUser } from 'hooks';
import { CustomDialog } from 'components';
import { formattedNumber } from 'helpers';
import {
  GetCommensalTransactionsQuery,
  CancelTransactionMutationVariables,
  Currency_Type_Enum,
  Transaction_Type_Enum, 
  PrintReportMutation, 
  PrintReportMutationVariables
} from 'types/graphql';

type Transaction = GetCommensalTransactionsQuery['transactions'][0];

interface TransactionRowProps {
  transaction: Transaction;
}

const CANCEL_TRANSACTION = gql`
  mutation CancelTransaction($id: Int!, $data: transactions_insert_input!) {
    insert_transactions_one(object: $data) {
      transaction_id
    }
    update_transactions_by_pk(pk_columns: { transaction_id: $id }, _set: { canceled: true }) {
      transaction_id
      canceled
    }
  }
`;

const PRINT_TICKET = gql `
  mutation printTicket ($format: String!, $id: Int!) {
  PrintTicket(format: $format, folio: $id) {
    message
    success
  }
}
`;

const TransactionRow = ({ transaction }: TransactionRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [infoDialog, setInfoDialog] = useState<boolean>(false);
  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const [printDialog, setPrintDialog] = useState<boolean>(false)
  const { showSnackbar } = useSnackbar();
  const { user } = useHasuraUser();
  const [cancelTransaction] = useMutation<unknown, CancelTransactionMutationVariables>(CANCEL_TRANSACTION);
  const [printTicket] = useMutation<PrintReportMutation, PrintReportMutationVariables>(PRINT_TICKET);

  const menuOpen = Boolean(anchorEl);

  const getTransactionType = (type: string): string => {
    return type === 'Charge'
      ? 'Consumo'
      : type === 'Deposit'
      ? 'Depósito'
      : type === 'Refund'
      ? 'Reembolso'
      : type === 'Cancellation'
      ? 'Cancelación'
      : type === 'Tip'
      ? 'Propina Palapa'
      : 'Traspaso';
  };

  const handlePrintClick = async (): Promise<void> => {
    try{
     await printTicket({
      variables: {
        id: transaction.transaction_id,
        date: '',
        format: transaction.transaction_type
      }
     });
     showSnackbar('Ticket Impreso', 'success');
     setPrintDialog(false);
    } catch (err) {
      showSnackbar('Error al reimprimir el ticket', 'error');
    }
  }

  const handleTransactionCancel = async (): Promise<void> => {
    try {
      await cancelTransaction({
        variables: {
          id: transaction.transaction_id,
          data: {
            created_by: user?.id,
            amount: -transaction.amount,
            currency: Currency_Type_Enum.Mxn,
            transaction_type: Transaction_Type_Enum.Cancellation,
            statement_account_id: transaction.statement_account_id,
            is_tip: false,
            en_description: 'Cancellation',
            es_description: 'Cancelación'
          }
        }
      });
      showSnackbar('Movimiento cancelado', 'success');
      setCancelDialog(false);
    } catch (err) {
      showSnackbar('Error al cancelar movimiento', 'error');
    }
  };

  return (
    <TableRow>
      {/* Diálogo de cancelación de movimiento */}
      <CustomDialog
        fullWidth
        maxWidth="sm"
        onAccept={handleTransactionCancel}
        onCancel={() => setCancelDialog(false)}
        open={cancelDialog}
        title="Cancelar movimiento"
      >
        <Grid alignItems="center" container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1">¿Cancelar movimiento?</Typography>
          </Grid>
        </Grid>
      </CustomDialog>

      {/* Diálogo de información de movimiento */}
      <CustomDialog
        fullWidth
        maxWidth="sm"
        onAccept={() => setInfoDialog(false)}
        onCancel={() => setInfoDialog(false)}
        open={infoDialog}
        title="Detalles de movimiento"
      >
        <Grid alignItems="center" container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                Registrado por: &nbsp;
                <Box
                  component="span"
                  sx={{ fontWeight: 'normal' }}
                >{`${transaction.user.first_name} ${transaction.user.last_name}`}</Box>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                Fecha y hora de creación: &nbsp;
                <Box component="span" sx={{ fontWeight: 'normal' }}>
                  {dayjs(transaction.created_at).format('DD/MM/YYYY hh:mm:ss a')}
                </Box>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </CustomDialog>

      {/* Diálogo de para re-imprimir ticket*/}
      <CustomDialog
        maxWidth="sm"
        onAccept={handlePrintClick}
        onCancel={() => setPrintDialog(false)}
        open={printDialog}
        title="Reimpresion de Ticket"
      >
        <Grid alignItems="center" container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1">¿Imprimir esta transacción?</Typography>
          </Grid>
        </Grid>
      </CustomDialog>

      <TableCell sx={{ color: (theme) => (transaction.canceled ? theme.palette.error.main : '') }}>
        {transaction.es_description}
      </TableCell>
      <TableCell sx={{ color: (theme) => (transaction.canceled ? theme.palette.error.main : '') }}>
        {dayjs(transaction.created_at).format('DD/MM/YY hh:mm:ss a')}
      </TableCell>
      <TableCell sx={{ color: (theme) => (transaction.canceled ? theme.palette.error.main : '') }}>
        {getTransactionType(transaction.transaction_type)}
      </TableCell>
      <TableCell align="right" sx={{ color: (theme) => (transaction.canceled ? theme.palette.error.main : '') }}>
        {formattedNumber(((transaction.amount * transaction.exchange) as number).toFixed(2))}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={menuOpen}>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setInfoDialog(true);
            }}
          >
            Detalles
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setPrintDialog(true);
            }}
          >
            Imprimir
          </MenuItem>
          <MenuItem
            disabled={transaction.canceled}
            onClick={() => {
              setAnchorEl(null);
              setCancelDialog(true);
            }}
          >
            {transaction.canceled ? 'Cancelado' : 'Cancelar'}
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default TransactionRow;
