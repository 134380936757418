import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, TextField, CircularProgress } from '@mui/material';
import { useQuery, useMutation, gql } from '@apollo/client';

import { useSnackbar } from 'hooks';
import { CustomDialog } from 'components';
import { CategorieContainer } from './components';
import {
  GetMenusQuery,
  InsertMenuMutation,
  InsertMenuMutationVariables,
  InsertCategoryMutation,
  InsertCategoryMutationVariables,
  InsertSubcategoryMutation,
  InsertSubcategoryMutationVariables,
  UpdateMenuMutation,
  UpdateMenuMutationVariables,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
  UpdateSubcategoryMutation,
  UpdateSubcategoryMutationVariables
} from 'types/graphql';

type Category = {
  id: number;
  title: string;
  position: number;
  disabled: boolean;
  hideMenu?: boolean;
  editing?: boolean;
};

type CategoryType = 'menus' | 'categories' | 'subcategories';

type CategoriesInfo = {
  menus: Category[];
  categories: Category[];
  subcategories: Category[];
};

type Values = {
  menu: number;
  category: number;
  subcategory: number;
};

const INSERT_MENU = gql`
  mutation InsertMenu($data: menu_types_insert_input!) {
    insert_menu_types_one(object: $data) {
      id
    }
  }
`;

const INSERT_CATEGORY = gql`
  mutation InsertCategory($data: categories_insert_input!) {
    insert_categories_one(object: $data) {
      id
    }
  }
`;

const INSERT_SUBCATEGORY = gql`
  mutation InsertSubcategory($data: subcategories_insert_input!) {
    insert_subcategories_one(object: $data) {
      id
    }
  }
`;

const GET_MENUS = gql`
  query GetMenus {
    menus: menu_types(order_by: { position: asc }) {
      id
      name
      position
      disabled
      categories(order_by: { position: asc }) {
        id
        name
        position
        disabled
        subcategories(order_by: { position: asc }) {
          id
          name
          position
          disabled
        }
      }
    }
  }
`;

const UPDATE_MENU = gql`
  mutation UpdateMenu($id: Int!, $data: menu_types_set_input!) {
    update_menu_types_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: Int!, $data: categories_set_input!) {
    update_categories_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

const UPDATE_SUBCATEGORY = gql`
  mutation UpdateSubcategory($id: Int!, $data: subcategories_set_input!) {
    update_subcategories_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

let currentIndex = -1;
let lastIndex = -1;
let categoryHelper: Category = { id: 0, title: '', position: 0, disabled: false, hideMenu: true };

const Categories = (): JSX.Element => {
  const [addMenuDialog, setAddMenuDialog] = useState(false);
  const [categoriesInfo, setCategoriesInfo] = useState<CategoriesInfo>({
    menus: [],
    categories: [],
    subcategories: []
  });
  const [menuName, setMenuName] = useState('');
  const [insertion, setInsertion] = useState<CategoryType>('menus');
  const [selectedValues, setSelectedValues] = useState<Values>({ menu: 1, category: 1, subcategory: 1 });
  const { data, loading } = useQuery<GetMenusQuery>(GET_MENUS, { pollInterval: 1000 });
  const [insertMenu] = useMutation<InsertMenuMutation, InsertMenuMutationVariables>(INSERT_MENU);
  const [insertCategory] = useMutation<InsertCategoryMutation, InsertCategoryMutationVariables>(INSERT_CATEGORY);
  const [updateMenu] = useMutation<UpdateMenuMutation, UpdateMenuMutationVariables>(UPDATE_MENU);
  const [updateCategory] = useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UPDATE_CATEGORY);
  const { showSnackbar } = useSnackbar();
  const [insertSubcategory] = useMutation<InsertSubcategoryMutation, InsertSubcategoryMutationVariables>(
    INSERT_SUBCATEGORY
  );
  const [updateSubcategory] = useMutation<UpdateSubcategoryMutation, UpdateSubcategoryMutationVariables>(
    UPDATE_SUBCATEGORY
  );

  useEffect(() => {
    if (data) {
      const firstMenu = data.menus[0].id;
      const firstCategory = data.menus[0].categories[0].id;
      const firstSubcategory = data.menus[0].categories[0]?.subcategories[0]?.id || 1;

      const mappedMenus: Category[] = data.menus.map((m) => ({
        id: m.id,
        title: m.name,
        position: m.position,
        disabled: m.disabled,
        editing: false
      }));
      const mappedCategories = getCategories(firstMenu);
      const mappedSubcategories = getSubcategories(firstMenu, firstCategory);

      setSelectedValues({
        menu: firstMenu,
        category: firstCategory,
        subcategory: firstSubcategory
      });
      setCategoriesInfo({
        menus: mappedMenus,
        categories: mappedCategories || [],
        subcategories: mappedSubcategories || []
      });
    }
  }, [data]);

  const getCategories = (menuId: number): Array<Category> | undefined => {
    const selectedMenu = data?.menus.find((m) => m.id === menuId);
    const categories: Category[] | undefined = selectedMenu?.categories.map((c) => ({
      id: c.id,
      title: c.name,
      disabled: c.disabled,
      position: c.position
    }));

    return categories;
  };

  const getSubcategories = (menuId: number, categoryId: number): Array<Category> | undefined => {
    const selectedCategory = data?.menus.find((m) => m.id === menuId)?.categories.find((c) => c.id === categoryId);
    const mappedSubcategories: Array<Category> | undefined = selectedCategory?.subcategories.map((sc) => ({
      id: sc.id,
      title: sc.name,
      position: sc.position,
      disabled: sc.disabled
    }));

    return mappedSubcategories;
  };

  const toggleMenuDialog = (type: CategoryType) => {
    setMenuName('');
    setInsertion(type);
    setAddMenuDialog((prevState) => !prevState);
  };

  const handleCategorieChange = (title: CategoryType, id: number): void => {
    switch (title) {
      case 'menus': {
        const categories = getCategories(id) || [];
        if (categories && categories.length >= 1) {
          const subcategories = getSubcategories(id, categories[0].id) || [];
          setSelectedValues({
            menu: id,
            category: categories[0].id,
            subcategory: subcategories[0]?.id || 1
          });
          setCategoriesInfo((prevState) => ({ ...prevState, categories, subcategories }));
        } else {
          setCategoriesInfo((prevState) => ({ ...prevState, categories, subcategories: [] }));
          setSelectedValues((prevState) => ({ ...prevState, menu: id }));
        }
        setCategoriesInfo((prevState) => ({ ...prevState, categories }));
        break;
      }
      case 'categories': {
        const subcategories = getSubcategories(selectedValues.menu, id) || [];
        const firstSubcategory = subcategories[0]?.id || 1;

        setSelectedValues((prevState) => ({ ...prevState, category: id, subcategory: firstSubcategory }));
        setCategoriesInfo((prevState) => ({ ...prevState, subcategories }));
        break;
      }
      case 'subcategories': {
        setSelectedValues((prevState) => ({ ...prevState, subcategory: id }));
        break;
      }
    }
  };

  const handleSubmission = async (): Promise<void> => {
    try {
      switch (insertion) {
        case 'menus': {
          await insertMenu({
            variables: {
              data: {
                name: menuName
              }
            }
          });
          break;
        }
        case 'categories': {
          await insertCategory({
            variables: {
              data: {
                name: menuName,
                menu_type_id: selectedValues.menu
              }
            }
          });
          break;
        }
        case 'subcategories': {
          await insertSubcategory({
            variables: {
              data: {
                name: menuName,
                category_id: selectedValues.category
              }
            }
          });
          break;
        }
      }
      showSnackbar(
        `${insertion === 'menus' ? 'Menú' : insertion === 'categories' ? 'Categoría' : 'Subcategoría'} agregado`,
        'success'
      );
      setMenuName('');
    } catch (err) {
      showSnackbar('Error al registrar menú', 'error');
    }
  };

  const handleDragStart = (type: CategoryType, index: number) => {
    categoryHelper = categoriesInfo[type][index];
  };

  const handleDragOver = (type: CategoryType, index: number) => {
    if (currentIndex !== index) {
      lastIndex = currentIndex;
      currentIndex = index;
      const arrayCopy = [...categoriesInfo[type]];

      arrayCopy[lastIndex] = arrayCopy[currentIndex];
      arrayCopy[currentIndex] = categoryHelper;

      setCategoriesInfo((prevState) => {
        const state = { ...prevState };
        state[type] = arrayCopy;

        return state;
      });
    }
  };

  const handleDragEnd = async (type: CategoryType): Promise<void> => {
    currentIndex = -1;
    lastIndex = -1;

    const updates: Promise<unknown>[] = [];
    const arrayCopy = [...categoriesInfo[type]];

    arrayCopy.forEach((c, index) => {
      c.position = index + 1;
      const mappedData = {
        ...c,
        title: undefined,
        editing: undefined,
        name: c.title
      };

      switch (type) {
        case 'menus': {
          updates.push(updateMenu({ variables: { id: c.id, data: mappedData } }));
          break;
        }
        case 'categories': {
          updates.push(updateCategory({ variables: { id: c.id, data: mappedData } }));
          break;
        }
        case 'subcategories': {
          updates.push(updateSubcategory({ variables: { id: c.id, data: mappedData } }));
          break;
        }
      }
    });

    await Promise.all(updates);
  };

  const handleCategoryEdit = (type: CategoryType, categories: Array<Category>): void => {
    setCategoriesInfo((prevState) => ({
      ...prevState,
      [type]: categories
    }));
  };

  const handleCategoryToggle = async (type: CategoryType, category: Category | null): Promise<void> => {
    try {
      if (category) {
        const copy = [...categoriesInfo[type]];
        const index = copy.findIndex((c) => c.id === category.id);

        switch (type) {
          case 'menus': {
            await updateMenu({
              variables: {
                id: category.id,
                data: {
                  disabled: !category.disabled
                }
              }
            });
            break;
          }

          case 'categories': {
            await updateCategory({
              variables: {
                id: category.id,
                data: {
                  disabled: !category.disabled
                }
              }
            });
            break;
          }

          case 'subcategories': {
            await updateSubcategory({
              variables: {
                id: category.id,
                data: {
                  disabled: !category.disabled
                }
              }
            });
            break;
          }
        }

        copy[index].disabled = !copy[index].disabled;
        setCategoriesInfo((prevState) => ({
          ...prevState,
          [type]: copy
        }));

        showSnackbar('Estado actualizado', 'success');
      }
    } catch (err) {
      showSnackbar('Error al actualizar estado', 'error');
    }
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      {loading ? (
        <Grid alignItems="center" container item justifyContent="center" xs={12}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <CustomDialog
            fullWidth
            maxWidth="sm"
            onAccept={handleSubmission}
            onCancel={toggleMenuDialog}
            open={addMenuDialog}
            title={`Agregar ${
              insertion === 'menus' ? 'menú' : insertion === 'categories' ? 'categoría' : 'subcategoría'
            }`}
          >
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12}>
                <TextField fullWidth label="Nombre" onChange={(e) => setMenuName(e.target.value)} value={menuName} />
              </Grid>
            </Grid>
          </CustomDialog>
          <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
            <Grid item>
              <Typography variant="h6">Menús y categorías</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={4}>
              <CategorieContainer
                categories={categoriesInfo.menus}
                handleDragEnd={handleDragEnd}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                onAddCategoryClick={toggleMenuDialog}
                onCategoryClick={handleCategorieChange}
                onEditClick={handleCategoryEdit}
                onStatusToggle={handleCategoryToggle}
                selectedCategory={selectedValues.menu}
                title="Menús"
              />
            </Grid>
            <Grid item xs={4}>
              <CategorieContainer
                categories={categoriesInfo.categories}
                handleDragEnd={handleDragEnd}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                onAddCategoryClick={toggleMenuDialog}
                onCategoryClick={handleCategorieChange}
                onEditClick={handleCategoryEdit}
                onStatusToggle={handleCategoryToggle}
                selectedCategory={selectedValues.category}
                title="Categorías"
              />
            </Grid>
            <Grid item xs={4}>
              <CategorieContainer
                categories={categoriesInfo.subcategories}
                handleDragEnd={handleDragEnd}
                handleDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                onAddCategoryClick={toggleMenuDialog}
                onCategoryClick={handleCategorieChange}
                onEditClick={handleCategoryEdit}
                onStatusToggle={handleCategoryToggle}
                selectedCategory={selectedValues.subcategory}
                title="Subcategorías"
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Categories;
