import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  Divider,
  Checkbox,
  Box,
  CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery, gql } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';

import { CustomDialog } from 'components';
import { Transaction_Type_Enum, GetAllTransactionsQueryVariables } from 'types/graphql';

const GET_APARTMENTS = gql`
  query MyQuery {
    apartments_helper {
      apartment_name
    }
  }
`;

type TransactionsBoolExp = GetAllTransactionsQueryVariables['filters'];
type FormikState = {
  dates: {
    enabled: boolean;
    from: Dayjs;
    to: Dayjs;
  };
  transactionType: {
    enabled: boolean;
    value: Transaction_Type_Enum;
  };
  apartment_name: {
    enabled: boolean;
    value: string;
  };
};

interface TransactionsReportFiltersProps {
  onApply: (filters: TransactionsBoolExp) => void;
  open: boolean;
  onCancel: (...args: any[]) => void;
  onAccept: (...args: any[]) => void;
}

const initialFormikState: FormikState = {
  dates: {
    enabled: false,
    from: dayjs(),
    to: dayjs().add(1, 'day')
  },
  transactionType: {
    enabled: false,
    value: Transaction_Type_Enum.Deposit
  },
  apartment_name: {
    enabled: false,
    value: ''
  }
};

const TransactionsReportFilters = ({
  onApply,
  open,
  onCancel,
  onAccept
}: TransactionsReportFiltersProps): JSX.Element => {
  const { data, loading } = useQuery(GET_APARTMENTS);
  const [apartmentNames, setApartmentNames] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      setApartmentNames(data.apartments_helper.map((apt: { apartment_name: string }) => apt.apartment_name));
    }
  }, [data]);

  const handleSubmission = (values: FormikState) => {
    const finalFilters: TransactionsBoolExp = {
      _and: [
        values.dates.enabled ? { date: { _gte: values.dates.from.format('YYYY-MM-DD') } } : {},
        values.dates.enabled ? { date: { _lte: values.dates.to.format('YYYY-MM-DD') } } : {},
        values.transactionType.enabled ? { transaction_type: { _eq: values.transactionType.value } } : {},
        values.apartment_name.enabled ? { apartment_name: { _eq: values.apartment_name.value } } : {}
      ]
    };

    onApply(finalFilters);
    onAccept();
  };

  return (
    <Formik initialValues={initialFormikState} onSubmit={handleSubmission}>
      {({ values, setFieldValue, handleSubmit }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={() => handleSubmit()}
          onCancel={onCancel}
          open={open}
          title="Filtros"
        >
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Rango de fechas</Typography>
            </Grid>
            <Grid alignItems="center" container item spacing={2} xs={12}>
              <Grid item justifyItems="center" xs={2}>
                <Box display="flex">
                  <Checkbox
                    checked={values.dates.enabled}
                    onChange={(e) => setFieldValue('dates.enabled', e.target.checked)}
                    sx={{ width: '98%' }}
                  />
                  <Divider flexItem orientation="vertical" />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  disabled={!values.dates.enabled}
                  inputFormat="dd/MM/yyyy"
                  label="Desde"
                  maxDate={dayjs()}
                  onChange={(date) => setFieldValue('dates.from', dayjs(date))}
                  renderInput={(props) => <TextField {...props} fullWidth label="Desde" />}
                  value={values.dates.from}
                />
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  disabled={!values.dates.enabled}
                  inputFormat="dd/MM/yyyy"
                  label="Hasta"
                  maxDate={dayjs()}
                  minDate={values.dates.from}
                  onChange={(date) => setFieldValue('dates.to', dayjs(date))}
                  renderInput={(props) => <TextField {...props} fullWidth label="Hasta" />}
                  value={values.dates.to}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Tipo de movimiento</Typography>
            </Grid>
            <Grid alignItems="center" container item spacing={2} xs={12}>
              <Grid item justifyItems="center" xs={2}>
                <Box display="flex">
                  <Checkbox
                    checked={values.transactionType.enabled}
                    onChange={(e) => setFieldValue('transactionType.enabled', e.target.checked)}
                    sx={{ width: '98%' }}
                  />
                  <Divider flexItem orientation="vertical" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <TextField
                    disabled={!values.transactionType.enabled} 
                    label="Tipo de movimiento"
                    onChange={(e) => setFieldValue('transactionType.value', e.target.value)}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    value={values.transactionType.value}
                  >
                    <option value={Transaction_Type_Enum.Deposit}>Depósitos</option>
                    <option value={Transaction_Type_Enum.Refund}>Reembolsos</option>
                    <option value={Transaction_Type_Enum.Cancellation}>Cancelaciones</option>
                    <option value={Transaction_Type_Enum.Charge}>Cargos</option>
                    <option value={Transaction_Type_Enum.Transfer}>Traspasos</option>
                    <option value={Transaction_Type_Enum.Tip}>Propinas</option>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Condominio</Typography>
            </Grid>
            <Grid alignItems="center" container item spacing={2} xs={12}>
              <Grid item justifyItems="center" xs={2}>
                <Box display="flex">
                  <Checkbox
                    checked={values.apartment_name.enabled}
                    onChange={(e) => setFieldValue('apartment_name.enabled', e.target.checked)}
                    sx={{ width: '98%' }}
                  />
                  <Divider flexItem orientation="vertical" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  disabled={!values.apartment_name.enabled}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    setFieldValue('apartment_name.value', newValue);
                  }}
                  options={apartmentNames}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Condominio"
                    />
                  )}
                  value={values.apartment_name.value}
                />
              </Grid>
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default TransactionsReportFilters;
