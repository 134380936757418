import { useState, useEffect } from 'react';

interface AutoSave<V> {
  autoSaveValue: V | undefined;
  getAutoSaveData: () => V | undefined;
  setAutoSaveData: (value: V) => void;
  destroyAutoSaveData: () => void;
}

const useAutoSave = <T>(key: string): AutoSave<T> => {
  const [autoSaveValue, setValue] = useState<T>();

  useEffect(() => {
    const minifiedItem = localStorage.getItem(key);
    if (minifiedItem) {
      const item = JSON.parse(minifiedItem) as T;
      setValue(item);
    }
  }, []);

  const get = (): T | undefined => {
    const minifiedItem = localStorage.getItem(key);
    if (minifiedItem) {
      const item = JSON.parse(minifiedItem) as T;
      setValue(item);
      return item;
    }
    return undefined;
  };

  const set = (value: T): void => {
    const minifiedValue = JSON.stringify(value);
    localStorage.setItem(key, minifiedValue);

    setValue(value);
  };

  const destroyAutoSaveData = () => {
    localStorage.removeItem(key);
  };

  return { autoSaveValue, getAutoSaveData: get, setAutoSaveData: set, destroyAutoSaveData };
};

export default useAutoSave;
