import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useFirebaseAuth } from 'hooks';
import { GetUserIdQuery, GetUserIdQueryVariables } from 'types/graphql';

type HasuraUser = {
  id: number;
};

type HasuraUserHook = {
  user: HasuraUser | null;
};

const GET_USER_ID = gql`
  query GetUserId($firebaseId: String!) {
    users(where: { uid: { _eq: $firebaseId } }) {
      id
    }
  }
`;

const useHasuraUser = (): HasuraUserHook => {
  const { user } = useFirebaseAuth();
  const [hasuraUser, setHasuraUser] = useState<HasuraUser | null>(null);
  const { data } = useQuery<GetUserIdQuery, GetUserIdQueryVariables>(GET_USER_ID, {
    variables: { firebaseId: user.uid }
  });

  useEffect(() => {
    if (data?.users && data.users.length >= 1) {
      const [dbUser] = data.users;

      setHasuraUser({ id: dbUser.id });
    }
  }, [data]);

  return { user: hasuraUser };
};

export default useHasuraUser;
