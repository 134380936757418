import React, { useState, useEffect } from 'react';
import { Grid, Divider, Typography, Box, TextField, TableRow, TableCell, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { gql, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';

import { CustomTable, CustomDialog } from 'components';
import { formattedNumber } from 'helpers';
import { GetAllBalancesQuery, GetAllBalancesQueryVariables } from 'types/graphql';

type Filters = {
  searchString: string;
  date: Dayjs;
  showDate: boolean;
};

type MappedAccount = {
  id: number;
  commensalName: string;
  apartmentName: string;
  balance: number;
  present: boolean;
};

type TableHeaders = Parameters<typeof CustomTable>[0]['headers'];

const GET_BALANCES = gql`
  query GetAllBalances($searchString: String!, $filteredDate: date!) {
    transactions: transactions_helper(
      where: {
        _and: [
          {
            _or: [{ commensal_name: { _ilike: $searchString } }, { apartment_name: { _ilike: $searchString } }]
            date: { _lte: $filteredDate }
          }
        ]
      }
      order_by: [{ apartment_building: asc }, { apartment_number: asc }]
    ) {
      transaction_id
      statement_account_id
      apartment_name
      amount
      commensal_name
      canceled
      transaction_type
      exchange
    }
  }
`;

const TABLE_HEADERS: TableHeaders = [
  {
    title: 'Condominio',
    width: '20%'
  },
  {
    title: 'Nombre'
  },
  {
    title: 'Saldo',
    width: '25%',
    align: 'right'
  }
];

const BalancesReport = (): JSX.Element => {
  const [filtersDialog, setFiltersDialog] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({ searchString: '', showDate: false, date: dayjs() });
  const [accounts, setAccounts] = useState<MappedAccount[]>([]);
  // TODO: Generar tipos para esta query
  const { data } = useQuery<GetAllBalancesQuery, GetAllBalancesQueryVariables>(GET_BALANCES, {
    variables: { searchString: `%${filters.searchString}%`, filteredDate: filters.date.format('YYYY-MM-DD') },
    pollInterval: 1000
  });

  useEffect(() => {
    filterAccounts();
  }, [data, filters]);

  const filterAccounts = () => {
    if (data?.transactions) {
      const mappedAccounts: MappedAccount[] = [];
      data.transactions.forEach((t: any) => {
        const index = mappedAccounts.findIndex((ac) => ac.id === t.statement_account_id);

        if (index < 0) {
          mappedAccounts.push({
            id: t.statement_account_id,
            commensalName: t.commensal_name,
            apartmentName: t.apartment_name,
            balance: t.amount,
            present: true
          });
        } else {
          mappedAccounts[index].balance += t.amount;
        }
      });

      setAccounts(mappedAccounts);
    }
  };

  return (
    <>
      <CustomDialog
        fullWidth
        maxWidth="md"
        onCancel={() => setFiltersDialog(false)}
        open={filtersDialog}
        title="Filtros"
      >
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Saldo a la fecha</Typography>
          </Grid>
          <Grid alignItems="center" container item spacing={2} xs={12}>
            <Grid item justifyItems="center" xs={2}>
              <Box display="flex">
                <Checkbox
                  checked={filters.showDate}
                  onChange={(e) => setFilters((prevState) => ({ ...prevState, showDate: e.target.checked }))}
                  sx={{ width: '98%' }}
                />
                <Divider flexItem orientation="vertical" />
              </Box>
            </Grid>
            <Grid item xs={10} />
          </Grid>
        </Grid>
      </CustomDialog>
      <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
        <Grid item xs={8}>
          <Typography variant="body2">Saldos</Typography>
        </Grid>
        <Grid item>
          <TextField
            label="Búsqueda"
            onChange={(e) => setFilters((prevState) => ({ ...prevState, searchString: e.target.value }))}
            value={filters.searchString}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="Saldos al día"
            maxDate={dayjs()}
            onChange={(date) => setFilters((prevState) => ({ ...prevState, date: dayjs(date) }))}
            renderInput={(props) => <TextField {...props} fullWidth label="Saldos al día" />}
            value={filters.date}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable headers={TABLE_HEADERS}>
          {accounts
            .filter(a => !(a.apartmentName.includes('-R') && a.balance === 0))
            .map((a, index) => (
              <TableRow key={index}>
                <TableCell>{a.apartmentName}</TableCell>
                <TableCell>{a.commensalName}</TableCell>
                <TableCell align="right">$ {formattedNumber(a.balance.toFixed(2))}</TableCell>
              </TableRow>
            ))}
        </CustomTable>
      </Grid>
    </>
  );
};

export default BalancesReport;
