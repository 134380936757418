import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';

import { Apartments } from 'components';
import { useSnackbar } from 'hooks';
import {
  GetUserDetailsQuery,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
} from 'types/graphql';

type User = GetUserDetailsQuery['user'];

interface UserInfoFormProps {
  user: User;
}

const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $data: users_set_input!, $apartment_id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    update_apartment_by_pk(pk_columns: { id: $apartment_id }, _set: { user_id: $id }) {
      id
    }
  }
`;

const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($email: String!, $uid: String!) {
    UpdateUserEmail(email: $email, uid: $uid) {
      email
    }
  }
`;

const initialFormikState = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  apartment: 0
};

type FormikState = typeof initialFormikState;

const UserInfoForm = ({ user }: UserInfoFormProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const [doUpdate, { loading: updateLoading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UPDATE_USER
  );
  const [updateEmail, { loading: emailLoading }] = useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UPDATE_USER_EMAIL);
  const [formikState, setFormikstate] = useState<FormikState>({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    phone_number: user?.phone_number || '',
    apartment: user?.apartment?.id || 0
  });

  useEffect(() => {
    setFormikstate({
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      phone_number: user?.phone_number || '',
      apartment: user?.apartment?.id || 0
    });
  }, [user]);

  const handleSubmission = async (values: FormikState) => {
    try {
      if (user) {
        if (values.email !== formikState.email) {
          // El correo se actualiza en otra mutation para ahorrar llamadas a las funciones de google cloud
          await updateEmail({
            variables: {
              email: values.email,
              uid: user.uid
            }
          });
        }
        await doUpdate({
          variables: {
            id: user.id,
            data: {
              phone_number: values.phone_number,
              first_name: values.first_name,
              last_name: values.last_name
            },
            apartment_id: values.apartment
          }
        });
      }
      showSnackbar('Información actualizada', 'success');
    } catch (err) {
      showSnackbar('Error al guardar información', 'error');
    }
  };

  return (
    <Formik enableReinitialize initialValues={formikState} onSubmit={handleSubmission}>
      {({ values, handleSubmit, setFieldValue, handleChange }) => (
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <TextField fullWidth label="Nombre" onChange={handleChange('first_name')} value={values.first_name} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Apellido" onChange={handleChange('last_name')} value={values.last_name} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Correo electronico" onChange={handleChange('email')} value={values.email} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Número de teléfono"
              onChange={handleChange('phone_number')}
              value={values.phone_number}
            />
          </Grid>
          <Grid item xs={12}>
            <Apartments
              hideOwnedapartments
              hideOwnerNames
              label="Condominio"
              onChange={(apartment) => setFieldValue('apartment', apartment)}
              value={values.apartment}
            />
          </Grid>
          <Grid alignItems="center" container item justifyContent="flex-end" spacing={2} xs={12}>
            <Grid item onClick={() => handleSubmit()}>
              <LoadingButton color="primary" loading={emailLoading || updateLoading} variant="contained">
                Aceptar
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default UserInfoForm;
