import React from 'react';
import {
  Typography,
  Collapse,
  Box,
  Grid,
  MenuItem,
  Autocomplete,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { CustomDialog } from 'components';
import { Queries } from 'common';
import { formattedNumber } from 'helpers';
import {
  GetClosedAccountsQuery,
  OpenAccountMutation,
  OpenAccountMutationVariables,
  GetTablesQuery,
  CreateAccountTicketMutation,
  CreateAccountTicketMutationVariables
} from 'types/graphql';
import { usePinAuthorization, useSnackbar } from 'hooks';

interface SearchAccountsProps {
  open: boolean;
  onAccept: () => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const validationSchema = yup.object().shape({
  account: yup.object().shape({
    id: yup.number().required()
  }),
  table: yup.number().required(),
  pin: yup.string().length(4, 'El nip debe tener exactamente 4 dígitos')
});

const initialFormikState = {
  account: {
    id: 0,
    name: '',
    total: 0,
    type: ''
  },
  table: 1,
  pin: ''
};

type FormikState = typeof initialFormikState;

const OPEN_ACCOUNT = gql`
  mutation OpenAccount($id: Int!) {
    update_statement_account_by_pk(pk_columns: { id: $id }, _set: { open: true }) {
      id
      open
    }
  }
`;

const CREATE_ACCOUNT_TICKET = gql`
  mutation CreateAccountTicket($data: account_tickets_insert_input!) {
    insert_account_tickets_one(object: $data) {
      id
    }
  }
`;

const GET_TABLES = gql`
  query GetTables {
    tables(where: { disabled: { _eq: false } }) {
      id
      name
    }
  }
`;

const SearchAccounts = ({ open, onAccept, onCancel }: SearchAccountsProps): JSX.Element => {
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { data, loading } = useQuery<GetClosedAccountsQuery>(Queries.GET_CLOSED_ACCOUNTS, { pollInterval: 1000 });
  const { data: tablesData } = useQuery<GetTablesQuery>(GET_TABLES);
  const { authorize, authorized } = usePinAuthorization();
  const [openAccount] = useMutation<OpenAccountMutation, OpenAccountMutationVariables>(OPEN_ACCOUNT);
  const [createTicket] = useMutation<CreateAccountTicketMutation, CreateAccountTicketMutationVariables>(
    CREATE_ACCOUNT_TICKET
  );

  const handleFormSubmission = async (values: FormikState, helpers: FormikHelpers<FormikState>): Promise<void> => {
    try {
      if (authorized) {
        if (values.account.type === 'rental' && values.account.total < 500) {
          helpers.setErrors({ account: { id: 'La cuenta debe tener un balance de al menos $500.00' } });
          return;
        } else if (values.account.type === 'owner' && values.account.total < 10) {
          helpers.setErrors({ account: { id: 'La cuenta debe tener un balance de al menos $10.00' } });
          return;
        }

        await createTicket({
          variables: {
            data: {
              statement_account_id: values.account.id,
              table_id: values.table,
              open: true,
              tip: 0,
              total: 0
            }
          }
        });

        await openAccount({
          variables: {
            id: values.account.id
          }
        });
        showSnackbar('Cuenta abierta.', 'success');
        history.push('/accounts/details', { id: values.account.id, title: values.account.name });
        onAccept();
      } else {
        helpers.setErrors({ pin: 'Nip incorrecto' });
      }
    } catch (err) {
      showSnackbar('Seleccione una cuenta para continuar.', 'error');
    }
  };

  return (
    <Formik initialValues={initialFormikState} onSubmit={handleFormSubmission} validationSchema={validationSchema}>
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={() => handleSubmit()}
          onCancel={onCancel}
          open={open}
          title="Abrir cuenta"
        >
          <Grid alignItems="center" container spacing={2}>
            {loading ? (
              <Grid alignItems="center" container item justifyContent="center" xs={12}>
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
              </Grid>
            ) : (
              <Grid alignItems="center" container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Autocomplete
                    getOptionLabel={(option) =>
                      option ? `${option.apartment_name || 'Empleado -'} (${option.commensal_name})` : ''
                    }
                    onChange={(_, value) => {
                      if (value) {
                        setFieldValue('account.id', value?.statement_id || 0);
                        setFieldValue('account.total', value?.balance || 0);
                        setFieldValue('account.name', value?.commensal_name || '');
                        setFieldValue('account.type', value?.commensal_type || '');
                      }
                    }}
                    options={data?.palapa_accounts || []}
                    renderInput={(inputProps) => (
                      <TextField
                        {...inputProps}
                        error={Boolean(errors.account?.id)}
                        fullWidth
                        helperText={Boolean(errors.account?.id) && errors.account?.id}
                        label="Cuenta"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ pr: 2, pl: 2 }}>
                    <Collapse in={values.account.id !== 0}>
                      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
                        <Grid item>
                          <Typography fontWeight="bold">Saldo: </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>$ {formattedNumber(values.account.total.toFixed(2))}</Typography>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="label">Mesa</InputLabel>
                    <Select
                      label="Mesa"
                      labelId="label"
                      onChange={(e) => setFieldValue('table', e.target.value)}
                      value={values.table}
                    >
                      {tablesData?.tables.map((t) => (
                        <MenuItem key={t.id} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={touched.pin && Boolean(errors.pin)}
                    fullWidth
                    helperText={touched.pin && errors.pin}
                    inputMode="numeric"
                    inputProps={{ maxLength: 4, inputMode: 'numeric' }}
                    label="Nip"
                    onChange={(e) => {
                      setFieldValue('pin', e.target.value);
                      authorize(e.target.value, values.account.id);
                    }}
                    type="password"
                    value={values.pin}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default SearchAccounts;
