import { applyMiddleware, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { PalapaReduxState, StoreActions, DispatchType } from 'types/redux';
import reducer from './reducer';

const persistConfig = {
  storage,
  key: 'root'
};

const persistedReducer = persistReducer(persistConfig, reducer);

// eslint-disable-next-line
const createPalapaStore = () => {
  const store: Store<PalapaReduxState, StoreActions> & { dispatch: DispatchType } = createStore(
    persistedReducer,
    applyMiddleware(thunk)
  );
  const persistor = persistStore(store);

  return { store, persistor };
};

export default createPalapaStore;
