import React from 'react';
import { Grid, TextField, Typography, Divider, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';

import { useSnackbar } from 'context';
import { InsertUserMutation, InsertUserMutationVariables, SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables } from 'types/graphql';
import { Apartments } from 'components';

const CREATE_USER = gql`
  mutation MyMutation (
      $first_name: String!
      $last_name: String!
      $email: String!
      $phone_number: String!
      $roles: String!
      $uid: String!
  ){ 
    insert_users(objects: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone_number: $phone_number
        roles: $roles
        uid: $uid}) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_APARTMENT = gql`
  mutation SetApartmentOwner($apartment_id: Int!, $owner_id: Int!) {
    update_apartment_by_pk(pk_columns: { id: $apartment_id }, _set: { user_id: $owner_id }) {
      id
    }
  }
`;

const initialFormikState = {
  first_name: '',
  last_name: '',
  roles: 'user',
  email: '',
  phone_number: '',
  apartment_id: 0
};

type FormikState = typeof initialFormikState;

const AddUser = (): JSX.Element => {
  const history = useHistory();
  const [doInsert, { loading: insertLoading }] = useMutation<InsertUserMutation, InsertUserMutationVariables>(
    CREATE_USER
  );
  const [updateApartment] = useMutation<SetApartmentOwnerMutation, SetApartmentOwnerMutationVariables>(
    UPDATE_APARTMENT
  );
  const { showSnackbar } = useSnackbar();

  const handleSubmission = async (values: FormikState) => {
    try {
      // Insert the user and get the new user's ID
      const { data } = await doInsert({
        variables: { ...values, uid: '' }
      });

      if (values.roles === 'user'){
        const newUserId = data?.insert_users?.returning[0]?.id;

        if (newUserId && values.apartment_id) {
          // Update the apartment with the new user's ID
          await updateApartment({
            variables: { 
              apartment_id: values.apartment_id, 
              owner_id: newUserId }
          });
        }
      }
      const newUserId = data?.insert_users?.returning[0]?.id;

      if (newUserId && values.apartment_id) {
        // Update the apartment with the new user's ID
        await updateApartment({
          variables: { 
            apartment_id: values.apartment_id, 
            owner_id: newUserId }
        });
      }

      history.goBack();

      showSnackbar('Usuario creado correctamante', 'success');
    } catch (err) {
      showSnackbar('Error al crear usuario', 'error');
    }
  };

  return (
    <Formik initialValues={initialFormikState} onSubmit={handleSubmission}>
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <Grid alignItems="center" container spacing={2}>
          <Grid alignItems="center" container item spacing={2} xs={12}>
            <Grid item>
              <Typography variant="h6">Registrar usuario</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Nombre(s)" onChange={handleChange('first_name')} value={values.first_name} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Apellido(s)" onChange={handleChange('last_name')} value={values.last_name} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Correo electrónico" onChange={handleChange('email')} value={values.email} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Número de teléfono"
              onChange={handleChange('phone_number')}
              value={values.phone_number}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="roles">Rol</InputLabel>
              <Select
                label="Rol"
                labelId="roles"
                onChange={(e) => {
                  setFieldValue('roles', e.target.value);
                }}
                value={values.roles}
              >
                <MenuItem value="user">Usuario / dueño</MenuItem>
                <MenuItem value="admin">Administrador</MenuItem>
                <MenuItem value="palapa">Palapa</MenuItem>
                <MenuItem value="recepcion">Recepcionista</MenuItem>
                <MenuItem value="board">Board</MenuItem>
                <MenuItem value="portico">Pórtico</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {values.roles === 'user' && (
            <Grid item xs={12}>
              <Apartments
                label="Condominio"
                onChange={(id) => setFieldValue('apartment_id', id)}
                value={values.apartment_id}
              />
            </Grid>
          )}
          <Grid alignItems="center" container item justifyContent="flex-end" spacing={2} xs={12}>
            <Grid item>
              <LoadingButton color="primary" loading={insertLoading} onClick={() => handleSubmit()} variant="contained">
                Aceptar
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default AddUser;
