import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { PalapaReduxState } from 'types/redux';
import { useSelector } from 'react-redux';
import { ExchangeRateDialog } from '.';

const ReceptionContextMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const [checkoutDialog, setExchangeDialog] = useState<boolean>(false);

  const handleChangeClick = (): void => {
    setExchangeDialog(true);
    setAnchorEl(null);
  };

  const handleCloseDialog = (): void => {
    setExchangeDialog(false);
  };

  const user = useSelector((state: PalapaReduxState) => state.user);

  return (
    <>
      <ExchangeRateDialog
        onClose={handleCloseDialog}
        open={checkoutDialog}
      />
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ ml: 2, mt: 1 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem onClick={() => history.push('/reception/cashier-close')}>Corte de caja</MenuItem>
        {user.role === 'admin' && (
          <MenuItem onClick={handleChangeClick}>Tipo de monedas</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ReceptionContextMenu;
