import React, { useState } from 'react';
import { TableRow, TableCell, Collapse, Box, IconButton } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { formattedNumber } from 'helpers';
import { GetOpenAccountsQuery } from 'types/graphql';

type MappedAccount = GetOpenAccountsQuery['accounts'][0] & { open: boolean };
interface AccountRowProps {
  account: MappedAccount;
}

const AccountRow = ({ account }: AccountRowProps): JSX.Element => {
  const [open, setOpen] = useState(account.open);

  return (
    <>
      <TableRow>
        <TableCell sx={{ maxWidth: '10%' }}>
          <IconButton onClick={() => setOpen((prevState) => !prevState)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{`${account.apartment_name} - ${account.commensal_name}`}</TableCell>
        <TableCell align="right">$ {formattedNumber((account.balance as number).toFixed(2))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open}>
            <Box sx={{ margin: 1 }}>
              <div>Hola</div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AccountRow;
