import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Divider,
  Box
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';

import { CustomDialog, NumberFormatInput } from 'components';
import { formattedNumber } from 'helpers';
import { usePinAuthorization } from 'hooks';

interface CloseAccountDialogProps {
  open: boolean;
  orderTotal: number;
  orderSubtotal: number;
  statementId: number;
  onAccept: (tipInfo: FormikState) => void;
  onCancel: (...args: any[]) => void;
}

const initialFormikState = {
  tip: '',
  tipPercentage: 4,
  pin: ''
};

type FormikState = typeof initialFormikState;

const CloseAccountDialog = ({
  open,
  orderTotal,
  orderSubtotal,
  statementId,
  onAccept,
  onCancel
}: CloseAccountDialogProps): JSX.Element => {
  const [formikState, setFormikState] = useState(initialFormikState);
  const { authorize, authorized } = usePinAuthorization();

  useEffect(() => {
    const tip = calculateTip(orderTotal, 4);
    setFormikState({ ...formikState, tip: tip.toString() });
  }, [orderTotal]);

  const calculateTip = (orderTotal: number, option: number): number => {
    if (option < 4) {
      const percentage = (10 + (option - 1) * 5) / 100;
      return Math.ceil(orderTotal * percentage);
    } else return Number(formikState.tip);
  };

  const handleFormSubmission = (values: FormikState, helpers: FormikHelpers<FormikState>) => {
    if (authorized) {
      onAccept(values);
    } else {
      helpers.setErrors({ pin: 'Pin incorrecto' });
    }
  };

  return (
    <Formik enableReinitialize initialValues={formikState} onSubmit={handleFormSubmission}>
      {({ values, errors, setFieldValue, handleSubmit }) => (
        <CustomDialog
          fullWidth
          maxWidth="md"
          onAccept={() => handleSubmit()}
          onCancel={onCancel}
          open={open}
          title="Cerrar cuenta"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel>Propina</FormLabel>
                <RadioGroup
                  aria-label="Propina"
                  onChange={(e) => {
                    const tip = calculateTip(orderTotal, Number(e.target.value));
                    setFieldValue('tip', tip.toString());
                    setFieldValue('tipPercentage', Number(e.target.value));
                  }}
                  value={values.tipPercentage}
                >
                  <FormControlLabel control={<Radio />} label="10%" value={1} />
                  <FormControlLabel control={<Radio />} label="15%" value={2} />
                  <FormControlLabel control={<Radio />} label="20%" value={3} />
                  <FormControlLabel control={<Radio />} label="Otro" value={4} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <NumberFormatInput
                disabled={values.tipPercentage !== 4}
                fullWidth
                inputProps={{ inputMode: 'numeric' }}
                label="Propina"
                onChange={(e) => {
                  setFieldValue('tip', e.target.value);
                }}
                prefix="$"
                readOnly={values.tipPercentage !== 4}
                thousandSeparator
                value={values.tip}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(errors.pin)}
                fullWidth
                helperText={Boolean(errors.pin) && errors.pin}
                inputMode="numeric"
                inputProps={{
                  maxLength: 4,
                  inputMode: 'numeric'
                }}
                label="Pin"
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setFieldValue('pin', value);
                  authorize(e.target.value, statementId);
                }}
                type="password"
                value={values.pin}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container item justifyContent="flex-end" spacing={2} xs={12}>
              <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                <span style={{ textAlign: 'right' }}>
                  <b>Subtotal: </b>$ {formattedNumber(orderSubtotal.toFixed(2))}
                </span>
                <span style={{ textAlign: 'right' }}>
                  <b>Descuento dueño: </b> - $ {formattedNumber((orderSubtotal - orderTotal).toFixed(2))}
                </span>
                <span style={{ textAlign: 'right' }}>
                  <b>Propina: </b> $ {formattedNumber(Number(values.tip).toFixed(2))}
                </span>
                <span style={{ textAlign: 'right' }}>
                  <b>Total: </b>$ {formattedNumber((orderTotal + Number(values.tip)).toFixed(2))}
                </span>
              </Box>
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default CloseAccountDialog;
