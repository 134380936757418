import React from 'react';
import { Grid, TextField, Button, Card, CardContent, Divider, CardActions, CardHeader } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useSnackbar, useFirebaseAuth } from 'hooks';
import { PalapaReduxState } from 'types/redux';

const validationSchema = yup.object().shape({
  user: yup.string().email('Se requiere un correo electrónico válido').required('Este campo es obligatorio'),
  password: yup.string().required('Este campo es obligatorio')
});

const initialValues = {
  user: '',
  password: ''
};

type FormikState = typeof initialValues;

const Login = (): JSX.Element => {
  const { signIn } = useFirebaseAuth();
  const { showSnackbar } = useSnackbar();
  const state = useSelector((reduxState: PalapaReduxState) => reduxState.user);
  const history = useHistory();

  const handleFormikSubmission = async (values: FormikState) => {
    try {
      if (signIn) {
        await signIn(values.user, values.password);
        history.push('/dashboard');
      }
    } catch (err) {
      // TODO: Handle error
      showSnackbar('Usuario y/o contraseña incorrectos', 'error');
    }
  };

  if (state.auth) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      spacing={0}
      style={{ minHeight: '100vh' }}
    >
      <Formik initialValues={initialValues} onSubmit={handleFormikSubmission} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form style={{ width: '100%' }}>
            <Card style={{ width: '80%', margin: 'auto' }}>
              <CardHeader title="Iniciar sesión" />
              <Divider />
              <CardContent>
                <Grid container flexDirection="column" item spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.user && Boolean(errors.user)}
                      fullWidth
                      helperText={touched.user && errors.user}
                      label="Email"
                      onBlur={handleBlur('user')}
                      onChange={handleChange('user')}
                      value={values.user}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.password && Boolean(errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      onBlur={handleBlur('password')}
                      onChange={handleChange('password')}
                      type="password"
                      value={values.password}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button color="primary" fullWidth onClick={() => handleSubmit()} type="submit" variant="contained">
                  Ingresar
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default Login;
