import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import { gql, useQuery } from '@apollo/client';

import { GetActiveCommensalsQuery } from 'types/graphql';

type CommensalType = 'owner' | 'rental';
type StatementSelectorItem = {
  name: string;
  id: number;
  apartmentId: number;
  balance: number;
  apartment: string;
  commensalType: CommensalType;
};

type CommensalInfo = {
  isOwner: boolean;
  apartmentId: number;
  balance: number;
  commensalName: string;
  apartment: string;
};

interface CommensalSelectorProps {
  value: number;
  onChange?: (statementId: number, commensalInfo: CommensalInfo) => void;
  label?: string;
  helperText?: string;
}

const GET_COMMENSALS = gql`
  query GetActiveCommensals {
    palapa_accounts(
      where: { _or: [{ present: { _eq: true } }, { commensal_type: { _eq: "owner" } }] }
      order_by: [{ apartment_building: asc }, { apartment_number: asc }]
    ) {
      apartment_id
      apartment_name
      commensal_name
      commensal_type
      balance
      statement_id
    }
  }
`;

const CommensalSelector = ({ value, helperText, label, onChange }: CommensalSelectorProps): JSX.Element => {
  const [commensals, setCommensals] = useState<StatementSelectorItem[]>([]);
  const [selectedCommensal, setSelectedCommensal] = useState<StatementSelectorItem | null>(null);
  const { data, loading } = useQuery<GetActiveCommensalsQuery>(GET_COMMENSALS, { pollInterval: 2000 });

  useEffect(() => {
    if (commensals && value) {
      handleValueChange();
    }
  }, [commensals, value]);

  useEffect(() => {
    if (data) {
      const mappedCommensals: StatementSelectorItem[] = data.palapa_accounts.map((c) => ({
        name: c.commensal_name || '',
        id: c.statement_id || 0,
        apartmentId: c.apartment_id || 0,
        balance: (c.balance as number) || 0,
        apartment: c.apartment_name || '',
        commensalType: c.commensal_type as CommensalType
      }));

      setCommensals(mappedCommensals);
    }
  }, [data]);

  const handleValueChange = () => {
    const selectedCommensal = commensals.find((c) => c.id === value);
    if (selectedCommensal) {
      setSelectedCommensal(selectedCommensal);
    }
  };

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: StatementSelectorItem | null) => {
    setSelectedCommensal(value);
    if (onChange) {
      onChange(value?.id || 0, {
        apartmentId: value?.apartmentId || 0,
        balance: value?.balance || 0,
        isOwner: value?.commensalType === 'owner' || false,
        commensalName: value?.name || '',
        apartment: value?.apartment || ''
      });
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => `${option.apartment}: ${option.name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      onChange={handleChange}
      options={commensals}
      renderInput={(props) => (
        <TextField {...props} fullWidth helperText={helperText} label={label || 'Cuenta'} variant="outlined" />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {`${option.apartment}: ${option.name}`}
        </Box>
      )}
      value={selectedCommensal}
    />
  );
};

export default CommensalSelector;
