import React, { useState } from 'react';
import { Grid, Typography, Divider, Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { GetUserDetailsQuery, GetUserDetailsQueryVariables } from 'types/graphql';
import { LoadingIndicator } from 'components';
import { TabPanel, UserInfoForm, UserManager } from './components';

const GET_USER_DETAILS = gql`
  query GetUserDetails($id: Int!) {
    user: users_by_pk(id: $id) {
      id
      first_name
      last_name
      email
      phone_number
      uid
      roles
      apartments {
        id
        statement_accounts {
          total
          transactions {
            amount
            es_description
            transaction_type
          }
        }
      }
    }
  }
`;

const UserDetails = (): JSX.Element => {
  const [tab, setTab] = useState(0);
  const location = useLocation<{ id: number }>();
  const { data, loading } = useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GET_USER_DETAILS, {
    variables: { id: location.state.id }
    // pollInterval: 1500
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
        <Grid item>
          <Typography variant="h6">{`${data?.user?.first_name} ${data?.user?.last_name}`}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={2}>
          <Tabs onChange={(_, value) => setTab(value)} orientation="vertical" value={tab}>
            <Tab label="Información personal" value={0} />
            <Tab label="Gestionar" value={1} />
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          <TabPanel index={0} value={tab}>
            <UserInfoForm user={data?.user} />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <UserManager user={data?.user} />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
