type Role = 'user' | 'admin' | 'portico' | 'board' | 'palapa' | 'recepcion' | 'drinkbar';

const grantAccess = (userRole: Role, routeRole: Role | Role[] |'*'): boolean => {
  if (userRole === 'admin' || routeRole === '*') {
    return true;
  }
  if (Array.isArray(routeRole)) {
    return routeRole.includes(userRole);
  }
  return userRole === routeRole;
};

export default grantAccess;
