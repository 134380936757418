import React from 'react';
import { ThemeProvider } from '@mui/material';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { AxiosProvider } from 'context';
import { useFirebaseAuth } from 'hooks';
import Routes from 'Routes';
import palapaTheme from './theme';

const history = createBrowserHistory();

const httpLink = new HttpLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? (process.env.REACT_APP_HASURA_PROD_LINK as string)
      : (process.env.REACT_APP_HASURA_DEV_LINK as string)
});

function App(): JSX.Element {
  const { getUserToken } = useFirebaseAuth();

  const authLink = setContext(async (_, { headers = {} }) => {
    const token = getUserToken ? await getUserToken() : '';

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development'
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={palapaTheme}>
        <AxiosProvider>
          <Router history={history}>
            <Routes />
          </Router>
        </AxiosProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
